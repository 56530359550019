import { faBullhorn } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Chip } from '@feathr/components';

import { StoresContext } from '../../state';
import { campaignColorMap, campaignIconMap } from '../../styles/campaign';

interface IProps {
  id: string;
  localUrl?: (url: string) => string;
}

function CampaignChip({ id, localUrl }: IProps) {
  const { Campaigns } = useContext(StoresContext);

  const campaign = Campaigns.get(id);
  if (campaign.isErrored) {
    return null;
  }

  if (campaign.isPending) {
    return <Chip isLoading={true} />;
  }

  const type = campaign.get('_cls');
  const content = (
    <Chip
      prefix={<FontAwesomeIcon icon={campaignIconMap.get(type) || faBullhorn} />}
      theme={campaignColorMap.get(type)}
    >
      {campaign.name}
      {campaign.get('is_archived') ? ' (Archived)' : ''}
    </Chip>
  );

  if (localUrl) {
    return <Link to={localUrl(campaign.getItemUrl())}>{content}</Link>;
  }
  return content;
}

export default observer(CampaignChip);
