import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { TAttributionModel } from '@feathr/blackbox';
import { isAttributionModel } from '@feathr/blackbox';
import { Select } from '@feathr/components';

import * as styles from './AttributionModel.css';

interface IAttributionModelOption {
  id: TAttributionModel;
  name: string;
  description: string;
}

interface IProps {
  isLoading?: boolean;
  onChange: (newValue: TAttributionModel) => void;
  value?: TAttributionModel;
}

export const attributionModelLabels: Record<
  TAttributionModel,
  { label: string; description: string }
> = {
  full: {
    label: 'Full',
    description:
      'Each Campaign that contributed to a conversion gets 100% credit for the conversion.',
  },
  linear: {
    label: 'Linear',
    description:
      'Credit for each conversion is distributed equally among campaigns that contributed.',
  },
  first_touch: {
    label: 'First Touch',
    description: 'Only the first campaign in a Path to Conversion gets credit for the conversion.',
  },
  last_touch: {
    label: 'Last Touch',
    description: 'Only the last campaign in a Path to Conversion gets credit for the conversion.',
  },
  positional: {
    label: 'Positional',
    description:
      'Credit for a conversion is weighted such that the first and last campaigns in a Path to Conversion get 80% of the credit and the remaining 20% is distributed equally among the remaining campaigns.',
  },
  temporal: {
    label: 'Time Decay',
    description:
      'Credit for a conversion is weighted such that a campaign gets more credit the closer it is in time to the time of the conversion.',
  },
};

const options = Object.keys(attributionModelLabels).map((key) => ({
  id: key as TAttributionModel,
  name: attributionModelLabels[key].label,
  description: attributionModelLabels[key].description,
}));

function AttributionModel({ isLoading = false, onChange, value }: IProps): JSX.Element {
  const optionValue = options.find((option) => option.id === value) || options[0];
  const { t } = useTranslation();

  function handleSelectSingle(newValue: IAttributionModelOption): void {
    if (typeof newValue.id === 'string' && isAttributionModel(newValue.id)) {
      onChange(newValue.id);
    }
  }

  return (
    <Select<IAttributionModelOption>
      className={styles.root}
      helpPlacement={'bottom'}
      helpText={
        <Trans t={t}>
          <p>{optionValue && optionValue.description}</p>
          <p>
            <a
              href={
                'https://help.feathr.co/hc/en-us/articles/360036644334-Conversion-Attribution-Models'
              }
              target={'_blank'}
            >
              Learn more
            </a>{' '}
            about Feathr's attribution models.
          </p>
        </Trans>
      }
      isLoading={isLoading}
      label={t('Attribution model')}
      onSelectSingle={handleSelectSingle}
      options={options}
      placeholder={t('Select attribution model')}
      value={optionValue}
    />
  );
}

export default AttributionModel;
