import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardV2 as Card, TimeFrameSelect } from '@feathr/components';
import { useTimeFrameSelect } from '@feathr/extender/hooks';
import { ECardCollapseKeys, ENamespaces } from '@feathr/hooks';

import AdPerformanceCharts from './AdPerformanceCharts';

import * as styles from './AdPerformance.css';

function AdPerformance(): JSX.Element {
  const { t } = useTranslation();
  const { options, timeFrame, timeFrameValue, handleChangeTimeFrame } = useTimeFrameSelect();

  return (
    <Card width={'full'}>
      <Card.Header
        isCollapsible={true}
        persist={{
          key: ECardCollapseKeys.AdPerformance,
          namespace: ENamespaces.AccountDashboard,
        }}
        title={t('Ad Performance')}
        width={'full'}
      >
        <TimeFrameSelect onChange={handleChangeTimeFrame} options={options} value={timeFrame} />
      </Card.Header>
      <Card.Content contentClassName={styles.content} name={'ad-performance-card-content'}>
        <AdPerformanceCharts timeFrameValue={timeFrameValue} />
      </Card.Content>
    </Card>
  );
}

export default AdPerformance;
