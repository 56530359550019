import * as React from 'react';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface ISkeletonLegProps {
  displayMode: 'default' | 'condensed';
}

function SkeletonLeg(props: ISkeletonLegProps) {
  const { displayMode = 'default' } = props;
  let height = '500px';
  if (displayMode === 'condensed') {
    height = '120px';
  }
  return <div className={skeleton} style={{ flex: 1, height, marginRight: '10px' }} />;
}

export default SkeletonLeg;
