import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Flight } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { FormElement, ModalV1, Radios, toast } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import LegSelect from '../LegSelect';

import * as styles from './LegModalRemove.css';

interface IProps {
  flight: Flight;
  legId: number;
  onClose: () => void;
  removeLeg: (legId: number) => Promise<void> | void;
}

function LegModalRemove({ flight, legId, onClose, removeLeg }: IProps): JSX.Element {
  const { Campaigns } = useContext(StoresContext);
  const [action, setAction] = useState<'move' | 'archive'>('move');
  const [selectedLegId, setLegId] = useState<number | undefined>();
  const { t } = useTranslation();

  const legs = flight.get('legs');
  const removedLegName = legs[legId].name;

  async function moveCampaigns(newLegId: number): Promise<void> {
    legs[newLegId].campaigns = [
      // Enforce unique campaignIds.
      ...new Set([...legs[newLegId].campaigns, ...legs[legId].campaigns]),
    ];
    legs[legId].campaigns = [];
    onClose();
    await removeLeg(legId);

    toast(
      t(
        'Moved all campaigns from {{removedLegName}} to leg {{newLegName}}, and removed {{removedLegName}}.',
        { removedLegName, newLegName: legs[newLegId].name },
      ),
      {
        type: 'success',
      },
    );
  }

  async function archiveCampaigns(): Promise<void> {
    await Campaigns.bulk(legs[legId].campaigns, {
      is_archived: true,
      state: CampaignState.Archived,
    });
    onClose();
    await removeLeg(legId);
    toast(
      t('Archived all campaigns from {{removedLegName}} and removed {{removedLegName}}.', {
        removedLegName,
      }),
      {
        type: ToastType.SUCCESS,
      },
    );
  }

  function handleActionChange(newValue?: string): void {
    setAction(newValue as 'move' | 'archive');
  }

  const legName = legs[legId].name;
  // 0 is a valid value for selectedLegId, so we need to check for undefined.
  const newLegName = selectedLegId !== undefined ? legs[selectedLegId].name : undefined;

  const modalMessage =
    action === 'archive' ? (
      <Trans t={t}>
        <p>
          Are you sure you want to remove leg {{ legName }}?<br />
          The campaigns in this leg will be <em>archived.</em>
        </p>
      </Trans>
    ) : selectedLegId === undefined ? (
      <Trans t={t}>
        <p>
          Are you sure you want to remove leg {{ legName }}?<br />
          The campaigns in this leg will be <em>moved.</em>
        </p>
      </Trans>
    ) : (
      <Trans t={t}>
        <p>
          Are you sure you want to remove leg {{ legName }}?<br />
          The campaigns in this leg will be moved to <em>{{ newLegName }}.</em>
        </p>
      </Trans>
    );

  return (
    <ModalV1
      confirmDisabled={action === 'move' && selectedLegId === undefined}
      controlled={true}
      onClose={onClose}
      onConfirm={() => {
        if (action === 'move') {
          moveCampaigns(selectedLegId!);
        } else if (action === 'archive') {
          archiveCampaigns();
        }
      }}
      size={'sm'}
      t={t}
      title={t('Remove Leg')}
    >
      <>
        {modalMessage}
        <Radios
          className={styles.radios}
          onChange={handleActionChange}
          options={[
            { id: 'move', name: t('Move campaigns') },
            { id: 'archive', name: t('Archive campaigns') },
          ]}
          value={action}
        />
        {action === 'move' && (
          <FormElement helpText={t('Select a leg to move all the campaigns in leg this to.')}>
            <LegSelect
              exclude={[legId]}
              flightId={flight.id}
              onChange={setLegId}
              onClear={() => setLegId(undefined)}
              value={selectedLegId}
            />
          </FormElement>
        )}
      </>
    </ModalV1>
  );
}

export default observer(LegModalRemove);
