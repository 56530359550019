import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMousePointer, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps, SingleValueProps } from 'react-select';
import { components } from 'react-select';

import { FieldCollection } from '@feathr/blackbox';
import type { ISelectProps } from '@feathr/components';
import { Select } from '@feathr/components';

import * as styles from './CustomFieldsCollectionSelect.css';

export interface ICustomFieldsCollectionSelectProps
  extends Pick<ISelectProps, 'disabled' | 'helpText' | 'helpPlacement' | 'label'> {
  className?: string;
  onChange: (newValue: FieldCollection) => void;
  value?: FieldCollection;
}

interface IFieldCollectionOption {
  id: FieldCollection;
  name: string;
}

const collectionToIconMap = new Map<FieldCollection, IconProp>([
  [FieldCollection.Person, faUser],
  [FieldCollection.Breadcrumb, faMousePointer],
]);

function Option(props: OptionProps<IFieldCollectionOption>) {
  const { data } = props;
  const icon = collectionToIconMap.get(data.id);
  return (
    <components.Option {...props} className={styles.option}>
      {icon && <FontAwesomeIcon icon={icon} />}
      <span>{data.name}</span>
    </components.Option>
  );
}

function SingleValue(props: SingleValueProps<IFieldCollectionOption>) {
  const { data } = props;
  const icon = collectionToIconMap.get(data.id);
  return (
    <components.SingleValue {...props} className={styles.option}>
      {icon && <FontAwesomeIcon icon={icon} />}
      <span>{data.name}</span>
    </components.SingleValue>
  );
}

function CustomFieldsCollectionSelect({
  className,
  onChange,
  value,
  ...additionalProps
}: ICustomFieldsCollectionSelectProps) {
  const { t } = useTranslation();

  const fieldCollectionOptions: IFieldCollectionOption[] = [
    { id: FieldCollection.Person, name: t('Person') },
    { id: FieldCollection.Breadcrumb, name: t('Activity') },
  ];

  const currentFieldCollection =
    fieldCollectionOptions.find((option) => option.id === value) || fieldCollectionOptions[0];
  return (
    <Select<IFieldCollectionOption>
      {...additionalProps}
      className={classNames(styles.element, className)}
      components={{ Option, SingleValue }}
      onSelectSingle={(option: IFieldCollectionOption) => {
        onChange(option.id);
      }}
      options={fieldCollectionOptions}
      value={currentFieldCollection}
      wrapperClassName={styles.root}
    />
  );
}

export default CustomFieldsCollectionSelect;
