import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import type { ValueType } from 'react-select';

import type { IEventBilling } from '@feathr/blackbox';
import type { Billable } from '@feathr/blackbox';
import { Button, Form, Input, Popover, Spinner, toast, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import BillableSelect from '@feathr/extender/components/BillableSelect';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import * as styles from '../EventSettingsBilling.css';

function EventSettingsBillingEdit() {
  const { Billables, Events } = React.useContext(StoresContext);
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();

  const event = Events.get(eventId);
  if (event.isPending) {
    return <Spinner />;
  }
  const billing = event.get('billing', {} as IEventBilling);
  const billable = billing.billable_id ? Billables.get(billing.billable_id) : undefined;

  function handleChange(value: ValueType<Billable>) {
    if (!value || value instanceof Array) {
      return;
    }
    event.set({
      billing: {
        ...billing,
        billable_id: (value as Billable).id,
      } as IEventBilling,
    });
  }

  async function handleSave() {
    try {
      await event.patchDirty();
      toast('Project Billing successfully updated.', { type: 'success' });
      history.push(localUrl(event.getItemUrl('settings/billing')));
    } catch (e) {
      toast(
        'There was an issue saving changes to the project billing configuration. Please try again later.',
        { type: 'error' },
      );
      throw e;
    }
  }

  const referenceIDLabel = (
    <>
      Reference ID
      <Popover toggle={'onMouseOver'}>
        <span className={styles.info}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        <div className={styles.popover}>
          <p>
            Optional reference identification field (such as a PO number) that will be included on
            media invoices for this project.
          </p>
        </div>
      </Popover>
    </>
  );

  const billingConfigurationLabel = (
    <>
      Billing configuration
      <Popover toggle={'onMouseOver'}>
        <span className={styles.info}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        <div className={styles.popover}>
          <p>Used to process media spend after the end of the billing cycle.</p>
        </div>
      </Popover>
    </>
  );

  function handleReferenceIdChange(newValue?: string) {
    event.set({
      billing: {
        ...billing,
        reference_id: newValue,
      } as IEventBilling,
    });
    event.setAttributeDirty('billing');
  }

  return (
    <Page title={'Media Billing'}>
      <Form label={'Media Billing Form'}>
        <BillableSelect
          label={billingConfigurationLabel}
          onChange={handleChange}
          value={billable}
        />
        <Input
          label={referenceIDLabel}
          onChange={handleReferenceIdChange}
          placeholder={'PO-12345678'}
          type={'text'}
          value={billing.reference_id}
        />
        <Toolbar>
          <Button onClick={handleSave} type={'success'}>
            Save
          </Button>
        </Toolbar>
      </Form>
    </Page>
  );
}

export default observer(EventSettingsBillingEdit);
