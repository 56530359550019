import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React from 'react';

import type { ICNAMERecord, IMXRecord } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function domainRecordsColumns(t: TFunction): Array<IColumn<ICNAMERecord | IMXRecord>> {
  return [
    {
      id: 'type',
      Header: TableColumnHeader({
        title: t('Type'),
      }),
      width: 60,
      sortable: false,
      className: tableStyles.cell,
      // TXT is a type of MX record.
      Cell({ original }): JSX.Element {
        return <>{original.type}</>;
      },
    },
    {
      id: 'host',
      Header: TableColumnHeader({
        title: t('Host'),
      }),
      sortable: false,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return <>{original.name}</>;
      },
    },
    {
      id: 'value',
      Header: TableColumnHeader({
        title: t('Value'),
      }),
      sortable: false,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return <>{original.value}</>;
      },
    },
  ];
}

export default domainRecordsColumns;
