import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { ConversationCampaign, IQuestion } from '@feathr/blackbox';
import { CampaignClass, CampaignLabelMap, CampaignState } from '@feathr/blackbox';
import { Checkbox, SaveButton } from '@feathr/components';
import { StoresContext, useAccount } from '@feathr/extender/state';
import { logUserEvents } from '@feathr/hooks';

import ConversationConfig from './ConversationConfig';
import ConversationPreview from './ConversationPreview';
import ConversationStats from './ConversationStats';
import QuestionConfig from './QuestionConfig';

import { config, content, header, headerText } from './ConversationDetails.css';

function ConversationDetails(): JSX.Element | null {
  const { t } = useTranslation();
  const { campaignId } = useParams<{ campaignId: string }>();
  const { Campaigns } = React.useContext(StoresContext);
  const account = useAccount();

  const campaign = Campaigns.get(campaignId, {
    _cls: CampaignClass.Conversation,
  }) as ConversationCampaign;
  if (campaign.isPending) {
    return null;
  }

  const questions: IQuestion[] = campaign.get('questions');
  const question = questions[0];
  const previewKey = `${campaign.get('theme').palette.primary1Color}${
    campaign.get('theme').palette.accent1Color
  }${campaign.get('logo')}`;

  function handleOnSave(): void {
    if (campaign.get('state') === CampaignState.Published) {
      logUserEvents({
        'Published campaign': {
          account_id: account.id,
          campaign_id: campaignId,
        },
        [`${CampaignLabelMap.get(campaign.get('_cls'))} Campaign published`]: {
          account_id: account.id,
          campaign_id: campaignId,
        },
      });
    }
  }

  function handleOnCheckboxChange(): void {
    campaign.set({
      is_enabled: campaign.get('state') !== 'published',
      state:
        campaign.get('state') === CampaignState.Published
          ? CampaignState.Stopped
          : CampaignState.Published,
    });
  }

  return (
    <>
      <div className={header}>
        <h3 className={headerText}>{t('Configuration')}</h3>
        <div>
          <Checkbox
            label={t('Published')}
            onChange={handleOnCheckboxChange}
            value={campaign.get('state') === CampaignState.Published}
          />
          {/* TODO: investigate using <SaveCampaignButton/> to get this app cues tracking for free */}
          <SaveButton model={campaign} onSave={handleOnSave} />
        </div>
      </div>
      <div className={content}>
        <div className={config}>
          <Trans t={t}>
            <p>
              Use Feathr conversations to engage with your users. When enabled, it will initialize
              on pages that have the Feathr super pixel already installed. It will ask the question
              you have configured below. Each answer has its own group for you to track your
              responses!
            </p>
          </Trans>
          <ConversationConfig conversationCampaign={campaign} />
          <QuestionConfig question={question} />
        </div>
        <ConversationPreview campaign={campaign} key={previewKey} />
      </div>

      <ConversationStats campaign={campaign} />
    </>
  );
}

export default observer(ConversationDetails);
