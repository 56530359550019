import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import type { DisplayCreative, IAdTagBaseCreative } from '@feathr/blackbox';
import { Postscribe } from '@feathr/components';

import * as styles from './CreativeThumbnail.css';

interface IProps {
  className?: string;
  size: number;
  creative: DisplayCreative<IAdTagBaseCreative>;
}

function formatAdTag(adtag: string, clickURL: string) {
  return adtag
    .replace(/@FEATHR_CLICK@/g, clickURL)
    .replace(/@FEATHR_CLICK_ESC@/g, encodeURIComponent(clickURL))
    .replace(/@FEATHR_TRACK@/g, '');
}

function AdTagCreativeThumbnail({ className, creative, size }: IProps) {
  const adtag = creative.get('preview_adtag') || creative.get('adtag');
  const width = creative.get('width');
  const height = creative.get('height');
  const extreme = Math.max(width, height);
  const previewStyles = {
    padding: 0,
    minHeight: '20px',
    minWidth: '20px',
    maxHeight: '200px',
    maxWidth: '200px',
    transformOrigin: 'top left',
    transform: '',
  };
  if (extreme > size) {
    const scaleFactor = size / extreme;
    previewStyles.transform = `scale(${scaleFactor})`;
  }
  return (
    <div className={classNames(styles.wrapper, className)}>
      <Postscribe html={formatAdTag(adtag, '')} style={previewStyles} />
    </div>
  );
}

export default observer(AdTagCreativeThumbnail);
