import type { TFunction } from 'i18next';
import capitalize from 'lodash.capitalize';

import { EBrowsers, EFlavorsRaw, EPlatforms } from '@feathr/blackbox';

function getLabel(t: TFunction, map: Record<string, string>, key?: string): string {
  return !key ? t('Unknown') : map[key] || capitalize(key);
}

export const browserToLabelMap = (
  t: TFunction,
  browser?: keyof typeof EBrowsers | string,
): string => {
  const map = {
    [EBrowsers.chrome]: t('Chrome'),
    [EBrowsers.firefox]: t('Firefox'),
    [EBrowsers.safari]: t('Safari'),
    [EBrowsers.edge]: t('Edge'),
    [EBrowsers.msie]: t('Internet Explorer'),
    [EBrowsers.opera]: t('Opera'),
    [EBrowsers.yahoo]: t('Yahoo'),
  };

  return getLabel(t, map, browser);
};

export const platformToLabelMap = (
  t: TFunction,
  platform?: keyof typeof EPlatforms | string,
): string => {
  const map = {
    [EPlatforms.windows]: t('Windows'),
    [EPlatforms.macos]: t('macOS'),
    [EPlatforms.iphone]: t('iPhone'),
    [EPlatforms.ipad]: t('iPad'),
    [EPlatforms.android]: t('Android'),
    [EPlatforms.chromeos]: t('Chrome OS'),
  };

  return getLabel(t, map, platform);
};

export const sourceToLabelMap = (t: TFunction, flavor: string): string => {
  const map = {
    [EFlavorsRaw.convert]: t('Conversion pixel'),
    [EFlavorsRaw.update]: t('Profile update'),
    [EFlavorsRaw.form_submission]: t('Submitted form'),
    [EFlavorsRaw.ad_click]: t('Clicked ad'),
    [EFlavorsRaw.pinpoint_tracked_email_click]: t('Clicked email'),
    [EFlavorsRaw.partner_message_link_click]: t('Clicked link in partner message'),
    [EFlavorsRaw.page_link_click]: t('Clicked link on page'),
    [EFlavorsRaw.conversion]: t('Conversion'),
    [EFlavorsRaw.dashboard_create]: t('Created dashboard'),
    [EFlavorsRaw.custom]: t('Custom'),
    [EFlavorsRaw.email_view]: t('Viewed email'),
    [EFlavorsRaw.pinpoint_tracked_email_suppression]: t('Email was suppressed'),
    [EFlavorsRaw.pinpoint_tracked_email_rendering_failure]: t('Email template rendering failed'),
    [EFlavorsRaw.pinpoint_tracked_email_hardbounce]: t('Hard bounce'),
    [EFlavorsRaw.dashboard_import]: t('Imported contacts on partner dashboard'),
    [EFlavorsRaw.pinpoint_tracked_email_open]: t('Opened email'),
    [EFlavorsRaw.created_by_import]: t('Created by import'),
    [EFlavorsRaw.updated_by_import]: t('Updated by import'),
    [EFlavorsRaw.pinpoint_tracked_email_delivered]: t('Received email'),
    [EFlavorsRaw.pinpoint_tracked_email_complaint]: t('Reported email as spam'),
    [EFlavorsRaw.dashboard_send_request]: t('Scheduled email invite'),
    [EFlavorsRaw.dashboard_send_complete]: t('Sent email from partner dashboard'),
    [EFlavorsRaw.pinpoint_tracked_email_softbounce]: t('Soft bounce'),
    [EFlavorsRaw.dashboard_submit]: t('Updated dashboard profile'),
    [EFlavorsRaw.ad_view]: t('Viewed ad'),
    [EFlavorsRaw.report_view]: t('Viewed campaign report'),
    [EFlavorsRaw.page_view]: t('Viewed page'),
    [EFlavorsRaw.dashboard_view]: t('Viewed partner dashboard'),
    [EFlavorsRaw.partner_message_view]: t('Viewed partner message'),
    [EFlavorsRaw.partner_message_send]: t('Was sent partner message'),
    [EFlavorsRaw.pinpoint_tracked_email_send]: t('Was sent email'),
    [EFlavorsRaw.user_create]: t('Manually created'),
    [EFlavorsRaw.user_update]: t('Manually updated'),
    [EFlavorsRaw.zapier_import]: t('Zapier import'),
    [EFlavorsRaw.zapier_update]: t('Zapier update'),
    [EFlavorsRaw.imis_create]: t('Created by iMIS'),
    [EFlavorsRaw.imis_update]: t('Updated by iMIS'),
    [EFlavorsRaw.email_preferences_update]: t('Updated email preferences'),
    [EFlavorsRaw.raisers_edge_constituent_created]: t("Created by Raiser's Edge NXT"),
    [EFlavorsRaw.raisers_edge_constituent_updated]: t("Updated by Raiser's Edge NXT"),
    [EFlavorsRaw.raisers_edge_constituent_summary]: t("Raiser's Edge NXT Giving Summary updated"),
    [EFlavorsRaw.raisers_edge_gift_added]: t("Raiser's Edge NXT gift added"),
  };

  return getLabel(t, map, flavor);
};
