import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Fieldset, Form, SaveButton } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useUser } from '@feathr/extender/state';

function NotificationManagementPage(): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();

  function handleChangeEmailOptOut(): void {
    user.set({ email_opt_out: !user.get('email_opt_out', false) });
  }

  return (
    <Page title={t('Notification Settings')}>
      <Form
        actions={[
          <SaveButton
            disabled={!user.isAttributeDirty('email_opt_out')}
            key={'save'}
            method={'patch'}
            model={user}
          >
            {t('Save Changes')}
          </SaveButton>,
        ]}
        label={t('Settings')}
      >
        <Fieldset>
          <Checkbox
            helpText={t(
              'If this box is checked, you will receive email notifications at {{email}} in addition to in-app notifications.',
              { email: user.get('email') },
            )}
            label={t('Receive email notifications')}
            onChange={handleChangeEmailOptOut}
            value={!user.get('email_opt_out')}
          />
        </Fieldset>
      </Form>
    </Page>
  );
}

export default observer(NotificationManagementPage);
