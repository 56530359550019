import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import type { Template } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import { Fieldset, Form, Input, SaveButton } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { useRedirect } from '@feathr/hooks';

import BannerTemplateMetadataInputs from './BannerTemplateMetadataInputs';
import EmailTemplateMetadataInputs from './EmailTemplateMetadataInputs';
import PageTemplateMetadataInputs from './PageTemplateMetadataInputs';
import TemplateMergefieldDefaults from './TemplateMergefieldDefaults';

interface IProps {
  template: Template;
}

function TemplateMetadataForm({ template }: IProps): JSX.Element {
  const { Events } = useContext(StoresContext);
  const location = useLocation();
  const [redirect] = useRedirect();
  const { t } = useTranslation();

  const params = new URLSearchParams(location.search);
  const redirectParam = params.get('redirect');

  const projectId = template.get('event');
  const cls = template.get('_cls');
  const project = projectId ? Events.get(projectId) : undefined;

  const showPageMetadata = [
    TemplateClass.Page,
    TemplateClass.ReferralPage,
    TemplateClass.LandingPage,
  ].includes(cls);
  const showEmailMetadata = [
    TemplateClass.Email,
    TemplateClass.ReferralEmail,
    TemplateClass.PinpointEmail,
  ].includes(cls);
  const isBanner = template.isBanner;
  const isPinpoint = [TemplateClass.PinpointEmail].includes(cls);

  const nameInput = (
    <Input
      attribute={'name'}
      disabled={isBanner && template.get('bannersnack_enabled')}
      helpText={
        <p>
          {t(
            'This is the name of the template used in Feathr and in your reports. Make it descriptive and memorable so you can find this template later.',
          )}
        </p>
      }
      label={t('Name')}
      model={template}
      type={'text'}
    />
  );

  const saveParams = {
    disabled: showPageMetadata
      ? !template.isValid(['short_code'], true)
      : isBanner && template.get('bannersnack_enabled'),
    model: template,
  };

  const saveButton = (
    // TODO: Try using patch instead of save
    <SaveButton {...saveParams} key={'save'}>
      {t('Save metadata')}
    </SaveButton>
  );
  const actions = [saveButton];
  if (redirectParam) {
    function handleRedirect(): void {
      redirect();
    }

    const redirectButton = (
      <SaveButton {...saveParams} key={'redirect'} onSave={handleRedirect}>
        {t('Return to campaign')}
      </SaveButton>
    );
    actions.push(redirectButton);
  }

  // TODO: Add each form input section into their own card #2750
  return (
    <Form actions={actions} label={'Metadata'}>
      <Fieldset>
        {nameInput}
        {showPageMetadata && <PageTemplateMetadataInputs event={project} template={template} />}
        {showEmailMetadata && <EmailTemplateMetadataInputs template={template} />}
        {isPinpoint && <TemplateMergefieldDefaults template={template} />}
        {isBanner && <BannerTemplateMetadataInputs template={template} />}
      </Fieldset>
    </Form>
  );
}

export default observer(TemplateMetadataForm);
