import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardV2 as Card, Checkbox, SaveButton } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';

function MFAAuthentication(): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const isLoading = account.isPending;
  const isDisabled = isLoading || !account.isAttributeDirty('settings');

  function handleRequireMultiFactorChange(newValue?: boolean): void {
    account.setSetting('require_mfa', newValue);
  }

  return (
    <Card>
      <Card.Header
        description={t(
          'Enabling multi-factor authentication requires users to enter a one-time passcode sent to their email when logging in. During the login process, users have the option to remember the device for 30 days.',
        )}
        title={t('Multi-Factor Authentication')}
      />
      <Card.Content>
        <Checkbox
          label={t('Require multi-factor authentication (MFA) for all users')}
          onChange={handleRequireMultiFactorChange}
          value={account.getSetting('require_mfa', false)}
        />
      </Card.Content>
      <Card.Actions>
        <SaveButton disabled={isDisabled} method={'patch'} model={account} />
      </Card.Actions>
    </Card>
  );
}

export default observer(MFAAuthentication);
