import { EIntegrationTypes } from '@feathr/blackbox';
import { useAccount, useFlags } from '@feathr/extender/state';

interface IUseGoogleAdsPermissions {
  /** If true, a Google Ads customer must be connected to view the contents hidden by this flag */
  requireConnectedCustomer?: boolean;
}

/** Check to see if a Google Ads customer is connected and the `hasGoogleAds` flag is enabled */
function useGoogleAdsPermissions(
  { requireConnectedCustomer }: IUseGoogleAdsPermissions = { requireConnectedCustomer: true },
): boolean {
  // Keep the feature flag to selectively give accounts access to Google Ads
  const { hasGoogleAds = false } = useFlags();
  const account = useAccount();
  const showGoogleAds =
    hasGoogleAds ||
    account.isPlatformBase ||
    account.isPlatformEssential ||
    account.isPlatformAdvanced ||
    account.isPro;

  // If we don't require a connected customer, we can just check the flag.
  if (!requireConnectedCustomer) {
    return showGoogleAds;
  }

  const isConnected = account.get('integrations').includes(EIntegrationTypes.GoogleAds);

  return showGoogleAds && isConnected;
}

export default useGoogleAdsPermissions;
