import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import type { ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { EBREActivityMappings, IBlackbaudContactMapping } from '@feathr/blackbox';
import { EBlackbaudRaisersEdgeActivityDataTypes } from '@feathr/blackbox';
import { CardV2 as Card, EFontAwesomeKitIcon, FontAwesomeKitIcon, Input } from '@feathr/components';
import {
  RaisersEdgeActivityLabelMap,
  RaisersEdgeContactLabelMap,
} from '@feathr/extender/styles/blackbaud_raisers_edge';

import * as styles from './SyncMappingCard.css';

export type TActivityMapping = [keyof typeof EBREActivityMappings, boolean];

interface IProps {
  controls?: ReactNode;
  mapping: IBlackbaudContactMapping | TActivityMapping;
  mappings: IBlackbaudContactMapping[] | TActivityMapping[];
  target: ReactNode;
}

interface IContactMappingProps extends IProps {
  mapping: IBlackbaudContactMapping;
  mappings: IBlackbaudContactMapping[];
}

interface IActivityMappingProps extends IProps {
  mapping: TActivityMapping;
  mappings: TActivityMapping[];
}

function isContactMapping(
  mapping: IBlackbaudContactMapping | TActivityMapping,
): mapping is IBlackbaudContactMapping {
  return typeof mapping === 'object' && mapping !== null && 'key' in mapping;
}

function isContactProps(props: IProps): props is IContactMappingProps {
  return isContactMapping(props.mapping) && props.mappings.every(isContactMapping);
}

function isActivityMapping(
  mapping: IBlackbaudContactMapping | TActivityMapping,
): mapping is TActivityMapping {
  return (
    Array.isArray(mapping) && typeof mapping[0] === 'string' && typeof mapping[1] === 'boolean'
  );
}

function isActivityProps(props: IProps): props is IActivityMappingProps {
  return isActivityMapping(props.mapping) && props.mappings.every(isActivityMapping);
}

function SyncMappingCard(props: Readonly<IContactMappingProps>): JSX.Element;
function SyncMappingCard(props: Readonly<IActivityMappingProps>): JSX.Element;
function SyncMappingCard(
  props: Readonly<IContactMappingProps | IActivityMappingProps>,
): JSX.Element {
  const { t } = useTranslation();
  const { target, controls } = props;
  const value = (function (): string | undefined {
    if (isContactProps(props)) {
      const {
        mapping: { key },
        mappings,
      } = props;
      const options = mappings.map(({ key }) => ({
        id: key,
        name: RaisersEdgeContactLabelMap(t, key),
      }));
      return options.find((option) => option.id === key)?.name;
    }

    if (isActivityProps(props)) {
      const {
        mapping: [key],
        mappings,
      } = props;
      const options = mappings.map(([activity]) => ({
        id: activity,
        name: RaisersEdgeActivityLabelMap(t, activity),
        data_type: EBlackbaudRaisersEdgeActivityDataTypes[activity],
      }));

      return options.find((option) => option.id === key)?.name;
    }

    return undefined;
  })();

  return (
    <Card contentClassName={styles.card}>
      <Card.Content contentClassName={styles.content}>
        <div className={styles.actions}>
          <Input
            className={styles.input}
            disabled={true}
            prefix={<FontAwesomeKitIcon iconName={EFontAwesomeKitIcon.blackbaud} />}
            value={value}
          />
          <FontAwesomeIcon icon={faArrowRight} />
          {target}
        </div>
        {controls && <div className={styles.controls}>{controls}</div>}
      </Card.Content>
    </Card>
  );
}

export default observer(SyncMappingCard);
