import { faCheck, faDownload, faFileAlt, faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import type { TImporterState } from '@feathr/blackbox';
import type { Importer } from '@feathr/blackbox';
import { Avatar, Button, Card, Chip, Spinner, Time } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import * as styles from './EventImportCard.css';

import noImg from 'images/no-img.png';

interface IImportCardProps {
  importer: Importer;
}

const DownloadButton = ({ importer }: IImportCardProps): JSX.Element => {
  return (
    <Button
      className={styles.button}
      href={`${BLACKBOX_URL}events/${importer.get('event')}/partners/imports/${importer.id}`}
      prefix={<FontAwesomeIcon icon={faDownload} size={'2x'} />}
      target={'_blank'}
    >
      Download import file
    </Button>
  );
};

const FinishButton = ({ importer }: IImportCardProps): JSX.Element => {
  const history = useHistory();
  const localUrl = useLocalUrl();

  return (
    <Button
      className={styles.button}
      onClick={() => history.push(localUrl(importer.getItemUrl()))}
      prefix={<FontAwesomeIcon icon={faCheck} size={'2x'} />}
    >
      Finish
    </Button>
  );
};

function EventImportCard({ importer }: IImportCardProps): JSX.Element {
  const { Tags, Users } = useContext(StoresContext);
  const tags = Tags.list({
    filters: { is_archived__ne: true, collection: 'Partner', id__in: importer.get('tag_ids') },
    ordering: ['-date_created'],
  });
  if (tags.isPending) {
    return <Spinner />;
  }
  const created_by = Users.get(importer.get('created_by'));
  const state: TImporterState = importer.get('state');
  return (
    <Card className={styles.card} data-name={'event-import-card'}>
      <div className={styles.meta}>
        <Avatar image={created_by.get('picture') || noImg} size={'large'} />
        <div className={styles.subdued}>
          <Time format={TimeFormat.shortDate} timestamp={importer.get('date_created')} />
        </div>
      </div>
      <p>
        {created_by.name || 'An unknown user'} {state === 'draft' ? 'started' : 'uploaded'} "
        {importer.name}"
      </p>
      <div className={styles.tags}>
        <b>Tags:</b>
        {tags.models.length > 0 ? (
          tags.models.map((tag) => (
            <Chip key={tag.id} theme={'tag'}>
              {tag.name}
            </Chip>
          ))
        ) : (
          <span className={styles.subdued}>None</span>
        )}
      </div>
      <FontAwesomeIcon
        className={classNames(
          styles.icon,
          { [styles.draft]: state === 'draft' },
          { [styles.complete]: state === 'complete' },
        )}
        icon={{ draft: faFileAlt, pending: faSpinnerThird, complete: faCheck }[state]}
        spin={state === 'pending'}
      />
      <span>{{ draft: 'Draft', pending: 'Processing', complete: 'Ready' }[state]}</span>
      {state === 'draft' ? (
        <FinishButton importer={importer} />
      ) : (
        <DownloadButton importer={importer} />
      )}
    </Card>
  );
}

export default observer(EventImportCard);
