import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { AggregatedStat, Campaign, FacebookCampaignInsight } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { StatsCardV1 } from '@feathr/components';

import {
  ClicksStat,
  ConversionsStat,
  NewUsersStat,
  ReachableNewAudienceStat,
  ReachStat,
  UniqueClicksStat,
  ViewsStat,
} from '../Stats';

interface ICardProps {
  aggregatedStats: AggregatedStat;
  campaign?: Campaign;
  clicksLabel?: string;
  clicksTooltip?: string;
  conversions?: number;
  facebookCampaignStats?: FacebookCampaignInsight;
  isMonetization?: boolean;
  reachLabel?: string;
  reachTooltipText?: string;
  targetImpressions?: number;
  totalUsers?: number;
  uniqueClicksLabel?: string;
  uniqueClicksTooltipText?: string;
  viewsLabel?: string;
  viewsTooltip?: string;
  isEventOrFlight?: boolean;
}

function ActivitySummaryCard({
  campaign,
  clicksLabel,
  clicksTooltip,
  conversions,
  aggregatedStats,
  facebookCampaignStats,
  isMonetization,
  reachLabel,
  reachTooltipText,
  targetImpressions,
  totalUsers,
  uniqueClicksLabel,
  uniqueClicksTooltipText,
  viewsLabel,
  viewsTooltip,
  isEventOrFlight,
}: ICardProps): JSX.Element | null {
  // Email campaigns now use EmailActivitySummaryCard
  if (campaign?.isEmail) {
    return null;
  }

  const flavors = aggregatedStats.get('flavors', {});
  const clicks = (): number => {
    const {
      ad_click: adClick = 0,
      page_link_click: pageLinkClick = 0,
      email_link_click: emailLinkClick = 0,
    } = flavors;
    return campaign?.isGoogle
      ? aggregatedStats.get('num_clicks', 0)
      : adClick + pageLinkClick + emailLinkClick;
  };
  const newUsers = aggregatedStats.get('num_new_users_new') || 0;
  const views = campaign?.isGoogle
    ? aggregatedStats.get('num_views', 0)
    : (flavors.ad_view || 0) + (flavors.page_view || 0) + (flavors.email_view || 0);
  let users = 0;
  let uniqueClicks = 0;
  if (campaign?.isFacebook && facebookCampaignStats) {
    users = facebookCampaignStats.get('reach');
    uniqueClicks = facebookCampaignStats.get('unique_clicks');
  } else {
    users = aggregatedStats.get('num_users_new', 0);
    uniqueClicks = aggregatedStats.get('num_clicks_new', 0);
  }

  const reachableNewAudience = aggregatedStats.get('num_new_users_new_reachable', 0);

  return (
    <StatsCardV1 title={reportModuleLabels.includeActivitySummary}>
      <>
        {!campaign?.isGoogle && (
          <ReachStat
            isMonetization={isMonetization}
            label={reachLabel}
            tooltip={reachTooltipText}
            totalUsers={totalUsers}
            value={users}
          />
        )}
        {!isEventOrFlight && (
          <ViewsStat
            conversions={conversions}
            isMonetization={isMonetization}
            label={viewsLabel}
            targetImpressions={targetImpressions}
            tooltip={viewsTooltip}
            users={users}
            value={views}
          />
        )}
        {!campaign?.isGoogle && (
          <UniqueClicksStat
            label={uniqueClicksLabel}
            tooltip={uniqueClicksTooltipText}
            value={uniqueClicks}
          />
        )}
        {!isEventOrFlight && (
          <ClicksStat
            label={clicksLabel}
            tooltip={clicksTooltip}
            type={campaign?.get('_cls')}
            value={clicks()}
            views={views}
          />
        )}
        {campaign?.isGoogle && (
          <ConversionsStat goals={campaign.get('goals', [])} value={conversions ?? 0} />
        )}
        {newUsers > 0 && (
          <NewUsersStat
            tooltip={'The combined audience from all audience expansion campaigns in the Flight.'}
            value={newUsers}
          />
        )}
        {campaign?.isAwarenessCampaign && (
          <ReachableNewAudienceStat
            isMonetization={isMonetization}
            totalUsers={totalUsers}
            value={reachableNewAudience}
          />
        )}
      </>
    </StatsCardV1>
  );
}

export default observer(ActivitySummaryCard);
