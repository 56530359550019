import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { GoogleAdsSmartCampaign } from '@feathr/blackbox';
import { CardV2 as Card, Form, Input } from '@feathr/components';
import type { TValidateGrouped } from '@feathr/rachis';

import * as styles from './InformationStep.css';

interface IWebsiteStepProps {
  readonly campaign: GoogleAdsSmartCampaign;
}

export function validateInformationStep(campaign: GoogleAdsSmartCampaign): TValidateGrouped {
  return campaign.validate(['destination_url', 'name', 'business_name'], false, 'grouped').errors;
}

function InformationStep({ campaign }: IWebsiteStepProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Form label={t('Information')}>
        <Card name={'website'}>
          <Card.Header title={t('Information')} />
          <Card.Content addVerticalGap={true}>
            <Input
              attribute={'destination_url'}
              helpPlacement={'bottom'}
              helpText={t(
                "Consider what you're advertising, and enter the most relevant page of your website. This might be your homepage, or a more specific page.",
              )}
              label={t('Website')}
              model={campaign}
              required={true}
              type={'text'}
            />
            <Input
              attribute={'name'}
              helpPlacement={'bottom'}
              helpText={t('The name of the campaign also used by Google.')}
              label={t('Name')}
              model={campaign}
              required={true}
              type={'text'}
            />
            <Input
              attribute={'business_name'}
              helpPlacement={'bottom'}
              helpText={t('This helps us show your ad when people search for you by name.')}
              label={t('Business name')}
              model={campaign}
              required={true}
              type={'text'}
            />
          </Card.Content>
        </Card>
      </Form>
    </div>
  );
}

export default observer(InformationStep);
