import type { JSX } from 'react';
import React from 'react';

import type { Campaign, TrackedLinkCampaign } from '@feathr/blackbox';
import CampaignPage from '@feathr/extender/App/EventsPage/CampaignPage';
import ReportActions from '@feathr/extender/components/PageHeaderActions';
import { useReportExport } from '@feathr/extender/hooks';

import CampaignReportSection, { getCampaignReportConfig } from '../CampaignReportSection';
import TrackedLinkCampaignLinks from './TrackedLinkCampaignLinks';

interface IProps {
  campaign: Campaign;
  eventId: string;
}

function TrackedLinkCampaignPage({ campaign }: Readonly<IProps>): JSX.Element {
  const config = getCampaignReportConfig(campaign);
  const reportExportProps = useReportExport({ model: campaign, config });

  const actions = <ReportActions campaign={campaign} {...reportExportProps} />;

  return (
    <CampaignPage actions={actions} campaign={campaign} title={campaign.name}>
      <h3>Links</h3>
      <TrackedLinkCampaignLinks campaign={campaign as TrackedLinkCampaign} />
      <h3>Report</h3>
      <CampaignReportSection campaign={campaign} {...reportExportProps} />
    </CampaignPage>
  );
}

export default TrackedLinkCampaignPage;
