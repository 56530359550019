import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';
import { cssVar, moment, TimeFormat } from '@feathr/hooks';

const DurationColumn: IColumn<BaseCampaign> = {
  checkboxLabel: 'Duration',
  Header: TableColumnHeader({
    title: 'Duration',
  }),
  id: 'duration',
  sortable: false,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const dateStart = original.get('date_start');
          const dateStartMoment = moment.utc(dateStart, TimeFormat.isoDate);
          const dateEnd = original.get('date_end');
          const dateEndMoment = moment.utc(dateEnd, TimeFormat.isoDate);
          let widthNumber = 0;
          if (dateEnd && dateStart) {
            const totalDurationDays = moment.duration(dateEndMoment.diff(dateStartMoment)).asDays();
            const dateNowMoment = moment.utc();
            let elapsedDurationDays = 0;
            if (dateNowMoment.isAfter(dateStartMoment)) {
              elapsedDurationDays = moment.duration(dateNowMoment.diff(dateStartMoment)).asDays();
            }
            widthNumber = Math.min(Math.ceil((elapsedDurationDays / totalDurationDays) * 100), 100);
          }
          const width = `${widthNumber}%`;
          return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <div
                style={{
                  flex: '1',
                  width: '100%',
                  height: '32px',
                  backgroundColor: cssVar('--color-disabled'),
                  borderRadius: '4px',
                }}
              >
                <div
                  style={{
                    width,
                    height: '32px',
                    backgroundColor: cssVar('--color-brand-teal'),
                    borderRadius: '4px',
                  }}
                />
              </div>
            </div>
          );
        }}
      </Observer>
    );
  },
};

export default DurationColumn;
