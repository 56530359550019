import { observer } from 'mobx-react-lite';
import React from 'react';

import type { LandingPageCampaign } from '@feathr/blackbox';
import { DatePicker, Form, Input } from '@feathr/components';
import { moment, momentToDate, TimeFormat } from '@feathr/hooks';

interface IProps {
  campaign: LandingPageCampaign;
}

function LandingPageCampaignInfo({ campaign }: IProps) {
  function getMinDuration() {
    return moment.duration(1, 'days');
  }

  function handleChangeStartDate(newDate?: string) {
    const patch: { date_start: string; date_end?: string } = {
      date_start: moment(newDate).utc().format(TimeFormat.isoDate),
    };
    const newStartMoment = moment.utc(newDate, moment.ISO_8601);
    const newMinEndMoment = newStartMoment.add(getMinDuration());
    if (endMoment && newMinEndMoment.isAfter(endMoment)) {
      patch.date_end = newMinEndMoment.startOf('day').format();
    }
    campaign.set(patch);
  }

  function handleChangeEndDate(newDate?: string) {
    campaign.set({ date_end: moment(newDate).utc().format(TimeFormat.isoDate) });
  }

  const startMoment = campaign.get('date_start')
    ? moment.utc(campaign.get('date_start'), moment.ISO_8601).startOf('day')
    : undefined;
  const startDate = startMoment ? momentToDate(startMoment) : undefined;

  const endMoment = campaign.get('date_end')
    ? moment.utc(campaign.get('date_end'), moment.ISO_8601).startOf('day')
    : undefined;
  const endDate = endMoment ? momentToDate(endMoment) : undefined;

  return (
    <Form description={'Configure basic information about your campaign.'} label={'Campaign Info'}>
      <Input
        attribute={'name'}
        key={'name'}
        label={'Campaign Name'}
        model={campaign}
        type={'text'}
      />
      <DatePicker
        label={'Start Date'}
        onDateStrChange={handleChangeStartDate}
        selected={startDate}
      />
      <DatePicker
        helpPlacement={'bottom'}
        helpText={`This date range does not affect the page itself in any way.
          It is only used to set a default date range for the report.`}
        label={'End Date'}
        onDateStrChange={handleChangeEndDate}
        selected={endDate}
      />
    </Form>
  );
}

export default observer(LandingPageCampaignInfo);
