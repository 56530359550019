import React from 'react';
import { useTranslation } from 'react-i18next';

import { FieldDataType } from '@feathr/blackbox';
import type { IFormElementProps, ISelectProps } from '@feathr/components';
import { Select } from '@feathr/components';
import { fieldDataTypeLabel } from '@feathr/extender/styles/fieldDataType';

import DataTypeOption from './DataTypeOption';
import DataTypeSingleValue from './DataTypeSingleValue';

import * as styles from './DataTypeSelect.css';

interface IProps extends IFormElementProps {
  onChange: (newValue?: FieldDataType) => void;
  placeholder?: string;
  value?: FieldDataType;
}

function DataTypeSelect({ onChange, value, ...additionalProps }: IProps) {
  const { t } = useTranslation();

  const options = [
    { name: fieldDataTypeLabel(FieldDataType.str, t), id: FieldDataType.str },
    { name: fieldDataTypeLabel(FieldDataType.date, t), id: FieldDataType.date },
    { name: fieldDataTypeLabel(FieldDataType.bool, t), id: FieldDataType.bool },
    { name: fieldDataTypeLabel(FieldDataType.float, t), id: FieldDataType.float },
    { name: fieldDataTypeLabel(FieldDataType.list, t), id: FieldDataType.list },
  ];

  function handleChange(option: ISelectProps) {
    onChange(option.id as FieldDataType);
  }

  return (
    <Select
      {...additionalProps}
      className={styles.root}
      components={{ SingleValue: DataTypeSingleValue, Option: DataTypeOption }}
      onSelectSingle={handleChange}
      options={options}
      value={value ? options.find((o) => o.id === value) : undefined}
    />
  );
}

export default DataTypeSelect;
