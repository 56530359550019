import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { ConfirmModal, toast } from '@feathr/components';
import CampaignSummary from '@feathr/extender/App/EventsPage/CampaignSummary';

interface ICampaignEnableDisableModalProps {
  campaign: Campaign;
  isDisableModalOpen: boolean;
  closeDisableModal: () => void;
}

function CampaignEnableDisableModal({
  campaign,
  isDisableModalOpen,
  closeDisableModal,
}: Readonly<ICampaignEnableDisableModalProps>): JSX.Element {
  const { t } = useTranslation();

  async function handleDisable(): Promise<void> {
    try {
      campaign.set({
        is_enabled: false,
        is_archived: false,
        state: CampaignState.Stopped,
      });
      await campaign.save();
      toast(
        t('Campaign {{name}} state has been updated to {{state}}.', {
          name: campaign.name,
          state: CampaignState.Stopped,
        }),
      );

      closeDisableModal();
    } catch (error: any) {
      toast(
        t('Encountered an error while disabling:\n{{- errorMessage}}', {
          errorMessage: error.message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  return (
    <ConfirmModal
      confirmationPhrase={t('DISABLE')}
      confirmButtonText={t('Disable')}
      confirmButtonType={'danger'}
      onClose={closeDisableModal}
      onConfirm={handleDisable}
      opened={isDisableModalOpen}
      t={t}
      title={t('Disable Campaign')}
    >
      <CampaignSummary campaign={campaign} layout={'vertical'} showMinimal={true} />
    </ConfirmModal>
  );
}

export default CampaignEnableDisableModal;
