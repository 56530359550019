import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DripCampaign, EmailBaseCampaign } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import { FilterCarousel, Label } from '@feathr/components';
import EmailDetails from '@feathr/extender/components/EmailDetails';
import ReportActions from '@feathr/extender/components/PageHeaderActions';
import { useReportExport } from '@feathr/extender/hooks';
import { StoresContext } from '@feathr/extender/state';

import CampaignPage from '../../CampaignPage';
import CampaignReportSection, { getCampaignReportConfig } from '../CampaignReportSection';

import * as styles from './DripCampaignReportPage.css';

interface IProps {
  campaign: DripCampaign;
}

function DripCampaignReportPage({ campaign: parentCampaign }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { Campaigns } = useContext(StoresContext);
  const [activeCampaign, setActiveCampaign] = useState<'all' | EmailBaseCampaign['id']>('all');
  const showAllCampaigns = activeCampaign === 'all';
  const shouldShowDetails = !showAllCampaigns;
  const config = getCampaignReportConfig(parentCampaign);
  const reportExportProps = useReportExport({ model: parentCampaign, config });

  // TODO: Remove fixture when integration for Drip Campaign stats is implemented in https://github.com/Feathr/shrike/issues/3746.
  const cpns = [
    {
      name: 'Poopy Campaign 1',
      id: '66d8d11effa8b02fe83b321c',
      template_id: '66d8d123ffa8b02fe83b321d',
    },
    {
      name: 'Doopy Campaign 2',
      id: '66e9f89ee009353cc8422b87',
      template_id: '66e9fdc6033f70915f68e33d',
    },
    {
      name: 'Peepee Campaign 3',
      id: '66ce28f320418d9a085a23a5',
      template_id: '66d8d123ffa8b02fe83b321d',
    },
    {
      name: 'Weewee Campaign 4',
      id: '66ce286220418d9a085a2302',
      template_id: '66ce28f320418d9a085a23a6',
    },
    {
      name: 'Woowoo Campaign 5',
      id: '66c79ee43e3c9ebe6ae690b3',
      template_id: '66c79ef3547f0ab798daa9c5',
    },
    {
      name: 'Peepoo Campaign 6',
      id: '66bb60b2bda085cfad71e659',
      template_id: '66bb60b3bda085cfad71e65a',
    },
    {
      name: 'Doodoo Campaign 7',
      id: '66bb5dc5199cb2b9ccc416f8',
      template_id: '66bb5dc5199cb2b9ccc416f9',
    },
    {
      name: 'Doodee Campaign 8',
      id: '656f610ae2506bf9b8cc02b4',
      template_id: '656f61706988f91098c42bc0',
    },
  ];

  const cpnListResponse = cpns.map((cpn) =>
    Campaigns.create({
      ...cpn,
      _cls: CampaignClass.AutoPinpointEmail,
      subject: cpn.name,
      from_name: 'Poo Poo',
      from_address: 'poo@pee.com',
      date_send_start: '2024-04-21T00:00:00.000Z',
    }),
  ) as EmailBaseCampaign[];

  const items = cpnListResponse.map(({ name, id }) => ({
    content: name,
    id,
    onClick: function (): void {
      setActiveCampaign(id);
    },
  }));

  const carousel = (
    <div className={styles.carousel}>
      <FilterCarousel
        initialSlide={'prefix'}
        items={items}
        ordered={true}
        prefix={{
          content: t('All {{count}} Emails', { count: cpnListResponse.length }),
          onClick: () => setActiveCampaign('all'),
        }}
      />
    </div>
  );

  const childCampaign = shouldShowDetails
    ? cpnListResponse.find((c) => c.id === activeCampaign)
    : undefined;
  const index = cpnListResponse.findIndex((c) => c.id === activeCampaign);

  const actions = <ReportActions campaign={parentCampaign} {...reportExportProps} />;

  return (
    <CampaignPage
      actions={actions}
      campaign={parentCampaign}
      headerChildren={carousel}
      title={parentCampaign.name}
    >
      {shouldShowDetails && (
        <>
          <Label className={styles.label}>{`${index + 1}. ${childCampaign!.name}`}</Label>
          <EmailDetails campaign={childCampaign!} />
        </>
      )}
      <h3 className={shouldShowDetails ? styles.titleWithDetails : styles.title}>{t('Report')}</h3>
      <CampaignReportSection
        campaign={activeCampaign === 'all' ? parentCampaign : childCampaign!}
        {...reportExportProps}
      />
    </CampaignPage>
  );
}

export default observer(DripCampaignReportPage);
