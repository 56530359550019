import {
  faAnalytics,
  faArrowsRotate,
  faEdit,
  faFileDownload,
  faTrashAlt,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Segment } from '@feathr/blackbox';
import { ArchiveModalV1, ContextMenu, Input, MenuItem, ModalV1, toast } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';
import { RerunModal } from '@feathr/report_components';

import SegmentExportModalWrapper from '../SegmentExportModalWrapper';

interface IProps {
  className?: string;
  showRecalculationOption?: boolean;
  model: Segment;
}

function DataSegmentOptions({ className, model, showRecalculationOption }: IProps): JSX.Element {
  const { Conversions } = useContext(StoresContext);

  const localUrl = useLocalUrl();
  const [isExportModalOpen, toggleIsExportModalOpen] = useToggle(false);
  const [isRenameModalOpen, toggleIsRenameModalOpen] = useToggle(false);
  const [isArchiveModalOpen, toggleIsArchiveModalOpen] = useToggle(false);
  const [isRerunModalOpen, toggleRerunModalOpen] = useToggle(false);

  const { t } = useTranslation();

  async function handleRename(): Promise<void> {
    if (!model.isValid()) {
      return;
    }

    try {
      await model.save();
      toast(t('Group renamed to {{name}}.', { name: model.get('name') }), {
        type: 'success',
      });
      toggleIsRenameModalOpen();

      // If err is instance of Error, it should be of type any.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast(
        t('Something went wrong while saving your changes:\n{{error}}', {
          error: error.message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  async function handleConfirmRerun(): Promise<void> {
    const params = new URLSearchParams({
      segment: model.id,
    });
    try {
      await Conversions.rerun(params);
      model.set({ rerun: true });
    } catch (error) {
      toast(t('Something went wrong when trying to recalculate conversions.'), {
        type: ToastType.ERROR,
      });
      throw error;
    }
  }

  const isSegmentEditable = model.get('user_can_edit');

  return (
    <>
      <ContextMenu className={className}>
        <MenuItem
          link={localUrl(model.getItemUrl('analytics'))}
          prefix={<FontAwesomeIcon icon={faAnalytics} />}
        >
          {t('Analytics')}
        </MenuItem>
        <MenuItem
          onClick={toggleIsExportModalOpen}
          prefix={<FontAwesomeIcon icon={faFileDownload} />}
        >
          {t('Export')}
        </MenuItem>
        <MenuItem
          disabled={!isSegmentEditable}
          onClick={toggleIsRenameModalOpen}
          prefix={<FontAwesomeIcon icon={faEdit} />}
          tooltip={!isSegmentEditable && t('You do not have permission to rename this group.')}
        >
          {t('Rename')}
        </MenuItem>
        <MenuItem
          disabled={!isSegmentEditable}
          onClick={toggleIsArchiveModalOpen}
          prefix={<FontAwesomeIcon icon={faTrashAlt} />}
          tooltip={!isSegmentEditable && t('You do not have permission to archive this group.')}
        >
          {t('Archive')}
        </MenuItem>
        {showRecalculationOption && (
          <MenuItem
            onClick={toggleRerunModalOpen}
            prefix={<FontAwesomeIcon icon={faArrowsRotate} />}
          >
            {t('Recalculate conversions')}
          </MenuItem>
        )}
      </ContextMenu>
      {isExportModalOpen && (
        <SegmentExportModalWrapper segment={model} toggleIsModalOpen={toggleIsExportModalOpen} />
      )}
      {isRenameModalOpen && (
        <ModalV1
          confirmButtonText={t('Save')}
          controlled={true}
          onClose={toggleIsRenameModalOpen}
          onConfirm={handleRename}
          t={t}
          title={t('Rename Group')}
        >
          <Input attribute={'name'} model={model} type={'text'} />
        </ModalV1>
      )}
      {isArchiveModalOpen && (
        <ArchiveModalV1 model={model} onClose={toggleIsArchiveModalOpen} t={t} />
      )}
      {isRerunModalOpen && (
        <RerunModal
          context={'Group'}
          id={model.id}
          name={model.name}
          onClose={toggleRerunModalOpen}
          onConfirm={handleConfirmRerun}
        />
      )}
    </>
  );
}

export default DataSegmentOptions;
