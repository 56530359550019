import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { FieldCollection, type Flavors, type Person } from '@feathr/blackbox';
import { Fieldset } from '@feathr/components';
import BreadcrumbsTimeline from '@feathr/extender/components/BreadcrumbsTimeline';
import FlavorSelect from '@feathr/extender/components/FlavorSelect';
import { StoresContext } from '@feathr/extender/state';
import type { IWretchResponseError, IWretchResponseValid } from '@feathr/rachis';
import { isWretchError, useInfiniteList, wretch } from '@feathr/rachis';

import * as styles from './ActivityFeed.css';

interface IFlvrOptionsResponse extends Record<string, unknown> {
  person_flvrs: Array<keyof Flavors>;
}

interface IProps {
  person: Person;
}

function ActivityFeed({ person }: Readonly<IProps>): JSX.Element {
  const { Breadcrumbs } = useContext(StoresContext);
  const [infiniteBreadcrumbsList, clearInfiniteList] = useInfiniteList(Breadcrumbs);
  const { t } = useTranslation();
  const [flvrsOptions, setFlvrsOptions] = useState<Array<keyof Flavors> | undefined>([]);
  const [flavors, setFlavors] = useState<Array<keyof Flavors>>([]);

  useEffect(() => {
    const getFilteredFlvrOptions = async (): Promise<
      IWretchResponseValid<IFlvrOptionsResponse> | IWretchResponseError
    > => {
      return wretch<IFlvrOptionsResponse>(Breadcrumbs.url('person.flavors', person.id), {
        headers: Breadcrumbs.getHeaders(),
        method: 'GET',
      });
    };
    (async (): Promise<void> => {
      const response = await getFilteredFlvrOptions();
      if (isWretchError(response)) {
        setFlvrsOptions(undefined);
      } else {
        setFlvrsOptions(response.data.person_flvrs);
      }
    })();
  }, [Breadcrumbs, person.id]);

  function handleFlavorsChange(newFlavors?: Array<keyof Flavors>): void {
    if (newFlavors) {
      setFlavors(newFlavors);
    }
    clearInfiniteList();
  }

  function handleFlavorsClear(): void {
    setFlavors([]);
    clearInfiniteList();
  }

  function next(): void {
    breadcrumbs.fetchMore();
  }

  // This endpoint does not allow anything other than filters and pagination.
  const breadcrumbs = infiniteBreadcrumbsList(
    {
      filters: flavors.length
        ? {
            flvrs: flavors,
          }
        : undefined,
      pagination: {
        page: 0,
        page_size: 10,
      },
    },
    { url: Breadcrumbs.url('person', person.id) },
  );

  if (!breadcrumbs.isPending) {
    runInAction(() => {
      breadcrumbs.models.forEach((model) => model.set({ per_id: person.id }));
    });
  }

  return (
    <Fieldset className={styles.root} name={'activity'}>
      <FlavorSelect
        isMulti={true}
        label={t('Filters')}
        limitOptionsTo={flvrsOptions}
        onChange={handleFlavorsChange}
        onClear={handleFlavorsClear}
      />
      <InfiniteScroll
        className={styles.infinite}
        dataLength={breadcrumbs.models.length}
        hasMore={breadcrumbs.pagination.page < breadcrumbs.pagination.pages - 1}
        // Don't use the built in loader.
        loader={<></>}
        next={next}
        scrollableTarget={'legacyScrollElement'}
      >
        <BreadcrumbsTimeline
          breadcrumbs={breadcrumbs.models}
          context={FieldCollection.Person}
          hasViewRawData={true}
          isLoading={breadcrumbs.isPending}
        />
      </InfiniteScroll>
    </Fieldset>
  );
}

export default observer(ActivityFeed);
