import { useCallback } from 'react';

import { isExternalUrl } from '@feathr/hooks';

import { useAccount } from '.';

function useLocalUrl(): (url: string) => string {
  const account = useAccount();

  const localUrl = useCallback(
    (url) => {
      // If the url is external, don't prefix it
      if (isExternalUrl(url)) {
        return url;
      }

      // Remove leading slash
      const cleanUrl = url.startsWith('/') ? url.slice(1) : url;

      // If the url is already prefixed with the account id, don't prefix it again
      return account && !cleanUrl.startsWith(account.id)
        ? `/${account.id}/${cleanUrl}`
        : `/${cleanUrl}`;
    },
    [account],
  );

  return localUrl;
}

export default useLocalUrl;
