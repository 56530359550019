import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import type { IEvent, TAttributionModel } from '@feathr/blackbox';
import Page from '@feathr/extender/App/Page';
import ReportActions from '@feathr/extender/components/PageHeaderActions';
import ReportWrapper from '@feathr/extender/components/ReportWrapper';
import { useReportExport } from '@feathr/extender/hooks';
import { StoresContext } from '@feathr/extender/state';
import { EventReport } from '@feathr/report_components';

interface IConfig {
  attributionModel: TAttributionModel;
  includeActivitySummary: boolean;
  includeAdActivity: boolean;
  includeAdPerformance: boolean;
  includeConversionsTable: boolean;
  includeEmailActivity: boolean;
  includeEmailPerformance: boolean;
  includeGoogleActivity: boolean;
  includePageActivity: boolean;
  includeROI: boolean;
  includeSpendPerformance: boolean;
}

const config: IConfig = {
  attributionModel: 'full',
  includeActivitySummary: true,
  includeAdActivity: true,
  includeAdPerformance: true,
  includeConversionsTable: false,
  includeEmailActivity: true,
  includeGoogleActivity: true,
  includeEmailPerformance: true,
  includePageActivity: true,
  includeROI: false,
  includeSpendPerformance: true,
};

function EventReportPage(): JSX.Element {
  const { t } = useTranslation();
  const { eventId } = useParams<{ eventId: string }>();
  const { Events } = useContext(StoresContext);
  const event = Events.get(eventId);
  const reportExportProps = useReportExport<IEvent, IConfig>({ model: event, config });

  const actions = <ReportActions project={event} {...reportExportProps} />;

  return (
    <Page actions={actions} loading={event.isPending} title={event.name} width={'wide'}>
      <h3>{t('Report')}</h3>
      <ReportWrapper model={event} Report={EventReport} {...reportExportProps} />
    </Page>
  );
}

export default observer(EventReportPage);
