import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader, Time } from '@feathr/components';
import { useUser } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const DateEndColumn: IColumn<BaseCampaign> = {
  id: 'date_end',
  checkboxLabel: 'End Date',
  Header: TableColumnHeader({
    sortType: 'numeric',
    title: 'End',
  }),
  headerClassName: tableStyles.sort,
  className: tableStyles.cell,
  width: 110,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          const user = useUser();
          const userTimezone = user.get('timezone');
          const value = original.isEmail ? original.get('date_send_end') : original.get('date_end');

          return value ? (
            <Time
              format={TimeFormat.shortDate}
              showTimezone={false}
              timestamp={value}
              timezone={userTimezone}
            />
          ) : (
            <>-</>
          );
        }}
      </Observer>
    );
  },
};

export default DateEndColumn;
