import { faCircleInfo, faRotate } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { Button, Tooltip } from '@feathr/components';

import type { IBaseIntegrationCardProps } from './BaseIntegrationsCard';
import BaseIntegrationsCard from './BaseIntegrationsCard';

/**
 * A Card element used on the Integrations page to render internal and external integrations. A card can have three states:
 * - A zapier card with actions, the actions provide links
 * - An external card without actions, the whole card links externally
 * - An internal card without actions, the whole card links internally
 */
function IntegrationsCard({
  item,
  externalLink,
  disabled = false,
  ...additionalProps
}: IBaseIntegrationCardProps): JSX.Element {
  // Used to type narrow, IIntegrationCardProps has no attribute helpDesk
  const isZapierCard = 'helpDesk' in item;

  const hasActions = isZapierCard ? !!(item.helpDesk && item.integrationUrl) : false;

  if (isZapierCard && hasActions && externalLink) {
    return (
      <BaseIntegrationsCard
        {...additionalProps}
        actions={[
          <Tooltip key={'help-desk'} title={'Integration Guide'}>
            <Button href={item.helpDesk} target={'_blank'} type={'naked'}>
              <FontAwesomeIcon icon={faCircleInfo} />
            </Button>
          </Tooltip>,
          <Tooltip key={'feathr-external'} title={'Integration Options'}>
            <Button href={item.integrationUrl} target={'_blank'} type={'naked'}>
              <FontAwesomeIcon icon={faRotate} />
            </Button>
          </Tooltip>,
        ]}
        disabled={disabled}
        externalLink={externalLink}
        href={item.url}
        item={item}
      />
    );
  } else if (externalLink) {
    return (
      <BaseIntegrationsCard
        {...additionalProps}
        disabled={disabled}
        externalLink={externalLink}
        href={item.url}
        item={item}
      />
    );
  }

  return (
    <BaseIntegrationsCard {...additionalProps} disabled={disabled} item={item} link={item.url} />
  );
}

export default observer(IntegrationsCard);
