import classNames from 'classnames';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import type { ActionMeta, ValueType } from 'react-select';

import type { IPartner, IPinpointPartnerMessage, PinpointPartnerMessage } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import { AsyncSelect } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './MessageSelect.css';

interface IPinpointPartnerMessageSelectProps {
  className?: string;
  eventId: string;
  id?: string;
  onClear: () => void;
  placeholder?: string;
}

export interface IPinpointPartnerMessageSelectMultiProps
  extends IPinpointPartnerMessageSelectProps {
  isMulti: true;
  onChange: (value: string[]) => void;
  value?: string[];
}

export interface IPinpointPartnerMessageSelectSingleProps
  extends IPinpointPartnerMessageSelectProps {
  isMulti?: false;
  onChange: (value: string) => void;
  value?: string;
}

function MessageSelect({
  className,
  eventId,
  id,
  isMulti = false,
  onChange,
  onClear,
  placeholder,
  value,
}: IPinpointPartnerMessageSelectMultiProps | IPinpointPartnerMessageSelectSingleProps) {
  const { Campaigns } = useContext(StoresContext);

  async function loadOptions(inputValue: string) {
    const data = Campaigns.list({
      filters: {
        _cls: CampaignClass.PinpointPartnerMessage,
        name__icontains: inputValue || undefined,
        _parent: eventId,
      },
    });
    await when(() => !data.isPending);
    return data.models
      .filter((model) => {
        if (Array.isArray(value)) {
          return !value.includes(model.id);
        }
        return !value || value !== model.id;
      })
      .map((model) => {
        return {
          ...model.toJS(),
          name: (model as PinpointPartnerMessage).getItemLabel(),
        };
      });
  }

  function handleChange(
    newValue: ValueType<IPinpointPartnerMessage>,
    action: ActionMeta<IPinpointPartnerMessage>,
  ) {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (Array.isArray(newValue)) {
        if (newValue.length) {
          (onChange as IPinpointPartnerMessageSelectMultiProps['onChange'])(
            (newValue as IPartner[]).map((v) => v.id!),
          );
        } else {
          onClear();
        }
      } else if (newValue) {
        (onChange as IPinpointPartnerMessageSelectSingleProps['onChange'])(
          (newValue as IPinpointPartnerMessage).id!,
        );
      } else {
        onClear();
      }
    }
  }

  return (
    <AsyncSelect
      defaultOptions={true}
      id={id}
      isClearable={true}
      isMulti={isMulti}
      loadOptions={loadOptions}
      noOptionsMessage={({ inputValue }) =>
        inputValue.length ? 'No results' : 'Start typing the name of a partner message...'
      }
      onChange={handleChange}
      placeholder={placeholder}
      value={
        Array.isArray(value)
          ? value.map((valueId) => {
              const msg = Campaigns.get(valueId) as PinpointPartnerMessage;
              return { ...msg.toJS(), name: msg.getItemLabel() };
            })
          : value
          ? {
              ...(Campaigns.get(value) as PinpointPartnerMessage).toJS(),
              name: (Campaigns.get(value) as PinpointPartnerMessage).getItemLabel(),
            }
          : undefined
      }
      wrapperClassName={classNames(className, styles.root)}
    />
  );
}

export default observer(MessageSelect);
