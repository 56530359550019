import { faPlus, faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TFunction } from 'i18next';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  GoogleAdsCreative,
  GoogleAdsSmartCampaign,
  IGoogleAdsAdText,
  IGoogleAdsCreative,
  IGoogleAdsSuggestedAdText,
} from '@feathr/blackbox';
import { CreativeClass, EIntegrationTypes } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import {
  Button,
  CardV2 as Card,
  Checkbox,
  ConfirmModalV1,
  Form,
  Input,
  Select,
  Spinner,
  toast,
} from '@feathr/components';
import { StoresContext, useAccount } from '@feathr/extender/state';
import { objectId, shuffleArray, useToggle } from '@feathr/hooks';
import type { ListResponse, TValidateGrouped } from '@feathr/rachis';
import { validate } from '@feathr/rachis';

import AdPreviewCard from '../AdPreviewCard';
import KeywordButton from '../KeywordsStep/KeywordButton';
import InputList from './InputList';

import * as styles from './AdTextStep.css';

interface IAdTextStepProps {
  readonly campaign: GoogleAdsSmartCampaign;
  readonly creatives: ListResponse<GoogleAdsCreative>;
  readonly showCallButton: boolean;
  readonly toggleShowCallButton: () => void;
}

interface IGetAdTextFromTypeReturn {
  section: 'headlines' | 'descriptions';
  selectedAdText: IGoogleAdsAdText[];
}

export function validateAdTextStep(
  campaign: GoogleAdsSmartCampaign,
  showCallButton: boolean,
  creatives: ListResponse<GoogleAdsCreative>,
): TValidateGrouped {
  const phoneErrors: Array<string | undefined> = validate.single(campaign.get('phone_number'), {
    format: {
      pattern: /^\d+$/,
      message: '^Phone number must contain numbers only',
    },
    presence: {
      allowEmpty: false,
      message: '^Phone number must not be blank',
    },
  });

  const creative = creatives.models.find((item) => item.get('_cls') === CreativeClass.GoogleAd);
  const mappedHeadlines = creative?.get('headlines').map(({ text }) => text);
  const mappedDescriptions = creative?.get('descriptions').map(({ text }) => text);

  const headlineErrors: Array<string | undefined> = validate.single(mappedHeadlines, {
    length: {
      minimum: 3,
      maximum: 15,
      tooShort: '^Must provide at least %{count} headlines',
      tooLong: '^Must provide at most %{count} headlines',
    },
    list: {
      presence: {
        allowEmpty: false,
        message: '^Headlines must not be blank',
      },
    },
    duplicates: {
      maximum: 0,
      message: '^Must provide unique headlines',
    },
  });

  const descriptionErrors: Array<string | undefined> = validate.single(mappedDescriptions, {
    length: {
      minimum: 2,
      maximum: 4,
      tooShort: '^Must provide at least %{count} descriptions',
      tooLong: '^Must provide at most %{count} descriptions',
    },
    list: {
      presence: {
        allowEmpty: false,
        message: '^Descriptions must not be blank',
      },
    },
    duplicates: {
      maximum: 0,
      message: '^Must provide unique descriptions',
    },
  });

  const errors = {
    phone_number: showCallButton ? phoneErrors : undefined,
    headlines: headlineErrors?.length ? headlineErrors.filter(Boolean) : undefined,
    descriptions: descriptionErrors?.length ? descriptionErrors.filter(Boolean) : undefined,
  };

  return errors as TValidateGrouped;
}

const phoneCountryCodes: ISelectOption[] = [
  {
    id: 'US',
    name: 'US',
  },
  {
    id: 'CA',
    name: 'CA',
  },
  {
    id: 'GB',
    name: 'GB',
  },
];

const sectionConfig = {
  description: {
    maxItems: 4,
    minItems: 2,
    maxLength: 90,
  },
  headline: {
    maxItems: 15,
    minItems: 3,
    maxLength: 30,
  },
};

type TAdTextSection = 'headline' | 'description';

function getSection(t: TFunction, type?: TAdTextSection): string {
  if (!type) {
    throw new Error('Type is required');
  }

  const sections = {
    headline: t('headline'),
    description: t('description'),
  };

  return sections[type];
}

function AdTextStep({
  campaign,
  creatives,
  toggleShowCallButton,
  showCallButton,
}: IAdTextStepProps): JSX.Element {
  const account = useAccount();
  const { Creatives } = useContext(StoresContext);
  const { t } = useTranslation();
  const [isDeleteModalOpen, toggleDeleteModalOpen] = useToggle(false);
  const [isSuggestModalOpen, toggleSuggestModalOpen] = useToggle(false);
  const [countryCode, setCountryCode] = useState<string>(
    campaign.get('phone_number_country_code') ?? phoneCountryCodes[0].id,
  );
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentSection, setCurrentSection] = useState<TAdTextSection | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(campaign.isPending || creatives.isPending);

  const selectedKeywords = campaign.get('keyword_themes') ?? [];
  const adTextCreative = creatives.models.find(
    (item) => item.get('_cls') === CreativeClass.GoogleAd,
  );
  const selectedHeadlines = adTextCreative?.get('headlines') ?? [];
  const selectedDescriptions = adTextCreative?.get('descriptions') ?? [];

  // Suggest ad text on the initial render if a creative does not already exist
  useEffect(() => {
    if (adTextCreative === undefined && !creatives.isPending) {
      suggestAdText();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatives.isPending]);

  // Set the country code to the default if show call button is toggled
  useEffect(() => {
    if (showCallButton) {
      campaign.set({ phone_number_country_code: countryCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCallButton]);

  async function addNewCreative({
    headlines = [],
    descriptions = [],
  }: Partial<Pick<IGoogleAdsCreative, 'headlines' | 'descriptions'>>): Promise<void> {
    // Push empty headlines into the array to match the minimum number of items
    while (headlines.length < sectionConfig.headline.minItems) {
      headlines.push({ text: '', key: objectId() });
    }
    // Push empty descriptions into the array to match the minimum number of items
    while (descriptions.length < sectionConfig.description.minItems) {
      descriptions.push({ text: '', key: objectId() });
    }

    const newCreative = Creatives.create({
      _cls: CreativeClass.GoogleAd,
      parent: campaign.id,
      is_enabled: true,
      account: account.id,
      is_feathr_approved: true,
      integrations: [EIntegrationTypes.GoogleAds],
      headlines,
      descriptions,
    });

    const response = (await Creatives.add(newCreative)) as GoogleAdsCreative;
    runInAction(() => {
      creatives.models.push(response);
    });
  }

  async function suggestAdText(type?: TAdTextSection): Promise<void> {
    try {
      setIsLoading(true);
      const suggestions = await campaign.suggest<IGoogleAdsSuggestedAdText>({
        type: 'ad',
        businessName: campaign.get('business_name'),
        destinationUrl: campaign.get('destination_url'),
        keywordThemes: campaign.get('keyword_themes'),
      });
      const { headlines, descriptions } = suggestions;

      /*
       * Shuffle the suggestions to get a random order. It's possible to get more
       * headlines or descriptions than we allow to be displayed at once.
       */
      const shuffledHeadlines = shuffleArray(headlines.filter(({ text }) => text !== ''));
      const shuffledDescriptions = shuffleArray(descriptions.filter(({ text }) => text !== ''));

      const maxHeadlines = shuffledHeadlines.slice(0, sectionConfig.headline.maxItems);
      const maxDescriptions = shuffledDescriptions.slice(0, sectionConfig.description.maxItems);

      // If we already have an adTextCreative, update it with the new suggestions
      if (adTextCreative) {
        if (type === 'headline') {
          adTextCreative.set({ headlines: maxHeadlines });
        }
        if (type === 'description') {
          adTextCreative.set({ descriptions: maxDescriptions });
        }
      } else {
        // If there are no creatives associated with the campaign, create one
        addNewCreative({ headlines: maxHeadlines, descriptions: maxDescriptions });
      }
    } catch (error) {
      toast(t('Something went wrong: {{- error}}', { error }), {
        type: ToastType.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  }

  /* Handlers for buttons that trigger modals */

  // Click of the delete button for the headline or description section
  function handleDeleteAdText(type: TAdTextSection): (index: number) => void {
    return function (index: number): void {
      setCurrentSection(type);
      setCurrentIndex(index);
      toggleDeleteModalOpen();
    };
  }

  // Click of the suggest button for the headline or description section
  function handleOnClickSuggest(type: TAdTextSection): () => void {
    return function (): void {
      setCurrentSection(type);
      toggleSuggestModalOpen();
    };
  }

  // Click of the confirmation button in the suggest modal for a headline or description
  function handleOnConfirmSuggest(type: TAdTextSection): () => Promise<void> {
    return async function (): Promise<void> {
      setCurrentSection(type);
      await suggestAdText(type);
      toggleSuggestModalOpen();
    };
  }

  /* Handlers for buttons change state */

  function getSelectedAdTextFromType(type: TAdTextSection): IGetAdTextFromTypeReturn {
    const section = `${type}s` as const;
    const selectedAdText = type === 'headline' ? selectedHeadlines : selectedDescriptions;

    return { selectedAdText: selectedAdText, section };
  }

  // Click of the add button for the headline or description section
  function handleAddAdText(type: TAdTextSection): () => void {
    return function () {
      const { selectedAdText, section } = getSelectedAdTextFromType(type);
      const selectedPlusOneEmpty = [...selectedAdText, { key: objectId(), text: '' }];

      adTextCreative?.set({
        [section]: selectedPlusOneEmpty,
      });
    };
  }

  // Click of the confirm button in the delete modal for a headline or description
  function handleConfirmDelete(type: TAdTextSection): () => void {
    return function () {
      if (!type) {
        throw new Error('Type is required');
      }

      const { selectedAdText, section } = getSelectedAdTextFromType(type);
      const filteredAdText = selectedAdText.filter((_, i) => i !== currentIndex);

      adTextCreative?.set({
        [section]: filteredAdText,
      });

      toggleDeleteModalOpen();
    };
  }

  const isAddHeadlinesButtonDisabled = selectedHeadlines?.length >= sectionConfig.headline.maxItems;
  const isAddDescriptionsButtonDisabled =
    selectedDescriptions?.length >= sectionConfig.description.maxItems;

  // Handles changing the content inside a headline or description input
  function handleOnChangeAdText(type: TAdTextSection): (index: number, value: string) => void {
    return function (index: number, value: string) {
      const { selectedAdText, section } = getSelectedAdTextFromType(type);
      const newAdText = [...selectedAdText];

      runInAction(() => {
        newAdText[index].text = value;

        adTextCreative?.set({
          [section]: newAdText,
        });

        adTextCreative?.setAttributeDirty(section);
      });
    };
  }

  /* Handlers for phone inputs and tooltips */

  function handleCountryCodeChange(option: ISelectOption): void {
    setCountryCode(option.id);
    campaign.set({ phone_number_country_code: option.id });
  }

  function handleShowCallButton(): void {
    toggleShowCallButton();
    // Clear the phone number when the call button is toggled off
    campaign.set({ phone_number: undefined, phone_number_country_code: undefined });
  }

  function getSuggestTooltip(section: TAdTextSection): string {
    return t(
      'Replace the existing {{section}}s with suggestions from Google, using the provided keywords.',
      {
        section,
      },
    );
  }

  const selectedCountryCodeOption = phoneCountryCodes.find((option) => option.id === countryCode);
  const positiveKeywords = selectedKeywords.filter((keyword) => !keyword.negative);
  const negativeKeywords = selectedKeywords.filter((keyword) => keyword.negative);

  return (
    <Form
      description={t(
        'The more headlines and description lines you provide, the more combinations will be tested to learn which perform the best over time.',
      )}
      label={t('Ad Text')}
      title={t("Now it's time to write your ad")}
      width={'wide'}
    >
      <div className={styles.root}>
        <div className={styles.left}>
          <Card name={'selected_keywords'}>
            <Card.Header title={t('Selected Keyword Themes')} />
            <Card.Content>
              {positiveKeywords.length ? (
                positiveKeywords.map((keyword) => (
                  <KeywordButton
                    key={keyword.display_name + keyword?.resource_name}
                    keyword={keyword}
                  />
                ))
              ) : (
                <span>{t('No keywords selected')}</span>
              )}
            </Card.Content>
          </Card>
          {!!negativeKeywords.length && (
            <Card name={'selected_negative_keywords'}>
              <Card.Header title={t('Selected Negative Keywords')} />
              <Card.Content>
                {negativeKeywords.length ? (
                  negativeKeywords.map((keyword) => (
                    <KeywordButton
                      key={keyword.display_name + keyword?.resource_name}
                      keyword={keyword}
                    />
                  ))
                ) : (
                  <span>{t('No keywords selected')}</span>
                )}
              </Card.Content>
            </Card>
          )}

          <Card name={'headlines'}>
            <Card.Header
              description={t(
                'Adding {{count}} or more {{section}} will help your ad performance.',
                {
                  count: sectionConfig.headline.minItems,
                  section: 'headline',
                },
              )}
              title={t('Headlines')}
            >
              <Button
                key={'headlines-suggest'}
                name={'headlines-suggest'}
                onClick={handleOnClickSuggest('headline')}
                prefix={<FontAwesomeIcon icon={faSparkles} />}
                tooltip={getSuggestTooltip('headline')}
              >
                {t('Suggest')}
              </Button>
            </Card.Header>
            <Card.Content>
              {/* Show the loader if loading and the current section is headline or undefined. We don't want to trigger the loading
              state for the opposite section when we suggest new ad text for an individual section. */}
              {isLoading && currentSection !== 'description' ? (
                <Spinner />
              ) : (
                <InputList
                  items={selectedHeadlines}
                  label={t('Headline')}
                  maxItems={sectionConfig.headline.maxItems}
                  maxLength={sectionConfig.headline.maxLength}
                  minItems={sectionConfig.headline.minItems}
                  onChange={handleOnChangeAdText('headline')}
                  onRemove={handleDeleteAdText('headline')}
                  removeTooltip={t('There is a minimum of {{count}} {{section}}.', {
                    count: sectionConfig.headline.minItems,
                    section: 'headline',
                  })}
                />
              )}
            </Card.Content>
            <Card.Actions>
              <Button
                disabled={isAddHeadlinesButtonDisabled}
                key={'add-headline'}
                name={'add-headline'}
                onClick={handleAddAdText('headline')}
                prefix={<FontAwesomeIcon icon={faPlus} />}
                tooltip={
                  isAddHeadlinesButtonDisabled &&
                  t(
                    'A maximum of {{count}} {{section}} can be added. Remove some before you can add more.',
                    {
                      count: sectionConfig.headline.maxItems,
                      section: 'headline',
                    },
                  )
                }
              >
                {t('Add {{section}}', { section: 'headline' })}
              </Button>
            </Card.Actions>
          </Card>
          <Card name={'descriptions'}>
            <Card.Header
              description={t(
                'Adding {{count}} or more {{section}} will help your ad performance.',
                { count: sectionConfig.description.minItems, section: 'description' },
              )}
              title={t('Descriptions')}
            >
              <Button
                key={'descriptions-suggest'}
                name={'descriptions-suggest'}
                onClick={handleOnClickSuggest('description')}
                prefix={<FontAwesomeIcon icon={faSparkles} />}
                tooltip={getSuggestTooltip('description')}
              >
                {t('Suggest')}
              </Button>
            </Card.Header>
            <Card.Content>
              {/* Show the loader if loading and the current section is description or undefined. We don't want to trigger the loading
              state for the opposite section when we suggest new ad text for an individual section. */}
              {isLoading && currentSection !== 'headline' ? (
                <Spinner />
              ) : (
                <InputList
                  items={selectedDescriptions}
                  label={t('Description')}
                  maxItems={sectionConfig.description.maxItems}
                  maxLength={sectionConfig.description.maxLength}
                  minItems={sectionConfig.description.minItems}
                  onChange={handleOnChangeAdText('description')}
                  onRemove={handleDeleteAdText('description')}
                  removeTooltip={t('There is a minimum of {{count}} {{section}}.', {
                    count: sectionConfig.description.minItems,
                    section: 'description',
                  })}
                  type={'textarea'}
                />
              )}
            </Card.Content>
            <Card.Actions>
              <Button
                disabled={isAddDescriptionsButtonDisabled}
                key={'add-description'}
                name={'add-description'}
                onClick={handleAddAdText('description')}
                prefix={<FontAwesomeIcon icon={faPlus} />}
                tooltip={
                  isAddDescriptionsButtonDisabled &&
                  t(
                    'A maximum of {{count}} {{section}} can be added. Remove some before you can add more.',
                    {
                      count: sectionConfig.headline.maxItems,
                      section: 'description',
                    },
                  )
                }
              >
                {t('Add {{section}}', { section: 'description' })}
              </Button>
            </Card.Actions>
          </Card>
          <Card name={'phone-number'}>
            <Card.Header title={t('Phone number')} />
            <Card.Content contentClassName={styles.phoneWrapper}>
              <Checkbox
                label={t('Show a call button in your ad')}
                onChange={handleShowCallButton}
                value={showCallButton}
              />
              {showCallButton && (
                <Input
                  attribute={'phone_number'}
                  label={t('Phone number')}
                  model={campaign}
                  prefix={
                    <Select
                      className={styles.phoneCountryCode}
                      name={'phone_country_code'}
                      onSelectSingle={handleCountryCodeChange}
                      options={phoneCountryCodes}
                      value={selectedCountryCodeOption}
                    />
                  }
                  prefixClassName={styles.phonePrefix}
                />
              )}
            </Card.Content>
          </Card>
        </div>
        <div className={styles.right}>
          {isLoading || creatives.isPending ? (
            <Spinner />
          ) : (
            <AdPreviewCard
              businessName={campaign.get('business_name')}
              descriptions={selectedDescriptions.map(({ text }) => text)}
              headlines={selectedHeadlines.map(({ text }) => text)}
              showCallButton={showCallButton}
              websiteUrl={campaign.get('destination_url')}
            />
          )}
        </div>
      </div>
      {isSuggestModalOpen && (
        <ConfirmModalV1
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Suggest')}
          description={t(
            'Suggesting new {{section}}s from Google will replace the previously provided {{section}}s.',
            {
              section: getSection(t, currentSection),
            },
          )}
          onClose={toggleSuggestModalOpen}
          onConfirm={handleOnConfirmSuggest(currentSection!)}
          t={t}
          title={t('Are you sure you want to suggest new {{section}}s?', {
            section: getSection(t, currentSection),
          })}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmModalV1
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Delete')}
          confirmButtonType={'danger'}
          description={t('Are you sure you want to delete this {{section}}?', {
            section: getSection(t, currentSection),
          })}
          onClose={toggleDeleteModalOpen}
          onConfirm={handleConfirmDelete(currentSection!)}
          t={t}
          title={t('Delete {{section}}', { section: getSection(t, currentSection) })}
        />
      )}
    </Form>
  );
}

export default observer(AdTextStep);
