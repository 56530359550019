import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Nav, NavItem } from '@feathr/components';
import { useLocalUrl, useRole } from '@feathr/extender/state';

function EventSettingsNav(): JSX.Element {
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const { hasBilling } = useRole();
  const { t } = useTranslation();

  return (
    <Nav indented={true} label={'Project settings navigation'} type={'vertical'}>
      <NavItem to={localUrl(`/projects/${eventId}/settings/info`)}>
        {t('Project Information')}
      </NavItem>
      {hasBilling && (
        <NavItem to={localUrl(`/projects/${eventId}/settings/billing`)}>{t('Billing')}</NavItem>
      )}
    </Nav>
  );
}

export default EventSettingsNav;
