import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { SaveButton, Tab } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import AttributionWindowsCard from '@feathr/extender/components/AttributionWindowsCard/AttributionWindowsCard';
import { useAccount, useActionBar, useRole } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import AttributionOptions from '../ConversionsPage/AttributionOptions';
import ConversionsOptions from '../ConversionsPage/ConversionsOptions';

type TTab = 'general' | 'attribution-windows';

function ConversionsSettingsPage(): JSX.Element {
  const account = useAccount();
  const { tab } = useParams<{ tab: TTab }>();
  const { isAdmin, isSudoer } = useRole();
  const { t } = useTranslation();

  const canSetConversionOptions = isSudoer || isAdmin;
  const { setRightActions } = useActionBar();

  const validationErrors: TValidateGrouped = account.validate(
    ['multi_convert_cooldown_value'],
    false,
    'grouped',
  ).errors;
  const flattenedErrors = flattenErrors(validationErrors);

  useEffect(() => {
    setRightActions(
      <SaveButton
        disabled={flattenedErrors.length > 0 || !account.isDirty || account.isPending}
        method={'patch'}
        model={account}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.isDirty, flattenedErrors.length]);

  const tabs = [
    <Tab active={tab === 'general'} key={'general'} link={'general'} title={t('General')} />,
    <Tab
      active={tab === 'attribution-windows'}
      key={'attribution-windows'}
      link={'attribution-windows'}
      title={t('Attribution Windows')}
    />,
  ];

  function General(): JSX.Element {
    return (
      <>
        {canSetConversionOptions && <ConversionsOptions account={account} />}
        <AttributionOptions account={account} />
      </>
    );
  }

  const content = {
    general: <General />,
    'attribution-windows': <AttributionWindowsCard />,
  }[tab];

  return (
    <Page tabs={tabs} title={t('Conversions')}>
      {content}
    </Page>
  );
}

export default observer(ConversionsSettingsPage);
