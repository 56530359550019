import type { IObservableArray } from 'mobx';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ToastType } from 'react-toastify';

import type { Domain } from '@feathr/blackbox';
import { Step, Steps, toast, Wizard } from '@feathr/components';
import { StoresContext, useAccount, useLocalUrl } from '@feathr/extender/state';
import { logUserEvents } from '@feathr/hooks';

import DomainEditStepFour from './DomainEditStepFour';
import DomainEditStepOne from './DomainEditStepOne';
import DomainEditStepThree from './DomainEditStepThree';
import DomainEditStepTwo from './DomainEditStepTwo';

interface IProps {
  domain: Domain;
}

function DomainEdit({ domain }: IProps): JSX.Element {
  const account = useAccount();
  const { Domains } = useContext(StoresContext);
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [completeStep, setCompleteStep] = useState<number>(0);
  const [matchingDomains, setMatchingDomains] = useState<IObservableArray<Domain>>(observable([]));
  const history = useHistory();
  const localUrl = useLocalUrl();

  function getHashStep(): number | undefined {
    const matchedHash = /\#step(\d{1})$/.exec(location.hash);
    return matchedHash ? +matchedHash[1] - 1 : undefined;
  }

  // The hash step functionality is not meant to work with ephemeral objects
  const hashStep = !domain.isEphemeral ? getHashStep() : undefined;

  const steps = (
    <Steps
      completed={!domain.isEphemeral ? 3 : completeStep}
      current={currentStep}
      onChange={setCurrentStep}
    >
      <Step key={1} stepIndex={0} title={t('Setup')} />
      <Step key={2} stepIndex={1} title={t('Content Serving Settings')} />
      <Step key={3} stepIndex={2} title={t('Email Sending Settings')} />
      <Step key={4} stepIndex={3} title={t('Review')} />
    </Steps>
  );

  /*
   * This effect is used to set the current and complete step for non-ephemeral domains,
   * and will only be ran once during the page load
   */
  useEffect(() => {
    if (!domain.isEphemeral) {
      hashStep ? setCurrentStep(hashStep) : setCurrentStep(3);
      setCompleteStep(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onNext(): void {
    const nextStep = Math.min(currentStep + 1, 4);
    setCurrentStep(nextStep);
    if (completeStep === undefined || completeStep < nextStep) {
      setCompleteStep(nextStep);
    }
  }

  function onPrev(): void {
    setCurrentStep(currentStep - 1);
  }

  async function handleSave(): Promise<void> {
    if (domain.isEphemeral) {
      const response = await Domains.add(domain);
      if (response.get('redirect')) {
        logUserEvents({
          'Added custom domain': {
            account_id: account.id,
            domain_id: domain.id,
          },
        });
        toast(t('Your domain was added successfully.'), { type: ToastType.SUCCESS });
        history.push(localUrl(`settings/account/domains/${response.id}`));
      } else if (response.isErrored) {
        toast(
          t('There was an error adding your domain: {{error}}.', {
            error: response.error!.message,
          }),
          { type: ToastType.ERROR },
        );
      }
    } else {
      const updatedDomain = await domain.patchDirty();

      if (updatedDomain.isErrored) {
        toast(
          t('There was an error updating your domain: {{error}}.', {
            error: updatedDomain.error!.message,
          }),
          {
            type: 'error',
          },
        );
        return;
      }
      toast(t('Your domain was updated successfully.'));
      history.push(localUrl('settings/account/domains'));
    }
  }

  const isReadonly = !domain.isEphemeral;

  return (
    <Wizard isLoading={domain.isPending} steps={steps}>
      {currentStep === 0 && (
        <DomainEditStepOne
          disabled={isReadonly}
          domain={domain}
          onNext={onNext}
          setCompleteStep={setCompleteStep}
          setMatchingDomains={setMatchingDomains}
        />
      )}
      {currentStep === 1 && (
        <DomainEditStepTwo
          disabled={isReadonly}
          domain={domain}
          matchingDomains={matchingDomains}
          onNext={onNext}
          onPrev={onPrev}
        />
      )}
      {currentStep === 2 && (
        <DomainEditStepThree
          disabled={isReadonly}
          domain={domain}
          matchingDomains={matchingDomains}
          onNext={onNext}
          onPrev={onPrev}
        />
      )}
      {currentStep === 3 && (
        <DomainEditStepFour
          domain={domain}
          handleSave={handleSave}
          matchingDomains={matchingDomains}
          onPrev={onPrev}
        />
      )}
    </Wizard>
  );
}

export default observer(DomainEdit);
