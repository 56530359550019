import { faCheckCircle, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';

import type { IImportColumn, IPreviewRow } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader, Tooltip } from '@feathr/components';
import Tags from '@feathr/extender/components/Tags';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as tagsStyles from './Tags.css';

/** Reorder columns in the order of preferred columns */
const reorderColumns = (columnMappers: IImportColumn[]): string[] => {
  let preferredColumns = ['email', 'name', 'first_name', 'last_name'];
  preferredColumns = preferredColumns.map((column) => {
    const preferredMapper = columnMappers.find((mapper) => mapper.feathr_attr === column);
    return preferredMapper ? preferredMapper.column_name : column;
  });

  const columns = columnMappers.map((mapping) => mapping.column_name);

  return columns.sort((a, b) => {
    let indexA = preferredColumns.indexOf(a);
    let indexB = preferredColumns.indexOf(b);
    if (indexA === -1) {
      indexA = preferredColumns.length;
    }
    if (indexB === -1) {
      indexB = preferredColumns.length;
    }
    return indexA - indexB;
  });
};

export default function previewTableColumns(
  columnMappers: IImportColumn[],
  tagIds: string[],
): Array<IColumn<IPreviewRow>> {
  const columns: Array<IColumn<IPreviewRow>> = reorderColumns(columnMappers).map((columnName) => {
    return {
      Header: TableColumnHeader({
        title: columnName,
      }),
      id: columnName,
      checkboxLabel: columnName,
      className: tableStyles.cell,
      sortable: false,
      Cell({ original }): JSX.Element {
        return <>{original.input[columnName]}</>;
      },
    };
  });

  // Prepend icon column that indicates whether the row will be imported or not
  columns.unshift({
    Header: '',
    id: 'icon',
    checkboxLabel: 'icon',
    className: tableStyles.cell,
    sortable: false,
    width: 40,
    Cell({ original }): JSX.Element {
      return original.error?.length ? (
        <Tooltip title={'This row will not be imported, due to an error.'}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </Tooltip>
      ) : (
        <Tooltip title={'This row will be imported.'}>
          <FontAwesomeIcon icon={faCheckCircle} />
        </Tooltip>
      );
    },
  });

  // Add in tags
  columns.push({
    Header: TableColumnHeader({
      title: 'Tags',
    }),
    id: 'tags',
    checkboxLabel: 'tags',
    className: tableStyles.cell,
    sortable: false,
    Cell() {
      return <Tags className={tagsStyles.root} value={tagIds} />;
    },
  });

  return columns;
}
