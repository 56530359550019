import React from 'react';
import type { OptionProps, SingleValueProps } from 'react-select';
import { components } from 'react-select';

import type { ICreativeAttributes } from '@feathr/blackbox';
import CampaignChip from '@feathr/extender/components/CampaignChip';

import CreativeThumbnail from '../CreativeThumbnail';

import { contentWrapper } from './SelectOptions.css';

export function CreativeOption(props: OptionProps<ICreativeAttributes>) {
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex' }}>
        <div className={contentWrapper}>
          <CreativeThumbnail creative={props.data} />
        </div>
        <div className={contentWrapper}>
          <span>{props.data.name}</span>
        </div>
        <div className={contentWrapper}>
          <span>
            {props.data.width}×{props.data.height}
          </span>
        </div>
        {!!props.data.parent && (
          <div className={contentWrapper}>
            <CampaignChip id={props.data.parent} linkToItem={false} />
          </div>
        )}
      </div>
    </components.Option>
  );
}

export function CreativeSingleValue(props: SingleValueProps<ICreativeAttributes>) {
  return (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex' }}>
        <div className={contentWrapper}>
          <span>{props.data.name}</span>
        </div>
        <div className={contentWrapper}>
          <span>
            {props.data.width}×{props.data.height}
          </span>
        </div>
        {!!props.data.parent && (
          <div className={contentWrapper}>
            <CampaignChip id={props.data.parent} linkToItem={false} />
          </div>
        )}
      </div>
    </components.SingleValue>
  );
}
