import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer, observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { Billable, Event } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Card, TableColumnHeader, Tooltip } from '@feathr/components';
import { BillListTable } from '@feathr/extender/components/BillTable';
import { EventsTable } from '@feathr/extender/components/EventsTable';
import { useAccount, useLocalUrl } from '@feathr/extender/state';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './BillableProjectsAndInvoices.css';

interface IProps {
  billable: Billable;
}

function BillableProjectsAndInvoices({ billable }: Readonly<IProps>): JSX.Element | null {
  const account = useAccount();
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const filters = {
    account: account.id,
    billable: billable.id,
  };

  const columns: Array<IColumn<Event>> = [
    {
      id: 'name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: 'Name',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => (
              <Link to={localUrl(original.getItemUrl())}>{original.get('name')}</Link>
            )}
          </Observer>
        );
      },
    },
  ];

  return (
    <div className={styles.content}>
      <Card
        className={styles.projects}
        nested={true}
        secondary={true}
        title={
          <span>
            {t('Projects')}
            <Tooltip
              className={styles.icon}
              title={t(
                'This billing configuration is the primary billing configuration for the following projects.',
              )}
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Tooltip>
          </span>
        }
      >
        <EventsTable
          columns={columns}
          filters={{
            is_archived__ne: true,
            billing__billable_id: billable.id,
          }}
        />
      </Card>
      <Card
        className={styles.invoices}
        nested={true}
        secondary={true}
        title={
          <span>
            {t('Invoices')}
            <Tooltip
              className={styles.icon}
              title={t(
                'This billing configuration is the billing configuration attached to the following invoices.',
              )}
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Tooltip>
          </span>
        }
      >
        <BillListTable filters={filters} key={'invoices'} noDataText={t('No Invoices')} />
      </Card>
    </div>
  );
}

export default observer(BillableProjectsAndInvoices);
