import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faAndroid,
  faApple,
  faChrome,
  faLinux,
  faWindows,
} from '@fortawesome/free-brands-svg-icons';

// TODO: Combine with david's types in blackbox and persons.ts
export enum EPlatforms {
  android = 'Android',
  chromeos = 'ChromeOS',
  ipad = 'iPad',
  iphone = 'iPhone',
  linux = 'Linux',
  macos = 'OSX',
  windows = 'Windows',
}

// TODO: Combine with david's types in blackbox and persons.ts
export const platformIconMap: Record<keyof typeof EPlatforms, IconDefinition> = {
  android: faAndroid,
  chromeos: faChrome,
  ipad: faApple,
  iphone: faApple,
  linux: faLinux,
  macos: faApple,
  windows: faWindows,
};
