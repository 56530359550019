import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Account } from '@feathr/blackbox';
import { SaveButtonValid } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useAccount, useActionBar } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import AllowList from './AllowList';

function AllowListPage(): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const { setRightActions } = useActionBar();

  const isLoading = account.isPending;
  const isDisabled = isLoading || !account.isDirty;

  const validationErrors: TValidateGrouped = account.validate(
    ['domain_allow_list'],
    false,
    'grouped',
  ).errors;

  useEffect(() => {
    setRightActions(
      <SaveButtonValid<Account>
        disabled={!!validationErrors.length || !account.isDirty}
        errors={flattenErrors(validationErrors)}
        method={'patch'}
        model={account}
        tooltipPosition={'top-start'}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.isValid(['domain_allow_list'], true), account.isDirty, isDisabled]);

  return (
    <Page title={t('Domain Allow List')}>
      <AllowList account={account} />
    </Page>
  );
}

export default observer(AllowListPage);
