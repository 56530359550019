import type { JSX } from 'react';
import React, { useState } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';

import type { TSource } from '@feathr/blackbox';
import { Radios } from '@feathr/components';

import BankAccountInput from './BankAccountInput';
import CreditCardInput from './CreditCardInput';

type TPaymentType = 'ach' | 'credit';
type TSaveFunction = () => Promise<TSource>;

interface IProps {
  children: (onSave: TSaveFunction, element: JSX.Element) => JSX.Element;
}

function PaymentMethodForm({ children }: Readonly<IProps>): JSX.Element {
  const [paymentType, setPaymentType] = useState<TPaymentType>('credit');

  function handlePaymentTypeChange(newValue?: string): void {
    setPaymentType(newValue as TPaymentType);
  }

  let handleSave: TSaveFunction | undefined;

  async function onSave(): Promise<TSource> {
    if (typeof handleSave !== 'function') {
      throw new Error('The handleSave function was not properly initiallized.');
    }
    return handleSave();
  }

  const element = (
    <>
      <Radios
        label={'Payment type'}
        layout={'block'}
        onChange={handlePaymentTypeChange}
        options={[
          { id: 'credit', name: 'Credit card' },
          { id: 'ach', name: 'Bank account (ACH)' },
        ]}
        value={paymentType}
      />
      <StripeProvider apiKey={STRIPE_API_KEY}>
        <Elements>
          <>
            {paymentType === 'credit' && (
              <CreditCardInput>
                {(onSave, element): JSX.Element => {
                  handleSave = onSave;
                  return element;
                }}
              </CreditCardInput>
            )}
            {paymentType === 'ach' && (
              <BankAccountInput>
                {(onSave, element): JSX.Element => {
                  handleSave = onSave;
                  return element;
                }}
              </BankAccountInput>
            )}
          </>
        </Elements>
      </StripeProvider>
    </>
  );

  return <>{children(onSave, element)}</>;
}

export default PaymentMethodForm;
