import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type {
  BlackbaudRaisersEdgeIntegration,
  IBlackbaudUnsubscribeSyncPreference,
} from '@feathr/blackbox';
import { EBlackbaudRaisersEdgeUnsubscribeSyncActions } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { CardV2 as Card, Radios, Select, toast } from '@feathr/components';

import * as styles from './RaisersEdgeUnsubscribeCard.css';

interface IProps {
  integration: BlackbaudRaisersEdgeIntegration;
}

function RaisersEdgeUnsubscribeCard({ integration }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  const [unsubscribePreference, setUnsubscribePreference] =
    useState<IBlackbaudUnsubscribeSyncPreference>(
      integration.get('unsubscribe_sync_preference') ?? {
        action: EBlackbaudRaisersEdgeUnsubscribeSyncActions.DoNotSync,
      },
    );
  const [solicitCodeOptions, setSolicitCodeOptions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    async function loadSolicitCodeOptions(): Promise<void> {
      try {
        const solicitCodes = await integration.getSolicitCodes();
        setSolicitCodeOptions(
          solicitCodes?.solicit_codes.map((solicitCode) => ({
            id: solicitCode,
            name: solicitCode,
          })) ?? [],
        );
      } catch (error: any) {
        toast(
          t('Failed to load solicit codes:\n{{error}}', {
            error: error.message,
          }),
          { type: 'error' },
        );
        // eslint-disable-next-line no-console
        console.error('Failed to load solicit codes', error);
      }
    }

    loadSolicitCodeOptions();
  }, []);

  function handleActionChange(newValue?: string): void {
    if (!newValue) {
      return;
    }
    const newUnsubscribePreference = {
      ...unsubscribePreference,
      action: newValue as EBlackbaudRaisersEdgeUnsubscribeSyncActions,
    };
    setUnsubscribePreference(newUnsubscribePreference);
    integration.set({
      unsubscribe_sync_preference: newUnsubscribePreference,
    });
  }

  function handleSolicitCodeChange(option: ISelectOption): void {
    const newUnsubscribePreference = {
      ...unsubscribePreference,
      solicit_code: option.id,
    };
    setUnsubscribePreference(newUnsubscribePreference);
    integration.set({
      unsubscribe_sync_preference: newUnsubscribePreference,
    });
  }

  const unsubscribeActions = [
    {
      id: EBlackbaudRaisersEdgeUnsubscribeSyncActions.DoNotSync,
      name: t("Do nothing in Raiser's Edge"),
    },
    {
      id: EBlackbaudRaisersEdgeUnsubscribeSyncActions.RequestsNoEmail,
      name: (
        <Trans>
          Add `Requests No Email` checkbox to constituent <br />
          <a href={'https://kb.blackbaud.com/knowledgebase/Article/42137'} target={'_blank'}>
            Learn more about the requests no email field
          </a>
        </Trans>
      ),
    },
    {
      id: EBlackbaudRaisersEdgeUnsubscribeSyncActions.SolicitCode,
      name: t('Add solicit code to constituent'),
    },
  ];

  return (
    <Card width={'wide'}>
      <Card.Header
        description={t(
          "Select what should happen in Raiser's Edge NXT when someone unsubscribes from an email in Feathr.",
        )}
        title={t('Manage Unsubscribes')}
      />
      <Card.Content>
        <Radios
          className={styles.radioGroup}
          name={'raisers-edge-unsubscribe'}
          onChange={handleActionChange}
          options={unsubscribeActions}
          orientation={'vertical'}
          value={unsubscribePreference.action}
        />
        {unsubscribePreference.action ===
          EBlackbaudRaisersEdgeUnsubscribeSyncActions.SolicitCode && (
          <Select
            className={styles.solicitCodeSelect}
            defaultOptions={true}
            id={'solicit-code'}
            isLoading={!solicitCodeOptions.length}
            isMulti={false}
            name={'solicit-code'}
            noOptionsMessage={t('No solicit codes found')}
            onSelectSingle={handleSolicitCodeChange}
            options={solicitCodeOptions}
            placeholder={t('Select a code')}
            value={solicitCodeOptions.find(
              (option) => option.id === unsubscribePreference.solicit_code,
            )}
          />
        )}
      </Card.Content>
    </Card>
  );
}

export default observer(RaisersEdgeUnsubscribeCard);
