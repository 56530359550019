import type { IBaseAttributes } from '@feathr/rachis';
import { Collection, isWretchError, Model, wretch } from '@feathr/rachis';

import type { ICampaignAttributes } from './campaigns/types';
import type { Flight, IFlight } from './flights';
import type { Flights } from './flights';
import type { TAttributionModel } from './stats';

export interface IPath extends IBaseAttributes {
  attribution_model?: TAttributionModel;
  name: string;
  shared_name: string;
  shared_description?: string;
  shared_image?: string;
  shared?: boolean;
  legs: IPathLeg[];
}

export interface IPathLeg {
  id?: string;
  name: string;
  description?: string;
  image?: string;
  campaigns: Array<Pick<ICampaignAttributes, 'id' | 'event' | '_cls' | 'name' | 'placeholder'>>;
}

export class Path extends Model<IPath> {
  public className = 'Path';

  public constraints = {};
}

export class Paths extends Collection<Path> {
  public getModel(attributes: IPath): Path {
    return new Path(attributes);
  }

  public getClassName(): string {
    return 'paths';
  }

  public async createFlightFromPath(
    pathId: string,
    overrides: Partial<IFlight>,
    FlightsCollection: Flights,
  ): Promise<Flight> {
    const response = await wretch<IFlight>(`${this.getHostname()}paths/${pathId}/create`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(overrides),
    });
    if (isWretchError(response)) {
      throw response.error;
    }

    const model = FlightsCollection.create(response.data);
    model.isEphemeral = false;
    return model;
  }
}
