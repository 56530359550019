import type { IObservableArray } from 'mobx';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';

import type { Campaign, Targeting } from '@feathr/blackbox';
import type { ISegmentSelectChangeProps } from '@feathr/extender/components/SegmentSelect';
import SegmentSelect from '@feathr/extender/components/SegmentSelect';
import { StoresContext } from '@feathr/extender/state';
interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  targetings: IObservableArray<Targeting>;
  onRemove: (targeting: Targeting) => void;
}

function SegmentTargeting({ campaign, targeting, targetings, onRemove }: IProps): JSX.Element {
  const { Segments } = useContext(StoresContext);

  const segmentId = targeting.get('target_data');

  async function handleChangeSegment({ included, id }: ISegmentSelectChangeProps): Promise<void> {
    if (id) {
      const newSegment = Segments.get(id);
      await when(() => !newSegment.isPending);
      targeting.set({ included, name: newSegment.get('name'), target_data: id });
    }
  }

  function handleRemoveSegment(): void {
    onRemove(targeting);
  }

  const facebookFilter = { predicates__elemMatch: { attr_against: 'loc_url' } };

  return (
    <SegmentSelect
      campaign={campaign}
      excludeIds={targetings
        .filter((t) => t.get('target_data') || false)
        .map((t) => t.get('target_data')!)}
      filters={campaign.isFacebook ? facebookFilter : {}}
      included={targeting.get('included')}
      key={segmentId || 'add'}
      name={'segment_select'}
      onChange={handleChangeSegment}
      onRemove={handleRemoveSegment}
      stat={'num_cookies_total'}
      value={segmentId}
    />
  );
}

export default observer(SegmentTargeting);
