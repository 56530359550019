import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { CampaignClass, FacebookIntegrationState } from '@feathr/blackbox';
import { CardV2 as Card } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';

import CardContent from './CardContent';
import DisabledFBTooltip from './DisabledFBTooltip';

import * as styles from './CampaignClassCard.css';

export interface ICardProps {
  cls: CampaignClass;
  disabled?: boolean;
  onClick: (type: CampaignClass) => void;
  usageText?: string | null;
}

function CampaignClassCard({
  cls,
  disabled,
  onClick,
  usageText,
}: Readonly<ICardProps>): JSX.Element {
  const account = useAccount();
  const fbIntegrationState = account.get('facebook').state;

  const isDisabledEmailCampaign =
    account?.get('email_health') === 'suspended' &&
    [
      CampaignClass.AutoPinpointEmail,
      CampaignClass.PinpointEmail,
      CampaignClass.SmartPinpointEmail,
    ].includes(cls);

  const isFBCampaign = cls === CampaignClass.Facebook || cls === CampaignClass.EmailListFacebook;

  const fbTooltipNeeded =
    (isFBCampaign && fbIntegrationState === FacebookIntegrationState.not_connected) ||
    (isFBCampaign && fbIntegrationState === FacebookIntegrationState.no_page_selected) ||
    (cls === CampaignClass.EmailListFacebook &&
      !account.get('facebook').tos_accepted.custom_audience_tos);

  const isDisabled = disabled || isDisabledEmailCampaign || fbTooltipNeeded;

  function handleClick(): void {
    if (!isDisabled) {
      onClick(cls);
    }
  }

  return (
    <Card
      contentClassName={styles.root}
      data-appcues={cls}
      disabled={isDisabled}
      onClick={handleClick}
      tooltip={fbTooltipNeeded && <DisabledFBTooltip cls={cls} state={fbIntegrationState} />}
      width={'full'}
    >
      <CardContent
        cls={cls}
        disabledEmailCampaign={isDisabledEmailCampaign}
        usageText={usageText}
      />
    </Card>
  );
}

export default observer(CampaignClassCard);
