import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { IAddOn, ILicense, IPackage, IService } from '@feathr/blackbox';
import {
  Alert,
  AlertType,
  Button,
  Card,
  Chip,
  Label,
  Section,
  SectionGroup,
  Time,
  Toolbar,
  Value,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import BillListTable from '@feathr/extender/components/BillTable/BillListTable';
import { StoresContext, useAccount, useFlags, useLocalUrl } from '@feathr/extender/state';
import { packageLicenseColorMap, packageLicenseToIconMap } from '@feathr/extender/styles/license';
import { TimeFormat } from '@feathr/hooks';

import { getMomentLicenseDate } from '../../GracePeriodSelect/UpdatedGracePeriodCard';
import UsageMetrics from './UsageMetrics';

import * as styles from './LicensePage.css';

function LicensePage(): JSX.Element | null {
  const { Users } = React.useContext(StoresContext);
  const account = useAccount();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const flags = useFlags();
  const { t } = useTranslation();

  const license = account.get('license', {
    add_ons: [] as IAddOn[],
    autorenew: false,
    discounts: [] as IService[],
    packages: [] as IPackage[],
    period: {},
    services: [] as IService[],
  } as ILicense);
  if (!license.billable) {
    history.push(localUrl('/settings/billing/license/edit'));
    return null;
  }

  const actions = (
    <Toolbar>
      <Button
        link={localUrl('/settings/billing/license/edit')}
        prefix={<FontAwesomeIcon icon={faEdit} />}
      >
        {t('Edit')}
      </Button>
    </Toolbar>
  );

  const filters = { _cls: 'PlatformBill', account: account.id };
  const { activePackage, activeServices, addOns } = account;
  const licenseEnd = activePackage?.period.end;

  const csmId = account.get('csm');
  // Intentionally use != to check for null OR undefined.
  // eslint-disable-next-line eqeqeq
  const csm = csmId != undefined ? Users.get(csmId) : undefined;
  const email = csm?.get('email') ?? 'customersuccess@feathr.co';

  return (
    <Page
      actions={actions}
      alerts={
        !activePackage ? (
          <Alert type={AlertType.danger}>
            <Trans t={t}>
              Please contact your CSM at <a href={`mailto:${email}`}>{{ email }}</a> to renew your
              account.
            </Trans>
          </Alert>
        ) : undefined
      }
      loading={account.isPending}
      title={t('Account License')}
    >
      <SectionGroup>
        <Section title={t('License Package')}>
          {!!activePackage && (
            <Card>
              <div className={styles.packageDetailWrapper}>
                <div
                  className={styles.icon}
                  style={{
                    ['--l-icon-background-color' as string]: packageLicenseColorMap(
                      activePackage?.name,
                    ),
                    ['--l-icon-size' as string]: '36px',
                  }}
                >
                  <FontAwesomeIcon
                    icon={packageLicenseToIconMap(activePackage?.name)}
                    size={'2x'}
                  />
                </div>
                {/* TODO: Long license names break the layout. See https://github.com/Feathr/shrike/issues/1960 */}
                <span className={styles.packageName}>{activePackage.name}</span>
                <span>
                  <Label className={styles.label}>{t('Valid through')}</Label>{' '}
                  {activePackage && licenseEnd ? (
                    <Time format={TimeFormat.longDate} timestamp={licenseEnd} />
                  ) : activePackage && !licenseEnd ? (
                    t('Unknown')
                  ) : (
                    t('Expired')
                  )}
                </span>
                {!!activePackage?.grace_period?.start && (
                  <span>
                    <Label className={styles.label}>{t('Start date includes grace period')}</Label>{' '}
                    <Time
                      format={TimeFormat.pickerDateTime}
                      timestamp={getMomentLicenseDate(
                        activePackage.period,
                        activePackage.grace_period,
                        'start',
                      )}
                    />
                  </span>
                )}
                {!!activePackage?.grace_period?.end && (
                  <span>
                    <Label className={styles.label}>{t('End date includes grace period')}</Label>{' '}
                    <Time
                      format={TimeFormat.pickerDateTime}
                      timestamp={getMomentLicenseDate(
                        activePackage.period,
                        activePackage.grace_period,
                        'end',
                      )}
                    />
                  </span>
                )}
                <span>
                  <Label className={styles.label}>{t('Price')}</Label>{' '}
                  {numeral(activePackage.price).format('$0,0.00')}
                </span>
                <span>
                  <Label className={styles.label}>{t('Autorenew')}</Label>{' '}
                  {license.autorenew ? (
                    <Chip theme={'lime'}>{t('Enabled')}</Chip>
                  ) : (
                    <Chip theme={'default'}>{t('Disabled')}</Chip>
                  )}
                </span>
              </div>
            </Card>
          )}
        </Section>
        <Section
          description={
            <Trans t={t}>
              <p>The currently active add-ons you have purchased.</p>
            </Trans>
          }
          title={t('Add-Ons')}
        >
          {addOns?.length > 0
            ? addOns.map((addOn) => (
                <Card
                  className={styles.serviceCard}
                  contentClassName={styles.serviceCardContent}
                  key={addOn.id}
                >
                  <Value className={styles.serviceValue} label={t('Item')} value={addOn.name} />
                  {!!addOn.quantity && (
                    <Value
                      className={styles.serviceValue}
                      label={t('Quantity')}
                      value={addOn.quantity}
                    />
                  )}
                  {!!addOn.period.start && (
                    <Value
                      className={styles.serviceValue}
                      label={t('Start')}
                      value={<Time format={TimeFormat.shortDate} timestamp={addOn.period.start} />}
                    />
                  )}
                  {!!addOn.period.end && (
                    <Value
                      className={styles.serviceValue}
                      label={t('End')}
                      value={<Time format={TimeFormat.shortDate} timestamp={addOn.period.end} />}
                    />
                  )}
                  {!!addOn.price && (
                    <Value
                      className={styles.serviceValue}
                      label={t('Price')}
                      value={numeral(addOn.price).format('$0,0.00')}
                    />
                  )}
                </Card>
              ))
            : t('No add-ons currently active.')}
        </Section>
        <>{flags.showUsageMetrics && <UsageMetrics account={account} />}</>
        <Section
          description={
            <Trans t={t}>
              <p>The currently active service packages you have purchased.</p>
            </Trans>
          }
          title={t('Active Services')}
        >
          {activeServices.length > 0
            ? activeServices.map((service) => (
                <Card
                  className={styles.serviceCard}
                  contentClassName={styles.serviceCardContent}
                  key={service.id}
                >
                  <Value className={styles.serviceValue} label={t('Item')} value={service.name} />
                  {!!service.quantity && (
                    <Value
                      className={styles.serviceValue}
                      label={t('Quantity')}
                      value={service.quantity}
                    />
                  )}
                  {!!service.period.start && (
                    <Value
                      className={styles.serviceValue}
                      label={t('Start')}
                      value={
                        <Time format={TimeFormat.shortDate} timestamp={service.period.start} />
                      }
                    />
                  )}
                  {!!service.period.end && (
                    <Value
                      className={styles.serviceValue}
                      label={t('End')}
                      value={<Time format={TimeFormat.shortDate} timestamp={service.period.end} />}
                    />
                  )}
                  {!!service.price && (
                    <Value
                      className={styles.serviceValue}
                      label={t('Price')}
                      value={numeral(service.price).format('$0,0.00')}
                    />
                  )}
                </Card>
              ))
            : t('No services currently active.')}
        </Section>
        <Section title={t('Platform Invoices')}>
          <BillListTable filters={filters} />
        </Section>
      </SectionGroup>
    </Page>
  );
}

export default observer(LicensePage);
