import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { PinpointEmailBaseCampaign } from '@feathr/blackbox';
import ReportActions from '@feathr/extender/components/PageHeaderActions/ReportActions';
import { useReportExport } from '@feathr/extender/hooks';

import EmailDetails from '../../../../components/EmailDetails';
import CampaignPage from '../../CampaignPage';
import CampaignReportSection, { getCampaignReportConfig } from '../CampaignReportSection';

import * as styles from './PinpointEmailCampaignReportPage.css';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
}

function PinpointEmailCampaignReportPage({ campaign }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const emailCampaignDetailSection = <EmailDetails campaign={campaign} />;
  const config = getCampaignReportConfig(campaign);
  const reportExportProps = useReportExport({ model: campaign, config });
  const actions = <ReportActions campaign={campaign} {...reportExportProps} />;

  return (
    <CampaignPage actions={actions} campaign={campaign} title={campaign.name}>
      {emailCampaignDetailSection}
      <h3 className={styles.title}>{t('Report')}</h3>
      <CampaignReportSection campaign={campaign} {...reportExportProps} />
    </CampaignPage>
  );
}

export default PinpointEmailCampaignReportPage;
