import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Event } from '@feathr/blackbox';
import { Button, TLDR } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

interface IProps {
  readonly event?: Event;
}

function EventTLDR({ event }: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  if (event === undefined || event.isPending) {
    return <TLDR isLoading={true} />;
  }

  return (
    <TLDR
      action={<Button href={localUrl(event.getItemUrl())}>{t('View campaigns')}</Button>}
      image={event.get('logo')}
      isLoading={false}
      title={event.get('name')}
    />
  );
}

export default observer(EventTLDR);
