import classNames from 'classnames';
import { autorun } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { FacebookVideoCreative } from '@feathr/blackbox';
import { Button, ModalV1, Spinner, toast } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';

import * as styles from './FacebookVideoThumbnail.css';

import noImg from '@feathr/extender/images/no-img.png';

interface IProps {
  creative: FacebookVideoCreative;
  previewButton: JSX.Element;
}

interface IFacebookVideoThumbnail {
  id: string;
  height: number;
  scale: number;
  uri: string;
  width: number;
  is_preferred: boolean;
}

interface IFacebookAdVideo {
  id: string;
  thumbnails: { data: IFacebookVideoThumbnail[] };
}

interface IState {
  thumbnails: IFacebookVideoThumbnail[];
  modalOpen: boolean;
  pending: boolean;
}

async function getThumbnails(
  creative: FacebookVideoCreative,
  accessToken: string,
): Promise<IFacebookVideoThumbnail[]> {
  const queryParams = new URLSearchParams({
    access_token: accessToken,
    fields: 'thumbnails',
  });
  const response = await fetch(
    `https://graph.facebook.com/${FACEBOOK_API_VERSION}/${creative.get(
      'ad_video_id',
    )}?${queryParams}`,
  );
  const json: IFacebookAdVideo = await response.json();
  return (json.thumbnails && json.thumbnails.data) || [];
}

async function pollForThumbnails(
  creative: FacebookVideoCreative,
  callback: (thumbnails: IFacebookVideoThumbnail[]) => void,
  accessToken?: string,
): Promise<void> {
  if (!accessToken) {
    return;
  }
  let tries = 0;
  const poll = async (): Promise<void> => {
    const thumbnails = await getThumbnails(creative, accessToken);
    if (thumbnails.length > 0) {
      callback(thumbnails);
    } else if (tries >= 20) {
      callback([]);
    } else {
      tries += 1;
      setTimeout(poll, 3000);
    }
  };
  await poll();
}

function FacebookVideoThumbnail({ creative, previewButton }: IProps): JSX.Element {
  const account = useAccount();
  const state = useLocalObservable<IState>(() => ({
    thumbnails: [],
    modalOpen: false,
    pending: true,
  }));
  const { t } = useTranslation();

  const accessToken = account?.getFacebookAccessToken;

  useEffect(() => {
    const disposer = autorun(() => {
      if (!accessToken) {
        state.pending = false;
      } else if (state.thumbnails.length > 0 && !creative.get('ad_video_thumbnail')) {
        const preferredThumbnail = state.thumbnails.find((thumbnail) => thumbnail.is_preferred);
        if (preferredThumbnail) {
          creative.set({ ad_video_thumbnail: preferredThumbnail.uri });
        } else {
          creative.set({ ad_video_thumbnail: state.thumbnails[0].uri });
        }
      }
    });

    pollForThumbnails(
      creative,
      (thumbnails) => {
        if (thumbnails.length > 0) {
          thumbnails.forEach((thumbnail) => state.thumbnails.push(thumbnail));
        } else {
          toast(
            t(
              'Thumbnails took too long to load. Try saving your campaign and refreshing the page.',
            ),
            { type: ToastType.ERROR },
          );
        }
        state.pending = false;
      },
      accessToken,
    ).catch(() => {
      // GitHub issue to add error handling: https://github.com/Feathr/shrike/issues/1608
    });

    return disposer;
  }, []);
  return (
    <>
      <div className={styles.thumbnailPreview}>
        <div className={styles.thumbnailWrapper}>
          {state.thumbnails.length === 0 && state.pending ? (
            <Spinner />
          ) : (
            <div
              className={styles.thumbnailImage}
              style={{ backgroundImage: `url(${creative.get('ad_video_thumbnail') || noImg})` }}
            />
          )}
          {/* Mask to display 80%  black layer between image and button */}
          <div className={styles.mask}></div>
          {previewButton}
        </div>
        <Button
          disabled={state.thumbnails.length === 0 || state.pending}
          onClick={() => {
            state.modalOpen = true;
          }}
        >
          {state.pending ? t('Loading thumbnails...') : t('Choose thumbnail')}
        </Button>
      </div>
      {state.modalOpen && (
        <ModalV1
          confirmButtonText={t('Ok')}
          controlled={true}
          onClose={() => {
            state.modalOpen = false;
          }}
          onConfirm={() => {
            state.modalOpen = false;
          }}
          t={t}
          title={t('Choose Thumbnail')}
        >
          <div className={styles.thumbnailGrid}>
            {state.thumbnails.map((thumbnail) => (
              <div
                className={classNames(styles.thumbnailImageOption, {
                  [styles.thumbnailImageSelected]:
                    thumbnail.uri === creative.get('ad_video_thumbnail'),
                })}
                key={thumbnail.id}
                onClick={() => {
                  creative.set({ ad_video_thumbnail: thumbnail.uri });
                }}
                style={{ backgroundImage: `url(${thumbnail.uri})` }}
              />
            ))}
          </div>
        </ModalV1>
      )}
    </>
  );
}

export default observer(FacebookVideoThumbnail);
