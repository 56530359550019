import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { AvatarV2 as Avatar, Icon, Skeleton, Tooltip } from '@feathr/components';
import PersonName from '@feathr/extender/components/PersonName';

import * as styles from './PersonHeader.css';

interface IProps {
  person: Person;
}

function PersonHeader({ person }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  const isPending = person.isPending;

  return (
    <div className={styles.root}>
      <div className={styles.avatar}>
        {isPending && <Skeleton height={48} paragraphs={0} prefix={true} width={48} />}
        {!isPending && (
          <Avatar
            name={person.get('name')}
            placeholder={person.get('placeholder')}
            size={'large'}
          />
        )}
      </div>
      <div className={styles.name}>
        <PersonName hasLink={false} person={person.toJS()} />
        {person.reachable && (
          <Tooltip title={t('Reachable')}>
            <Icon className={styles.reachable} icon={faBadgeCheck} size={6} />
          </Tooltip>
        )}
      </div>
      <div className={styles.email}>
        {isPending ? <Skeleton paragraphs={1} width={180} /> : person.get('email')}
      </div>
    </div>
  );
}

export default observer(PersonHeader);
