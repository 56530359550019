import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { Importer } from '@feathr/blackbox';
import { defaultPersonAttributes, type IImportColumn } from '@feathr/blackbox';
import {
  Button,
  Chip,
  Fieldset,
  Form,
  FormSummary,
  FormSummaryItem,
  Input,
  Skeleton,
} from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import type { IValidation } from '@feathr/rachis';

import type { IImportButtonProps } from './DataImportPage';

import * as styles from './DataImportPage.css';

interface IProps {
  disabled?: boolean;
  importer: Importer;
  onPrev: () => void;
  ImportButton: React.FunctionComponent<IImportButtonProps>;
}

interface ISummaryProps {
  importer: Importer;
}

interface IColumnSummaryProps {
  importer: Importer;
}

export const validateStepThree = (importer: Importer): IValidation<string[]> => {
  return importer.validate(['name', 'file_name', 'tag_ids', 'column_mappers'], false);
};

const ImportDetailSummary = observer(({ importer }: ISummaryProps) => {
  const { t } = useTranslation();

  return (
    <>
      <FormSummaryItem label={t('Import Name')} value={importer.get('name')} />
      <FormSummaryItem label={t('File Name')} value={importer.get('file_name')} />
    </>
  );
});

const TagsSummary = observer(({ importer }: ISummaryProps) => {
  const { Tags } = useContext(StoresContext);

  if (importer.isOptOut) {
    return null;
  }

  const tags = Tags.list({
    filters: {
      is_archived__ne: true,
      collection: 'Partner',
    },
    pagination: { page_size: 1000 },
  });

  return (
    <FormSummaryItem
      label={'Tags'}
      value={
        !tags.isPending ? (
          tags.models
            .filter((tag) => importer.get('tag_ids', []).includes(tag.id))
            .map((tag) => (
              <Chip key={tag.get('name')} theme={'tag'}>
                {tag.get('name')}
              </Chip>
            ))
        ) : (
          <Skeleton />
        )
      }
    />
  );
});

const ColumnsSummary = observer(({ importer }: IColumnSummaryProps) => {
  const { CustomFields } = useContext(StoresContext);
  const fields = [
    ...defaultPersonAttributes,
    ...importer.getUsedCustomFields(CustomFields).map((f) => f.toJS()),
  ];
  const columns = importer
    .get('column_mappers')
    .filter((column) => column.column_name !== '') as IObservableArray<IImportColumn>;
  return (
    <FormSummaryItem
      label={'Columns'}
      value={columns.map((column) => {
        return (
          <Fieldset direction={'column'} key={`${column.column_name} Fieldset`}>
            <Input
              disabled={true}
              key={`${column.column_name}`}
              type={'text'}
              value={column.column_name}
            />
            <FontAwesomeIcon className={styles.arrow} icon={faArrowRight} />
            <Input
              disabled={true}
              isLoading={fields === undefined}
              key={`${column.column_name} Custom Field`}
              type={'text'}
              value={
                fields.find((f) => f.id === column.feathr_attr) !== undefined
                  ? fields.find((f) => f.id === column.feathr_attr)!.u_key
                  : ''
              }
            />
          </Fieldset>
        );
      })}
    />
  );
});

function DataImportStepThree({
  disabled = false,
  importer,
  ImportButton,
  onPrev,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const isDraft = importer.get('state') === 'draft';

  const actions = [
    <Button key={'prev'} onClick={onPrev}>
      {t('Previous')}
    </Button>,
    <ImportButton disabled={disabled || !isDraft} importer={importer} key={'importButton'} />,
  ];

  const description = importer.isOptOut
    ? t(
        'In order for your data to be properly imported into Feathr, each column of your spreadsheet must be mapped to a field on each person that is being imported. You can map each column to a field that already exists or to a new custom field you create.',
      )
    : t(
        'Review your settings and make sure you are tagging and associating your imported partners how you want.',
      );

  return (
    <Form actions={actions} description={description} label={t('Review')}>
      <FormSummary>
        <ImportDetailSummary importer={importer} />
        <TagsSummary importer={importer} />
        <ColumnsSummary importer={importer} />
      </FormSummary>
    </Form>
  );
}

export default observer(DataImportStepThree);
