import debounce from 'debounce-promise';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useParams } from 'react-router';

import type { ITemplate } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import { AsyncSelect } from '@feathr/components';
import { TemplateOption } from '@feathr/extender/components/SelectOptions';
import { StoresContext, useAccount } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';

interface IProps {
  onChange: (template?: ITemplate) => void;
  templateClass: TemplateClass;
  value?: ITemplate;
}

const templateClasses: Record<string, TemplateClass[]> = {
  [TemplateClass.ReferralPage]: [TemplateClass.ReferralPage, TemplateClass.Page],
  [TemplateClass.ReferralEmail]: [TemplateClass.ReferralEmail, TemplateClass.Email],
  [TemplateClass.ReferralBanner]: [TemplateClass.ReferralBanner, TemplateClass.Banner],
  [TemplateClass.LandingPage]: [TemplateClass.LandingPage, TemplateClass.Page],
  [TemplateClass.PinpointEmail]: [TemplateClass.PinpointEmail],
};

function TemplateSelectCustom({ onChange, templateClass, value }: IProps): JSX.Element {
  const { Templates } = React.useContext(StoresContext);
  const account = useAccount();
  const { eventId } = useParams<{ eventId: string }>();

  const getTemplates = async (inputValue: string): Promise<ITemplate[]> => {
    const templates = Templates.list({
      filters: {
        default_neq: true,
        event: eventId,
        account: account.id,
        parent_kind: 'campaign',
        /*
         * Legacy templates are only associated with an account and are of deprecated generic classes
         * need to map the template select class mode to a list of classes including the deprecated
         * generic classes to make those old templates available to use here.
         */
        _cls__in: templateClasses[templateClass],
        name__icontains: inputValue,
      },
      pagination: {
        page: 0,
        page_size: 20,
      },
      ordering: ['-date_last_modified'],
    });

    await when(() => !templates.isPending);
    return templates.models.sort((a, b) => a.name.localeCompare(b.name)).map((t) => t.toJS());
  };

  function handleChangeTemplate(selectedTemplate: ITemplate): void {
    onChange(selectedTemplate);
  }

  return (
    <AsyncSelect<ITemplate>
      // Set key to force refresh when templateClass prop changes.
      cacheOptions={true}
      components={{ Option: TemplateOption }}
      defaultOptions={true}
      key={templateClass}
      label={'Choose a custom template'}
      loadOptions={debounce(getTemplates, DEFAULT_DEBOUNCE_WAIT)}
      name={'template_select'}
      onSelectSingle={handleChangeTemplate}
      placeholder={'Select template...'}
      value={value}
    />
  );
}

export default observer(TemplateSelectCustom);
