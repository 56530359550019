import type { JSX } from 'react';
import React from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import { type ISelectOption, Label } from '@feathr/components';

export interface ICustomSelectOption extends ISelectOption {
  description: string;
}

type TOptionProps = OptionProps<ICustomSelectOption>;

function TargetingOption(props: TOptionProps): JSX.Element {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div>
        <Label margin={'condensed'} weight={'semibold'}>
          {data.name}
        </Label>
        <p>{data.description}</p>
      </div>
    </components.Option>
  );
}

export default TargetingOption;
