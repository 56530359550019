import { observer } from 'mobx-react-lite';
import React from 'react';

import type { Event } from '@feathr/blackbox';
import {
  Checkbox,
  ColorPicker,
  Fieldset,
  ImagePicker,
  RichTextEditor,
  Spinner,
  Toolbar,
} from '@feathr/components';
import { useAccount } from '@feathr/extender/state';

interface IProps {
  event: Event;
}

function PartnerDashboardContent({ event }: IProps) {
  const account = useAccount();
  return (
    <Fieldset label={'Content Settings'}>
      {event.isPending ? (
        <Spinner />
      ) : (
        <>
          <ImagePicker
            label={'Custom Partner Dashboard Logo'}
            onPick={(url) => {
              event.set({
                partner_dashboard_config: {
                  ...event.get('partner_dashboard_config'),
                  custom_logo: url,
                },
              });
            }}
            value={event.get('partner_dashboard_config').custom_logo}
          />
          <Toolbar align={'left'}>
            <ColorPicker
              attribute={['partner_dashboard_config', 'primary_color']}
              label={'Primary Color'}
              model={event}
            />
            <ColorPicker
              attribute={['partner_dashboard_config', 'button_color']}
              label={'Button Color'}
              model={event}
            />
            <ColorPicker
              attribute={['partner_dashboard_config', 'button_text_color']}
              label={'Button Text Color'}
              model={event}
            />
          </Toolbar>
          <RichTextEditor
            attribute={['partner_dashboard_config', 'welcome_text']}
            label={'Welcome Text'}
            model={event}
          />
          {account.isFalcon && (
            <>
              <Checkbox
                helpText={`
                If checked, the name of the template will be displayed above each Page and Email in the Partner 
                Dashboard. This can be helpful when you have multiple Pages or Emails for each Partner to use.
                `}
                label={'Show Template Titles'}
                onChange={() => {
                  event.set({
                    partner_dashboard_config: {
                      ...event.get('partner_dashboard_config'),
                      show_template_titles: !event.get('partner_dashboard_config')
                        .show_template_titles,
                    },
                  });
                }}
                value={event.get('partner_dashboard_config').show_template_titles}
              />
              <Checkbox
                helpText={
                  "If checked, views and clicks for each partner's creative will be shown below the creative in the Partner Dashboard."
                }
                label={'Show Views & Clicks'}
                onChange={() => {
                  event.set({
                    partner_dashboard_config: {
                      ...event.get('partner_dashboard_config'),
                      show_partner_stats: !event.get('partner_dashboard_config').show_partner_stats,
                    },
                  });
                }}
                value={event.get('partner_dashboard_config').show_partner_stats}
              />
            </>
          )}
        </>
      )}
    </Fieldset>
  );
}

export default observer(PartnerDashboardContent);
