import { faSliders } from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@feathr/components';

interface IProps {
  onClick: () => void;
}

function ConfigureColumnsButton({ onClick }: IProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Button onClick={onClick} prefix={<Icon icon={faSliders} />}>
      {t('Configure columns')}
    </Button>
  );
}

export default ConfigureColumnsButton;
