import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';

import type { ITemplate, ReferralCampaign } from '@feathr/blackbox';
import { TemplateClass, templateTypeLabel } from '@feathr/blackbox';
import { Alert, AlertType, Button, Card, Form, Radios, Toolbar } from '@feathr/components';
import TemplateSelect from '@feathr/extender/components/TemplateSelect';
import TemplatesTable from '@feathr/extender/components/TemplatesTable';
import TemplateColumnName from '@feathr/extender/components/TemplatesTable/TemplateColumnName';
import TemplateColumnThumbnail from '@feathr/extender/components/TemplatesTable/TemplateColumnThumbnail';
import TemplateColumnType from '@feathr/extender/components/TemplatesTable/TemplateColumnType';
import { StoresContext } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import TemplateColumnOptions from '../TemplateColumnOptions';

import * as styles from './StepThree.css';

interface IProps {
  campaign: ReferralCampaign;
  onNext: () => void;
  onPrev: () => void;
}

type TReferralTemplateClass =
  | TemplateClass.ReferralBanner
  | TemplateClass.ReferralEmail
  | TemplateClass.ReferralPage;

function StepThree({ campaign, onNext, onPrev }: IProps) {
  const { Templates } = useContext(StoresContext);
  const [newTemplate, setNewTemplate] = useState<ITemplate | undefined>();
  const [templateClass, setTemplateClass] = useState<TReferralTemplateClass>(
    TemplateClass.ReferralBanner,
  );
  const [isTemplateSelectVisible, toggleTemplateSelectVisible] = useToggle(false);

  async function handleAdd() {
    // Button is disabled if newTemplate is not set.
    if (!newTemplate || !newTemplate.id) {
      return;
    }

    const clonedTemplate = await Templates.clone(newTemplate.id, {
      campaign_id: campaign.id,
      event_id: campaign.get('event')!,
      _cls: templateClass,
    });
    campaign.addTemplate(clonedTemplate);
    handleCancel();
  }

  function handleCancel() {
    setNewTemplate(undefined);
    toggleTemplateSelectVisible();
  }

  function handleChangeTemplateClass(newValue?: string) {
    setTemplateClass(newValue as TReferralTemplateClass);
    setNewTemplate(undefined);
  }

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          Previous
        </Button>,
        <Button key={'next'} onClick={onNext} type={'primary'}>
          Next
        </Button>,
      ]}
      description={
        <>
          <p>Set up any templates as a part of your invite campaign.</p>
          <Alert type={AlertType.info}>
            If you select a new template or change a template, that template is duplicated before
            being used by the Campaign. The copied version will be used in your Campaign(s), so any
            edits you make to the original template will not affect this campaign.
          </Alert>
        </>
      }
      label={'Edit Campaign: Templates'}
    >
      <TemplatesTable
        columns={[
          TemplateColumnThumbnail,
          TemplateColumnName,
          TemplateColumnType,
          TemplateColumnOptions,
        ]}
        isPaginated={false}
        model={campaign}
      />
      {!isTemplateSelectVisible ? (
        <Toolbar align={'left'}>
          <Button
            key={'add'}
            onClick={toggleTemplateSelectVisible}
            prefix={<FontAwesomeIcon icon={faPlus} />}
            type={'primary'}
          >
            Add template
          </Button>
        </Toolbar>
      ) : (
        <Card className={styles.card}>
          <Radios
            onChange={handleChangeTemplateClass}
            options={[
              TemplateClass.ReferralBanner,
              TemplateClass.ReferralEmail,
              TemplateClass.ReferralPage,
            ].map((key) => ({ id: key, name: templateTypeLabel(key) }))}
            value={templateClass}
          />
          <TemplateSelect
            onChange={setNewTemplate}
            templateClass={templateClass}
            value={newTemplate}
          />
          <Toolbar>
            <Button key={'cancel'} onClick={handleCancel}>
              Cancel
            </Button>
            <Button disabled={!newTemplate} key={'select'} onClick={handleAdd}>
              Add template
            </Button>
          </Toolbar>
        </Card>
      )}
    </Form>
  );
}

export default observer(StepThree);
