import { makeObservable, observable } from 'mobx';

import { enumToObject } from '@feathr/hooks';
import type {
  IBaseAttributes,
  ICollectionStore,
  IListOptions,
  IListParams,
  ListResponse,
} from '@feathr/rachis';
import { isWretchError } from '@feathr/rachis';
import { Collection, Model, wretch } from '@feathr/rachis';

import type { IPredicate, IPredicateGroup } from './segments';

/*
 * Product Support requests that the convert, update, and form_submission
 * flavors remain at the top of the FlavorSelect options.
 * With the exception of the top-used-items, this is sorted alphabetically by label (not key)
 */

export enum EFlavorsRaw {
  convert = 'convert',
  update = 'update',
  form_submission = 'form_submission',
  ad_click = 'ad_click',
  pinpoint_tracked_email_click = 'pinpoint_tracked_email_click',
  partner_message_link_click = 'partner_message_link_click',
  page_link_click = 'page_link_click',
  contact_list_import = 'contact_list_import',
  conversion = 'conversion',
  dashboard_create = 'dashboard_create',
  email_view = 'email_view',
  custom = 'custom',
  pinpoint_tracked_email_suppression = 'pinpoint_tracked_email_suppression',
  pinpoint_tracked_email_rendering_failure = 'pinpoint_tracked_email_rendering_failure',
  pinpoint_tracked_email_hardbounce = 'pinpoint_tracked_email_hardbounce',
  dashboard_import = 'dashboard_import',
  pinpoint_tracked_email_open = 'pinpoint_tracked_email_open',
  created_by_import = 'created_by_import',
  updated_by_import = 'updated_by_import',
  pinpoint_tracked_email_delivered = 'pinpoint_tracked_email_delivered',
  pinpoint_tracked_email_complaint = 'pinpoint_tracked_email_complaint',
  dashboard_send_request = 'dashboard_send_request',
  dashboard_send_complete = 'dashboard_send_complete',
  pinpoint_tracked_email_softbounce = 'pinpoint_tracked_email_softbounce',
  dashboard_submit = 'dashboard_submit',
  ad_view = 'ad_view',
  report_view = 'report_view',
  page_view = 'page_view',
  dashboard_view = 'dashboard_view',
  partner_message_view = 'partner_message_view',
  partner_message_send = 'partner_message_send',
  pinpoint_tracked_email_send = 'pinpoint_tracked_email_send',
  user_create = 'user_create',
  user_update = 'user_update',
  zapier_import = 'zapier_import',
  zapier_update = 'zapier_update',
  imis_create = 'imis_create',
  imis_update = 'imis_update',
  email_preferences_update = 'email_preferences_update',
  raisers_edge_constituent_created = 'raisers_edge_constituent_created',
  raisers_edge_constituent_updated = 'raisers_edge_constituent_updated',
  raisers_edge_constituent_summary = 'raisers_edge_constituent_summary',
  raisers_edge_gift_added = 'raisers_edge_gift_added',
}

// TODO: The values of the keys in EFlavors should be the same as the values in EFlavorsRaw. The human readable version should be in themes/breadcrumbs.ts.
export enum EFlavors {
  convert = 'Conversion pixel',
  update = 'Profile update',
  form_submission = 'Submitted form',
  ad_click = 'Clicked ad',
  pinpoint_tracked_email_click = 'Clicked email',
  partner_message_link_click = 'Clicked link in partner message',
  page_link_click = 'Clicked link on page',
  contact_list_import = 'Imported contact list',
  conversion = 'Conversion',
  dashboard_create = 'Created dashboard',
  custom = 'Custom',
  pinpoint_tracked_email_suppression = 'Email was suppressed',
  pinpoint_tracked_email_rendering_failure = 'Email template rendering failed',
  pinpoint_tracked_email_hardbounce = 'Hard bounce',
  dashboard_import = 'Imported contacts on partner dashboard',
  pinpoint_tracked_email_open = 'Opened email',
  created_by_import = 'Created by import',
  updated_by_import = 'Updated by import',
  pinpoint_tracked_email_delivered = 'Received email',
  pinpoint_tracked_email_complaint = 'Reported email as spam',
  dashboard_send_request = 'Scheduled email invite',
  dashboard_send_complete = 'Sent email from partner dashboard',
  pinpoint_tracked_email_softbounce = 'Soft bounce',
  dashboard_submit = 'Updated dashboard profile',
  ad_view = 'Viewed ad',
  report_view = 'Viewed campaign report',
  page_view = 'Viewed page',
  dashboard_view = 'Viewed partner dashboard',
  partner_message_view = 'Viewed partner message',
  partner_message_send = 'Was sent partner message',
  pinpoint_tracked_email_send = 'Was sent email',
  user_create = 'Manually created',
  user_update = 'Manually updated',
  zapier_import = 'Zapier import',
  zapier_update = 'Zapier update',
  imis_create = 'Created by iMIS',
  imis_update = 'Updated by iMIS',
  email_preferences_update = 'Updated email preferences',
  raisers_edge_constituent_created = "Created by Raiser's Edge NXT",
  raisers_edge_constituent_updated = "Updated by Raiser's Edge NXT",
  raisers_edge_constituent_summary = "Raiser's Edge NXT Giving Summary updated",
  raisers_edge_gift_added = "Raiser's Edge NXT gift added",
}

export const Flavors = enumToObject(EFlavors);
export type Flavors = typeof Flavors;

interface ILocation {
  accuracy_radius?: number;
  latitude?: number;
  longitude?: number;
  metro_code?: number;
  time_zone?: string;
}

interface ICustomData {
  [key: string]: string | string[] | number | boolean | undefined;
}

export interface IUTMParams {
  utm_campaign: string;
  utm_medium: string;
  utm_source: string;
  utm_term: string;
  utm_content: string;
}

export interface IBreadcrumb extends IBaseAttributes {
  d_c: string;
  a_id: string;
  e_id: string;
  p_id: string;
  f_id: string;
  ttd_id: string;
  tag_id?: string;
  lp_id?: string;
  plp_id?: string;
  t_id?: string;
  at_id?: string;
  cpn_id?: string;
  pcpn_id?: string;
  sreq_id?: string;
  crv_id?: string;
  per_id: string;
  ses_id: string;
  cl_id?: string;
  form_id?: string;
  fl_id?: string;
  q_id?: string;
  flvr: string;
  pform: string;
  brow: string;
  adx_anon?: string;
  adx_vendor?: string;
  rfr: string;
  trgt: string;
  loc_url: string;
  msg?: string;
  ip: string;
  isp: string;
  s_w: number;
  s_h: number;
  b_w: number;
  b_h: number;
  payload?: ICustomData;
  others_list?: string[];
  utm_params?: IUTMParams;
  custom_data: ICustomData;
  loc: ILocation;
}

export interface IBreadcrumbSuggestion extends Record<string, unknown> {
  occurrence: number;
  value: string;
}

export interface IBreadcrumbListParams extends IListParams<IBreadcrumb> {
  predicates: Array<IPredicate | IPredicateGroup>;
  mode: 'match_all' | 'match_any';
  lookback_mode: 'absolute' | 'unbounded' | 'relative' | 'before';
  lookback_value: string | number;
  exclude: ['breadcrumbs'];
}

export class Breadcrumb extends Model<IBreadcrumb> {
  public readonly className = 'Breadcrumb';

  // Breadcrumbs are now always linked to a Person.

  public constraints = {};
}

export class Breadcrumbs extends Collection<Breadcrumb> {
  @observable public stream: Breadcrumb[] = [];

  constructor(initialModels: Array<Partial<IBreadcrumb>>, store: ICollectionStore = {}) {
    super(initialModels, store);

    makeObservable(this);
  }

  public getClassName(): string {
    return 'breadcrumbs';
  }

  public getModel(attributes: Partial<IBreadcrumb>): Breadcrumb {
    return new Breadcrumb(attributes);
  }

  /**
   * @param listId Use the useId() hook to generate a unique listID.
   */
  public list(
    params: Partial<IBreadcrumbListParams>,
    options: Partial<IListOptions> = {},
    listId?: string,
  ): ListResponse<Breadcrumb> {
    return super.list(params, options, listId);
  }

  public async getSuggestions(path: string, query: string): Promise<IBreadcrumbSuggestion[]> {
    const response = await wretch<IBreadcrumbSuggestion[]>(
      this.url('suggestions', [path, encodeURIComponent(query)]),
      {
        method: 'GET',
        headers: this.getHeaders(),
      },
    );

    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public url(): string;

  /**
   * @param value (required) person id
   */
  public url(variant: 'person' | 'person.flavors', value: string): string;

  /**
   * @param value (required) [ path, query ]
   */
  public url(variant: 'suggestions', value: [string, string]): string;

  public url(
    variant?: 'person' | 'person.flavors' | 'suggestions',
    value?: string | string[],
  ): string {
    if (
      variant &&
      ['person', 'person.flavors', 'suggestions'].includes(variant) &&
      (Array.isArray(value) ? !value.length : !value)
    ) {
      throw new Error(`Value is required when variant is "${variant}".`);
    }

    switch (variant) {
      case 'person':
        return `${this.getHostname()}persons/${value}/breadcrumbs/`;

      case 'person.flavors':
        return `${this.getHostname()}persons/${value}/breadcrumbs/flvrs`;

      case 'suggestions':
        if (!Array.isArray(value)) {
          throw new Error(`Value should be an array when variant is "${variant}".`);
        }
        return `${this.getHostname()}${value[0]}/?q=${encodeURIComponent(value[1])}`;

      default:
        return `${this.getHostname()}breadcrumbs/page/`;
    }
  }
}
