import type { ReactNode } from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { StoresContext } from '@feathr/extender/state';
import { useDocumentTitle } from '@feathr/hooks';

interface IUsePageTitleProps {
  pageTitle?: string;
  title?: ReactNode;
}

function usePageTitle({ pageTitle, title }: IUsePageTitleProps): void {
  const { eventId } = useParams<{ eventId: string }>();
  const { Events } = useContext(StoresContext);

  const titleSuffix = eventId ? Events.get(eventId)?.name : 'Feathr';
  const titleString = pageTitle ? pageTitle : title && typeof title === 'string' ? title : '';

  useDocumentTitle(titleString ? `${titleString} | ${titleSuffix}` : titleSuffix);
}

export default usePageTitle;
