import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { LandingPageCampaign } from '@feathr/blackbox';
import ReportActions from '@feathr/extender/components/PageHeaderActions';
import { useReportExport } from '@feathr/extender/hooks';

import CampaignReportSection, { getCampaignReportConfig } from '../CampaignReportSection';
import LandingPageCampaignPage from './LandingPageCampaignPage';

interface IProps {
  campaign: LandingPageCampaign;
}

function DefaultLandingPageCampaignPage({ campaign }: Readonly<IProps>): JSX.Element {
  const config = getCampaignReportConfig(campaign);
  const reportExportProps = useReportExport({ model: campaign, config });
  const { t } = useTranslation();

  function wrapActions(children: ReactNode): JSX.Element {
    return (
      <ReportActions campaign={campaign} {...reportExportProps}>
        {children}
      </ReportActions>
    );
  }

  return (
    <LandingPageCampaignPage title={t('Report')} wrapActions={wrapActions}>
      <CampaignReportSection campaign={campaign} {...reportExportProps} />
    </LandingPageCampaignPage>
  );
}

export default observer(DefaultLandingPageCampaignPage);
