import cloneDeep from 'lodash.clonedeep';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';

import { Section, SectionGroup } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import AchInfo from '@feathr/extender/components/AchInfo';
import BillListTable from '@feathr/extender/components/BillTable/BillListTable';
import { useAccount } from '@feathr/extender/state';

import type { IInvoiceFilters } from './InvoicesFilters';
import InvoicesFilters from './InvoicesFilters';

function InvoicesPage(): JSX.Element {
  const account = useAccount();
  const [filters, setFilters] = useState<IInvoiceFilters>({
    __raw__: {
      account: account.id,
      $and: [],
    },
  });

  const fixedFilters = cloneDeep(filters);
  if (fixedFilters.__raw__.$and?.length === 0) {
    delete fixedFilters.__raw__.$and;
  }

  return (
    <Page title={'Invoices'}>
      <SectionGroup>
        <Section
          description={
            'Use this information to pay with domestic ACH, international wire transfer, or check.'
          }
          title={'Payment Information'}
        >
          <AchInfo />
        </Section>
        <Section
          description={'Use the filters to find invoices from any project in your account.'}
          title={'View Invoices'}
        >
          <BillListTable
            filterElements={[
              <InvoicesFilters filters={fixedFilters} key={'filters'} setFilters={setFilters} />,
            ]}
            filters={filters}
            isLoading={account.isPending}
            key={'invoices'}
          />
        </Section>
      </SectionGroup>
    </Page>
  );
}

export default observer(InvoicesPage);
