import type { JSX } from 'react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CardV2 as Card } from '@feathr/components';
import BillableCard from '@feathr/extender/components/BillableCard';
import { StoresContext, useAccount, useLocalUrl } from '@feathr/extender/state';

import BillableDefaultMissing from './BillableDefaultMissing';

function BillableDefault(): JSX.Element {
  const { Billables } = useContext(StoresContext);
  const account = useAccount();
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const billable = account.primaryBillableId ? Billables.get(account.primaryBillableId) : undefined;
  const hasPrimary = !!billable;

  return (
    <Card theme={'selected'} width={'wide'}>
      <Card.Header
        description={
          <Trans t={t}>
            This is the billing configuration for your{' '}
            <Link to={localUrl('/settings/billing/license')}>Feathr license</Link> and the default
            billing configuration for all media bills.
          </Trans>
        }
        title={t('Primary Billing Configuration')}
      />
      <Card.Content>
        {!hasPrimary ? <BillableDefaultMissing /> : <BillableCard billable={billable} />}
      </Card.Content>
    </Card>
  );
}

export default BillableDefault;
