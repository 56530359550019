import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Segment as Group, TPredicateMode } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';

import LookbackMode from './LookbackMode';

import * as styles from './GroupModeControls.css';

interface IProps {
  disabled?: boolean;
  group: Group;
}

interface IModeOption extends ISelectOption {
  id: TPredicateMode;
}

function GroupModeControls({ disabled = false, group }: IProps): JSX.Element {
  const { t } = useTranslation();
  const mode = group.get('mode');

  const modeOptions: IModeOption[] = [
    { id: 'match_all', name: t('all') },
    { id: 'match_any', name: t('any') },
  ];

  const value = modeOptions.find(({ id }) => id === mode) ?? modeOptions[0];

  function handleChangeMode({ id }: IModeOption): void {
    group.set({ mode: id });
  }

  const disabledMode = (
    <span className={styles.disabled}>{t('{{mode}}', { mode: value.name })}</span>
  );

  return (
    <div className={styles.root}>
      <Trans t={t}>People matching</Trans>{' '}
      {disabled ? (
        disabledMode
      ) : (
        <Select
          className={styles.mode}
          onSelectSingle={handleChangeMode}
          options={modeOptions}
          value={value}
        />
      )}{' '}
      <Trans t={t}>of the following filters, looking at data</Trans>
      <LookbackMode disabled={disabled} group={group} />
    </div>
  );
}

export default observer(GroupModeControls);
