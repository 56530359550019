import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Goal, Redirect, RedirectDomain, TrackedLinkCampaign } from '@feathr/blackbox';
import { Button, Form } from '@feathr/components';

import CampaignSummary from '../../CampaignSummary';
import type { ICampaignValidationErrors } from '../../CampaignSummary/CampaignSummary';

import * as styles from '../CampaignEdit/CampaignEdit.css';

interface IProps {
  campaign: TrackedLinkCampaign;
  domain: RedirectDomain;
  goals: IObservableArray<Goal>;
  onPrev: () => void;
  redirects: IObservableArray<Redirect>;
  saveButton: ReactNode;
  validationErrors: ICampaignValidationErrors;
}

function TrackedLinkCampaignEditStepFour({
  campaign,
  onPrev,
  saveButton,
  validationErrors,
}: IProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        saveButton,
      ]}
      className={styles.formRoot}
      description={
        <div className={styles.fieldset}>
          <p>
            {t(
              "Check the summary below to make sure everything is configured correctly. When you're ready, click Publish to launch your campaign!",
            )}
          </p>
        </div>
      }
      label={t('Edit Campaign: Review')}
    >
      <CampaignSummary campaign={campaign} validationErrors={validationErrors} />
    </Form>
  );
}

export default observer(TrackedLinkCampaignEditStepFour);
