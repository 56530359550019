export const defaultPersonAttributes = [
  {
    id: 'name',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Name',
    is_default: true,
  },
  {
    id: 'first_name',
    collection: 'Person',
    data_type: 'str',
    u_key: 'First Name',
    is_default: true,
  },
  {
    id: 'last_name',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Last Name',
    is_default: true,
  },
  {
    id: 'email',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Primary email',
    is_default: true,
  },
  {
    id: 'emails',
    collection: 'Person',
    data_type: 'list',
    u_key: 'Emails',
    is_default: true,
  },
  {
    id: 'income',
    collection: 'Person',
    data_type: 'float',
    u_key: 'Income',
    is_default: true,
  },
  {
    id: 'language',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Language',
    is_default: true,
  },
  {
    id: 'companies',
    collection: 'Person',
    data_type: 'list',
    u_key: 'Companies',
    is_default: true,
  },
  {
    id: 'occupation',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Occupation',
    is_default: true,
  },
  {
    id: 'external_id',
    collection: 'Person',
    data_type: 'str',
    u_key: 'External ID',
    is_default: true,
  },
  {
    id: 'phone',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Phone',
    is_default: true,
  },
  {
    id: 'address',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address',
    is_default: true,
  },
  {
    id: 'address.administrative_area_name',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: State',
    is_default: true,
  },
  {
    id: 'address.country_name',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: Country',
    is_default: true,
  },
  {
    id: 'address.postal_code',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: Postal Code',
    is_default: true,
  },
  {
    id: 'address.locality',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: City',
    is_default: true,
  },
  {
    id: 'address.premise1',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: Line 1',
    is_default: true,
  },
  {
    id: 'address.premise2',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: Line 2',
    is_default: true,
  },
  {
    id: 'address.timezone',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: Timezone',
    is_default: true,
  },
  {
    id: 'pp_opt_outs.all',
    collection: 'Person',
    data_type: 'bool',
    u_key: 'Opt Out (Unsubscribed)',
    is_default: true,
  },
];
