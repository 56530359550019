import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { DataRequest, Segment } from '@feathr/blackbox';
import type { ButtonType } from '@feathr/components';
import { ButtonValid } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface IProps {
  children?: React.ReactNode;
  dataRequest: DataRequest;
  conversionPixel?: Segment;
  onNext: () => void;
  type?: ButtonType;
  validate: (request: DataRequest, conversionPixel?: Segment) => TValidateGrouped;
}

function ButtonNext({
  children = 'Next',
  conversionPixel,
  dataRequest,
  onNext,
  type = 'primary',
  validate,
}: IProps): JSX.Element {
  const errors = flattenErrors(validate(dataRequest, conversionPixel));
  return (
    <ButtonValid errors={errors} onClick={onNext} type={type}>
      {children}
    </ButtonValid>
  );
}

export default observer(ButtonNext);
