import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import type { Campaign } from '@feathr/blackbox';
import {
  CampaignClass,
  CampaignLabelMap,
  CampaignState as CampaignStateEnum,
} from '@feathr/blackbox';
import { Card, Tooltip } from '@feathr/components';
import { CampaignIcon } from '@feathr/extender/components/CampaignIcons';
import CampaignOptions from '@feathr/extender/components/CampaignOptions';
import { useLocalUrl } from '@feathr/extender/state';

import CampaignState from './CampaignState';

import * as styles from './CampaignCard.css';

interface IProps {
  className?: string;
  campaign: Campaign;
}

function CampaignCard({ className, campaign }: IProps) {
  const localUrl = useLocalUrl();

  let link: string;

  if (
    campaign.attributes.state === 'draft' &&
    campaign.attributes._cls !== CampaignClass.LandingPage
  ) {
    link = localUrl(campaign.getItemUrl('edit'));
  } else {
    link = localUrl(campaign.getItemUrl());
  }

  const campaignType = CampaignLabelMap.get(campaign.get('_cls')) || 'Campaign';
  const isDraft = (campaign && campaign.get('state') === CampaignStateEnum.Draft) || false;
  const tooltip = isDraft ? `${campaignType} campaign (draft)` : `${campaignType} campaign`;

  return (
    <Card
      actions={[
        <Tooltip key={'more'} title={'Campaign actions'}>
          <CampaignOptions campaign={campaign} showFlightOptions={true} />
        </Tooltip>,
      ]}
      className={classNames(styles.campaignCard, className)}
      link={link}
      nested={true}
      raised={true}
      title={campaign.name}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Tooltip title={tooltip}>
          <CampaignIcon campaign={campaign} className={styles.icon} hover={false} size={36} />
        </Tooltip>
        <CampaignState state={campaign.get('state')} />
      </div>
    </Card>
  );
}

export default observer(CampaignCard);
