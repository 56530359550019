import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { Account, BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IPermissionColumn {
  shouldShow?: (account: Account) => boolean;
}

const MonetizationValueColumn: IPermissionColumn & IColumn<BaseCampaign> = {
  id: 'monetization_value',
  checkboxLabel: 'Monetization Value',
  Header: TableColumnHeader({
    title: 'Monetization Value',
    sortType: 'numeric',
  }),
  headerClassName: tableStyles.sort,
  className: tableStyles.cellRight,
  width: 180,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const value = original.get('monetization_value') || 0;
          return <>{numeral(value).format('$0,0.00')}</>;
        }}
      </Observer>
    );
  },
  Footer({ data }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const total = data.reduce((subtotal, currentValue) => {
            const value = currentValue._original.get('monetization_value') || 0;
            return subtotal + value;
          }, 0);
          return <>{numeral(total).format('$0,0.00')}</>;
        }}
      </Observer>
    );
  },
  shouldShow: (account: Account): boolean => account.hasMonetization,
};

export default MonetizationValueColumn;
