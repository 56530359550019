import React from 'react';
import { useTranslation } from 'react-i18next';
import { useClipboard } from 'use-clipboard-copy';

import type { APIKey } from '@feathr/blackbox';
import { ArchiveModalV1, ContextMenu, MenuItem, toast } from '@feathr/components';
import { useToggle } from '@feathr/hooks';
interface IOptionsColumnProps {
  apiKey: APIKey;
}

function APIKeyOptions({ apiKey }: IOptionsColumnProps) {
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const { t } = useTranslation();

  const clipboard = useClipboard({
    onSuccess: () => {
      toast(t('Successfully copied to clipboard.'), { type: 'success' });
    },
    onError: () => {
      toast(t("Couldn't copy to clipboard. Please try again."), { type: 'error' });
    },
  });

  function handleCopy() {
    clipboard.copy(apiKey.get('key'));
  }

  return (
    <>
      <ContextMenu>
        <MenuItem onClick={toggleArchiveModalOpen}>{t('Revoke')}</MenuItem>
        <MenuItem onClick={handleCopy}>{t('Copy to clipboard')}</MenuItem>
      </ContextMenu>
      {isArchiveModalOpen && (
        <ArchiveModalV1
          confirmButtonText={t('Revoke key')}
          message={t('Are you sure you want to revoke this API key?')}
          method={'delete'}
          model={apiKey}
          onClose={toggleArchiveModalOpen}
          t={t}
          title={t('Revoke API key')}
        />
      )}
    </>
  );
}

export default APIKeyOptions;
