import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@feathr/components';

interface IProps {
  onClick: () => void;
}

function FilterCampaignsButton({ onClick }: IProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Button onClick={onClick} prefix={<Icon icon={faFilter} />}>
        {t('Filters')}
      </Button>
    </>
  );
}

export default FilterCampaignsButton;
