import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { ButtonValid, CardV2 as Card, Fieldset, Input, Label } from '@feathr/components';
import { flattenErrors, useUniqueKeys } from '@feathr/hooks';
import type { IValidateGrouped, TValidateDetailed } from '@feathr/rachis';

import AdditionalEmail from './AdditionalEmail';

import * as styles from './EmailAddresses.css';

interface IProps {
  person: Person;
  validationErrors: IValidateGrouped;
}

function validateAdditionalEmails(person: Person): TValidateDetailed {
  return person.validate(['emails'], false, 'detailed').errors;
}

function EmailAddresses({ person, validationErrors }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const [emails, setEmailsKeys] = useUniqueKeys(person.get('emails') || []);

  function setNewEmails(newEmails: Array<{ key?: string; value: string }>): void {
    setEmailsKeys(newEmails);
    person.set({ emails: newEmails.map(({ value }) => value) });
  }

  function handleChangeEmail(newValue: string, key: string): void {
    const newEmails = (function (): Array<{ key: string; value: string }> {
      const emailToUpdate = emails.find((email) => email.key === key);
      if (emailToUpdate) {
        emailToUpdate.value = newValue;
      }
      return emails;
    })();
    setNewEmails(newEmails);
  }

  function handleAddEmail(): void {
    const newEmails = [...emails, { value: '' }];
    setNewEmails(newEmails);
  }

  function handleRemoveEmail(index: number): void {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setNewEmails(newEmails);
  }

  const hasValidPrimaryEmail = !!person.get('email') && !validationErrors.email;
  const noPrimaryError = t('A valid primary address is required to use additional addresses.');
  const errors = flattenErrors(validationErrors);

  if (!hasValidPrimaryEmail) {
    errors.push(noPrimaryError);
  }

  const props = {
    emails,
    handleChangeEmail,
    handleRemoveEmail,
    validationErrors: validateAdditionalEmails(person),
  };

  return (
    <Card>
      <Card.Header title={t('Emails')} />
      <Card.Content>
        <Fieldset>
          <Input
            attribute={'email'}
            key={'email'}
            label={t('Primary')}
            model={person}
            name={'primary-email-input'}
            type={'email'}
          />
        </Fieldset>
        {emails.length > 0 && (
          <Fieldset className={styles.fieldset}>
            <Label className={styles.label}>{t('Additional')}</Label>
            {emails.map((email, index) => (
              <AdditionalEmail {...props} email={email} index={index} key={email.key} />
            ))}
          </Fieldset>
        )}
      </Card.Content>
      <Card.Actions>
        <ButtonValid
          disabled={!hasValidPrimaryEmail || !!validationErrors.emails?.length}
          errors={errors}
          onClick={handleAddEmail}
          prefix={<FontAwesomeIcon icon={faPlus} />}
        >
          {t('Add email address')}
        </ButtonValid>
      </Card.Actions>
    </Card>
  );
}

export default observer(EmailAddresses);
