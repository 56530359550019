import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ImisIntegration } from '@feathr/blackbox';
import { EIntegrationSyncState, integrationSyncStateTranslationMap } from '@feathr/blackbox';
import { Chip } from '@feathr/components';
import {
  templateTypeColorSyncState,
  templateTypeIconSyncState,
} from '@feathr/extender/styles/integrations';

import * as styles from './ImisDownSyncStatus.css';

interface IProps {
  integration?: ImisIntegration;
}

function ImisDownSyncStatus({ integration }: IProps): JSX.Element {
  const { t } = useTranslation();
  const state = integration ? integration.syncState : EIntegrationSyncState.notSynced;

  function getTooltipMessage(state: EIntegrationSyncState): string {
    switch (state) {
      case EIntegrationSyncState.notSynced:
        return t('We have not been able to get your data yet.');

      case EIntegrationSyncState.syncInProgress:
        return t('We are currently getting your data. This may take a while.');

      case EIntegrationSyncState.syncComplete:
        return t('Your data is up to date.');

      default:
        return t("We don't know the state of your data sync.");
    }
  }

  return (
    <div className={styles.content}>
      <Chip
        prefix={<FontAwesomeIcon icon={templateTypeIconSyncState(state)} />}
        theme={templateTypeColorSyncState(state)}
        tooltip={getTooltipMessage(state)}
      >
        {integrationSyncStateTranslationMap(t)[state]}
      </Chip>
    </div>
  );
}

export default observer(ImisDownSyncStatus);
