import { faArrowsRotate, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ITemplate, PinpointEmailBaseCampaign, Template } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import {
  Button,
  EmailTemplateSelect as EmailTemplateSelectComponent,
  Icon,
} from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import type { TTemplateSelectAndEditState } from '../TemplateSelectAndEditModal';
import TemplateSelectAndEditModal from '../TemplateSelectAndEditModal';

import * as styles from './EmailTemplateSelect.css';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
  disabled?: boolean;
  /** Update template before saving */
  onPreChangeTemplate?: (template: Template, patch: Partial<ITemplate>) => Partial<ITemplate>;
  template?: Template;
}

function EmailTemplateSelect({
  campaign,
  disabled,
  onPreChangeTemplate,
  template: originalTemplate,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { Templates } = useContext(StoresContext);

  // TODO: quit faking it and use the selected template #3704
  const fakeItTillYouMakeIt = '66d8d123ffa8b02fe83b321d';
  /*
   * TODO: bala wanted to make this drip campaign specific but the steps themselves are auto sends
   * perhaps add a context prop to the modal to choose drip or pinpoint
   */
  const template = originalTemplate ?? Templates.get(fakeItTillYouMakeIt);

  const disclosure = useDisclosure();
  const [, { open: openTemplateModal }] = disclosure;

  const [initialState, setInitialState] = useState<TTemplateSelectAndEditState | undefined>();

  async function handleTemplateChange(templateId?: string): Promise<void> {
    if (!templateId) {
      // Remove template
      campaign.set({ template_id: undefined });
      return;
    }

    // Save changes to campaign before cloning template
    await campaign.patchDirty();
    // Clone template, attach to campaign, update name to match campaign
    const clonedTemplate = await Templates.clone(templateId, {
      campaign_id: campaign.id,
      event_id: campaign.get('event'),
      _cls: TemplateClass.PinpointEmail,
    });
    let templatePatch: Partial<ITemplate> = { name: campaign.get('name') };
    // Allow implementing components to modify template before it is saved
    if (onPreChangeTemplate) {
      templatePatch = onPreChangeTemplate(clonedTemplate, templatePatch);
    }
    clonedTemplate.set(templatePatch);
    await clonedTemplate.patchDirty();

    // Attach template to campaign
    await campaign.addTemplate(clonedTemplate);
  }

  // Open template modal for editing
  function handleEditTemplate(): void {
    setInitialState('editing');
    openTemplateModal();
  }

  // Open template modal for selecting a new template
  function handleChooseTemplate(): void {
    setInitialState('selecting');
    openTemplateModal();
  }

  return (
    <EmailTemplateSelectComponent
      description={t('Get started by selecting a template')}
      isLoading={template?.isPending}
      previewUrl={template ? campaign.getTemplatePreviewUrl() : undefined}
      theme={'slate'}
      title={template === undefined ? t('No template selected') : template.name}
    >
      {template === undefined ? (
        <Button disabled={disabled} onClick={openTemplateModal}>
          {t('Select template')}
        </Button>
      ) : (
        <Group className={styles.actions} grow={true} justify={'space-between'}>
          <Button
            disabled={disabled}
            onClick={handleEditTemplate}
            prefix={<Icon icon={faPenToSquare} />}
          >
            {t('Edit content')}
          </Button>
          <Button
            disabled={disabled}
            onClick={handleChooseTemplate}
            prefix={<Icon icon={faArrowsRotate} />}
          >
            {t('Change template')}
          </Button>
        </Group>
      )}
      <TemplateSelectAndEditModal
        disclosure={disclosure}
        initialState={initialState}
        onChange={handleTemplateChange}
        templateClass={TemplateClass.PinpointEmail}
        templateId={template?.id}
      />
    </EmailTemplateSelectComponent>
  );
}

export default observer(EmailTemplateSelect);
