import type { Campaign, IConfig } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';

enum EActivityKeys {
  includeActivityBreakdown = 'includeActivityBreakdown',
  includeActivitySummary = 'includeActivitySummary',
  includeAdActivity = 'includeAdActivity',
  includeCampaignEngagement = 'includeCampaignEngagement',
  includeConversionsTable = 'includeConversionsTable',
  includeCreativesTable = 'includeCreativesTable',
  includeDailyStatsTable = 'includeDailyStatsTable',
  includeEmailActivity = 'includeEmailActivity',
  includeGoogleActivity = 'includeGoogleActivity',
  includeFormSubmissionsTable = 'includeFormSubmissionsTable',
  includeHeatmap = 'includeHeatmap',
  includeKeywordStatsTable = 'includeKeywordStatsTable',
  includeLeadsSummary = 'includeLeadsSummary',
  includeLinkClicksTable = 'includeLinkClicksTable',
  includeMonetizationROI = 'includeMonetizationROI',
  includePageActivity = 'includePageActivity',
  includePartnersStatsTable = 'includePartnersStatsTable',
  includeROI = 'includeROI',
  includeSpendPerformance = 'includeSpendPerformance',
  includeTrafficBreakdown = 'includeTrafficBreakdown',
}

const {
  PinpointEmail: SingleSend,
  SmartPinpointEmail: SmartSend,
  AutoPinpointEmail: AutoSend,
  LandingPage,
  Referral,
  Segment: Retargeting,
  EmailList,
  Search,
  MobileGeoFencing,
  MobileGeoFenceRetargeting,
  Lookalike,
  SeedSegment,
  Affinity,
  GoogleAdsSmart,
  Facebook,
  EmailListFacebook,
  TrackedLink,
  DripCampaign: Drip,
} = CampaignClass;

const {
  includeAdActivity: adActivity,
  includeEmailActivity: emailActivity,
  includePageActivity: pageActivity,
  includeHeatmap: heatmap,
  includeActivityBreakdown: activityBreakdown,
  includeActivitySummary: activitySummary,
  includeLeadsSummary: leads,
  includeFormSubmissionsTable: formSubmissions,
  includeDailyStatsTable: dailyStats,
  includePartnersStatsTable: partners,
  includeCampaignEngagement: engagement,
  includeTrafficBreakdown: trafficBreakdown,
  includeGoogleActivity: googleActivity,
  includeKeywordStatsTable: keywords,
  includeROI: roi,
  includeConversionsTable: conversions,
  includeLinkClicksTable: linkClicks,
  includeCreativesTable: creatives,
  includeSpendPerformance: spendPerformance,
  includeMonetizationROI: monetizationROI,
} = EActivityKeys;

const CampaignClassActivityKeyMap = new Map<CampaignClass, Array<keyof IConfig>>([
  [LandingPage, [pageActivity, heatmap, activityBreakdown, leads, formSubmissions, dailyStats]],
  [
    Referral,
    [
      adActivity,
      emailActivity,
      pageActivity,
      heatmap,
      leads,
      activityBreakdown,
      formSubmissions,
      partners,
      dailyStats,
    ],
  ],
  [Retargeting, [adActivity, heatmap, activityBreakdown, dailyStats, engagement]],
  [EmailList, [adActivity, heatmap, activityBreakdown, dailyStats, engagement]],
  [Search, [adActivity, heatmap, activityBreakdown, dailyStats, engagement]],
  [MobileGeoFencing, [adActivity, heatmap, activityBreakdown, dailyStats, engagement]],
  [MobileGeoFenceRetargeting, [adActivity, heatmap, activityBreakdown, dailyStats, engagement]],
  [TrackedLink, [trafficBreakdown, pageActivity, activityBreakdown, dailyStats]],
  [Lookalike, [adActivity, heatmap, activityBreakdown, dailyStats, engagement]],
  [SeedSegment, [adActivity, heatmap, activityBreakdown, dailyStats, engagement]],
  [Affinity, [adActivity, heatmap, activityBreakdown, dailyStats, engagement]],
  [GoogleAdsSmart, [googleActivity, keywords]],
  [Facebook, [adActivity, dailyStats, engagement]],
  [EmailListFacebook, [adActivity, dailyStats, engagement]],
  [SingleSend, [emailActivity, roi, conversions, engagement]],
  [SmartSend, [emailActivity, roi, conversions, engagement]],
  [AutoSend, [emailActivity, roi, conversions, engagement]],
  [Drip, [linkClicks, engagement]],
]);

const configDefault: IConfig = {
  attributionModel: 'full',
  [activityBreakdown]: false,
  [activitySummary]: true,
  [adActivity]: false,
  [conversions]: false,
  [creatives]: true,
  [dailyStats]: false,
  [emailActivity]: false,
  [heatmap]: false,
  [leads]: false,
  [formSubmissions]: false,
  [pageActivity]: false,
  [roi]: false,
  [spendPerformance]: true,
  [trafficBreakdown]: false,
  [monetizationROI]: false,
  [linkClicks]: true,
};

export function getCampaignReportConfig(campaign: Campaign): IConfig {
  const activityKeys: Array<keyof IConfig> = CampaignClassActivityKeyMap.get(campaign.get('_cls'))!;
  const config: IConfig = activityKeys
    ? activityKeys.reduce((newConfig, key) => {
        return { ...newConfig, [key]: true };
      }, configDefault)
    : configDefault;
  if (!campaign.isCreatedAfterScriptedUpdates) {
    /**
     * There are discrepancies in statistics for campaigns
     * that were running before and after scripted updates went live.
     * We do not want to show this component if that is the case.
     */
    delete config.includeCampaignEngagement;
  }
  return config;
}
