import isEqual from 'lodash.isequal';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { FacebookCampaign, IFacebookPage, TPublisherPlatforms } from '@feathr/blackbox';
import { Select } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import FacebookPageOption from './FacebookPageOption';
import FacebookPageSingleValue from './FacebookPageSingleValue';
import PlatformOption from './PlatformOption';

interface IFacebookPageSelectProps {
  campaign: FacebookCampaign;
  setPage: (page: IFacebookPage) => void;
}

interface IPlatformOption {
  id: string;
  isDisabled: boolean;
  name: string;
  platforms: TPublisherPlatforms[];
  tooltip?: JSX.Element;
}

function FacebookPageSelect({ campaign, setPage }: IFacebookPageSelectProps): JSX.Element {
  const { FacebookPages } = useContext(StoresContext);
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  const basePlatformOptions: IPlatformOption[] = [
    {
      id: 'facebook_only',
      isDisabled: false,
      name: t('Facebook only'),
      platforms: ['facebook', 'messenger', 'audience_network'],
    },
    {
      id: 'instagram_only',
      isDisabled: false,
      name: t('Instagram only'),
      platforms: ['instagram', 'messenger', 'audience_network'],
    },
    {
      id: 'facebook_and_instagram',
      isDisabled: false,
      name: t('Facebook and Instagram'),
      platforms: ['facebook', 'instagram', 'messenger', 'audience_network'],
    },
  ];

  const disabledTooltip: JSX.Element = (
    <Trans t={t}>
      <p>
        Instagram platforms cannot be selected because Feathr doesn't have appropriate permissions
        to advertise on Instagram.
        <br />
        <a href={localUrl('/settings/integrations/meta')} target={'_blank'}>
          Manage your Meta integration to grant permissions.
        </a>
      </p>
    </Trans>
  );

  const disabledPlatformOptions: IPlatformOption[] = [
    {
      id: 'facebook_only',
      isDisabled: false,
      name: t('Facebook only'),
      platforms: ['facebook', 'messenger', 'audience_network'],
    },
    {
      id: 'instagram_only',
      isDisabled: true,
      name: t('Instagram only'),
      platforms: ['instagram', 'messenger', 'audience_network'],
      tooltip: disabledTooltip,
    },
    {
      id: 'facebook_and_instagram',
      isDisabled: true,
      name: t('Facebook and Instagram'),
      platforms: ['facebook', 'instagram', 'messenger', 'audience_network'],
      tooltip: disabledTooltip,
    },
  ];

  const [selectedFacebookPage, setSelectedFacebookPage] = useState<
    IFacebookPage | undefined | null
  >();
  const [selectedPlatform, setSelectedPlatform] = useState<IPlatformOption | null>();
  const [platformOptions, setPlatformOptions] = useState<IPlatformOption[]>(basePlatformOptions);
  const [allowedPages, setAllowedPages] = useState<IFacebookPage[] | undefined>(undefined);
  const facebookPageId = campaign.get('facebook_page_id');
  const platformValue = campaign.get('publisher_platforms');
  const facebookPages = FacebookPages.list();

  useEffect(() => {
    if (!facebookPages.isPending) {
      const pages = getAllowedPages();
      setAllowedPages(pages);
      const page = pages.find((p) => p.id === facebookPageId);
      if (page) {
        setSelectedFacebookPage(page);
        setPage(page);
        const {
          instagram: { instagram_actor_id: instagramActorId },
        } = page;
        instagramActorId === null
          ? setPlatformOptions(disabledPlatformOptions)
          : setPlatformOptions(basePlatformOptions);
        if (!campaign.get('instagram_actor_id') && instagramActorId) {
          campaign.set({ instagram_actor_id: instagramActorId });
        }
        if (platformValue) {
          const selected = platformOptions.find((o) => isEqual(o.platforms, platformValue));
          if (selected) {
            setSelectedPlatform(selected);
          }
        } else {
          setDefaultPlatform(instagramActorId);
        }
      }
    }
  }, [facebookPages.isPending]);

  function getAllowedPages(): IFacebookPage[] {
    let pages: IFacebookPage[] = [];
    if (facebookPages) {
      pages = facebookPages.models.map((p) => p.toJS()).filter((p) => p.feathr_has_agency);
    }
    return pages;
  }

  function setDefaultPlatform(instagramActorId): void {
    const defaultPlatform: IPlatformOption | undefined =
      instagramActorId === null
        ? platformOptions.find((o) => (o.id = 'facebook_only'))
        : platformOptions.find((o) => o.id === 'facebook_and_instagram');
    setSelectedPlatform(defaultPlatform);
    campaign.set({
      publisher_platforms: defaultPlatform?.platforms,
    });
  }

  function handlePageSelect(page: IFacebookPage): void {
    const {
      id,
      instagram: { instagram_actor_id: instagramActorId },
    } = page;

    setSelectedFacebookPage(page);
    setPage(page);
    instagramActorId === null
      ? setPlatformOptions(disabledPlatformOptions)
      : setPlatformOptions(basePlatformOptions);

    // If the page has changed use the default value for that page
    if (selectedFacebookPage?.id !== id) {
      setDefaultPlatform(instagramActorId);
    }

    campaign.set({
      facebook_page_id: id,
      instagram_actor_id: instagramActorId,
    });
  }

  function handlePlatformSelect(platform: IPlatformOption): void {
    setSelectedPlatform(platform);
    const { platforms } = platform;
    campaign.set({ publisher_platforms: platforms });
  }

  return (
    <>
      <Select<IFacebookPage>
        components={{ Option: FacebookPageOption, SingleValue: FacebookPageSingleValue }}
        helpText={t(
          'The Facebook page and Instagram account (if connected) that will be used in this campaign. All creatives in this campaign will be delivered by the selected page.',
        )}
        isLoading={facebookPages.isPending}
        label={t('Facebook page & Instagram account')}
        name={'facebook_page_select'}
        onSelectSingle={handlePageSelect}
        options={allowedPages}
        placeholder={t('Select a facebook page...')}
        required={true}
        value={selectedFacebookPage}
      />
      {!facebookPages.isPending && selectedFacebookPage && (
        <Select<IPlatformOption>
          components={{ Option: PlatformOption }}
          helpText={
            <Trans t={t}>
              <p>
                Select which platforms ads from this campaign will be displayed on. Check out our{' '}
                <a
                  href={
                    'https://help.feathr.co/hc/en-us/articles/360048057553-How-to-launch-a-Facebook-campaign-from-Feathr'
                  }
                  target={'_blank'}
                >
                  help desk article
                </a>{' '}
                for more information on Meta publisher platforms.
              </p>
            </Trans>
          }
          label={t('Publisher platforms')}
          name={'publisher_platforms_select'}
          onSelectSingle={handlePlatformSelect}
          options={platformOptions}
          placeholder={t('Facebook and/or Instagram...')}
          required={true}
          value={selectedPlatform}
        />
      )}
    </>
  );
}

export default observer(FacebookPageSelect);
