import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  EIntegrationConnectionState,
  integrationStateTooltipTranslationMap,
  integrationStateTranslationMap,
} from '@feathr/blackbox';
import { Chip, Label } from '@feathr/components';
import {
  templateTypeColorState,
  templateTypeIconState,
} from '@feathr/extender/styles/integrations';

import * as styles from './RaisersEdgeIntegrationStatus.css';

interface IProps {
  state?: EIntegrationConnectionState;
  showLabel?: boolean;
}

function RaisersEdgeIntegrationStatus({ state, showLabel = true }: IProps): JSX.Element {
  const { t } = useTranslation();
  const currentState = state || EIntegrationConnectionState.notConnected;

  return (
    <div className={styles.content}>
      {showLabel && <Label className={styles.label}>{t('Integration status')}</Label>}
      <Chip
        prefix={<FontAwesomeIcon icon={templateTypeIconState(currentState)} />}
        theme={templateTypeColorState(currentState)}
        tooltip={integrationStateTooltipTranslationMap(t)[currentState]}
      >
        {integrationStateTranslationMap(t)[currentState]}
      </Chip>
    </div>
  );
}

export default observer(RaisersEdgeIntegrationStatus);
