import { faCaretDown, faCaretRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import capitalize from 'lodash.capitalize';
import type { JSX } from 'react';
import React from 'react';

import type { Bill } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Button, TableColumnHeader, Time, TimeRange } from '@feathr/components';
import { currencyFormatter } from '@feathr/extender/utils';
import { TimeFormat } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

export const BillListColumns: Array<IColumn<Bill>> = [
  {
    id: 'expander',
    sortable: false,
    expander: true,
    className: tableStyles.cellCenter,
    Expander({ isExpanded }): JSX.Element {
      return (
        <>
          {isExpanded ? (
            <FontAwesomeIcon icon={faCaretDown} />
          ) : (
            <FontAwesomeIcon icon={faCaretRight} />
          )}
        </>
      );
    },
  },
  {
    id: '_id',
    Header: TableColumnHeader({
      sortType: 'numeric',
      title: 'Date',
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    width: 170,
    Cell({ original }): JSX.Element {
      const period = original.get('period');
      if (!period) {
        return <Time format={TimeFormat.shortDate} timestamp={original.get('date_posted')} />;
      } else if (period.start === period.end) {
        return <Time format={TimeFormat.shortDate} timestamp={period.end} />;
      }

      return <TimeRange end={period.end} format={TimeFormat.shortDate} start={period.start} />;
    },
  },
  {
    id: 'total',
    Header: TableColumnHeader({
      sortType: 'numeric',
      title: 'Total',
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      return <>{currencyFormatter.format(original.get('total'))}</>;
    },
  },
  {
    id: 'stripe.status',
    Header: TableColumnHeader({
      sortType: 'alpha',
      title: 'Status',
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    Cell({ original }): JSX.Element {
      const stripe = original.get('stripe');
      if (stripe) {
        return stripe.credit_note ? <>Credited</> : <>{capitalize(stripe.status)}</>;
      }
      return <></>;
    },
  },
  {
    id: 'invoice',
    Header: TableColumnHeader({
      title: 'Invoice',
    }),
    sortable: false,
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      const stripe = original.get('stripe');
      return stripe && stripe.hosted_invoice_url ? (
        <Button
          href={`${BLACKBOX_URL}billing/bills/${original.id}/hosted-invoice-url`}
          target={'_blank'}
          type={'link'}
        >
          View on Stripe
        </Button>
      ) : (
        <></>
      );
    },
  },
];
