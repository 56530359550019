import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { Campaign, Targetable, Targeting } from '@feathr/blackbox';
import { CampaignState, TargetableClass } from '@feathr/blackbox';
import { Button, Card, FileUpload, Tooltip } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './SegmentTargeting/SegmentTargeting.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

async function parseFile(csvFile: File, targetable: Targetable): Promise<void> {
  const Papa = await import(/* webpackChunkName: "papaparse" */ 'papaparse');
  Papa.parse<Record<string, string>>(csvFile, {
    header: false,
    complete: (results) => {
      runInAction(() => {
        const uniqueTerms = new Set(results.data.filter((row) => !!row[0]).map((row) => row[0]));
        targetable.set({ num_keywords: uniqueTerms.size });
      });
    },
  });
}

function SearchKeywordTargeting({ campaign, targeting, onRemove }: IProps): JSX.Element {
  const { Targetables } = React.useContext(StoresContext);
  let targetable: Targetable;
  const targetableId = targeting.get('target_data');
  const canDeleteTarget = campaign.get('state') === CampaignState.Draft;
  const removeButtonTooltip = canDeleteTarget
    ? 'Remove'
    : 'Cannot remove keyword list from a published campaign.';
  if (targetableId) {
    targetable = Targetables.get(targetableId);
  } else {
    targetable = Targetables.create({
      _cls: TargetableClass.search,
      name: 'Search Keyword List Targeting',
      partner: campaign.get('parent_kind') === 'partner' ? campaign.get('parent') : undefined,
    });
    targeting.set({ target_data: targetable.get('id') });
  }

  async function handleOnUpload(key, container, file, filename): Promise<void> {
    const url = `https://s3.amazonaws.com/${container}/${encodeURIComponent(key!)}`;
    targetable.set({
      name: filename,
      src_search_keywords: url,
      src_filename: filename,
    });
    await parseFile(file as File, targetable);
  }

  return (
    <Card
      actions={[
        <Tooltip key={'remove'} title={removeButtonTooltip}>
          <Button
            disabled={!canDeleteTarget}
            name={'remove_targeting'}
            onClick={() => onRemove(targeting)}
            type={'naked'}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Tooltip>,
      ]}
    >
      <section>
        {targetable.get('src_search_keywords') ? (
          <>
            <label>File</label>
            <p>{targetable.get('src_filename')}</p>
            <label>Unique Keywords</label>
            <p>{numeral(targetable.get('num_keywords')).format('0,0')}</p>
            {!targetable.isValid([], false) && (
              <p className={styles.error}>{targetable.validate([], false).errors}</p>
            )}
          </>
        ) : (
          <>
            <FileUpload
              attribute={'src_search_keywords'}
              disabled={campaign.get('state') === CampaignState.Published}
              helpText={
                <>
                  <span>
                    Upload a <code>.csv</code> file of your target search keywords. The file should
                    consist of a single column with one term or group of terms per row, with no
                    header.{' '}
                    <a
                      href={
                        'https://feathr-static-assets.s3.amazonaws.com/data/keyword_list_template.csv'
                      }
                    >
                      Click here{' '}
                    </a>
                    to download an example file to use as a template.
                  </span>
                </>
              }
              label={'Upload CSV'}
              model={targetable}
              name={'upload_csv'}
              onUpload={handleOnUpload}
              pickerOptions={{
                storeTo: {
                  location: 's3',
                  container: 'feathr-import-data',
                  access: 'public',
                  region: 'us-east-1',
                },
                accept: ['text/comma-separated-values', 'text/csv', 'application/csv', '.csv'],
                maxFiles: 1,
                exposeOriginalFile: true,
              }}
            />
          </>
        )}
      </section>
    </Card>
  );
}

export default observer(SearchKeywordTargeting);
