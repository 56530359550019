import type { BaseCampaign, TCampaignGroup } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';

import BudgetColumn from './BudgetColumn';
import ClicksColumn from './ClicksColumn';
import CPCColumn from './CPCColumn';
import CPMColumn from './CPMColumn';
import CTRColumn from './CTRColumn';
import DateCreatedColumn from './DateCreatedColumn';
import DateEndColumn from './DateEndColumn';
import DateStartColumn from './DateStartColumn';
import DurationColumn from './DurationColumn';
import EmailCTRColumn from './EmailCTRColumn';
import EmailDeliveriesColumn from './EmailDeliveriesColumn';
import EmailHardBouncesColumn from './EmailHardBouncesColumn';
import EmailOpenRateColumn from './EmailOpenRateColumn';
import EmailSendsColumn from './EmailSendsColumn';
import EmailUniqueClicksColumn from './EmailUniqueClicksColumn';
import EmailUnsubscribesColumn from './EmailUnsubscribesColumn';
import FlightColumn from './FlightColumn';
import HardBounceRateColumn from './HardBounceRateColumn';
import MonetizationROIColumn from './MonetizationROIColumn';
import MonetizationValueColumn from './MonetizationValueColumn';
import NameColumn from './NameColumn';
import OptionsColumn from './OptionsColumn';
import ParticipantsColumn from './ParticipantsColumn';
import PartnerColumn from './PartnerColumn';
import ProgressColumn from './ProgressColumn';
import ProjectColumn from './ProjectColumn';
import ResponsesColumn from './ResponsesColumn';
import SeenColumn from './SeenColumn';
import SpendColumn from './SpendColumn';
import StateColumn from './StateColumn';
import TargetImpressionsColumn from './TargetImpressionsColumn';
import TypeColumn from './TypeColumn';
import ViewsColumn from './ViewsColumn';

const getDefaultColumns = (includeProjectColumn?: boolean): Array<IColumn<BaseCampaign>> => {
  const columns = [
    PartnerColumn,
    NameColumn,
    TypeColumn,
    StateColumn,
    DateCreatedColumn,
    DateStartColumn,
    DateEndColumn,
    DurationColumn,
    SpendColumn,
    MonetizationValueColumn,
    MonetizationROIColumn,
    CPMColumn,
    CPCColumn,
    CTRColumn,
    { id: 'cpa', checkboxLabel: 'CPA', Header: 'CPA' },
    BudgetColumn,
    TargetImpressionsColumn,
    ProgressColumn,
    SeenColumn,
    ResponsesColumn,
    EmailSendsColumn,
    EmailDeliveriesColumn,
    EmailHardBouncesColumn,
    EmailUniqueClicksColumn,
    EmailUnsubscribesColumn,
    ViewsColumn,
    EmailOpenRateColumn,
    ClicksColumn,
    EmailCTRColumn,
    { id: 'conversions', checkboxLabel: 'Conversions', Header: 'Conversions' },
    { id: 'roi', checkboxLabel: 'Conversion Value', Header: 'Conversion Value' },
    ParticipantsColumn,
    HardBounceRateColumn,
    FlightColumn,
    OptionsColumn,
  ];

  if (includeProjectColumn) {
    // We want to insert the project column after the name column.
    columns.splice(2, 0, ProjectColumn);
  }

  return columns;
};

const defaultColumnIds = (showProject?: boolean): string[] => {
  const ids = [
    'name',
    '_cls',
    'state',
    'date_created',
    'date_start',
    'date_end',
    'views',
    'clicks',
    'options',
  ];

  if (showProject) {
    // We want to insert the project column after the name column.
    ids.splice(1, 0, 'project');
  }

  return ids;
};

const baseColumnIds = (showProject?: boolean): string[] => {
  const ids = [
    'name',
    '_cls',
    'state',
    'date_created',
    'date_start',
    'date_end',
    'views',
    'clicks',
    'conversions',
    'roi',
    'options',
  ];

  if (showProject) {
    // We want to insert the project column after the name column.
    ids.splice(1, 0, 'project');
  }

  return ids;
};

function defaultColumnIdsMap(showProject?: boolean): Record<TCampaignGroup, string[]> {
  return {
    ads: [...baseColumnIds(showProject), 'budget', 'stats__spend'],
    all: defaultColumnIds(showProject),
    email: [...baseColumnIds(showProject), 'email_sends'],
    'google-ads': [...defaultColumnIds(showProject), 'conversions', 'roi'],
    monetization: [...baseColumnIds(showProject)],
    other: [...baseColumnIds(showProject), 'email_sends'],
  };
}

export { defaultColumnIdsMap };
export default getDefaultColumns;
