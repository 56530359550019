import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { TFacebookCampaign } from '@feathr/blackbox';
import type { FacebookCampaign } from '@feathr/blackbox';
import { EMouseLeaveDelay, Select, Tooltip } from '@feathr/components';

import * as styles from './AdCategorySelect.css';

function AdCategoryOption(props: OptionProps<IAdCategory>): JSX.Element {
  const { data } = props;
  const option = (
    <components.Option {...props}>
      <Fragment key={data.id}>
        {data.name}
        <div
          className={classNames(styles.description, {
            [styles.disabled]: data.isDisabled,
          })}
        >
          <p>{data.description}</p>
        </div>
      </Fragment>
    </components.Option>
  );
  return data.tooltip ? (
    <Tooltip closeDelay={EMouseLeaveDelay.Link} title={data.tooltip}>
      {option}
    </Tooltip>
  ) : (
    option
  );
}

interface IProps {
  campaign: FacebookCampaign;
}

interface IAdCategory {
  id: TFacebookCampaign['special_ad_category'];
  isDisabled: boolean;
  name: string;
  description: string;
  tooltip?: JSX.Element;
}

function AdCategorySelect({ campaign }: IProps): JSX.Element {
  const { t } = useTranslation();

  function handleSelect(option: IAdCategory): void {
    campaign.set({ special_ad_category: option.id });
  }

  const options: IAdCategory[] = [
    {
      id: 'NONE',
      isDisabled: false,
      name: t('None'),
      description: t(
        'If your ads do not relate to any of the other topical categories, choose this option.',
      ),
    },
    {
      id: 'HOUSING',
      isDisabled: false,
      name: t('Housing'),
      description: t('Ads promoting or related to the sale or lease of property, apartments, etc.'),
    },
    {
      id: 'CREDIT',
      isDisabled: false,
      name: t('Credit'),
      description: t('Ads promoting or related to financial services and products.'),
    },
    {
      id: 'EMPLOYMENT',
      isDisabled: false,
      name: t('Employment'),
      description: t('Ads promoting or related to job opportunities.'),
    },
    {
      id: 'ISSUES_ELECTIONS_POLITICS',
      isDisabled: true,
      name: t('Issues, Elections and Politics'),
      description: t(
        'Ads promoting or related to candidates for political office, ballot measures, etc.',
      ),
      tooltip: (
        <Trans t={t}>
          <p>
            Feathr's integration with Facebook does not support campaigns about social issues,
            elections, or politics. Please use Meta's native ads manager and{' '}
            <a
              href={'https://www.facebook.com/business/learn/lessons/run-ads-on-social-issues'}
              target={'_blank'}
            >
              follow their guidelines for running ads of this type.
            </a>
          </p>
        </Trans>
      ),
    },
  ];
  return (
    <Select<IAdCategory>
      components={{ Option: AdCategoryOption }}
      helpText={
        <Trans t={t}>
          Meta requires that all ads on their platform explicitly indicate whether they belong to
          specific topical categories to prevent discriminatory advertising practices.
        </Trans>
      }
      label={t('Special ad category')}
      name={'ad_category_select'}
      onSelectSingle={handleSelect}
      options={options}
      placeholder={t('Choose an ad category')}
      required={true}
      value={options.find((option) => option.id === campaign.get('special_ad_category'))}
    />
  );
}

export default observer(AdCategorySelect);
