import type { JSX } from 'react';
import React from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import EventAddPage from '@feathr/extender/App/EventAddPage';
import EventsPage from '@feathr/extender/App/EventsPage';
import CampaignEditPage from '@feathr/extender/App/EventsPage/CampaignEditPage';
import CampaignConversionPage from '@feathr/extender/App/EventsPage/CampaignPage/CampaignConversionPage';
import CampaignRouter from '@feathr/extender/App/EventsPage/CampaignRouter';
import StatusReferralCampaignPage from '@feathr/extender/App/EventsPage/CampaignRouter/ReferralCampaignPage/StatusReferralCampaignPage';
import TemplatesReferralCampaignPage from '@feathr/extender/App/EventsPage/CampaignRouter/ReferralCampaignPage/TemplatesReferralCampaignPage';
import CampaignsPage from '@feathr/extender/App/EventsPage/CampaignsPage';
import EventCreativesPage from '@feathr/extender/App/EventsPage/EventCreativesPage';
import EventCreativeEditPage from '@feathr/extender/App/EventsPage/EventCreativesPage/EventCreativeEditPage';
import EventPartnersPage from '@feathr/extender/App/EventsPage/EventPartnersPage';
import EventPartnerPage from '@feathr/extender/App/EventsPage/EventPartnersPage/EventPartnerPage';
import EventPartnersActivityPage from '@feathr/extender/App/EventsPage/EventPartnersPage/EventPartnersActivityPage';
import EventPartnersExportsPage from '@feathr/extender/App/EventsPage/EventPartnersPage/EventPartnersExportsPage';
import EventPartnersFieldsPage from '@feathr/extender/App/EventsPage/EventPartnersPage/EventPartnersFieldsPage';
import EventPartnersImportPage from '@feathr/extender/App/EventsPage/EventPartnersPage/EventPartnersImportPage';
import EventPartnersImportsHistoryPage from '@feathr/extender/App/EventsPage/EventPartnersPage/EventPartnersImportsHistoryPage';
import EventPartnersMessagePage from '@feathr/extender/App/EventsPage/EventPartnersPage/EventPartnersMessagePage';
import EventPartnersMessagesPage from '@feathr/extender/App/EventsPage/EventPartnersPage/EventPartnersMessagesPage';
import PartnerDashboardSettingsPage from '@feathr/extender/App/EventsPage/EventPartnersPage/PartnerDashboardSettingsPage';
import EventReportPage from '@feathr/extender/App/EventsPage/EventReportPage';
import EventSettingsBilling from '@feathr/extender/App/EventsPage/EventSettingsBilling';
import EventSettingsBillingEdit from '@feathr/extender/App/EventsPage/EventSettingsBilling/EventSettingsBillingEdit';
import EventSettingsInfo from '@feathr/extender/App/EventsPage/EventSettingsInfo';
import EventTemplatesCampaignPage from '@feathr/extender/App/EventsPage/EventTemplatesCampaignPage';
import EventTemplatesGlobalPage from '@feathr/extender/App/EventsPage/EventTemplatesGlobalPage';
import FlightAddPage from '@feathr/extender/App/EventsPage/FlightAddPage';
import FlightDetailPage from '@feathr/extender/App/EventsPage/FlightDetailPage';
import FlightEditPage from '@feathr/extender/App/EventsPage/FlightEditPage';
import FlightReportPage from '@feathr/extender/App/EventsPage/FlightReportPage';
import FlightsListPage from '@feathr/extender/App/EventsPage/FlightsListPage';
import TemplatePage from '@feathr/extender/App/TemplatePage';
import { useGoogleAdsPermissions } from '@feathr/extender/hooks';
import { useAccount, useRole } from '@feathr/extender/state';

import SettingsLandingPageCampaignPage from '../EventsPage/CampaignRouter/LandingPageCampaignPage/SettingsLandingPageCampaignPage';
import TemplateLandingPageCampaignPage from '../EventsPage/CampaignRouter/LandingPageCampaignPage/TemplateLandingPageCampaignPage';
import TemplateEditPage from '../TemplatePage/TemplateEditPage';
import RedirectTo403 from './RedirectTo403';
import RedirectTo404 from './RedirectTo404';
import RouteWithPermissions from './RouteWithPermissions';

function EventsRoutes(): JSX.Element {
  const { hasProjects } = useRole();
  return (
    <Switch>
      <Route exact={true} path={'/:accountId/projects'}>
        <EventsPage />
      </Route>
      {hasProjects && (
        <Route path={'/:accountId/projects/add'}>
          <EventAddPage />
        </Route>
      )}
      <Route path={'/:accountId/projects/:eventId'}>
        <EventRoutes />
      </Route>
      <RedirectTo404 />
    </Switch>
  );
}

function EventRoutes(): JSX.Element {
  const { eventId: projectId } = useParams<{ eventId?: string }>();
  const { projects, hasProjectsSome, hasProjects } = useRole();

  const hasAccessToTheCurrentProject =
    hasProjects || (hasProjectsSome && projects.includes(projectId ?? ''));

  return (
    <Switch>
      {/* 
        If the user has some permissions and this project is not included in their access list, 403 all routes below 
      */}
      {!hasAccessToTheCurrentProject && <RedirectTo403 />}
      <Redirect
        exact={true}
        from={'/:accountId/projects/:eventId'}
        to={'/:accountId/projects/:eventId/campaigns'}
      />

      <Route path={'/:accountId/projects/:eventId/campaigns'}>
        <CampaignsRoutes />
      </Route>
      <Route path={'/:accountId/projects/:eventId/partners'}>
        <PartnersRoutes />
      </Route>
      <Route path={'/:accountId/projects/:eventId/flights'}>
        <FlightRoutes />
      </Route>
      <Route path={'/:accountId/projects/:eventId/settings'}>
        <SettingsRoutes />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/report'}>
        <EventReportPage />
      </Route>

      <Redirect
        exact={true}
        from={'/:accountId/projects/:eventId/content'}
        to={'/:accountId/projects/:eventId/content/creatives'}
      />
      <Redirect
        from={'/:accountId/projects/:eventId/creatives'}
        to={'/:accountId/projects/:eventId/content/creatives'}
      />
      <Redirect
        from={'/:accountId/projects/:eventId/templates'}
        to={'/:accountId/projects/:eventId/content/templates'}
      />
      <Route exact={true} path={'/:accountId/projects/:eventId/content/creatives'}>
        <EventCreativesPage />
      </Route>
      <Redirect
        exact={true}
        /*
         * Limit :templateId to only ObjectId, which has length of 24
         * characters in order to ignore /global and /campaign.
         */
        from={'/:accountId/projects/:eventId/content/creatives/:creativeId([^/]{24,})/edit'}
        to={'/:accountId/projects/:eventId/content/creatives/:creativeId/edit/design'}
      />
      <Route
        exact={true}
        path={'/:accountId/projects/:eventId/content/creatives/:creativeId/edit/:tab'}
      >
        <EventCreativeEditPage />
      </Route>
      <Redirect
        exact={true}
        from={'/:accountId/projects/:eventId/content/templates'}
        to={'/:accountId/projects/:eventId/content/templates/global'}
      />
      <Route exact={true} path={'/:accountId/projects/:eventId/content/templates/global'}>
        <EventTemplatesGlobalPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/content/templates/campaign'}>
        <EventTemplatesCampaignPage />
      </Route>
      <Redirect
        exact={true}
        /*
         * Limit :templateId to only ObjectId, which has length of 24
         * characters in order to ignore /global and /campaign.
         */
        from={'/:accountId/projects/:eventId/content/templates/:templateId([^/]{24,})/edit'}
        to={'/:accountId/projects/:eventId/content/templates/:templateId/edit/design'}
      />
      <Route exact={true} path={'/:accountId/projects/:eventId/content/templates/:templateId/'}>
        <TemplatePage />
      </Route>
      <Route path={'/:accountId/projects/:eventId/content/templates/:templateId/edit/:tab'}>
        <TemplateEditPage />
      </Route>
      <RedirectTo404 />
    </Switch>
  );
}

function FlightRoutes(): JSX.Element {
  const { eventId: projectId } = useParams<{ eventId?: string }>();
  const { projects, hasProjectsSome, hasProjects } = useRole();

  const hasAccessToTheCurrentProject =
    hasProjects || (hasProjectsSome && projects.includes(projectId ?? ''));

  return (
    <Switch>
      {/* 
        If the user has some permissions and this project is not included in their access list, 403 all routes below 
      */}
      {!hasAccessToTheCurrentProject && <RedirectTo403 />}

      <Route exact={true} path={'/:accountId/projects/:eventId/flights'}>
        <FlightsListPage />
      </Route>
      <Route path={'/:accountId/projects/:eventId/flights/add'}>
        <FlightAddPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/flights/:flightId'}>
        <FlightDetailPage />
      </Route>
      <Route path={'/:accountId/projects/:eventId/flights/:flightId/edit'}>
        <FlightEditPage />
      </Route>
      <Route path={'/:accountId/projects/:eventId/flights/:flightId/report'}>
        <FlightReportPage />
      </Route>
      <Route
        exact={true}
        path={'/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId'}
      >
        <CampaignRouter />
      </Route>
      <Route path={'/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId/edit'}>
        <CampaignEditPage />
      </Route>
      <Route
        exact={true}
        path={'/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId/settings'}
      >
        <SettingsLandingPageCampaignPage />
      </Route>
      <Route
        path={'/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId/template'}
      >
        <TemplateLandingPageCampaignPage />
      </Route>
      <Route
        exact={true}
        path={'/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId/partners'}
      >
        <StatusReferralCampaignPage />
      </Route>
      <Route
        exact={true}
        path={'/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId/templates'}
      >
        <TemplatesReferralCampaignPage />
      </Route>
      <Redirect
        exact={true}
        from={
          '/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId/templates/:templateId/edit'
        }
        to={
          '/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId/templates/:templateId/edit/design'
        }
      />
      <Route
        exact={true}
        path={
          '/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId/templates/:templateId/edit/:tab'
        }
      >
        <TemplateEditPage />
      </Route>
      <Route
        exact={true}
        path={
          '/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId/templates/:templateId/'
        }
      >
        <TemplatePage />
      </Route>
      <Route
        component={CampaignConversionPage}
        exact={true}
        path={
          '/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId/conversions/:conversionId'
        }
      />

      <RedirectTo404 />
    </Switch>
  );
}

function PartnersRoutes(): JSX.Element {
  const { eventId: projectId } = useParams<{ eventId?: string }>();
  const { projects, hasProjectsSome, hasProjects } = useRole();

  const hasAccessToTheCurrentProject =
    hasProjects || (hasProjectsSome && projects.includes(projectId ?? ''));

  return (
    <Switch>
      {/* 
        If the user has some permissions and this project is not included in their access list, 403 all routes below 
      */}
      {!hasAccessToTheCurrentProject && <RedirectTo403 />}

      <Route exact={true} path={'/:accountId/projects/:eventId/partners'}>
        <EventPartnersPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/partners/activity'}>
        <EventPartnersActivityPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/partners/fields'}>
        <EventPartnersFieldsPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/partners/exports'}>
        <EventPartnersExportsPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/partners/imports/:importerId'}>
        <EventPartnersImportPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/partners/imports'}>
        <EventPartnersImportsHistoryPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/partners/messages'}>
        <EventPartnersMessagesPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/partners/messages/:messageId'}>
        <EventPartnersMessagePage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/partners/dashboard'}>
        <PartnerDashboardSettingsPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/partners/:partnerId'}>
        <EventPartnerPage />
      </Route>

      <RedirectTo404 />
    </Switch>
  );
}

function CampaignsRoutes(): JSX.Element {
  const account = useAccount();
  const showGoogleAds = useGoogleAdsPermissions();
  const { eventId: projectId } = useParams<{ eventId?: string }>();
  const { projects, hasProjectsSome, hasProjects } = useRole();

  const hasAccessToTheCurrentProject =
    hasProjects || (hasProjectsSome && projects.includes(projectId ?? ''));

  return (
    <Switch>
      {!hasAccessToTheCurrentProject && <RedirectTo403 />}

      <Redirect
        exact={true}
        from={'/:accountId/projects/:eventId/campaigns'}
        to={'/:accountId/projects/:eventId/campaigns/all'}
      />
      {!account.hasMonetization && (
        <Redirect
          exact={true}
          from={'/:accountId/projects/:eventId/campaigns/monetization'}
          to={'/:accountId/projects/:eventId/campaigns/all'}
        />
      )}

      <Route exact={true} path={'/:accountId/projects/:eventId/campaigns/all'}>
        <CampaignsPage campaignGroup={'all'} />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/campaigns/ads'}>
        <CampaignsPage campaignGroup={'ads'} />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/campaigns/email'}>
        <CampaignsPage campaignGroup={'email'} />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/campaigns/monetization'}>
        <CampaignsPage campaignGroup={'monetization'} />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/campaigns/other'}>
        <CampaignsPage campaignGroup={'other'} />
      </Route>
      {/* Prevent routing to the campaign router if google ads customer is disconnected */}
      <Route exact={true} path={'/:accountId/projects/:eventId/campaigns/google-ads'}>
        {showGoogleAds ? <CampaignsPage campaignGroup={'google-ads'} /> : <RedirectTo404 />}
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/campaigns/:campaignId'}>
        <CampaignRouter />
      </Route>
      <Route path={'/:accountId/projects/:eventId/campaigns/:campaignId/edit'}>
        <CampaignEditPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/campaigns/:campaignId/settings'}>
        <SettingsLandingPageCampaignPage />
      </Route>
      <Route path={'/:accountId/projects/:eventId/campaigns/:campaignId/template'}>
        <TemplateLandingPageCampaignPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/campaigns/:campaignId/partners'}>
        <StatusReferralCampaignPage />
      </Route>
      <Route exact={true} path={'/:accountId/projects/:eventId/campaigns/:campaignId/templates'}>
        <TemplatesReferralCampaignPage />
      </Route>
      <Redirect
        exact={true}
        from={'/:accountId/projects/:eventId/campaigns/:campaignId/templates/:templateId/edit'}
        to={'/:accountId/projects/:eventId/campaigns/:campaignId/templates/:templateId/edit/design'}
      />
      <Route
        exact={true}
        path={'/:accountId/projects/:eventId/campaigns/:campaignId/templates/:templateId/'}
      >
        <TemplatePage />
      </Route>
      <Route
        exact={true}
        path={'/:accountId/projects/:eventId/campaigns/:campaignId/templates/:templateId/edit/:tab'}
      >
        <TemplateEditPage />
      </Route>
      <Route
        component={CampaignConversionPage}
        exact={true}
        path={'/:accountId/projects/:eventId/campaigns/:campaignId/conversions/:conversionId'}
      />

      <RedirectTo404 />
    </Switch>
  );
}

function SettingsRoutes(): JSX.Element {
  const { hasBilling, projects, hasProjectsSome, hasProjects } = useRole();
  const { eventId: projectId } = useParams<{ eventId?: string }>();

  const hasAccessToTheCurrentProject =
    hasProjects || (hasProjectsSome && projects.includes(projectId ?? ''));

  return (
    <Switch>
      <Redirect
        exact={true}
        from={'/:accountId/projects/:eventId/settings'}
        to={'/:accountId/projects/:eventId/settings/info'}
      />
      {!hasAccessToTheCurrentProject && <RedirectTo403 />}

      <Route path={'/:accountId/projects/:eventId/settings/info'}>
        <EventSettingsInfo />
      </Route>
      <RouteWithPermissions
        condition={hasBilling}
        path={'/:accountId/projects/:eventId/settings/billing/edit'}
      >
        <EventSettingsBillingEdit />
      </RouteWithPermissions>
      <RouteWithPermissions
        condition={hasBilling}
        path={'/:accountId/projects/:eventId/settings/billing'}
      >
        <EventSettingsBilling />
      </RouteWithPermissions>

      <RedirectTo404 />
    </Switch>
  );
}

export default EventsRoutes;
