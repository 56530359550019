import classNames from 'classnames';
import type { Marker, TileLayerOptions } from 'leaflet';
import L from 'leaflet';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';

import * as styles from './MarkerMap.css';

interface IProps {
  className?: string;
  emptyMessage?: string;
  zoom?: number;
}

interface IPersonProps extends IProps {
  person: Person;
  lat?: never;
  lng?: never;
}

interface ILatLngProps extends IProps {
  person?: never;
  lat?: number;
  lng?: number;
}

function createMarker(lat: number, lng: number): Marker {
  const icon = L.divIcon({ className: classNames('map-marker', styles.marker) });
  const marker = L.marker([lat, lng], { icon });
  return marker;
}

function MarkerMap({
  person,
  lat = 0,
  lng = 0,
  className,
  emptyMessage,
  zoom = 3,
}: IPersonProps | ILatLngProps): JSX.Element {
  const { t } = useTranslation();
  const latitude = person?.get('geoip')?.lat ?? lat;
  const longitude = person?.get('geoip')?.lng ?? lng;

  const [map, setMap] = useState<L.Map | undefined>();
  const mapContainer = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const initializeMap = (): void => {
      const newMap = L.map(mapContainer.current!, {
        dragging: true,
        touchZoom: false,
        scrollWheelZoom: true,
        doubleClickZoom: false,
        boxZoom: false,
        tap: false,
        keyboard: false,
        zoomControl: false,
        attributionControl: false,
        center: [latitude, longitude],
        zoom,
      });
      setMap(newMap);
      L.tileLayer(
        'https://api.mapbox.com/styles/v1/alevental/ck1vf3f9j5h7d1cqp1j64zjkx/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}',
        {
          renderer: L.canvas(),
          accessToken: MAPBOX_ACCESS_TOKEN,
        } as TileLayerOptions,
      ).addTo(newMap);
      const marker = createMarker(latitude, longitude);
      marker.addTo(newMap);
    };

    if (!map && mapContainer.current) {
      initializeMap();
    }
  }, [map, mapContainer.current]);

  if (!latitude || !longitude) {
    return <span>{emptyMessage ?? t('No location data available.')}</span>;
  }

  function getRef(el: HTMLDivElement): void {
    mapContainer.current = el;
  }

  return <div className={classNames(styles.map, className)} ref={getRef} />;
}

export default observer(MarkerMap);
