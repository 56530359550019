import { faChartPie } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Chip } from '@feathr/components';

import { StoresContext } from '../../state';

interface IProps {
  id: string;
  localUrl?: (url: string) => string;
}

function SegmentChip({ id, localUrl }: IProps) {
  const { Segments } = useContext(StoresContext);

  const segment = Segments.get(id);
  if (segment.isErrored) {
    return null;
  }

  if (segment.isPending) {
    return <Chip isLoading={true} />;
  }

  const content = (
    <Chip prefix={<FontAwesomeIcon icon={faChartPie} />}>
      {segment.name}
      {segment.get('is_archived') ? ' (Archived)' : ''}
    </Chip>
  );

  if (localUrl) {
    return <Link to={localUrl(segment.getItemUrl())}>{content}</Link>;
  }
  return content;
}

export default observer(SegmentChip);
