import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import { StatBox } from '@feathr/components';

import type { IStatProps } from './Stats';

interface IProps extends IStatProps {
  isMonetization?: boolean;
  totalUsers?: number;
}

interface IPropsSmall extends IStatProps {
  isMonetization?: never;
  size: 'mini';
  totalUsers?: never;
}

function ReachableNewAudienceStat({
  isMonetization,
  label = 'Reachable New Audience',
  size = 'default',
  totalUsers,
  value,
}: IPropsSmall | IProps): JSX.Element {
  const formatted = numeral(value).format('0,0');

  const percentValue = value && totalUsers ? numeral(Math.min(value / totalUsers, 1)) : 0;
  const percent = !isMonetization && totalUsers ? percentValue : undefined;

  const secondary = percent ? `${percent.format('0.00%')} of Target` : undefined;

  return <StatBox label={label} primary={formatted} secondary={secondary} size={size} />;
}

export default observer(ReachableNewAudienceStat);
