import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@mantine/hooks';
import type { TFunction } from 'i18next';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ToastType } from 'react-toastify';

import type { Domain, EPinpointRequestStatus } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import {
  Button,
  Chip,
  ConfirmModal,
  ContextMenu,
  EMouseLeaveDelay,
  MenuItem,
  TableColumnHeader,
  toast,
  Tooltip,
} from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IRow {
  original: Domain;
}

type TStatusRecord = Record<EPinpointRequestStatus | 'N/A', string>;

const statusToColorMap: TStatusRecord = {
  Pending: 'yellow',
  Success: 'green',
  Failed: 'red',
  TemporaryFailure: 'orange',
  NotStarted: 'default',
  /*
   * Adding N/A as default for Domains that have
   * not initiated the Email Domain verification process since
   * they won't have DKIM or MX records with a status
   */
  'N/A': 'default',
};

const statusToLabelMap: TStatusRecord = {
  Pending: 'Pending',
  Success: 'Ready',
  Failed: 'Failed',
  TemporaryFailure: 'Erroring',
  NotStarted: 'N/A',
  'N/A': 'N/A',
};

export function OptionsCell({ original }: Readonly<IRow>): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { Domains } = useContext(StoresContext);
  const [isOpenModal, { open: openModal, close: closeModal }] = useDisclosure(false);
  const contentDomain = original.get('content_domain');

  function handleClickEdit(): void {
    history.push(original.getItemUrl('edit'));
  }

  async function handleClickDelete(): Promise<void> {
    try {
      await original.deleteDomain(original.id);
      Domains.remove(original.id);
      toast(t('Domain deleted successfully.'), {
        type: ToastType.SUCCESS,
      });
    } catch (error) {
      toast(t('Something went wrong while trying to delete this domain.'), {
        type: ToastType.ERROR,
      });
    } finally {
      closeModal();
    }
  }

  const helpText = t(
    'Are you sure you want to delete this domain? This change cannot be undone. Landing pages using {{contentDomain}} will no longer work and sending emails will resume only when adding a new domain.',
    { contentDomain },
  );

  return (
    <>
      <ContextMenu name={'domain-options'}>
        <MenuItem
          name={'edit-role'}
          onClick={handleClickEdit}
          prefix={<FontAwesomeIcon icon={faEdit} />}
        >
          {t('Edit')}
        </MenuItem>
        <MenuItem
          name={'delete-role'}
          onClick={openModal}
          prefix={<FontAwesomeIcon icon={faTrash} />}
        >
          {t('Delete')}
        </MenuItem>
      </ContextMenu>
      <ConfirmModal
        cancelButtonText={t('Cancel')}
        confirmButtonText={t('Delete')}
        confirmButtonType={'danger'}
        onClose={closeModal}
        onConfirm={handleClickDelete}
        opened={isOpenModal}
        t={t}
        title={t('Delete "{{contentDomain}}"', { contentDomain })}
      >
        {helpText}
      </ConfirmModal>
    </>
  );
}

function EmailStatusCell({ original }: IRow): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  function handleClickRefresh(): void {
    history.push(original.getItemUrl());
  }

  const status = original.emailSendingStatus;
  const tooltipMap = {
    Success: t('This domain, and any sender from it, are ready to be used in email campaigns.'),
    Failed: (
      <Trans t={t}>
        Add CNAME and mail records for this domain to your DNS host and then{' '}
        <Button onClick={handleClickRefresh} type={'link'}>
          refresh it
        </Button>
        .
      </Trans>
    ),
    'N/A': t("This domain isn't setup to be used for email."),
  };
  return (
    <Chip
      isLoading={original.isPending}
      mouseLeaveDelay={EMouseLeaveDelay.Link}
      theme={statusToColorMap[status]}
      tooltip={tooltipMap[status]}
    >
      {statusToLabelMap[status]}
    </Chip>
  );
}

function domainsColumns(t: TFunction): Array<IColumn<Domain>> {
  return [
    {
      id: 'content_domain',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Content Domain'),
      }),
      headerClassName: tableStyles.sort,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return (
                <Tooltip title={t('View details')}>
                  <Button href={original.getItemUrl()} type={'link'}>
                    {original.get('content_domain')}
                  </Button>
                </Tooltip>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'email_domain',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Email Domain'),
      }),
      headerClassName: tableStyles.sort,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return (
                <Tooltip title={t('View details')}>
                  <Button href={original.getItemUrl()} type={'link'}>
                    {original.get('email_domain')}
                  </Button>
                </Tooltip>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'content_status',
      Header: TableColumnHeader({
        title: t('Content Serving Status'),
        tooltip: t(
          "This domain's status for serving landing pages, partner dashboards, and invite pages.",
        ),
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      width: 110,
      Cell({ original }: IRow): JSX.Element {
        const status = original.get('redirect').is_verified ? 'Success' : 'Pending';
        return (
          <Chip
            isLoading={original.isPending}
            theme={statusToColorMap[status]}
            tooltip={
              status === 'Success'
                ? t('This domain can be used to serve pages, invite pages, and partner dashboards.')
                : t(
                    'Add CNAME records for this domain to your DNS host to use it for serving content.',
                  )
            }
          >
            {statusToLabelMap[status]}
          </Chip>
        );
      },
    },
    {
      id: 'email_status',
      Header: TableColumnHeader({
        title: t('Email Sending Status'),
        tooltip: t("This domain's status for sending emails from Feathr."),
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      width: 110,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return <EmailStatusCell original={original} />;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'options',
      Header: TableColumnHeader({
        title: t('Options'),
      }),
      width: 80,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return <OptionsCell original={original} />;
            }}
          </Observer>
        );
      },
    },
  ];
}

export default domainsColumns;
