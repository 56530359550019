import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';

import type { Account } from '@feathr/blackbox';
import { Button, CardV2 as Card, ConfirmModalV1, Form } from '@feathr/components';
import InputList from '@feathr/extender/components/InputList';
import { useReactionEffect, useToggle } from '@feathr/hooks';

interface IProps {
  account: Account;
}

function AllowList({ account }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const [domains, setDomains] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isConfirmModalOpen, toggleIsConfirmModalOpen] = useToggle(false);

  useReactionEffect(
    () => !account.isPending,
    () => {
      if (!account.isPending) {
        const allowedDomains = account.get('domain_allow_list', []);
        setDomains(allowedDomains);
      }
    },
  );

  function handleDomainChange(index: number, value: string): void {
    const newDomains = [...domains];
    newDomains[index] = value;
    setDomains(newDomains);
    account.set({
      domain_allow_list: newDomains,
    });
  }

  // Check if the new domain list is the same as the shadow list.
  function isDomainListClean(newDomains: string[]): boolean {
    const shadowDomains = account.shadowAttributes.domain_allow_list || [];
    return JSON.stringify(newDomains) === JSON.stringify(shadowDomains);
  }

  function removeDomain(index: number): void {
    const newDomains = domains.filter((_, i) => i !== index);
    setDomains(newDomains);
    account.set({
      domain_allow_list: newDomains,
    });

    if (isDomainListClean(newDomains)) {
      account.setAttributeClean('domain_allow_list');
    }
  }

  // Check if the domain at the given index is in the shadow list.
  function isDomainInShadowList(index: number): boolean {
    const shadowDomains = account.shadowAttributes.domain_allow_list || [];
    return shadowDomains[index] !== undefined;
  }

  function handleDomainDelete(index: number): void {
    if (domains[index] === '') {
      removeDomain(index);
    } else {
      setCurrentIndex(index);
      toggleIsConfirmModalOpen();
    }
  }

  function handleConfirmDelete(): void {
    removeDomain(currentIndex);
    toggleIsConfirmModalOpen();
  }

  function handleAddDomain(): void {
    const newDomains = [...domains, ''];
    setDomains(newDomains);
    account.set({
      domain_allow_list: newDomains,
    });
  }

  return (
    <>
      <Prompt
        message={t('You have unsaved changes. Are you sure you want to leave this page?')}
        when={account.isDirty}
      />
      <Card>
        <Card.Header
          description={t(
            'Domains added here will not be blocked by our security systems, ensuring that all clicks from Feathr campaigns to URLs within these domains are processed as intended.',
          )}
          title={t('Allowed Domains')}
        />
        <Card.Content>
          <Form label={t('Domain Allow List')}>
            <InputList
              emptyMessage={t('You have no domains in your allow list.')}
              items={domains}
              onChange={handleDomainChange}
              onRemove={handleDomainDelete}
            />
          </Form>
        </Card.Content>
        <Card.Actions>
          <Button onClick={handleAddDomain}>{t('Add domain')}</Button>
        </Card.Actions>
      </Card>
      {isConfirmModalOpen && (
        <ConfirmModalV1
          cancelButtonText={t('Cancel')}
          confirmationPhrase={t('DELETE')}
          confirmButtonText={t('Delete domain')}
          confirmButtonType={'danger'}
          description={t('Are you sure you want to delete this domain from your allow list?')}
          onClose={toggleIsConfirmModalOpen}
          onConfirm={handleConfirmDelete}
          // If the user is trying to delete a domain that has not been saved, we don't need to require confirmation.
          requireConfirmation={!account.isDirty || isDomainInShadowList(currentIndex)}
          t={t}
          title={t('Delete domain?')}
        />
      )}
    </>
  );
}

export default observer(AllowList);
