import { observer } from 'mobx-react-lite';
import React from 'react';

import { Skeleton } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

interface IProps {
  className?: string;
  id: string;
}

function BillingConfigurationLabel({ className, id }: IProps) {
  const { Billables } = React.useContext(StoresContext);
  const billable = Billables.get(id);

  const name = billable.get('name') || '';
  const email = billable.get('email') || '';
  const label = !billable.isPending
    ? (billable.get('description', `${name} (${email})`) as string) || id
    : undefined;

  return <div className={className}>{label || <Skeleton width={100} />}</div>;
}

export default observer(BillingConfigurationLabel);
