import classNames from 'classnames';
import type { TFunction } from 'i18next';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IUniquePredicate, User } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { DatePicker, Input, NumberInput, Select } from '@feathr/components';
import { useUser } from '@feathr/extender/state';
import { moment, TimeFormat, timezoneAbbr } from '@feathr/hooks';

import type { ICollectionOption } from '../../PredicateIdValueSelect';
import type { TCollection } from '../../PredicateIdValueSelect/PredicateIdValueSelect';
import PredicateIdValueSelect from '../../PredicateIdValueSelect/PredicateIdValueSelect';
import PredicateListValueSelect from '../../PredicateListValueSelect';
import PredicateTextValueInput from '../../PredicateTextValueInput';
import type { IRelativeDateOptions, TOptionsGroup } from '../utils';
import { getRelativeDateOptionsMap } from '../utils';

import * as styles from './ValueInput.css';

interface IProps {
  disabled?: boolean;
  excludeIds?: string[];
  handleChangeValue: (value: any) => void;
  optionsGroup: TOptionsGroup;
  predicate: IUniquePredicate;
}

interface IInputParams {
  predicate: IUniquePredicate;
  excludeIds: string[];
  disabled: boolean;
  t: TFunction;
  optionsGroup: string;
  handleChangeValue: (value: any) => void;
  selectedRelativeTimeOptionId: string;
  setSelectedRelativeTimeOptionId: (id: string) => void;
  relativeDateOptionsMap: IRelativeDateOptions;
  user: User;
}

export function getValueInput({
  predicate,
  excludeIds,
  disabled,
  t,
  optionsGroup,
  handleChangeValue,
  selectedRelativeTimeOptionId,
  setSelectedRelativeTimeOptionId,
  relativeDateOptionsMap,
  user,
}: IInputParams): JSX.Element | null {
  const userTimezone = user.get('timezone');
  const { attr_against: targetAttribute, attr_type: type, comparison, kind } = predicate;

  function getPath(): string {
    const collectionMap = {
      activity: 'breadcrumbs',
      attribute: 'persons',
      update: 'persons',
    };
    return `${collectionMap[kind || 'activity']}/${targetAttribute}/values`;
  }

  function onSelect(selected: ICollectionOption | ICollectionOption[] | null): void {
    if (Array.isArray(selected)) {
      handleChangeValue(selected.map(({ id }) => id!));
    } else if (selected) {
      handleChangeValue(selected.id);
    } else {
      handleChangeValue(undefined);
    }
  }

  const {
    Conversation,
    LandingPage,
    EmailList,
    Lookalike,
    SeedSegment,
    Affinity,
    Segment,
    Search,
    MobileGeoFencing,
    MobileGeoFenceRetargeting,
    Facebook,
    TrackedLink,
    EmailListFacebook,
    Referral,
    PinpointEmail,
    SmartPinpointEmail,
  } = CampaignClass;

  // Construct allowed classes for 'campaign is' dropdown
  function getAllowedClasses(optionsGroup: string): CampaignClass[] {
    const allowedClasses: CampaignClass[] = [
      Conversation,
      LandingPage,
      EmailList,
      Lookalike,
      SeedSegment,
      Affinity,
      Segment,
      Search,
      MobileGeoFencing,
      MobileGeoFenceRetargeting,
      Facebook,
      TrackedLink,
      EmailListFacebook,
      Referral,
      PinpointEmail,
      SmartPinpointEmail,
    ];

    /**
     * Only allow autosend in dropdown for group builder and the targets
     * step of the campaign wizard. We want to exclude autosend campaigns
     * from being used in the trigger condition of other autosends.
     */
    if (['segment', 'campaign'].includes(optionsGroup)) {
      allowedClasses.push(CampaignClass.AutoPinpointEmail);
    }

    return allowedClasses;
  }

  // Construct optional filters for 'campaign is' dropdown
  function getOptionalFilters(excludeIds: string[]): Record<string, string[]> {
    return excludeIds.length ? { id__nin: excludeIds } : {};
  }

  function getSelectProps(collection, filters = {}, multi = false): Record<string, any> {
    const idSelectShared = {
      disabled,
      key: `${predicate.key}_comparison`,
      name: 'predicate_value',
      onSelect,
      placeholder: t('Select an option'),
      value: predicate.value as string | undefined,
    };
    return {
      ...idSelectShared,
      collection: collection as TCollection,
      filters,
      multi,
    };
  }

  const idSelectProps = {
    cpn_id: getSelectProps('Campaigns', {
      _cls__in: getAllowedClasses(optionsGroup),
      ...getOptionalFilters(excludeIds),
    }),
    e_id: getSelectProps('Events'),
    p_id: getSelectProps('Partners'),
    seg_id: getSelectProps('Segments', {
      context: 'breadcrumb',
      is_conversion_segment: true,
    }),
    tag_id: getSelectProps('Tags', {
      context: 'breadcrumb',
    }),
    tag_ids: getSelectProps(
      'Tags',
      {
        context: 'person',
      },
      true,
    ),
    'pp_opt_outs.events': getSelectProps('Events', {}, true),
    'pp_opt_outs.campaigns': getSelectProps(
      'Campaigns',
      {
        _cls__in: [
          CampaignClass.PinpointEmail,
          CampaignClass.SmartPinpointEmail,
          CampaignClass.AutoPinpointEmail,
        ],
      },
      true,
    ),
  };

  if (targetAttribute && Object.keys(idSelectProps).includes(targetAttribute)) {
    return <PredicateIdValueSelect {...idSelectProps[targetAttribute]} />;
  }

  const sharedInputProps = {
    disabled,
    key: `${predicate.key}_comparison`,
    name: 'predicate_value',
    placeholder: t('Enter a value'),
  };

  const inputProps = {
    getPath,
    ...sharedInputProps,
  };
  const relativeDateOptions: ISelectOption[] = Object.values(relativeDateOptionsMap);

  function handleChangeDateNumberValue(
    value?: number,
    relativeTimeOption: string | undefined = undefined,
  ): void {
    if (!value) {
      return;
    }

    runInAction(() => {
      // If days ago is the selected option and the the user has not changed the dropdown
      const isDaysAgo = selectedRelativeTimeOptionId === '-' && relativeTimeOption === undefined;

      /*
       * Check that if the user has selected a different relative time option because we aren't
       * getting the updated value of `selectedRelativeTimeOptionId` right away, probably because
       * `getValueInput` is outside of the component lifecycle.
       */
      const hasSelectedDaysAgo = relativeTimeOption === '-';

      // Use absolute value to convert the number to positive so we can negate it appropriately
      const formattedValue = isDaysAgo || hasSelectedDaysAgo ? -Math.abs(value) : Math.abs(value);

      handleChangeValue(formattedValue);
    });
  }

  function handleRelativeTimeSelect({ id }: ISelectOption): void {
    const option = relativeDateOptions.find((option) => option.id === id);
    if (!option) {
      return;
    }
    setSelectedRelativeTimeOptionId(option.id);

    // Negate the value if the dropdown has changed
    handleChangeDateNumberValue(predicate.value as number, option.id);
  }

  switch (type) {
    case 'string':
      return (
        <PredicateTextValueInput
          {...inputProps}
          onChange={handleChangeValue}
          value={predicate.value as string}
        />
      );

    case 'list':
      return (
        <PredicateListValueSelect
          {...inputProps}
          onChange={handleChangeValue}
          value={predicate.value as string[]}
        />
      );

    case 'date': {
      // Relative dates include `_r` in the comparison: eq_date_r, ne_date_r, gt_date_r, lt_date_r
      const isRelativeComparison = comparison?.includes('_r');
      // Exact date and time does not include `_` in the comparison: eq, ne, gt, lt
      const isExactDateAndTimeComparison = !comparison?.includes('_');
      const isTodaySelected = isRelativeComparison && predicate?.value === 0;

      if (isRelativeComparison && predicate?.value !== 0) {
        const count = predicate.value !== undefined ? +predicate.value : undefined;
        const positiveCount = Math.abs(count ?? 0);
        const selectedOption = relativeDateOptions.find(
          (option) => option.id === selectedRelativeTimeOptionId,
        );

        return (
          <div className={styles.container}>
            <NumberInput
              {...sharedInputProps}
              className={styles.numberInput}
              min={1}
              onChange={handleChangeDateNumberValue}
              value={positiveCount}
              wrapperClassName={styles.numberInput}
            />
            <Select
              className={classNames(styles.select, styles.time)}
              disabled={disabled}
              name={'predicate_value_relative'}
              onChange={handleRelativeTimeSelect}
              options={relativeDateOptions}
              value={selectedOption}
            />
          </div>
        );
      }
      const selectedMoment = predicate.value ? moment.utc(predicate.value as string) : moment.utc();

      return (
        <DatePicker
          {...inputProps}
          autoComplete={'off'}
          dateFormat={isExactDateAndTimeComparison ? 'MMMM d, yyyy h:mm aa' : 'MMMM d, yyyy'}
          disabled={isTodaySelected}
          // Prevent adding a min/max time since we can freely pick any date /time
          maxTime={undefined}
          minTime={undefined}
          onDateStrChange={handleChangeValue}
          placeholder={t('Select a date')}
          showCalendarIcon={true}
          showTimeSelect={isExactDateAndTimeComparison}
          suffix={isExactDateAndTimeComparison && timezoneAbbr(selectedMoment.toDate())}
          timeIntervals={5}
          timezone={userTimezone}
          value={selectedMoment.format(TimeFormat.isoDateTime)}
        />
      );
    }

    case 'integer':

    case 'float':
      return (
        <NumberInput
          {...inputProps}
          onChange={handleChangeValue}
          value={predicate.value as number | undefined}
          wrapperClassName={styles.numberInput}
        />
      );

    case 'boolean': {
      const booleanOptions = [
        { id: true, name: t('True') },
        { id: false, name: t('False') },
      ];

      function handleChangeBoolean({ id }: ISelectOption): void {
        handleChangeValue(id);
      }

      return (
        <Select
          {...inputProps}
          onChange={handleChangeBoolean}
          options={booleanOptions}
          value={
            predicate.value !== undefined
              ? booleanOptions.find(({ id }) => predicate.value === id)
              : booleanOptions.find(({ id }) => false === id)
          }
        />
      );
    }

    default:
      return <Input {...inputProps} onChange={handleChangeValue} type={'text'} />;
  }
}

function ValueInput({
  disabled = false,
  excludeIds = [],
  handleChangeValue,
  optionsGroup,
  predicate: { comparison },
  predicate,
}: IProps): JSX.Element | null {
  const { t } = useTranslation();
  const user = useUser();

  const relativeDateOptionsMap = getRelativeDateOptionsMap(t, predicate.value as number);
  const [selectedRelativeTimeOptionId, setSelectedRelativeTimeOptionId] = useState<string>(
    relativeDateOptionsMap.daysFromNow.id,
  );

  // Set the proper value for the relative time option on initial render
  useEffect(() => {
    const hasRelativeDateSelection =
      predicate.attr_type === 'date' &&
      typeof predicate?.value === 'number' &&
      predicate?.value < 0;

    if (hasRelativeDateSelection) {
      setSelectedRelativeTimeOptionId(relativeDateOptionsMap.daysAgo.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * We don't want to show value if there's no comparison,
   * or if we're just checking for existence.
   */
  if (!comparison || ['exists', 'nexists'].includes(comparison)) {
    return null;
  }

  return getValueInput({
    predicate,
    excludeIds,
    disabled,
    t,
    optionsGroup,
    handleChangeValue,
    selectedRelativeTimeOptionId,
    setSelectedRelativeTimeOptionId,
    relativeDateOptionsMap,
    user,
  });
}

export default observer(ValueInput);
