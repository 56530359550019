import type { IConfig as IBaseConfig, IReportAttributes, ReportModel } from '@feathr/blackbox';

import type { IUseReportConfigHandlers, IUseReportConfigReturn } from './useReportConfig';
import useReportConfig from './useReportConfig';
import type {
  IUseReportCustomDateRangeHandlers,
  IUseReportCustomDateRangeReturn,
} from './useReportCustomDateRange';
import useReportCustomDateRange from './useReportCustomDateRange';

interface IUseReportExportProps<
  IAttributes extends IReportAttributes,
  IConfig extends IBaseConfig,
> {
  model: ReportModel<IAttributes>;
  config: IConfig;
}

/**
 * This hook combines the useReportConfig and useReportCustomDateRanage hooks to provide a single hook
 * for the report pages so we can keep the logic abstracted for the different functionality of the
 * report pages.
 */
export interface IUseReportExportReturn<IConfig extends IBaseConfig>
  extends Omit<IUseReportCustomDateRangeReturn, 'handlers'>,
    Omit<IUseReportConfigReturn<IConfig>, 'handlers'> {
  handlers: IUseReportConfigHandlers<IConfig> & IUseReportCustomDateRangeHandlers;
}

function useReportExport<IAttributes extends IReportAttributes, IConfig extends IBaseConfig>({
  config,
  model,
}: IUseReportExportProps<IAttributes, IConfig>): IUseReportExportReturn<IConfig> {
  /*
   * Must get the config first and so it can be passed to the date range hook, so we aren't
   * passing along just the initial config
   */
  const { handlers: configHandlers, ...configProps } = useReportConfig<IAttributes, IConfig>({
    model,
    initialConfig: config,
  });

  const { handlers: dateRangeHandlers, ...dateRangeProps } = useReportCustomDateRange<
    IAttributes,
    IConfig
  >({
    model,
    config: configProps.config,
  });

  return {
    ...dateRangeProps,
    ...configProps,
    handlers: {
      ...dateRangeHandlers,
      ...configHandlers,
    },
  };
}

export default useReportExport;
