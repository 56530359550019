import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useParams } from 'react-router';

import type { ITemplate, TemplateClass } from '@feathr/blackbox';
import { AsyncSelect } from '@feathr/components';
import { TemplateOption } from '@feathr/extender/components/SelectOptions';
import { StoresContext, useAccount } from '@feathr/extender/state';

import { listTemplates } from './templateSelectShared';

interface IProps {
  onChange: (template?: ITemplate) => void;
  templateClass: TemplateClass;
  value?: ITemplate;
}

function TemplateSelectDefault({ onChange, templateClass, value }: IProps): JSX.Element {
  const { Templates } = React.useContext(StoresContext);
  const account = useAccount();
  const { eventId } = useParams<{ eventId: string }>();

  async function getDefaultTemplates(inputValue: string): Promise<ITemplate[]> {
    const templates = listTemplates({
      accountId: account.id,
      eventId,
      name: inputValue,
      templateClass,
      Templates,
    });
    await when(() => !templates.isPending);
    return templates.models.map((t) => t.toJS());
  }

  return (
    <AsyncSelect<ITemplate>
      // Set key to force refresh when templateClass prop changes.
      cacheOptions={true}
      components={{ Option: TemplateOption }}
      defaultOptions={true}
      key={templateClass}
      label={'Choose a template'}
      loadOptions={getDefaultTemplates}
      name={'template_select'}
      onSelectSingle={onChange}
      placeholder={'Select template...'}
      value={value}
    />
  );
}

export default observer(TemplateSelectDefault);
