import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';

import { Spinner, TableColumnHeader } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

import type { ITemplateRow } from './TemplatesTable';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const TemplateColumnLinkedName = {
  id: 'name',
  Header: TableColumnHeader({
    sortType: 'alpha',
    title: 'Name',
  }),
  headerClassName: tableStyles.sort,
  className: tableStyles.cell,
  Cell({ original }: ITemplateRow) {
    return (
      <Observer>
        {function useAnonymousFunction() {
          const localUrl = useLocalUrl();
          const { template } = original;
          if (template.isUpdating) {
            return (
              <div style={{ display: 'flex' }}>
                <i>Loading...</i>&nbsp;
                <Spinner size={16} />
              </div>
            );
          }
          const to = localUrl(template.getItemUrl());
          return <Link to={to}>{template.name}</Link>;
        }}
      </Observer>
    );
  },
};

export default TemplateColumnLinkedName;
