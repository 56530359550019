import { useDisclosure } from '@mantine/hooks';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ToastType } from 'react-toastify';
import type { ClipboardAPI } from 'use-clipboard-copy';
import { useClipboard } from 'use-clipboard-copy';

import type { Account, Campaign, Partner } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import {
  ArchiveModalV1,
  ContextMenu,
  MenuItem,
  TableColumnHeader,
  toast,
  Tooltip,
} from '@feathr/components';
import { StoresContext, useAccount, useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import { AddCampaignModal } from '../../../CampaignsPage/AddCampaignButton';

import styles from './OptionsColumn.css';

interface IProps {
  original: Partner;
}

function OptionsCell({ original: partner }: IProps): JSX.Element {
  const account: Account | undefined = useAccount();
  const { t } = useTranslation();
  const clipboard: ClipboardAPI = useClipboard({
    onSuccess: (): void => {
      toast(t('Copied dashboard link to clipboard.'), { type: ToastType.SUCCESS });
    },
    onError: (): void => {
      toast(t("Couldn't copy the link. Please try again."), { type: ToastType.ERROR });
    },
  });
  const { Campaigns } = useContext(StoresContext);
  const history = useHistory();
  const localUrl = useLocalUrl();
  const [showArchiveModal, toggleShowArchiveModal] = useToggle(false);
  const [isAddCampaignModalOpen, { open: openAddCampaignModal, close: closeAddCampaignModal }] =
    useDisclosure(false);

  const parent: string = partner.get('parent');

  async function createMessage(): Promise<void> {
    const message: Campaign = Campaigns.create({
      _cls: CampaignClass.PinpointPartnerMessage,
      event: parent,
      participation: {
        partner_ids: [partner.id],
        mode: 'manual',
      },
    });
    const response = await Campaigns.add(message, { validate: false });
    history.push(localUrl(response.getItemUrl()));
  }

  async function archive(): Promise<void> {
    try {
      partner.set({ is_archived: true });
      await partner.save();
      toast(t('Successfully archived.'), { type: 'success' });

      // If err is instance of Error, it should be of type any.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      partner.set({ is_archived: false });
      toast(
        t('Encountered an error while archiving:\n{{errorMessage}}', {
          errorMessage: error.message,
        }),
        {
          type: 'error',
        },
      );
    } finally {
      toggleShowArchiveModal();
    }
  }

  const hasMonetization = !!account?.hasMonetization;
  const monetizationDisabled: boolean =
    !partner.get('logo') ||
    !partner.get('logo').includes('http') ||
    !partner.get('website') ||
    !partner.get('website').includes('http') ||
    !hasMonetization;

  function handleCopyDashboardLink(): void {
    clipboard.copy(partner.getDashboardUrl());
  }

  return (
    <>
      <ContextMenu>
        <MenuItem href={partner.getDashboardUrl()} target={'_blank'}>
          {t('View dashboard')}
        </MenuItem>
        <MenuItem onClick={handleCopyDashboardLink}>{t('Copy dashboard link')}</MenuItem>
        <MenuItem onClick={createMessage}>{t('Message this partner')}</MenuItem>
        {monetizationDisabled ? (
          <Tooltip
            title={t(
              'You can only add monetization campaigns for partners with a valid logo and website URL.',
            )}
          >
            <MenuItem disabled={true}>{t('Add monetization campaign')}</MenuItem>
          </Tooltip>
        ) : (
          <MenuItem onClick={openAddCampaignModal}>{t('Add monetization campaign')}</MenuItem>
        )}
        <MenuItem link={localUrl(partner.getItemUrl())}>{t('Edit')}</MenuItem>
        <MenuItem onClick={toggleShowArchiveModal}>{t('Archive')}</MenuItem>
      </ContextMenu>

      <AddCampaignModal
        campaignClasses={[
          CampaignClass.Affinity,
          CampaignClass.EmailList,
          CampaignClass.Facebook,
          CampaignClass.Lookalike,
          CampaignClass.MobileGeoFenceRetargeting,
          CampaignClass.MobileGeoFencing,
          CampaignClass.PinpointEmail,
          CampaignClass.Search,
          CampaignClass.SeedSegment,
          CampaignClass.Segment,
        ]}
        onClose={closeAddCampaignModal}
        opened={isAddCampaignModalOpen}
        partnerId={partner.id}
      />

      {showArchiveModal && (
        <ArchiveModalV1
          model={partner}
          onClose={toggleShowArchiveModal}
          onConfirm={archive}
          t={t}
        />
      )}
    </>
  );
}

export default () => ({
  id: 'options',
  checkboxLabel: 'Options',
  Header: TableColumnHeader({
    title: 'Options',
  }),
  className: styles.root,
  width: 80,
  sortable: false,
  Cell(row: IProps): JSX.Element {
    return <OptionsCell {...row} />;
  },
});
