import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { StoresContext } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function MessageLabel({ className, id }: IProps) {
  const { Campaigns } = React.useContext(StoresContext);
  const message = Campaigns.get(id);
  return (
    <div className={classNames({ [skeleton]: message.isPending }, className)}>
      {message.get('name', 'placeholder') as string}
    </div>
  );
}

export default observer(MessageLabel);
