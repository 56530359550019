import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTimeFrameValue } from '@feathr/components';
import { StatBox } from '@feathr/components';
import useDashboardAggregatedStats from '@feathr/extender/hooks/useDashboardAggregatedStats';

import * as styles from './AudienceEngagementStats.css';

interface IProps {
  timeFrameValue: TTimeFrameValue;
}

export function AudienceEngagementStats({ timeFrameValue }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { isLoading, community, connections, conversions } = useDashboardAggregatedStats({
    timeFrameValue,
  });

  return (
    <div className={styles.root}>
      <StatBox
        align={'center'}
        border={'right'}
        isLoading={isLoading}
        label={t('Community')}
        primary={numeral(community).format('0,0')}
        secondaryLabel={t('People')}
        statSize={'large'}
        theme={'purple'}
        tooltip={t(
          'The total number of people that have been added to your account from all sources.',
        )}
        tooltipIcon={faInfoCircle}
      />
      <StatBox
        align={'center'}
        border={'none'}
        isLoading={isLoading}
        label={t('Connections')}
        primary={numeral(connections).format('0,0')}
        secondaryLabel={t('People')}
        statSize={'large'}
        theme={'cyan'}
        tooltip={t(
          'The number of unique interactions that your audience has had with you across all channels.',
        )}
        tooltipIcon={faInfoCircle}
      />
      <StatBox
        align={'center'}
        border={'left'}
        isLoading={isLoading}
        label={t('Conversions')}
        primary={numeral(conversions).format('0,0')}
        secondaryLabel={t('Total')}
        statSize={'large'}
        theme={'green'}
        tooltip={t(
          'The total number of conversions across all of your campaigns, projects, and goals.',
        )}
        tooltipIcon={faInfoCircle}
      />
    </div>
  );
}

export default AudienceEngagementStats;
