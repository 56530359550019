import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { EUsageMetrics } from '@feathr/blackbox';
import { Button, Checkbox, DebouncedInput, Input, Tab } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import {
  useAccount,
  useActionBar,
  useLocalUrl,
  useRole,
  useUsageAndQuota,
} from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import InviteUsersButton from './InviteUsersButton';
import MFAAuthentication from './MFAAuthentication';
import CreateRoleModal from './Roles/CreateRoleModal';
import rolesColumns from './Roles/rolesColumns';
import RolesTable from './Roles/RolesTable';
import BulkAssignButton from './Users/BulkAssignButton';
import usersColumns from './Users/usersColumns';
import UsersTable from './Users/UsersTable';

import * as columnStyles from './Users/UsersColumns.css';
import * as styles from './UsersAndRolesPage.css';

function UsersAndRolesPage(): JSX.Element {
  const localUrl = useLocalUrl();
  const [search, setSearch] = useState<string | undefined>();
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const { t } = useTranslation();
  const { setLeftActions, setRightActions } = useActionBar();
  const { accountId } = useParams<{ accountId: string }>();
  const { getUsageText } = useUsageAndQuota(accountId, [EUsageMetrics.Users]);

  const { tab } = useParams<{ tab: 'roles' | 'authentication' }>();
  const isTabRoles = tab === 'roles';
  const isTabAuthentication = tab === 'authentication';
  const placeHolderText = isTabRoles ? t('Search by role...') : t('Search by name or email...');

  const [isCreateRoleModalOpen, toggleIsCreateRoleModalOpen] = useToggle(false);

  const account = useAccount();
  const { hasPermissionsLicenseOrFlag } = useRole();
  const userRoles = account.get('user_roles');

  // Clear the search field when switching between tabs.
  useEffect(() => {
    setSearch('');
    setSelectedUserIds([]);
    account.reload();
  }, [isTabRoles]);

  function handleDebouncedSearchChange(newValue?: string): void {
    setSearch(newValue ?? '');
  }

  function handleClearSelection(): void {
    setSelectedUserIds([]);
  }

  const rawSearch = isTabRoles
    ? [{ name: { $regex: search, $options: 'i' } }]
    : [{ name: { $regex: search, $options: 'i' } }, { email: { $regex: search, $options: 'i' } }];

  const filters = search
    ? {
        __raw__: {
          $or: rawSearch,
        },
      }
    : {};

  const filterElements: JSX.Element[] = [
    <DebouncedInput<string>
      defaultValue={search}
      key={'debounced-input-users-and-roles-search'}
      onChange={handleDebouncedSearchChange}
    >
      {(liveValue, onChangeLiveValue): JSX.Element => (
        <Input
          className={styles.filter}
          isClearable={true}
          key={'users-and-roles-search'}
          onChange={onChangeLiveValue}
          placeholder={placeHolderText}
          prefix={<FontAwesomeIcon icon={faSearch} />}
          type={'text'}
          value={liveValue}
        />
      )}
    </DebouncedInput>,
  ];

  const userActions: JSX.Element[] = [<InviteUsersButton key={'invite-users-button'} />];

  const roleActions: JSX.Element[] = [
    <Button
      key={'createRole'}
      name={'create-role'}
      onClick={toggleIsCreateRoleModalOpen}
      prefix={<FontAwesomeIcon icon={faPlus} />}
      type={'primary'}
    >
      {t('Create role')}
    </Button>,
  ];

  const actions: JSX.Element[] = isTabRoles ? roleActions : userActions;

  useEffect(() => {
    const selectedUsersCount = selectedUserIds.length ?? 0;
    if (selectedUsersCount) {
      setLeftActions([
        <Checkbox
          className={columnStyles.selectionCheckbox}
          key={'selected-user-ids'}
          label={
            <Trans count={selectedUsersCount} t={t}>
              <strong>{{ count: selectedUsersCount }} person</strong> selected
            </Trans>
          }
          onChange={handleClearSelection}
          value={true}
        />,
        // TODO: Add option to select all users and update label accordingly (to be implemented in the integration ticket) #3248
        <Button key={'clear-selection'} onClick={handleClearSelection} type={'link'}>
          {t('Clear selection')}
        </Button>,
      ]);
      setRightActions(
        <BulkAssignButton onClearSelection={handleClearSelection} selected={selectedUserIds} />,
      );
    } else {
      setLeftActions(undefined);
      setRightActions(undefined);
    }
  }, [selectedUserIds, setRightActions, setLeftActions, t]);

  const tabs = [
    <Tab
      exact={true}
      key={'users'}
      link={localUrl('/settings/account/users')}
      title={t('Users')}
    />,
  ];

  if (hasPermissionsLicenseOrFlag) {
    tabs.push(
      <Tab
        exact={true}
        key={'roles'}
        link={localUrl('/settings/account/users/roles')}
        title={t('Roles')}
      />,
    );
  }

  tabs.push(
    <Tab
      exact={true}
      key={'authentication'}
      link={localUrl('/settings/account/users/authentication')}
      title={t('Authentication')}
    />,
  );

  return (
    <Page
      actions={actions}
      secondaryText={!isTabRoles ? getUsageText() : undefined}
      tabs={tabs}
      title={hasPermissionsLicenseOrFlag ? t('Users & Roles') : t('Users')}
    >
      {isTabAuthentication ? (
        <MFAAuthentication />
      ) : isTabRoles ? (
        <>
          {isCreateRoleModalOpen && <CreateRoleModal onClose={toggleIsCreateRoleModalOpen} />}
          <RolesTable
            columns={rolesColumns(userRoles, t)}
            filterElements={filterElements}
            // When using leftActions, the filterLabel defaults to "Filter by". We want to hide it until we can move away from it.
            filterLabel={<></>}
            filters={filters}
          />
        </>
      ) : (
        <UsersTable
          columns={usersColumns(t)}
          filterElements={filterElements}
          // When using leftActions, the filterLabel defaults to "Filter by". We want to hide it until we can move away from it.
          filterLabel={<></>}
          filters={filters}
          selected={selectedUserIds}
          setSelected={setSelectedUserIds}
        />
      )}
    </Page>
  );
}

export default observer(UsersAndRolesPage);
