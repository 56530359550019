import { faCheck, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Flight, IConfig } from '@feathr/blackbox';
import { Button, Toolbar } from '@feathr/components';
import MakeSharedFlightModal from '@feathr/extender/App/EventsPage/FlightPage/MakeSharedFlightModal';
import type { IUseReportExportReturn } from '@feathr/extender/hooks';
import { useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import ReportActionsContextMenu from './ReportActionsContextMenu';

export interface IReportActionsFlightProps extends IUseReportExportReturn<IConfig> {
  flight: Flight;
}

interface IFlightActionsProps {
  flight: Flight;
}

type tCampaignActionsProps = IReportActionsFlightProps | IFlightActionsProps;

function isReportProps(obj: tCampaignActionsProps): obj is IReportActionsFlightProps {
  return 'config' in obj;
}

function ReportActionsFlight(props: Readonly<tCampaignActionsProps>): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const [isFlightModalOpen, toggleIsFlightModalOpen] = useToggle(false);
  const { flight } = props;

  function renderReportActions(): JSX.Element | null {
    if (!isReportProps(props)) {
      return null;
    }

    const { flight, ...exportProps } = props;
    return <ReportActionsContextMenu model={flight} {...exportProps} />;
  }

  return (
    <>
      <Toolbar>
        {renderReportActions()}
        <Button
          link={localUrl(flight.getItemUrl('edit'))}
          prefix={<FontAwesomeIcon icon={faPencil} />}
        >
          {t('Edit')}
        </Button>
        <Button
          key={'flight-path'}
          onClick={toggleIsFlightModalOpen}
          prefix={flight.get('shared') ? <FontAwesomeIcon icon={faCheck} /> : undefined}
          type={'primary'}
        >
          {flight.get('shared') ? t('Flight Path') : t('Make Flight Path')}
        </Button>
      </Toolbar>

      {isFlightModalOpen && (
        <MakeSharedFlightModal flight={flight} toggleModal={toggleIsFlightModalOpen} />
      )}
    </>
  );
}

export default observer(ReportActionsFlight);
