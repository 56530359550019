import { when } from 'mobx';
import { Observer, observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { PinpointPartnerMessage } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import { Button, Fieldset, Form, FormElement } from '@feathr/components';
import CampaignSelect from '@feathr/extender/components/CampaignSelect';
import { StoresContext } from '@feathr/extender/state';
import { useId } from '@feathr/hooks';

import * as styles from './EventPartnersMessageStepThree.css';

interface IProps {
  disabled?: boolean;
  message: PinpointPartnerMessage;
  onNext: () => void;
  onPrevious: () => void;
}

function EventPartnersMessageStepThree({
  disabled,
  message,
  onNext,
  onPrevious,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const { Partners } = useContext(StoresContext);
  const { eventId } = useParams<{ eventId: string }>();
  const elementId = useId();

  async function filterParticipation(campaignId: string): Promise<void> {
    /*
     * If you choose a campaign, you get a subset of the partners in the project
     * to choose from in the participation step. this removes partners that are
     * not participants of the chosen campaign from the participation of this message.
     */
    const recipients = message.get('participation').partner_ids;
    if (recipients && recipients.length > 0) {
      const availablePartners = Partners.list({
        filters: { participation: campaignId },
        only: ['id'],
        pagination: { page_size: 9999 },
      });
      await when(() => !availablePartners.isPending);
      const availablePartnerIds = availablePartners.models.map((p) => p.id);
      const filteredRecipients = recipients.filter((r: string) => availablePartnerIds.includes(r));
      message.set({
        participation: {
          ...message.get('participation'),
          partner_ids: filteredRecipients,
        },
      });
    }
  }

  return (
    <Form
      actions={[
        <Button key={'previous'} name={'previous_step'} onClick={onPrevious}>
          {t('Previous')}
        </Button>,
        <Button key={'next'} name={'next_step'} onClick={onNext} type={'primary'}>
          {t('Next')}
        </Button>,
      ]}
      description={
        <Trans t={t}>
          Selecting a campaign allows you to use campaign-specific content and merge variables.
          <strong>
            This also restricts the possible recipients of this message to the participating
            partners of the selected campaign.
          </strong>
        </Trans>
      }
      label={t('Edit Message: Campaign')}
    >
      <Fieldset>
        <FormElement htmlFor={elementId} label={t('Campaign')}>
          <Observer>
            {(): JSX.Element => (
              <CampaignSelect
                className={styles.element}
                disabled={disabled}
                eventId={eventId!}
                id={elementId}
                isMulti={false}
                onChange={(newCampaign) => {
                  message.set({
                    invites_campaign: newCampaign,
                    participation: { ...message.get('participation'), mode: 'campaign' },
                  });
                  filterParticipation(newCampaign);
                }}
                onClear={() =>
                  message.set({
                    invites_campaign: undefined,
                    participation: { ...message.get('participation'), mode: 'event' },
                  })
                }
                placeholder={t('Select a campaign')}
                type={[CampaignClass.Referral]}
                value={message.get('invites_campaign')}
              />
            )}
          </Observer>
        </FormElement>
      </Fieldset>
    </Form>
  );
}

export default observer(EventPartnersMessageStepThree);
