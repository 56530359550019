import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign, Campaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function getCPC(campaign: Campaign): number {
  const spend = campaign.get('total_stats').spend || 0;
  const clicks = campaign.totalClicks;
  return clicks > 0 ? spend / clicks : 0;
}

const CPCColumn: IColumn<BaseCampaign> = {
  id: 'cpc',
  checkboxLabel: 'CPC',
  Header: TableColumnHeader({
    title: 'CPC',
  }),
  width: 80,
  sortable: false,
  className: tableStyles.cellRight,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const cpc = getCPC(original);
          return <>{numeral(cpc).format('$0,0.00')}</>;
        }}
      </Observer>
    );
  },
  Footer({ data }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const spendArray = data.map((item) => item._original.get('total_stats').spend || 0);
          // Exclude campaigns with zero spend from the average
          const clicksArray = data.map((item) =>
            item._original.get('total_stats').spend > 0 ? item._original.totalClicks : 0,
          );
          const totalSpend = spendArray.reduce((sum, num) => sum + num, 0);
          const totalClicks = clicksArray.reduce((sum, num) => sum + num, 0);
          const weightedAvg = totalClicks > 0 ? totalSpend / totalClicks : 0;
          return <>{numeral(weightedAvg).format('$0,0.00')}</>;
        }}
      </Observer>
    );
  },
};

export default CPCColumn;
