import { observer } from 'mobx-react-lite';
import React from 'react';

import type {
  Creative,
  DisplayCreative,
  IAdTagBaseCreative,
  ReferralBannerCreative,
  VideoCreative,
} from '@feathr/blackbox';
import { CreativeClass } from '@feathr/blackbox';

import AdTagCreativeThumbnail from './AdTagCreativeThumbnail';
import ImageCreativeThumbnail from './ImageCreativeThumbnail';
import VideoCreativeThumbnail from './VideoCreativeThumbnail';

import noImg from '@feathr/report_components/images/no-img.png';

interface IProps {
  className?: string;
  creative: Creative;
}

function CreativeThumbnail({ className, creative }: IProps) {
  switch (creative.get('_cls')) {
    case CreativeClass.DisplayImage:

    case CreativeClass.FacebookImage:
      const imgCrv = creative as DisplayCreative;
      return <ImageCreativeThumbnail className={className} creative={imgCrv} size={200} />;

    case CreativeClass.DisplayVideo:

    case CreativeClass.FacebookVideo:
      const videoCrv = creative as VideoCreative;
      return <VideoCreativeThumbnail className={className} creative={videoCrv} size={200} />;

    case CreativeClass.DisplayAdTag:

    case CreativeClass.DisplayBannersnack:
      const adtCrv = creative as DisplayCreative<IAdTagBaseCreative>;
      return <AdTagCreativeThumbnail className={className} creative={adtCrv} size={200} />;

    case CreativeClass.ReferralPage:
      const pageCrv = creative;
      return (
        <ImageCreativeThumbnail
          className={className}
          size={200}
          url={`${BLACKBOX_URL}creatives/${pageCrv.id}/thumbnail?w=200&h=150`}
        />
      );

    case CreativeClass.ReferralBanner:
      const bannerCrv = creative as ReferralBannerCreative;
      return (
        <ImageCreativeThumbnail
          className={className}
          size={200}
          url={`${BLACKBOX_URL}creatives/${bannerCrv.id}/thumbnail?w=${bannerCrv.get(
            'width',
          )}&h=${bannerCrv.get('height')}`}
        />
      );

    case CreativeClass.ReferralEmail:
      const emailCrv = creative;
      return (
        <ImageCreativeThumbnail
          className={className}
          size={200}
          url={`${BLACKBOX_URL}creatives/${emailCrv.id}/thumbnail?w=200&h=150`}
        />
      );

    case CreativeClass.PartnerMessageEmail:
      const messageCrv = creative;
      return (
        <ImageCreativeThumbnail
          className={className}
          size={200}
          url={`${BLACKBOX_URL}creatives/${messageCrv.id}/thumbnail?w=200&h=150`}
        />
      );

    default:
      return <ImageCreativeThumbnail className={className} size={200} url={noImg} />;
  }
}

export default observer(CreativeThumbnail);
