import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign, IStats, TAttributionModel } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function goalExists(stats: IStats, attributionModel: TAttributionModel): boolean {
  const { conversions } = stats;
  return conversions ? !!conversions[attributionModel]?.segments_breakdown.length : false;
}

function getNumConv(stats: IStats, attributionModel: TAttributionModel): number {
  const { conversions } = stats;
  return (conversions && conversions[attributionModel]?.num) || 0;
}

function getCPA(stats: IStats, attributionModel: TAttributionModel): number {
  const { spend = 0 } = stats;
  const numConversions = getNumConv(stats, attributionModel);
  return numConversions > 0 ? spend / numConversions : 0;
}

export function getCPAColumn(attributionModel: TAttributionModel): IColumn<BaseCampaign> {
  return {
    id: 'cpa',
    checkboxLabel: 'CPA',
    Header: TableColumnHeader({
      title: 'CPA',
    }),
    sortable: false,
    width: 80,
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      return (
        <Observer>
          {(): JSX.Element => {
            const cpa = getCPA(original.get('total_stats'), attributionModel);
            return <>{numeral(cpa).format('$0,0.00')}</>;
          }}
        </Observer>
      );
    },
    Footer({ data }): JSX.Element {
      return (
        <Observer>
          {(): JSX.Element => {
            // Exclude campaigns with no goal groups and/or zero spend
            const filteredData = data.filter(
              (item) =>
                goalExists(item._original.get('total_stats'), attributionModel) &&
                item._original.get('total_stats').spend > 0,
            );
            const spendArray = filteredData.map(
              (item) => item._original.get('total_stats').spend || 0,
            );
            const convArray = filteredData.map((item) =>
              getNumConv(item._original.get('total_stats'), attributionModel),
            );
            const totalSpend = spendArray.reduce((sum, num) => sum + num, 0);
            const totalConv = convArray.reduce((sum, num) => sum + num, 0);
            const weightedAvg = totalConv > 0 ? totalSpend / totalConv : 0;
            return <>{numeral(weightedAvg).format('$0,0.00')}</>;
          }}
        </Observer>
      );
    },
  };
}
