import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { PinpointPartnerMessage } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { Alert, AlertType, Button, Form } from '@feathr/components';
import type { TValidateGrouped } from '@feathr/rachis';

import ButtonNext from './ButtonNext';
import { validateStepFive } from './EventPartnersMessageStepFive';
import { validateStepFour } from './EventPartnersMessageStepFour';
import { validateStepOne } from './EventPartnersMessageStepOne';
import { validateStepSix } from './EventPartnersMessageStepSix';
import PartnerMessageSummary from './PartnerMessageSummary';

interface IProps {
  disabled?: boolean;
  message: PinpointPartnerMessage;
  onCancel: () => void;
  onSave: () => void;
  onPrevious: () => void;
}

export interface IEventPartnersMessageErrors extends TValidateGrouped {
  name?: string[];
  campaign?: string[];
  participation?: string[];
  from_address?: string[];
  from_name?: string[];
  subject?: string[];
  template?: string[];
  date_send_start?: string[];
}

function validate(message: PinpointPartnerMessage): IEventPartnersMessageErrors {
  return {
    ...validateStepOne(message),
    ...validateStepFive(message),
    ...validateStepFour(message),
    ...validateStepSix(message),
  };
}

function EventPartnersMessageStepSeven({
  disabled = false,
  message,
  onCancel,
  onSave,
  onPrevious,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const state: CampaignState = message.get('state', CampaignState.Draft);

  const actions: ReactNode[] = [];
  if (state === 'draft') {
    actions.push(
      <Button key={'previous'} name={'previous_step'} onClick={onPrevious}>
        {t('Previous')}
      </Button>,
    );
    actions.push(
      <ButtonNext
        key={'send'}
        message={message}
        name={'send_message'}
        onNext={onSave}
        type={'success'}
        validate={validate}
      >
        {t('Send')}
      </ButtonNext>,
    );
  } else if (state === CampaignState.Published && !message.isPastStartDate) {
    actions.push(
      <Button key={'previous'} name={'cancel_send'} onClick={onCancel} type={'danger'}>
        {t('Cancel sending')}
      </Button>,
    );
  }

  return (
    <Form
      actions={actions}
      description={t(
        "Check the summary below to make sure everything is configured correctly. When you're ready, click Send to finalize your message.",
      )}
      label={t('Edit Message: Review')}
    >
      {disabled && (
        <Alert type={AlertType.info}>
          {t(
            'If you would like to make changes to this partner message, you must cancel sending first, make your changes, and re-publish.',
          )}
        </Alert>
      )}
      <PartnerMessageSummary message={message} validationErrors={validate(message)} />
    </Form>
  );
}

export default observer(EventPartnersMessageStepSeven);
