import type { Account, BaseCampaign, TAttributionModel } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';

import { getConversionsColumn } from './ConversionsColumn';
import { getCPAColumn } from './CPAColumn';
import getDefaultColumns from './defaultColumns';
import { getROIColumn } from './ROIColumn';

interface IPermissionColumn {
  shouldShow?: (account: Account) => boolean;
}

function filterColumns(
  attributionModel: TAttributionModel,
  columns: Array<IColumn<BaseCampaign>>,
): Array<IColumn<BaseCampaign>> {
  return columns.map((column) => {
    if (column.id === 'roi') {
      return getROIColumn(attributionModel);
    }
    if (column.id === 'conversions') {
      return getConversionsColumn(attributionModel);
    }
    if (column.id === 'cpa') {
      return getCPAColumn(attributionModel);
    }
    return column;
  });
}

export function getColumnIds(attributionModel: TAttributionModel, columnIds: string[]): string[] {
  return columnIds.map((columnId) => {
    if (columnId === 'roi') {
      return getROIColumn(attributionModel).id!;
    }
    if (columnId === 'conversions') {
      return getConversionsColumn(attributionModel).id!;
    }
    if (columnId === 'cpa') {
      return getCPAColumn(attributionModel).id!;
    }
    return columnId;
  });
}

function getColumns(
  attributionModel: TAttributionModel,
  includeProjectColumn: boolean,
  account: Account,
): Array<IPermissionColumn & IColumn<BaseCampaign>> {
  const columns = getDefaultColumns(includeProjectColumn);
  return filterColumns(attributionModel, columns).filter(
    (col: IPermissionColumn & IColumn<BaseCampaign>) =>
      col.shouldShow ? !!account && col.shouldShow(account) : true,
  );
}

export default getColumns;
