import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { SmartPinpointEmailCampaign } from '@feathr/blackbox';
import { ButtonValid, Fieldset, Form, Input } from '@feathr/components';
import { flattenError, flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface IButtonProps {
  campaign: SmartPinpointEmailCampaign;
  onNext: () => void;
}

interface IProps extends IButtonProps {
  disabled: boolean;
}

interface IErrors extends TValidateGrouped {
  name?: string[];
}

export function validateStepOne(campaign: SmartPinpointEmailCampaign): IErrors {
  return campaign.validate<IErrors>(['name'], false, 'grouped').errors;
}

const NextStepButton = observer(({ campaign, onNext }: IButtonProps) => {
  const errors = validateStepOne(campaign);
  return (
    <ButtonValid errors={flattenErrors(errors)} name={'next_step'} onClick={onNext}>
      Next
    </ButtonValid>
  );
});

function SmartPinpointEmailCampaignStepOne({ campaign, disabled, onNext }: IProps): JSX.Element {
  const validationErrors = validateStepOne(campaign);
  return (
    <Form
      actions={[<NextStepButton campaign={campaign} key={'next'} onNext={onNext} />]}
      label={'Edit Campaign: Info'}
    >
      <Fieldset>
        <Input
          attribute={'name'}
          disabled={disabled}
          helpText={'Name your campaign.'}
          label={'Name'}
          model={campaign}
          name={'campaign_name'}
          required={true}
          type={'text'}
          validationError={flattenError(validationErrors.name)}
        />
      </Fieldset>
    </Form>
  );
}

export default observer(SmartPinpointEmailCampaignStepOne);
