import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Button } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { StoresContext } from '@feathr/extender/state';

import DomainDetails from './DomainDetails';
import DomainRecords from './DomainRecords';

function DomainDetailPage(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { Domains } = useContext(StoresContext);
  const { domainId } = useParams<{ domainId: string }>();
  if (!domainId) {
    throw new Error(t('Domain id not found.'));
  }
  const domain = Domains.get(domainId);

  function handleClickEdit(): void {
    history.push(domain.getItemUrl('edit'));
  }

  return (
    <Page actions={<Button onClick={handleClickEdit}>{t('Edit')}</Button>} title={domain.name}>
      <DomainDetails domain={domain} />
      <DomainRecords domain={domain} />
    </Page>
  );
}

export default observer(DomainDetailPage);
