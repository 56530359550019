import { faInfoCircle, faSync, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Segment } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { Chip, Fieldset } from '@feathr/components';
import { cssVar } from '@feathr/hooks';

import * as styles from './FacebookAudience.css';

interface IProps {
  segment?: Segment;
  campaignState: CampaignState;
}

function FacebookAudience({ campaignState, segment }: IProps): JSX.Element {
  const { t } = useTranslation();

  function getAudience(): JSX.Element | null {
    if (segment?.isPending) {
      return null;
    }

    if (campaignState === CampaignState.Draft) {
      return (
        <div>
          <FontAwesomeIcon className={styles.icon} icon={faInfoCircle} />
          {t(
            'Because the audience size may be different on Meta platforms, it will be determined after your campaign is published.',
          )}
        </div>
      );
    }

    if (campaignState === CampaignState.Erroring) {
      return (
        <div>
          <FontAwesomeIcon
            className={styles.icon}
            color={cssVar('--color-brand-danger')}
            icon={faTriangleExclamation}
          />
          {t('Please make the recommended edits to your campaign to re-sync audience.')}
        </div>
      );
    }

    if (campaignState === CampaignState.Published) {
      const SyncMessage = (
        <>
          <FontAwesomeIcon className={styles.icon} icon={faSync} spin={true} />
          {t('Syncing')}
        </>
      );

      const audience = segment?.get('fb_custom_audience');
      return (
        <Fieldset className={styles.estimates} direction={'column'}>
          <div>
            <label className={styles.label}>{t('Lower estimate')}</label>
            <Chip
              className={classNames({ [styles.success]: audience, [styles.sync]: !audience })}
              isLoading={segment?.isPending}
              key={'lower'}
            >
              {audience?.lower_bound ?? SyncMessage}
            </Chip>
          </div>
          <div>
            <label className={styles.label}>{t('Higher estimate')}</label>
            <Chip
              className={classNames({ [styles.success]: audience, [styles.sync]: !audience })}
              isLoading={segment?.isPending}
              key={'higher'}
            >
              {audience?.upper_bound ?? SyncMessage}
            </Chip>
          </div>
        </Fieldset>
      );
    }

    return null;
  }

  return (
    <Fieldset>
      <label>{t('Meta platforms audience size')}</label>
      {getAudience()}
    </Fieldset>
  );
}

export default observer(FacebookAudience);
