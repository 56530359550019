import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import type { Creative, DailyStat, DailyStats } from '@feathr/blackbox';
import { CreativeClass } from '@feathr/blackbox';
import { moment } from '@feathr/hooks';

interface IProps {
  start: string;
  end: string;
  Stats: DailyStats;
  creative: Creative;
  calculate: (stats: IObservableArray<DailyStat>) => string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function CreativeStat({ calculate, creative, end, Stats, start }: IProps) {
  const pageSize =
    Math.max(
      Math.ceil(
        moment
          .duration(moment.utc(end, moment.ISO_8601).diff(moment.utc(start, moment.ISO_8601)))
          .asDays(),
      ),
      0,
    ) + 1;
  const getStatsModel = () => {
    switch (creative.get('_cls')) {
      case CreativeClass.FacebookImage:

      case CreativeClass.FacebookVideo:
        return 'facebook_creative';

      default:
        return 'creative';
    }
  };
  const stats = Stats.list({
    filters: {
      metadata__date__gte: start,
      metadata__date__lte: end,
      metadata__obj_id: creative.id,
    },
    model: getStatsModel(),
    pagination: {
      page_size: pageSize,
    },
  });
  if (stats.isPending) {
    return <>-</>;
  }
  return <>{calculate(stats.models)}</>;
}

export default observer(CreativeStat);
