import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { TModalV1ActionEvent } from '@feathr/components';
import { ModalV1, toast } from '@feathr/components';
import { StoresContext, useAccount, useUser } from '@feathr/extender/state';
import { flattenError } from '@feathr/hooks';
import { validate } from '@feathr/rachis';

import RoleSelect from '../../Roles/RoleSelect';

interface IBulkAssignModalProps {
  onClearSelection: () => void;
  onClose: (event: TModalV1ActionEvent) => void;
  selected: string[];
}

function BulkAssignModal({
  onClearSelection,
  onClose,
  selected,
}: Readonly<IBulkAssignModalProps>): JSX.Element {
  const { t } = useTranslation();
  const [role, setRole] = useState<string | undefined>(undefined);
  const account = useAccount();
  const { id: currentUserId } = useUser();
  const userRoles = account?.get('user_roles');
  const { UserRoles } = useContext(StoresContext);
  const roleList = UserRoles.list();

  function handleClose(event: TModalV1ActionEvent): void {
    setRole(undefined);
    onClearSelection();
    onClose(event);
  }

  function getRoleName(roleId: string): string {
    return roleList.models.find((role) => role.id === roleId)?.name ?? '';
  }

  async function handleAssign(event: TModalV1ActionEvent): Promise<void> {
    if (role) {
      const unselectedUserRoles = userRoles.filter(
        (userRole) => !selected.some((user) => user === userRole.user),
      );
      runInAction(() => {
        account.set({
          user_roles: [
            ...unselectedUserRoles,
            ...selected.map((userId) => {
              return { user: userId, role: role };
            }),
          ],
        });
      });

      try {
        await account.patchDirty();
        toast(
          t('{{ count }} person reassigned to the role {{ role }}', {
            count: selected.length,
            role: getRoleName(role),
          }),
          { type: ToastType.SUCCESS },
        );
      } catch (error) {
        toast(t('Something went wrong while updating the user roles: {{- error}}', { error }), {
          type: ToastType.ERROR,
        });
      }
    }
    handleClose(event);
  }

  function handleRoleSelect(roleId: string): void {
    setRole(roleId);
  }

  function validateRole(): string[] {
    const roleConstraint = {
      presence: {
        allowEmpty: false,
        message: '^A role must be selected.',
      },
    };
    const error = validate.single(role, roleConstraint);
    return flattenError(error) ?? [];
  }

  function validateSelectedUsers(): string[] {
    const hasCurrentUser = selected.includes(currentUserId);
    return hasCurrentUser ? [t('You cannot reassign yourself to a role.')] : [];
  }

  const validationErrors = [...validateRole(), ...validateSelectedUsers()];

  return (
    <ModalV1
      confirmButtonText={t('Assign')}
      confirmDisabled={validationErrors.length > 0}
      controlled={true}
      description={t('Are you sure you want to assign these {{ count }} person?', {
        count: selected.length ?? 0,
      })}
      errors={validationErrors}
      onClose={onClose}
      onConfirm={handleAssign}
      size={'sm'}
      t={t}
      title={t('Assign People')}
      useValidation={true}
    >
      <RoleSelect onChange={handleRoleSelect} />
    </ModalV1>
  );
}

export default observer(BulkAssignModal);
