import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import type { Creative } from '@feathr/blackbox';
import { StoresContext } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function CreativeLabel({ className, id }: IProps) {
  const { Creatives } = React.useContext(StoresContext);
  const creative: Creative = Creatives.get(id);
  return (
    <div className={classNames({ [skeleton]: creative.isPending }, className)}>
      {creative.get('name', 'placeholder') as string}
    </div>
  );
}

export default observer(CreativeLabel);
