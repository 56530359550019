import type { JSX } from 'react';
import React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';

import RedirectTo403 from './RedirectTo403';

interface IPrivateRouteProps extends RouteProps {
  children: JSX.Element;
  condition: boolean | undefined;
}

function RouteWithPermissions({
  children,
  condition,
  ...rest
}: Readonly<IPrivateRouteProps>): JSX.Element {
  return (
    <Route exact={true} {...rest}>
      {condition ? children : <RedirectTo403 />}
    </Route>
  );
}

export default RouteWithPermissions;
