import { ObjectId } from 'bson';
import type { JSX } from 'react';
import React from 'react';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';

import type { IPath } from '@feathr/blackbox';
import { Button, CollapseButton, ListItem } from '@feathr/components';
import { useToggle } from '@feathr/hooks';

import CustomPathPreview from './CustomPathPreview';
import PathPreview from './PathPreview';

import styles from './Path.css';

import defaultFlightImage01 from '@feathr/extender/images/flight_default_01.jpg';
import defaultFlightImage02 from '@feathr/extender/images/flight_default_02.jpg';
import defaultFlightImage03 from '@feathr/extender/images/flight_default_03.jpg';
import defaultFlightImage04 from '@feathr/extender/images/flight_default_04.jpg';
import defaultFlightImage05 from '@feathr/extender/images/flight_default_05.jpg';
import defaultFlightImage06 from '@feathr/extender/images/flight_default_06.jpg';
import defaultFlightImage07 from '@feathr/extender/images/flight_default_07.jpg';

const defaultImages = [
  defaultFlightImage01,
  defaultFlightImage02,
  defaultFlightImage03,
  defaultFlightImage04,
  defaultFlightImage05,
  defaultFlightImage06,
  defaultFlightImage07,
];

function getPathImageURL(path: IPath): string {
  const sharedImage = path.shared_image;
  if (!sharedImage) {
    return defaultFlightImage07;
  }
  const tokens = sharedImage.split(':');
  if (tokens.length && tokens[0] === 'defaultFlightImage') {
    return defaultImages[+tokens[1]];
  }
  return sharedImage;
}

interface IProps {
  path: IPath;
  choosePath: (path: IPath) => Promise<void>;
}

function Path({ choosePath, path }: IProps): JSX.Element {
  const { t } = useTranslation();
  const [isCollapsed, toggleCollapsed] = useToggle(true);

  async function handleOnClickCreate(): Promise<void> {
    await choosePath(path);
  }

  return (
    <ListItem className={styles.root}>
      <div
        className={styles.pathWrapper}
        style={{ ['--l-background-image' as any]: `url(${getPathImageURL(path)})` }}
      >
        <div className={styles.path}>
          <h3>{path.shared_name || path.name}</h3>
        </div>
        <Button className={styles.actionChoose} onClick={handleOnClickCreate}>
          {t('Create a flight with this path')}
        </Button>
        <CollapseButton
          className={styles.actionDetails}
          collapsed={isCollapsed}
          onClick={toggleCollapsed}
        >
          {isCollapsed ? t('Show details') : t('Hide details')}
        </CollapseButton>
      </div>
      <Collapse isOpened={!isCollapsed}>
        {ObjectId.isValid(path.id) ? (
          <CustomPathPreview path={path as any} />
        ) : (
          <PathPreview path={path as any} />
        )}
      </Collapse>
    </ListItem>
  );
}

export default Path;
