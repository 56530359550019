import {
  faAt,
  faBriefcase,
  faIdCardAlt,
  faPhone,
  faPortrait,
  faTag,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { Fieldset, FormElement, Input, List, ListItem } from '@feathr/components';

import Tags from '../../../components/Tags';

import styles from './PersonPreview.css';

interface IProps {
  person: Person;
  tagIds: string[];
}

function PersonPreview({ person, tagIds }: IProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div>
        <Fieldset>
          <Input
            attribute={'external_id'}
            disabled={true}
            helpText={
              'An External ID is a unique identifier for a person record. This ID will be used to find the person for updates and deduplication.'
            }
            key={'external_id'}
            label={
              <>
                <FontAwesomeIcon icon={faIdCardAlt} /> {t('External ID')}
              </>
            }
            model={person}
            type={'text'}
          />
          <Input
            attribute={'name'}
            disabled={true}
            key={'name'}
            label={
              <>
                <FontAwesomeIcon icon={faPortrait} /> {t('Name')}
              </>
            }
            model={person}
            type={'text'}
          />
          <Input
            attribute={'first_name'}
            disabled={true}
            key={'first_name'}
            label={
              <>
                <FontAwesomeIcon icon={faPortrait} /> {t('First name')}
              </>
            }
            model={person}
            type={'text'}
          />
          <Input
            attribute={'last_name'}
            disabled={true}
            key={'last_name'}
            label={
              <>
                <FontAwesomeIcon icon={faPortrait} /> {t('Last name')}
              </>
            }
            model={person}
            type={'text'}
          />
          <Input
            attribute={'email'}
            disabled={true}
            key={'email'}
            label={
              <>
                <FontAwesomeIcon icon={faAt} /> {t('Primary email')}
              </>
            }
            model={person}
            type={'text'}
          />
          <Input
            attribute={'phone'}
            disabled={true}
            key={'phone'}
            label={
              <>
                <FontAwesomeIcon icon={faPhone} /> {t('Phone number')}
              </>
            }
            model={person}
            type={'text'}
          />
          <Input
            attribute={'occupation'}
            disabled={true}
            key={'occupation'}
            label={
              <>
                <FontAwesomeIcon icon={faBriefcase} /> {t('Title')}
              </>
            }
            model={person}
            type={'text'}
          />
          {tagIds.length !== 0 && (
            <>
              <label>
                <FontAwesomeIcon icon={faTag} /> {t('Tags')}
              </label>
              <Tags className={styles.tags} value={tagIds} />
            </>
          )}
        </Fieldset>
      </div>
      <div>
        <Fieldset>
          <FormElement>
            <Fieldset label={t('Address data')}>
              <Input
                attribute={['address', 'premise1']}
                disabled={true}
                key={'premise1'}
                label={t('Street address line 1')}
                model={person}
                type={'text'}
              />
              <Input
                attribute={['address', 'premise2']}
                disabled={true}
                key={'premise2'}
                label={t('Street address line 2')}
                model={person}
                type={'text'}
              />
              <Input
                attribute={['address', 'locality']}
                disabled={true}
                key={'locality'}
                label={t('City')}
                model={person}
                type={'text'}
              />
              <Input
                attribute={['address', 'administrative_area_name']}
                disabled={true}
                key={'administrative_area_name'}
                label={t('State/Province')}
                model={person}
                type={'text'}
              />
              <Input
                attribute={['address', 'postal_code']}
                disabled={true}
                key={'postal_code'}
                label={t('ZIP/Postal code')}
                model={person}
                type={'text'}
              />
            </Fieldset>
          </FormElement>
        </Fieldset>
        {Object.keys(person.get('custom')).length !== 0 && (
          <Fieldset label={t('Custom data')}>
            <List>
              {Object.entries(person.get('custom')).map(([key, value]) => (
                <ListItem className={styles.item} key={'tags'}>
                  <label>{key}</label>
                  <span>{value}</span>
                </ListItem>
              ))}
            </List>
          </Fieldset>
        )}
      </div>
    </div>
  );
}

export default observer(PersonPreview);
