import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CountrySelect, Input } from '@feathr/components';
import type { IBaseAttributes } from '@feathr/rachis';
import type { Model } from '@feathr/rachis';

import * as styles from './LegacyAddressInput.css';

interface IProps<T extends Model<IBaseAttributes>> {
  attribute: keyof T['attributesType'];
  isLoading?: boolean;
  model: T;
}

function LegacyAddressInput<T extends Model<IBaseAttributes>>({
  attribute,
  isLoading,
  model,
}: IProps<T>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const isReallyLoading = isLoading || model.isPending;
        return (
          <>
            <Input
              attribute={[attribute as string, 'line1']}
              className={styles.addressOne}
              isLoading={isReallyLoading}
              label={t('Address')}
              model={model}
              type={'text'}
            />
            <Input
              attribute={[attribute as string, 'line2']}
              isLoading={isReallyLoading}
              model={model}
              type={'text'}
            />
            <div className={styles.inline}>
              <Input
                attribute={[attribute as string, 'city']}
                className={styles.city}
                isLoading={isReallyLoading}
                label={t('City')}
                model={model}
                type={'text'}
                wrapperClassName={styles.cityWrapper}
              />
              <Input
                attribute={[attribute as string, 'state']}
                className={styles.state}
                isLoading={isReallyLoading}
                label={t('State')}
                model={model}
                type={'text'}
              />
              <Input
                attribute={[attribute as string, 'postal_code']}
                className={styles.postalCode}
                isLoading={isReallyLoading}
                label={t('Postal code')}
                model={model}
                type={'text'}
              />
            </div>
            <CountrySelect
              attribute={[attribute as string, 'country']}
              isLoading={isReallyLoading}
              label={t('Country')}
              model={model}
              valueType={'name'}
            />
          </>
        );
      }}
    </Observer>
  );
}

export default LegacyAddressInput;
