import type { ReactNode } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import type { IPerson } from '@feathr/blackbox';
import { cssVar } from '@feathr/hooks';

interface IProps {
  person: IPerson;
  localUrl?: (url: string) => string;
}

interface IBoundaryProps {
  children?: ReactNode;
  name: string;
}

class ErrorBoundary extends React.Component<IBoundaryProps> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public state = { hasError: false };

  public render() {
    const { children, name } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return name;
    }
    return children;
  }
}

function PersonName({ person, localUrl }: IProps) {
  const isNotAnonymous = person.name && person.name.trim().length !== 0;
  const placeholder = person.placeholder || {
    name: 'Anonymous',
    color_hex: cssVar('--color-text-body'),
    color_name: 'Gray',
    bird: 'Pigeon',
  };
  const name = isNotAnonymous ? person.name! : placeholder.name || 'Anonymous';
  return (
    <div
      style={{
        fontWeight: isNotAnonymous ? 600 : 400,
        color: cssVar('--color-primary-3'),
      }}
    >
      <ErrorBoundary name={name}>
        {localUrl ? (
          <Link to={localUrl(`/data/people/${person.id}`)}>{name}</Link>
        ) : (
          <span>{name}</span>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default PersonName;
