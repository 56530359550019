import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observable } from 'mobx';
import type { JSX } from 'react';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Account } from '@feathr/blackbox';
import { ELicensePackage, EPackageTiers } from '@feathr/blackbox';
import type { ISelectGroupOptions } from '@feathr/components';
import { Checkbox, DebouncedInput, Input, Select, Table } from '@feathr/components';
import UserSelect from '@feathr/extender/components/UserSelect';
import { StoresContext, useUser } from '@feathr/extender/state';
import { packageTiersToLabelMap } from '@feathr/extender/styles/license';

import Page from '../Page/Page';
import accountsColumns from './accountsColumns';

import * as styles from './AccountsPage.css';

interface IFilters {
  csm?: string;
  name__icontains?: string;
  id__in?: string[];
  is_archived__ne: boolean;
  license__packages__name__in?: ELicensePackage[];
}

function AccountsPage(): JSX.Element {
  const { Accounts, Users } = useContext(StoresContext);
  const user = useUser();
  const [filters, setFilters] = useState<IFilters>({
    is_archived__ne: true,
  });
  const { t } = useTranslation();

  const favorites = user.getSetting('favorite_account_ids') || observable([] as string[]);

  function handleFavoritesChange(newValue?: boolean): void {
    if (newValue) {
      setFilters({
        ...filters,
        id__in: favorites,
      });
    } else {
      setFilters({
        ...filters,
        id__in: undefined,
      });
    }
  }

  function handleCSMChange(value?: string): void {
    setFilters({ ...filters, csm: value });
  }

  function handleCSMClear(): void {
    setFilters({ ...filters, csm: undefined });
  }

  interface ILicensePackageOption {
    id: ELicensePackage;
    name: string;
  }

  interface ILicensePackageGroupOptions extends ISelectGroupOptions {
    options: ILicensePackageOption[];
  }

  const tierOptions: ILicensePackageGroupOptions[] = useMemo(
    () => [
      {
        label: t('Legacy Packages'),
        options: [
          { id: ELicensePackage.Finch, name: packageTiersToLabelMap(t, EPackageTiers.Finch) },
          { id: ELicensePackage.Falcon, name: packageTiersToLabelMap(t, EPackageTiers.Falcon) },
          {
            id: ELicensePackage.EnterpriseFalcon,
            name: packageTiersToLabelMap(t, EPackageTiers.EnterpriseFalcon),
          },
          { id: ELicensePackage.Phoenix, name: packageTiersToLabelMap(t, EPackageTiers.Phoenix) },
        ],
      },
      {
        label: t('Platform Packages'),
        options: [
          {
            id: ELicensePackage.FeathrPlatformBase,
            name: packageTiersToLabelMap(t, EPackageTiers.Base),
          },
          {
            id: ELicensePackage.FeathrPlatformEssential,
            name: packageTiersToLabelMap(t, EPackageTiers.Essential),
          },
          {
            id: ELicensePackage.FeathrPlatformAdvanced,
            name: packageTiersToLabelMap(t, EPackageTiers.Advanced),
          },
          {
            id: ELicensePackage.FeathrPlatformEnterprise,
            name: packageTiersToLabelMap(t, EPackageTiers.Enterprise),
          },
        ],
      },
    ],
    [t],
  );

  function handleTierChange(option: ILicensePackageOption): void {
    function parseLicensePackage(): ELicensePackage[] | undefined {
      const licensePackage = option?.id;

      if (!licensePackage) {
        return undefined;
      }

      switch (licensePackage) {
        case ELicensePackage.Falcon:
          return [
            ELicensePackage.Falcon,
            ELicensePackage.FalconLicenseExtended,
            ELicensePackage.EnterpriseFalcon,
            ELicensePackage.FalconLicense,
          ];

        case ELicensePackage.EnterpriseFalcon:
          return [
            ELicensePackage.EnterpriseFalcon,
            ELicensePackage.EnterpriseFalconSeat,
            ELicensePackage.EnterpriseFalconUnlimitedSeats,
            ELicensePackage.EnterpriseFlamingo,
          ];

        case ELicensePackage.Finch:
          return [
            ELicensePackage.Finch,
            ELicensePackage.FinchLicenseExtended,
            ELicensePackage.FinchLegacy,
            ELicensePackage.FinchLicense,
            ELicensePackage.FinchLegacyLicense,
          ];

        case ELicensePackage.Phoenix:
          return [
            ELicensePackage.Phoenix,
            ELicensePackage.PhoenixLicenseExtended,
            ELicensePackage.PhoenixLicense,
          ];

        case ELicensePackage.FeathrPlatformEssential:
          return [
            ELicensePackage.FeathrPlatformEssential,
            ELicensePackage.FeathrPlatformEssentialPro,
          ];

        case ELicensePackage.FeathrPlatformAdvanced:
          return [
            ELicensePackage.FeathrPlatformAdvanced,
            ELicensePackage.FeathrPlatformAdvancedPro,
          ];

        case ELicensePackage.FeathrPlatformEnterprise:
          return [
            ELicensePackage.FeathrPlatformEnterprise,
            ELicensePackage.FeathrPlatformEnterprisePro,
          ];

        default:
          return [licensePackage];
      }
    }

    setFilters({
      ...filters,
      license__packages__name__in: parseLicensePackage(),
    });
  }

  function handleTierClear(): void {
    setFilters({
      ...filters,
      license__packages__name__in: undefined,
    });
  }

  function handleDebouncedSearchChange(newValue?: string): void {
    setFilters({
      ...filters,
      name__icontains: newValue ?? undefined,
    });
  }

  const filterElements = [
    <>
      <Checkbox
        className={styles.filterCheckbox}
        label={t('Favorites')}
        onChange={handleFavoritesChange}
        value={filters.id__in !== undefined}
        wrapperClassName={styles.filterCheckboxWrapper}
      />
      <Select
        className={styles.filter}
        isClearable={true}
        name={'license-package-select'}
        onChange={handleTierChange}
        onClear={handleTierClear}
        options={tierOptions}
        placeholder={t('Select package...')}
      />
      <UserSelect
        filters={{ role: 'csm', is_archived__ne: true, skip_acl: true }}
        isClearable={true}
        onChange={handleCSMChange}
        onClear={handleCSMClear}
        placeholder={t('Select CSM...')}
        queryOptions={{ fetchOptions: { headers: Users.getHeaders(false) } }}
        value={filters.csm || undefined}
      />
    </>,
    <>
      <DebouncedInput<string>
        defaultValue={filters.name__icontains ?? ''}
        onChange={handleDebouncedSearchChange}
      >
        {(liveValue, onChangeLiveValue): JSX.Element => (
          <Input
            className={styles.filter}
            isClearable={true}
            onChange={onChangeLiveValue}
            placeholder={t('Search by account name...')}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
    </>,
  ];

  return (
    <Page title={t('Accounts')}>
      <Table<Account>
        collection={Accounts}
        columns={accountsColumns({ t })}
        filterElements={filterElements}
        filters={filters}
        initialSort={[{ id: 'name' }]}
        noDataText={t('No accounts found.')}
      />
    </Page>
  );
}

export default AccountsPage;
