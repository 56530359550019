import { get as lodashGet } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import type { JSX } from 'react';
import React from 'react';

import type { IDailyStats } from '@feathr/blackbox';
import type { IAreaChartProps, IChartTooltipProps } from '@feathr/components';
import { AreaChart } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import { AudienceAreaChartGroup } from './AudienceAreaChartGroup';

type TAudienceData = IDailyStats & { date: string };

interface IProps
  extends IAreaChartProps<IDailyStats>,
    Pick<IChartTooltipProps, 'descriptor' | 'showValue' | 'operation'> {
  data: IDailyStats[];
  /**
   * Whether or not to format the date. If true, date will be formatted MMMM YYYY, e.g. `January 2020`. Defaults to `false`.
   * If `true`, the data must have a `metadata.date` property.
   */
  isFormatDate?: boolean;
  /** The size of the chart */
  size?: 'small' | 'medium' | 'large';
  /** Whether or not to backfill the data with default values */
  backfillData?: boolean;
}

// Format `metadata.date` to MMMM YYYY and add it to the data object as `date: 'June 2024'`
export function formatDate(data: IProps['data']): TAudienceData[] {
  if (!data) {
    return [];
  }
  return data.map((stat) => {
    const originalStat = stat;
    const formattedDate = moment
      .utc(originalStat.metadata.date, moment.ISO_8601)
      .format(TimeFormat.monthYear);
    return {
      // Spread the original stat object
      ...originalStat,
      // Override or add the date property with the formatted date
      date: formattedDate,
    };
  });
}

export function calculateChartTitle(
  stats: IDailyStats[],
  attribute: string,
  operation: IChartTooltipProps['operation'],
): string {
  if (operation === 'average' || operation === 'percentage') {
    const average =
      stats.reduce((acc, stat) => acc + (Number(lodashGet(stat, attribute, 0)) || 0), 0) /
      stats.length;
    return average.toLocaleString();
  }
  // operation === 'sum'
  const sum = stats.reduce((acc, stat) => acc + (Number(lodashGet(stat, attribute, 0)) || 0), 0);
  return sum.toLocaleString();
}

export function getChartAreaTitle(
  stats: IDailyStats[],
  attribute: string,
  operation: IChartTooltipProps['operation'],
): string | undefined {
  if (!stats || stats.length <= 0) {
    return undefined;
  }
  if (stats.length > 0) {
    return calculateChartTitle(stats, attribute, operation);
  }
  return undefined;
}

function AudienceAreaChart({
  backfillData = false,
  data,
  descriptor = 'people',
  isFormatDate = true,
  showValue = false,
  size = 'medium',
  title,
  xAxisKey,
  yAxisKey,
  operation = 'sum',
  ...props
}: Readonly<IProps>): JSX.Element {
  const determinedXAxisKey = isFormatDate ? 'date' : xAxisKey;

  function checkDataForYAxisKey(
    data: IDailyStats[],
    key: string,
    defaultValue: number = 0,
  ): IDailyStats[] {
    return data.map((item) => ({
      ...item,
      [key]: lodashGet(item, key, defaultValue),
    }));
  }

  // Get the unique ticks for the x-axis
  function getTicks(formattedStats?: TAudienceData[]): string[] {
    if (!formattedStats || formattedStats.length <= 0) {
      return [];
    }

    return [...new Set(formattedStats.map(({ date }) => date))];
  }

  if (isFormatDate) {
    data = formatDate(data);
  }

  const backfilledData = checkDataForYAxisKey(data, yAxisKey);
  const chartAreaTitle = getChartAreaTitle(data, yAxisKey, operation);
  const ticks = isFormatDate ? getTicks(data as TAudienceData[]) : undefined;
  return (
    <AreaChart<IDailyStats>
      {...props}
      data={backfillData ? backfilledData : data}
      descriptor={descriptor}
      operation={operation}
      showValue={showValue}
      size={size}
      ticks={ticks}
      // If a title is provided, use it. Otherwise, aggregate it.
      title={title ?? chartAreaTitle}
      xAxisKey={determinedXAxisKey}
      yAxisKey={yAxisKey}
    />
  );
}

AudienceAreaChart.Group = AudienceAreaChartGroup;

export default observer(AudienceAreaChart);
