import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import type { Template } from '@feathr/blackbox';
import { Button } from '@feathr/components';
import BannersnackEditor from '@feathr/extender/components/BannersnackEditor';
import MergetagSelect from '@feathr/extender/components/MergetagSelect';
import { useRedirect } from '@feathr/hooks';

import TemplatePreview from '../../TemplatePreview';

import * as styles from '../TemplateEditor.css';

export interface IBannerTemplateEditorProps {
  className?: string;
  template: Template;
}

function BannerTemplateEditor({
  className,
  template,
}: Readonly<IBannerTemplateEditorProps>): JSX.Element {
  const { t } = useTranslation();
  const [status, setStatus] = useState<'editing' | 'previewing'>('editing');
  const location = useLocation();
  const [redirect] = useRedirect();
  const params = new URLSearchParams(location.search);
  const redirectParam = params.get('redirect');

  function onClick(): void {
    if (status === 'editing') {
      setStatus('previewing');
    } else if (status === 'previewing') {
      setStatus('editing');
    }
  }

  const toolbar: ReactNode[] = [
    <>
      <Button className={styles.toolbarButton} id={'preview'} onClick={onClick}>
        {status === 'previewing' ? 'Back to editor' : 'Show preview'}
      </Button>
      <Button
        className={styles.toolbarButton}
        href={'https://help.feathr.co/hc/en-us/articles/360060112794'}
        id={'help'}
        target={'_blank'}
      >
        Help
      </Button>
    </>,
    <MergetagSelect
      disabled={status === 'previewing'}
      key={'merge-tag-select'}
      name={'merge-tag-select'}
      template={template}
      tooltip={
        <>
          <Trans t={t}>
            <p>
              To use merge data in a banner template, click the tag you want to use from this list
              to copy the tag to your clipboard. Then paste the tag into the name of the layer you
              want to replace with merge data.
            </p>
          </Trans>
          <Trans t={t}>
            <p>
              <strong>You can only replace image and text layers</strong>. Data used to replace an
              image layer must be in the form of an image URL.
            </p>
          </Trans>
        </>
      }
    />,
    !!redirectParam && (
      <>
        <div className={styles.divider} />
        <Button onClick={redirect} type={'primary'}>
          Return to campaign
        </Button>
      </>
    ),
  ];

  return (
    <BannersnackEditor
      className={classNames({
        [styles.editorHidden]: status === 'previewing',
      })}
      model={template}
      toolbar={toolbar}
      wrapperClassName={className}
    >
      {status === 'previewing' && (
        <div className={styles.preview}>
          <TemplatePreview template={template} visible={true} />
        </div>
      )}
    </BannersnackEditor>
  );
}

export default observer(BannerTemplateEditor);
