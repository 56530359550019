import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IOpportunityLineItem } from '@feathr/blackbox';
import { CardV2 as Card, TimeRange, Value } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import * as styles from './PackageDetailsCard.css';

interface IServiceDetailsCardProps {
  name: IOpportunityLineItem['name'];
  opp_id: IOpportunityLineItem['opp_id'];
  period: IOpportunityLineItem['period'];
}

function PackageDetailsCard({
  name,
  opp_id: oppId,
  period,
}: Readonly<IServiceDetailsCardProps>): JSX.Element {
  const { t } = useTranslation();
  const { start, end } = period;
  return (
    <Card>
      <Card.Content addVerticalGap={true}>
        <Value className={styles.value} label={t('Package')} value={name} />
        <Value className={styles.value} label={t('Opportunity ID')} value={oppId} />
        <Value
          className={styles.value}
          label={t('License period')}
          value={<TimeRange end={end} format={TimeFormat.pickerDateTime} start={start} />}
        />
      </Card.Content>
    </Card>
  );
}

export default PackageDetailsCard;
