import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { IMergeField, Template } from '@feathr/blackbox';
import { CardContent, CardHeader, CardV2, Fieldset, Input } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

interface IProps {
  disabled?: boolean;
  template: Template;
}

function TemplateMergefieldDefaults({ disabled = false, template }: IProps): JSX.Element {
  const { CustomFields } = useContext(StoresContext);
  const { t } = useTranslation();
  const customFields = CustomFields.list({
    filters: { collection__in: ['Person', 'Partner'] },
    pagination: {
      page: 0,
      page_size: 1000,
    },
  });
  const usedMergefields = template.get('used_mergefields', []);
  const mergefields = template.getMergefields(customFields.models);

  function getHandleChange(def: IMergeField) {
    return (newValue?: string) => {
      const current = template.get('mergefield_defaults', { User: { UserAttributes: {} } }).User
        .UserAttributes;
      template.set({
        mergefield_defaults: {
          User: {
            UserAttributes: {
              ...current,
              [def.defaultPath!]: newValue ?? '',
            },
          },
        },
      });
    };
  }

  return (
    <CardV2>
      <CardHeader
        description={t(
          'These values will be used to replace merge tags when the recipient does not have data for the corresponding field.',
        )}
        title={t('Default Merge Data')}
      />
      <CardContent>
        <Fieldset>
          {usedMergefields.map((value) => {
            const def = mergefields.find((mf) => mf.value === value);
            if (!def || def.value === '{{Attributes.Email}}') {
              return undefined;
            }
            const currentDefault =
              template.get('mergefield_defaults').User.UserAttributes[def.defaultPath!];
            const inputValue = currentDefault && currentDefault.length ? currentDefault : '';
            return (
              <Input
                disabled={disabled}
                key={value}
                label={def.name}
                onChange={getHandleChange(def)}
                type={'text'}
                value={inputValue}
              />
            );
          })}
        </Fieldset>
      </CardContent>
    </CardV2>
  );
}

export default observer(TemplateMergefieldDefaults);
