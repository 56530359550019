import type { IBaseAttributes } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

export interface IContactList extends IBaseAttributes {
  list_file: string;
  name: string;
  share_with_event: boolean;
  state: string;
  stats: {
    num_rows: number;
    num_error_rows: number;
    errors: string[];
  };
}

export class ContactList extends Model<IContactList> {
  public readonly className = 'ContactList';

  public constraints = {
    list_file: {
      presence: {
        allowEmpty: false,
      },
    },
  };

  public getDefaults(): Partial<IContactList> {
    return {
      list_file: '',
      stats: {
        num_rows: 0,
        num_error_rows: 0,
        errors: [],
      },
    };
  }
}

export class ContactLists extends Collection<ContactList> {
  public getModel(attributes: Partial<IContactList>) {
    return new ContactList(attributes);
  }

  public getClassName() {
    return 'contact_lists';
  }
}
