/*
 * Use enum, which is compiled into an object at runtime, so we can iterate
 * over its keys.
 */
export enum EFlags {
  'altText',
  'anonymous',
  'csmManager',
  'editableCreatives',
  'eventSettingsPage',
  'facebookV2',
  'flightPaths',
  'flightPathsLightSwitch',
  'GDPR_ipDisplay',
  'geofenceRetargeting',
  'hasBlackbaudRaisersEdge',
  'hasFBEmailMapping',
  'hasGoogleAds',
  'hasImis',
  'hasMultiConversions',
  'hidePartnerLeads',
  'historicalGeofence',
  'locales',
  'manualLookalikes',
  'minBudget',
  'monetization',
  'newCampaignWizard',
  'newPartnerDashboard',
  'noMaxBudget',
  'pinpoint',
  'pinpointSendAllEmails',
  'referralCampaigns',
  'shareTemplate',
  'showDataRequests',
  'showDripCampaigns',
  'showFlights',
  'showPersonDetailV2',
  'showTranslateOnDashboard',
  'showUsageMetrics',
  'showUsersAndRolesV2',
  'stopClickFraud',
  'trackedLinkCampaign',
  'userPaymentMethod',
  'zapier',
  'showConversionsSettingsV2',
}

export type TFlags = keyof typeof EFlags;

export type TFlagsRecord = Partial<Record<TFlags, boolean>>;
