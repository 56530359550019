import type { IObservableArray } from 'mobx';
import type { JSX } from 'react';
import React from 'react';

import type { EmailBaseCampaign, Goal } from '@feathr/blackbox';
import { Layout } from '@feathr/components';

import StepGoals from '../../StepGoals';

interface IGoalsStepProps {
  campaign: EmailBaseCampaign;
  goals: IObservableArray<Goal>;
}

function GoalsStep({ campaign, goals }: Readonly<IGoalsStepProps>): JSX.Element {
  return (
    <Layout>
      <StepGoals campaign={campaign} goals={goals} />
    </Layout>
  );
}

export default GoalsStep;
