import { observer } from 'mobx-react-lite';
import React from 'react';

import type { Flight } from '@feathr/blackbox';
import { Card, Checkbox, Input, Textarea } from '@feathr/components';

import FlightPathImagePicker from './FlightPathImagePicker';

import * as styles from './FlightPathSettings.css';

interface IProps {
  flight: Flight;
  title?: string;
}

function FlightPathSettings({ flight, title }: IProps) {
  return (
    <Card contentClassName={styles.root} title={title}>
      <Checkbox
        attribute={'shared'}
        helpText={
          'Making this Flight configuration into a Custom Flight Path will make it visible in the Flight Paths list for other Projects in your Account. This will allow you to re-use this Flight in other Projects, or as an additional Flight in this Project.'
        }
        label={'Flight Path'}
        model={flight}
      />
      {flight.get('shared') && (
        <>
          <Input
            attribute={'shared_name'}
            helpPlacement={'bottom'}
            helpText={
              "Leave this blank to use the Flight's current name as the name that will appear in the Flight Paths list."
            }
            label={'Shared Name'}
            model={flight}
            placeholder={flight.get('name')}
            type={'text'}
          />
          <Textarea
            attribute={'shared_description'}
            helpPlacement={'bottom'}
            helpText={
              "Whatever you write here will be available in the Flight Paths list entry for this Flight Path in the 'Learn More' button"
            }
            label={'Description'}
            model={flight}
            placeholder={'Description'}
          />
          <FlightPathImagePicker flight={flight} />
        </>
      )}
    </Card>
  );
}

export default observer(FlightPathSettings);
