import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Partner } from '@feathr/blackbox';
import { Button, TLDR } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

import noImg from '@feathr/extender/images/no-img.png';

interface IProps {
  readonly isLinked?: boolean;
  readonly partner?: Partner;
}

function PartnerTLDR({ isLinked = true, partner }: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  if (partner === undefined || partner.isPending) {
    return <TLDR isLoading={true} />;
  }

  return (
    <TLDR
      action={
        isLinked ? <Button href={localUrl(partner.getItemUrl())}>{t('View')}</Button> : undefined
      }
      image={partner.get('logo') || noImg}
      isLoading={false}
      name={'partner_tldr'}
      title={partner.get('name')}
    />
  );
}

export default observer(PartnerTLDR);
