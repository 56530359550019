import type { IObservableArray } from 'mobx';
import { observable, reaction, toJS, when } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';

import type { IImportColumn } from '@feathr/blackbox';
import type { Importer } from '@feathr/blackbox';
import { Button, ButtonValid, Form } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';
import { validate } from '@feathr/rachis';

import PartnerImportColumnConfig from './PartnerImportColumnConfig';

interface IProps {
  onPrev: () => void;
  onNext: () => void;
  importer: Importer;
}

interface IButtonProps {
  onNext: () => void;
  importer: Importer;
}

export const validateStepTwo = (importer: Importer) => {
  function validateColumn(column: IImportColumn) {
    if (!column.feathr_attr) {
      return `"${column.column_name}" must map to a partner field.`;
    }
    if (!column.attr_type) {
      return `Field for "${column.column_name}" needs a data type.`;
    }
    return undefined;
  }

  const mappers: IImportColumn[] = importer.get('column_mappers');
  const hasName = mappers.some((m) => m.feathr_attr === 'name');
  const hasEmail = mappers.some((m) => m.feathr_attr === 'email');
  const errors = toJS(importer.validate(['column_mappers']).errors);
  if (!hasName) {
    errors.push('Exactly one column must map to the Name field.');
  }
  if (!hasEmail) {
    errors.push('Exactly one column must map to the Email field.');
  }
  return [
    ...errors,
    ...(mappers.map(validateColumn).filter((error?: string) => !!error) as string[]),
  ];
};

export const validateCustomField = (value: string | undefined): string[] | undefined => {
  const constraints = {
    length: {
      maximum: 20,
      tooLong: '^Custom field name must be 20 characters or less.',
    },
  };
  return validate.single(value, constraints);
};

const NextStepButton = observer(({ onNext, importer }: IButtonProps) => {
  const { CustomFields } = useContext(StoresContext);
  const [fieldExists, setFieldExists] = useState<string[]>([]);
  const validationErrors = validateStepTwo(importer);
  const defaults = ['name', 'email', 'external_id', 'website', 'description', 'logo'];
  fieldExists.forEach((entry) => validationErrors.push(entry));
  useEffect(() =>
    reaction(
      () => importer.getEphemeralCustomFields(CustomFields).map((cf) => cf.get('u_key') as string),
      async (fieldNames) => {
        const customFieldErrors = validateCustomField(fieldNames[0]) || [];
        const existingFields = CustomFields.list({
          filters: {
            u_key__in: fieldNames,
            is_archived__ne: true,
            collection: 'Partner',
          },
        });
        await when(() => !existingFields.isPending);
        const builtinFieldErrs = fieldNames
          .filter((fn) => defaults.includes(fn?.toLowerCase()))
          .map((fn) => `"${fn}" is a built in partner field. Use the built in field instead.`);
        const existingFieldErrs = existingFields.models.map(
          (f) =>
            `"${f.get(
              'u_key',
            )}" is a custom field that already exists. Choose a different name or use the existing field.`,
        );
        setFieldExists([...builtinFieldErrs, ...existingFieldErrs, ...customFieldErrors]);
      },
      { delay: DEFAULT_DEBOUNCE_WAIT },
    ),
  );

  return (
    <ButtonValid errors={validationErrors} onClick={onNext}>
      Next
    </ButtonValid>
  );
});

function PartnerImportStepTwo({ importer, onNext, onPrev }: IProps) {
  const actions = [
    <Button key={'prev'} onClick={onPrev}>
      Previous
    </Button>,
    <Button
      disabled={importer.get('column_names').length === importer.get('column_mappers').length}
      key={'add'}
      onClick={() => {
        const name = (importer.get('column_names') as string[]).filter(
          (columnName) =>
            !(importer.get('column_mappers') as IObservableArray<IImportColumn>)
              .map((column) => column.column_name)
              .includes(columnName),
        )[0];
        const obj = observable({
          column_name: name,
          feathr_attr: undefined,
          attr_type: undefined,
        } as IImportColumn);
        importer.get('column_mappers').push(obj);
      }}
    >
      Add column
    </Button>,
    <NextStepButton importer={importer} key={'next'} onNext={onNext} />,
  ];

  return (
    <Form
      actions={actions}
      description={
        <>
          <p>Map the columns from your spreadsheet to fields in Feathr.</p>
          <p>
            In order for your data to be properly imported into Feathr, each column of your
            spreadsheet must be mapped to a field on each person that is being imported. You can map
            each column to a field that already exists or to a new custom field you create.
          </p>
          <p>
            Note that External ID and Email Address will be used to find existing records. If an
            existing record is found, its content will be overwritten by the import. For this
            reason, these fields should be unique.
          </p>
        </>
      }
      label={'Map'}
    >
      {(importer.get('column_mappers') as IObservableArray<IImportColumn>).map((column) => (
        <PartnerImportColumnConfig column={column} importer={importer} key={column.column_name} />
      ))}
    </Form>
  );
}

export default observer(PartnerImportStepTwo);
