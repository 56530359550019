import type { JSX } from 'react';
import React from 'react';

import type { Campaign } from '@feathr/blackbox';
import ReportActions from '@feathr/extender/components/PageHeaderActions';
import { useReportExport } from '@feathr/extender/hooks';

import CampaignReportSection, { getCampaignReportConfig } from '../../CampaignReportSection';
import ReferralCampaignPage from '../ReferralCampaignPage';

interface IProps {
  campaign: Campaign;
}

function DefaultReferralCampaignPage({ campaign }: Readonly<IProps>): JSX.Element {
  const config = getCampaignReportConfig(campaign);
  const reportExportProps = useReportExport({ model: campaign, config });

  const actions = <ReportActions campaign={campaign} {...reportExportProps} />;

  return (
    <ReferralCampaignPage actions={actions} title={'Report'}>
      <CampaignReportSection campaign={campaign} {...reportExportProps} />
    </ReferralCampaignPage>
  );
}

export default DefaultReferralCampaignPage;
