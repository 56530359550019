import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ReactStripeElements } from 'react-stripe-elements';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
  PostalCodeElement,
} from 'react-stripe-elements';

import type { ICreditCardSource } from '@feathr/blackbox';
import { Checkbox, Form, FormElement } from '@feathr/components';
import { cssVar, useToggle } from '@feathr/hooks';

import * as inputStyles from '@feathr/components/dist/Input/InputElement.css';
import * as styles from './CreditCardInput.css';

interface IProps {
  children: (onSave: () => Promise<ICreditCardSource>, element: JSX.Element) => JSX.Element;
}

type TProps = ReactStripeElements.InjectedStripeProps & Readonly<IProps>;

/**
 * Generates token for a credit card using the provided Stripe object.
 *
 * @param stripe - The Stripe object injected by injectStripe.
 * @param approved - A boolean indicating whether the user has approved the transaction.
 * @returns A promise that resolves to an object containing credit card details.
 * @throws Error if the user has not approved the transaction, if Stripe object is not set, if credit card information is invalid, or if there is an issue adding the card.
 */
async function getToken(
  stripe: ReactStripeElements.InjectedStripeProps['stripe'],
  approved: boolean,
): Promise<ICreditCardSource> {
  if (!approved) {
    throw new Error('You must accept the terms and conditions before continuing.');
  }

  // stripe is added by injectStripe
  if (!stripe) {
    throw new Error('Stripe is not set!');
  }
  // Let errors bubble up to the caller
  const response = await stripe.createToken();

  if (response.error) {
    throw new Error(
      'The information you entered is invalid. Please correct your credit card details and try again.',
    );
  }
  if (!response.token) {
    throw new Error('There was an issue adding your card.');
  }

  const { id } = response.token;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { brand, exp_month, exp_year, last4 } = response.token.card!;
  return {
    brand,
    exp_month,
    exp_year,
    id,
    last4,
    // TODO: Status should be retrieved from response.token.card but requires stripe api to be updated #2795

    // NOTE: Is setting this a problem? Will the value be wrong? Asking @reese
    status: 'chargeable',
  };
}

// The type is not exported by react-stripe-elements, so we are left with inferring the type.
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function createOptions() {
  // TODO: Use global styles for CreditCardInput create option (font, colors, etc)
  return {
    style: {
      base: {
        font: '"Inter", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: '18px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
          content: 'Card Number',
        },
      },
      invalid: {
        color: cssVar('--color-danger'),
      },
    },
  };
}

/*
 * The stripe prop is added by injectStripe
 * Don't wrap TProps in Readonly or typescript will yell due to a type mismatch with what injectStripe expects
 */
function CreditCardInput({ children, stripe }: TProps): JSX.Element {
  const [approved, toggleApproved] = useToggle(false);
  const { t } = useTranslation();

  function onSave(): Promise<ICreditCardSource> {
    // Pass in props.stripe
    return getToken(stripe, approved);
  }

  const element = (
    <Form label={t('Enter credit card details')}>
      <FormElement className={styles.card} label={t('Card number')}>
        <CardNumberElement className={inputStyles.input} {...createOptions()} />
      </FormElement>
      <div className={styles.inline}>
        <FormElement className={styles.expiration} label={t('Exp date')}>
          <CardExpiryElement className={inputStyles.input} {...createOptions()} />
        </FormElement>
        <FormElement className={styles.securityCode} label={t('CVV')}>
          <CardCVCElement className={inputStyles.input} {...createOptions()} />
        </FormElement>
        <FormElement className={styles.postalCode} label={t('Postal code')}>
          <PostalCodeElement className={inputStyles.input} {...createOptions()} />
        </FormElement>
      </div>
      <div className={styles.authorize}>
        <Checkbox
          label={t(
            'I authorize to charge my credit card above for agreed upon purchases. I understand that my information will be saved to file for future transactions on my account. I understand that this authorization will remain in effect until I cancel it in writing, and I agree to notify accounting@feathr.co in writing of any changes in my account information or termination of this authorization at least 15 days prior to the next billing date. If the above noted payment dates fall on a weekend or holiday, I understand that the payments may be executed on the next business day. I acknowledge that the origination of Credit Card transactions to my account must comply with the provisions of U.S. law. I certify that I am an authorized user of this Credit Card and will not dispute these scheduled transactions; so long as the transactions correspond to the terms indicated in this authorization form.',
          )}
          onChange={toggleApproved}
          value={approved}
        />
      </div>
    </Form>
  );

  return <>{children(onSave, element)}</>;
}

export default injectStripe(CreditCardInput);
