import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Template } from '@feathr/blackbox';
import { Chip, FormSummary, FormSummaryItem } from '@feathr/components';
import {
  templateGroupLabel,
  templateGroupTheme,
  templateTypeIcon,
  templateTypeTheme,
} from '@feathr/extender/styles/template';
import { flattenError } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import CampaignChip from '../CampaignChip';

interface IProps {
  template: Template;
  validationErrors?: ITemplateSummaryErrors;
}

interface ITemplateSummaryErrors extends TValidateGrouped {
  name?: string;
}

function TemplateSummary({ template, validationErrors = {} }: IProps): JSX.Element {
  const { t } = useTranslation();

  const type = template.get('_cls');
  const typeIcon = templateTypeIcon(type);
  const typeColor = templateTypeTheme(type);

  const isCampaign = template.get('parent_kind') === 'campaign';

  const group = (function (): JSX.Element {
    const isDefault = !!template.get('default');
    const isAccount = template.get('parent_kind') === 'account';
    const isEvent = template.get('parent_kind') === 'event';
    if (isDefault) {
      return <Chip theme={templateGroupTheme('default')}>{templateGroupLabel('default', t)}</Chip>;
    } else if (isAccount) {
      return <Chip theme={templateGroupTheme('account')}>{templateGroupLabel('account', t)}</Chip>;
    } else if (isEvent) {
      return <Chip theme={templateGroupTheme('project')}>{templateGroupLabel('project', t)}</Chip>;
    } else {
      return (
        <Chip theme={templateGroupTheme('campaign')}>{templateGroupLabel('campaign', t)}</Chip>
      );
    }
  })();

  return (
    <FormSummary>
      <FormSummaryItem
        errors={flattenError(validationErrors.name)}
        label={t('Name')}
        value={template.name}
      />
      <FormSummaryItem
        label={t('Type')}
        value={
          <Chip
            prefix={typeIcon ? <FontAwesomeIcon icon={typeIcon} /> : undefined}
            theme={typeColor}
          >
            {template.getTypeLabel()}
          </Chip>
        }
      />
      <FormSummaryItem label={t('Group')} value={group} />
      {isCampaign ? (
        <FormSummaryItem
          label={t('Campaign')}
          value={<CampaignChip id={template.get('parent')} />}
        />
      ) : undefined}
    </FormSummary>
  );
}

export default observer(TemplateSummary);
