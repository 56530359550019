import capitalize from 'lodash.capitalize';
import React from 'react';

import type { CampaignState as CampaignStateEnum } from '@feathr/blackbox';
import { Chip } from '@feathr/components';
import { campaignStateColorMap } from '@feathr/extender/styles/campaign';

interface IProps {
  state: string;
}

function CampaignState(props: IProps) {
  const backgroundColor = campaignStateColorMap.get(props.state as CampaignStateEnum)!;
  return <Chip theme={backgroundColor}>{capitalize(props.state)}</Chip>;
}

export default CampaignState;
