import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import type { ColumnRenderProps } from 'react-table';

import type { BaseCampaign, IExposureSettings } from '@feathr/blackbox';
import type { Campaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const TargetImpressionsColumn: IColumn<BaseCampaign> = {
  id: 'targetViews',
  checkboxLabel: 'Target Views',
  Header: TableColumnHeader({
    title: 'Target Views',
  }),
  sortable: false,
  width: 120,
  className: tableStyles.cellRight,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const exposureSettings = original.get('exposure_settings');
          if (!exposureSettings) {
            return <>-</>;
          }
          const targetValue = exposureSettings.target_value || 0;
          const targetType = exposureSettings.target_type || 'fixed_budget';
          return (
            <>{targetType === 'fixed_impressions' ? numeral(targetValue).format('0,0') : '-'}</>
          );
        }}
      </Observer>
    );
  },
  Footer({ data }: ColumnRenderProps<{ _original: Campaign }>): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const total = data.reduce((subtotal, currentValue) => {
            const exposureSettings: IExposureSettings =
              currentValue._original.get('exposure_settings');
            if (!exposureSettings) {
              return subtotal;
            }
            const targetValue = exposureSettings.target_value || 0;
            const targetType = exposureSettings.target_type || 'fixed_budget';
            if (targetType === 'fixed_impressions') {
              return subtotal + targetValue;
            }
            return subtotal;
          }, 0);
          return <>{numeral(total).format('0,0')}</>;
        }}
      </Observer>
    );
  },
};

export default TargetImpressionsColumn;
