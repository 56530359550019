import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { DripCampaign } from '@feathr/blackbox';
import { Layout } from '@feathr/components';

import EmailCampaignSegments from '../../EmailCampaignSegments';

interface IExclusionsStepProps {
  campaign: DripCampaign;
}

function ExclusionsStep({ campaign }: Readonly<IExclusionsStepProps>): JSX.Element {
  const { t } = useTranslation();
  return (
    <Layout>
      <EmailCampaignSegments
        campaign={campaign}
        disabled={false}
        hideIncluded={true}
        showImportContacts={false}
        step={2}
        title={t('Group Exclusions')}
      />
    </Layout>
  );
}

export default observer(ExclusionsStep);
