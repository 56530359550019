import { faEye, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { JSX } from 'react';
import React from 'react';

import type { TStageType } from '@feathr/blackbox';
import { Button, Popover, Spinner, Tooltip } from '@feathr/components';

import styles from './ReferralParticipationFunnel.css';

export interface IProps {
  className?: string;
  isLoading?: boolean;
  numParticipants: number;
  onSendMessage?: (stage: TStageType) => Promise<void>;
  stages: Record<TStageType, number>;
  step?: TStageType;
  setStep?: (step?: TStageType) => void;
  title?: React.ReactNode;
  type?: 'primary';
}

interface IStageDetails {
  name: string;
  helpText: string;
}

const stageDetails: Record<TStageType, IStageDetails> = {
  new: {
    name: 'Unmessaged',
    helpText:
      'These partners have not yet received a message through Feathr, relating to this campaign.',
  },
  unread: {
    name: 'Unread',
    helpText:
      'These partners have received but not yet read a message sent through Feathr, relating to this campaign.',
  },
  received: {
    name: 'Received message',
    helpText: 'These partners were sent and have opened a message relating to this campaign.',
  },
  visited: {
    name: 'Visited dashboard',
    helpText: 'These partners have visited their partner dashboard.',
  },
  shared: {
    name: 'Shared collateral',
    helpText: 'These partners have shared their marketing materials and are generating traffic.',
  },
  leads: {
    name: 'Generating leads',
    helpText: 'These partners have generated new leads.',
  },
  completed: {
    name: 'Generating conversions',
    helpText:
      'These partners have shared their marketing materials and are generating conversions.',
  },
};

function ReferralParticipationFunnel({
  className,
  isLoading,
  numParticipants,
  onSendMessage,
  stages,
  step,
  setStep,
  title,
  type,
}: IProps): JSX.Element {
  // Separating stages into two parts to allow for enough horizontal space
  const stagesPartOne = Object.fromEntries(
    ['new', 'unread', 'received', 'visited']
      .filter((key) => key in stages)
      .map((key) => [key, stages[key]]),
  ) as Record<TStageType, number>;
  const stagesPartTwo = Object.fromEntries(
    ['shared', 'leads', 'completed']
      .filter((key) => key in stages)
      .map((key) => [key, stages[key]]),
  ) as Record<TStageType, number>;

  function renderTable(stages): JSX.Element {
    return (
      <table
        className={classNames(
          styles.root,
          {
            [styles.primary]: type === 'primary',
          },
          className,
        )}
        style={{ ['--l-stages' as any]: Object.keys(stages).length }}
      >
        {!!title && <caption>{title}</caption>}
        <thead>
          <tr>
            {Object.keys(stages).map((key) => {
              const { name, helpText } = stageDetails[key as TStageType];
              return (
                <th
                  className={classNames({
                    [styles.active]: step === key,
                    [styles.leads]: key === 'leads',
                    [styles.completed]: key === 'completed',
                    [styles.leadingArrow]: key === 'shared',
                  })}
                  key={key}
                >
                  <span>{name}</span>
                  {type === 'primary' && (
                    <Popover toggle={'onMouseOver'}>
                      <span>
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </span>
                      <>{helpText}</>
                    </Popover>
                  )}
                </th>
              );
            })}
            {Object.keys(stages).includes('visited') && (
              // Add dummy element to support adding a trailing arrow
              <th className={styles.dummyLast}></th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {Object.keys(stages).map((key) => {
              function handleOnClickShow(): void {
                if (setStep) {
                  setStep(step === key ? undefined : (key as TStageType));
                }
              }

              async function handleOnClickSend(): Promise<void> {
                if (onSendMessage) {
                  await onSendMessage(key as TStageType);
                }
              }

              const participantCount = stages[key as TStageType];
              // Don't divide by zero.
              const percent =
                numParticipants > 0 ? Math.round((participantCount / numParticipants) * 100) : 0;
              return (
                <td key={key}>
                  {type === 'primary' && (
                    <>
                      <Tooltip
                        className={styles.buttonWrapper}
                        title={'Show partners in this stage'}
                      >
                        <Button
                          className={classNames(styles.button, {
                            [styles.buttonPressed]: key === step,
                          })}
                          onClick={handleOnClickShow}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                      </Tooltip>
                      {key !== step && (
                        <Tooltip
                          className={styles.buttonWrapper}
                          title={'Send message to partners in this stage'}
                        >
                          <Button className={styles.button} onClick={handleOnClickSend}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                          </Button>
                        </Tooltip>
                      )}
                    </>
                  )}
                  <span className={styles.percent}>
                    {isLoading ? (
                      <Spinner size={12} />
                    ) : (
                      <span className={styles.counter}>
                        {participantCount} ({percent}%)
                      </span>
                    )}
                  </span>
                </td>
              );
            })}
            {Object.keys(stages).includes('visited') && (
              // Add dummy element to support adding a trailing arrow
              <td className={styles.dummyLast}></td>
            )}
          </tr>
        </tbody>
      </table>
    );
  }

  return (
    <>
      {renderTable(stagesPartOne)}
      {renderTable(stagesPartTwo)}
    </>
  );
}

export default ReferralParticipationFunnel;
