import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import type { Campaign, TAttributionModel, TCampaignGroup } from '@feathr/blackbox';
import { Button, Checkbox, Drawer, Input, Label } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';
import { useReactionEffect } from '@feathr/hooks';
import { AttributionModel } from '@feathr/report_components';

import getColumns, { getColumnIds } from './CampaignsColumns/helpers';

import * as styles from './CampaignsColumnsDrawer.css';

interface IProps {
  attributionModel: TAttributionModel;
  columnIds: string[];
  campaignGroup: TCampaignGroup;
  close: () => void;
  items: Campaign[];
  onChangeSettings: (settings: ISettings) => void;
  isOpen: boolean;
}

interface ISettings {
  attributionModel: TAttributionModel;
  columnIds: string[];
}

const additionalColumns = ['duration', 'project', 'options'];

function CampaignsColumnsDrawer({
  campaignGroup,
  close,
  isOpen,
  onChangeSettings,
  items,
  attributionModel,
  columnIds,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const { eventId: projectId } = useParams<{ eventId?: string }>();
  const unclearable = getColumnIds(attributionModel, [
    'name',
    '_cls',
    'state',
    'date_created',
    'date_start',
    'date_end',
    'views',
    'clicks',
  ]);
  const drawerColumns = useLocalObservable(() => ({
    ids: columnIds,
    search: '',
    setSearch(newSearch: string): void {
      runInAction(() => {
        drawerColumns.search = newSearch;
      });
    },
    attributionModel,
  }));

  function setDrawerColumns(newIds: string[]): void {
    runInAction(() => {
      drawerColumns.ids = newIds;
    });
  }

  function setDrawerAttributionModel(newAttributionModel: TAttributionModel): void {
    runInAction(() => {
      drawerColumns.attributionModel = newAttributionModel;
    });
  }

  useReactionEffect(
    () => columnIds,
    (columnIds) => {
      setDrawerColumns(columnIds);
    },
    {},
    [columnIds],
  );

  useReactionEffect(
    () => attributionModel,
    (attributionModel) => {
      setDrawerAttributionModel(attributionModel);
    },
    {},
    [attributionModel],
  );

  const includeProjectColumn = projectId === undefined;

  const columnOptionsMap: Record<TCampaignGroup, string[]> = {
    ads: ['cpm', 'cpc', 'ctr', 'cpa', ...additionalColumns],
    all: getColumns(attributionModel, includeProjectColumn, account)
      .map((column) => column.id!)
      .filter((id) => !unclearable.includes(id)),
    email: [
      'stats__flavors__pinpoint_tracked_email_send',
      'stats__email_open_rate',
      'stats__email_click_through_rate',
      'stats__hard_bounce_rate',
      'stats__num_persons_unsubscribed',
      'stats__flavors__pinpoint_tracked_email_delivered',
      'stats__flavors__pinpoint_tracked_email_hardbounce',
      'stats__num_clicks_new',
      ...additionalColumns,
    ],
    'google-ads': ['project', 'options', ...additionalColumns],
    monetization: [
      'stats__email_open_rate',
      'stats__email_click_through_rate',
      'stats__hard_bounce_rate',
      ...additionalColumns,
    ],
    other: [
      'stats__email_open_rate',
      'stats__email_click_through_rate',
      'stats__hard_bounce_rate',
      ...additionalColumns,
    ],
  };

  const allColumns = getColumns(
    drawerColumns.attributionModel,
    includeProjectColumn,
    account,
  ).filter(({ id }) => columnOptionsMap[campaignGroup].includes(id!));

  function handleChangeAttributionModel(newAttributionModel: TAttributionModel): void {
    const newColumnIds = columnIds.map((cid) => {
      if (cid.includes('roi')) {
        return `stats__conversions__${newAttributionModel}__roi`;
      } else if (cid.includes('conversions')) {
        return `stats__conversions__${newAttributionModel}__num`;
      }
      return cid;
    });
    setDrawerAttributionModel(newAttributionModel);
    setDrawerColumns(newColumnIds);
  }

  function handleChangeColumn(id: string): void {
    if (drawerColumns.ids.includes(id)) {
      setDrawerColumns(drawerColumns.ids.filter((cid) => cid !== id));
    } else {
      setDrawerColumns([...drawerColumns.ids, id]);
    }
  }

  function handleApplyChanges(): void {
    onChangeSettings({
      attributionModel: drawerColumns.attributionModel,
      columnIds: drawerColumns.ids,
    });
    close();
  }

  function handleSelectAll(): void {
    if (drawerColumns.ids.length - unclearable.length === allColumns.length) {
      // Exclude name from being cleared.
      setDrawerColumns(unclearable);
    } else {
      setDrawerColumns([...unclearable, ...allColumns.map(({ id }) => id!)]);
    }
  }

  function handleClose(): void {
    setDrawerColumns(columnIds);
    setDrawerAttributionModel(attributionModel);
    close();
  }

  function handleSearchChange(newSearch?: string): void {
    drawerColumns.setSearch(newSearch ?? '');
  }

  const filteredColumns = allColumns.filter(({ checkboxLabel }) =>
    checkboxLabel?.toString().toLowerCase()?.includes(drawerColumns.search.toLowerCase()),
  );
  const noResults = filteredColumns.length === 0;

  const children = noResults ? (
    <>{t('No matches found for "{{-search}}"', { search: drawerColumns.search })}</>
  ) : (
    filteredColumns.map(({ id, checkboxLabel }) => {
      function handleChange(): void {
        handleChangeColumn(id!);
      }

      return (
        <Checkbox
          id={`checkbox-${id}`}
          key={id}
          label={typeof checkboxLabel === 'string' ? checkboxLabel : checkboxLabel?.(items)}
          name={id}
          onChange={handleChange}
          value={drawerColumns.ids.includes(id!)}
        />
      );
    })
  );
  return (
    <Drawer
      headerSuffix={
        <Input
          isClearable={true}
          onChange={handleSearchChange}
          placeholder={t('Search by name...')}
          prefix={<FontAwesomeIcon icon={faSearch} />}
          type={'text'}
          value={drawerColumns.search}
        />
      }
      isOpen={isOpen}
      name={'configure-columns-drawer'}
      onClose={handleClose}
      title={t('Configure columns')}
    >
      <Drawer.Content addVerticalGap={true}>
        <div className={styles.content}>
          <section>
            <Label>{t('Columns')}</Label>
            <div className={classNames(styles.columns, { [styles.noResults]: noResults })}>
              {children}
            </div>
          </section>
          <section>
            {/* TODO: Only change attribution model if user selects apply. */}
            <AttributionModel
              onChange={handleChangeAttributionModel}
              value={drawerColumns.attributionModel}
            />
          </section>
        </div>
      </Drawer.Content>
      <Drawer.Actions>
        <Button isFullWidth={true} key={'cancel'} onClick={handleSelectAll}>
          {drawerColumns.ids.length - unclearable.length === allColumns.length
            ? t('Unselect all')
            : t('Select all')}
        </Button>
        <Button isFullWidth={true} key={'apply'} onClick={handleApplyChanges} type={'primary'}>
          {t('Apply')}
        </Button>
      </Drawer.Actions>
    </Drawer>
  );
}

export default observer(CampaignsColumnsDrawer);
