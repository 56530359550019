import { faAdd } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  GoogleAdsSmartCampaign,
  IGoogleAdsKeyword,
  IGoogleAdsSuggestedKeywordThemes,
} from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  CardContent,
  CardHeader,
  CardV2 as Card,
  EAlertV2Type,
  Form,
  Label,
  Spinner,
  toast,
} from '@feathr/components';
import type { TValidateGrouped } from '@feathr/rachis';

import KeywordButton from './KeywordButton';
import KeywordsChooser from './KeywordsChooser/KeywordsChooser';

import * as styles from './KeywordsStep.css';

interface IKeywordsStepProps {
  readonly campaign: GoogleAdsSmartCampaign;
}

export function validateKeywordsStep(campaign: GoogleAdsSmartCampaign): TValidateGrouped {
  return campaign.validate(['keyword_themes'], false, 'grouped').errors;
}

const LEARN_MORE_LINK = 'https://support.google.com/google-ads/answer/9263830';
const LEARN_MORE_LINK_NEGATIVE =
  'https://support.google.com/google-ads/answer/10138845?hl=en&ref_topic=9024774&sjid=15918767365236029582-NC';

function KeywordsStep({ campaign }: IKeywordsStepProps): JSX.Element {
  const { t } = useTranslation();
  const [selectedKeywords, setSelectedKeywords] = useState<IGoogleAdsKeyword[]>(
    campaign.get('keyword_themes') ?? [],
  );
  const [suggestedKeywords, setSuggestedKeywords] = useState<IGoogleAdsKeyword[]>([]);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState<boolean>(false);
  const [hasGoogleSuggestions, setHasGoogleSuggestions] = useState<boolean>(false);

  const selectedPositiveKeywords = selectedKeywords.filter((keyword) => !keyword.negative);
  const flattenedPositiveKeywords = selectedPositiveKeywords.map((keyword) => keyword.display_name);
  const selectedNegativeKeywords = selectedKeywords.filter((keyword) => keyword.negative);
  const flattenedNegativeKeywords = selectedNegativeKeywords.map((keyword) => keyword.display_name);

  // Suggest keywords on the first render of the page
  useEffect(() => {
    setIsLoadingSuggestions(true);
    campaign
      .suggest<IGoogleAdsSuggestedKeywordThemes>({
        type: 'keyword',
        businessName: campaign.get('business_name'),
        destinationUrl: campaign.get('destination_url'),
      })
      .then((keywords) => {
        if (keywords.keyword_themes.length) {
          setHasGoogleSuggestions(true);
        }

        // Filter out any selected keywords from the suggested keywords
        const suggestedKeywordThemes = keywords.keyword_themes.filter(
          (suggestedKeyword: IGoogleAdsKeyword): boolean =>
            !flattenedPositiveKeywords.includes(suggestedKeyword.display_name),
        );

        setSuggestedKeywords(suggestedKeywordThemes);
      })
      .catch((error) => {
        toast(t('Something went wrong: {{- error}}', { error }), {
          type: ToastType.ERROR,
        });
      })
      .finally(() => {
        setIsLoadingSuggestions(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSelectKeyword(keyword: IGoogleAdsKeyword): void {
    const newSelectedKeywords = [...selectedKeywords, keyword];
    setSelectedKeywords(newSelectedKeywords);
    campaign.set({ keyword_themes: newSelectedKeywords });

    // Filter out selected keyword from suggested keywords
    updateSuggestedKeywords(keyword);
  }

  // Filter out selected keyword from suggested keywords
  function updateSuggestedKeywords(keyword: IGoogleAdsKeyword): void {
    setSuggestedKeywords(
      suggestedKeywords.filter(
        (suggestedKeyword) => suggestedKeyword.display_name !== keyword.display_name,
      ),
    );
  }

  function handleRemoveKeyword(keyword: IGoogleAdsKeyword, isNegative: boolean): void {
    const newSelectedKeywords = selectedKeywords.filter(
      (selectedKeyword) =>
        selectedKeyword.display_name !== keyword.display_name ||
        selectedKeyword.negative !== isNegative,
    );
    setSelectedKeywords(newSelectedKeywords);
    campaign.set({ keyword_themes: newSelectedKeywords });

    // Add the keyword back to the suggested keywords if it was there to begin with
    if (keyword.suggestion) {
      setSuggestedKeywords([...suggestedKeywords, keyword]);
    }
  }

  function handleAddKeywordTheme(customKeyword: string, isNegative: boolean): void {
    const keyword = { display_name: customKeyword, negative: isNegative, suggestion: false };

    // See if the user submitted keyword is already in the suggested keywords
    const customKeywordAsSuggestion = suggestedKeywords.find(
      (suggestedKeyword) => suggestedKeyword.display_name === customKeyword,
    );

    // Add new user input keyword to selected keyword if not already present
    if (
      isNegative
        ? !flattenedNegativeKeywords.includes(keyword.display_name)
        : !flattenedPositiveKeywords.includes(keyword.display_name)
    ) {
      const keywords = [
        ...selectedKeywords,
        isNegative ? keyword : customKeywordAsSuggestion ?? keyword,
      ];
      setSelectedKeywords(keywords);
      campaign.set({ keyword_themes: keywords });
      // Suggested keywords are only applicable to positive keywords
      if (customKeywordAsSuggestion && !isNegative) {
        updateSuggestedKeywords(customKeywordAsSuggestion);
      }
    }
  }

  const noSuggestedKeywords = hasGoogleSuggestions ? (
    <span>{t('No keywords available')}</span>
  ) : (
    <Alert
      title={t('No suggested keywords from Google available at this time.')}
      type={EAlertV2Type.warning}
    />
  );

  const suggestedKeywordButtons = suggestedKeywords.length
    ? suggestedKeywords.map((keyword) => (
        <KeywordButton
          key={keyword.resource_name ?? keyword.display_name}
          keyword={keyword}
          onClick={handleSelectKeyword}
          suffix={<FontAwesomeIcon icon={faAdd} />}
        />
      ))
    : noSuggestedKeywords;

  return (
    <div className={styles.root}>
      <Form label={t('Keywords')}>
        <Card name={'keywords'}>
          <CardHeader
            description={
              <Trans t={t}>
                Give us a few keyword themes and we'll show your ad for similar searches. For best
                results, limit to 7-10 keyword themes.{' '}
                <a href={LEARN_MORE_LINK} target={'_blank'}>
                  Learn more
                </a>
              </Trans>
            }
            title={t('Keyword Themes')}
          />
          <CardContent addVerticalGap={true}>
            <div>
              <Label>{t('Suggested keyword themes')}</Label>
              <div data-name={'suggestedKeywords'}>
                {isLoadingSuggestions ? <Spinner /> : suggestedKeywordButtons}
              </div>
            </div>
            <KeywordsChooser
              campaign={campaign}
              isNegative={false}
              label={t('Selected keyword themes')}
              name={'selectedKeywords'}
              onAdd={handleAddKeywordTheme}
              onRemove={handleRemoveKeyword}
              selectedKeywords={selectedKeywords}
            />
          </CardContent>
        </Card>
        <Card name={'negative-keywords'}>
          <CardHeader
            description={
              <Trans t={t}>
                Your ads don't show when people search for negative keywords.{' '}
                <a href={LEARN_MORE_LINK_NEGATIVE} target={'_blank'}>
                  Learn more
                </a>
              </Trans>
            }
            title={t('Negative Keywords')}
          />
          <CardContent addVerticalGap={true}>
            <KeywordsChooser
              campaign={campaign}
              isNegative={true}
              label={t('Negative keywords')}
              name={'selectedNegativeKeywords'}
              onAdd={handleAddKeywordTheme}
              onRemove={handleRemoveKeyword}
              placeholder={t('Keyword')}
              selectedKeywords={selectedKeywords}
            />
          </CardContent>
        </Card>
      </Form>
    </div>
  );
}

export default observer(KeywordsStep);
