import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Fieldset, Label } from '@feathr/components';
import PartnerTLDR from '@feathr/extender/components/BreadcrumbDetails/PartnerTLDR';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './AddCampaignModalPartnerTLDR.css';

export interface IAddCampaignModalPartnerTLDRProps {
  value: string;
}

function AddCampaignModalPartnerTLDR({ value }: IAddCampaignModalPartnerTLDRProps): JSX.Element {
  const { Partners } = useContext(StoresContext);
  const { t } = useTranslation();

  const partner = Partners.get(value);

  return (
    <Fieldset className={styles.root}>
      <div className={styles.tldr}>
        <Label>{t('Partner')}</Label>
        <PartnerTLDR isLinked={false} partner={partner} />
      </div>
    </Fieldset>
  );
}

export default observer(AddCampaignModalPartnerTLDR);
