import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ConfirmModalV1 } from '@feathr/components';
import { useToggle } from '@feathr/hooks';

interface IDisconnectButtonProps {
  readonly onClick: () => Promise<void>;
}

function DisconnectButton({ onClick }: IDisconnectButtonProps): JSX.Element | null {
  const { t } = useTranslation();
  const [showDisconnectModal, toggleDisconnectModal] = useToggle(false);

  function handleDisconnect(): void {
    onClick();
    toggleDisconnectModal();
  }

  return (
    <>
      <Button name={'disconnect_button'} onClick={toggleDisconnectModal}>
        {t('Disconnect')}
      </Button>
      {showDisconnectModal && (
        <ConfirmModalV1
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Yes')}
          description={t('Are you sure you want to disconnect your Google Ad Grants account?')}
          onClose={toggleDisconnectModal}
          onConfirm={handleDisconnect}
          t={t}
          title={t('Disconnect account?')}
        />
      )}
    </>
  );
}

export default observer(DisconnectButton);
