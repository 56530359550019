import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { ITradeDeskCategory } from '@feathr/blackbox';
import { Select, toast } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';

import type { IIndustryCategory } from '../ProfilePage';

import * as styles from './IndustryCategorySelect.css';

interface IProps {
  industryCategory?: IIndustryCategory;
  industrySubCategory?: IIndustryCategory;
  handleCategoryChange: Dispatch<SetStateAction<IIndustryCategory | undefined>>;
  handleSubcategoryChange: Dispatch<SetStateAction<IIndustryCategory | undefined>>;
}

function IndustryCategorySelect({
  industryCategory,
  industrySubCategory,
  handleCategoryChange,
  handleSubcategoryChange,
}: Readonly<IProps>): JSX.Element {
  const account = useAccount();
  const ttd = account.get('ttd');
  const { t } = useTranslation();
  const [industryCategories, setIndustryCategories] = useState<IIndustryCategory[]>([]);
  const [industrySubCategories, setIndustrySubCategories] = useState<IIndustryCategory[]>([]);

  useEffect(() => {
    // Fetch and set initial industry categories
    if (!industryCategories.length) {
      (async function fetchIndustryCategories(): Promise<void> {
        try {
          const categories = await account.getIndustryCategories();
          const industryCategories = formatTradeDeskCategories(categories.data);
          setIndustryCategories(industryCategories);
        } catch (error) {
          toast(t('There was an error fetching industry categories:\n{{- error}}', { error }), {
            type: ToastType.ERROR,
          });
        }
      })();
    }
    // if we have a ttd advertiser category id, set the industry category and subcategory
    if (!industryCategory && industryCategories.length && ttd?.advertiser_category_id) {
      const { parentCategory, subCategory } = findCategory(ttd.advertiser_category_id);
      handleCategoryChange(parentCategory);
      handleSubcategoryChange(subCategory);
    }
  });

  useEffect(() => {
    // Update the sub categories if the industry category changes
    if (industryCategory?.children?.length) {
      setIndustrySubCategories((prevState) =>
        industryCategory?.id !== prevState[0]?.parentCategoryId
          ? industryCategory?.children || []
          : prevState,
      );
    }
  }, [industryCategory, industrySubCategories]);

  function findCategory(categoryId: number): {
    parentCategory?: IIndustryCategory;
    subCategory?: IIndustryCategory;
  } {
    const parentCategory = industryCategories.find(({ id }) => id === categoryId);

    if (parentCategory) {
      return {
        parentCategory,
        subCategory: undefined,
      };
    }
    const industrySubCategories = mapIndustrySubcategories(industryCategories);
    const subCategory = industrySubCategories.find(({ id }) => id === categoryId);
    const subCategoryParent = industryCategories.find(
      ({ id }) => id === subCategory?.parentCategoryId,
    );

    return {
      parentCategory: subCategoryParent,
      subCategory: subCategory,
    };
  }

  function formatTradeDeskCategories(categories: ITradeDeskCategory[]): IIndustryCategory[] {
    return categories.map(
      ({ CategoryId, Children, IsSensitive, Name, ParentCategoryId }: ITradeDeskCategory) => ({
        children: Children.length ? formatTradeDeskCategories(Children) : [],
        id: CategoryId,
        name: Name,
        parentCategoryId: ParentCategoryId ?? null,
        isSensitive: IsSensitive,
      }),
    );
  }

  function mapIndustrySubcategories(industryCategories: IIndustryCategory[]): IIndustryCategory[] {
    return industryCategories.flatMap(({ children }) => (children?.length ? children : []));
  }

  function handleIndustryCategoryChange(category: IIndustryCategory): void {
    handleCategoryChange(category);
    // why do we set to undefined here?
    handleSubcategoryChange(undefined);
  }

  const validateSubCategorySelect =
    industryCategory?.isSensitive && !industrySubCategory?.id
      ? t('Please select an industry subcategory.')
      : undefined;

  return (
    <>
      <Select<IIndustryCategory>
        helpText={t('Select the industry category that best describes your organization.')}
        label={t('Industry category')}
        name={'industry-category-select'}
        onSelectSingle={handleIndustryCategoryChange}
        options={industryCategories}
        placeholder={t('Select your industry category...')}
        value={industryCategory}
        wrapperClassName={styles.select}
      />
      <Select<IIndustryCategory>
        disabled={!industryCategory?.children?.length}
        helpText={t('Select the industry sub-category that best describes your organization.')}
        isClearable={true}
        label={t('Industry sub-category')}
        name={'industry-sub-category-select'}
        onChange={handleSubcategoryChange}
        options={industrySubCategories}
        placeholder={t('Select your industry sub-category...')}
        validationError={validateSubCategorySelect}
        value={industrySubCategory}
        wrapperClassName={styles.select}
      />
    </>
  );
}

export default observer(IndustryCategorySelect);
