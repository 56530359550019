import type { JSX, ReactNode } from 'react';
import React from 'react';

import { Alert, AlertType } from '@feathr/components';

import InputListItem from './InputListItem';

interface IInputListProps {
  actions?: React.ReactNode;
  emptyMessage: string;
  items: string[];
  onChange: (index: number, value: string) => void;
  onRemove: (index: number) => void;
  removeTooltip?: ReactNode;
}

function InputList({
  actions,
  emptyMessage,
  items,
  onChange,
  onRemove,
  removeTooltip,
}: IInputListProps): JSX.Element {
  const hasItems = !!items.length;
  return (
    <>
      {!hasItems && <Alert type={AlertType.warning}>{emptyMessage}</Alert>}
      {hasItems &&
        items.map((item, index) => (
          <InputListItem
            index={index}
            item={item}
            key={index}
            onChange={onChange}
            onRemove={onRemove}
            removeTooltip={removeTooltip}
          />
        ))}
      {actions}
    </>
  );
}

export default InputList;
