import capitalize from 'lodash.capitalize';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';

import type { Campaign, IPinpointPartnerMessage, PinpointPartnerMessage } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { Chip, FormSummary, FormSummaryItem, Skeleton, Time } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { campaignStateColorMap } from '@feathr/extender/styles/campaign';
import { flattenError, TimeFormat } from '@feathr/hooks';

import type { IEventPartnersMessageErrors } from '../EventPartnersMessageStepSeven';

import * as styles from './PartnerMessageSummary.css';

interface IProps {
  message: PinpointPartnerMessage;
  validationErrors?: IEventPartnersMessageErrors;
}

function getPartnerFilters(message: PinpointPartnerMessage) {
  const { mode } = message.get('participation');
  if (mode === 'event') {
    return {
      _parent: message.get('event'),
    };
  }
  if (mode === 'campaign') {
    return {
      participation: message.get('invites_campaign'),
    };
  }
  return {
    id__in: message.get('participation').partner_ids.slice(0, 10),
  };
}

function PartnerMessageSummary({ message, validationErrors = {} }: IProps) {
  const { Campaigns, Partners, Templates } = useContext(StoresContext);

  const campaignId: string | undefined = message.get('invites_campaign');
  const campaign: Campaign | undefined = campaignId ? Campaigns.get(campaignId) : undefined;

  const participation: IPinpointPartnerMessage['participation'] = message.get('participation');
  const templateId = message.get('template_id');
  const template = Templates.get(templateId);

  const listOptions = {
    filters: getPartnerFilters(message),
    pagination: {
      page_size: 10,
    },
  };
  const partners = Partners.list(listOptions);
  const state: CampaignState = message.get('state', CampaignState.Draft);
  const count =
    participation.mode === 'manual' ? participation.partner_ids.length : partners.pagination.count;
  const sNone = '(None)';

  return (
    <FormSummary>
      <FormSummaryItem
        errors={flattenError(validationErrors.name)}
        label={'Name'}
        value={message.get('name') || sNone}
      />
      <FormSummaryItem
        errors={flattenError(validationErrors.campaign)}
        label={'Campaign'}
        value={campaign ? campaign.name || sNone : sNone}
      />
      <FormSummaryItem
        errors={flattenError(validationErrors.participation)}
        label={'Recipients'}
        value={
          <>
            {partners.models
              // Cannot sort observable in place, so make a copy using slice().
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((partner, i) => (
                <Fragment key={partner.id}>
                  {partner.isPending ? (
                    <Skeleton width={100 + Math.random() * 60} />
                  ) : (
                    `${partner.name} <${partner.get('email')}>`
                  )}
                  {i < partners.models.length - 1 && <br />}
                </Fragment>
              ))}
            {count > 10 ? ` and ${count - 10} more.` : ''}
          </>
        }
      />
      <FormSummaryItem
        errors={flattenError(validationErrors.from_address)}
        label={'From address'}
        value={message.get('from_address') || sNone}
      />
      <FormSummaryItem
        errors={flattenError(validationErrors.from_name)}
        label={'From name'}
        value={message.get('from_name') || sNone}
      />
      <FormSummaryItem
        errors={flattenError(validationErrors.subject)}
        label={'Subject'}
        value={template.get('subject') || sNone}
      />
      <FormSummaryItem
        errors={flattenError(validationErrors.template)}
        label={'Template'}
        value={template.get('name') || sNone}
      />
      <FormSummaryItem
        errors={flattenError(validationErrors.date_send_start)}
        label={'Send time'}
        value={
          <>
            <Time
              format={TimeFormat.shortDateTimeZone}
              timestamp={message.get('date_send_start')}
            />{' '}
            <em>
              (
              <Time
                format={TimeFormat.shortDateTimeZone}
                formatLocal={true}
                timestamp={message.get('date_send_start')}
              />
              )
            </em>
          </>
        }
      />
      <FormSummaryItem
        className={styles.chip}
        label={'Status'}
        value={<Chip theme={campaignStateColorMap.get(state)}>{capitalize(state)}</Chip>}
      />
    </FormSummary>
  );
}

export default observer(PartnerMessageSummary);
