import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import { StatBox } from '@feathr/components';

import type { IStatProps } from './Stats';

function NewUsersStat({
  label = 'Net New Audience',
  tooltip = 'The combined audience from all audience expansion campaigns in the Flight.',
  value,
}: IStatProps): JSX.Element {
  const formatted = numeral(value).format('0,0');

  return <StatBox label={label} primary={formatted} tooltip={tooltip} />;
}

export default observer(NewUsersStat);
