import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { components } from 'react-select';

import { FieldDataType } from '@feathr/blackbox';
import { fieldDataTypeIcon } from '@feathr/extender/styles/fieldDataType';

export function FieldSingleValue(props: any) {
  const valueToFieldDataType = (value: string | number | boolean | Date | undefined) => {
    if (typeof value === 'string') {
      return FieldDataType.str;
    }
    if (typeof value === 'number') {
      return FieldDataType.int;
    }
    if (typeof value === 'boolean') {
      return FieldDataType.bool;
    }
    return FieldDataType.str;
  };
  const icon = fieldDataTypeIcon(
    props.data.data_type ? props.data.data_type : valueToFieldDataType(props.data.value),
  );
  return (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
          {icon && <FontAwesomeIcon icon={icon} />}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{props.data.key || props.data.name || props.data.label || props.data.u_key}</span>
        </div>
      </div>
    </components.SingleValue>
  );
}
