import { faExternalLink, faPencil, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import capitalize from 'lodash.capitalize';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, DisplayCreative } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { Button, Card, Chip, ModalV1, Tooltip } from '@feathr/components';
import CreativeThumbnail from '@feathr/extender/components/CreativeThumbnail';
import { useLocalUrl } from '@feathr/extender/state';
import { moment, useRedirect, useToggle } from '@feathr/hooks';

import CreativeMetadata from '../CreativeMetadata/CreativeMetadata';
import CreativePreview from './CreativePreview';

import * as styles from './Creative.css';

const statusColorMap: Record<string, string> = {
  ['not audited']: 'default',
  ['pending']: 'yellow',
  ['mixed']: 'violet',
  ['approved']: 'green',
  ['rejected']: 'red',
  ['unauditable']: 'orange',
};

const statusLabelMap: Record<string, string> = {
  ['not audited']: 'Not reviewed',
  ['pending']: 'Pending review',
  ['approved']: 'Approved',
  ['rejected']: 'Rejected',
  ['unauditable']: 'Not reviewable',
  ['mixed']: 'Mixed',
};

interface IProps {
  campaign: Campaign;
  creative: DisplayCreative;
  creatives: IObservableArray<DisplayCreative>;
  onRemove: (creative: DisplayCreative) => void;
}

function Creative({ campaign, creative, onRemove }: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const [, setRedirect] = useRedirect();
  const [showPreviewModal, togglePreviewModal] = useToggle(false);
  const { t } = useTranslation();

  const creativeSpec = creative.getSpec();

  const hasDestinationURL =
    campaign.isValid(['destination_url'], false) ||
    (creative.get('destination_url') && creative.isValid(['destination_url'], false));

  let statusLabel;
  const statuses = creative.get('audit_statuses', []);
  const parsedStatuses = [...new Set(statuses.map((status) => status.status))].filter(
    (status) => !!status,
  ) as string[];
  if (parsedStatuses.length > 1) {
    statusLabel = 'mixed';
  } else {
    statusLabel = parsedStatuses[0];
  }

  function getReason(reason: string | undefined): string {
    if (reason) {
      return `(${reason})`;
    } else {
      return '';
    }
  }

  const statusInfo = statuses.length ? (
    <ul className={styles.statuses}>
      {statuses.map(
        (status) =>
          status.status && (
            <li key={status.auditor}>{`${status.auditor}: ${capitalize(status.status)} ${getReason(
              status.reason,
            )}`}</li>
          ),
      )}
    </ul>
  ) : (
    <></>
  );

  const progress = campaign.get('sync_progress');
  const synced = progress ? progress.synced_campaign : false;
  const date_end = moment.utc(campaign.get('date_end'));

  function handleRemove(): void {
    onRemove(creative);
  }

  const ApprovalStatus = (
    <>
      <dt>{t('Approval Status')}</dt>
      <dd>
        {synced && statuses.length ? (
          <Chip
            isLoading={creative.isPending}
            theme={statusColorMap[statusLabel]}
            tooltip={statusInfo}
          >
            {statusLabelMap[statusLabel]}
          </Chip>
        ) : campaign.get('state') === CampaignState.Published ? (
          <Chip theme={'yellow'}>{t('Pending')}</Chip>
        ) : (
          <Chip>{t('N/A')}</Chip>
        )}
      </dd>
    </>
  );

  return (
    <>
      <Card
        actions={[
          <Tooltip
            key={'preview'}
            title={hasDestinationURL ? t('Open Preview') : t('Add Destination URL')}
          >
            <Button disabled={!hasDestinationURL} onClick={togglePreviewModal} type={'naked'}>
              <FontAwesomeIcon icon={faExternalLink} />
            </Button>
          </Tooltip>,
          <Tooltip key={'edit'} title={t('Edit')}>
            <Button
              link={setRedirect(localUrl(creative.getItemUrl('edit/design')), 'step4')}
              type={'naked'}
            >
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          </Tooltip>,
          <Tooltip key={'remove'} title={'Remove'}>
            <Button onClick={handleRemove} type={'naked'}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>,
        ]}
      >
        <div className={styles.creativeInfo}>
          <CreativeThumbnail creative={creative.toJS()} size={200} />
          <dl>
            <dt>{t('Dimensions')}</dt>
            <dd>
              {creative.get('width')}px x {creative.get('height')}px
            </dd>
            <dt>{t('Spec')}</dt>
            <dd>{creativeSpec && creativeSpec.name}</dd>
            {moment.utc().isBefore(date_end) && ApprovalStatus}
          </dl>
        </div>
        <CreativeMetadata
          creative={creative}
          destinationUrlPlaceholder={campaign?.get('destination_url')}
          showStatus={true}
        />
      </Card>
      {showPreviewModal && (
        <ModalV1
          controlled={true}
          onClose={togglePreviewModal}
          size={'xl'}
          t={t}
          title={t('Creative Preview')}
        >
          <CreativePreview campaign={campaign} creative={creative} />
        </ModalV1>
      )}
    </>
  );
}

export default observer(Creative);
