import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { CardV2 as Card } from '@feathr/components';
import MarkerMap from '@feathr/extender/components/MarkerMap';

interface IProps {
  readonly person: Person;
}

function Location({ person }: IProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header
        description={t('Most recent geographic coordinates for this person.')}
        title={t('Location')}
      />
      <Card.Content>
        <MarkerMap person={person} />
      </Card.Content>
    </Card>
  );
}

export default observer(Location);
