import type { TFunction } from 'i18next';
import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import type { RowRenderProps } from 'react-table';
import ReactTable from 'react-table';

import type { CustomField, IBreadcrumbSuggestion, IFetchPersonFieldValue } from '@feathr/blackbox';
import { Session } from '@feathr/blackbox';
import { TableColumnHeader } from '@feathr/components';
import { isWretchError, wretch } from '@feathr/rachis';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IRow extends RowRenderProps {
  original: CustomField;
}

interface IValue {
  value: string | number;
  occurrence: number;
}

interface IValueRow extends RowRenderProps {
  original: IValue;
}

const getHeaders = () => Session.getHeaders();

function ValueColumn(t: TFunction) {
  return {
    id: 'value',
    accessor: 'value',
    Header: TableColumnHeader({
      sortType: 'alpha',
      title: t('Value'),
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    Cell({ original }: IValueRow) {
      if (typeof original.value === 'number') {
        return original.value % 1 === 0
          ? numeral(original.value).format('0,0')
          : numeral(original.value).format('0,0.00');
      } else {
        return original.value.trim() ? original.value : t('(Empty)');
      }
    },
  };
}

function OccurrencesColumn(t: TFunction) {
  return {
    id: 'occurrence',
    accessor: 'occurrence',
    Header: TableColumnHeader({
      sortType: 'numeric',
      title: t('Occurrences'),
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cellCenter,
    Cell({ original }: IValueRow) {
      return original.occurrence.toLocaleString();
    },
  };
}

function ValuesTable({ original }: IRow): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { t } = useTranslation();
        const { eventId } = useParams<{ eventId: string }>();
        const [isLoading, setLoading] = useState(true);
        const [values, setValues] = useState<IValue[]>([]);

        useEffect(() => {
          async function getFieldValues(): Promise<void> {
            const path = `${original.get('collection').toLowerCase()}s/custom_data.${original.get(
              'f_key',
            )}/values`;
            const headers = getHeaders();
            const url = `${BLACKBOX_URL}${path}/?parent=${eventId}`;
            try {
              const response = await wretch<IBreadcrumbSuggestion[] | IFetchPersonFieldValue[]>(
                url,
                {
                  headers,
                  method: 'GET',
                },
              );
              if (isWretchError(response)) {
                throw response.error;
              }
              setValues(response.data);
            } finally {
              setLoading(false);
            }
          }

          if (!original.isPending) {
            getFieldValues();
          }
        }, [original.isPending]);

        return (
          <ReactTable<IValue>
            columns={[ValueColumn(t), OccurrencesColumn(t)]}
            data={values}
            defaultPageSize={10}
            defaultSorted={[{ id: 'value', desc: false }]}
            loading={isLoading}
            minRows={3}
            noDataText={isLoading ? '' : t('There is no data for this field yet.')}
          />
        );
      }}
    </Observer>
  );
}

export default ValuesTable;
