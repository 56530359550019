import { Observer, observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { DataRequest, Segment } from '@feathr/blackbox';
import { Button, Fieldset, Form, Input } from '@feathr/components';
import type { ISegmentSelectSimpleChangeProps } from '@feathr/extender/components/SegmentSelectSimple';
import SegmentSelectSimple from '@feathr/extender/components/SegmentSelectSimple';
import { StoresContext } from '@feathr/extender/state';
import type { TValidateGrouped } from '@feathr/rachis';

import ButtonNext from './ButtonNext';

interface IProps {
  dataRequest: DataRequest;
  onNext: () => void;
  onPrevious: () => void;
}

export function validateStepFour(
  dataRequest: DataRequest,
  conversionPixel?: Segment,
): TValidateGrouped {
  const completed = !['canceled', 'draft'].includes(dataRequest.get('state'));
  const dValidationErrors = dataRequest.validate(
    completed
      ? ['form_submission_field', 'form_submission_value']
      : [
          'conversion_pixel',
          'conversion_category',
          'form_submission_field',
          'form_submission_value',
        ],
    false,
    'grouped',
  ).errors;

  dValidationErrors.name =
    conversionPixel
      ?.validate(['name'], false)
      .errors.map((error) => error.replace('Name', 'Conversion pixel name')) || [];

  return dValidationErrors;
}

function DataRequestFormStepFour({
  dataRequest,
  onNext,
  onPrevious,
}: Readonly<IProps>): JSX.Element {
  const { Segments } = useContext(StoresContext);
  const { t } = useTranslation();

  function handleCreateConversionPixel(newSegment: Segment): void {
    newSegment.set({
      is_conversion_segment: true,
      predicates: [
        {
          attr_type: 'string',
          attr_against: 'seg_id',
          comparison: 'eq',
          kind: 'activity',
          value: newSegment.id,
        },
      ],
      read_only: true,
    });
  }

  function handleSelectConversionPixel({ id }: ISegmentSelectSimpleChangeProps): void {
    dataRequest.set({ conversion_pixel: id || undefined });
  }

  const conversionPixelId = dataRequest.get('conversion_pixel');
  const segment = conversionPixelId ? Segments.get(conversionPixelId) : undefined;

  function handleChangeName(newValue?: string): void {
    segment!.set({ name: newValue });
  }

  return (
    <Form
      actions={[
        <Button key={'previous'} onClick={onPrevious}>
          {t('Previous')}
        </Button>,
        <ButtonNext
          conversionPixel={segment}
          dataRequest={dataRequest}
          key={'next'}
          onNext={onNext}
          validate={validateStepFour}
        />,
      ]}
      description={
        <Trans t={t}>
          <p>
            A conversion pixel allows you to gain precise control over what activities are tracked
            as a conversion for your campaigns and flights. This helps to accurately report on
            conversion value from activities that can have a variable value, e.g., when tracking an
            e-commerce purchase where the customer may have many different items in a cart.
          </p>
          <p>
            <a
              href={
                'https://help.feathr.co/hc/en-us/articles/360063115793-How-to-use-Advanced-Conversions'
              }
              target={'_blank'}
            >
              Learn more about conversion pixels
            </a>
            .
          </p>
        </Trans>
      }
      label={t('Form Submit Capture')}
    >
      <Fieldset>
        <Observer>
          {() => {
            return (
              <SegmentSelectSimple
                filters={{
                  is_conversion_segment: true,
                }}
                helpText={t('Select or create a new conversion pixel.')}
                isClearable={true}
                label={t('Conversion pixel')}
                onChange={handleSelectConversionPixel}
                onCreate={handleCreateConversionPixel}
                required={true}
                value={dataRequest.get('conversion_pixel')}
              />
            );
          }}
        </Observer>
        {segment && segment.isEphemeral && (
          <Observer>
            {function useAnonymousFunction() {
              const [hadFocus, setHadFocus] = useState(false);

              function handleBlurName() {
                setHadFocus(true);
              }

              /*
               * TODO: When translations work for validation, allow passing in alternative field name.
               * Ensure label matches in error messages.
               */
              const segmentValidationErrors: string[] = hadFocus
                ? segment
                    .validate(['name'], false)
                    .errors.map((error) => error.replace('Name', 'Conversion pixel name'))
                : [];

              return (
                <Input
                  helpText={
                    <Trans t={t}>
                      Describe what this pixel is capturing.
                      <br />
                      Examples: <em>"Events", "Membership", "Donations".</em>
                    </Trans>
                  }
                  label={t('Conversion pixel name')}
                  onBlur={handleBlurName}
                  onChange={handleChangeName}
                  placeholder={t('Events')}
                  required={true}
                  type={'text'}
                  validationError={segmentValidationErrors}
                  value={segment.get('name') || ''}
                />
              );
            }}
          </Observer>
        )}
        <Observer>
          {() => {
            return (
              <Input
                attribute={'conversion_category'}
                helpText={
                  <Trans t={t}>
                    Enter a more specific label representing the precise conversion activity you
                    want to capture.
                    <br />
                    Examples:{' '}
                    <em>
                      "2022 Annual Event Registrations", "Membership Renewals", "Donations on
                      Homepage".
                    </em>
                  </Trans>
                }
                label={t('Conversion category')}
                model={dataRequest}
                placeholder={t('2022 Annual Event Registrations')}
                type={'text'}
              />
            );
          }}
        </Observer>
      </Fieldset>
    </Form>
  );
}

export default observer(DataRequestFormStepFour);
