import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ToastType } from 'react-toastify';

import type { IFacebook } from '@feathr/blackbox';
import type { Campaign } from '@feathr/blackbox';
import {
  Alert,
  AlertType,
  Button,
  Card,
  Fieldset,
  ModalV1,
  Spinner,
  toast,
  Tooltip,
} from '@feathr/components';
import { StoresContext, useAccount, useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

interface IProps {
  campaigns: Campaign[];
  disabled: boolean;
  setAgree: (agree: boolean) => void;
}

function FacebookDisconnectButton({ campaigns, disabled, setAgree }: IProps): JSX.Element {
  const account = useAccount();
  const { Events, Users } = useContext(StoresContext);
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);

  const projectIds = [...new Set(campaigns.map((campaign) => campaign.get('parent')))];
  const projects = Events.list({ filters: { id__in: projectIds } });

  async function disconnectIntegration(): Promise<void> {
    const response = await account.patch({ facebook: {} as IFacebook });

    if (response.isErrored) {
      toast(
        t('There was an error disconnecting your Facebook account: {{error}}', {
          error: response.error,
        }),
        {
          type: ToastType.ERROR,
        },
      );
      return;
    }
    toast(t('Facebook account has been disconnected.'), { type: ToastType.INFO });
    window.Appcues?.identify(account.id, {
      account_id: account.id,
      is_integrated_with_meta: false,
    });
    toggleIsModalOpen();
    /*
     * Set agree to false to enable the checkbox that allows the user to
     * connect their Facebook account
     */
    setAgree(false);
  }

  function getAffectedCampaigns(): JSX.Element | JSX.Element[] {
    // Create Fieldset per project, then populate with its child campaigns
    return projects.models.map((project) => (
      <Fieldset key={project.name} label={project.name}>
        {campaigns
          .filter((cpn) => cpn.get('parent') === project.id)
          .map((cpn) => (
            <>
              <Link key={cpn.name} to={localUrl(cpn.getItemUrl())}>
                {cpn.name}
              </Link>{' '}
              {/* User.name defaults to "Unnamed User" */}
              created by {Users.get(cpn.get('created_by')).name}
              <br />
            </>
          ))}
      </Fieldset>
    ));
  }

  return (
    <>
      <Tooltip key={'disconnect'} title={t('Disconnect your Facebook account from Feathr')}>
        <Button
          disabled={disabled}
          onClick={toggleIsModalOpen}
          prefix={<FontAwesomeIcon icon={faTrashAlt} />}
        >
          {t('Disconnect')}
        </Button>
      </Tooltip>
      {isModalOpen && (
        <ModalV1
          confirmButtonText={t('Yes, disconnect')}
          controlled={true}
          onClose={toggleIsModalOpen}
          onConfirm={disconnectIntegration}
          size={'sm'}
          t={t}
        >
          <Alert type={AlertType.danger}>
            {t(
              'Are you sure you want to disconnect this Facebook account? The following campaigns will be stopped:',
            )}
          </Alert>
          <Card>
            {campaigns.length ? (
              projects.isPending ? (
                <Spinner />
              ) : (
                getAffectedCampaigns()
              )
            ) : (
              t(
                'There are no active Meta campaigns in any of your projects, but disconnecting your Facebook account will stop Feathr from deploying your Meta pixel and stop audience growth on Meta platforms. Are you sure you want to disconnect?',
              )
            )}
          </Card>
        </ModalV1>
      )}
    </>
  );
}

export default observer(FacebookDisconnectButton);
