import { faAnglesUpDown, faFolderPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Dropdown,
  FormElement,
  InputElement,
  ListItem,
  Menu,
  MenuItem,
  Skeleton,
  Spinner,
} from '@feathr/components';

import * as inputStyles from '@feathr/components/dist/Input/InputElement.css';
import * as styles from './Switcher.css';

import noImg from 'images/no-img.png';

export interface ISwitcherItem {
  id: string;
  logo?: JSX.Element | string;
  name: string;
  url?: string;
}

interface ISwitcherProps {
  buttons?: JSX.Element[];
  className?: string;
  count?: number;
  currentItem?: ISwitcherItem;
  dropdownClassName?: string;
  hasMore?: boolean;
  hideSummary?: boolean;
  id?: string;
  isLoading?: boolean;
  items: ISwitcherItem[];
  linkType?: 'href' | 'link';
  placeholder?: string;
  search: string | undefined;
  setSearch: (value: string | undefined) => void;
  suffix?: ReactNode;
}

function Switcher(props: Readonly<ISwitcherProps>): JSX.Element {
  const { t } = useTranslation();
  const {
    buttons,
    className,
    count,
    currentItem,
    dropdownClassName,
    hasMore = false,
    hideSummary = false,
    id,
    isLoading = false,
    items,
    linkType = 'link',
    placeholder = t('Search by name...'),
    search,
    setSearch,
    suffix,
  } = props;

  // Set default icon for when placeholder is displayed
  let logo: JSX.Element = <FontAwesomeIcon icon={faFolderPlus} />;
  if (currentItem) {
    // Wrap logo in span to allow additional styling
    logo =
      typeof currentItem.logo === 'string' || currentItem.logo === undefined ? (
        <span>
          <img alt={''} src={currentItem.logo || noImg} />
        </span>
      ) : (
        <span>{currentItem.logo}</span>
      );
  }

  const content = (
    <div
      className={classNames(styles.content, {
        [styles.single]: count === undefined || count < 1 || (isLoading && count === undefined),
        [styles.compact]: hideSummary,
      })}
    >
      {!hideSummary && (
        <div className={styles.summary}>
          {logo}
          <p>{currentItem ? currentItem.name : placeholder}</p>
        </div>
      )}
      {count === undefined && <Spinner size={20} />}
      {!!count && count >= 1 && (
        <>
          <FormElement label={'Switch to...'} prefix={<FontAwesomeIcon icon={faSearch} />}>
            <InputElement
              className={inputStyles.input}
              isClearable={true}
              onChange={(newSearch) => {
                setSearch(newSearch);
              }}
              placeholder={placeholder}
              value={search}
            />
            {!!buttons && <ButtonGroup className={styles.buttonGroup}>{buttons}</ButtonGroup>}
          </FormElement>
          <Menu className={styles.results}>
            {isLoading ? (
              [...Array(3)].map((_, key) => (
                <MenuItem key={key}>
                  <Skeleton paragraphs={0} title={true} width={160 + Math.random() * 100} />
                </MenuItem>
              ))
            ) : items.length ? (
              <>
                {items.map((i: ISwitcherItem) => {
                  const itemAttributes = i.url
                    ? {
                        // Use href instead of link to bypass react router to enforce a complete refresh.
                        [linkType]: i.url,
                      }
                    : {};
                  const itemLogo =
                    typeof i.logo === 'string' || i.logo === undefined ? (
                      <img alt={''} src={i.logo || noImg} />
                    ) : (
                      i.logo
                    );
                  return (
                    <MenuItem className={styles.item} key={i.id} {...itemAttributes}>
                      {itemLogo}
                      {i.name}
                    </MenuItem>
                  );
                })}
                {hasMore && (
                  <ListItem key={'no-results'}>Use search field for additional results...</ListItem>
                )}
              </>
            ) : (
              <ListItem key={'no-results'}>No results found...</ListItem>
            )}
          </Menu>
          {suffix}
        </>
      )}
    </div>
  );

  return (
    <Dropdown
      align={'start'}
      className={classNames(styles.root, dropdownClassName)}
      content={content}
      position={'bottom'}
      toggleOnClick={false}
    >
      <Button
        className={classNames(styles.button, className)}
        id={id}
        prefix={isLoading ? <Spinner className={styles.spinner} size={20} /> : logo}
        suffix={<FontAwesomeIcon icon={faAnglesUpDown} />}
      >
        {/* We need span to elide name */}
        <span>{currentItem ? currentItem.name : placeholder}</span>
      </Button>
    </Dropdown>
  );
}

export default observer(Switcher);
