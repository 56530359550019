import { faAddressBook, faFileDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ContactList } from '@feathr/blackbox';
import { Button, TLDR } from '@feathr/components';

interface IProps {
  readonly contactList?: ContactList;
}

function ContactListTLDR({ contactList }: IProps): JSX.Element {
  const { t } = useTranslation();

  if (contactList === undefined || contactList.isPending) {
    return <TLDR isLoading={true} />;
  }

  const listFile = contactList.get('list_file');
  const shareWithEvent = contactList.get('share_with_event');

  return (
    <TLDR
      action={
        listFile &&
        shareWithEvent && (
          <Button href={listFile} prefix={<FontAwesomeIcon icon={faFileDownload} />}>
            {t('Download')}
          </Button>
        )
      }
      icon={faAddressBook}
      isLoading={false}
      title={contactList.get('name')}
    />
  );
}

export default observer(ContactListTLDR);
