import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { BlackbaudRaisersEdgeIntegration } from '@feathr/blackbox';
import { Avatar, CardV2 as Card } from '@feathr/components';

import RaisersEdgeIntegrationControls from '../../RaisersEdgeIntegrationControls';
import RaisersEdgeIntegrationStatus from '../../RaisersEdgeIntegrationStatus';
import SummaryElement from './SummaryElement';

import * as styles from './RaisersEdgeSummaryCard.css';

interface IProps {
  integration: BlackbaudRaisersEdgeIntegration;
}

function RaisersEdgeSummaryCard({ integration }: IProps): JSX.Element {
  const { t } = useTranslation();
  /*
   * Summary card only show when integration is present and authenticated; however,
   * an authenticated user may not be present when the integration is first created.
   */
  const {
    user_id: userId,
    given_name: givenName,
    family_name: familyName,
    email,
  } = integration.get('authenticated_user')!;
  const fullName = t('{{givenName}} {{familyName}}', { givenName, familyName });

  return (
    <>
      <Card width={'full'}>
        <Card.Header title={t('Summary')}>
          <RaisersEdgeIntegrationControls integration={integration} />
        </Card.Header>
        <Card.Content>
          <div className={styles.elements}>
            <div>
              <Avatar size={'large'}></Avatar>
              <SummaryElement element={email} title={fullName} />
            </div>
            <SummaryElement element={userId} title={t('ID')} />
            <SummaryElement
              element={
                <RaisersEdgeIntegrationStatus
                  showLabel={false}
                  state={integration.integrationState}
                />
              }
            />
          </div>
        </Card.Content>
      </Card>
    </>
  );
}

export default RaisersEdgeSummaryCard;
