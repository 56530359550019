import React, { Fragment } from 'react';

import type { IPath } from '@feathr/blackbox';

import styles from './PathPreview.css';

interface IProps {
  path: IPath;
}

function PathPreview(props: IProps) {
  const { path } = props;

  return (
    <section className={styles.root}>
      <h4>Goal:</h4>
      <p>{path.shared_description}</p>
      {path.legs.map((leg, index) => (
        <Fragment key={index}>
          <h5>
            Leg {index + 1}: {leg.name}
          </h5>
          <ul>
            {leg.campaigns.filter((cpn) => !!cpn).length === 0 && (
              <li>No campaigns available in this leg for your license tier.</li>
            )}
            {leg.campaigns
              .filter((cpn) => !!cpn)
              .map((campaign, i) => (
                <li key={campaign.id || i}>{campaign.placeholder ?? campaign.name}</li>
              ))}
          </ul>
        </Fragment>
      ))}
    </section>
  );
}

export default PathPreview;
