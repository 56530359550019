import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import type { RowRenderProps } from 'react-table';

import type { Creative } from '@feathr/blackbox';
import { Button, Chip, Spinner, TableColumnHeader, Time } from '@feathr/components';
import CampaignChip from '@feathr/extender/components/CampaignChip';
import CreativeThumbnail from '@feathr/extender/components/CreativeThumbnail';
import { TimeFormat } from '@feathr/hooks';

import EventCreativesOptions from './EventCreativesOptions';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './EventCreativesColumns.css';

import noImg from '@feathr/extender/images/no-img.png';

interface IRow extends RowRenderProps {
  original: Creative;
}

function EventTemplatesColumns() {
  return [
    {
      id: 'thumbnail',
      Header: TableColumnHeader({
        title: 'Thumbnail',
      }),
      sortable: false,
      className: styles.thumbnail,
      width: 220,
      Cell({ original }: IRow): JSX.Element {
        const url = (original.get('url') ?? original.get('preview_url')) || noImg;
        return (
          <Observer>
            {() => {
              return (
                <Button href={url} target={'_blank'} type={'link'}>
                  <CreativeThumbnail creative={original.toJS()} height={106} width={203} />
                </Button>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: 'Name',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction() {
              if (original.isUpdating) {
                return (
                  <div style={{ display: 'flex' }}>
                    <i>Loading...</i>&nbsp;
                    <Spinner size={16} />
                  </div>
                );
              }

              const url = (original.get('url') ?? original.get('preview_url')) || noImg;
              return (
                <Button href={url} target={'_blank'} type={'link'}>
                  {original.name}
                </Button>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: '_cls',
      Header: TableColumnHeader({
        title: 'Type',
      }),
      sortable: false,
      width: 150,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {() => {
              return <Chip>{original.getTypeLabel()}</Chip>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'dimensions',
      Header: TableColumnHeader({
        title: 'Dimensions',
      }),
      sortable: false,
      className: tableStyles.cellRight,
      width: 110,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {() => {
              const height = original.get('height', 0);
              const width = original.get('width', 0);
              return <>{width > 0 && height > 0 ? `${width}px x ${height}px` : '-'}</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'campaign',
      Header: TableColumnHeader({
        title: 'Campaign',
      }),
      sortable: false,
      width: 150,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {() => {
              const campaignId = original.get('parent');
              return campaignId ? <CampaignChip id={original.get('parent')} /> : null;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'date_last_modified',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Last Updated',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      width: 150,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {() => {
              return (
                <Time
                  format={TimeFormat.shortDateTime}
                  timestamp={original.get('date_last_modified')}
                />
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'options',
      Header: TableColumnHeader({
        title: 'Options',
      }),
      sortable: false,
      className: tableStyles.cellCenter,
      width: 80,
      Cell(row: IRow): JSX.Element {
        return <EventCreativesOptions creative={row.original} />;
      },
    },
  ];
}

export default EventTemplatesColumns;
