import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { JSX } from 'react';
import React from 'react';

import { Tooltip } from '@feathr/components';

import * as styles from './StatBox.css';

/*
 * @deprecated This component is now deprecated and should use the StatBox component inside
 * src/components. This component can be completely removed when Usage metrics are
 * updated to reflect the new design.
 */

interface IProps {
  label: string | React.ReactNode;
  primary: string;
  primarySize?: 'sm' | 'md' | 'lg';
  secondary?: string;
  secondaryLabel?: string | React.ReactNode;
  size?: 0 | 1 | 2 | 3 | 4;
  tooltip?: string;
  vertical?: boolean;
  children?: React.ReactNode;
}

function StatBox({
  label,
  children,
  primary,
  secondary = '',
  secondaryLabel = '',
  size = 1,
  primarySize = 'lg',
  vertical = false,
  tooltip,
}: IProps): JSX.Element {
  const secondaryElement: JSX.Element = (
    <div className={styles.secondaryStatWrapper}>
      <label>{secondaryLabel}</label>
      <span className={styles.secondaryStat}>{secondary}</span>
    </div>
  );

  return (
    <div
      className={classNames(styles.statBox, styles[`size${size}` as keyof typeof styles], {
        [styles.vertical]: vertical,
      })}
      data-appcues-stat={label}
    >
      <div className={classNames(styles.box, { [styles.vertical]: vertical })}>
        <label>
          {label} &nbsp;
          {tooltip && (
            <Tooltip title={tooltip}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Tooltip>
          )}
        </label>
        <div className={classNames(styles.primaryStat, styles[primarySize])}>{primary}</div>
        {(!!secondary || !!secondaryLabel) && secondaryElement}
        {children && <div className={styles.content}>{children}</div>}
      </div>
    </div>
  );
}

export default StatBox;
