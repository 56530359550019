import type { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  Campaign,
  CustomField,
  ICampaignAttributes,
  IPinpointTrigger,
  TComparison,
} from '@feathr/blackbox';
import { CampaignClass, FieldCollection } from '@feathr/blackbox';
import { FormSummaryItem, Value } from '@feathr/components';
import type { IAttrOption } from '@feathr/extender/components/EditFilters/PredicateInputs';
import { StoresContext } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';

import type { ICampaignValidationErrors } from '../CampaignSummary';

import * as styles from './TriggerSummary.css';

import defaultBreadcrumbFields from '@feathr/blackbox/fixtures/defaultBreadcrumbFields.json';
import defaultPersonFields from '@feathr/blackbox/fixtures/defaultPersonFields.json';

interface IProps {
  campaign: Campaign<ICampaignAttributes>;
  validationErrors?: ICampaignValidationErrors;
}

interface IComparisonOption {
  id: TComparison;
  name: string;
}

function getComparisons(t: TFunction, attr: IPinpointTrigger): IComparisonOption[] {
  const baseOptions: IComparisonOption[] = [
    { id: 'exists', name: t('exists') },
    { id: 'nexists', name: t('does not exist') },
  ];
  const numberOptions: IComparisonOption[] = [
    ...baseOptions,
    { id: 'eq', name: t('equals') },
    { id: 'ne', name: t('does not equal') },
    { id: 'gt', name: t('is greater than') },
    { id: 'lt', name: t('is less than') },
  ];
  switch (attr.attr_type) {
    case 'string':
      if (attr.attr_against?.includes('_id')) {
        return [...baseOptions, { id: 'eq', name: t('is') }, { id: 'ne', name: t('is not') }];
      }
      return [
        ...baseOptions,
        { id: 'eq', name: t('is') },
        { id: 'ne', name: t('is not') },
        { id: 'starts_with', name: t('starts with') },
        { id: 'wildcard', name: t('matches pattern') },
        { id: 'regexp', name: t('matches the expression') },
        { id: 'contains_substring', name: t('contains') },
      ];

    case 'integer':

    case 'float':
      return numberOptions;

    case 'boolean':
      return [
        { id: 'eq', name: t('is') },
        { id: 'ne', name: t('is not') },
      ];

    case 'date':
      return [
        ...baseOptions,
        { id: 'eq', name: t('is') },
        { id: 'ne', name: t('is not') },
        { id: 'gt', name: t('is after') },
        { id: 'lt', name: t('is before') },
      ];

    case 'list':
      return [
        ...baseOptions,
        { id: 'in', name: t('contains') },
        { id: 'nin', name: t('does not contain') },
      ];

    default:
      return baseOptions;
  }
}

const TriggerSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();
  const { CustomFields } = useContext(StoresContext);
  const customFields = CustomFields.list({
    filters: { collection__in: [FieldCollection.Person, FieldCollection.Breadcrumb] },
    pagination: { page: 0, page_size: 1000 },
  });

  function customFieldToOption(field: CustomField): IAttrOption {
    return {
      id: `custom.${field.get('f_key')}`,
      name: field.get('u_key'),
      type: field.get('data_type'),
      kind: field.get('collection') as 'Person' | 'Breadcrumb',
      helpText: field.get('description'),
    };
  }

  const allFields = [
    ...defaultPersonFields,
    ...defaultBreadcrumbFields,
    ...customFields.models.map((field) => customFieldToOption(field)),
  ];

  const delayUnitMap = {
    seconds: t('seconds'),
    minutes: t('minutes'),
    hours: t('hours'),
    days: t('days'),
    weeks: t('weeks'),
  };

  const delayValue = campaign.get('delay_value', 5);
  const delayUnit = campaign.get('delay_unit', 'seconds');

  /**
   * 5 seconds = "immediately" because that's the soonest we can send
   * an email in response to a trigger.
   */
  const delayValueFormatted =
    delayValue <= 5 && delayUnit === 'seconds'
      ? t('Immediately')
      : `${delayValue} ${delayUnitMap[delayUnit]}`;

  return (
    <>
      <FormSummaryItem
        label={t('Trigger', { count: campaign.get('actions', []).length })}
        value={
          <ul>
            {campaign.get('actions').map((action, i) => {
              const error = validationErrors?.actions ?? ([] as Array<Record<string, string[]>>);
              const attrLabel =
                allFields.find((field) => field.id === action.attr_against)?.name ?? '-';
              const comparison =
                getComparisons(t, action).find((option) => option.id === action.comparison)?.name ??
                '-';

              function getValueLabel(value?: string): JSX.Element | string | number | null {
                if (['exists', 'nexists'].includes(action.comparison)) {
                  return null;
                }

                if (value === undefined) {
                  return <em>{t('empty')}</em>;
                }

                return value;
              }

              return (
                <li key={action.attr_against}>
                  <Value
                    className={styles.triggerActivity}
                    validationError={error[i] ? flattenErrors(error[i]) : undefined}
                    value={
                      <>
                        {attrLabel} {comparison} {getValueLabel(action.value)}
                      </>
                    }
                  />
                </li>
              );
            })}
          </ul>
        }
      />
      <FormSummaryItem label={t('Delay')} value={delayValueFormatted} />
      {campaign.get('_cls') === CampaignClass.AutoPinpointEmail && (
        <FormSummaryItem label={t('Repeat')} value={campaign.get('repeat') ? t('Yes') : t('No')} />
      )}
    </>
  );
});

export default TriggerSummary;
