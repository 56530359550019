import type { WithT } from 'i18next';

import type { UsageMetric, UsageQuota } from '@feathr/blackbox';
import { EUsageMetrics } from '@feathr/blackbox';

export const USAGE_UNLIMITED = -1;
export const USAGE_WARNING = 0.7;
export const USAGE_EXCEEDED = 1;

interface IGetUsageMetrics extends WithT {
  usage: UsageMetric;
  quota: UsageQuota;
}

interface IUsage {
  feature: string;
  usage: number;
  limit: number;
  limitLabel: string;
  tooltip?: string;
}

export function getUsageMetrics({ t, usage, quota }: IGetUsageMetrics): IUsage[] {
  return [
    {
      feature: t('Breadcrumbs'),
      usage: usage.get(EUsageMetrics.Breadcrumbs, 0),
      limit: quota.get(EUsageMetrics.Breadcrumbs)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('per month'),
    },
    {
      feature: t('Known Persons'),
      usage: usage.get(EUsageMetrics.IncludedIdentifiedPersons, 0),
      limit: quota.get(EUsageMetrics.IncludedIdentifiedPersons)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('per license'),
    },
    {
      feature: t('Anonymous Persons'),
      usage: usage.get(EUsageMetrics.IncludedAnonymousPersons, 0),
      limit: quota.get(EUsageMetrics.IncludedAnonymousPersons)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('per license'),
    },
    {
      feature: t('Sent Emails'),
      usage: usage.get(EUsageMetrics.Emails, 0),
      limit: quota.get(EUsageMetrics.Emails)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('per month'),
    },
    {
      feature: t('Custom Fields'),
      usage: usage.get(EUsageMetrics.CustomDataFields, 0),
      limit: quota.get(EUsageMetrics.CustomDataFields)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('per license'),
    },
    {
      feature: t('Conversion Pixels'),
      usage: usage.get(EUsageMetrics.ConversionPixels, 0),
      limit: quota.get(EUsageMetrics.ConversionPixels)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('per license'),
    },
    {
      feature: t('Email Mapping Campaigns'),
      usage: usage.get(EUsageMetrics.EmailMappingCampaigns, 0),
      limit: quota.get(EUsageMetrics.EmailMappingCampaigns)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('active'),
    },
    {
      feature: t('Geofencing Campaigns'),
      usage: usage.get(EUsageMetrics.GeofencingCampaigns, 0),
      limit: quota.get(EUsageMetrics.GeofencingCampaigns)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('active'),
    },
    {
      feature: t('Historical Geofencing Campaigns'),
      usage: usage.get(EUsageMetrics.HistoricalGeofencingCampaigns, 0),
      limit: quota.get(EUsageMetrics.HistoricalGeofencingCampaigns)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('active'),
    },
    {
      feature: t('Authorized Domains'),
      usage: usage.get(EUsageMetrics.DKIMAuthentications, 0),
      limit: quota.get(EUsageMetrics.DKIMAuthentications)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('per license'),
    },
    {
      feature: t('Creatives Designed'),
      usage: usage.get(EUsageMetrics.AdBuilders, 0),
      limit: quota.get(EUsageMetrics.AdBuilders)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('per license'),
    },
    {
      feature: t('Projects'),
      usage: usage.get(EUsageMetrics.Projects, 0),
      limit: quota.get(EUsageMetrics.Projects)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('per license'),
    },
    {
      feature: t('Users'),
      usage: usage.get(EUsageMetrics.Users, 0),
      limit: quota.get(EUsageMetrics.Users)?.quota ?? USAGE_UNLIMITED,
      limitLabel: t('per license'),
    },
  ];
}
