import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionMeta, ValueType } from 'react-select';

import type { IPartner } from '@feathr/blackbox';
import { AsyncSelect } from '@feathr/components';
import { PartnerOption } from '@feathr/extender/components/SelectOptions';
import { StoresContext } from '@feathr/extender/state';

interface IPartnerSelectProps {
  className?: string;
  disabled?: boolean;
  projectIds?: string[];
  filters?: Record<string, unknown>;
  helpPlacement?: 'bottom' | 'top';
  helpText?: ReactNode;
  id?: string;
  isLoading?: boolean;
  label?: React.ReactNode;
  onClear: () => void;
  placeholder?: string;
}

export interface IPartnerSelectMultiProps extends IPartnerSelectProps {
  isMulti: true;
  onChange: (value: string[]) => void;
  value?: string[];
}

export interface IPartnerSelectSingleProps extends IPartnerSelectProps {
  isMulti?: false;
  onChange: (value: string) => void;
  value?: string;
}

function PartnerSelect({
  disabled,
  projectIds,
  filters,
  helpText,
  helpPlacement,
  id,
  isLoading,
  isMulti = false,
  label,
  onChange,
  onClear,
  placeholder,
  value,
}: IPartnerSelectMultiProps | IPartnerSelectSingleProps): JSX.Element {
  const { t } = useTranslation();
  const { Partners } = useContext(StoresContext);

  async function loadOptions(inputValue?: string): Promise<IPartner[]> {
    const defaultFilters: { name__icontains?: string; _parent__in?: string[] } = {};

    if (inputValue) {
      defaultFilters.name__icontains = inputValue;
    }

    if (projectIds?.length) {
      defaultFilters._parent__in = projectIds;
    }

    const partnerFilters: Record<string, unknown> = !!filters
      ? {
          ...defaultFilters,
          ...filters,
        }
      : defaultFilters;
    const data = Partners.list({
      filters: partnerFilters,
    });
    await when(() => !data.isPending);
    return data.models
      .filter((model) => {
        if (Array.isArray(value)) {
          return !value.includes(model.id);
        }
        return !value || value !== model.id;
      })
      .map((model) => model.toJS());
  }

  function handleChange(newValue: ValueType<IPartner>, action: ActionMeta<IPartner>): void {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (Array.isArray(newValue)) {
        if (newValue.length) {
          (onChange as IPartnerSelectMultiProps['onChange'])(
            (newValue as IPartner[]).map((v) => v.id!),
          );
        } else {
          onClear();
        }
      } else if (newValue) {
        (onChange as IPartnerSelectSingleProps['onChange'])((newValue as IPartner).id!);
      } else {
        onClear();
      }
    }
  }

  function noOptionsMessage({ inputValue }): string {
    return inputValue.length ? 'No results' : 'Start typing the name of a partner...';
  }

  return (
    <AsyncSelect
      components={{ Option: PartnerOption }}
      defaultOptions={true}
      disabled={disabled}
      helpPlacement={helpPlacement}
      helpText={helpText}
      id={id}
      isClearable={true}
      isLoading={isLoading}
      isMulti={isMulti}
      label={label}
      loadOptions={loadOptions}
      noOptionsMessage={noOptionsMessage}
      onChange={handleChange}
      placeholder={placeholder}
      validationError={
        disabled && projectIds?.length === 0 ? t('Select a project first') : undefined
      }
      value={
        Array.isArray(value)
          ? value.map((valueId) => Partners.get(valueId).toJS())
          : value
            ? Partners.get(value).toJS()
            : // Due to a bug in async select, we need to pass null instead of undefined in order to clear the value
              null
      }
    />
  );
}

export default observer(PartnerSelect);
