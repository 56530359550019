import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Account } from '@feathr/blackbox';
import { Alert, AlertType, Section, Spinner, StatsCardV1 } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { concatenateAndRoundNumber } from '@feathr/extender/utils';
import { StatBox } from '@feathr/report_components';

import { getUsageMetrics, USAGE_EXCEEDED, USAGE_UNLIMITED, USAGE_WARNING } from './usage';

import * as styles from './UsageMetrics.css';

interface IUsageMetricsProps {
  account: Account;
}

function UsageMetricsComponent({ account }: IUsageMetricsProps): JSX.Element {
  const { UsageQuotas, UsageMetrics } = React.useContext(StoresContext);
  const { t } = useTranslation();

  const usage = UsageMetrics.get(account.id);
  const quota = UsageQuotas.get(account.id);
  const usageMetrics = getUsageMetrics({ t, usage, quota });

  const hasUsageWarnings = usageMetrics.some(
    (metric) => metric.usage / metric.limit >= USAGE_WARNING,
  );
  const hasExceededUsage = usageMetrics.some(
    (metric) => metric.usage / metric.limit >= USAGE_EXCEEDED,
  );

  return (
    <Section
      description={t('See the limits and current usage levels of various tools in Feathr.')}
      title={t('Usage')}
    >
      <>
        {hasUsageWarnings && !hasExceededUsage && (
          <Alert type={AlertType.warning}>
            {t('There is at least one usage metric over 70%.')}
          </Alert>
        )}
        {hasExceededUsage && (
          <Alert type={AlertType.danger}>
            {t(
              'There is at least one usage metric being exceeded. Please speak to your CSM to review options.',
            )}
          </Alert>
        )}
        <StatsCardV1 childContentClassName={styles.childContent} contentType={'bordered'}>
          {usage.isPending || quota.isPending ? (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            usageMetrics.map(({ usage, limit, feature, limitLabel, tooltip }) => {
              const percentage = usage / limit;
              const primary =
                limit === USAGE_UNLIMITED
                  ? t('Unlimited')
                  : `${concatenateAndRoundNumber(usage, true)}/${concatenateAndRoundNumber(
                      limit,
                      false,
                    )}`;
              const secondary =
                limit === USAGE_UNLIMITED
                  ? t('{{usage}} used', { usage: numeral(usage).format('0,0') })
                  : undefined;

              const showUsageWarning = percentage >= USAGE_WARNING && percentage < USAGE_EXCEEDED;
              const showUsageExceeded = percentage >= USAGE_EXCEEDED;

              return (
                <StatBox
                  key={feature}
                  label={feature}
                  primary={primary}
                  primarySize={'md'}
                  secondary={secondary}
                  secondaryLabel={limitLabel}
                  size={2}
                  tooltip={tooltip}
                >
                  {showUsageWarning && (
                    <Alert size={'lg'} type={AlertType.warning}>
                      {t('Usage over 70%')}
                    </Alert>
                  )}
                  {showUsageExceeded && (
                    <Alert size={'lg'} type={AlertType.danger}>
                      {t('Usage exceeded')}
                    </Alert>
                  )}
                </StatBox>
              );
            })
          )}
        </StatsCardV1>
      </>
    </Section>
  );
}

export default observer(UsageMetricsComponent);
