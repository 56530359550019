import { runInAction, set } from 'mobx';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { Prompt } from 'react-router';

import type { IBannersnackAttributes } from '@feathr/blackbox';
import type { IEditorIframeProps } from '@feathr/components';
import { Editor, toast } from '@feathr/components';
import type { IBaseAttributes, Model } from '@feathr/rachis';

interface IBannersnackClass {
  editorURL: () => Promise<string>;
  sync: (timestamp: number) => Promise<void>;
}

interface IProps<T extends Model<IBaseAttributes & IBannersnackAttributes> & IBannersnackClass>
  extends Omit<IEditorIframeProps, 'element' | 'src'> {
  model: T;
}

interface IState {
  editorURL: string;
  timestamp: number;
}

function BannersnackEditor<
  T extends Model<IBaseAttributes & IBannersnackAttributes> & IBannersnackClass,
>({ children, className, isLoading, model, ...editorProps }: IProps<T>) {
  return (
    <Observer>
      {function useAnonymousFunction() {
        const state = useLocalObservable(
          () =>
            ({
              editorURL: '',
              timestamp: Math.floor(+new Date() / 1000),
            } as IState),
        );
        const iframeRef = useRef<HTMLIFrameElement>(null);

        useEffect(() => {
          function listener(event: MessageEvent) {
            if (event.origin !== 'https://app.enterprise.bannersnack.com') {
              return;
            }
            if (!event.data.split) {
              return;
            }
            const parts = event.data.split(':');
            if (parts[0] === 'BS.useBanner') {
              const bannerHash = parts[1];
              model.patch({ banner_hash: bannerHash });
              model.setAttributeClean('banner_hash');
            }
          }

          window.addEventListener('message', listener, false);
          return () => {
            model.sync(state.timestamp);
            window.removeEventListener('message', listener);
          };
        }, []);

        useEffect(() => {
          function listener(event: KeyboardEvent) {
            if (!iframeRef.current) {
              return;
            }
            if (event.key === 'Control' || event.key === 'Meta') {
              return;
            }
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
              toast(
                'Please click inside the banner editor and then press Ctrl + s (or ⌘ + s on Mac) to save.',
              );
              event.preventDefault();
            }
          }

          document.addEventListener('keydown', listener, false);

          return () => {
            document.removeEventListener('keydown', listener);
          };
        }, []);

        useEffect(() => {
          model.editorURL().then((url) => {
            model.setAttributeDirty('banner_hash');
            runInAction(() => {
              set(state, 'editorURL', `https://${url}`);
            });
          });
        }, []);

        return (
          <Editor
            {...editorProps}
            className={className}
            element={'iframe'}
            isLoading={!state.editorURL || isLoading}
            name={'bannersnack-container'}
            ref={iframeRef}
            src={state.editorURL}
          >
            <Prompt
              message={() => {
                return 'You have unsaved changes to your model. Are you sure you want to leave without saving?';
              }}
              when={model.isDirty}
            />
            {children}
          </Editor>
        );
      }}
    </Observer>
  );
}

export default BannersnackEditor;
