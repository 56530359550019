import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faBullhorn, faCode, faComment } from '@fortawesome/pro-light-svg-icons';

import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

export type TNotificationType =
  | 'campaign_budget_hit'
  | 'account_super_pixel_inactive'
  | 'facebook_ad_creative_rejected'
  | 'facebook_ad_creative_approved'
  | 'facebook_ad_creative_with_issues'
  | 'facebook_campaign_sync_failure'
  | 'facebook_campaign_sync_failure_non_discrimination'
  | 'pinpoint_bounce_rate_critical'
  | 'pinpoint_bounce_rate_warning';

export enum ENotificationLevel {
  info = 0,
  warning = 1,
  critical = 2,
}

export interface ICampaignNotificationContext {
  account_id: string;
  event_id: string;
  campaign_id: string;
}

export type TNotificationContext = ICampaignNotificationContext;

export interface INotificationAction {
  text?: string;
  link?: string;
}

export interface INotification extends IBaseAttributes {
  account_id: string;
  action: INotificationAction;
  channel: string;
  context?: TNotificationContext;
  created: string;
  is_read: boolean;
  link: string;
  message: string;
  method_name: TNotificationType;
  level: ENotificationLevel;
  title: string;
  /** Timestamp of when the notification's issue was resolved. */
  resolved?: string;
}

export class Notification extends Model<INotification> {
  public readonly className = 'Notification';

  public constraints: TConstraints<INotification> = {};

  public getIcon(): IconDefinition {
    const NOTIFICATION_ICON_MAP: Record<string, IconDefinition> = {
      campaign_budget_hit: faBullhorn,
      account_super_pixel_inactive: faCode,
      facebook_ad_creative_rejected: faBullhorn,
      facebook_ad_creative_with_issues: faBullhorn,
      facebook_ad_creative_approved: faBullhorn,
      facebook_campaign_sync_failure: faBullhorn,
      facebook_campaign_sync_failure_non_discrimination: faBullhorn,
      default: faComment,
    };

    return (
      NOTIFICATION_ICON_MAP[this.get('method_name') as keyof typeof NOTIFICATION_ICON_MAP] ||
      NOTIFICATION_ICON_MAP['default']
    );
  }

  public getMessage(): string {
    const action = this.get('action');
    if (action && action.text) {
      return `${this.get('message')} ${action.text}`;
    }
    return this.get('message');
  }

  public getCta(): string {
    const context: TNotificationContext = this.get('context')
      ? this.get('context')!
      : ({} as TNotificationContext);
    const action: INotificationAction = this.get('action');
    const methodName: TNotificationType = this.get('method_name');
    const methodLink = {
      campaign_budget_hit: `/projects/${context.event_id}/campaigns/${context.campaign_id}/`,
      account_super_pixel_inactive: '/data/pixel',
      facebook_ad_creative_rejected: `/projects/${context.event_id}/campaigns/${context.campaign_id}/edit#step3`,
      facebook_ad_creative_with_issues: `/projects/${context.event_id}/campaigns/${context.campaign_id}/edit#step3`,
      facebook_ad_creative_approved: `/projects/${context.event_id}/campaigns/${context.campaign_id}/`,
      facebook_campaign_sync_failure: `/projects/${context.event_id}/campaigns/${context.campaign_id}/edit`,
      facebook_campaign_sync_failure_non_discrimination: `/projects/${context.event_id}/campaigns/${context.campaign_id}/edit`,
      notify_bad_communication_preference: `/settings/integrations/imis`,
    }[methodName];
    if (methodLink) {
      return methodLink;
    } else if (action && action.link) {
      if (isFeathrActionLink(action.link)) {
        const url = new URL(action.link);
        return `${url.pathname}${url.search}${url.hash}`;
      } else {
        return action.link;
      }
    }
    return '';
  }
}

export class Notifications extends Collection<Notification> {
  public getModel(attributes: Partial<INotification>): Notification {
    return new Notification(attributes);
  }

  public url(): string {
    return `${this.getHostname()}notifications/`;
  }

  public getClassName(): string {
    return 'notifications';
  }
}

export function isFeathrActionLink(actionLink: string): boolean {
  if (!actionLink) {
    return false;
  }
  try {
    const url = new URL(actionLink);
    const domains = [
      'app.feathr.co',
      'local.feathr.app',
      'dev.feathr.co',
      'staging.feathr.co',
      'qa1.feathr.co',
      'qa2.feathr.co',
      'qa3.feathr.co',
    ];
    for (const domain of domains) {
      if (url.hostname === domain) {
        return true;
      }
    }
  } catch (e) {
    return false;
  }

  return false;
}
