// TODO: Refactor any code implmenting this file to use segment.ts instead.
import { computed, get, makeObservable } from 'mobx';
import { fromPromise } from 'mobx-utils';

import { isWretchError, wretch } from '@feathr/rachis';

import { Session } from '..';
import type {
  ISegment,
  ISegmentFetchCountResponse,
  ISegmentFetchFieldCardinalityResponse,
  IStatsPayload,
} from '../segments';

const getHeaders = () => Session.getHeaders();

class SegmentStatHelper {
  public segment: Partial<ISegment>;

  public oldCount = 0;

  public oldReachable = 0;

  public oldCrumbs = 0;

  public oldUniqueEmails = 0;

  @computed
  get countPromise() {
    return fromPromise(this.fetchCount());
  }

  @computed
  get count() {
    return this.countPromise.case({
      pending: () => this.oldCount,
      rejected: () => (this.oldCount = 0),
      fulfilled: (value) => (this.oldCount = value),
    });
  }

  @computed
  get reachablePromise() {
    return fromPromise(this.fetchReachable());
  }

  @computed
  get reachable() {
    return this.reachablePromise.case({
      pending: () => this.oldReachable,
      rejected: () => (this.oldReachable = 0),
      fulfilled: (value) => (this.oldReachable = value),
    });
  }

  @computed
  get uniqueEmailsPromise() {
    return fromPromise(this.fetchUniqueEmails());
  }

  @computed
  get emails() {
    return this.uniqueEmailsPromise.case({
      pending: () => this.oldUniqueEmails,
      rejected: () => (this.oldUniqueEmails = 0),
      fulfilled: (value) => (this.oldUniqueEmails = value),
    });
  }

  @computed
  get crumbsPromise() {
    return fromPromise(this.fetchCrumbs());
  }

  @computed
  get crumbs() {
    return this.crumbsPromise.case({
      pending: () => this.oldCrumbs,
      rejected: () => (this.oldCrumbs = 0),
      fulfilled: (value) => (this.oldCrumbs = value),
    });
  }

  public constructor(segment: Partial<ISegment>) {
    makeObservable(this);
    this.segment = segment;
  }

  public async fetchLiveStat<T extends Record<string, unknown>>(
    path: string,
    body: Partial<ISegment>,
  ): Promise<T> {
    const url = `${BLACKBOX_URL}${path}`;
    const headers = getHeaders();
    const response = await wretch<T>(url, {
      headers,
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public getPayload(): IStatsPayload {
    const payload = {
      predicates: get(this.segment, 'predicates'),
      mode: get(this.segment, 'mode'),
      lookback_mode: get(this.segment, 'lookback_mode'),
      lookback_value: get(this.segment, 'lookback_value'),
    };
    const parentSegment = get(this.segment, 'parent_segment');
    if (parentSegment) {
      payload['parent_segment'] = parentSegment;
    }
    return payload;
  }

  private async fetchStat<T extends Record<string, unknown>>(stat: string): Promise<T> {
    const urls = {
      count: 'persons/count',
      reachable: 'segments/field_cardinality/breadcrumbs.ttd_id',
      uniqueEmails: 'segments/field_cardinality/email',
      crumbs: 'breadcrumbs/count/',
    };
    try {
      const data = await this.fetchLiveStat<T>(urls[stat], this.getPayload());
      return data;
    } catch (error) {
      throw new Error(`Failed to fetch ${stat}:\n${error}`);
    }
  }

  public async fetchCount(): Promise<number> {
    const { count } = await this.fetchStat<ISegmentFetchCountResponse>('count');

    return count;
  }

  public async fetchReachable(): Promise<number> {
    const { cardinality } =
      await this.fetchStat<ISegmentFetchFieldCardinalityResponse>('reachable');
    return cardinality;
  }

  public async fetchUniqueEmails(): Promise<number> {
    const { cardinality } =
      await this.fetchStat<ISegmentFetchFieldCardinalityResponse>('uniqueEmails');
    return cardinality;
  }

  public async fetchCrumbs(): Promise<number> {
    const { count } = await this.fetchStat<ISegmentFetchCountResponse>('crumbs');
    return count;
  }
}

export default SegmentStatHelper;
