import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';

import type { IGoogleAdsKeyword } from '@feathr/blackbox';
import { Button, Chip } from '@feathr/components';

interface IKeywordButtonProps {
  readonly keyword: IGoogleAdsKeyword;
  readonly onClick?: (keyword: IGoogleAdsKeyword) => void;
  readonly prefix?: ReactNode;
  readonly suffix?: ReactNode;
  readonly name?: string;
}

import * as styles from './KeywordButton.css';

function KeywordButton({
  keyword,
  onClick,
  prefix,
  suffix,
  name,
}: IKeywordButtonProps): JSX.Element {
  function handleKeywordClick(): void {
    onClick?.(keyword);
  }

  return (
    <Button
      className={styles.root}
      name={name ?? keyword.display_name}
      onClick={handleKeywordClick}
      type={'naked'}
    >
      <Chip className={styles.chipFix} prefix={prefix} suffix={suffix} theme={'tag'}>
        {keyword.display_name}
      </Chip>
    </Button>
  );
}

export default observer(KeywordButton);
