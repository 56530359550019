import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type {
  Account,
  Billable,
  EmailListFacebookCampaign,
  Event,
  FacebookCampaign,
  FacebookDisplayCreative,
  Goal,
  IFacebook,
  Segment,
  Targetable,
  Targeting,
  User,
} from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import type { IWizardStepsCompleted } from '@feathr/components';
import {
  Alert,
  AlertType,
  Button,
  Spinner,
  Step,
  Steps,
  useSteps,
  Wizard,
} from '@feathr/components';
import { StoresContext, useAccount, useFlags, useUser } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { ListResponse, Model } from '@feathr/rachis';

import type { ICampaignValidationErrors } from '../../CampaignSummary/CampaignSummary';
import createCampaignStore, { CampaignContext } from '../CampaignEdit/campaignEditContext';
import CampaignEditStepFive, { validateStepFive } from '../CampaignEdit/CampaignEditStepFive';
import CampaignEditStepTwo, {
  getTargetables,
  getTargetSegments,
  validateStepTwo,
} from '../CampaignEdit/CampaignEditStepTwo';
import SaveCampaignButton from '../SaveCampaignButton/index';
import StepGoals, { getGoalSegments, validateStepGoals } from '../StepGoals';
import FacebookCampaignEditStepFour, { validateStepFour } from './FacebookCampaignEditStepFour';
import FacebookCampaignEditStepOne, { validateStepOne } from './FacebookCampaignEditStepOne';
import FacebookCampaignEditStepSeven from './FacebookCampaignEditStepSeven';

interface IProps {
  campaign: FacebookCampaign;
}

interface ICampaignDetails {
  campaign: FacebookCampaign;
  targetings: IObservableArray<Targeting>;
  targetingSegments?: Segment[];
  targetables?: Targetable[];
  goals: IObservableArray<Goal>;
  goalSegments?: Segment[];
  creatives: IObservableArray<FacebookDisplayCreative>;
  billable?: Billable;
  account: Account;
  event: Event;
  integration?: IFacebook;
}

function validate(
  details: ICampaignDetails,
  user?: User,
  noMaxBudget?: boolean,
): ICampaignValidationErrors {
  const targetables =
    details.campaign.get('cls') === CampaignClass.Facebook
      ? details.targetingSegments
      : details.targetables;
  const one = validateStepOne(details.campaign);
  const two = flattenErrors(validateStepTwo(details.campaign, details.targetings, targetables));
  const four = validateStepFour(details.campaign, details.creatives);
  const five = validateStepFive(
    details.campaign,
    details.event,
    details.billable,
    details.account,
    user,
    noMaxBudget,
  );
  const six = validateStepGoals(details.goals, details.goalSegments);
  const facebookIntegrationErrors = details.account.validateFacebookIntegration();
  return {
    name: one,
    targets: two,
    creatives: four,
    duration: [...(five.date_end ?? []), ...(five.date_end ?? [])],
    budget: five.budget,
    goals: six.goals,
    facebook: facebookIntegrationErrors.map((e) => e.message),
  };
}

function FacebookCampaignEdit({ campaign }: IProps): JSX.Element {
  const account = useAccount();
  const {
    Creatives,
    Billables,
    Events,
    Goals,
    Segments,
    Targetings,
    Targetables,
    FacebookCampaignIntegrations,
  } = React.useContext(StoresContext);
  const { t } = useTranslation();
  const flags = useFlags();
  const user = useUser();

  const foundStep = /#step(\d+)$/.exec(location.hash);
  const defaultStep = foundStep ? +foundStep[1] - 1 : undefined;
  const [currentStep, setCurrentStep] = useState<number>(defaultStep ?? 0);
  const [completeStep, setCompleteStep] = useState<number>(defaultStep ?? 0);
  const event = Events.get(campaign.get('event'));
  const billing = event.get('billing');
  const billable =
    !event.isPending && billing?.billable_id ? Billables.get(billing.billable_id) : undefined;

  const targetings = Targetings.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const creatives = Creatives.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  }) as ListResponse<FacebookDisplayCreative>;

  const integration = account.get('facebook', {} as IFacebook);
  const facebookCampaignIntegration = FacebookCampaignIntegrations.get(campaign.id);

  const getCompletedStepMap = (): IWizardStepsCompleted => {
    const goalSegments = getGoalSegments(goals.models, Segments);
    const five = validateStepFive(campaign, event, billable, account, user, flags.noMaxBudget);

    return {
      0: !validateStepOne(campaign).length,
      1: !(
        targetings.models.length === 0 ||
        validateStepTwo(campaign, targetings.models, targetingSegments).length
      ),
      2: !validateStepFour(campaign, creatives.models).length,
      3: !flattenErrors(five).length,
      4: !validateStepGoals(goals.models, goalSegments).length,
      5: true,
    };
  };
  const waitFor: () => boolean = () => {
    return (
      !!billing?.billable_id &&
      !!billable &&
      !(
        campaign.isPending ||
        creatives.isPending ||
        event.isPending ||
        facebookCampaignIntegration.isPending ||
        goals.isPending ||
        targetings.isPending ||
        billable.isPending
      )
    );
  };
  useSteps(
    {
      getCompletedStepMap,
      waitFor,
      setCurrentStep,
      setCompleteStep,
      skipToMaxStep: [
        CampaignState.Published,
        CampaignState.Publishing,
        CampaignState.Stopped,
      ].includes(campaign.get('state', CampaignState.Draft)),
    },
    [billable, integration],
  );

  function onNext(): void {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    if (nextStep > completeStep) {
      setCompleteStep(nextStep);
    }
  }

  function onPrev(): void {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
  }

  if (
    event.isPending ||
    !billable ||
    billable?.isPending ||
    targetings.isPending ||
    goals.isPending ||
    creatives.isPending ||
    account?.isPending ||
    (facebookCampaignIntegration.isPending && !facebookCampaignIntegration.isErrored) ||
    completeStep === undefined
  ) {
    return <Spinner />;
  }

  const stepsItems = [
    <Step key={0} stepIndex={0} title={t('Name and Objective')} />,
    <Step key={1} stepIndex={1} title={t('Targets')} />,
    <Step key={2} stepIndex={2} title={t('Creatives')} />,
    <Step key={3} stepIndex={3} title={t('Budget')} />,
    <Step key={4} stepIndex={4} title={t('Conversion Tracking')} />,
    <Step key={5} stepIndex={5} title={t('Review')} />,
  ];
  const steps = (
    <Steps current={currentStep} initialCompleted={completeStep} onChange={setCurrentStep}>
      {stepsItems}
    </Steps>
  );

  const targetables = getTargetables(targetings.models, Targetables);
  const targetingSegments = getTargetSegments(targetings.models, Segments);

  const grandchildModels: Model[] = [
    ...targetingSegments,
    ...getGoalSegments(goals.models, Segments),
    ...targetables,
  ];
  // Converting observables back to vanilla JavaScript.
  const childModels: Model[] = [
    ...targetings.models.slice(),
    ...creatives.models.slice(),
    ...goals.models.slice(),
  ];
  if (targetables.length) {
    grandchildModels.push(...targetables);
  }
  if (targetingSegments.length) {
    grandchildModels.push(...targetingSegments);
  }

  function buttonValidate(): ICampaignValidationErrors {
    const goalSegments = getGoalSegments(goals.models, Segments);
    return validate(
      {
        account,
        billable,
        campaign,
        creatives: creatives.models,
        event,
        goals: goals.models,
        goalSegments,
        targetables,
        targetings: targetings.models,
        targetingSegments,
        integration: integration,
      },
      user,
      flags.noMaxBudget,
    );
  }

  const actions = [
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      grandchildModels={grandchildModels}
      key={'save'}
      name={'save_changes'}
      shouldChangeState={false}
      validate={
        [CampaignState.Published, CampaignState.Publishing].includes(campaign.get('state'))
          ? buttonValidate
          : undefined
      }
    />,
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      // Disabled publish if not all steps are completed.
      disabled={completeStep < 5 || undefined}
      grandchildModels={grandchildModels}
      key={'changeState'}
      name={'publish_or_stop'}
      shouldChangeState={true}
      validate={buttonValidate}
    />,
  ];
  const facebookIntegrationErrors = account?.validateFacebookIntegration() ?? [];
  const upsyncError = facebookCampaignIntegration?.getUpsyncErrorData();
  const upsyncErrorMessage = upsyncError ? upsyncError.message : undefined;

  const campaignStore = createCampaignStore({ targetings });

  return (
    <CampaignContext.Provider value={campaignStore}>
      <div data-appcues-campaign={campaign.get('_cls')}>
        {facebookIntegrationErrors.length > 0 &&
          facebookIntegrationErrors.map((error, i) => (
            <Alert key={i} type={AlertType.danger}>
              <Trans t={t}>
                {{ message: error.message }}
                <br />
                Please{' '}
                <Button href={error.link} target={'_blank'} type={'link'}>
                  resolve this issue
                </Button>{' '}
                to be able to publish your campaign.
              </Trans>
            </Alert>
          ))}
        {upsyncError && (
          <Alert key={'upsync'} type={AlertType.danger}>
            <>
              {t('Meta Sync Error: {{- message}}', { message: upsyncErrorMessage })}
              {upsyncError.link && (
                <Trans t={t}>
                  Please{' '}
                  <Button href={upsyncError.link} target={'_blank'} type={'link'}>
                    resolve this issue
                  </Button>{' '}
                  and then re-publish your campaign.
                </Trans>
              )}
            </>
          </Alert>
        )}
        <Wizard actions={actions} steps={steps}>
          {currentStep === 0 && (
            <FacebookCampaignEditStepOne
              campaign={campaign as FacebookCampaign | EmailListFacebookCampaign}
              onNext={onNext}
            />
          )}
          {currentStep === 1 && (
            <CampaignEditStepTwo
              campaign={campaign}
              onNext={onNext}
              onPrev={onPrev}
              targetings={targetings.models}
            />
          )}
          {currentStep === 2 && (
            <FacebookCampaignEditStepFour
              campaign={campaign as FacebookCampaign | EmailListFacebookCampaign}
              creatives={creatives.models}
              onNext={onNext}
              onPrev={onPrev}
            />
          )}
          {currentStep === 3 && (
            <CampaignEditStepFive
              account={account}
              billable={billable}
              campaign={campaign}
              event={event}
              onNext={onNext}
              onPrev={onPrev}
              targetings={targetings.models}
            />
          )}
          {currentStep === 4 && (
            <StepGoals campaign={campaign} goals={goals.models} onNext={onNext} onPrev={onPrev} />
          )}
          {currentStep === 5 && (
            <FacebookCampaignEditStepSeven
              account={account}
              billable={billable}
              campaign={campaign}
              creatives={creatives.models}
              event={event}
              goals={goals.models}
              integration={integration}
              onPrev={onPrev}
              targets={targetings.models}
              validate={validate}
            />
          )}
        </Wizard>
      </div>
    </CampaignContext.Provider>
  );
}

export default observer(FacebookCampaignEdit);
