import { ObjectId } from 'bson';
import cloneDeep from 'lodash.clonedeep';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import type { ActionMeta, ValueType } from 'react-select';

import type { Billable, Event, IEvent } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { AsyncSelect, DatePicker, Select } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';
import type { IListParams } from '@feathr/rachis';

import * as styles from './InvoicesFilters.css';

type TDatePostedQuery = {
  date_posted: {
    $gt?: Date;
    $lt?: Date;
  };
};

type TDateStartQuery = {
  'period.start': {
    $gt: Date;
  };
};

type TDateEndQuery = {
  'period.end': {
    $lt: Date;
  };
};

type TDateQuery = {
  $or: Array<TDatePostedQuery | TDateStartQuery | TDateEndQuery>;
};

export interface IInvoiceFilters extends Record<string, unknown> {
  __raw__: {
    account?: string;
    event_id?: string | Record<string, string[]>;
    billable?: string | Record<string, string[]>;
    period?: Record<string, Record<string, string> | undefined>;
    _cls?: string;
    $and?: TDateQuery[];
  };
}

export interface IInvoicesFiltersProps {
  filters: IInvoiceFilters;
  setFilters: (newFilters: IInvoiceFilters) => void;
}

function InvoicesFilters({ filters, setFilters }: IInvoicesFiltersProps): JSX.Element {
  const { Billables, Events } = useContext(StoresContext);

  const clsOptions: ISelectOption[] = [
    { name: 'All Invoices', id: '' },
    { name: 'Media Spend Invoices', id: 'Bill.MediaBill' },
    { name: 'Platform Invoices', id: 'Bill.PlatformBill' },
    { name: 'Prepaid Media Invoices', id: 'Bill.PrepaidMediaBill' },
  ];

  async function loadEventOptions(inputValue: string): Promise<Event[]> {
    const params: IListParams<IEvent> = {
      only: ['id', 'logo', 'name'],
      ordering: ['name'],
    };
    if (inputValue.length) {
      params.filters = {
        name__icontains: inputValue,
      };
    }
    const events = Events.list(params);
    await when(() => !events.isPending);
    // Converting observables back to vanilla JavaScript.
    return events.models.slice();
  }

  async function loadBillableOptions(inputValue: string): Promise<Billable[]> {
    const billables = Billables.list({
      filters: {
        name__icontains: inputValue || undefined,
      },
    });
    await when(() => !billables.isPending);
    // Converting observables back to vanilla JavaScript.
    return billables.models.slice();
  }

  function handleEventChange(newValue: ValueType<Event>, action: ActionMeta<Event>): void {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (Array.isArray(newValue)) {
        if (newValue.length) {
          setFilters({
            ...filters,
            __raw__: {
              ...filters.__raw__,
              event_id: { $in: newValue.map((value) => new ObjectId(value.id).toHexString()) },
            },
          });
        } else {
          setFilters({
            ...filters,
            __raw__: {
              ...filters.__raw__,
              event_id: undefined,
            },
          });
        }
      } else if (newValue) {
        setFilters({
          ...filters,
          __raw__: {
            ...filters.__raw__,
            event_id: (newValue as Event).id,
          },
        });
      } else {
        setFilters({
          ...filters,
          __raw__: {
            ...filters.__raw__,
            event_id: undefined,
          },
        });
      }
    }
  }

  function handleBillableChange(newValue: ValueType<Billable>, action: ActionMeta<Billable>): void {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (Array.isArray(newValue)) {
        if (newValue.length) {
          setFilters({
            ...filters,
            __raw__: {
              ...filters.__raw__,
              billable: { $in: newValue.map((value) => new ObjectId(value.id).toHexString()) },
            },
          });
        } else {
          setFilters({
            ...filters,
            __raw__: {
              ...filters.__raw__,
              billable: undefined,
            },
          });
        }
      } else if (newValue) {
        setFilters({
          ...filters,
          __raw__: {
            ...filters.__raw__,
            billable: new ObjectId((newValue as Billable).id).toHexString(),
          },
        });
      } else {
        setFilters({
          ...filters,
          __raw__: {
            ...filters.__raw__,
            billable: undefined,
          },
        });
      }
    }
  }

  function handleClearStart(): void {
    // Get key of start date filter
    const startFilterKey: number | undefined = filters.__raw__.$and?.findIndex((obj) =>
      obj.$or?.some((or) => or['period.start']),
    );
    const hasStartFilter = startFilterKey !== undefined && startFilterKey !== -1;

    if (!hasStartFilter) {
      return;
    }

    setFilters({
      ...filters,
      __raw__: {
        ...filters.__raw__,
        // Remove start date filter from $and using startFilterKey
        $and: filters.__raw__.$and!.filter((obj) => obj !== filters.__raw__.$and![startFilterKey]),
      },
    });
  }

  function handleClearEnd(): void {
    // Get key of end date filter
    const endFilterKey: number | undefined = filters.__raw__.$and?.findIndex((obj) =>
      obj.$or?.some((or) => or['period.end']),
    );
    const hasEndFilter = endFilterKey !== undefined && endFilterKey !== -1;

    if (!hasEndFilter) {
      return;
    }

    setFilters({
      ...filters,
      __raw__: {
        ...filters.__raw__,
        // Remove end date filter from $and using endFilterKey
        $and: filters.__raw__.$and!.filter((obj) => obj !== filters.__raw__.$and![endFilterKey]),
      },
    });
  }

  function handleDateStrChangeStart(date?: string): void {
    if (date) {
      // Define start date filter params
      const params: TDateQuery = {
        $or: [
          { 'period.start': { $gt: new Date(date) } },
          { date_posted: { $gt: new Date(date) } },
        ],
      };

      // Get key of start date filter
      const startFilterKey: number | undefined = filters.__raw__.$and?.findIndex((obj) =>
        obj.$or?.some((or) => or['period.start']),
      );
      const hasStartFilter = startFilterKey !== undefined && startFilterKey !== -1;

      const newFilters = cloneDeep(filters);
      if (!newFilters.__raw__.$and) {
        newFilters.__raw__.$and = [];
      }
      // If start date filter already exists, replace it, else add it
      if (hasStartFilter) {
        newFilters.__raw__.$and[startFilterKey] = params;
      } else {
        newFilters.__raw__.$and.unshift(params);
      }

      setFilters(newFilters);
    }
  }

  function handleDateStrChangeEnd(date?: string): void {
    if (date) {
      const params: TDateQuery = {
        $or: [{ 'period.end': { $lt: new Date(date) } }, { date_posted: { $lt: new Date(date) } }],
      };

      // Get key of end date filter
      const endFilterKey: number | undefined = filters.__raw__.$and?.findIndex((obj) =>
        obj.$or?.some((or) => or['period.end']),
      );
      const hasEndFilter = endFilterKey !== undefined && endFilterKey !== -1;

      const newFilters = cloneDeep(filters);
      if (!newFilters.__raw__.$and) {
        newFilters.__raw__.$and = [];
      }
      // If end date filter already exists, replace it, else add it
      if (hasEndFilter) {
        newFilters.__raw__.$and[endFilterKey] = params;
      } else {
        newFilters.__raw__.$and.push(params);
      }

      setFilters(newFilters);
    }
  }

  function handleSelectType(option: ISelectOption): void {
    setFilters({
      ...filters,
      __raw__: { ...filters.__raw__, _cls: option.id || undefined },
    });
  }

  function dateFromQuery(key: 'period.start' | 'period.end'): Date | undefined {
    if (!filters.__raw__.$and) {
      return undefined;
    }
    // Find the query that contains the correct key
    const query = filters.__raw__.$and.find((obj) => obj.$or?.some((or) => or[key]));
    if (!query) {
      return undefined;
    }
    // Find the date in the query
    const date = query.$or?.find((obj) => obj[key]);
    if (date) {
      return date[key].$gt || date[key].$lt;
    }
    return undefined;
  }

  return (
    <div className={styles.container}>
      <DatePicker
        format={TimeFormat.isoDateTime}
        isClearable={true}
        key={'start'}
        label={'Date range'}
        onClear={handleClearStart}
        onDateStrChange={handleDateStrChangeStart}
        placeholder={'Select start'}
        value={dateFromQuery('period.start')?.toISOString()}
      />
      <DatePicker
        format={TimeFormat.isoDateTime}
        isClearable={true}
        key={'end'}
        label={' '}
        onClear={handleClearEnd}
        onDateStrChange={handleDateStrChangeEnd}
        placeholder={'Select end'}
        value={dateFromQuery('period.end')?.toISOString()}
      />
      <Select
        defaultValue={clsOptions[3]}
        key={'cls'}
        label={'Type of invoice'}
        onSelectSingle={handleSelectType}
        options={clsOptions}
        value={clsOptions.find((opt) => {
          return opt.id === (filters.__raw__._cls ?? '');
        })}
        wrapperClassName={styles.select}
      />
      <AsyncSelect<Event>
        defaultOptions={true}
        isClearable={true}
        isMulti={true}
        key={'project'}
        label={'Projects'}
        loadOptions={loadEventOptions}
        onChange={handleEventChange}
        // TODO: Set initial value from passed in filters
        wrapperClassName={styles.select}
      />
      <AsyncSelect<Billable>
        defaultOptions={true}
        isClearable={true}
        isMulti={true}
        key={'billing'}
        label={'Billing configurations'}
        loadOptions={loadBillableOptions}
        onChange={handleBillableChange}
        wrapperClassName={styles.select}
      />
    </div>
  );
}

export default observer(InvoicesFilters);
