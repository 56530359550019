import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { isWretchError } from '@feathr/rachis';
import { Collection, Model, wretch } from '@feathr/rachis';

import type { IFacebook } from './accounts';

export interface IInstagram {
  /** The Instagram account ID of the page-connected Instagram account */
  instagram_actor_id: string;
  /** The permission to get the Instagram account ID. Requires the "pages_read_engagement" permission */
  invalid_permission: boolean;
  /** The Instagram username */
  username: string;
}

export interface IFacebookPage extends IBaseAttributes {
  name: string;
  picture: string;
  link: string;
  instagram: IInstagram;
  feathr_has_agency: boolean;
  user_is_page_admin: boolean;
  user_is_business_admin: boolean;
  business: Record<string, string>;
}
export class FacebookPage extends Model<IFacebookPage> {
  public readonly className = 'FacebookPage';

  public constraints: TConstraints<IFacebookPage> = {};

  public async toggleAccess(): Promise<IFacebook> {
    if (!this.collection || !this.id) {
      throw new Error('Page is not in collection');
    }
    this.isUpdating = true;
    const hasAgency = this.attributes.feathr_has_agency;
    const method = hasAgency ? 'DELETE' : 'POST';
    const url = `${this.collection.url()}${this.id}`;
    const response = await wretch<IFacebook>(url, {
      method,
      headers: this.collection.getHeaders(),
    });
    if (isWretchError(response)) {
      this.error = response.error;
      throw response.error;
    }
    this.isUpdating = false;
    this.set({ feathr_has_agency: !hasAgency });
    return response.data;
  }
}

export class FacebookPages extends Collection<FacebookPage> {
  public getModel(attributes: Partial<IFacebookPage>): FacebookPage {
    return new FacebookPage(attributes);
  }

  public getClassName(): string {
    return 'pages';
  }

  public url(): string {
    return `${this.getHostname()}integrations/facebook/pages/`;
  }
}
