import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import type { ColumnRenderProps } from 'react-table';

import type { BaseCampaign, IStats } from '@feathr/blackbox';
import type { Campaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function getSeen(stats: IStats): number {
  const flavors = stats.flavors || {};
  return (flavors.conversation_opened || 0) + (flavors.conversation_popped || 0);
}

const SeenColumn: IColumn<BaseCampaign> = {
  id: 'stats__flavors__conversation_popped',
  checkboxLabel: 'Seen',
  Header: TableColumnHeader({
    title: 'Seen',
  }),
  className: tableStyles.cellRight,
  width: 70,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const seen = getSeen(original.get('total_stats'));
          return <>{seen.toLocaleString()}</>;
        }}
      </Observer>
    );
  },
  Footer({ data }: ColumnRenderProps<{ _original: Campaign }>): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const total = data.reduce((subtotal, currentValue) => {
            const seen = getSeen(currentValue._original.get('total_stats'));
            return subtotal + seen;
          }, 0);
          return <>{total.toLocaleString()}</>;
        }}
      </Observer>
    );
  },
};

export default SeenColumn;
