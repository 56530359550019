import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React, { Fragment } from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { IFacebookPage } from '@feathr/blackbox';

import * as styles from './FacebookPageOption.css';

function FacebookPageOption(props: OptionProps<IFacebookPage>): JSX.Element {
  const {
    data: {
      id,
      instagram: { instagram_actor_id: instagramActorId, username: instagramUsername },
      name,
    },
  } = props;

  return (
    <components.Option {...props}>
      <Fragment key={id}>
        <div className={styles.root}>
          <div>
            <FontAwesomeIcon icon={faFacebook} />
            {name}
          </div>
          {!!instagramActorId && (
            <div className={styles.instagramIndicator}>
              <FontAwesomeIcon icon={faInstagram} /> {instagramUsername}
            </div>
          )}
        </div>
      </Fragment>
    </components.Option>
  );
}

export default FacebookPageOption;
