import { ObjectId } from 'bson';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { DataRequest, IRequestField } from '@feathr/blackbox';
import { FieldDataType } from '@feathr/blackbox';
import { Button, ButtonValid, Fieldset, Form } from '@feathr/components';

import RequestField from './RequestField';

interface IProps {
  dataRequest: DataRequest;
  onNext: () => void;
  onPrevious: () => void;
}

export function validateStepThree(dataRequest: DataRequest): string[] {
  function validateRequestField(requestField: IRequestField): string | undefined {
    if (!requestField.field_name) {
      return 'All fields must have a form field to map from.';
    }
    if (!requestField.feathr_attr) {
      return `"${requestField.field_name}" must map to a Feathr field.`;
    }
    return undefined;
  }

  const fields = dataRequest.get('request_fields') || [];
  return fields.map(validateRequestField).filter((error?: string) => !!error) as string[];
}

const NextStepButton = observer(
  ({ onNext, dataRequest }: Omit<IProps, 'onPrevious'>): JSX.Element => {
    const { t } = useTranslation();
    const validationErrors = validateStepThree(dataRequest);
    return (
      <ButtonValid errors={validationErrors} key={'nextButton'} onClick={onNext}>
        {t('Next')}
      </ButtonValid>
    );
  },
);

function DataRequestFormStepThree({
  dataRequest,
  onPrevious,
  onNext,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const fields = dataRequest.get('request_fields');

  return (
    <Form
      actions={[
        <Button key={'previous'} onClick={onPrevious}>
          {t('Previous')}
        </Button>,
        <Button
          key={'add'}
          onClick={() => {
            const field: IRequestField = observable({
              id: new ObjectId().toHexString(),
              field_name: '',
              attr_type: FieldDataType.str,
              feathr_attr: '',
              request_type: 'activity',
            });
            dataRequest.get('request_fields').push(field);
          }}
          type={'primary'}
        >
          {t('Add Data')}
        </Button>,
        <NextStepButton dataRequest={dataRequest} key={'next'} onNext={onNext} />,
      ]}
      description={
        <>
          <p>
            {t(
              'Here please list the fields you want to capture from your form or registration flow. The most common fields are selected for you.',
            )}
          </p>
          <p>
            {t(
              `You can remove any of the default fields you don't need by clicking the "trash" icon on the card representing that field. Add any other fields using the Add Data button and configure them as necessary.`,
            )}
          </p>
        </>
      }
      label={'Edit Message: Name'}
    >
      <Fieldset>
        {fields.map((requestField, i) => (
          <RequestField
            key={requestField.id || i}
            request={dataRequest}
            requestField={requestField}
          />
        ))}
      </Fieldset>
    </Form>
  );
}

export default observer(DataRequestFormStepThree);
