import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { FacebookPage } from '@feathr/blackbox';
import { Button, toast, Tooltip } from '@feathr/components';
import { useToggle } from '@feathr/hooks';

interface IProps {
  page: FacebookPage;
}

function ManagePageAgencyButton({ page }: IProps): JSX.Element {
  const { t } = useTranslation();
  const isBusinessPage = Object.keys(page.get('business')).length;
  const isPageAdmin = page.get('user_is_page_admin', false);
  const isBusinessAdmin = page.get('user_is_business_admin', false);
  const [disabled, setDisabled] = useState(
    (isBusinessPage ? !(isPageAdmin && isBusinessAdmin) : !isPageAdmin) || page.isUpdating,
  );
  const [allowsAgency, toggleAllowsAgency] = useToggle(page.get('feathr_has_agency'));
  const buttonText = allowsAgency ? t('Revoke') : t('Grant');
  const buttonType = allowsAgency ? 'danger' : 'secondary';

  async function handleAgencyChange(): Promise<void> {
    try {
      await page.toggleAccess();
      toggleAllowsAgency();
      toast(
        allowsAgency
          ? t('Access to this Facebook Page has been revoked')
          : t('Access to this Facebook Page has been granted'),
        { type: ToastType.INFO },
      );
    } catch (e) {
      page.isUpdating = false;
      setDisabled(true);
      toast(
        t(
          'There was a problem connecting to your page. Our Support team has been notified and they will email you to help resolve the problem.',
        ),
        {
          type: ToastType.ERROR,
        },
      );
      throw e;
    }
  }

  function getTooltip(): string | undefined {
    if (page.isUpdating) {
      return 'Please wait, changes are being made.';
    }

    if (isBusinessPage) {
      if (!isPageAdmin && !isBusinessAdmin) {
        return 'You are neither an admin of this page nor an admin of the business that owns this page.';
      } else if (isPageAdmin && !isBusinessAdmin) {
        return 'You are an admin of this page, but not an admin of the business that owns this page.';
      } else if (!isPageAdmin && isBusinessAdmin) {
        return 'You are an admin of the business that owns this page, but not an admin of this page.';
      }
    } else {
      if (!isPageAdmin) {
        return 'You are not an admin of the page.';
      }
    }

    return undefined;
  }

  const title = getTooltip();
  const button = (
    <Button
      disabled={disabled}
      isLoading={page.isUpdating}
      onClick={handleAgencyChange}
      type={buttonType}
    >
      {buttonText}
    </Button>
  );

  return title ? <Tooltip title={title}>{button}</Tooltip> : button;
}

export default observer(ManagePageAgencyButton);
