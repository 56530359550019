import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Billable } from '@feathr/blackbox';
import { CardV2 as Card, Checkbox } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';

import * as styles from './BillingConfigurationUsageCard.css';

interface IProps {
  billable: Billable;
  isPrimary: boolean;
  setPrimary: (value: boolean) => void;
}

function BillingConfigurationUsageCard({
  billable,
  isPrimary,
  setPrimary,
}: Readonly<IProps>): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();

  function handleChangePrimary(value?: boolean): void {
    setPrimary(!!value);
  }

  return (
    <Card>
      <Card.Header title={t('Usage')} />
      <Card.Content>
        <Checkbox
          // Don't allow unsetting if this is the primary billing configuration
          disabled={!!account.primaryBillableId && billable.id === account.primaryBillableId}
          helpPlacement={'bottom'}
          helpText={t(
            'This setup will serve as the billing configuration for your Feathr license and as the primary billing configuration for all media bills. Any existing primary billing configurations will be moved to additional billing configurations.',
          )}
          label={t('Set billing configuration as primary')}
          labelClassName={styles.label}
          onChange={handleChangePrimary}
          value={isPrimary}
          wrapperClassName={styles.formElement}
        />
      </Card.Content>
    </Card>
  );
}

export default observer(BillingConfigurationUsageCard);
