import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { ITTDSubAdvertiser } from '@feathr/blackbox';
import { TableColumnHeader } from '@feathr/components';

import AdvertiserOptionsCell from './AdvertiserOptionsCell';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './AdvertisersColumns.css';

import noImg from 'images/no-img.png';

interface IRow {
  original: ITTDSubAdvertiser;
}

const AdvertisersColumns = [
  {
    id: 'logo',
    Header: TableColumnHeader({
      title: 'Logo',
    }),
    width: 64,
    sortable: false,
    className: tableStyles.cellCenter,
    Cell(row: IRow): JSX.Element {
      const logo = row.original.logo || noImg;
      return <div className={styles.logo} style={{ backgroundImage: `url('${logo}')` }} />;
    },
  },
  {
    id: 'name',
    Header: TableColumnHeader({
      sortType: 'alpha',
      title: 'Name',
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    Cell({ original }: IRow): JSX.Element {
      return (
        <Observer>
          {function useAnonymousFunction() {
            return <span>{original.name}</span>;
          }}
        </Observer>
      );
    },
  },
  {
    id: 'url',
    Header: TableColumnHeader({
      sortType: 'numeric',
      title: 'Website URL',
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    Cell({ original }: IRow): JSX.Element {
      return (
        <Observer>
          {function useAnonymousFunction() {
            return (
              <a href={original.url} rel={'noopener'} target={'_blank'}>
                {original.url}
              </a>
            );
          }}
        </Observer>
      );
    },
  },
  {
    id: 'options',
    Header: TableColumnHeader({
      title: 'Options',
    }),
    className: styles.root,
    width: 80,
    sortable: false,
    Cell(row: IRow): JSX.Element {
      return <AdvertiserOptionsCell {...row} />;
    },
  },
];

export default AdvertisersColumns;
