import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TIntegrationDataType } from '@feathr/blackbox';
import {
  iMISFieldDataTypeLabelMap,
  templateFieldDataTypeIcon,
} from '@feathr/extender/styles/integrations';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IiMISDataTypeProps {
  dataType: TIntegrationDataType;
}

function ImisDataType({ dataType }: IiMISDataTypeProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <p>
      <FontAwesomeIcon icon={templateFieldDataTypeIcon(dataType)} />{' '}
      {iMISFieldDataTypeLabelMap(t, dataType)}
    </p>
  );
}

export default ImisDataType;
