import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ToastType } from 'react-toastify';

import type { IModalV1Props } from '@feathr/components';
import { Fieldset, Input, ModalV1, toast } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

function CreateRoleModal({ onClose }: Readonly<Pick<IModalV1Props, 'onClose'>>): JSX.Element {
  const { t } = useTranslation();
  const [roleName, setRoleName] = useState<string>('');
  const localUrl = useLocalUrl();
  const history = useHistory();

  function handleConfirm(): void {
    try {
      const encodedRoleName = encodeURIComponent(roleName);
      history.push(localUrl(`/settings/account/users/roles/create?name=${encodedRoleName}`));
    } catch (error) {
      toast(t('Failed to create {{roleName}} role. {{- error}}', { roleName, error }), {
        type: ToastType.ERROR,
      });
    }
  }

  function handleOnChangeRoleName(newValue?: string): void {
    setRoleName(newValue ?? '');
  }

  return (
    <ModalV1
      confirmButtonText={t('Create')}
      confirmButtonType={'primary'}
      confirmDisabled={!roleName}
      controlled={true}
      onClose={onClose}
      onConfirm={handleConfirm}
      size={'sm'}
      t={t}
      title={t('Create Role')}
    >
      <Fieldset>
        <Input
          isClearable={true}
          label={t('Role name')}
          name={'role-name-input'}
          onChange={handleOnChangeRoleName}
          type={'text'}
          value={roleName}
        />
      </Fieldset>
    </ModalV1>
  );
}

export default CreateRoleModal;
