import type { JSX } from 'react';
import React from 'react';

import type {
  IBannerTemplateEditorProps,
  ITemplateEditorProps,
} from '@feathr/extender/components/TemplateEditor';
import {
  BannerTemplateEditor,
  BeeTemplateEditor,
} from '@feathr/extender/components/TemplateEditor';
import { BeeEditorProvider } from '@feathr/extender/components/TemplateEditor/BeeTemplateEditor';

import * as styles from './TemplateSelectAndEditModalEditor.css';

function TemplateSelectAndEditModalEditor(props: Readonly<IBannerTemplateEditorProps>): JSX.Element;
function TemplateSelectAndEditModalEditor(props: Readonly<ITemplateEditorProps>): JSX.Element;
function TemplateSelectAndEditModalEditor({
  template,
}: Readonly<ITemplateEditorProps> | Readonly<IBannerTemplateEditorProps>): JSX.Element {
  const isBannersnackBannerTemplate = template.isBanner && template.get('bannersnack_enabled');

  if (isBannersnackBannerTemplate) {
    return <BannerTemplateEditor className={styles.editor} template={template} />;
  }

  return (
    <BeeEditorProvider template={template}>
      <BeeTemplateEditor className={styles.editor} template={template} />
    </BeeEditorProvider>
  );
}

export default TemplateSelectAndEditModalEditor;
