import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input } from '@feathr/components';

import * as styles from './InputListItem.css';

interface IInputListItemProps {
  item: string;
  index: number;
  onChange: (index: number, value: string) => void;
  onRemove: (index: number) => void;
  removeTooltip?: ReactNode;
}

function InputListItem({
  index,
  item,
  onChange,
  onRemove,
  removeTooltip,
}: IInputListItemProps): JSX.Element {
  const { t } = useTranslation();

  function handleRemove(): void {
    onRemove(index);
  }

  function handleChange(newValue?: string): void {
    onChange(index, newValue ?? '');
  }

  return (
    <div className={styles.root}>
      <Input data-name={`item-${index}`} onChange={handleChange} value={item} />
      <Button
        aria-label={'remove button'}
        name={`item-${index}-remove`}
        onClick={handleRemove}
        tooltip={removeTooltip ?? t('Remove')}
        type={'icon-outlined'}
      >
        <FontAwesomeIcon className={styles.deleteIcon} icon={faTrash} />
      </Button>
    </div>
  );
}

export default observer(InputListItem);
