import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Font } from '@feathr/blackbox';
import { Button, DebouncedInput, Input, Table } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import columns from './FontsColumns';

function FontsPage() {
  const { Fonts } = useContext(StoresContext);
  const localUrl = useLocalUrl();
  const [search, setSearch] = useState<string | undefined>();
  const { t } = useTranslation();

  function handleDebouncedSearchChange(newValue?: string) {
    setSearch(newValue);
  }

  const filterElements = [
    ,
    <>
      <DebouncedInput<string> defaultValue={search} onChange={handleDebouncedSearchChange}>
        {(liveValue, onChangeLiveValue) => (
          <Input
            isClearable={true}
            onChange={onChangeLiveValue}
            placeholder={t('Search by name...')}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
    </>,
  ];

  const filters = search ? { name__icontains: search } : undefined;

  return (
    <Page
      actions={[
        <Button key={'add'} link={localUrl('/settings/account/fonts/add')} type={'primary'}>
          {t('Add font')}
        </Button>,
      ]}
      description={t('Fonts can be used in banner, email, and page templates.')}
      title={t('Fonts')}
    >
      <Table<Font>
        collection={Fonts}
        columns={columns}
        filterElements={filterElements}
        filters={filters}
        initialPagesize={10}
        initialSort={[{ id: 'name' }]}
        isPaginated={true}
        noDataText={t('No fonts')}
      />
    </Page>
  );
}

export default FontsPage;
