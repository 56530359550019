import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign, Campaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function weightedAvg(
  { rateSum, sendSum },
  currentValue: { _original: Campaign },
): { rateSum: number; sendSum: number } {
  const stats = currentValue._original.get('total_stats') || {};
  const rate = stats.hard_bounce_rate || 0;
  const flavors = stats.flavors || {};
  const sends = flavors.pinpoint_tracked_email_send || 0;
  return {
    rateSum: rateSum + rate * sends,
    sendSum: sendSum + sends,
  };
}

const HardBounceRateColumn: IColumn<BaseCampaign> = {
  id: 'stats__hard_bounce_rate',
  checkboxLabel: 'Hard Bounce Rate',
  Header: TableColumnHeader({
    title: 'Hard Bounce Rate',
    sortType: 'numeric',
  }),
  headerClassName: tableStyles.sort,
  width: 170,
  className: tableStyles.cellRight,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const stats = original.get('total_stats') || {};
          const rate = stats.hard_bounce_rate || 0;
          return <>{numeral(rate).format('0%')}</>;
        }}
      </Observer>
    );
  },
  Footer({ data }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const { rateSum, sendSum } = data.reduce(weightedAvg, { rateSum: 0, sendSum: 0 });
          const avg = sendSum > 0 ? rateSum / sendSum : 0;
          return <>{numeral(avg).format('0.00%')}</>;
        }}
      </Observer>
    );
  },
};

export default HardBounceRateColumn;
