import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';

import type { FacebookCampaign, FacebookDisplayCreative, IFacebookPage } from '@feathr/blackbox';
import { StoresContext } from '@feathr/extender/state';
import { moment } from '@feathr/hooks';

import FacebookCreative from '../FacebookCreative';

interface IListProps {
  creatives: IObservableArray<FacebookDisplayCreative>;
  campaign: FacebookCampaign;
  page?: IFacebookPage;
}

function FacebookCreativesList({ creatives, campaign, page }: IListProps): JSX.Element {
  const { Creatives } = useContext(StoresContext);

  function removeCreative(creative: FacebookDisplayCreative): void {
    if (creative.isEphemeral) {
      creatives.remove(creative);
      Creatives.remove(creative.get('id'));
    } else {
      creative.set({ is_archived: true });
      creative.patchDirty();
    }
  }

  return (
    <>
      {creatives
        .filter((c) => !c.get('is_archived'))
        .sort((a, b) => moment.utc(a.get('date_created')).diff(moment.utc(b.get('date_created'))))
        .map((creative) => {
          return (
            <FacebookCreative
              campaign={campaign}
              creative={creative}
              creatives={creatives}
              key={creative.listId}
              onRemove={removeCreative}
              page={page}
            />
          );
        })}
    </>
  );
}

export default observer(FacebookCreativesList);
