import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ImisIntegration } from '@feathr/blackbox';
import { Time } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import * as styles from './ImisLastSync.css';

interface IProps {
  integration?: ImisIntegration;
}

function ImisLastSync({ integration }: IProps): JSX.Element | null {
  const { t } = useTranslation();
  const dateLastSynced: string | undefined = integration ? integration.lastSynced : undefined;

  return (
    <div className={styles.content}>
      {dateLastSynced && (
        <>
          {t('Last synced')} <Time format={TimeFormat.timeFromNow} timestamp={dateLastSynced} />
        </>
      )}
    </div>
  );
}

export default ImisLastSync;
