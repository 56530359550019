import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { IDailyStats, TAttributionModel } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Time } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

export interface IComputedDailyStats extends IDailyStats {
  views: number;
  clicks: number;
}

export interface IRowOutput extends Record<string, unknown> {
  spend: number;
  conversions__full__num: number;
  conversions__linear__num: number;
  conversions__positional__num: number;
  conversions__first_touch__num: number;
  conversions__last_touch__num: number;
  conversions__temporal__num: number;
  views: number;
  clicks: number;
}

export function getConversionsColumn(
  attributionModel: TAttributionModel,
): IColumn<IComputedDailyStats, IRowOutput> {
  return {
    width: 200,
    id: `conversions__${attributionModel}__num`,
    Header: 'Conversions',
    accessor: `conversions.${attributionModel}.num`,
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      const { conversions } = original;
      if (conversions) {
        return <>{numeral(conversions[attributionModel]?.num || 0).format('0,0')}</>;
      }
      return <>0</>;
    },
    Footer({ data }): JSX.Element {
      const numConversions = data.reduce((acc, stat) => {
        const conversions = stat[
          `conversions__${attributionModel}__num` as keyof IRowOutput
        ] as number;
        return acc + (conversions || 0);
      }, 0);
      return <label>{numConversions}</label>;
    },
  };
}

// TODO: Fix types when we upgrade react-table
export const DailyStatsColumns: Array<IColumn<IComputedDailyStats, IRowOutput>> = [
  {
    Header: 'Date',
    id: 'date',
    width: 110,
    Cell({ original }): JSX.Element {
      return <Time format={TimeFormat.shortDate} timestamp={original.metadata.date} />;
    },
  },
  {
    id: 'views',
    Header: 'Views',
    accessor: 'views',
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      const { views } = original;
      return <>{numeral(views).format('0,0')}</>;
    },
    Footer({ data }): JSX.Element {
      const totalAdView = data.reduce((acc, current) => acc + (current.views || 0), 0);
      return <label>{numeral(totalAdView).format('0,0')}</label>;
    },
  },
  {
    width: 110,
    id: 'clicks',
    Header: 'Clicks',
    accessor: 'clicks',
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      const { clicks } = original;
      return <>{numeral(clicks).format('0,0')}</>;
    },
    Footer({ data }): JSX.Element {
      const totalAdClick = data.reduce((acc, current) => acc + (current.clicks || 0), 0);
      return <label>{numeral(totalAdClick).format('0,0')}</label>;
    },
  },
  {
    id: 'ctr',
    Header: 'CTR',
    width: 65,
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      const { views, clicks } = original;
      const ctr = views > 0 ? clicks / views : 0;
      return <>{numeral(ctr).format('0.00%')}</>;
    },
  },
  {
    width: 150,
    id: 'num_users_active',
    Header: 'Active Audience',
    accessor: 'num_users_active.daily',
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      const { num_users_active: numUsersActive } = original;
      return <>{numeral(numUsersActive ? numUsersActive.daily : 0).format('0,0')}</>;
    },
  },
  {
    width: 140,
    Header: 'Spend',
    id: 'spend',
    accessor: 'spend.daily',
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      const { spend = { daily: 0 } } = original;
      return <>{numeral(spend.daily).format('$0,0.00')}</>;
    },
    Footer({ data }): JSX.Element {
      const totalSpend = data.reduce((acc, { spend = 0 }) => acc + spend, 0);
      return <label>{numeral(totalSpend).format('$0,0.00')}</label>;
    },
  },
  {
    id: 'cpm',
    Header: 'CPM',
    width: 65,
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      const { views, spend } = original;
      if (spend && spend.daily) {
        const cpm = views > 0 ? (spend.daily / views) * 1000 : 0;
        return <>{numeral(cpm).format('$0,0.00')}</>;
      }
      return <>$0.00</>;
    },
  },
  {
    id: 'cpm',
    Header: 'CPC',
    width: 65,
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      const { clicks, spend } = original;
      if (spend && spend.daily) {
        const cpc = clicks > 0 ? spend.daily / clicks : 0;
        return <>{numeral(cpc).format('$0,0.00')}</>;
      }
      return <>$0.00</>;
    },
  },
  {
    id: 'cpa',
    Header: 'CPA',
    width: 65,
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      const { conversions, spend } = original;
      if (conversions && spend && spend.daily) {
        const cpa =
          (conversions.full?.num || 0) > 0 ? spend.daily / (conversions.full?.num || 0) : 0;
        return <>{numeral(cpa).format('$0,0.00')}</>;
      }
      return <>$0.00</>;
    },
  },
];
