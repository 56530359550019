import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { PropsWithChildren } from 'react';
import React from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { FieldDataType } from '@feathr/blackbox';
import type { ISelectOption, ISelectProps } from '@feathr/components';
import { fieldDataTypeIcon } from '@feathr/extender/styles/fieldDataType';

type IProps = PropsWithChildren<OptionProps<ISelectProps<ISelectOption>>>;
interface IDataProps extends IProps {
  data: ISelectOption;
}

// To keep compatible with react-select, props needs to be IProps and not IDataProps.
function DataTypeOption(props: IProps) {
  const { data } = props as IDataProps;

  const icon = data.id === 'create' ? faPlus : fieldDataTypeIcon(data.id as FieldDataType);

  return (
    <components.Option {...props}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {data.name}
    </components.Option>
  );
}

export default DataTypeOption;
