import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import { Chip, TableColumnHeader } from '@feathr/components';
import { templateTypeIcon, templateTypeTheme } from '@feathr/extender/styles/template';

import type { ITemplateRow } from './TemplatesTable';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const TemplateColumnType = {
  id: '_cls',
  Header: TableColumnHeader({
    sortType: 'alpha',
    title: 'Type',
  }),
  headerClassName: tableStyles.sort,
  className: tableStyles.cell,
  width: 120,
  Cell({ original }: ITemplateRow) {
    return (
      <Observer>
        {() => {
          const { template } = original;
          const cls = template.get('_cls');
          return (
            <Chip
              prefix={<FontAwesomeIcon icon={templateTypeIcon(cls)} />}
              theme={templateTypeTheme(cls)}
            >
              {template.getTypeLabel()}
            </Chip>
          );
        }}
      </Observer>
    );
  },
};

export default TemplateColumnType;
