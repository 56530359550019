import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useParams } from 'react-router';

import type { Person } from '@feathr/blackbox';

import ActivityFeed from './ActivityFeed';
import CustomFields from './CustomFields';
import Profile from './Profile';
import Subscriptions from './Subscriptions';

type TTab = 'activity' | 'profile' | 'custom-fields' | 'subscriptions';

interface IProps {
  person: Person;
}

function PersonDetail({ person }: IProps): JSX.Element {
  const { tab } = useParams<{ tab: TTab }>();

  const tabMap = {
    activity: <ActivityFeed person={person} />,
    profile: <Profile person={person} />,
    'custom-fields': <CustomFields person={person} />,
    subscriptions: <Subscriptions person={person} />,
  };

  // Default to activity feed tab if no tab is provided
  const tabContent = tabMap[tab] ?? <ActivityFeed person={person} />;

  return tabContent;
}

export default observer(PersonDetail);
