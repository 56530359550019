import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { CreativeClass } from '@feathr/blackbox';
import { useToggle } from '@feathr/hooks';

import CreativeClassCard from './CreativeTypeCard';

import * as styles from './CreativeTypeSelect.css';

export interface IProps {
  addCreative: (type: CreativeClass, partnerId?: string) => void;
  creativeClasses?: CreativeClass[];
}

const creativeClassesArray: CreativeClass[] = Object.values(CreativeClass).filter(
  (c) => c.includes('DisplayCreative') && !c.includes('GoogleAdsCreative'),
);

function CreativeTypeSelect({
  addCreative,
  creativeClasses = creativeClassesArray,
}: IProps): JSX.Element {
  const [isClicked, toggleClicked] = useToggle(false);

  return (
    <div className={styles.root}>
      {creativeClasses.map((cls) => (
        <CreativeClassCard
          addCreative={addCreative}
          cls={cls}
          isClicked={isClicked}
          key={cls}
          toggleClicked={toggleClicked}
        />
      ))}
    </div>
  );
}

export default observer(CreativeTypeSelect);
