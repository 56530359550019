import type { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

import type { Creative, CreativeClass } from '@feathr/blackbox';
import { creativeColorMap, creativeIconMap } from '@feathr/extender/styles/creative';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';
import styles from './CreativeIcon.css';

interface IBaseProps {
  className?: string;
  size: number;
  hover?: boolean;
}

interface ICreativeProps extends IBaseProps {
  cls?: never;
  creative: Creative;
}

interface IClassProps extends IBaseProps {
  cls: CreativeClass;
  creative?: never;
}

function CreativeIcon({
  creative,
  className,
  cls,
  size,
  hover = false,
}: ICreativeProps | IClassProps) {
  const isPending = (creative && creative.isPending) || false;
  // TODO: Add draft mode.
  const isDraft = false;

  const icon = creative ? creativeIconMap.get(creative.get('_cls'))! : creativeIconMap.get(cls!)!;
  let iconSize: SizeProp = '1x';

  if (size >= 24 && size < 36) {
    iconSize = 'lg';
  } else if (size >= 36 && size < 54) {
    iconSize = '2x';
  } else if (size >= 54 && size < 72) {
    iconSize = '3x';
  } else if (size >= 72) {
    iconSize = '4x';
  }

  const backgroundColor = creativeColorMap.get(creative ? creative.get('_cls') : cls!);

  return (
    <div
      className={classNames(
        styles.root,
        { [styles.hoverable]: hover },
        { [styles.draft]: isDraft },
        { [skeleton]: isPending },
        className,
      )}
      style={{
        ['--l-icon-background-color' as any]: backgroundColor ? backgroundColor : null,
        ['--l-icon-size' as any]: `${size}px`,
      }}
    >
      {!isPending && <FontAwesomeIcon icon={icon} size={iconSize} />}
    </div>
  );
}

export default CreativeIcon;
