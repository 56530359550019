import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign, Campaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function getCPM(campaign: Campaign): number {
  const spend = campaign.get('total_stats').spend || 0;
  const views = campaign.totalViews;
  return views > 0 ? (spend / views) * 1000 : 0;
}

const CPMColumn: IColumn<BaseCampaign> = {
  id: 'cpm',
  checkboxLabel: 'CPM',
  Header: TableColumnHeader({
    title: 'CPM',
  }),
  width: 80,
  sortable: false,
  className: tableStyles.cellRight,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const cpm = getCPM(original);
          return <>{numeral(cpm).format('$0,0.00')}</>;
        }}
      </Observer>
    );
  },
  Footer({ data }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const spendArray = data.map((item) => item._original.get('total_stats').spend || 0);
          // Exclude campaigns with zero spend from the average
          const viewsArray = data.map((item) =>
            item._original.get('total_stats').spend > 0 ? item._original.totalViews : 0,
          );
          const totalSpend = spendArray.reduce((sum, num) => sum + num, 0);
          const totalViews = viewsArray.reduce((sum, num) => sum + num, 0);
          const weightedAvg = totalViews > 0 ? (totalSpend / totalViews) * 1000 : 0;
          return <>{numeral(weightedAvg).format('$0,0.00')}</>;
        }}
      </Observer>
    );
  },
};

export default CPMColumn;
