import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { IiMISCommunicationPreference, ImisIntegration } from '@feathr/blackbox';
import { Button, CardV2 as Card, Radios, Spinner, toast, Toolbar } from '@feathr/components';
import type { TValidateGrouped } from '@feathr/rachis';

import FinishSetupButton from './FinishSetupButton';
import type { TConfigurationContext } from './ImisConfiguration';

import * as styles from './ImisConfigStepFive.css';

interface IProps {
  context?: TConfigurationContext;
  onPrev: () => void;
  integration: ImisIntegration;
}

interface ICommunicationPreferenceOption {
  id: IiMISCommunicationPreference['communication_id'];
  name: IiMISCommunicationPreference['communication_display'];
}

function ImisConfigStepFive({
  context = 'wizard',
  integration,
  onPrev,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const [communicationPreferenceOptions, setCommunicationPreferenceOptions] = useState<
    ICommunicationPreferenceOption[] | undefined
  >(undefined);
  const communicationPreferencesURL = `${integration.get('org_site')}/Staff/iCore/Contacts/Communication_Preferences_Settings.aspx`;

  async function handleSaveCommunicationPreference(): Promise<void> {
    try {
      // Default the communication preference to the first option if it is not set.
      if (!integration.get('communication_preference_id')) {
        // There will always be at least one option.
        integration.set({ communication_preference_id: communicationPreferenceOptions![0].id });
      }
      await integration.patchDirty();
      toast(t('Communication preferences updated'), { type: ToastType.SUCCESS });
    } catch (error) {
      toast(t('Failed to update communication preferences:\n{{error}}', { error }));
    }
  }

  function handleChangeCommunicationPreference(
    newValue?: ICommunicationPreferenceOption['id'],
  ): void {
    // This should never happen.
    if (!newValue) {
      return;
    }
    integration.set({ communication_preference_id: newValue });

    if (context === 'settings') {
      handleSaveCommunicationPreference();
    }
  }

  const getCommunicationPreferenceOptions = useCallback(async () => {
    try {
      const response = await integration.getCommunicationPreferenceOptions();
      // Convert the response to the format expected by the Radios component.
      const options: ICommunicationPreferenceOption[] = response.map(
        ({ communication_display: name, communication_id: id }) => ({ id, name }),
      );
      setCommunicationPreferenceOptions(options);

      // Default the communication preference to the first option if it is not set.
      if (options.length && !integration.get('communication_preference_id')) {
        integration.set({ communication_preference_id: options[0].id });
      }
    } catch (error) {
      toast(t('Failed to get communication preferences:\n{{error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
  }, [integration, t]);

  useEffect(() => {
    getCommunicationPreferenceOptions();
  }, [getCommunicationPreferenceOptions]);

  function validateStepFive(integration: ImisIntegration): TValidateGrouped {
    return integration.validate(['communication_preference_id'], false, 'grouped').errors;
  }

  const isLoading = communicationPreferenceOptions === undefined;
  const hasOptions = communicationPreferenceOptions?.length;
  const shouldShowOptions = !isLoading && Boolean(hasOptions);
  const shouldShowLinkToImis = !isLoading && !hasOptions;

  return (
    <>
      <Card>
        <Card.Header
          description={
            <>
              {t(
                'Choose the communication preference in iMIS that you want to map to the Feathr global subscription. ',
              )}
              <a
                href={
                  'https://help.feathr.co/hc/en-us/articles/16642566803351-How-to-Integrate-iMIS-with-Feathr#01HNBAKPK6ADHTR3C7FCNC609A'
                }
                target={'_blank'}
              >
                {t('Learn more')}
              </a>
            </>
          }
          title={t('Communication Preferences Mapping')}
        ></Card.Header>
        {isLoading && (
          <Card.Content>
            <Spinner />
          </Card.Content>
        )}
        {shouldShowOptions && (
          <Card.Content>
            <Radios
              onChange={handleChangeCommunicationPreference}
              options={communicationPreferenceOptions}
              orientation={'vertical'}
              value={
                isLoading
                  ? undefined
                  : communicationPreferenceOptions.find(
                      ({ id }) => id === integration.get('communication_preference_id'),
                    )?.id ?? communicationPreferenceOptions[0].id
              }
            />
          </Card.Content>
        )}
        {shouldShowLinkToImis && (
          <Card.Content contentClassName={styles.link}>
            {t("You don't have any communication preference types enabled in iMIS.")}
            <Button
              href={communicationPreferencesURL}
              name={'communication-preferences-url'}
              suffix={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
              target={'_blank'}
            >
              {t('Configure preferences')}
            </Button>
          </Card.Content>
        )}
      </Card>
      {context === 'wizard' && (
        <Toolbar align={'right'} className={styles.buttonWrapper}>
          <Button key={'prev'} name={'previous'} onClick={onPrev}>
            {t('Previous')}
          </Button>
          <FinishSetupButton
            integration={integration}
            key={'finish-imis-setup'}
            t={t}
            validation={validateStepFive}
          />
        </Toolbar>
      )}
    </>
  );
}

export default observer(ImisConfigStepFive);
