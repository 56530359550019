import type { WithT } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { SmartPinpointEmailCampaign, TUnitOfTime } from '@feathr/blackbox';
import {
  Button,
  ButtonValid,
  DatePicker,
  Fieldset,
  Form,
  NumberInput,
  Select,
} from '@feathr/components';
import { useUser } from '@feathr/extender/state';
import {
  flattenError,
  flattenErrors,
  moment,
  sortErrors,
  TimeFormat,
  timezoneAbbr,
} from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import type { IActionErrors } from '../../CampaignSummary/CampaignSummary';
import PinpointTriggersConfig from '../PinpointActions/PinpointTriggersConfig';

import * as styles from './SmartPinpointEmailCampaignStep.css';

interface IProps {
  campaign: SmartPinpointEmailCampaign;
  onNext: () => void;
  onPrev: () => void;
  disabled: boolean;
}

interface IMenuOption {
  id: TUnitOfTime;
  value: string;
}

interface IButtonProps extends WithT {
  campaign: SmartPinpointEmailCampaign;
  onNext: () => void;
}

interface IErrors extends TValidateGrouped {
  actions?: IActionErrors[];
  date_send_start?: string[];
  date_send_end?: string[];
  delay_value?: string[];
  delay_unit?: string[];
  send_all?: string[];
}

const timeOptions = [
  { id: 'minutes', value: 'minutes' },
  { id: 'hours', value: 'hours' },
  { id: 'days', value: 'days' },
  { id: 'weeks', value: 'weeks' },
];

export function validateSmartStep(campaign: SmartPinpointEmailCampaign): IErrors {
  return campaign.validate<IErrors>(
    ['actions', 'date_send_start', 'date_send_end', 'delay_value', 'delay_unit', 'send_all'],
    false,
    'grouped',
  ).errors;
}

const NextStepButton = observer(({ campaign, onNext, t }: IButtonProps): JSX.Element => {
  const validationErrors = validateSmartStep(campaign);
  /**
   * Sort validation errors object in the order of input elements on the page,
   * then turn into array.
   */
  const parsedErrors = flattenErrors(
    sortErrors(validationErrors, [
      'actions',
      'date_send_start',
      'date_send_end',
      'delay_value',
      'delay_unit',
      'send_all',
    ]),
  );
  return (
    <ButtonValid errors={parsedErrors} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function SmartPinpointEmailCampaignStep({
  campaign,
  disabled,
  onNext,
  onPrev,
}: IProps): JSX.Element {
  const { t } = useTranslation();

  const user = useUser();

  function onChangeSendStart(newTimestamp?: string): void {
    const newMoment = moment.utc(newTimestamp);
    campaign.set({
      date_send_start: newTimestamp,
      date_start: newMoment.clone().startOf('day').format(TimeFormat.isoDateTime),
    });
    if (newMoment.isAfter(moment.utc(campaign.get('date_send_end')))) {
      onChangeSendEnd(newMoment.add(1, 'day').format(TimeFormat.isoDateTime));
    }
  }

  function onChangeSendEnd(newTimestamp?: string): void {
    const newMoment = moment.utc(newTimestamp);
    campaign.set({
      date_send_end: newTimestamp,
      date_end: newMoment.clone().add(30, 'days').startOf('day').format(TimeFormat.isoDateTime),
    });
    if (newMoment.isBefore(moment.utc(campaign.get('date_send_start')))) {
      let newStartMoment = newMoment.subtract(1, 'day');
      if (newStartMoment.isBefore()) {
        newStartMoment = moment.utc().startOf('minute');
      }
      onChangeSendStart(newStartMoment.format(TimeFormat.isoDateTime));
    }
  }

  function getOptionValue(option: IMenuOption): string {
    return option.value;
  }

  function getOptionLabel(option: IMenuOption): string | undefined {
    const delay = campaign.get('delay_value');
    return {
      minutes: t('minute', { count: delay }),
      hours: t('hour', { count: delay }),
      days: t('day', { count: delay }),
      weeks: t('week', { count: delay }),
    }[option.id];
  }

  function onSelectSingle(option: IMenuOption): void {
    campaign.set({ delay_unit: option.id });
  }

  const userTimezone = user.get('timezone');
  const sendStartTimestamp = campaign.get('date_send_start');
  const sendEndTimestamp = campaign.get('date_send_end');
  const sendStartMoment = moment.utc(sendStartTimestamp).local();
  const sendEndMoment = moment.utc(sendEndTimestamp).local();
  const isoSendStartTimestamp = moment.utc(sendStartTimestamp).format(TimeFormat.isoDateTime);
  const isoSendEndTimestamp = moment.utc(sendEndTimestamp).format(TimeFormat.isoDateTime);

  let minStartTimeMoment = moment();
  if (moment.utc(sendStartTimestamp).startOf('day').isAfter(minStartTimeMoment)) {
    minStartTimeMoment = minStartTimeMoment.startOf('day');
  }

  let minEndTimeMoment = moment();
  if (moment.utc(sendEndTimestamp).startOf('day').isAfter(minEndTimeMoment)) {
    minEndTimeMoment = minEndTimeMoment.startOf('day');
  }

  const validationErrors = validateSmartStep(campaign);

  function getTimeOptions(): IMenuOption[] {
    return [
      { id: 'minutes', value: 'minutes' },
      { id: 'hours', value: 'hours' },
      { id: 'days', value: 'days' },
      { id: 'weeks', value: 'weeks' },
    ];
  }

  function getTimeValue(attribute: 'delay_unit' | 'cooldown_unit'): IMenuOption | undefined {
    return getTimeOptions().find(
      (option) =>
        option.id === campaign.get(attribute, attribute.startsWith('delay') ? 'minutes' : 'hours'),
    );
  }

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <NextStepButton campaign={campaign} key={'next'} onNext={onNext} t={t} />,
      ]}
      description={t(
        'Contacts with email addresses in your target groups (set on the previous step) who perform this trigger during the send window will receive the email as a response to that trigger. Contacts in your target groups who do not perform this activity will receive the email at the end of the Send Window.',
      )}
      label={t('Edit Campaign: Smart Settings')}
    >
      <Fieldset>
        <PinpointTriggersConfig campaign={campaign} disabled={disabled} label={t('Triggers')} />
      </Fieldset>
      <Fieldset>
        <DatePicker
          dateFormat={'MMM d, yyyy h:mm aa'}
          disabled={disabled}
          helpPlacement={'bottom'}
          helpText={
            t(
              'Choose the time that Feathr should start sending emails to contacts who perform the trigger.',
            ) +
            ' ' +
            sendStartMoment.utc().format(TimeFormat.pickerDateTimeZone)
          }
          label={t('Send window start')}
          maxTime={sendStartMoment.local().endOf('day').toDate()}
          minDate={new Date()}
          minTime={minStartTimeMoment.toDate()}
          name={'date_send_start'}
          onDateStrChange={onChangeSendStart}
          showTimeSelect={true}
          suffix={timezoneAbbr(sendStartMoment.toDate())}
          timeIntervals={5}
          timezone={userTimezone}
          validationError={flattenError(validationErrors.date_send_start)}
          value={isoSendStartTimestamp}
        />
        <DatePicker
          dateFormat={'MMM d, yyyy h:mm aa'}
          disabled={disabled}
          helpPlacement={'bottom'}
          helpText={
            t(
              "Choose the time at which all contacts in your target group should receive this email, even if they haven't performed the trigger.",
            ) +
            ' ' +
            sendEndMoment.utc().format(TimeFormat.pickerDateTimeZone)
          }
          label={t('Send window end')}
          maxTime={sendEndMoment.local().endOf('day').toDate()}
          minDate={new Date()}
          minTime={minEndTimeMoment.toDate()}
          name={'date_send_end'}
          onDateStrChange={onChangeSendEnd}
          showTimeSelect={true}
          suffix={timezoneAbbr(sendEndMoment.toDate())}
          timeIntervals={5}
          timezone={userTimezone}
          validationError={flattenError(validationErrors.date_send_end)}
          value={isoSendEndTimestamp}
        />
      </Fieldset>
      <Fieldset>
        <NumberInput
          attribute={'delay_value'}
          className={styles.delay}
          disabled={disabled}
          helpText={t('Choose how long after a person performs the action to send the email.')}
          label={t('Delay time')}
          min={1}
          model={campaign}
          name={'delay_value'}
          prefix={t('Delay email for')}
          suffix={
            <Select
              disabled={disabled}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              name={'delay_unit'}
              onSelectSingle={onSelectSingle}
              options={timeOptions}
              value={getTimeValue('delay_unit')}
            />
          }
          suffixClassName={styles.delaySuffix}
          validationError={flattenError(validationErrors.delay_value)}
          wrapperClassName={styles.delayWrapper}
        />
      </Fieldset>
    </Form>
  );
}

export default observer(SmartPinpointEmailCampaignStep);
