import { faRocket } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { ImisIntegration } from '@feathr/blackbox';
import { EIntegrationConnectionState } from '@feathr/blackbox';
import { Button, Card, Toolbar } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './ImisGettingStartedCard.css';

interface IProps {
  integration?: ImisIntegration;
  setIsBeingConfigured: React.Dispatch<React.SetStateAction<boolean>>;
}

function ImisGettingStartedCard({ integration, setIsBeingConfigured }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { ImisIntegrations } = useContext(StoresContext);
  const { disconnected } = EIntegrationConnectionState;

  async function handleOnClick(): Promise<void> {
    if (!integration) {
      const newIntegration = ImisIntegrations.create();
      // Setting validate to false because we don't want to validate the integration until the end.
      await ImisIntegrations.add(newIntegration, { validate: false });
      ImisIntegrations.clearApiCache();
    } else if (integration && integration.integrationState === disconnected) {
      // If it is not the first time being configured and the state is disconnected, setIsBeingConfigured to true onClick to continue with the configuration process
      setIsBeingConfigured(true);
    }
  }

  return (
    <Card className={styles.card} title={t('Ready to connect iMIS to Feathr?')}>
      {t(
        'There are a few steps to follow, but the process should only take 10-15 minutes. Before you start, make sure:',
      )}
      <ul>
        <li>
          {t(
            'You are on iMIS Cloud (also called EMS Enterprise). This integration only works with iMIS Cloud.',
          )}
        </li>
        <li> {t('You have administrator access to your iMIS account.')}</li>
        <li>
          <Trans t={t}>
            Refer to our{' '}
            <a href={'https://help.feathr.co/hc/en-us/articles/16642566803351'}>
              iMIS integration help documentation
            </a>{' '}
            to help you get setup.
          </Trans>
        </li>
      </ul>
      <Toolbar align={'center'}>
        <Button
          onClick={handleOnClick}
          prefix={<FontAwesomeIcon icon={faRocket} />}
          type={'primary'}
        >
          {t("Let's get started")}
        </Button>
      </Toolbar>
    </Card>
  );
}

export default observer(ImisGettingStartedCard);
