import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { DisplayCreative } from '@feathr/blackbox';

import noImg from '@feathr/report_components/images/no-img.png';

interface IProps {
  className?: string;
  creative?: DisplayCreative;
  size: number;
  url?: string;
}

function ImageCreativeThumbnail({ className, creative, size, url }: IProps): JSX.Element {
  return (
    <div className={className} style={{ width: `${size}px` }}>
      <img
        alt={''}
        src={creative?.get('url') || url || noImg}
        style={{
          maxWidth: `${size}px`,
          maxHeight: `${size}px`,
        }}
      />
    </div>
  );
}

export default observer(ImageCreativeThumbnail);
