import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import type { ITemplate } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import { Fieldset, FormElement, PlaceholderImage, Radios } from '@feathr/components';

import TemplateSelectCustom from './TemplateSelectCustom';
import TemplateSelectDefault from './TemplateSelectDefault';

import * as styles from './TemplateSelect.css';

import blankTemplate from './blankTemplate.json';

interface IProps {
  onChange: (template?: ITemplate) => void;
  templateClass: TemplateClass;
  value?: ITemplate;
}

export const templatesBlankToClassMap: Record<TemplateClass, number> = {
  [TemplateClass.Banner]: 1,
  [TemplateClass.Email]: 1,
  [TemplateClass.LandingPage]: 1,
  [TemplateClass.Link]: 1,
  [TemplateClass.Page]: 1,
  [TemplateClass.ReferralPage]: 0,
  [TemplateClass.ReferralEmail]: 1,
  [TemplateClass.ReferralBanner]: 1,
  [TemplateClass.PinpointEmail]: 2,
};

const radioOptions = [
  { id: 'default', name: 'Saved templates' },
  { id: 'custom', name: 'From campaigns' },
  { id: 'blank', name: 'Blank template' },
];

function TemplateSelect({ onChange, templateClass, value }: IProps) {
  const [templateType, setTemplateType] = React.useState<string | null>(null);

  const hasBlank = templateClass !== TemplateClass.ReferralBanner;
  useEffect(() => {
    if (!hasBlank && templateType === 'blank') {
      setTemplateType(null);
    }
  }, [templateClass]);

  const blankIndex = templatesBlankToClassMap[templateClass];

  function handleChangeRadios(newValue?: string) {
    if (newValue === undefined) {
      return;
    }
    setTemplateType(newValue);
    if (newValue !== 'blank') {
      onChange(undefined);
    } else {
      onChange(blankTemplate[blankIndex]);
    }
  }

  let valueContent = <p>Select a template to preview.</p>;

  if (value) {
    const isBanner = [TemplateClass.ReferralBanner, TemplateClass.Banner].includes(value._cls);
    if (isBanner) {
      if (value.bannersnack_enabled && value.default) {
        valueContent = (
          <PlaceholderImage
            height={value.height!}
            style={{ maxWidth: `${Math.min(value.width || 534, 534)}px` }}
            width={value.width!}
          />
        );
      } else if (value.bannersnack_enabled && !value.banner_hash) {
        valueContent = (
          <PlaceholderImage
            height={value.height!}
            style={{ maxWidth: `${value.width}px` }}
            width={value.width!}
          />
        );
      } else {
        valueContent = (
          <img
            alt={''}
            src={`${BLACKBOX_URL}templates/${value.id}/thumb`}
            style={{ maxWidth: `${value.width}px` }}
          />
        );
      }
    } else {
      valueContent = (
        <iframe
          src={`${BLACKBOX_URL}templates/${value.id}/screenshot`}
          title={'Template Preview'}
        />
      );
    }
  }

  return (
    <>
      <Fieldset>
        <p className={styles.helpText}>
          Choose a starting point: one of your saved templates, another campaign, or a blank editor.
        </p>
        <Radios
          dataName={'template_type'}
          onChange={handleChangeRadios}
          options={hasBlank ? radioOptions : radioOptions.slice(0, 2)}
        />
        <div className={styles.select}>
          {templateType === 'default' ? (
            <TemplateSelectDefault
              onChange={onChange}
              templateClass={templateClass}
              value={value}
            />
          ) : templateType === 'custom' ? (
            <TemplateSelectCustom onChange={onChange} templateClass={templateClass} value={value} />
          ) : null}
        </div>
      </Fieldset>
      <FormElement className={styles.preview} label={'Preview'}>
        {valueContent}
      </FormElement>
    </>
  );
}

export default observer(TemplateSelect);
