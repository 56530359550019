import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { PinpointPartnerMessage } from '@feathr/blackbox';
import { Button, Form } from '@feathr/components';
import ConsentCapture from '@feathr/extender/components/ConsentCapture';
import RecipientsPicker from '@feathr/extender/components/RecipientsPicker';
import { useUser } from '@feathr/extender/state';
import { moment, TimeFormat } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import ButtonNext from '../ButtonNext';

import * as styles from './EventPartnersMessageStepFour.css';

interface IProps {
  disabled?: boolean;
  message: PinpointPartnerMessage;
  onNext: () => void;
  onPrevious: () => void;
}

export function validateStepFour(message: PinpointPartnerMessage): TValidateGrouped {
  return message.validate(['participation', 'consent.has_consent'], false, 'grouped').errors;
}

function EventPartnersMessageStepFour({
  disabled = false,
  message,
  onNext,
  onPrevious,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();

  function handleChangeConsent(newValue = false): void {
    if (newValue) {
      message.set({
        consent: {
          user: user.id,
          has_consent: true,
          date_consented: moment.utc().format(TimeFormat.isoDateTime),
        },
      });
    } else {
      message.set({
        consent: {
          has_consent: false,
        },
      });
    }
  }

  return (
    <Form
      actions={[
        <Button key={'previous'} name={'previous_step'} onClick={onPrevious}>
          {t('Previous')}
        </Button>,
        <ButtonNext
          key={'next'}
          message={message}
          name={'next_step'}
          onNext={onNext}
          validate={validateStepFour}
        />,
      ]}
      className={styles.form}
      description={t('Select the partners you would like to message.')}
      label={t('Edit Message: Audience')}
    >
      <RecipientsPicker className={styles.select} disabled={disabled} model={message} />
      <ConsentCapture
        alertDescription={t(
          'I understand that if my campaigns exhibit abnormally high bounce or complaint rates, it may result in my account being placed under temporary review or suspension.',
        )}
        alertTitle={t(
          'I attest that I am sending this email to recipients who have signed up and specifically requested to receive email about this topic from me or my organization.',
        )}
        className={styles.topMargin}
        disabled={disabled}
        label={t(
          'Please type "AGREE" in the box below to verify the above statement (case-sensitive).',
        )}
        onChange={handleChangeConsent}
        value={message.get('consent', { has_consent: false }).has_consent}
      />
    </Form>
  );
}

export default observer(EventPartnersMessageStepFour);
