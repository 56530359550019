import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import type { DropResult } from 'smooth-dnd';

import type { Flight } from '@feathr/blackbox';
import { Button, Toolbar, Tooltip } from '@feathr/components';
import journey from '@feathr/extender/images/journey.svg';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';

import Leg from './Leg';

import styles from './Legs.css';

interface IProps {
  className?: string;
  displayMode?: 'default' | 'condensed';
  flight: Flight;
}

function Legs({ className, displayMode = 'default', flight }: IProps): JSX.Element {
  const legs = flight.get('legs');

  const debouncedSave = debounce(() => flight.patchDirty(), DEFAULT_DEBOUNCE_WAIT);

  async function addLeg(): Promise<void> {
    legs.push({
      name: 'Unnamed leg',
      campaigns: [],
    });
    flight.setAttributeDirty('legs');
    await debouncedSave();
  }

  async function removeLeg(index: number): Promise<void> {
    legs.splice(index, 1);
    flight.setAttributeDirty('legs');
    await debouncedSave();
  }

  async function moveLeg({ addedIndex, removedIndex }: DropResult): Promise<void> {
    if (removedIndex === null || addedIndex === null) {
      return;
    }

    if (removedIndex === addedIndex) {
      // Nothing to do when dropped in the same place.
      return;
    }

    const item = legs.splice(removedIndex, 1)[0];
    legs.splice(addedIndex, 0, item);
    /*
     * Since legs is an array, changning its contents doesn't change the reference to the array.
     * So manually mark as changed.
     */
    flight.setAttributeDirty('legs');
    await debouncedSave();
  }

  async function moveCampaign({
    addedIndex,
    legId,
    payload,
    removedIndex,
  }: DropResult & { legId: number }): Promise<void> {
    if (removedIndex !== null && addedIndex !== null && removedIndex === addedIndex) {
      // Nothing to do when dropped in the same place.
      return;
    }

    if (removedIndex !== null) {
      legs[legId].campaigns.splice(removedIndex, 1);
    }
    if (addedIndex !== null) {
      legs[legId].campaigns.splice(addedIndex, 0, payload);
    }
    if (removedIndex !== null || addedIndex !== null) {
      /*
       * Since legs is an array, changning its contents doesn't change the reference to the array.
       * So manually mark as changed.
       */
      flight.setAttributeDirty('legs');
      await debouncedSave();
    }
  }

  return (
    <div className={classNames(styles.root, className)}>
      <Container onDrop={moveLeg} orientation={'horizontal'}>
        {legs.map((_, legId) => (
          <Draggable key={legId}>
            <Leg
              displayMode={displayMode}
              flight={flight}
              legId={legId}
              onDrop={moveCampaign}
              removeLeg={removeLeg}
            />
          </Draggable>
        ))}
      </Container>
      {displayMode === 'default' && legs.length > 0 && (
        <Tooltip title={'Add leg'}>
          <Button className={styles.button} onClick={addLeg} type={'icon-outlined'}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Tooltip>
      )}
      {displayMode === 'default' && legs.length === 0 && (
        <div className={styles.noDataContainer}>
          <svg height={320} width={420}>
            <image width={420} xlinkHref={journey} />
          </svg>
          <p>
            A <strong>flight</strong> is made up of one or more <strong>legs</strong>, each defined
            by campaigns oriented around a theme or group of activities that gets your audience
            closer to your marketing goal.
          </p>
          <p>Add your first leg to start designing your flight!</p>
          <Toolbar>
            <Button onClick={addLeg} type={'primary'}>
              Add a leg
            </Button>
            <Button
              href={'https://help.feathr.co/hc/en-us/articles/360047836514-Intro-to-Flights'}
              target={'_blank'}
            >
              Learn more about flights
            </Button>
          </Toolbar>
        </div>
      )}
    </div>
  );
}

export default observer(Legs);
