export type { IParticipation } from './participation';
export { ReportModel } from './report';
export type { IReportAttributes, TReportKey } from './report';

export enum ECollectionClassName {
  // There are additional collection classnames, but these are the ones that are currently used.
  BlackbaudContactMappings = 'BlackbaudContactMappings',
  BlackbaudRaisersEdgeIntegrations = 'BlackbaudRaisersEdgeIntegrations',
  CustomField = 'CustomField',
  EmailVerification = 'EmailVerification',
  ImisIntegrations = 'ImisIntegrations',
  PinpointEmailBaseCampaign = 'PinpointEmailBaseCampaign',
  Segment = 'Segment',
  GoogleAdsCustomer = 'GoogleAdsCustomer',
  GoogleAdsSmartCampaign = 'GoogleAdsSmartCampaign',
}
