import classNames from 'classnames';
import type { TFunction } from 'i18next';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { User } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Avatar, TableColumnHeader, Time } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import RoleSelect from '../Roles/RoleSelect';
import UserOptions from './UserOptions';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './UsersColumns.css';

function userColumns(t: TFunction): Array<IColumn<User>> {
  return [
    {
      id: 'picture',
      Header: TableColumnHeader({
        title: t('Profile Picture'),
      }),
      sortable: false,
      width: 150,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return <Avatar image={original.get('picture')} size={'medium'} />;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Name'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return (
                <>
                  <div>{original.name}</div>
                  <div className={styles.userEmail}>{original.get('email')}</div>
                </>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'date_last_access',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Last Access'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              const date = original.get('date_last_access');
              if (!date) {
                return <>Never</>;
              }
              return <Time format={TimeFormat.shortDate} timestamp={date} />;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'is_admin',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Role'),
      }),
      headerClassName: tableStyles.sort,
      sortMethod: (a: boolean, b: boolean): number => {
        return Number(a) > Number(b) ? -1 : 1;
      },
      className: classNames(tableStyles.cellCenter, styles.role),
      Cell({ original }): JSX.Element {
        return <RoleSelect menuPortalTarget={document.body} userId={original.id} />;
      },
    },
    {
      id: 'options',
      Header: TableColumnHeader({
        title: t('Options'),
      }),
      width: 80,
      sortable: false,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        return <UserOptions user={original} />;
      },
    },
  ];
}

export default userColumns;
