import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardV2 as Card, EmailTemplateSelect, Label, Well } from '@feathr/components';

import * as styles from './PreviewInitialEnrollment.css';

function PreviewInitialEnrollment(): JSX.Element {
  const { t } = useTranslation();

  // Content is placeholder so we're only translating things that are actual content
  return (
    <>
      <Card.Content>
        <Label weight={'semibold'}>{t('Initial enrollment')}</Label>
        {/* TODO: Integrate the preview UI #3704 */}
        <div className={styles.wells}>
          <Well layout={'vertical'} theme={'white'}>
            <Well.Row
              description={t('Not set')}
              label={t('Trigger type')}
              tooltip={'Placeholder tooltip'}
            />
          </Well>
          <Well layout={'vertical'} theme={'white'}>
            <Well.Row
              description={
                <>
                  {/* TODO: Create a util that builds sentence-like elements out of predicates */}
                  <p>URL visited is unitedway.org/donation-confirmation</p>
                  <p>Conversion pixel is 2024 Annual Conference Registration</p>
                  <p>Membership Renewal Date is 90 days from now</p>
                </>
              }
              label={t('Trigger')}
              tooltip={'Placeholder tooltip'}
            />
          </Well>
          <Well layout={'vertical'} theme={'white'}>
            <Well.Row description={t('Delay')} label={t('Send schedule')} />
            <Well.Row description={t('30 minutes')} label={t('Delay timer')} />
            <Well.Row description={t('Send only the first time')} label={t('Send cadence')} />
          </Well>
        </div>
      </Card.Content>
      <Card.Content>
        <Label weight={'semibold'}>{t('Email')}</Label>
        {/* TODO: Integrate the preview UI #3704 */}
        <EmailTemplateSelect
          description={'Test drip campaign preview text truncated to fit on a single line'}
          previewUrl={
            'https://local.feathr.app:8002/v1/view_email?cpn_id=66d8d11effa8b02fe83b321c&t_id=66d8d123ffa8b02fe83b321d'
          }
          readOnly={true}
          subtitle={'Test drip campaign subject truncated to fit on a single line'}
          title={'emailaddress@emailaddress.com'}
        />
      </Card.Content>
    </>
  );
}

export default PreviewInitialEnrollment;
