import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import type { BaseCampaign, CampaignState } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import { Button, DebouncedInput, Input, Select, Table, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import eventPartnersMessagesColumns from './eventPartnersMessagesColumns';

import * as styles from './EventPartnersMessagesPage.css';

interface IFilters {
  name__icontains?: string;
  state?: CampaignState;
  _cls?: CampaignClass;
}

function EventPartnersMessagesPage(): JSX.Element {
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const [filters, setFilters] = useState<IFilters>({
    name__icontains: undefined,
    _cls: CampaignClass.PinpointPartnerMessage,
  });
  const history = useHistory();

  const { Campaigns } = useContext(StoresContext);

  async function createMessage(): Promise<void> {
    const message = Campaigns.create({
      _cls: CampaignClass.PinpointPartnerMessage,
      event: eventId,
      participation: {
        partner_ids: [],
        mode: 'event',
      },
    });
    const response = await Campaigns.add(message, { validate: false });
    history.push(localUrl(response.getItemUrl()));
  }

  function handleDebouncedSearchChange(newValue?: string): void {
    setFilters({
      ...filters,
      name__icontains: newValue,
    });
  }

  const actions = (
    <Toolbar>
      <Button
        onClick={createMessage}
        prefix={<FontAwesomeIcon icon={faPaperPlane} />}
        type={'primary'}
      >
        New message
      </Button>
    </Toolbar>
  );

  const stateOptions = [
    { id: 'draft', name: 'Draft' },
    { id: 'stopped', name: 'Stopped' },
    { id: 'erroring', name: 'Erroring' },
    { id: 'published', name: 'Published' },
    { id: 'publishing', name: 'Publishing' },
    { id: 'archived', name: 'Archived' },
  ];
  const stateValue = stateOptions.find((option) => option.id === filters.state);
  const filterElements = [
    <>
      <Select
        isClearable={true}
        onClear={() => {
          setFilters({ ...filters, state: undefined });
        }}
        onSelectSingle={(value) => {
          setFilters({ ...filters, state: value.id as CampaignState });
        }}
        options={stateOptions}
        placeholder={'Status'}
        value={stateValue}
        wrapperClassName={styles.filter}
      />
    </>,
    <>
      <DebouncedInput<string>
        defaultValue={filters.name__icontains}
        onChange={handleDebouncedSearchChange}
      >
        {(liveValue, onChangeLiveValue) => (
          <Input
            onChange={onChangeLiveValue}
            placeholder={'Search by name...'}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
    </>,
  ];

  return (
    <Page actions={actions} title={'Messages'}>
      <Table<BaseCampaign>
        collection={Campaigns}
        columns={eventPartnersMessagesColumns}
        filterElements={filterElements}
        filters={{ ...filters, event: eventId, is_archived__ne: true }}
        initialSort={[{ id: 'date_created' }]}
      />
    </Page>
  );
}

export default observer(EventPartnersMessagesPage);
