import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { ContextMenu, MenuItem } from '@feathr/components';
import { useCampaign } from '@feathr/extender/hooks';
import { useLocalUrl } from '@feathr/extender/state';

import CampaignArchiveModal from '../CampaignArchiveModal';
import CampaignCloneModal from '../CampaignCloneModal';
import CampaignDeleteModal from '../CampaignDeleteModal';
import CampaignDisableModal from '../CampaignDisableModal';
import CampaignRemoveFromFlightModal from '../CampaignRemoveFromFlightModal';

interface ICampaignOptions {
  campaign: Campaign;
  showFlightOptions?: boolean;
}

function CampaignOptions({ campaign, showFlightOptions = false }: ICampaignOptions): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const { disabled, allowed, modalHandlers, modalStates, text } = useCampaign({
    campaign,
  });

  return (
    <>
      <ContextMenu>
        <MenuItem link={localUrl(campaign.getItemUrl('edit'))}>{t('Edit')}</MenuItem>
        <MenuItem
          disabled={disabled.clone}
          onClick={modalHandlers.openCloneModal}
          tooltip={text.disableClone}
        >
          {t('Clone')}
        </MenuItem>
        {allowed.stop && (
          <MenuItem
            disabled={disabled.stop}
            onClick={modalHandlers.openDisableModal}
            tooltip={text.disableStop}
          >
            {t('Disable')}
          </MenuItem>
        )}
        {showFlightOptions && (
          <MenuItem onClick={modalHandlers.openRemoveFromFlightModal}>
            {t('Remove from flight')}
          </MenuItem>
        )}
        <MenuItem onClick={modalHandlers.openArchiveModal}>{t('Archive')}</MenuItem>
        {allowed.delete && (
          <MenuItem onClick={modalHandlers.openDeleteModal}>{t('Delete')}</MenuItem>
        )}
      </ContextMenu>

      <CampaignCloneModal
        campaign={campaign}
        closeCloneModal={modalHandlers.closeCloneModal}
        isCloneModalOpen={modalStates.isCloneModalOpen}
      />

      <CampaignDisableModal
        campaign={campaign}
        closeDisableModal={modalHandlers.closeDisableModal}
        isDisableModalOpen={modalStates.isDisableModalOpen}
      />

      <CampaignArchiveModal
        campaign={campaign}
        closeArchiveModal={modalHandlers.closeArchiveModal}
        isArchiveModalOpen={modalStates.isArchiveModalOpen}
      />

      <CampaignDeleteModal
        campaign={campaign}
        closeDeleteModal={modalHandlers.closeDeleteModal}
        isDeleteModalOpen={modalStates.isDeleteModalOpen}
      />

      <CampaignRemoveFromFlightModal
        campaign={campaign}
        closeRemoveFromFlightModal={modalHandlers.closeRemoveFromFlightModal}
        isRemoveFromFlightModalOpen={modalStates.isRemoveFromFlightModalOpen}
      />
    </>
  );
}

export default CampaignOptions;
