import type { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { JSX } from 'react';
import React from 'react';

import type { Campaign, CampaignClass } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { campaignColorMap, campaignIconMap } from '@feathr/extender/styles/campaign';
import { cssVar } from '@feathr/hooks';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';
import styles from './CampaignIcon.css';

interface IBaseProps {
  className?: string;
  size: 36 | 48;
  hover?: boolean;
}

interface ICampaignProps extends IBaseProps {
  cls?: never;
  campaign: Campaign;
}

interface IClassProps extends IBaseProps {
  cls: CampaignClass;
  campaign?: never;
}

// TODO: Eventually move to utilizing <Icon /> when it's spacing issues have been fixed
const sizeMap: Record<number, SizeProp> = {
  36: '1x',
  48: 'lg',
};

function CampaignIcon({
  campaign,
  className,
  cls,
  size,
  hover = false,
}: ICampaignProps | IClassProps): JSX.Element {
  const isPending = (campaign && campaign.isPending) || false;
  const isDraft = (campaign && campaign.get('state') === CampaignState.Draft) || false;

  const icon = campaign ? campaignIconMap.get(campaign.get('_cls'))! : campaignIconMap.get(cls!)!;
  const backgroundColor = campaignColorMap.get(campaign ? campaign.get('_cls') : cls!);

  return (
    <div
      className={classNames(
        styles.root,
        { [styles.hoverable]: hover },
        { [styles.draft]: isDraft },
        { [skeleton]: isPending },
        className,
      )}
      style={{
        ['--l-icon-background-color' as string]: backgroundColor
          ? cssVar(`--color-${backgroundColor}-500`)
          : null,
        ['--l-circle-size' as string]: `${size}px`,
      }}
    >
      {!isPending && <FontAwesomeIcon icon={icon} size={sizeMap[size]} />}
    </div>
  );
}

export default CampaignIcon;
