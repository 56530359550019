import type { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Account, TCampaignGroup } from '@feathr/blackbox';
import { CampaignClass, CampaignGroupMap, EUsageMetrics } from '@feathr/blackbox';
import { SegmentedControl } from '@feathr/components';
import { useGoogleAdsPermissions } from '@feathr/extender/hooks';
import { useAccount, useFlags, useUsageAndQuota } from '@feathr/extender/state';

import CampaignTypeSelectSections from './CampaignTypeSelectSections';

import * as styles from './CampaignTypeSelect.css';

type TCampaignCategory = 'marketing' | 'monetization';
export type TCampaignSection = 'expand' | 'cultivate' | 'understand';

export interface ICampaignTypeSelectProps {
  campaignClasses?: CampaignClass[];
  campaignGroup?: TCampaignGroup;
  defaultCategory?: TCampaignCategory;
  onSelect: (type: CampaignClass, isMonetization?: boolean) => void;
}

interface IAccessConditions {
  showGoogleAds: boolean;
  showDripCampaigns: boolean;
}

export interface IType {
  id: CampaignClass;
  access: (account: Account, conditions: IAccessConditions) => boolean;
  category: TCampaignCategory[];
  sections?: TCampaignSection[];
  usageText?: string | null;
}

const types: IType[] = [
  // Campaign types that are available to all license packages.
  {
    // Single Send
    id: CampaignClass.PinpointEmail,
    access: (account) => account.hasActivePackage,
    category: ['marketing', 'monetization'],
    sections: ['cultivate'],
  },
  {
    // Smart Send
    id: CampaignClass.SmartPinpointEmail,
    access: (account) => account.hasActivePackage,
    category: ['marketing'],
    sections: ['cultivate'],
  },
  {
    // Auto Send
    id: CampaignClass.AutoPinpointEmail,
    access: (account) => account.hasActivePackage,
    category: ['marketing'],
    sections: ['cultivate'],
  },
  {
    // Drip
    id: CampaignClass.DripCampaign,
    access: (account, conditions): boolean =>
      account.hasActivePackage && conditions.showDripCampaigns,
    category: ['marketing'],
    sections: ['cultivate'],
  },
  {
    // Retargeting
    id: CampaignClass.Segment,
    access: (account) => account.hasActivePackage,
    category: ['marketing', 'monetization'],
    sections: ['cultivate'],
  },
  {
    // Meta Retargeting
    id: CampaignClass.Facebook,
    access: (account) => account.hasActivePackage,
    category: ['marketing'],
    sections: ['cultivate'],
  },
  {
    // Meta Email Mapping
    id: CampaignClass.EmailListFacebook,
    access: (account) => account.hasActivePackage,
    category: ['marketing'],
    sections: ['cultivate'],
  },
  {
    // Mobile Geofencing
    id: CampaignClass.MobileGeoFencing,
    access: (account) => account.hasActivePackage,
    category: ['marketing', 'monetization'],
    sections: ['expand'],
  },
  {
    // Historical Geofencing
    id: CampaignClass.MobileGeoFenceRetargeting,
    access: (account) => account.hasActivePackage,
    category: ['marketing', 'monetization'],
    sections: ['expand'],
  },
  {
    // Tracked Link
    id: CampaignClass.TrackedLink,
    access: (account) => account.hasActivePackage,
    category: ['marketing'],
    sections: ['understand'],
  },
  {
    // Landing Page
    id: CampaignClass.LandingPage,
    access: (account) => account.hasActivePackage,
    category: ['marketing'],
    sections: ['understand'],
  },
  {
    // Conversation
    id: CampaignClass.Conversation,
    access: (account) => account.hasActivePackage,
    category: ['marketing'],
    sections: ['understand'],
  },
  // Campaign types that require specific license packages.
  {
    // Search Keyword
    id: CampaignClass.Search,
    access: (account) => account.isFinchLegacy,
    category: ['marketing', 'monetization'],
    sections: ['expand'],
  },
  {
    // Email Mapping
    id: CampaignClass.EmailList,
    access: (account) => account.isFinchLegacy,
    category: ['marketing', 'monetization'],
    sections: ['cultivate'],
  },
  {
    // Lookalike
    id: CampaignClass.SeedSegment,
    access: (account) => account.isFinchLegacy,
    category: ['marketing', 'monetization'],
    sections: ['expand'],
  },
  {
    // Affinity
    id: CampaignClass.Affinity,
    access: (account) => account.isFinchLegacy,
    category: ['marketing', 'monetization'],
    sections: ['expand'],
  },
  {
    // Invites
    id: CampaignClass.Referral,
    access: (account) => account.isFalcon,
    category: ['marketing'],
    sections: ['expand'],
  },
  {
    // Google Ad Grants
    id: CampaignClass.GoogleAdsSmart,
    access: (account, conditions): boolean => account.hasActivePackage && conditions.showGoogleAds,
    category: ['marketing'],
    sections: ['expand'],
  },
];

export function campaignSectionTitle(section: TCampaignSection, t: TFunction): string {
  const titles: Record<TCampaignSection, string> = {
    expand: t('Expand'),
    cultivate: t('Cultivate and Activate'),
    understand: t('Understand'),
  };
  return titles[section];
}

export function campaignSectionDescription(section: TCampaignSection, t: TFunction): string {
  const titles: Record<TCampaignSection, string> = {
    expand: t(
      'Grow your community with campaigns that help you get in front of new, qualified audiences.',
    ),
    cultivate: t(
      'Nurture your community and drive impact with campaigns designed to engage and convert people you already know.',
    ),
    understand: t('Gather insights on the behavior, interests, and preferences of your community.'),
  };
  return titles[section];
}

function CampaignTypeSelect({
  campaignClasses = [],
  campaignGroup,
  defaultCategory = 'marketing',
  onSelect,
}: ICampaignTypeSelectProps): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();
  const { getUsageText } = useUsageAndQuota(account.id, [
    EUsageMetrics.EmailMappingCampaigns,
    EUsageMetrics.GeofencingCampaigns,
    EUsageMetrics.HistoricalGeofencingCampaigns,
  ]);
  const [category, setCategory] = useState<TCampaignCategory>(defaultCategory);
  const showGoogleAds = useGoogleAdsPermissions();
  const { showDripCampaigns = false } = useFlags();

  const accessConditions = {
    showGoogleAds,
    showDripCampaigns,
  };

  if (campaignClasses.length === 0) {
    types.forEach((type) => {
      campaignClasses.push(type.id);
    });
  }

  const filteredTypes = types
    .filter((type) => {
      if (type.access === undefined) {
        return false;
      }
      switch (type.id) {
        case CampaignClass.EmailList:
          type.usageText = getUsageText(EUsageMetrics.EmailMappingCampaigns);
          break;

        case CampaignClass.MobileGeoFencing:
          type.usageText = getUsageText(EUsageMetrics.GeofencingCampaigns);
          break;

        case CampaignClass.MobileGeoFenceRetargeting:
          type.usageText = getUsageText(EUsageMetrics.HistoricalGeofencingCampaigns);
          break;

        default:
      }
      return !!account && type.access(account, accessConditions);
    })
    .filter((type) => campaignClasses.find((cls) => cls === type.id))
    .filter((type) => {
      if (campaignGroup) {
        return CampaignGroupMap[campaignGroup].includes(type.id);
      }
      return true;
    });

  function handleMarketingTabClick(): void {
    setCategory('marketing');
  }

  function handleMonetizationTabClick(): void {
    setCategory('monetization');
  }

  function handleMonetizationSelect(type: CampaignClass): void {
    onSelect(type, true);
  }

  return (
    <div className={styles.root}>
      {/* Hide tabs when creating from partners page; defaultPage is monetization */}
      {defaultCategory === 'marketing' && (campaignGroup === 'all' || !campaignGroup) && (
        <SegmentedControl>
          <SegmentedControl.Item
            active={category === 'marketing'}
            name={'marketing-tab'}
            onClick={handleMarketingTabClick}
            title={t('Marketing')}
          />
          {account.hasMonetization && (
            <SegmentedControl.Item
              active={category === 'monetization'}
              name={'monetization-tab'}
              onClick={handleMonetizationTabClick}
              title={t('Monetization')}
            />
          )}
        </SegmentedControl>
      )}
      {category === 'marketing' && (
        <CampaignTypeSelectSections
          onSelect={onSelect}
          types={filteredTypes.filter((type) => type.category.includes('marketing'))}
        />
      )}
      {category === 'monetization' && (
        <CampaignTypeSelectSections
          hasSections={false}
          onSelect={handleMonetizationSelect}
          types={filteredTypes.filter((type) => type.category.includes('monetization'))}
        />
      )}
    </div>
  );
}

export default observer(CampaignTypeSelect);
