import type { IObservableArray } from 'mobx';
import React from 'react';
import ReactTable from 'react-table';

import type { DailyStat } from '@feathr/blackbox';
import { moment, TimeFormat } from '@feathr/hooks';

interface IProps {
  label: string;
  stats: IObservableArray<DailyStat>;
}

interface IRow {
  date: moment.Moment;
  total: number;
  active: number;
  cookies: number;
  leads: number;
  new: number;
}

// TODO: replace with table component
export default function SegmentTable({ stats }: IProps) {
  return (
    <>
      <ReactTable
        className={'-striped -highlight'}
        columns={[
          {
            Header: 'Date',
            accessor: 'date',
            Cell(row: { original: IRow }) {
              return row.original.date.format(TimeFormat.shortDate);
            },
            width: 95,
          },
          {
            Header: 'Total',
            columns: [
              {
                Header: 'People',
                accessor: 'total',
                Cell(row: { original: IRow }) {
                  return (
                    <div style={{ textAlign: 'right' }}>{row.original.total.toLocaleString()}</div>
                  );
                },
              },
              {
                Header: 'Cookies',
                accessor: 'cookies',
                Cell(row: { original: IRow }) {
                  return (
                    <div style={{ textAlign: 'right' }}>
                      {row.original.cookies.toLocaleString()}
                    </div>
                  );
                },
              },
              {
                Header: 'Leads',
                accessor: 'leads',
                Cell(row: { original: IRow }) {
                  return (
                    <div style={{ textAlign: 'right' }}>{row.original.leads.toLocaleString()}</div>
                  );
                },
              },
            ],
          },
          {
            Header: 'Daily',
            columns: [
              {
                Header: 'Active',
                accessor: 'active',
                Cell(row: { original: IRow }) {
                  return (
                    <div style={{ textAlign: 'right' }}>{row.original.active.toLocaleString()}</div>
                  );
                },
              },
              {
                Header: 'New',
                accessor: 'new',
                Cell(row: { original: IRow }) {
                  return (
                    <div style={{ textAlign: 'right' }}>{row.original.new.toLocaleString()}</div>
                  );
                },
              },
            ],
          },
        ]}
        data={stats.map(
          (stat) =>
            ({
              date: moment.utc(stat.get('metadata').date, moment.ISO_8601),
              total: stat.get('num_users_total', { daily: 0 }).daily,
              active: stat.get('num_users_active', { daily: 0 }).daily,
              cookies: stat.get('num_cookies_total', { daily: 0 }).daily,
              leads: stat.get('num_emails_total', { daily: 0 }).daily,
              new: stat.get('num_users_new', { daily: 0 }).daily,
            } as IRow),
        )}
        defaultPageSize={20}
        style={{ borderRadius: '4px' }}
      />
    </>
  );
}
