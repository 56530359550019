import type { JSX } from 'react';
import React from 'react';

import * as styles from './SummaryElement.css';

interface ISummaryElementProps {
  /** The title of the element. */
  title?: string;
  /** The element to display. This is the primary focus of the component. */
  element: React.ReactNode;
  /** An optional subtitle to display below the element. */
  subtitle?: React.ReactNode;
}

function SummaryElement({ title, element, subtitle }: ISummaryElementProps): JSX.Element {
  return (
    <div className={styles.root}>
      {title && <span className={styles.title}>{title}</span>}
      <div className={styles.content}>
        {element}
        {subtitle ? <span className={styles.subtitle}>{subtitle}</span> : undefined}
      </div>
    </div>
  );
}

export default SummaryElement;
