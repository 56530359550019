import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Goal as GoalModel, Segment } from '@feathr/blackbox';
import { Alert, AlertType, NumberInput, Tooltip } from '@feathr/components';
import { StoresContext, useFlags } from '@feathr/extender/state';

import ConversionModelRadios from '../ConversionModelRadios/ConversionModelRadios';
import SegmentSelect from '../SegmentSelect';
import type { ISegmentSelectSimpleChangeProps } from '../SegmentSelectSimple';
import GoalSegmentPresetSelect from './GoalSegmentPresetSelect';

import * as styles from './Goal.css';

interface IProps {
  goal: GoalModel;
  goals: IObservableArray<GoalModel>;
  onRemove?: (goal: GoalModel) => void;
  showWarning?: boolean;
  type?: 'default' | 'narrow';
  disabled?: boolean;
}

function Goal({ goal, goals, onRemove, showWarning, disabled = false }: IProps): JSX.Element {
  const { Segments } = React.useContext(StoresContext);
  const { t } = useTranslation();
  const flags = useFlags();

  function handleRemove(): void {
    if (onRemove) {
      onRemove(goal);
    }
  }

  async function handleChange({ id }: ISegmentSelectSimpleChangeProps): Promise<void> {
    if (id) {
      const newSegment = Segments.get(id);
      await when(() => !newSegment.isPending);
      goal.set({ name: newSegment.get('name'), segment: id });
    }
  }

  function handleCreate(newSegment: Segment): void {
    newSegment.set({
      lookback_value: undefined,
      mode: 'match_all',
      name: t('New Goal Group'),
    });
  }

  return (
    <SegmentSelect
      childrenOnAdd={<GoalSegmentPresetSelect goal={goal} />}
      disabled={disabled}
      excludeIds={goals.filter((g) => g.get('segment') || false).map((g) => g.get('segment')!)}
      filters={{
        goal_eligible: true,
        is_conversion_segment__ne: true,
        __raw__: { predicates: { $exists: true, $ne: [] } },
      }}
      hideIncluded={true}
      label={t('Goal group')}
      name={'segment_select'}
      onChange={handleChange}
      onCreate={handleCreate}
      onRemove={onRemove ? handleRemove : undefined}
      prefix={
        !!showWarning ? (
          <Alert type={AlertType.warning}>
            {t(
              'Edits to this goal may result in changes to conversions for the {{goalKind}} as well as other campaigns and flights with overlapping goals.',
              { goalKind: goal.get('kind') },
            )}
          </Alert>
        ) : undefined
      }
      stat={'num_crumbs_total'}
      value={goal.get('segment')}
    >
      <NumberInput
        attribute={'conv_value'}
        className={styles.input}
        disabled={disabled}
        label={
          <>
            {t('Goal value')}{' '}
            <Tooltip
              title={t(
                'This is how much it is worth to you each time someone does the action described by the goal group. For example, if your goal is to register for an event, this would be the average ticket price.',
              )}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </>
        }
        min={0}
        model={goal}
        name={'conv_value'}
        prefix={'$'}
        required={true}
        // TODO: Fix bug where previously set empty conversion values do not trigger validation errors - see #2765
        validationError={!goal.get('conv_value') ? [t('Please set a value.')] : []}
        wrapperClassName={styles.fieldset}
      />
      {flags.hasMultiConversions && <ConversionModelRadios goal={goal} />}
    </SegmentSelect>
  );
}

export default observer(Goal);
