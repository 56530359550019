import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CardV2 as Card, ETheme, SegmentedControl, TimeFrameSelect } from '@feathr/components';
import { useTimeFrameSelect } from '@feathr/extender/hooks';
import { ECardCollapseKeys, ENamespaces } from '@feathr/hooks';

import AudienceEngagementCharts from './AudienceEngagementCharts';
import AudienceEngagementStats from './AudienceEngagementStats/AudienceEngagementStats';

import * as styles from './AudienceEngagement.css';

export type TView = 'community' | 'connections' | 'conversions';

function AudienceEngagement(): JSX.Element {
  const { t } = useTranslation();
  const [view, setView] = useState<TView>('community');

  const { options, timeFrame, timeFrameValue, handleChangeTimeFrame } = useTimeFrameSelect();

  function handleChangeView(view: TView): () => void {
    return () => setView(view);
  }

  const { community, connections, conversions } = {
    community: {
      active: view === 'community',
      theme: ETheme.purple,
      onClick: handleChangeView('community'),
      title: t('Community'),
      name: 'community',
    },
    connections: {
      active: view === 'connections',
      theme: ETheme.cyan,
      onClick: handleChangeView('connections'),
      title: t('Connections'),
      name: 'connections',
    },
    conversions: {
      active: view === 'conversions',
      theme: ETheme.green,
      onClick: handleChangeView('conversions'),
      title: t('Conversions'),
      name: 'conversions',
    },
  };

  return (
    <Card contentClassName={styles.root} width={'full'}>
      <Card.Header
        isCollapsible={true}
        persist={{
          key: ECardCollapseKeys.AudienceEngagement,
          namespace: ENamespaces.AccountDashboard,
        }}
        title={t('Audience Engagement')}
        width={'full'}
      >
        <TimeFrameSelect onChange={handleChangeTimeFrame} options={options} value={timeFrame} />
      </Card.Header>
      <Card.Content
        contentClassName={styles.content}
        name={'audience-engagement-card-content'}
        padding={'relaxed'}
      >
        <AudienceEngagementStats timeFrameValue={timeFrameValue} />
        <div key={'controls'}>
          <SegmentedControl name={'audience-segmented-control'}>
            <SegmentedControl.Item {...community} />
            <SegmentedControl.Item {...connections} />
            <SegmentedControl.Item {...conversions} />
          </SegmentedControl>
        </div>
        <AudienceEngagementCharts timeFrameValue={timeFrameValue} view={view} />
      </Card.Content>
    </Card>
  );
}

export default observer(AudienceEngagement);
