import { faArrowRight, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { set } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';

import type { FieldDataType, IImportColumn, Importer } from '@feathr/blackbox';
import { defaultPartnerAttributes, FieldCollection } from '@feathr/blackbox';
import { Button, Fieldset, Input, Select } from '@feathr/components';
import CustomFieldSelect from '@feathr/extender/components/CustomFieldSelect';
import DataTypeSelect from '@feathr/extender/components/DataTypeSelect';
import { StoresContext } from '@feathr/extender/state';

import { validateCustomField } from './PartnerImportStepTwo';

import * as styles from './EventPartnersImportPage.css';

interface IImportConfigProps {
  disabled?: boolean;
  column: IImportColumn;
  importer: Importer;
}

function ImportConfig({ column, importer, disabled = false }: IImportConfigProps) {
  const { CustomFields } = useContext(StoresContext);
  const [hadFocusFromCustomField, setHadFocusFromCustomField] = useState(false);

  useEffect(() => {
    const columnName = column.column_name.trim().toLowerCase();
    defaultPartnerAttributes.forEach((attr) => {
      if (attr.id === columnName) {
        runInAction(() => {
          set(column, { feathr_attr: attr.id, attr_type: attr.data_type });
        });
      }
    });
  }, []);
  const columnOptions = (importer.get('column_names') as string[]).map((c) => ({
    id: c,
    name: c,
  }));
  const columns: IObservableArray<IImportColumn> = importer.get('column_mappers');

  function deleteColumn() {
    columns.remove(column);
  }

  function handleChangeDataType(newvalue?: FieldDataType) {
    if (isFieldSelected && !isDefaultField && customField && customField.isEphemeral) {
      customField.set({ data_type: newvalue });
    }
    set(column, { attr_type: newvalue });
  }

  function handleBlur(): void {
    setHadFocusFromCustomField(true);
  }

  const isFieldSelected = !!column.feathr_attr;
  const isDefaultField =
    isFieldSelected && defaultPartnerAttributes.some((attr) => attr.id === column.feathr_attr);
  const customField =
    isFieldSelected && !isDefaultField ? CustomFields.get(column.feathr_attr!) : undefined;

  return (
    <Fieldset direction={'column'}>
      <Select
        defaultValue={columnOptions.find((opt) => opt.id === column.column_name)}
        disabled={disabled}
        onSelectSingle={(option) => {
          column.column_name = option.id;
        }}
        options={columnOptions}
        wrapperClassName={styles.columnSelect}
      />
      <FontAwesomeIcon className={styles.arrow} icon={faArrowRight} />
      {isFieldSelected && !isDefaultField && customField && customField.isEphemeral ? (
        <Input
          attribute={'u_key'}
          className={styles.columnSelect}
          model={customField}
          onBlur={handleBlur}
          placeholder={'Enter custom field name...'}
          type={'text'}
          validationError={
            hadFocusFromCustomField ? validateCustomField(customField.name) : undefined
          }
          wrapperClassName={styles.columnSelect}
        />
      ) : (
        <CustomFieldSelect
          contexts={[FieldCollection.Partner]}
          customField={customField}
          object={column}
        />
      )}
      <DataTypeSelect
        className={styles.dataType}
        disabled={!isFieldSelected || isDefaultField || (customField && !customField.isEphemeral)}
        id={'dataTypeSelect'}
        onChange={handleChangeDataType}
        placeholder={'Select type...'}
        value={column.attr_type}
      />
      <Button
        className={styles.deleteButton}
        disabled={disabled}
        icon={<FontAwesomeIcon icon={faTrash} />}
        onClick={deleteColumn}
      />
    </Fieldset>
  );
}

export default observer(ImportConfig);
