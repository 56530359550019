import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useParams } from 'react-router';

import { Form, SaveButton, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { StoresContext, useAccount } from '@feathr/extender/state';

import PartnerDashboardContent from './PartnerDashboardContent';
import PartnerDashboardDataAccess from './PartnerDashboardDataAccess';
import PartnerDashboardModules from './PartnerDashboardModules';

function PartnerDashboardSettingsPage(): JSX.Element {
  const { eventId } = useParams<{ eventId: string }>();
  const { Events } = useContext(StoresContext);
  const account = useAccount();
  const event = Events.get(eventId);
  const actions = (
    <Observer>
      {(): JSX.Element => (
        <Toolbar>
          <SaveButton disabled={event.isPending} method={'patch'} model={event} />
        </Toolbar>
      )}
    </Observer>
  );
  return (
    <Page actions={actions} title={'Partner Dashboard Settings'}>
      <Form actions={actions} label={'Partner Dashboard Settings'}>
        <PartnerDashboardContent event={event} />
        {account.isFalcon && <PartnerDashboardDataAccess event={event} />}
        <PartnerDashboardModules event={event} />
      </Form>
    </Page>
  );
}

export default PartnerDashboardSettingsPage;
