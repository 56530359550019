import type { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import type { Campaign, IExposureSettings, TCampaignGroup } from '@feathr/blackbox';
import { REGEX_INPUT_VALIDATION } from '@feathr/blackbox';
import {
  CampaignClass,
  CampaignLabelMap,
  getMinDaysAdvance,
  leadTimeCampaigns,
} from '@feathr/blackbox';
import type { IModalProps } from '@feathr/components';
import { AlertV2, Button, EAlertV2Type, Fieldset, Input, Modal, toast } from '@feathr/components';
import ProjectSelect from '@feathr/extender/components/ProjectSelect';
import useGoogleAdsCustomer from '@feathr/extender/hooks/useGoogleAdsCustomer';
import { StoresContext, useAccount, useLocalUrl } from '@feathr/extender/state';
import { logUserEvents } from '@feathr/hooks';
import { validate } from '@feathr/rachis';

import AddCampaignModalPartnerSelect from './AddCampaignModalPartnerSelect';
import AddCampaignModalPartnerTLDR from './AddCampaignModalPartnerTLDR';
import CampaignTypeSelect from './CampaignTypeSelect';

export interface IAddCampaignModalProps extends Pick<IModalProps, 'onClose' | 'opened'> {
  campaignClasses?: CampaignClass[];
  campaignGroup?: TCampaignGroup;
  flightId?: string;
  partnerId?: string;
  /** Callback function for additional processing once a campaign is created. */
  postSave?: (campaign: Campaign) => Promise<void> | void;
  redirect?: boolean;
}

interface IModalText {
  title: string;
  description: string;
}

function getModalText(t: TFunction, campaignGroup: TCampaignGroup): IModalText {
  const campaignGroupTextMap: Record<TCampaignGroup, IModalText> = {
    ads: {
      title: t('Create an ad'),
      description: t("Choose the type of ad you'd like to create."),
    },
    all: {
      title: t('Create a campaign'),
      description: t("Choose the type of campaign you'd like to create."),
    },
    email: {
      title: t('Create an email'),
      description: t("Choose the type of email you'd like to create."),
    },
    'google-ads': {
      title: t('Create a Google Ad Grants campaign'),
      description: t("Choose the type of Google Ads campaign you'd like to create."),
    },
    monetization: {
      title: t('Create a monetization campaign'),
      description: t("Choose the type of monetization campaign you'd like to create."),
    },
    other: {
      title: t('Create campaign'),
      description: t("Choose the type of campaign you'd like to create."),
    },
  };
  return campaignGroupTextMap[campaignGroup];
}

function AddCampaignModal({
  campaignClasses,
  campaignGroup,
  flightId,
  onClose,
  partnerId: defaultPartnerId,
  postSave,
  redirect = true,
  opened,
}: IAddCampaignModalProps): JSX.Element {
  const account = useAccount();
  const { Campaigns } = useContext(StoresContext);
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { eventId: projectId } = useParams<{ eventId?: string }>();
  const [campaignType, setCampaignType] = useState<CampaignClass | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [isMonetization, setIsMonetization] = useState<boolean>(campaignGroup === 'monetization');
  const [partnerId, setPartnerId] = useState<string | undefined>(defaultPartnerId);
  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const googleAdsCustomer = useGoogleAdsCustomer();
  const googleAdsCustomerId = googleAdsCustomer?.id;

  // Update partnerId state when partnerId prop is changed.
  useEffect(() => {
    setPartnerId(defaultPartnerId);
  }, [defaultPartnerId]);

  function handleTypeChange(newCampaignType: CampaignClass, isMonetization = false): void {
    setCampaignType(newCampaignType);
    setIsMonetization(isMonetization);
  }

  function reset(): void {
    setCampaignType(undefined);
    setIsMonetization(false);
    setName(undefined);
    setPartnerId(defaultPartnerId);
  }

  function handlePreviousClick(): void {
    reset();
  }

  async function handleClose(): Promise<void> {
    await onClose();
    // Prevent data from persisting once modal is closed.
    reset();
  }

  // During this modal stage, the campaign object is not yet created, so we can't use its validate method.
  function validateCampaignName(): string | undefined {
    const error: Array<string | undefined> = validate.single(name, {
      presence: {
        allowEmpty: false,
        message: t("Can't be blank"),
      },
      format: {
        pattern: REGEX_INPUT_VALIDATION,
        flags: 'i',
        message: t('Campaign names must consist of letters, numbers, and basic symbols.'),
      },
    });
    return error ? error[0] : undefined;
  }

  // During this modal stage, the campaign object is not yet created, so we can't use its validate method.
  function validateCampaignProject(): string | undefined {
    if (projectId) {
      return;
    }
    const error: Array<string | undefined> = validate.single(selectedProjectId, {
      presence: {
        allowEmpty: false,
        message: t('^Please select a project.'),
      },
    });
    return error ? error[0] : undefined;
  }

  async function handleCreateCampaign(): Promise<void> {
    if (
      !campaignType ||
      !name ||
      !!validateCampaignName() ||
      (!partnerId && isMonetization) ||
      (isMonetization && !account.hasMonetization)
    ) {
      // This should never happen.
      throw new Error(t('Campaign type and name are required to create a new campaign!'));
    }

    let overrides: Record<string, string | IExposureSettings> = {
      name,
    };
    if (partnerId) {
      overrides = {
        ...overrides,
        parent: partnerId,
        parent_kind: 'partner',
        exposure_settings: {
          base_bid: 5,
          max_bid: 10,
          custom_bid: false,
          custom_target: false,
          target_type: 'fixed_impressions',
          target_value: 1000,
        },
      };
    }
    if (flightId) {
      overrides = {
        ...overrides,
        flight: flightId,
      };
    }

    if (googleAdsCustomerId && campaignType === CampaignClass.GoogleAdsSmart) {
      overrides.customer = googleAdsCustomerId;
      overrides.advertising_language_code = 'en';
    }

    const response = await Campaigns.addCampaign(
      campaignType,
      /*
       * If there is no projectId, we are in the account level context where the user must select a project
       * before creating a campaign. We validate that a project was selected on lines 269-271, so we can
       * assert that selectedProjectId is defined here.
       */
      projectId ?? selectedProjectId!,
      overrides,
    );
    if (postSave) {
      await postSave(response);
    }

    toast(t('Draft campaign created.'), { type: ToastType.SUCCESS });
    const userEventDetails = {
      account_id: account.id,
      campaign_id: response.id,
      campaign_name: response.name,
    };
    logUserEvents({
      'Created campaign': userEventDetails,
      [`Created ${CampaignLabelMap.get(response.get('_cls'))} campaign`]: userEventDetails,
    });
    await onClose();

    if (redirect) {
      if (campaignType === CampaignClass.LandingPage) {
        history.push(localUrl(response.getItemUrl()));
      } else {
        history.push(localUrl(response.getItemUrl('edit')));
      }
    }
  }

  const step = campaignType ? 1 : 0;

  const description =
    step === 0
      ? (campaignGroup && getModalText(t, campaignGroup).description) ??
        t("Choose the type of campaign you'd like to create.")
      : t('Name this campaign.');

  const step2Actions = [
    <Button key={'previous'} onClick={handlePreviousClick}>
      {t('Previous')}
    </Button>,
    <Button
      disabled={
        !!validateCampaignName() || !!validateCampaignProject() || (isMonetization && !partnerId)
      }
      key={'create'}
      onClick={handleCreateCampaign}
      type={'primary'}
    >
      {t('Create')}
    </Button>,
  ];

  return (
    <Modal
      allowOverflow={step === 1}
      description={description}
      onClose={handleClose}
      opened={opened}
      rightActions={step === 1 && step2Actions}
      // Only use large size for campaign select stage of the modal
      size={step === 1 ? 'default' : 'lg'}
      title={(campaignGroup && getModalText(t, campaignGroup).title) ?? t('Create a campaign')}
    >
      {step === 0 ? (
        <CampaignTypeSelect
          campaignClasses={campaignClasses}
          campaignGroup={campaignGroup}
          defaultCategory={
            defaultPartnerId || campaignGroup === 'monetization' ? 'monetization' : 'marketing'
          }
          onSelect={handleTypeChange}
        />
      ) : (
        <>
          <Fieldset>
            {!!step && campaignType && leadTimeCampaigns.includes(campaignType) && (
              <AlertV2
                title={t(
                  'This type of campaign must be published at least {{count}} day prior to the intended start date.',
                  { count: getMinDaysAdvance(campaignType) },
                )}
                type={EAlertV2Type.info}
              />
            )}
            {!projectId && (
              <ProjectSelect onChangeSingle={setSelectedProjectId} value={selectedProjectId} />
            )}
            <Input
              label={t('Name')}
              onChange={setName}
              placeholder={t('Enter a campaign name...')}
              validationError={name ? validateCampaignName() : undefined}
              value={name}
            />
          </Fieldset>
          {isMonetization &&
            (defaultPartnerId ? (
              <AddCampaignModalPartnerTLDR value={defaultPartnerId} />
            ) : (
              <AddCampaignModalPartnerSelect
                eventId={projectId ?? selectedProjectId}
                onChange={setPartnerId}
              />
            ))}
        </>
      )}
    </Modal>
  );
}

export default observer(AddCampaignModal);
