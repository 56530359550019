import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { DataRequest } from '@feathr/blackbox';
import { Session } from '@feathr/blackbox';
import { FormSummaryItem, Spinner } from '@feathr/components';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';
import { isWretchError, wretch } from '@feathr/rachis';

import * as styles from './GTMPermissionDisplay.css';

interface IProps {
  dataRequest: DataRequest;
  displayType: 'short' | 'long';
}

export type GTMPermissionState =
  | 'Unrequested'
  | 'Fetch Error'
  | 'JSON Error'
  | 'Pending'
  | 'Forbidden'
  | 'Read'
  | 'Edit'
  | 'Approve'
  | 'Publish';

interface IGTMPermissionStateProps {
  gtmPermissionState: GTMPermissionState;
}

const gtmPermStyleMap: Record<GTMPermissionState, string> = {
  Unrequested: styles.normal,
  'Fetch Error': styles.danger,
  'JSON Error': styles.danger,
  Pending: styles.disabled,
  Forbidden: styles.danger,
  Read: styles.danger,
  Edit: styles.warning,
  Approve: styles.warning,
  Publish: styles.success,
};

const getHeaders = (): HeadersInit => Session.getHeaders();

function parseGTMState(permissionObjects, gtmContainer): GTMPermissionState {
  if (!permissionObjects.filter) {
    return 'JSON Error';
  }

  const matchingPermission = permissionObjects.filter((permissionObject) => {
    return (
      !!permissionObject.permission &&
      !!permissionObject.publicId &&
      permissionObject.publicId === `GTM-${gtmContainer}`
    );
  })[0];

  if (!matchingPermission) {
    return 'Forbidden';
  }

  let permission: GTMPermissionState;

  switch (matchingPermission.permission) {
    case 'publish':
      permission = 'Publish';
      break;

    case 'edit':
      permission = 'Edit';
      break;

    case 'approve':
      permission = 'Approve';
      break;

    case 'read':
      permission = 'Read';
      break;

    default:
      permission = 'Forbidden';
  }

  return permission;
}

function ShortGTMPermissionDisplay({ gtmPermissionState }: IGTMPermissionStateProps): JSX.Element {
  const { t } = useTranslation();

  const gtmPermissionStateMap: Record<GTMPermissionState, string> = {
    Unrequested: t('Unrequested'),
    Forbidden: t('Forbidden'),
    Read: t('Read'),
    Edit: t('Edit'),
    Approve: t('Approve'),
    Publish: t('Publish'),
    Pending: t('Loading'),
    'JSON Error': t('Error'),
    'Fetch Error': t('Error'),
  };

  return (
    <FormSummaryItem
      className={gtmPermStyleMap[gtmPermissionState]}
      label={t('GTM Permission')}
      value={gtmPermissionStateMap[gtmPermissionState]}
    />
  );
}

function LongGTMPermissionDisplay({ gtmPermissionState }: IGTMPermissionStateProps): JSX.Element {
  const { t } = useTranslation();
  let component: JSX.Element;

  switch (gtmPermissionState) {
    case 'Publish':
      component = (
        <Trans t={t}>
          <p>
            Feathr has <strong className={gtmPermStyleMap['Publish']}>Publish</strong> access.
          </p>
        </Trans>
      );
      break;

    case 'Approve':

    case 'Edit':
      component = (
        <Trans t={t}>
          <p>
            Feathr has{' '}
            <strong className={gtmPermStyleMap[gtmPermissionState]}>{gtmPermissionState}</strong>{' '}
            access. We will be able to implement your data request, but the owner of the container
            will need to publish the container when it is ready.
          </p>
        </Trans>
      );
      break;

    case 'Forbidden':
      component = (
        <Trans t={t}>
          <p>
            Feathr does not have access to this container. Please double check your account ID and
            container ID and try again.
            <br />
            You may still make the request, but be sure to give Feathr access to your GTM container.
          </p>
        </Trans>
      );
      break;

    case 'Read':
      component = (
        <Trans t={t}>
          <p>
            Feathr has <strong className={gtmPermStyleMap['Read']}>Read</strong> access to this
            container. You may still make the request, but we will not be able to implement this
            request until we are given <strong>Publish, Approve, or Edit </strong> access to the
            container.
          </p>
        </Trans>
      );
      break;

    case 'Pending':
      component = <Spinner size={40} />;
      break;

    case 'JSON Error':

    case 'Fetch Error':
      component = (
        <Trans t={t}>
          <p>
            There was an error. Please submit your request and we will verify permissions manually.
          </p>
        </Trans>
      );
      break;

    default:
      component = <></>;
  }

  return component;
}

function parsedAccountId(accountId: string): string {
  const accountUrlMatch = accountId.trim().match(/accounts\/([\w]+)/);
  if (!!accountUrlMatch && accountUrlMatch.length > 1) {
    return accountUrlMatch[1];
  } else {
    return accountId;
  }
}

function parsedContainerId(containerId: string): string {
  if (containerId.toUpperCase().indexOf('GTM-') === 0) {
    return containerId.trim().slice(4);
  } else {
    return containerId.trim();
  }
}

interface IFetchGTMPermissionsResponse extends Record<string, unknown> {
  name: string;
  permission: string;
  publicId: string;
}

function GTMPermissionDisplay({ dataRequest, displayType }: IProps): JSX.Element {
  const [gtmPermissionState, setGTMPermissionState] = useState<GTMPermissionState>('Unrequested');

  // TODO: Move this function to data_requests.ts.
  const validateGTM = useMemo(() => {
    return debounce(async () => {
      if (
        !!dataRequest.attributes.gtm_account_id &&
        !!dataRequest.attributes.gtm_container &&
        dataRequest.isValid(['gtm_container', 'gtm_account_id'], true)
      ) {
        setGTMPermissionState('Pending');

        const headers = {
          'Content-Type': 'application/json',
          ...getHeaders(),
        };

        const accountId = parsedAccountId(dataRequest.attributes.gtm_account_id);

        const url = `${BLACKBOX_URL}gtm_permissions/?account_id=${accountId}`;

        const response = await wretch<IFetchGTMPermissionsResponse>(url, {
          headers: headers,
          method: 'GET',
        });
        if (isWretchError(response)) {
          switch (response.error.message) {
            case 'Fetch succeeded but response was not valid JSON.':
              setGTMPermissionState('JSON Error');
              break;

            default:
              setGTMPermissionState('Fetch Error');
          }
          return;
        }
        if (response.data) {
          setGTMPermissionState(
            parseGTMState(response.data, parsedContainerId(dataRequest.attributes.gtm_container)),
          );
        }
      } else {
        setGTMPermissionState('Unrequested');
      }
    }, DEFAULT_DEBOUNCE_WAIT);
  }, [dataRequest]);

  useEffect(() => {
    validateGTM();
  }, [validateGTM, dataRequest.attributes.gtm_account_id, dataRequest.attributes.gtm_container]);

  if (displayType === 'long') {
    return <LongGTMPermissionDisplay gtmPermissionState={gtmPermissionState} />;
  } else {
    return <ShortGTMPermissionDisplay gtmPermissionState={gtmPermissionState} />;
  }
}

export default observer(GTMPermissionDisplay);
