import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faArrowDownArrowUp,
  faArrowsRotate,
  faCalendar,
  faCheckCircle,
  faCheckSquare,
  faCircleXmark,
  faDown,
  faHashtag,
  faLink,
  faLinkSlash,
  faList,
  faPause,
  faText,
  faTriangleExclamation,
  faUp,
} from '@fortawesome/pro-regular-svg-icons';
import type { TFunction } from 'i18next';

import type { TIntegrationDataType } from '@feathr/blackbox';
import {
  EiMISContactFieldMappingKeys,
  EIntegrationConnectionState,
  EIntegrationSyncRule,
  EIntegrationSyncState,
  FieldDataType,
} from '@feathr/blackbox';

export function templateTypeColorState(type: EIntegrationConnectionState): string {
  const typeMap: Record<EIntegrationConnectionState, string> = {
    [EIntegrationConnectionState.connected]: 'green',
    [EIntegrationConnectionState.disconnected]: 'orange',
    [EIntegrationConnectionState.notConnected]: 'default',
    [EIntegrationConnectionState.failed]: 'red',
    [EIntegrationConnectionState.paused]: 'yellow',
    [EIntegrationConnectionState.updating_connection]: 'green',
  };
  return typeMap[type];
}

export function templateTypeIconState(type: EIntegrationConnectionState): IconDefinition {
  const typeMap: Record<EIntegrationConnectionState, IconDefinition> = {
    [EIntegrationConnectionState.connected]: faLink,
    [EIntegrationConnectionState.disconnected]: faLinkSlash,
    [EIntegrationConnectionState.notConnected]: faCircleXmark,
    [EIntegrationConnectionState.failed]: faTriangleExclamation,
    [EIntegrationConnectionState.paused]: faPause,
    [EIntegrationConnectionState.updating_connection]: faLink,
  };
  return typeMap[type];
}

export function templateTypeIconSyncRule(type: EIntegrationSyncRule): IconDefinition {
  const typeMap: Record<EIntegrationSyncRule, IconDefinition> = {
    [EIntegrationSyncRule.downSync]: faDown,
    [EIntegrationSyncRule.upSync]: faUp,
    [EIntegrationSyncRule.bothWays]: faArrowDownArrowUp,
  };
  return typeMap[type];
}

export function templateTypeIconSyncState(type: EIntegrationSyncState): IconDefinition {
  const typeMap: Record<EIntegrationSyncState, IconDefinition> = {
    [EIntegrationSyncState.notSynced]: faCircleXmark,
    [EIntegrationSyncState.syncInProgress]: faArrowsRotate,
    [EIntegrationSyncState.syncComplete]: faCheckCircle,
  };
  return typeMap[type];
}

export function templateTypeColorSyncState(type: EIntegrationSyncState): string {
  const typeMap: Record<EIntegrationSyncState, string> = {
    [EIntegrationSyncState.notSynced]: 'default',
    [EIntegrationSyncState.syncInProgress]: 'yellow',
    [EIntegrationSyncState.syncComplete]: 'green',
  };
  return typeMap[type];
}

export function templateFieldDataTypeIcon(type: keyof typeof FieldDataType): IconDefinition {
  const icons: Record<TIntegrationDataType, IconDefinition> = {
    [FieldDataType.bool]: faCheckSquare,
    [FieldDataType.date]: faCalendar,
    [FieldDataType.float]: faHashtag,
    [FieldDataType.int]: faHashtag,
    [FieldDataType.list]: faList,
    [FieldDataType.str]: faText,
  };
  return icons[type];
}

export const iMISContactFieldTranslationMap = (
  t: TFunction,
  field: keyof typeof EiMISContactFieldMappingKeys,
): string => {
  const map: Record<EiMISContactFieldMappingKeys, string> = {
    [EiMISContactFieldMappingKeys.address]: t('Address'),
    [EiMISContactFieldMappingKeys.average_gift_value]: t('Average Gift Value'),
    [EiMISContactFieldMappingKeys.background_check_valid_until]: t('Background Check Valid Until'),
    [EiMISContactFieldMappingKeys.birth_date]: t('Birth Date'),
    [EiMISContactFieldMappingKeys.category]: t('Category'),
    [EiMISContactFieldMappingKeys.chapter]: t('Chapter'),
    [EiMISContactFieldMappingKeys.companies]: t('Companies'),
    [EiMISContactFieldMappingKeys.company_member_type]: t('Company Member Type'),
    [EiMISContactFieldMappingKeys.contact_rank]: t('Contact Rank'),
    [EiMISContactFieldMappingKeys.contact_status_code]: t('Contact Status Code'),
    [EiMISContactFieldMappingKeys.consecutive_years_giving]: t('Consecutive Years Giving'),
    [EiMISContactFieldMappingKeys.date_added]: t('Date Added'),
    [EiMISContactFieldMappingKeys.date_created]: t('Date Created'),
    [EiMISContactFieldMappingKeys.designation]: t('Designation'),
    [EiMISContactFieldMappingKeys.email]: t('Primary email'),
    [EiMISContactFieldMappingKeys.external_id]: t('Contact Key'),
    [EiMISContactFieldMappingKeys.fax]: t('Fax'),
    [EiMISContactFieldMappingKeys.first_gift_amount]: t('First Gift Amount'),
    [EiMISContactFieldMappingKeys.first_gift_appeal]: t('First Gift Appeal'),
    [EiMISContactFieldMappingKeys.first_gift_date]: t('First Gift Date'),
    [EiMISContactFieldMappingKeys.first_gift_product_code]: t('First Gift Product Code'),
    [EiMISContactFieldMappingKeys.first_name]: t('First Name'),
    [EiMISContactFieldMappingKeys.functional_title]: t('Functional Title'),
    [EiMISContactFieldMappingKeys.gender]: t('Gender'),
    [EiMISContactFieldMappingKeys.highest_gift_amount]: t('Highest Gift Amount'),
    [EiMISContactFieldMappingKeys.highest_trans_num_processed]: t(
      'Highest Transaction Number Processed',
    ),
    [EiMISContactFieldMappingKeys.home_phone]: t('Home Phone'),
    [EiMISContactFieldMappingKeys.informal_name]: t('Informal Name'),
    [EiMISContactFieldMappingKeys.is_member]: t('Is Member'),
    [EiMISContactFieldMappingKeys.join_date]: t('Join Date'),
    [EiMISContactFieldMappingKeys.last_gift_amount]: t('Last Gift Amount'),
    [EiMISContactFieldMappingKeys.last_gift_appeal]: t('Last Gift Appeal'),
    [EiMISContactFieldMappingKeys.last_gift_date]: t('Last Gift Date'),
    [EiMISContactFieldMappingKeys.last_gift_product_code]: t('Last Gift Product Code'),
    [EiMISContactFieldMappingKeys.last_name]: t('Last Name'),
    [EiMISContactFieldMappingKeys.last_updated]: t('Last Updated'),
    [EiMISContactFieldMappingKeys.lifetime_gift_value]: t('Lifetime Gift Value'),
    [EiMISContactFieldMappingKeys.lowest_gift_amount]: t('Lowest Gift Amount'),
    [EiMISContactFieldMappingKeys.major_key]: t('Major Key'),
    [EiMISContactFieldMappingKeys.member_status]: t('Member Status'),
    [EiMISContactFieldMappingKeys.member_status_date]: t('Member Status Date'),
    [EiMISContactFieldMappingKeys.member_type]: t('Member Type'),
    [EiMISContactFieldMappingKeys.member_type_change_date]: t('Member Type Change Date'),
    [EiMISContactFieldMappingKeys.middle_name]: t('Middle Name'),
    [EiMISContactFieldMappingKeys.mobile_phone]: t('Mobile Phone'),
    [EiMISContactFieldMappingKeys.name]: t('Name'),
    [EiMISContactFieldMappingKeys.name_prefix]: t('Name Prefix'),
    [EiMISContactFieldMappingKeys.name_suffix]: t('Name Suffix'),
    [EiMISContactFieldMappingKeys.next_last_gift_amount]: t('Next to Last Gift Amount'),
    [EiMISContactFieldMappingKeys.next_last_gift_appeal]: t('Next to Last Gift Appeal'),
    [EiMISContactFieldMappingKeys.next_last_gift_date]: t('Next to Last Gift Date'),
    [EiMISContactFieldMappingKeys.next_last_gift_product_code]: t('Next to Last Gift Product Code'),
    [EiMISContactFieldMappingKeys.number_of_gifts]: t('Number of Gifts'),
    [EiMISContactFieldMappingKeys.paid_through]: t('Paid Through'),
    [EiMISContactFieldMappingKeys.party_id]: t('Party ID'),
    [EiMISContactFieldMappingKeys.previous_member_type]: t('Previous Member Type'),
    [EiMISContactFieldMappingKeys.status]: t('Status'),
    [EiMISContactFieldMappingKeys.title]: t('Title'),
    [EiMISContactFieldMappingKeys.toll_free]: t('Toll Free'),
    [EiMISContactFieldMappingKeys.volunteer_age_group]: t('Volunteer: Age Group'),
    [EiMISContactFieldMappingKeys.volunteer_available_beginning]: t(
      'Volunteer: Available Beginning',
    ),
    [EiMISContactFieldMappingKeys.volunteer_background_check_status]: t(
      'Volunteer: Background Check Status',
    ),
    [EiMISContactFieldMappingKeys.volunteer_completed_orientation]: t(
      'Volunteer: Completed Orientation',
    ),
    [EiMISContactFieldMappingKeys.volunteer_emergency_contact]: t('Volunteer: Emergency Contact'),
    [EiMISContactFieldMappingKeys.volunteer_emergency_contact_phone]: t(
      'Volunteer: Emergency Contact Phone',
    ),
    [EiMISContactFieldMappingKeys.volunteer_government_id_number]: t(
      'Volunteer: Government ID Number',
    ),
    [EiMISContactFieldMappingKeys.volunteer_is_volunteer]: t('Volunteer: Is Volunteer'),
    [EiMISContactFieldMappingKeys.volunteer_orientation_valid_until]: t(
      'Volunteer: Orientation Valid Until',
    ),
    [EiMISContactFieldMappingKeys.website]: t('Website'),
    [EiMISContactFieldMappingKeys.work_phone]: t('Work Phone'),
  };

  return map[field];
};

export const iMISSyncRuleTranslationMap = (t: TFunction, type: EIntegrationSyncRule): string => {
  const map: Record<EIntegrationSyncRule, string> = {
    [EIntegrationSyncRule.downSync]: t('Down sync'),
    [EIntegrationSyncRule.upSync]: t('Up sync'),
    [EIntegrationSyncRule.bothWays]: t('Both ways'),
  };
  return map[type];
};

export const iMISFieldDataTypeLabelMap = (
  t: TFunction,
  type: keyof typeof FieldDataType,
): string => {
  const map: Record<FieldDataType, string> = {
    [FieldDataType.bool]: t('True/False'),
    [FieldDataType.date]: t('Date'),
    [FieldDataType.float]: t('Number'),
    [FieldDataType.int]: t('Number'),
    [FieldDataType.list]: t('List'),
    [FieldDataType.str]: t('Text'),
  };

  return map[type];
};
