import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import capitalize from 'lodash.capitalize';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { Button, Input, Textarea } from '@feathr/components';

import * as styles from './InputListItem.css';

export interface IInputListItemSharedProps {
  readonly maxLength: number;
  readonly label?: string;
  readonly onChange: (index: number, value: string) => void;
  readonly onRemove?: (index: number) => void;
  readonly removeTooltip: string;
  readonly type?: 'input' | 'textarea';
}

interface IInputListItemProps extends IInputListItemSharedProps {
  readonly canRemove: boolean;
  readonly item: string;
  readonly index: number;
}

function InputListItem({
  canRemove,
  index,
  item,
  maxLength,
  onChange,
  onRemove,
  label,
  removeTooltip,
  type = 'input',
}: IInputListItemProps): JSX.Element {
  function handleRemove(): void {
    onRemove && onRemove(index);
  }

  function handleChange(value: string | undefined): void {
    const newValue = value ?? '';
    onChange(index, newValue);
  }

  const sharedProps = {
    label: `${capitalize(label)} ${index + 1}`,
    maxLength: maxLength,
    onChange: handleChange,
    showCounter: true,
    value: item,
  };

  return (
    <div className={styles.root}>
      {type === 'input' ? <Input {...sharedProps} /> : <Textarea {...sharedProps} />}
      {onRemove && (
        <Button
          disabled={!canRemove}
          onClick={handleRemove}
          tooltip={!canRemove && removeTooltip}
          type={'icon'}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      )}
    </div>
  );
}

export default observer(InputListItem);
