import type { RowRenderProps } from 'react-table';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';

export interface ICampaignRow extends RowRenderProps {
  original: BaseCampaign;
}

export default function CampaignsColumns(
  columns: Array<IColumn<BaseCampaign>>,
  columnIds: string[],
): Array<IColumn<BaseCampaign>> {
  const filterColumns = (def: IColumn<BaseCampaign>): boolean =>
    columnIds.includes(def.id as string);
  return columns.filter(filterColumns);
}
