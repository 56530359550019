import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import validate from 'validate.js';

import type { IBankAccountSource } from '@feathr/blackbox';
import { sourceValidation } from '@feathr/blackbox';
import { Checkbox, Form, Input } from '@feathr/components';
import { useToggle } from '@feathr/hooks';

import * as styles from './BankAccountInput.css';

interface IProps {
  children: (onSave: () => Promise<IBankAccountSource>, element: JSX.Element) => JSX.Element;
}

/**
 * Retrieves a token for a bank account using the provided routing number, account number, and account holder name.
 *
 * @param routing_number - The routing number of the bank account.
 * @param account_number - The account number of the bank account.
 * @param account_holder_name - The name of the account holder.
 * @returns A promise that resolves to an object containing bank account details.
 * @throws Error if the information entered is invalid or if there is an issue adding the bank account.
 */
async function getToken(
  /* eslint-disable @typescript-eslint/naming-convention */
  routing_number: string | null,
  account_number: string | null,
  account_holder_name: string | null,
  /* eslint-enable @typescript-eslint/naming-convention */
  approved: boolean,
  t: TFunction,
): Promise<IBankAccountSource> {
  if (!approved) {
    throw new Error(t('You must accept the terms and conditions before continuing.'));
  }

  // Let errors bubble up to the caller
  const response = await global.Stripe(STRIPE_API_KEY).createToken('bank_account', {
    country: 'US',
    currency: 'usd',
    routing_number: routing_number ?? undefined,
    account_number: account_number ?? undefined,
    account_holder_name: account_holder_name ?? undefined,
    account_holder_type: 'individual',
    // Casting to BankAccountTokenOptions to fix the wrong overload being used.
  } as stripe.BankAccountTokenOptions);

  if (response.error) {
    throw new Error(
      t(
        'The information you entered is invalid. Please correct your bank account details and try again.',
      ),
    );
  }
  if (!response.token) {
    throw new Error(t('There was an issue adding bank account.'));
  }

  const { id } = response.token;
  const bankAccount = response.token.bank_account!;
  return {
    account_holder_name: bankAccount.account_holder_name,
    bank_name: bankAccount.bank_name,
    id,
    last4: bankAccount.last4,
    status: bankAccount.status,
  };
}

function BankAccountInput({ children }: Readonly<IProps>): JSX.Element {
  const [routingNumber, setRoutingNumber] = useState<string | null>(null);
  const [accountNumber, setAccountNumber] = useState<string | null>(null);
  const [accountHolderName, setAccountHolderName] = useState<string | null>(null);
  const [approved, toggleApproved] = useToggle(false);
  const { t } = useTranslation();

  const validation =
    validate(
      {
        routing_number: routingNumber,
        account_number: accountNumber,
        account_holder_name: accountHolderName,
      },
      sourceValidation,
    ) || {};

  function handleRoutingNumberChange(newValue?: string): void {
    setRoutingNumber(newValue ?? '');
  }

  function handleAccountNumberChange(newValue?: string): void {
    setAccountNumber(newValue ?? '');
  }

  function handleAccountHolderNameChange(newValue?: string): void {
    setAccountHolderName(newValue ?? '');
  }

  async function onSave(): Promise<IBankAccountSource> {
    return getToken(routingNumber, accountNumber, accountHolderName, approved, t);
  }

  const element = (
    <Form label={t('Enter ACH details')}>
      <Input
        label={t('Routing number')}
        onChange={handleRoutingNumberChange}
        type={'text'}
        validationError={routingNumber !== null && validation.routing_number}
        value={routingNumber || undefined}
      />
      <Input
        label={t('Account number')}
        onChange={handleAccountNumberChange}
        type={'text'}
        validationError={accountNumber !== null && validation.account_number}
        value={accountNumber || undefined}
      />
      <Input
        label={t('Account holder name')}
        onChange={handleAccountHolderNameChange}
        type={'text'}
        validationError={accountHolderName !== null && validation.account_holder_name}
        value={accountHolderName || undefined}
      />
      <div className={styles.authorize}>
        <Checkbox
          label={
            <Trans t={t}>
              I authorize Feathr, Inc. to electronically debit my our organization's account (and,
              if necessary, electronically credit our account to correct erroneous debits) at the
              depository financial institution named below. We agree that ACH transactions we
              authorize comply with all applicable law.The dollar amount of debit(s) will be based
              on Feathr's monthly invoice schedule and the timing of debit(s) will be based on the
              payment terms agreed to in the applicable order form executed by Customer and
              Feathr.We understand that this authorization will remain in full force and effect
              until we notify Feathr via email in writing at{' '}
              <a href={'mailto:accounting@feathr.co'}>accounting@feathr.co</a> that we wish to
              revoke this authorization. We understand that Feathr requires at least 15 days prior
              to the next billing date to reflect such revocation.
            </Trans>
          }
          onChange={toggleApproved}
          value={approved}
        />
      </div>
    </Form>
  );

  return <>{children(onSave, element)}</>;
}

export default BankAccountInput;
