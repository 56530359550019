import type { IBaseAttributes } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

interface IGeoFilterAddress {
  country: string;
  region: string;
}

export interface IGeoFilter extends IBaseAttributes {
  address: IGeoFilterAddress;
  iso_code: string;
  kind: 'Country' | 'Region' | 'City';
  name: string;
}

export class GeoFilter extends Model<IGeoFilter> {
  public readonly className = 'GeoFilter';

  public constraints = {};
}

export class GeoFilters extends Collection<GeoFilter> {
  public getClassName() {
    return 'geofilters';
  }

  public getModel(attributes: Partial<IGeoFilter>) {
    return new GeoFilter(attributes);
  }
}
