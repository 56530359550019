import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { CampaignClass, CampaignState, TCampaignGroup } from '@feathr/blackbox';
import { DateRangeSelects } from '@feathr/components';
import PartnerSelect from '@feathr/extender/components/PartnerSelect';
import ProjectSelect from '@feathr/extender/components/ProjectSelect';
import { useAccount } from '@feathr/extender/state';

import CampaignStateSelect from './CampaignStateSelect';
import CampaignTypeSelect from './CampaignTypeSelect';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';

export interface IFilters {
  date_end?: string;
  date_start?: string;
  event__in?: string[];
  participants?: string[];
  partner?: string;
  state__in?: CampaignState[];
  types?: Array<CampaignClass | 'monetization'>;
}

interface IProps {
  campaignGroup: TCampaignGroup;
  context: 'global' | 'project';
  filters: IFilters;
  onChange: (filters: IFilters) => void;
}

function CampaignsFilters({ campaignGroup, context, filters }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();

  function setPartner(partner?: string): void {
    runInAction(() => {
      filters.partner = partner;
    });
  }

  function setParticipants(participants?: string[]): void {
    runInAction(() => {
      filters.participants = participants;
    });
  }

  function setProjectIds(projects?: string[]): void {
    runInAction(() => {
      filters.event__in = projects;
    });
  }

  function setStates(states?: CampaignState[]): void {
    runInAction(() => {
      filters.state__in = states;
    });
  }

  function setTypes(types?: Array<CampaignClass | 'monetization'>): void {
    runInAction(() => {
      filters.types = types;
    });
  }

  function handleChangeDateRange(newDates?: [string | undefined, string | undefined]): void {
    if (!newDates) {
      return;
    }
    runInAction(() => {
      filters.date_start = newDates[0];
      filters.date_end = newDates[1];
    });
  }

  const showPartnerSelects =
    account && account.isFalcon && ['all', 'monetization'].includes(campaignGroup);
  const showTypeSelect = campaignGroup !== 'google-ads';
  const showProjectSelect = context === 'global';

  return (
    <>
      {showProjectSelect && (
        <ProjectSelect onChangeMulti={setProjectIds} values={filters.event__in} />
      )}
      <CampaignStateSelect onChange={setStates} value={filters.state__in} />
      {showTypeSelect && (
        <CampaignTypeSelect
          campaignGroup={campaignGroup}
          onChange={setTypes}
          value={filters.types}
        />
      )}
      {showPartnerSelects && (
        <>
          <PartnerSelect
            isMulti={true}
            label={t('Participants')}
            onChange={setParticipants}
            onClear={setParticipants}
            placeholder={t('All participants')}
            projectIds={filters.event__in}
            value={filters.participants ? filters.participants : []}
          />
          <PartnerSelect
            label={t('Partners')}
            onChange={setPartner}
            onClear={setPartner}
            placeholder={t('All partners')}
            projectIds={filters.event__in}
            value={filters.partner}
          />
        </>
      )}
      <DateRangeSelects
        onChange={handleChangeDateRange}
        t={t}
        value={[filters.date_start, filters.date_end]}
      />
    </>
  );
}

export default observer(CampaignsFilters);
