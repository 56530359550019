import type { WithT } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ToastType } from 'react-toastify';

import type { ImisIntegration } from '@feathr/blackbox';
import { ButtonValid, toast } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface INextStepButtonProps extends WithT {
  integration: ImisIntegration;
  validation: (integration: ImisIntegration) => TValidateGrouped;
  externalErrors?: string[];
}

const FinishSetupButton = observer(
  ({ externalErrors, integration, t, validation }: INextStepButtonProps): React.JSX.Element => {
    const validationErrors = validation(integration);
    const errors = flattenErrors(validationErrors);
    errors.push(...(externalErrors ?? []));

    const { ImisIntegrations } = useContext(StoresContext);

    async function handleOnClick(): Promise<void> {
      try {
        await ImisIntegrations.finishIntegration(integration);
      } catch (error) {
        toast(
          t(
            'Something went wrong while trying to finish setting up your iMIS integration: {{error}}',
            {
              error,
            },
          ),
          {
            type: ToastType.ERROR,
          },
        );
      }
    }

    return (
      <ButtonValid errors={errors} name={'finish-setup'} onClick={handleOnClick}>
        {t('Finish iMIS setup')}
      </ButtonValid>
    );
  },
);

export default FinishSetupButton;
