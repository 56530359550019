import capitalize from 'lodash.capitalize';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export function param(params: Record<string, string> = {}): string {
  return new URLSearchParams(params).toString();
}

export function setUrlParams(params: Record<string, string> = {}): void {
  const urlParams = new URLSearchParams(window.location.search);
  Object.entries(params).forEach(([key, v]: any[]) => {
    let value = v;
    if (value !== undefined) {
      if (typeof value === 'object') {
        value = JSON.stringify(v);
      }
      if (urlParams.has(key)) {
        urlParams.set(key, value);
      } else {
        urlParams.append(key, value);
      }
    }
  });

  window.history.pushState(
    null,
    document.title,
    `${window.location.pathname}?${urlParams.toString()}`,
  );
}

export function cookies(): Map<string, string> {
  const cookiesSplit = document.cookie.split('; ');
  const tuples = cookiesSplit.map((c) => c.split('='));
  const cookieMap = new Map<string, string>(tuples as any);
  return cookieMap;
}

export function roundFloat(num: number, numDecimalPlaces = 1): number {
  const rounded = Math.round(parseFloat(num + 'e' + numDecimalPlaces));
  return Number(rounded + 'e' + -numDecimalPlaces);
}

export function floorFloat(num: number, numDecimalPlaces = 1): number {
  const rounded = Math.floor(parseFloat(num + 'e' + numDecimalPlaces));
  return Number(rounded + 'e' + -numDecimalPlaces);
}

export function concatenateAndRoundNumber(
  num: number,
  roundDown = false,
  numDecimalPlaces = 1,
): string {
  if (num >= 1e15) {
    throw new Error('Only numbers in the trillions and below are supported');
  }

  const charMap = ['', 'k', 'm', 'b', 't', 'q'];
  let currCharIndex = 0;

  /*
   * Convert numbers to decimal values and add suffix denoting thousands,
   * millions, billions or trillions.
   * ie: 9,999,999 => 9.999999m
   */

  // 0 - 999
  let decimal = num;

  // 1,000 - 999,999
  if (num >= 1e3 && num < 1e6) {
    currCharIndex = 1;
    decimal = Math.abs(Number(num)) / 1.0e3;
  }

  // 1,000,000 - 999,999,999
  if (num >= 1e6 && num < 1e9) {
    currCharIndex = 2;
    decimal = Math.abs(Number(num)) / 1.0e6;
  }

  // 1,000,000,000 - 999,999,999,999
  if (num >= 1e9 && num < 1e12) {
    currCharIndex = 3;
    decimal = Math.abs(Number(num)) / 1.0e9;
  }

  // 1,000,000,000,000 - 999,999,999,999,999
  if (num >= 1e12 && num < 1e15) {
    currCharIndex = 4;
    decimal = Math.abs(Number(num)) / 1.0e12;
  }

  // Round the number up or down
  const roundedNumber = roundDown
    ? floorFloat(decimal, numDecimalPlaces)
    : roundFloat(decimal, numDecimalPlaces);

  /*
   * Return the rounded number with the suffix added above
   * If the number is rounded to 1000 that means we must skip to the next bracket of numbers
   * ie: 999,999 -> 1m
   */
  return roundedNumber === 1000
    ? '1' + charMap[currCharIndex + 1]
    : roundedNumber + charMap[currCharIndex];
}

export function formatLabel(key: string): string {
  return capitalize(key).replaceAll('_', ' ').replaceAll('-', ' ').replace('.', ' ');
}
