import type { WithT } from 'i18next';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import type { EmailVerification } from '@feathr/blackbox';
import {
  AddressInput,
  Button,
  EmailInput,
  Fieldset,
  Form,
  Input,
  SaveButtonValid,
  toast,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { flattenError, flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';
import { validate } from '@feathr/rachis';

import * as styles from './EmailEditPage.css';

interface ISaveButtonProps {
  email: EmailVerification;
  onSave: () => Promise<void>;
}

interface IErrors extends TValidateGrouped {
  'address.company_name'?: string[];
  'address.premise1'?: string[];
  'address.locality'?: string[];
  'address.administrative_area_name'?: string[];
  'address.postal_code'?: string[];
  'address.country_code'?: string[];
  email?: string[];
  from_name?: string[];
  status?: string[];
}

const SaveButton = observer(({ email, onSave, t }: ISaveButtonProps & WithT) => {
  const validationErrors = email.validate(
    [
      'from_name',
      'address.premise1',
      'address.locality',
      'address.administrative_area_name',
      'address.postal_code',
      'address.country_code',
    ],
    false,
  );
  return (
    <SaveButtonValid<EmailVerification>
      errors={toJS(validationErrors.errors)}
      method={email.isEphemeral ? 'add' : 'patch'}
      model={email}
      onSave={onSave}
    >
      {t('Save')}
    </SaveButtonValid>
  );
});

function EmailEditPage(): JSX.Element {
  const { EmailVerifications } = useContext(StoresContext);
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { emailId } = useParams<{ emailId: string }>();
  const { t } = useTranslation();

  const email = EmailVerifications.get(emailId);

  async function onSave() {
    const hasIncompleteAddress = flattenErrors(
      email.validate(
        [
          'address.premise1',
          'address.locality',
          'address.administrative_area_name',
          'address.postal_code',
          'address.country_code',
        ],
        false,
        'grouped',
      ).errors,
    ).length;
    if (!email.get('from_name') || hasIncompleteAddress) {
      return;
    }
    history.push(localUrl('/settings/account/emails'));
  }

  const status = email.get('status') ?? 'Unverified';

  async function handleVerify() {
    if (email.isEphemeral) {
      await EmailVerifications.add(email, { validate: false });
    }

    await email.reload();
    const newStatus = email.get('status') ?? 'Unverified';

    if (newStatus === 'Success') {
      toast(t('This email address has been verified.'), { type: ToastType.SUCCESS });
      return;
    }

    if (newStatus === 'Pending') {
      // Status was updated by getEmailStatus().
      toast(t('Check your email for a verification link.'), { type: ToastType.INFO });
    }
  }

  async function handleResend() {
    const response = await email.resend();
    if (email.isErrored) {
      toast(t('There was a problem trying to resend a verification email.'), {
        type: ToastType.ERROR,
      });
      // eslint-disable-next-line no-console
      console.error(response);
    } else {
      toast(
        t('A verification link has been resent to {{email}}. This link will expire in 24 hours.', {
          email: email.get('email'),
        }),
        { type: ToastType.INFO },
      );
    }
  }

  // Remove email from full validation since it's already validated in ephemeral state
  const validationErrors = email.validate<IErrors>(
    [
      'address.company_name',
      'address.premise1',
      'address.locality',
      'address.administrative_area_name',
      'address.postal_code',
      'address.country_code',
      'from_name',
      // Need to include status to make presenceUnless work.
      'status',
    ],
    false,
    'grouped',
  ).errors;

  const validateEmail: string[] = (function () {
    // We only want to validate all constraints once the email has been verified.
    if (email.isEphemeral) {
      const error: string[] | undefined = validate.single(email.get('email'), {
        presence: { allowEmpty: false, message: 'Email address has to be valid.' },
        email: true,
      });

      return error ?? [];
    }

    return flattenError(validationErrors.email);
  })();

  return (
    <Page loading={email.isPending} title={email.get('email')}>
      <Form
        actions={[
          <SaveButton email={email} key={'save'} onSave={onSave} t={t} />,
          <Button key={'cancel'} onClick={onSave}>
            {t('Cancel')}
          </Button>,
        ]}
        label={t('Edit email')}
      >
        <Fieldset>
          <EmailInput
            attribute={'email'}
            disabled={!email.isEphemeral}
            helpText={t("The address you'd like to send emails from.")}
            isLoading={email.isPending}
            label={t('From email address')}
            model={email}
            onVerify={handleVerify}
            required={true}
            status={
              // Success maps to Verified in this component
              status === 'Success' ? 'Verified' : status
            }
            t={t}
            validationError={validateEmail}
          />
          {status !== 'Success' && (
            <Button onClick={handleResend} type={'link'}>
              {t('Resend verification email')}
            </Button>
          )}
        </Fieldset>
        {status === 'Success' && (
          <Fieldset className={styles.addressGroup}>
            <Input
              attribute={'from_name'}
              label={t('From name')}
              model={email}
              required={true}
              type={'text'}
            />
            <AddressInput<EmailVerification>
              attribute={'address'}
              className={styles.address}
              isLoading={email.isPending}
              model={email}
              required={true}
              t={t}
            />
          </Fieldset>
        )}
      </Form>
    </Page>
  );
}

export default observer(EmailEditPage);
