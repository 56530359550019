import type { JSX } from 'react';
import React from 'react';

import Page from '@feathr/extender/App/Page';

import EventAdd from './EventAdd';

function EventAddPage(): JSX.Element {
  return (
    <Page title={'Add Project'}>
      <EventAdd />
    </Page>
  );
}

export default EventAddPage;
