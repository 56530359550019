import capitalize from 'lodash.capitalize';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Chip, TableColumnHeader } from '@feathr/components';
import { campaignStateColorMap } from '@feathr/extender/styles/campaign';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const StateColumn: IColumn<BaseCampaign> = {
  id: 'state',
  checkboxLabel: 'Status',
  Header: TableColumnHeader({
    sortType: 'alpha',
    title: 'Status',
  }),
  headerClassName: tableStyles.sort,
  width: 120,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const state = original.get('state');
          return (
            <>
              {!!state && <Chip theme={campaignStateColorMap.get(state)}>{capitalize(state)}</Chip>}
            </>
          );
        }}
      </Observer>
    );
  },
};

export default StateColumn;
