import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { IConversationCampaignTheme } from '@feathr/blackbox';
import type { ConversationCampaign } from '@feathr/blackbox';
import { ColorPicker, ImagePicker, Input } from '@feathr/components';

import styles from './ConversationConfig.css';

import defaultLogo from './logo_white.png';

interface IProps {
  conversationCampaign: ConversationCampaign;
}

function ConversationConfig({ conversationCampaign }: IProps): JSX.Element {
  const theme = conversationCampaign.get('theme', observable({} as IConversationCampaignTheme));

  return (
    <div>
      <Input
        attribute={'name'}
        className={styles.row}
        helpText={'Enter an easily identifiable unique name for your campaign.'}
        label={'Campaign Name'}
        model={conversationCampaign}
        placeholder={'Determining Referral Source'}
        type={'text'}
      />
      <Input
        attribute={'url'}
        className={styles.row}
        helpText={
          "Enter text that partially (or entirely) matches the url of the website where you'd like to see this question loaded. Enter something like google.com, feathr.co/my_event, etc"
        }
        label={'Question URL'}
        model={conversationCampaign}
        placeholder={'google.com/pages'}
        type={'text'}
      />
      <div className={styles.colorPickers}>
        <ColorPicker
          attribute={['theme', 'palette', 'primary1Color']}
          label={'Primary Color'}
          model={conversationCampaign}
        />
        <ColorPicker
          attribute={['theme', 'palette', 'accent1Color']}
          label={'Secondary Color'}
          model={conversationCampaign}
        />
      </div>
      <ImagePicker attribute={'logo'} model={conversationCampaign}>
        <div className={styles.imagePickerWrapper}>
          <div
            className={styles.logoWrapper}
            style={{
              backgroundColor: theme.palette.primary1Color,
            }}
          >
            <img
              alt={'feathr logo'}
              className={styles.logo}
              src={conversationCampaign.get('logo') || defaultLogo}
            />
          </div>
        </div>
      </ImagePicker>
    </div>
  );
}

export default observer(ConversationConfig);
