import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { StoresContext } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function FlightLabel({ className, id }: IProps) {
  const { Flights } = React.useContext(StoresContext);

  const flight = Flights.get(id);
  const name = flight.isPending ? 'placeholder' : flight.name;

  return <div className={classNames({ [skeleton]: flight.isPending }, className)}>{name}</div>;
}

export default observer(FlightLabel);
