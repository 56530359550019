import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomField } from '@feathr/blackbox';
import { ButtonValid } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface IProps {
  errors: Record<string, string[]>;
  field: CustomField;
  name?: string;
  onNext: () => void;
}

interface IErrors extends TValidateGrouped {
  collection?: string[];
  data_type?: string[];
  description?: string[];
  u_key?: string[];
}

export function validateStepOne(field: CustomField): IErrors {
  return field.validate<IErrors>(
    ['collection', 'data_type', 'description', 'u_key', 'is_read_only'],
    false,
    'grouped',
  ).errors;
}

function NextStepButton({ errors, field, name, onNext }: IProps): JSX.Element {
  const { t } = useTranslation();

  const buttonErrors = { ...errors, ...validateStepOne(field) };
  return (
    <ButtonValid errors={flattenErrors(buttonErrors)} name={name} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
}

export default observer(NextStepButton);
