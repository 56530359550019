import debounce from 'debounce-promise';
import { isObservableArray, when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionMeta, ValueType } from 'react-select';
import { ToastType } from 'react-toastify';

import type { ISelectOption } from '@feathr/components';
import { AsyncSelect, toast } from '@feathr/components';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';
import type { Collection, DisplayModel, IBaseAttributes, IListParams } from '@feathr/rachis';

type TSortDirection = 'asc' | 'desc';

interface IModelSelectProps<T extends DisplayModel<IBaseAttributes>, S = string | string[]> {
  /** A Rachis Collection loaded via useContext. */
  collection: Collection<T>;
  /** Filters to apply to the collection. */
  filters?: Record<string, string | string[] | boolean>;
  /** If true, the component will allow multi-select. */
  isMulti?: boolean;
  /** Message to display when no options are found. If no text is given a default will be provided. */
  noOptionsMessage?: string;
  /** Callback function to handle the selected value(s). */
  onSelect: (id: S) => void;
  /** Placeholder text for the select component. If no text is given a default will be provided. */
  placeholder?: string;
  /** Sort direction for groups by date created. Sorted descending by default. */
  sortDirection?: TSortDirection;
  /** The selected, or pre-selected value(s). */
  value?: string | string[];
}

function ModelSelect<T extends DisplayModel<IBaseAttributes>, S = string | string[]>({
  collection,
  filters,
  isMulti = false,
  noOptionsMessage,
  onSelect,
  sortDirection = 'desc',
  value,
  ...props
}: Readonly<IModelSelectProps<T, S>>): JSX.Element {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<ISelectOption | ISelectOption[] | null>();

  function onChange(
    newValue: ValueType<ISelectOption | ISelectOption[]>,
    action: ActionMeta<ISelectOption>,
  ): void {
    const onChangeActions = ['select-option', 'remove-value', 'clear'];

    if (onChangeActions.includes(action.action)) {
      if (Array.isArray(newValue)) {
        onSelect(newValue.map(({ id }) => id) as S);
      } else {
        onSelect((newValue as ISelectOption).id as S);
      }
    }
  }

  const loadOptions = debounce(async (newValue: string): Promise<ISelectOption[]> => {
    const ordering = [(sortDirection === 'asc' ? '' : '-') + 'date_created'];
    const realFilters = {
      name__icontains: newValue,
    };

    const params: IListParams<Collection<T>> = {
      filters: {
        ...realFilters,
        ...filters,
      },
      ordering: ordering,
      only: ['id', 'name', 'date_created'],
    };
    const data = collection.list(params);
    await when(() => !data.isPending);
    return data.models.map(({ id, name }) => ({
      id,
      name,
    }));
  }, DEFAULT_DEBOUNCE_WAIT);

  const fetchSelectedValue = useCallback(
    async (value: string | string[]): Promise<ISelectOption | ISelectOption[]> => {
      try {
        const params: IListParams<Collection<T>> = { only: ['id', 'name', 'date_created'] };

        if (isObservableArray(value)) {
          const response = collection.list({ filters: { id__in: value }, ...params });
          await when(() => !response.isPending);
          return response.models.map(({ id, name }) => ({
            id: id,
            name: name,
          }));
        }

        if (typeof value === 'string') {
          const response = collection.list({
            filters: { id: value },
            ...params,
          });
          await when(() => !response.isPending);
          const single = response.models[0];
          if (single) {
            return { id: single.id, name: single.name };
          }
        }

        return [];
      } catch (error) {
        toast(t('Failed to load options: {{- error}}', { error }), { type: ToastType.ERROR });
        return [];
      }
    },
    [collection, t],
  );

  useEffect(() => {
    const fetchOptions: () => Promise<void> = async (): Promise<void> => {
      if (value) {
        setIsLoading(true);
        const fetchedOptions = await fetchSelectedValue(value);
        setSelectedOptions(fetchedOptions);
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, [value, onSelect, fetchSelectedValue]);

  function getOptionLabel({ name }: ISelectOption): string {
    return name || '';
  }

  function getOptionValue({ id }: ISelectOption): string {
    return id || '';
  }

  function getNoOptionsMessage(): string {
    return noOptionsMessage ?? t('No options found');
  }

  return (
    <AsyncSelect
      {...props}
      cacheOptions={true}
      defaultOptions={true}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isLoading={isLoading}
      isMulti={isMulti}
      loadOptions={loadOptions}
      noOptionsMessage={getNoOptionsMessage}
      onChange={onChange}
      value={selectedOptions}
    />
  );
}

export default observer(ModelSelect);
