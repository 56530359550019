import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faHandshake,
  faMousePointer,
  faProjectDiagram,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomField } from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import { FormSummary, FormSummaryItem } from '@feathr/components';
import { fieldDataTypeIcon, fieldDataTypeLabel } from '@feathr/extender/styles/fieldDataType';
import { flattenError } from '@feathr/hooks';

import type { IDataFieldErrors } from '../DataFieldEditPage';

interface IProps {
  field: CustomField;
  validationErrors?: IDataFieldErrors;
}

const collectionIconMap: Record<FieldCollection, IconProp> = {
  [FieldCollection.Breadcrumb]: faMousePointer,
  [FieldCollection.Partner]: faHandshake,
  [FieldCollection.Person]: faUser,
  [FieldCollection.Project]: faProjectDiagram,
};

function DataFieldSummary({ field, validationErrors = {} }: IProps): JSX.Element {
  const { t } = useTranslation();
  const sNone = t('(None)');

  const collectionMap: Record<FieldCollection, string> = {
    [FieldCollection.Breadcrumb]: t('Activity'),
    [FieldCollection.Partner]: t('Partner'),
    [FieldCollection.Person]: t('Person'),
    [FieldCollection.Project]: t('Project'),
  };

  const collection = field.get('collection');
  const dataType = field.get('data_type');

  return (
    <FormSummary>
      <FormSummaryItem
        errors={flattenError(validationErrors.u_key)}
        label={t('Name')}
        value={field.get('u_key') || sNone}
      />
      <FormSummaryItem
        errors={flattenError(validationErrors.u_key)}
        label={t('Data type')}
        value={
          dataType ? (
            <>
              <FontAwesomeIcon icon={fieldDataTypeIcon(dataType)} />{' '}
              {fieldDataTypeLabel(dataType, t)}
            </>
          ) : (
            sNone
          )
        }
      />
      <FormSummaryItem
        errors={flattenError(validationErrors.description)}
        label={t('Description')}
        value={field.get('description') || sNone}
      />
      <FormSummaryItem
        errors={flattenError(validationErrors.collection)}
        label={'Type'}
        value={
          collection ? (
            <>
              <FontAwesomeIcon icon={collectionIconMap[collection]} /> {collectionMap[collection]}
            </>
          ) : (
            sNone
          )
        }
      />
    </FormSummary>
  );
}

export default observer(DataFieldSummary);
