import type { Client, PickerFileMetadata, PickerResponse } from 'filestack-js';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { FacebookCampaign, FacebookDisplayCreative, IFacebook } from '@feathr/blackbox';
import { CreativeClass, FacebookVideoCreative } from '@feathr/blackbox';
import { Button, MenuItem, toast } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './AddCreativeButton.css';

interface IProps {
  creatives: IObservableArray<FacebookDisplayCreative>;
  campaign: FacebookCampaign;
  isMenuItem?: boolean;
}

interface IVideoDimensionDefintion {
  width: number;
  height: number;
  duration: number;
}

function getVideoDimensions(url: string): Promise<IVideoDimensionDefintion> {
  return new Promise<IVideoDimensionDefintion>((resolve) => {
    const video = document.createElement('video');
    video.addEventListener('loadedmetadata', () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
      });
    });
    video.src = url;
  });
}

async function uploadToFacebook(
  creative: FacebookVideoCreative,
  fbAccountId?: string,
  accessToken?: string,
): Promise<void> {
  if (!fbAccountId || !accessToken) {
    toast('Failed to upload video to Meta.', {
      type: ToastType.ERROR,
    });
    return;
  }
  const body = new FormData();
  body.append('access_token', accessToken);
  body.append('file_url', creative.get('url'));
  const response = await fetch(
    `https://graph.facebook.com/${FACEBOOK_API_VERSION}/${fbAccountId}/advideos`,
    {
      body,
      method: 'POST',
    },
  );
  const json = await response.json();
  const videoId = json.id;
  if (videoId) {
    creative.set({ ad_video_id: videoId });
    toast('Video uploaded to Meta', { type: ToastType.SUCCESS });
  }
}

function AddCreativeButton({ creatives, campaign, isMenuItem }: IProps): JSX.Element {
  const { Creatives } = React.useContext(StoresContext);
  const account = useAccount();
  const integration = account.get('facebook', {} as IFacebook);
  const { t } = useTranslation();

  function setCreatives(client: Client) {
    return (files: PickerResponse) => {
      const { filesUploaded } = files;
      filesUploaded.forEach(async (file: PickerFileMetadata): Promise<void> => {
        const { key, container, handle } = file;
        const url = `https://s3.amazonaws.com/${container}/${encodeURIComponent(key!)}`;
        const metadata = await client.metadata(handle, {
          width: true,
          height: true,
          mimetype: true,
          size: true,
          filename: true,
        });
        let cls = CreativeClass.FacebookImage;
        let { width, height } = metadata;
        let duration = 0;
        const { mimetype, filename } = metadata;
        const isVideo = mimetype.includes('video');
        const isGif = mimetype.includes('gif');
        if (isVideo) {
          const videoDimensions = await getVideoDimensions(url);
          width = videoDimensions.width;
          height = videoDimensions.height;
          duration = videoDimensions.duration;
          cls = CreativeClass.FacebookVideo;
        }
        if (isGif) {
          cls = CreativeClass.FacebookVideo;
        }
        const creative = Creatives.create({
          width,
          height,
          duration,
          mimetype,
          url,
          _cls: cls,
          parent: campaign.id,
          is_enabled: true,
          account: account.id,
        }) as FacebookDisplayCreative;
        const dimensionDefinition = creative.getSpec();
        if (dimensionDefinition) {
          creative.set({ spec: dimensionDefinition.spec });
        }
        const validDimensions = creative.getValidDimensions();
        const specs = Object.values(validDimensions).map((v) => v.spec);
        const creativeIsValid = specs.includes(creative.get('spec'));
        if (!creativeIsValid) {
          const closest = creative.closestValidDimension(validDimensions);
          toast(
            `"${filename}" is the wrong size (${width}x${height}). The closest valid size would be ${closest}`,
            { type: 'error' },
          );
        } else {
          if (creative instanceof FacebookVideoCreative) {
            await uploadToFacebook(creative, integration.id, account?.getFacebookAccessToken);
          }
          creatives.push(creative);
        }
      });
    };
  }

  async function handleClick(): Promise<void> {
    const filestackJs = await import(/* webpackChunkName: "filestack-js" */ 'filestack-js');
    const client = filestackJs.init(FILESTACK_API_KEY);

    const handleFileNameChange = async (file: PickerFileMetadata): Promise<PickerFileMetadata> => {
      const sanitizeFilename = (filename: string): string =>
        filename
          // Match any character that is NOT alphanumeric, hyphen, underscore, space, or dot.
          .replaceAll(/[^a-zA-Z0-9_\-\s.]/g, '')
          // Then replace any whitespace.
          .replaceAll(/\s+/g, '_');

      // Uploads from URLs and 3rd-party apps do not contain a filename.
      if (!file.filename && file.originalPath) {
        const filename = file.originalPath.split('/').pop() ?? '';

        return { ...file, filename: sanitizeFilename(filename) };
      }

      return {
        ...file,
        filename: sanitizeFilename(file.filename),
      };
    };
    client
      .picker({
        onUploadDone: setCreatives(client),
        onFileSelected: handleFileNameChange, // Renaming currently only works for local uploads
        cleanupImageExif: true,
        maxFiles: 10,
        maxSize: 200 * 1024 * 1024,
        accept: ['image/jpeg', 'image/png', 'image/gif', 'video/mp4'],
        transformations: {
          crop: false,
          circle: false,
          rotate: false,
        },
        dropPane: {},
      })
      .open();
  }

  return isMenuItem ? (
    <MenuItem id={'addMedia'} onClick={handleClick}>
      {t('Add image/video creative')}
    </MenuItem>
  ) : (
    <Button
      className={styles.root}
      disabled={!campaign.get('facebook_page_id')}
      id={'addMedia'}
      key={'add'}
      name={'add_creative_button'}
      onClick={handleClick}
      type={'primary'}
    >
      {t('Add creative')}
    </Button>
  );
}

export default observer(AddCreativeButton);
