import classNames from 'classnames';
import type { TFunction } from 'i18next';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { IAccountUserRole, UserRole } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import RoleOptions from './RoleOptions';
import RoleTypeChip from './RoleTypeChip';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function countUsersForRole(userRoles: IAccountUserRole[], roleId: string): number {
  return userRoles.filter((userRole): boolean => userRole.role === roleId).length;
}

function rolesColumns(userRoles: IAccountUserRole[], t: TFunction): Array<IColumn<UserRole>> {
  return [
    {
      id: 'name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Role'),
      }),
      sortable: true,
      headerClassName: classNames(tableStyles.cellLeft, tableStyles.sort),
      className: tableStyles.cellLeft,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return <>{original.name}</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'id',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Type'),
      }),
      sortable: true,
      headerClassName: tableStyles.sort,
      className: tableStyles.cellCenter,
      width: 120,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return <RoleTypeChip roleId={original.id} />;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'users-assigned',
      Header: TableColumnHeader({
        title: t('Users Assigned'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      sortable: false,
      width: 152,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              const userCount = countUsersForRole(userRoles, original.id);
              return <>{userCount}</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'options',
      Header: TableColumnHeader({
        title: t('Options'),
      }),
      width: 80,
      sortable: false,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        return <RoleOptions role={original} />;
      },
    },
  ];
}

export default rolesColumns;
