import React from 'react';

import type { Campaign } from '@feathr/blackbox';
import { CampaignLabelMap, CampaignState } from '@feathr/blackbox';
import { Tooltip } from '@feathr/components';

import CampaignIcon from './CampaignIcon';

import styles from './CampaignIcons.css';

interface IProps {
  campaigns: Campaign[];
  legIndex: number;
}

function CampaignIcons({ campaigns, legIndex }: IProps) {
  return (
    <div className={styles.root} data-id={legIndex}>
      {campaigns.map((campaign) => {
        const campaignType = CampaignLabelMap.get(campaign.get('_cls')) || 'Campaign';
        const isDraft = (campaign && campaign.get('state') === CampaignState.Draft) || false;
        const tooltip = isDraft ? `${campaignType} campaign (draft)` : `${campaignType} campaign`;

        return (
          <Tooltip className={styles.icon} key={campaign.get('id')} title={tooltip}>
            <CampaignIcon campaign={campaign} key={campaign.get('id')} size={36} />
          </Tooltip>
        );
      })}
    </div>
  );
}

export default CampaignIcons;
