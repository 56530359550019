import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import { StatBox } from '@feathr/components';

import type { IStatProps } from './Stats';

interface IProps extends IStatProps {
  conversions?: number;
  isMonetization?: boolean;
  targetImpressions?: number;
  users?: number;
}

interface IPropsSmall extends IStatProps {
  conversions?: never;
  isMonetization?: never;
  targetImpressions?: never;
  users?: never;
}

function ViewsStat({
  conversions,
  isMonetization = false,
  label = 'Views',
  targetImpressions,
  tooltip,
  users,
  value,
  size = 'default',
}: IPropsSmall | IProps): JSX.Element {
  const formatted = numeral(value).format('0,0');

  const target = targetImpressions || 0;
  const targetProgress = target > 0 ? value / target : 0;
  const progressPercent = numeral(targetProgress).format('0.00%');

  let impressionsSecondary = '';
  let impressionsPerUserFormatted = '';
  let impressionsPerConvFormatted = '';
  if (isMonetization) {
    impressionsSecondary = `out of ${numeral(target).format('0,0')} (${progressPercent})`;
  } else {
    if (users) {
      impressionsPerUserFormatted = `${numeral(value / users).format('0,0')} views per user `;
    }
    if (conversions) {
      impressionsPerConvFormatted = `${numeral(value / conversions).format(
        '0,0',
      )} views per conversion`;
    }
    impressionsSecondary = `${impressionsPerUserFormatted} ${impressionsPerConvFormatted}`;
  }

  return (
    <StatBox
      label={label}
      primary={formatted}
      secondary={impressionsSecondary}
      size={size}
      tooltip={tooltip}
    />
  );
}

export default observer(ViewsStat);
