import type { WithT } from 'i18next';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { IiMISContactMapping, ImisIntegration } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Label, TableColumnHeader } from '@feathr/components';
import { iMISContactFieldTranslationMap } from '@feathr/extender/styles/integrations';

import ImisLastSyncChip from '../ImisChips/ImisLastSyncChip';
import ImisSyncRuleChip from '../ImisChips/ImisSyncRuleChip';
import ImisDataType from '../ImisDataType';
import ImisMappingOptions from '../ImisMappingOptions';
import ImisSyncContactSelect from './ImisSyncContactSelect';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './ImisSyncContactColumns.css';

interface IProps extends WithT {
  integration: ImisIntegration;
}

function iMISSyncContactColumns({ t, integration }: IProps): Array<IColumn<IiMISContactMapping>> {
  return [
    {
      accessor: 'key',
      id: 'imis_field',
      Header: TableColumnHeader({
        title: t('iMIS field'),
        sortType: 'alpha',
      }),
      sortable: true,
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => (
              <Label className={styles.label}>
                {iMISContactFieldTranslationMap(t, original.key)}
              </Label>
            )}
          </Observer>
        );
      },
    },
    {
      accessor: 'id',
      id: 'feathr_field',
      Header: TableColumnHeader({
        title: t('Feathr field'),
        sortType: 'alpha',
      }),
      minWidth: 200,
      width: 250,
      sortable: true,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return (
                <ImisSyncContactSelect
                  integration={integration}
                  key={original.id}
                  original={original}
                  value={original.key}
                />
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'data_type',
      Header: TableColumnHeader({
        title: t('Data type'),
      }),
      sortable: false,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>{(): JSX.Element => <ImisDataType dataType={original.data_type!} />}</Observer>
        );
      },
    },
    {
      id: 'sync_rule',
      accessor: 'id',
      Header: TableColumnHeader({
        title: t('Sync rule'),
        sortType: 'alpha',
      }),
      width: 220,
      sortable: true,
      headerClassName: tableStyles.sort,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => <ImisSyncRuleChip syncRule={original.sync_rule} />}
          </Observer>
        );
      },
    },
    {
      id: 'date_last_synced',
      Header: TableColumnHeader({
        title: t('Last sync'),
      }),
      sortable: false,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => <ImisLastSyncChip dateLastSynced={original.date_last_synced} />}
          </Observer>
        );
      },
    },
    {
      id: 'options',
      Header: TableColumnHeader({
        title: t('Options'),
      }),
      width: 100,
      sortable: false,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => (
              <ImisMappingOptions integration={integration} original={original} />
            )}
          </Observer>
        );
      },
    },
  ];
}

export default iMISSyncContactColumns;
