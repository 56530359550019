import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CampaignClass } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { CardV2 as Card, Select } from '@feathr/components';
import { enumKeys } from '@feathr/hooks';

import CampaignTypeChip from '../CampaignTypeChip/CampaignTypeChip';

import * as styles from './AttributionWindowsCard.css';

type TDateOption = '30' | '45' | '60' | '75' | '90';

interface IWindowOption extends ISelectOption {
  id: TDateOption;
}

function AttributionWindowsCard(): JSX.Element {
  const { t } = useTranslation();
  const options: IWindowOption[] = [
    { id: '30', name: t('{{num}} days', { num: 30 }) },
    { id: '45', name: t('{{num}} days', { num: 45 }) },
    { id: '60', name: t('{{num}} days', { num: 60 }) },
    { id: '75', name: t('{{num}} days', { num: 75 }) },
    { id: '90', name: t('{{num}} days', { num: 90 }) },
  ];
  // Filter out partner messages.
  const campaignTypes: Array<keyof typeof CampaignClass> = enumKeys(CampaignClass).filter(
    (type) => type !== 'PinpointPartnerMessage',
  );
  const fixtureMap = campaignTypes.reduce((acc, type) => {
    const randomIndex = Math.floor(Math.random() * options.length);
    acc[type] = options[randomIndex].id;
    return acc;
  }, {});
  const [fixture, setFixture] = useState(fixtureMap);
  const [allValue, setAllValue] = useState<IWindowOption | null>();

  function handleChangeAll({ id }: IWindowOption): void {
    const newFixture = campaignTypes.reduce((acc, type) => {
      acc[type] = id;
      return acc;
    }, {});
    setFixture(newFixture);
    setAllValue(options.find((option) => option.id === id));
  }

  return (
    <Card>
      <Card.Header
        description={t(
          'Set the attribution window for each campaign type. An attribution window is the set time period after a campaign interaction during which when a conversion can be linked to that campaign.',
        )}
        title={t('Attribution Windows')}
      />
      <Card.Content
        action={
          <Select
            name={'set-all-select'}
            onSelectSingle={handleChangeAll}
            options={options}
            placeholder={t('Select one')}
            value={allValue}
            wrapperClassName={styles.select}
          />
        }
        key={'set-all'}
        title={t('Set all')}
      />
      {campaignTypes.map((type) => {
        const value = options.find((option) => option.id === fixture[type]);

        function handleChangeSingle({ id }: ISelectOption): void {
          setFixture({ ...fixture, [type]: id });
          setAllValue(null);
        }

        return (
          <Card.Content
            action={
              <Select
                name={`${type}-select`}
                onSelectSingle={handleChangeSingle}
                options={options}
                value={value}
                wrapperClassName={styles.select}
              />
            }
            key={type}
            title={<CampaignTypeChip cls={CampaignClass[type]} />}
          />
        );
      })}
    </Card>
  );
}

export default observer(AttributionWindowsCard);
