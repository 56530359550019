import { useTranslation } from 'react-i18next';

interface IUseCampaignTextReturn {
  disableEmailRepublish: string;
  disableLookalikeClone: string;
  disableStopOrRepublishPastEndDate: string;
  disableSingleSendStopStartEndDate: string;
}

function useCampaignText(): IUseCampaignTextReturn {
  const { t } = useTranslation();

  return {
    disableEmailRepublish: t(
      'Auto Send and Smart campaigns cannot be re-enabled. Please clone this campaign instead.',
    ),
    disableLookalikeClone: t(
      'This lookalike campaign type is outdated and can no longer be cloned.',
    ),
    disableStopOrRepublishPastEndDate: t(
      'This campaign can no longer be published or stopped because it is past the end date.',
    ),
    disableSingleSendStopStartEndDate: t(
      'Single Send campaigns cannot be stopped past their start date.',
    ),
  };
}

export default useCampaignText;
