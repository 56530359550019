import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { Drawer } from '@feathr/components';
import { cssVar } from '@feathr/hooks';

import ActivityFeed from '../DataPage/PersonPage/ActivityFeed';
import PersonHeader from './PersonHeader';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  person: Person;
}

function PersonActivityDrawer({ isOpen, onClose, person }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Drawer
      headerSuffix={<PersonHeader person={person} />}
      isOpen={isOpen}
      name={'person-activity-drawer'}
      onClose={onClose}
      title={t('Activity Feed')}
      width={cssVar('--drawer-width-168')}
    >
      <Drawer.Content>
        <ActivityFeed person={person} />
      </Drawer.Content>
    </Drawer>
  );
}

export default PersonActivityDrawer;
