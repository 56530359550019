import type { WithT } from 'i18next';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Domain } from '@feathr/blackbox';
import {
  Alert,
  AlertType,
  Button,
  ButtonValid,
  Form,
  FormSummary,
  FormSummaryItem,
} from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';
import type { IValidateGrouped } from '@feathr/rachis';

interface IProps {
  domain: Domain;
  handleSave: () => void;
  matchingDomains: IObservableArray<Domain>;
  onPrev: () => void;
}

interface IButtonProps extends WithT {
  domain: Domain;
  handleSave: () => void;
  matchingDomains: IObservableArray<Domain>;
}

export function validateStepFour(domain: Domain): IValidateGrouped {
  return domain.validate(['email_domain', 'content_domain', 'mail_from'], false, 'grouped').errors;
}

const SaveButton = observer(
  ({ domain, handleSave, matchingDomains, t }: Readonly<IButtonProps>) => {
    const validationErrors = validateStepFour(domain);
    const errors = flattenErrors(validationErrors);
    if (matchingDomains.length) {
      /*
       * We use the email_domain to find matching domains. All matching domains should have the same
       * email_domain value, so below we just grab the first element in matchingDomains.
       */
      if (domain.get('email_domain') !== matchingDomains[0].get('email_domain')) {
        errors.push(t('The email domain cannot be modified for this domain.'));
      } else if (
        matchingDomains.find((d) => d.get('content_domain') === domain.get('content_domain'))
      ) {
        errors.push(t('This content domain is already in use for the given root domain.'));
      }
    }
    return (
      <ButtonValid
        errors={domain.isEphemeral ? errors : ['Domains are not editable once saved.']}
        onClick={handleSave}
      >
        {t('Add domain')}
      </ButtonValid>
    );
  },
);

function DomainEditStepFour({
  domain,
  handleSave,
  matchingDomains,
  onPrev,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <SaveButton
          domain={domain}
          handleSave={handleSave}
          key={'save'}
          matchingDomains={matchingDomains}
          t={t}
        />,
      ]}
      description={
        <>
          {t('Review the information for the domain you are adding.')}
          {domain.isV1 && (
            <Alert type={AlertType.warning}>
              {t(
                'Domains created before January 10th 2023 are fully functional but do not support retroactively adding an email domain. Please re-add your domain to set it up for content serving and email sending.',
              )}
            </Alert>
          )}
        </>
      }
      label={t('Edit Domain: Review')}
    >
      <FormSummary>
        <FormSummaryItem label={t('Domain')} value={domain.get('email_domain')} />
        <FormSummaryItem label={t('Content serving domain')} value={domain.get('content_domain')} />
        <FormSummaryItem label={t('Email sending subdomain')} value={domain.get('mail_from')} />
      </FormSummary>
    </Form>
  );
}

export default observer(DomainEditStepFour);
