import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type {
  IRedirectDomain,
  ITrackedLink,
  Redirect,
  RedirectDomain,
  TrackedLinkCampaign,
} from '@feathr/blackbox';
import { Button, ButtonValid, Fieldset, Form, Select } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { flattenErrors, shortCodeToken } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import type { ITrackedLinkErrors } from '../../../CampaignSummary';
import TrackedLinkRedirect, { validateShortCode } from './TrackedLinkRedirect';

interface IProps {
  onNext: () => void;
  onPrev: () => void;
  campaign: TrackedLinkCampaign;
  redirects: IObservableArray<Redirect>;
  domain: RedirectDomain;
}

interface IRedirectsErrors extends TValidateGrouped {
  short_code?: string[];
}

interface IErrors extends TValidateGrouped {
  domain?: string[];
  tracked_links?: ITrackedLinkErrors[];
  redirects?: {
    [key: string]: IRedirectsErrors;
  };
}

export function validateStepTwo(
  campaign: TrackedLinkCampaign,
  redirects: IObservableArray<Redirect>,
  domain: RedirectDomain,
): IErrors {
  const errors = campaign.validate<IErrors>(['tracked_links'], false, 'grouped').errors;

  if (!(domain.get('is_verified') && domain.get('is_secure'))) {
    errors.domain = [
      'Please choose a different domain, or finish setting up the one you have chosen.',
    ];
  }

  errors.redirects = {};
  const trackedLinks = campaign.get('tracked_links');
  redirects.reduce((previous, redirect) => {
    previous![redirect.id] = redirect.validate<IRedirectsErrors>([], false, 'grouped').errors;

    const trackedLink = trackedLinks.find((tl) => tl.redirect_id === redirect.id);
    if (!trackedLink) {
      previous![redirect.id]['short_code'] = ['Tracked link is missing for this redirect.'];
    } else {
      previous![redirect.id]['short_code'] = validateShortCode(
        redirect,
        domain,
        trackedLink.original_url?.trim(),
      );
    }
    return previous;
  }, errors.redirects as IErrors['redirects']);

  return errors;
}

const NextStepButton = observer(
  ({ campaign, domain, onNext, redirects }: Omit<IProps, 'onPrev'>): JSX.Element => {
    // Trigger observer.
    redirects.map((redirect) => redirect.toJS());

    const validationErrors = validateStepTwo(campaign, redirects, domain);
    return (
      <ButtonValid errors={flattenErrors(validationErrors)} onClick={onNext}>
        Next
      </ButtonValid>
    );
  },
);

function TrackedLinkCampaignEditStepTwo({
  campaign,
  redirects,
  domain,
  onNext,
  onPrev,
}: IProps): JSX.Element {
  const { Redirects, RedirectDomains } = React.useContext(StoresContext);
  const domains = RedirectDomains.list();
  const { t } = useTranslation();

  // Extract domain and port form short url
  const defaultDomain = new URL(BLACKBOX_SHORT_URL).host;

  async function remove(redirect: Redirect): Promise<void> {
    await Redirects.delete(redirect.id);
    const links: ITrackedLink[] = campaign.get('tracked_links');
    const filteredLinks = links.filter((link) => link.redirect_id !== redirect.id);
    campaign.set({
      tracked_links: filteredLinks,
    });
    await campaign.patchDirty();
  }

  function handleAdd(): void {
    const newRedirect = Redirects.create({
      short_code: shortCodeToken(12),
      domain: campaign.get('domain_id'),
      url: '',
      proxy: false,
    });
    redirects.push(newRedirect);
    const trackedLinks = campaign.get('tracked_links');
    trackedLinks.push({
      original_url: '',
      redirect_id: newRedirect.id,
      utm_campaign: '',
      utm_content: '',
      utm_medium: '',
      utm_source: '',
      utm_term: '',
    });
    campaign.setAttributeDirty('tracked_links');
  }

  function handleDomainSelect(selectedDomain: IRedirectDomain): void {
    campaign.set({ domain_id: selectedDomain.id });
    redirects.forEach((rdr) => rdr.set({ domain: selectedDomain.id }));
  }

  function getDomainOptionLabel(d: IRedirectDomain): string {
    return d.domain;
  }

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <Button
          id={'addLink'}
          key={'add'}
          onClick={handleAdd}
          type={redirects.length >= 1 ? 'secondary' : 'primary'}
        >
          {t('Add link')}
        </Button>,
        <NextStepButton
          campaign={campaign}
          domain={domain}
          key={'next'}
          onNext={onNext}
          redirects={redirects}
        />,
      ]}
      description={
        <>
          <Trans t={t}>
            <p>
              Add links to your campaign to track clickthroughs and sessions from campaigns outside
              of Feathr. Where you would add a URL for a call-to-action in a marketing email, paid
              search ad, in a social post, or anywhere else, instead add one of the links you create
              here.
            </p>
            <p>
              This campaign will track page views and sessions from people following the links, and
              if you add a Goal in the next step conversions from people who clicked through the
              link will be tracked as well.
            </p>
          </Trans>
        </>
      }
      label={'Edit Campaign: Links'}
    >
      <Fieldset>
        <Select
          getOptionLabel={getDomainOptionLabel}
          helpText={
            <p>
              <Trans t={t}>
                <a
                  href={
                    'https://help.feathr.co/hc/en-us/articles/360040220793-Creating-Custom-Domains-for-Pages'
                  }
                  target={'_blank'}
                >
                  Learn how to create custom domains
                </a>{' '}
                to make your links more recognizable to your audience.
              </Trans>
            </p>
          }
          isLoading={domains.isPending}
          label={'Link Domain'}
          onSelectSingle={handleDomainSelect}
          options={[
            { id: '000000000000000000000000', domain: defaultDomain },
            ...domains.models.map((d) => d.toJS()),
          ]}
          value={domain.toJS()}
        />
      </Fieldset>
      {campaign.get('tracked_links').map((tl: ITrackedLink) => {
        return (
          <TrackedLinkRedirect
            campaign={campaign}
            key={tl.redirect_id}
            link={tl}
            onRemove={remove}
          />
        );
      })}
    </Form>
  );
}

export default observer(TrackedLinkCampaignEditStepTwo);
