import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Nav, NavItem } from '@feathr/components';
import { useGoogleAdsPermissions } from '@feathr/extender/hooks';
import { useAccount, useLocalUrl } from '@feathr/extender/state';

function EventMarketingNav(): JSX.Element {
  const localUrl = useLocalUrl();
  const account = useAccount();
  const { eventId } = useParams<{ eventId: string }>();
  const { t } = useTranslation();
  const showGoogleAds = useGoogleAdsPermissions();

  return (
    <Nav indented={true} label={t('Marketing navigation')} type={'vertical'}>
      <NavItem
        isForwardedFrom={localUrl(`/projects/${eventId}/campaigns`)}
        to={localUrl(`/projects/${eventId}/campaigns/all`)}
      >
        {t('All')}
      </NavItem>
      <NavItem to={localUrl(`/projects/${eventId}/campaigns/ads`)}>{t('Ads')}</NavItem>
      <NavItem to={localUrl(`/projects/${eventId}/campaigns/email`)}>{t('Email')}</NavItem>
      {showGoogleAds && (
        <NavItem to={localUrl(`/projects/${eventId}/campaigns/google-ads`)}>
          {t('Google Ads')}
        </NavItem>
      )}
      {account.hasMonetization && (
        <NavItem to={localUrl(`/projects/${eventId}/campaigns/monetization`)}>
          {t('Monetization')}
        </NavItem>
      )}
      <NavItem to={localUrl(`/projects/${eventId}/campaigns/other`)}>{t('Other')}</NavItem>
    </Nav>
  );
}

export default observer(EventMarketingNav);
