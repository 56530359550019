import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import { StatBox } from '@feathr/components';

import type { IStatProps } from './Stats';

interface IProps extends IStatProps {
  isMonetization?: boolean;
  totalUsers?: number;
}

interface IPropsSmall extends IStatProps {
  isMonetization?: never;
  size: 'mini';
  totalUsers?: never;
}

function ReachStat({
  isMonetization,
  label = 'Reach',
  size = 'default',
  tooltip = 'The total number of unique people who viewed or clicked marketing material.',
  totalUsers,
  value,
}: IPropsSmall | IProps): JSX.Element {
  const formatted = numeral(value).format('0,0');

  const percent =
    !isMonetization && totalUsers
      ? value
        ? numeral(Math.min(value / totalUsers, 1))
        : 0
      : undefined;

  const secondary = percent ? `${percent.format('0.00%')} of Target` : undefined;

  return (
    <StatBox
      label={label}
      primary={formatted}
      secondary={secondary}
      size={size}
      tooltip={tooltip}
    />
  );
}

export default observer(ReachStat);
