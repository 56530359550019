import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { CardV2 as Card, Time } from '@feathr/components';
import {
  browserToLabelMap,
  platformToLabelMap,
  sourceToLabelMap,
} from '@feathr/extender/styles/person';
import { TimeFormat } from '@feathr/hooks';

interface IProps {
  readonly person: Person;
}

function Metadata({ person }: IProps): JSX.Element {
  const { t } = useTranslation();
  const unknown = t('Unknown');

  function formatDate(date?: string): JSX.Element | string {
    if (date) {
      return <Time format={TimeFormat.longDate} timestamp={date} />;
    }
    return unknown;
  }

  return (
    <Card>
      <Card.Header title={t('Metadata')} />
      <Card.Content
        action={sourceToLabelMap(t, person.get('source')) || unknown}
        title={t('Source')}
      />
      <Card.Content action={person.get('placeholder')?.name || unknown} title={t('Placeholder')} />
      <Card.Content action={formatDate(person.get('date_created'))} title={t('First seen')} />
      <Card.Content action={formatDate(person.get('date_last_seen'))} title={t('Last seen')} />
      <Card.Content
        action={formatDate(person.get('date_last_session_start'))}
        title={t('Last session')}
      />
      <Card.Content
        action={formatDate(person.get('date_last_heard_from'))}
        title={t('Last heard from')}
      />
      <Card.Content action={browserToLabelMap(t, person.get('last_brow'))} title={t('Browser')} />
      <Card.Content
        action={platformToLabelMap(t, person.get('last_pform'))}
        title={t('Platform')}
      />
      <Card.Content action={person.get('seen_count', 0)} title={t('Times seen')} />
      <Card.Content action={person.get('session_count', 0)} title={t('Sessions')} />
    </Card>
  );
}

export default observer(Metadata);
