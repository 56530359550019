import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import type { ActionMeta, ValueType } from 'react-select';

import { Select } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './LegSelect.css';

interface ISelectOption {
  id: number;
  name: string;
}

interface IProps {
  className?: string;
  exclude?: number[];
  flightId: string;
  onClear: () => void;
  placeholder?: string;
}

interface IMultiProps extends IProps {
  isMulti: true;
  onChange: (value: number[]) => void;
  value?: number[];
}

interface ISingleProps extends IProps {
  isMulti?: false;
  onChange: (value: number) => void;
  value?: number;
}

function LegSelect({
  className,
  exclude = [],
  flightId,
  isMulti = false,
  onChange,
  onClear,
  placeholder = 'Select a leg...',
  value,
}: IMultiProps | ISingleProps) {
  const { Flights } = useContext(StoresContext);

  const flight = Flights.get(flightId);
  const legs = flight.get('legs');

  function handleChange(newValue: ValueType<ISelectOption>, action: ActionMeta<ISelectOption>) {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (Array.isArray(newValue)) {
        if (newValue.length) {
          (onChange as IMultiProps['onChange'])((newValue as ISelectOption[]).map((v) => v.id!));
        } else {
          onClear();
        }
      } else if (newValue) {
        (onChange as ISingleProps['onChange'])((newValue as ISelectOption).id!);
      } else {
        onClear();
      }
    }
  }

  const options: ISelectOption[] = legs
    .map((leg, i) => ({ id: i, name: leg.name }))
    .filter((option) => !exclude.includes(option.id));

  return (
    <Select<ISelectOption>
      defaultOptions={true}
      isClearable={true}
      isLoading={flight.isPending}
      isMulti={isMulti}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      value={
        Array.isArray(value)
          ? value.map((v) => options.find((option) => option.id === v)!)
          : value !== undefined
          ? options.find((option) => option.id === value)!
          : undefined
      }
      wrapperClassName={classNames(styles.root, className)}
    />
  );
}

export default observer(LegSelect);
