import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { DataRequest } from '@feathr/blackbox';
import { Button, Fieldset, Form, Textarea } from '@feathr/components';
import type { TValidateGrouped } from '@feathr/rachis';

import ButtonNext from './ButtonNext';

interface IProps {
  dataRequest: DataRequest;
  onNext: () => void;
  onPrevious: () => void;
}

export function validateStepFive(dataRequest: DataRequest): TValidateGrouped {
  return dataRequest.validate(['instructions'], false, 'grouped').errors;
}

function DataRequestFormStepFive({
  dataRequest,
  onNext,
  onPrevious,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  return (
    <Form
      actions={[
        <Button key={'previous'} onClick={onPrevious}>
          {t('Previous')}
        </Button>,
        <ButtonNext
          dataRequest={dataRequest}
          key={'next'}
          onNext={onNext}
          validate={validateStepFive}
        />,
      ]}
      description={t(
        "Provide any special instructions. Is there anything else we should know about your form or registration flow that hasn't been captured elsewhere in the request? Too much information is always better than too little!",
      )}
      label={'Edit Message: Name'}
    >
      <Fieldset>
        <Textarea attribute={'instructions'} label={'Special Instructions'} model={dataRequest} />
      </Fieldset>
    </Form>
  );
}

export default observer(DataRequestFormStepFive);
