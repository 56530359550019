import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, IKeywordStats } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { Table, TableStatsCard } from '@feathr/components';

import KeywordStatsColumns from './KeywordStatsColumns';

export interface IKeywordStatsTableProps {
  campaign: Campaign;
  keywords?: IKeywordStats[];
}

function KeywordStatsTableCard({
  campaign,
  keywords,
}: Readonly<IKeywordStatsTableProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <TableStatsCard title={reportModuleLabels.includeKeywordStatsTable}>
      <Table<IKeywordStats>
        columns={KeywordStatsColumns(campaign, t)}
        idKey={'term'}
        isPaginated={false}
        items={keywords ?? []}
        noDataText={t('No data available')}
      />
    </TableStatsCard>
  );
}

export default observer(KeywordStatsTableCard);
