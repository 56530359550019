import isEqual from 'lodash.isequal';
import type { DependencyList, EffectCallback } from 'react';
import { useEffect, useRef } from 'react';

/*
 * To avoid the "maximum depth exceeded" error when adding things to the
 * dependency array when using the action bar, you can use this custom hook to deeply compare the deps.
 * This way, the useEffect hook will only trigger when the actual contents of the array change, rather than on every render.
 */
export function useDeepCompareEffect(callback: EffectCallback, dependencies: DependencyList): void {
  const currentDependenciesRef = useRef<DependencyList>();

  if (!isEqual(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, [currentDependenciesRef.current]);
}
