import { faLockKeyhole, faUnlockKeyhole } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { WithT } from 'i18next';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Domain } from '@feathr/blackbox';
import { Button, ButtonValid, Fieldset, Form, Input, Tooltip } from '@feathr/components';
import { flattenError, flattenErrors, useToggle } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import * as styles from './DomainEditStepTwo.css';

interface IProps {
  disabled?: boolean;
  domain: Domain;
  matchingDomains: IObservableArray<Domain>;
  onNext: () => void;
  onPrev: () => void;
}

interface INextButtonProps extends WithT {
  domain: Domain;
  domains: IObservableArray<Domain>;
  onNext: () => void;
  isEditingContentDomain: boolean;
}

interface IErrors extends TValidateGrouped {
  content_domain?: string[];
}

export function validateStepTwo(domain: Domain): IErrors {
  return domain.validate<IErrors>(['content_domain'], false, 'grouped').errors;
}

const NextStepButton = observer(
  ({ domain, domains, onNext, t, isEditingContentDomain }: INextButtonProps) => {
    const validationErrors = validateStepTwo(domain);
    const errors = flattenErrors(validationErrors);
    if (isEditingContentDomain) {
      errors.push(t('Please lock in your content serving domain by clicking the lock above.'));
    } else if (
      domains.length &&
      domains.find((d) => d.get('content_domain') === domain.get('content_domain'))
    ) {
      errors.push(t('This content domain is already in use for the given root domain.'));
    }
    return (
      <ButtonValid errors={domain.isEphemeral ? errors : []} onClick={onNext}>
        {t('Next')}
      </ButtonValid>
    );
  },
);

function DomainEditStepTwo({
  disabled = false,
  domain,
  matchingDomains,
  onNext,
  onPrev,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const [editContentDomain, toggleEditContentDomain] = useToggle(false);
  const validationErrors = validateStepTwo(domain);

  function handleChangeContentDomain(newValue?: string) {
    const contentSubdomain = newValue ? newValue : '';
    domain.set({ content_domain: `${contentSubdomain}.${domain.get('email_domain')}` });
  }

  const prefix = (
    <Tooltip
      title={
        editContentDomain
          ? t('Click to set your content serving subdomain.')
          : t('Click to edit your content serving subdomain.')
      }
    >
      <Button className={styles.button} disabled={domain.isV1} onClick={toggleEditContentDomain}>
        <FontAwesomeIcon icon={editContentDomain ? faUnlockKeyhole : faLockKeyhole} />
      </Button>
    </Tooltip>
  );

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <NextStepButton
          domain={domain}
          domains={matchingDomains}
          isEditingContentDomain={editContentDomain}
          key={'next'}
          onNext={onNext}
          t={t}
        />,
      ]}
      description={
        <Trans t={t}>
          <p>
            Set up your domain for serving content you create in Feathr, like landing pages, invite
            pages, partner dashboards, and more.{' '}
            <strong>
              We have automatically determined content serving settings for your domain. Only make
              changes if you are sure they are needed.
            </strong>
          </p>
        </Trans>
      }
      label={t('Edit Domain: Info')}
    >
      <Fieldset>
        <Input
          disabled={disabled || !editContentDomain}
          helpText={
            <Trans t={t}>
              <p>
                The domain you would like your content, like landing pages and invites collateral,
                served from. Use a subdomain, like <strong>fthr-content</strong>.domain.com that is
                only used for serving Feathr content.
              </p>
            </Trans>
          }
          label={t('Content serving domain address')}
          onChange={handleChangeContentDomain}
          prefix={prefix}
          prefixClassName={styles.prefix}
          required={true}
          suffix={domain.isV1 ? '' : `.${domain.get('email_domain')}`}
          type={'text'}
          validationError={flattenError(validationErrors.content_domain)}
          value={domain.get('content_domain').replace(`.${domain.get('email_domain')}`, '')}
        />
      </Fieldset>
    </Form>
  );
}

export default observer(DomainEditStepTwo);
