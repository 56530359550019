import type { IObservableArray } from 'mobx';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { IReportAttributes, Redirect, ReportModel } from '@feathr/blackbox';

import RedirectCard from './RedirectCard';

interface IRedirectCardsProps<IAttributes extends IReportAttributes> {
  model: ReportModel<IAttributes>;
  redirects: IObservableArray<Redirect>;
  remove: (redirect: Redirect) => Promise<void>;
}

function RedirectCards<IAttributes extends IReportAttributes>({
  redirects,
  remove,
  model,
}: IRedirectCardsProps<IAttributes>): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        return (
          <div>
            {redirects.map((redirect) => (
              <RedirectCard key={redirect.id} model={model} onRemove={remove} redirect={redirect} />
            ))}
          </div>
        );
      }}
    </Observer>
  );
}

export default RedirectCards;
