import { faArrowDownToLine } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  Domain,
  ERegistrars,
  ICNAMERecord,
  IMXRecord,
  IPinpointRecord,
} from '@feathr/blackbox';
import { Button, Card, Collapse, Fieldset, Table, toast, Toolbar } from '@feathr/components';

import domainRecordsColumns from './domainRecordsColumns';

import * as styles from './DomainRecordsTable.css';

interface IProps {
  domain: Domain;
  format: ERegistrars;
}

function DomainRecordsTable({ domain, format }: IProps): JSX.Element {
  const { t } = useTranslation();

  const state = useLocalObservable<{
    items: IPinpointRecord[];
    setItems: (items: IPinpointRecord[]) => void;
  }>(() => ({
    items: [],
    setItems: (items) => (state.items = items),
  }));

  useEffect(() => {
    async function getItems(): Promise<void> {
      try {
        const response = await domain.formatRecords({ format });
        // Flatten all records into one array.
        state.setItems(Object.values(response).flat());
      } catch (e) {
        const message = e instanceof Error ? e.message : String(e);
        toast(t(`There was an error formatting your records: {{- message}}`, { message }), {
          type: ToastType.ERROR,
        });
      }
    }

    getItems();
  }, [domain, format, state, t]);

  return (
    <Card className={styles.card}>
      <Collapse title={t('View and download your domain records')}>
        <Fieldset
          className={styles.fieldset}
          helpText={t(
            'Use this tool to view your domain records. If you selected a DNS host above, these records will be formatted to seamlessly work with them.',
          )}
        >
          <Toolbar>
            <Button
              className={styles.downloadButton}
              href={domain.getDownloadUrl(format)}
              suffix={<FontAwesomeIcon icon={faArrowDownToLine} />}
              type={'primary'}
            >
              {t('Download records')}
            </Button>
          </Toolbar>
          <Table<ICNAMERecord | IMXRecord>
            columns={domainRecordsColumns(t)}
            idKey={'value'}
            isLoading={!state.items.length}
            items={state.items}
          />
        </Fieldset>
      </Collapse>
    </Card>
  );
}

export default observer(DomainRecordsTable);
