import type { TFunction } from 'i18next';
import capitalize from 'lodash.capitalize';
import type { IObservableArray } from 'mobx';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { Campaign, Creative, DailyStat, DailyStats, IFlavors } from '@feathr/blackbox';
import { CreativeClass } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Chip, TableColumnHeader, Tooltip } from '@feathr/components';

import CreativeStat from './CreativeStat';
import CreativeThumbnail from './CreativeThumbnail';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function CreativeTableColumns(
  campaign: Campaign,
  start: string,
  end: string,
  Stats: DailyStats,
  t: TFunction,
): Array<IColumn<Creative>> {
  return [
    {
      id: 'thumbnail',
      Header: TableColumnHeader({
        title: t('Thumbnail'),
      }),
      sortable: false,
      width: 210,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        return <CreativeThumbnail creative={original} />;
      },
    },
    {
      id: 'name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Name'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return <>{original.name}</>;
      },
    },
    {
      id: 'spec',
      accessor: (c: Creative): string => {
        if (c.get('spec')) {
          return c.get('spec').split('_').map(capitalize).join(' ');
        }
        switch (c.get('_cls')) {
          case CreativeClass.ReferralPage:
            return 'Page';

          case CreativeClass.ReferralBanner:
            return 'Banner';

          case CreativeClass.ReferralEmail:
            return 'Email';

          default:
            return '-';
        }
      },
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Spec'),
      }),
      width: 150,
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
    },
    {
      id: 'dimensions',
      Header: TableColumnHeader({
        title: t('Dimensions'),
      }),
      sortable: false,
      width: 110,
      className: tableStyles.cellRight,
      Cell({ original }): JSX.Element {
        if (original.get('width') && original.get('height')) {
          return <>{`${original.get('width')}px x ${original.get('height')}px`}</>;
        }
        return <>-</>;
      },
    },
    {
      id: 'views',
      Header: TableColumnHeader({
        title: t('Views'),
      }),
      width: 110,
      sortable: false,
      className: tableStyles.cellRight,
      Cell({ original }): JSX.Element {
        let flavor: keyof IFlavors = 'ad_view';
        if (original.get('_cls') === CreativeClass.ReferralEmail) {
          flavor = 'email_view';
        } else if (original.get('_cls') === CreativeClass.ReferralPage) {
          flavor = 'page_view';
        }

        function handleCalculate(dailyStats: IObservableArray<DailyStat>): string {
          const views = dailyStats.reduce(
            (acc, stat) => acc + (stat.get('flavors', {})[flavor] || 0),
            0,
          );
          return numeral(views).format('0,0');
        }

        return (
          <CreativeStat
            calculate={handleCalculate}
            creative={original}
            end={end}
            start={start}
            Stats={Stats}
          />
        );
      },
    },
    {
      id: 'clicks',
      Header: TableColumnHeader({
        title: t('Clicks'),
      }),
      width: 110,
      sortable: false,
      className: tableStyles.cellRight,
      Cell({ original }): JSX.Element {
        let flavor: keyof IFlavors = 'ad_click';
        if (original.get('_cls') === CreativeClass.ReferralEmail) {
          flavor = 'email_link_click';
        } else if (original.get('_cls') === CreativeClass.ReferralPage) {
          flavor = 'page_link_click';
        }

        function handleCalculate(dailyStats: IObservableArray<DailyStat>): string {
          const clicks = dailyStats.reduce(
            (acc, stat) => acc + (stat.get('flavors', {})[flavor] || 0),
            0,
          );
          return numeral(clicks).format('0,0');
        }

        return (
          <CreativeStat
            calculate={handleCalculate}
            creative={original}
            end={end}
            start={start}
            Stats={Stats}
          />
        );
      },
    },
    {
      id: 'ctr',
      Header: TableColumnHeader({
        title: t('CTR'),
      }),
      width: 110,
      sortable: false,
      className: tableStyles.cellRight,
      Cell({ original }): JSX.Element {
        let viewFlavor: keyof IFlavors = 'ad_view';
        let clickFlavor: keyof IFlavors = 'ad_click';
        if (original.get('_cls') === CreativeClass.ReferralEmail) {
          viewFlavor = 'email_view';
          clickFlavor = 'email_link_click';
        } else if (original.get('_cls') === CreativeClass.ReferralPage) {
          viewFlavor = 'page_view';
          clickFlavor = 'page_link_click';
        }

        function handleCalculate(dailyStats: IObservableArray<DailyStat>): string {
          const views = dailyStats.reduce(
            (acc, stat) => acc + (stat.get('flavors', {})[viewFlavor] || 0),
            0,
          );
          const clicks = dailyStats.reduce(
            (acc, stat) => acc + (stat.get('flavors', {})[clickFlavor] || 0),
            0,
          );
          return numeral(clicks / views).format('0.00%');
        }

        return (
          <CreativeStat
            calculate={handleCalculate}
            creative={original}
            end={end}
            start={start}
            Stats={Stats}
          />
        );
      },
    },
    {
      id: 'is_enabled',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Status'),
      }),
      width: 75,
      headerClassName: tableStyles.sort,
      Cell({ original }): JSX.Element {
        const enabled = original.get('is_enabled');
        return (
          <Chip theme={enabled ? 'green' : 'default'}>
            {enabled ? t('Enabled') : t('Disabled')}
          </Chip>
        );
      },
    },
    {
      id: 'destination_url',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Destination URL'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        let destinationURL = campaign.get('destination_url');
        if (original.get('destination_url')) {
          destinationURL = original.get('destination_url')!;
        }
        return (
          <Tooltip title={destinationURL}>
            <a href={destinationURL}>{destinationURL}</a>
          </Tooltip>
        );
      },
    },
  ];
}

export default CreativeTableColumns;
