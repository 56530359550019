import type { WithT } from 'i18next';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { APIKey } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import APIKeyOptions from './APIKeyOptions';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function apiKeyColumns({ t }: WithT): Array<IColumn<APIKey>> {
  return [
    {
      id: 'service_name',
      Header: TableColumnHeader({
        title: t('Service'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      maxWidth: 150,
      Cell({ original }): JSX.Element {
        return <Observer>{(): JSX.Element => <>{original.name}</>}</Observer>;
      },
    },
    {
      id: 'key',
      Header: TableColumnHeader({
        title: t('Key'),
      }),
      sortable: false,
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return <Observer>{(): JSX.Element => <>{original.get('key')}</>}</Observer>;
      },
    },
    {
      id: 'options',
      Header: TableColumnHeader({
        title: t('Options'),
      }),
      width: 80,
      sortable: false,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        return <APIKeyOptions apiKey={original} />;
      },
    },
  ];
}

export default apiKeyColumns;
