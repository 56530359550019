import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import type { RowRenderProps } from 'react-table';

import type {
  Campaign,
  PinpointEmailBaseCampaign,
  PinpointPartnerMessage,
  ReferralCampaign,
  Template,
} from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import type { IColumn, ISorted } from '@feathr/components';
import { Table } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

export interface IData {
  id: string;
  campaign?: Campaign;
  message?: PinpointPartnerMessage;
  template: Template;
}

export interface ITemplateRow extends RowRenderProps {
  original: IData;
}

interface IProps {
  model: ReferralCampaign | PinpointEmailBaseCampaign;
  columns: Array<IColumn<IData>>;
  isPaginated?: boolean;
  noDataText?: string;
}

function TemplatesTable({
  model,
  columns,
  isPaginated = true,
  noDataText = 'No items found for the given filters.',
}: IProps): JSX.Element {
  const [sort, setSort] = useState<ISorted[]>([{ id: 'name' }]);

  const { Templates } = React.useContext(StoresContext);

  let templateIds: string[];
  if (model.isPinpointCampaign()) {
    templateIds = model.get('template_id') ? [model.get('template_id')] : [];
  } else {
    templateIds = [
      ...(model.get('banner_templates') || []),
      ...(model.get('email_templates') || []),
      ...(model.get('page_templates') || []),
    ];
  }
  const templates = templateIds.length
    ? Templates.list({
        filters: {
          id__in: templateIds,
        },
        ordering: sort.map((s) => `${s.desc ? '-' : ''}${s.id}`),
      })
    : Templates.newListResponse();

  const data: IData[] = !templates.isPending
    ? templates.models.map((template) => ({
        [model.isPinpointCampaign() && model.get('_cls') === CampaignClass.PinpointPartnerMessage
          ? 'message'
          : 'campaign']: model,
        id: template.id,
        template,
      }))
    : [];

  return (
    <Table<IData>
      columns={columns}
      idKey={'id'}
      initialPagesize={isPaginated ? 100 : undefined}
      isLoading={model.isPending || templates.isPending}
      isPaginated={isPaginated}
      items={data}
      noDataText={noDataText}
      onSortChange={setSort}
      sort={sort}
    />
  );
}

export default observer(TemplatesTable);
