import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { GoogleAdsSmartCampaign } from '@feathr/blackbox';
import { AlertV2 as Alert, CardV2 as Card, EAlertV2Type, Form } from '@feathr/components';
import type { TValidateGrouped } from '@feathr/rachis';

import * as styles from './GoalStep.css';

export function validateGoalStep(campaign: GoogleAdsSmartCampaign): TValidateGrouped {
  return campaign.validate([''], false, 'grouped').errors;
}

function GoalStep(): JSX.Element {
  const { t } = useTranslation();

  // TODO: Eventually going to re-implement changes from https://github.com/Feathr/shrike/commit/aebcfd42c3ae82eddccf020203ee30230517a7fb in (#2588)
  return (
    <div className={styles.root}>
      <Form label={t('Goals')}>
        <Card>
          <Card.Header title={t('Campaign Goal')} />
          <Card.Content>
            <Alert
              className={styles.alert}
              description={t(
                'Conversions for this campaign will automatically be tracked for every goal configured in your Google Ad Grant account.',
              )}
              title={t('Goal tracking for Google Ad Grant campaigns')}
              type={EAlertV2Type.info}
            >
              <a href={'https://ads.google.com/aw/conversions'} target={'_blank'}>
                {t('Manage goals')}
              </a>
            </Alert>
          </Card.Content>
        </Card>
      </Form>
    </div>
  );
}

export default observer(GoalStep);
