import type { JSX } from 'react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useGoogleAdsPermissions } from '@feathr/extender/hooks';

import CampaignsPage from '../EventsPage/CampaignsPage';
import RedirectTo404 from './RedirectTo404';

function MarketingRoutes(): JSX.Element {
  const showGoogleAds = useGoogleAdsPermissions();

  return (
    <Switch>
      <Redirect exact={true} from={'/:accountId/marketing'} to={'/:accountId/marketing/all'} />
      <Route path={'/:accountId/marketing/all'}>
        <CampaignsPage campaignGroup={'all'} />
      </Route>
      <Route path={'/:accountId/marketing/ads'}>
        <CampaignsPage campaignGroup={'ads'} />
      </Route>
      <Route path={'/:accountId/marketing/email'}>
        <CampaignsPage campaignGroup={'email'} />
      </Route>
      {showGoogleAds && (
        <Route path={'/:accountId/marketing/google-ads'}>
          <CampaignsPage campaignGroup={'google-ads'} />
        </Route>
      )}
      <Route path={'/:accountId/marketing/monetization'}>
        <CampaignsPage campaignGroup={'monetization'} />
      </Route>
      <Route path={'/:accountId/marketing/other'}>
        <CampaignsPage campaignGroup={'other'} />
      </Route>
      <RedirectTo404 />
    </Switch>
  );
}

export default MarketingRoutes;
