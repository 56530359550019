import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { FacebookCampaign } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { Alert, AlertType, Button } from '@feathr/components';

import { StoresContext } from '../../state';

interface IProps {
  campaign: FacebookCampaign;
  localUrl: (url: string) => string;
}

function FacebookCampaignAlerts({ campaign, localUrl }: IProps) {
  const { Creatives } = useContext(StoresContext);
  const { t } = useTranslation();
  const hasUpsyncErrors = campaign.get('state') === CampaignState.Erroring;
  const allTargetSegmentsErroring = campaign.get('all_target_segments_erroring');
  // Check if any creatives are in a state that requires attention
  const rejectedCreatives = Creatives.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
      ad_status__in: ['WITH_ISSUES', 'DISAPPROVED'],
    },
  });

  return (
    <>
      {hasUpsyncErrors && !allTargetSegmentsErroring && (
        <Alert type={AlertType.danger}>
          <Trans t={t}>
            There was a problem syncing your campaign with Meta. Please{' '}
            <Button
              href={localUrl(`${campaign.getItemUrl()}/edit`)}
              target={'_blank'}
              type={'link'}
            >
              make the recommended edits to your campaign
            </Button>{' '}
            and publish it again.
          </Trans>
        </Alert>
      )}
      {!rejectedCreatives.isPending && !!rejectedCreatives.models.length && (
        <Alert type={AlertType.danger}>
          {t('One or more ads in this campaign have been rejected. Please ')}
          <Link to={localUrl(`${campaign.getItemUrl()}/edit#step3`)}>
            {t("review this campaign's ads")}
          </Link>
          {t(' to resolve the issue.')}
        </Alert>
      )}
      {allTargetSegmentsErroring && (
        <Alert type={AlertType.danger}>
          {t('All target audiences in this campaign are too small to target. ')}
          <Link to={localUrl(`${campaign.getItemUrl()}/edit#step2`)}>
            {t('View and manage your campaign targets.')}
          </Link>
        </Alert>
      )}
    </>
  );
}

export default observer(FacebookCampaignAlerts);
