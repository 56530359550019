import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Well } from '@feathr/components';
import { browserIconMap, EBrowsers } from '@feathr/extender/styles/browser';
import { getDeviceLabelAndIcon } from '@feathr/extender/styles/device';
import { EPlatforms, platformIconMap } from '@feathr/extender/styles/platform';
import { cssVar } from '@feathr/hooks';

import * as styles from './ContextWell.css';

interface IProps {
  readonly browser?: string;
  readonly name?: string;
  readonly platform?: string;
  readonly screenWidth?: number;
}

function ContextWell({ browser, name, platform, screenWidth }: IProps): JSX.Element | null {
  const { t } = useTranslation();
  const { icon: deviceIcon, label: deviceLabel } = getDeviceLabelAndIcon({
    screenWidth,
    platform,
    t,
  });
  const platformIcon = platform ? platformIconMap[platform] : null;
  const platformLabel = platform ? EPlatforms[platform] : t('Other');
  const browserIcon = browser ? browserIconMap[browser] : null;
  const browserLabel = browser ? EBrowsers[browser] : t('Other');

  return (
    <Well className={styles.root} name={name}>
      <>
        {!!screenWidth && platform && (
          <div className={styles.item}>
            <FontAwesomeIcon
              aria-label={deviceLabel}
              color={cssVar('--color-slate-400')}
              icon={deviceIcon}
            />
            <span className={styles.label}>{t('Device')}</span>
          </div>
        )}
        {platformIcon && (
          <div className={styles.item}>
            <FontAwesomeIcon
              aria-label={platformLabel}
              color={cssVar('--color-slate-400')}
              icon={platformIcon}
            />
            <span className={styles.label}>{t('Platform')}</span>
          </div>
        )}
        {browserIcon && (
          <div className={styles.item}>
            <FontAwesomeIcon
              aria-label={browserLabel}
              color={cssVar('--color-slate-400')}
              icon={browserIcon}
            />
            <span className={styles.label}>{t('Browser')}</span>
          </div>
        )}
      </>
    </Well>
  );
}

export default ContextWell;
