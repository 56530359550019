import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  type AutoPinpointEmailCampaign,
  CampaignClass,
  type IDripCampaignStepSpec,
} from '@feathr/blackbox';
import { Button, CardV2 as Card, Label } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import AutomationSchedule from '../../../AutomationSchedule/AutomationSchedule';
import MergeDataInputs from '../../../EmailDetails/MergeDataInputs';
import PinpointTriggersConfig from '../../../PinpointActions/PinpointTriggersConfig';
import TriggerTypeRadios from '../../../TriggerTypeRadios/TriggerTypeRadios';
import useTriggerTypeRadios from '../../../TriggerTypeRadios/useTriggerTypeRadios';
import EmailInputs from '../EmailInputs';

import * as styles from './EditInitialEnrollment.css';

interface IEditInitialEnrollmentProps {
  step: IDripCampaignStepSpec;
  onApply: (updatedStep: IDripCampaignStepSpec) => void;
}

function EditInitialEnrollment({
  step,
  onApply,
}: Readonly<IEditInitialEnrollmentProps>): JSX.Element {
  const { Campaigns } = useContext(StoresContext);
  const { t } = useTranslation();

  const { step: stepAttributes = step } = step;
  const campaign = useMemo(() => {
    const autoCampaign = {
      ...stepAttributes,
      _cls: CampaignClass.AutoPinpointEmail,
    };
    return Campaigns.create(autoCampaign) as AutoPinpointEmailCampaign;
  }, [Campaigns, stepAttributes]);
  const { handleSubtypeChange, repeat, setRepeat, subtype, triggerTypeOptions } =
    useTriggerTypeRadios(campaign);

  function handleApply(): void {
    const updatedStep: IDripCampaignStepSpec = {
      ...campaign.attributes,
    };
    onApply(updatedStep);
  }

  // TODO: Validate all the things in #3705
  const validationErrors: any = [];

  return (
    <Card theme={'disabled'} width={'full'}>
      <Card.Content addVerticalGap={true}>
        <div className={styles.overrideGap}>
          <Label margin={'condensed'} weight={'semibold'}>
            {t('Initial enrollment')}
          </Label>
          <p>{t('Select the type of trigger you want to use for this drip campaign.')}</p>
        </div>
        <TriggerTypeRadios
          context={'drip'}
          description={t('Select the type of trigger you want to use for this drip campaign.')}
          disabled={false}
          handleSubtypeChange={handleSubtypeChange}
          subtype={subtype}
          title={t('Trigger type')}
          triggerTypeOptions={triggerTypeOptions}
        />
        <PinpointTriggersConfig campaign={campaign} context={'drip'} disabled={false} />
        <AutomationSchedule
          campaign={campaign}
          context={'drip'}
          disabled={false}
          repeat={repeat}
          setRepeat={setRepeat}
          validationErrors={validationErrors}
        />
      </Card.Content>
      <Card.Content>
        <Label weight={'semibold'}>{t('Email')}</Label>
        <EmailInputs campaign={campaign} />
      </Card.Content>
      <Card.Content>
        <Label margin={'condensed'} weight={'semibold'}>
          {t('Default Merge Data')}
        </Label>
        <p>
          {t(
            'These values will be used to replace merge tags when the recipient does not have data for the corresponding field.',
          )}
        </p>
        {/* TODO: integrate campaign and template here in #3704 */}
        <MergeDataInputs disabled={false} />
      </Card.Content>
      <Card.Actions>
        {/* TODO: convert to ButtonValid in #3705 */}
        <Button onClick={handleApply} type={'primary'}>
          {t('Apply')}
        </Button>
      </Card.Actions>
    </Card>
  );
}

export default observer(EditInitialEnrollment);
