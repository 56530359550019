import { faSave, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer } from 'mobx-react-lite';
import type { JSX, KeyboardEvent } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import type {
  IConfig,
  IReportAttributes,
  Redirect,
  ReportModel,
  TAttributionModel,
} from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import {
  Button,
  Card,
  Collapse,
  CopyToClipboardButton,
  Fieldset,
  Input,
  Spinner,
  toast,
} from '@feathr/components';

import { StoresContext } from '../../state';
import { ConfigForm } from '../ConfigModal';
import ReportDateRange from '../ReportDateRange';

import * as styles from './ShareableLinksModal.css';

interface IRedirectCardProps<IAttributes extends IReportAttributes> {
  redirect: Redirect;
  onRemove: (redirect: Redirect) => void | Promise<void>;
  model: ReportModel<IAttributes>;
}

function RedirectCard<IAttributes extends IReportAttributes>({
  redirect,
  onRemove,
  model,
}: IRedirectCardProps<IAttributes>): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { Campaigns, Goals, Flights } = useContext(StoresContext);
        const { campaignId, eventId } = useParams<{ eventId: string; campaignId?: string }>();
        const [responseError, setResponseError] = useState<string>('');
        const { t } = useTranslation();

        let goals = Goals.newListResponse();

        if (campaignId) {
          goals = Goals.list({
            filters: {
              _parent: campaignId,
              is_archived__ne: true,
            },
          });
        } else if (eventId) {
          const campaigns = Campaigns.list({
            filters: {
              _parent: eventId,
              is_archived__ne: true,
              state__ne: CampaignState.Draft,
            },
          });
          const flights = Flights.list({
            filters: {
              event_id: eventId,
              is_archived__ne: true,
            },
          });
          const flightlessCampaigns = campaigns.models.filter((cpn) => !cpn.get('flight'));
          goals = Goals.list({
            filters: {
              _parent__in: flights.models
                .map((f) => f.id)
                .concat(flightlessCampaigns.map((cpn) => cpn.id)),
              is_archived__ne: true,
            },
          });
        }
        if (goals.isPending) {
          return <Spinner />;
        }

        async function saveRedirect(): Promise<void> {
          try {
            await redirect.patchDirty();
            if (redirect.isErrored) {
              throw redirect.error;
            }
            setResponseError('');
          } catch (error) {
            const message = error instanceof Error ? error.message : String(error);
            setResponseError(message);
          }
        }

        function onBlur(): void {
          if (redirect.isValid(['short_code'])) {
            saveRedirect();
          }
        }

        function onKeyPress(event: KeyboardEvent<HTMLInputElement>): void {
          if (event.key === 'Enter' && redirect.isValid(['short_code'])) {
            saveRedirect();
          }
        }

        async function save(): Promise<void> {
          if (redirect.isValid(['short_code', 'url'])) {
            try {
              await redirect.save();
              toast(t('Short link saved.'), { type: ToastType.SUCCESS });
            } catch (error) {
              const message = error instanceof Error ? error.message : String(error);
              setResponseError(message);
            }
          }
        }

        function remove(): void {
          onRemove(redirect);
        }

        function setReportStart(value: string | undefined): void {
          if (value) {
            redirect.reportStart = value;
          }
        }

        function setReportEnd(value: string | undefined): void {
          if (value) {
            redirect.reportEnd = value;
          }
        }

        function setReportMode(modeId: 'live' | 'dateWindow'): void {
          redirect.reportMode = modeId;
        }

        function handleConfigFormChange(
          key: keyof IConfig,
          value: TAttributionModel | boolean | undefined,
        ): void {
          redirect.config = { ...config, [key]: value };
        }

        const config = redirect.config;
        const reportStart = redirect.reportStart;
        const reportEnd = redirect.reportEnd;
        const mode = redirect.reportMode;
        const hasGoals = goals.models.length > 0;
        const redirectDomain = new URL(BLACKBOX_SHORT_URL).host;
        return (
          <Card
            actions={[
              <Button key={'save'} onClick={save} tooltip={t('Save changes')} type={'naked'}>
                <FontAwesomeIcon icon={faSave} />
              </Button>,
              <CopyToClipboardButton
                key={'copy'}
                t={t}
                text={`https://${redirectDomain}/${redirect.get('short_code')}`}
                tooltip={t('Copy to clipboard')}
                type={'naked'}
              />,
              <Button key={'remove'} onClick={remove} tooltip={t('Delete link')} type={'naked'}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>,
            ]}
            className={styles.cardWrapper}
          >
            <Fieldset>
              <Input
                attribute={'short_code'}
                className={styles.input}
                model={redirect}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
                placeholder={'short-link'}
                prefix={`https://${redirectDomain}/`}
                type={'text'}
                validationError={
                  responseError ? responseError : redirect.validate(['short_code']).errors
                }
              />
            </Fieldset>
            <Collapse title={t('Show report configuration')}>
              <ReportDateRange
                mode={mode}
                model={model}
                reportEnd={reportEnd}
                reportStart={reportStart}
                setMode={setReportMode}
                setReportEnd={setReportEnd}
                setReportStart={setReportStart}
              />
              <ConfigForm config={config} hasGoals={hasGoals} onChange={handleConfigFormChange} />
            </Collapse>
          </Card>
        );
      }}
    </Observer>
  );
}

export default RedirectCard;
