import { createContext } from 'react';

import type { Targeting } from '@feathr/blackbox';
import type { ListResponse } from '@feathr/rachis';

interface ICampaignStore {
  targetings?: ListResponse<Targeting>;
}

export class CampaignStore {
  public targetings: ListResponse<Targeting> | undefined = undefined;

  public constructor(properties: ICampaignStore) {
    for (const key in properties) {
      this[key] = properties[key];
    }
  }
}

export function createCampaignStore(init: ICampaignStore = {}): CampaignStore {
  return new CampaignStore(init);
}

export const CampaignContext = createContext(createCampaignStore());

export default createCampaignStore;
