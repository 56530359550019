import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Template } from '@feathr/blackbox';
import { RecipientType } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { Button, Card, PageHeaderV1 } from '@feathr/components';

import TemplateSendTestForm from '../TemplateSendTestForm';

import * as styles from './TemplateEditor.css';

export const RecipientTypeOptions: ISelectOption[] = [
  { id: RecipientType.Person, name: 'Person' },
  { id: RecipientType.Partner, name: 'Partner' },
];

interface IProps {
  template: Template;
  onClose: () => void;
  recipientType?: RecipientType;
}

function SendPreview({ template, onClose, recipientType }: Readonly<IProps>): JSX.Element {
  let sendPreviewEmail: (() => Promise<boolean>) | undefined = undefined;

  async function handleSend(): Promise<void> {
    if (sendPreviewEmail) {
      const success = await sendPreviewEmail();
      if (success) {
        onClose();
      }
    }
  }

  return (
    <Card className={styles.dialogForm}>
      <PageHeaderV1 className={styles.dialogHeader}>
        <h3>Send Preview</h3>
      </PageHeaderV1>
      <TemplateSendTestForm
        actions={[
          <Button key={'cancel'} onClick={onClose}>
            Cancel
          </Button>,
          <Button key={'confirm'} onClick={handleSend} type={'primary'}>
            Send
          </Button>,
        ]}
        recipientType={recipientType}
        template={template!}
      >
        {(form, _, send): JSX.Element => {
          sendPreviewEmail = send;
          return form;
        }}
      </TemplateSendTestForm>
    </Card>
  );
}

export default observer(SendPreview);
