import { action, computed, makeObservable, runInAction } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel, isWretchError, wretch } from '@feathr/rachis';

import type { CustomField, CustomFields, FieldDataType } from './custom_fields';

export type TRequestFieldType = 'attribute' | 'activity';

export interface IRequestField {
  id: string;
  field_name?: string;
  request_type: TRequestFieldType;
  feathr_attr: string;
  attr_type?: FieldDataType;
  static_value?: string;
  filestack_url?: string;
  url?: string;
}

export type TDataRequestState =
  | 'draft'
  | 'submitted'
  | 'in_progress'
  | 'needs_feedback'
  | 'completed'
  | 'canceled'
  | 'archived';

export interface IDataRequest extends IBaseAttributes {
  context: string;
  conversion_category?: string;
  conversion_pixel?: string;
  date_created: string;
  date_submitted: string;
  form_subission_value: string;
  form_submission_field: string;
  gtm_account_id: string;
  gtm_container: string;
  instructions: string;
  name?: string;
  promo_code: string;
  reg_confirm: string;
  request_fields: IRequestField[];
  state: DataRequestState;
  url_start: string;
  zendesk_ticket: IZendeskTicket;
}

interface IZendeskTicket {
  id: string;
}

export class DataRequest extends DisplayModel<IDataRequest> {
  public readonly className = 'DataRequest';

  public constraints: TConstraints<IDataRequest> = {
    name: { presence: { allowEmpty: false, message: '^Data Request must have a name.' } },
    gtm_container: {
      presence: {
        allowEmpty: false,
        message: '^Data request must include the GTM container for this request.',
      },
      length: { minimum: 6 },
    },
    gtm_account_id: {
      length: (value: string | undefined) => {
        if (value && value.length > 0) {
          return { minimum: 6, message: 'GTM Account ID must be 6 characters if provided.' };
        }
        return undefined;
      },
    },
    url_start: { url: true, presence: { allowEmpty: false } },
    request_fields: {
      array: {
        field_name: {
          presence: {
            allowEmpty: false,
          },
        },
        request_type: {
          presence: { allowEmpty: false, message: '^Request field must have a request type.' },
        },
        feathr_attr: { presence: { allowEmpty: false } },
        attr_type: { presence: { allowEmpty: false } },
      },
    },
    context: {
      presence: { allowEmpty: false },
    },
    conversion_pixel: {
      presence: { allowEmpty: false },
    },
    form_submission_value: (_: string, attributes: Partial<IDataRequest>) => {
      if (attributes.form_submission_field) {
        return {
          presence: {
            allowEmpty: false,
            message: '^You must provide a value for the form submit field.',
          },
        };
      }
      return undefined;
    },
    form_submission_field: (_: string, attributes: Partial<IDataRequest>) => {
      if (attributes.form_submission_value) {
        return {
          presence: {
            allowEmpty: false,
            message: '^You must provide a field for the form submit value.',
          },
        };
      }
      return undefined;
    },
  };

  constructor(attributes: Partial<IDataRequest> = {}) {
    super(attributes);

    makeObservable(this);
  }

  @action.bound
  public async submitted(): Promise<void> {
    return this.changeState('submitted', true);
  }

  @action.bound
  public async canceled(): Promise<void> {
    return this.changeState('canceled', false);
  }

  @action.bound
  public async archived(): Promise<void> {
    return this.changeState('archived', false);
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/data/pixel/implementations/${this.id}`, pathSuffix);
  }

  public getUsedCustomFields(customFields: CustomFields): CustomField[] {
    const defaults = ['name', 'email', 'companies', 'occupation', 'external_id'];
    return this.get('request_fields')
      .filter((m: IRequestField) => m.feathr_attr && !defaults.includes(m.feathr_attr))
      .map((m: IRequestField) => customFields.get(m.feathr_attr));
  }

  public getEphemeralCustomFields(customFields: CustomFields): CustomField[] {
    return this.getUsedCustomFields(customFields).filter((cf) => cf.isEphemeral);
  }

  private async changeState(
    state: 'submitted' | 'canceled' | 'archived',
    validate = true,
  ): Promise<void> {
    if (!this.collection) {
      throw new Error('Data Request is not in a collection.');
    }
    if (validate && !this.isValid([], true)) {
      throw new Error(this.validate([], false).errors.join('\n'));
    }
    this.isUpdating = true;
    const response = await wretch<IDataRequest>(`${this.collection.url()}${this.id}/${state}`, {
      method: 'POST',
      headers: this.collection.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    runInAction(() => {
      this.set(response.data);
      this.isUpdating = false;
    });
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Implementation';
  }
}

export class DataRequests extends Collection<DataRequest> {
  public getClassName(): string {
    return 'data_requests';
  }

  public getModel(attributes: IDataRequest): DataRequest {
    return new DataRequest(attributes);
  }
}

export enum DataRequestState {
  Draft = 'draft',
  Submitted = 'submitted',
  InProgress = 'in_progress',
  NeedsFeedback = 'needs_feedback',
  Live = 'live',
  Completed = 'completed',
  Canceled = 'canceled',
  Archived = 'archived',
}
