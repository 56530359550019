import type { TCountryCodes } from '@feathr/hooks';
import type { TConstraints } from '@feathr/rachis';

/**
 * @see https://help.feathr.co/hc/en-us/articles/360036646474-Glossary-of-Attribute-Fields
 */
export interface IAddress {
  /** Company name. */
  company_name?: string;
  /** First line of a physical address. */
  premise1: string;
  /** Second line of a physical address. */
  premise2?: string;
  /** City, town, or village. */
  locality: string;
  /** State, province, or region. ISO 3166-2. */
  administrative_area_name: string;
  /** Postal code or zip code of area. */
  postal_code: string;
  /** Country code. ISO 3166-1 alpha-2 country codes. */
  country_code: TCountryCodes;
}

export const addressConstraints: TConstraints<IAddress> = {
  premise1: {
    presence: {
      allowEmpty: false,
      message: "^Address Line 1 can't be blank.",
    },
  },
  locality: {
    presence: {
      allowEmpty: false,
      message: "^City can't be blank.",
    },
  },
  administrative_area_name: {
    presence: {
      allowEmpty: false,
      message: "^State can't be blank.",
    },
  },
  postal_code: {
    presence: {
      allowEmpty: false,
      message: "^Postal Code can't be blank.",
    },
  },
  country_code: {
    presence: {
      allowEmpty: false,
      message: "^Country can't be blank.",
    },
  },
};
