import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { DataRequest } from '@feathr/blackbox';
import { Button, Fieldset, Form, Input } from '@feathr/components';
import type { TValidateGrouped } from '@feathr/rachis';

import ButtonNext from './ButtonNext';

interface IProps {
  dataRequest: DataRequest;
  onNext: () => void;
  onPrevious: () => void;
}

export function validateStepTwo(dataRequest: DataRequest): TValidateGrouped {
  return dataRequest.validate(['url_start'], false, 'grouped').errors;
}

function DataRequestFormStepTwo({
  dataRequest,
  onNext,
  onPrevious,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  return (
    <Form
      actions={[
        <Button key={'previous'} onClick={onPrevious}>
          {t('Previous')}
        </Button>,
        <ButtonNext
          dataRequest={dataRequest}
          key={'next'}
          onNext={onNext}
          validate={validateStepTwo}
        />,
      ]}
      description={
        <p>
          {t(
            'Provide the URL for the start of your form or flow. This tells us where to start looking for data that you want to capture.',
          )}
        </p>
      }
      label={'Edit Message: Name'}
    >
      <Fieldset>
        <Input
          attribute={'url_start'}
          helpText={t('URL where your form or flow begins.')}
          label={t('Starting URL')}
          model={dataRequest}
          placeholder={'https://www.example.com/feathrcon2021/reg_start'}
          type={'url'}
        />
        <Input
          attribute={'promo_code'}
          helpText={t('A code to get through the payment portion of a flow (if applicable).')}
          label={t('Promo code')}
          model={dataRequest}
          optional={true}
          placeholder={'FeathrTestCode'}
          type={'text'}
        />
      </Fieldset>
    </Form>
  );
}

export default DataRequestFormStepTwo;
