import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { AggregatedStat } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { StatBox, StatsCardV1 } from '@feathr/components';

interface ICardProps {
  aggregatedStats: AggregatedStat;
  budget: number;
  conversions: number;
  isGoogle?: boolean;
  monetizationValue?: number;
  showBudget?: boolean;
  showCPA?: boolean;
  showCPM?: boolean;
  showMonetizationROI?: boolean;
  spendTooltip?: string;
}

function BudgetCard({
  budget,
  conversions,
  isGoogle = false,
  aggregatedStats,
  monetizationValue = 0,
  showBudget = true,
  showCPA = true,
  showCPM = true,
  showMonetizationROI = false,
  spendTooltip,
}: ICardProps): JSX.Element {
  const flavors = aggregatedStats.get('flavors', {});

  const views = (flavors.ad_view || 0) + (flavors.page_view || 0) + (flavors.email_view || 0);
  const clicks = isGoogle
    ? aggregatedStats.get('num_clicks', 0)
    : (flavors.ad_click || 0) + (flavors.page_link_click || 0) + (flavors.email_link_click || 0);
  const spend = aggregatedStats.get('spend') || 0;

  const spendFormatted = numeral(spend).format('$0,0.00');
  const budgetFormatted = numeral(budget).format('$0,0.00');
  const valueFormatted = numeral(monetizationValue).format('$0,0.00');
  const roiFormatted = numeral(monetizationValue - spend).format('$0,0.00');
  const cpm = numeral(views > 0 ? spend / (views / 1000) : 0).format('$0,0.00');
  const cpc = numeral(clicks > 0 ? spend / clicks : 0).format('$0,0.00');
  const cpa = numeral(conversions > 0 ? spend / conversions : 0).format('$0,0.00');

  return (
    <StatsCardV1 title={reportModuleLabels.includeSpendPerformance}>
      <StatBox
        label={'Spend'}
        primary={spendFormatted}
        secondary={showBudget ? `${budgetFormatted} Budget` : ''}
        tooltip={spendTooltip}
      />
      {!!showMonetizationROI && (
        <StatBox
          label={reportModuleLabels.includeMonetizationROI}
          primary={roiFormatted}
          secondary={`Monetization Value: ${valueFormatted}`}
        />
      )}
      {showCPM && (
        <StatBox
          label={'CPM'}
          primary={cpm}
          tooltip={'Cost per Mille: the cost for every thousand views'}
        />
      )}
      <StatBox label={'CPC'} primary={cpc} tooltip={'Cost per Click: the amount spent per click'} />
      {showCPA && (
        <StatBox
          label={'CPA'}
          primary={cpa}
          tooltip={'Cost per Activity: the amount spent per Conversion'}
        />
      )}
    </StatsCardV1>
  );
}

export default BudgetCard;
