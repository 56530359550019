import { observer } from 'mobx-react-lite';
import React from 'react';

import type { IEditableField } from '@feathr/blackbox';
import type { Event } from '@feathr/blackbox';
import { Button, FormElement } from '@feathr/components';

import PartnerField from '../PartnerField';

import * as styles from './PartnerFields.css';

interface IProps {
  event: Event;
}

function PartnerFields({ event }: IProps) {
  const partnerFieldList: IEditableField[] = event.get('editable_partner_field_list');
  const onRemove = (f_key: string) => {
    event.set({
      editable_partner_field_list: partnerFieldList.filter((field) => field.f_key !== f_key),
    });
  };
  return (
    <FormElement className={styles.root} label={'Fields'}>
      {partnerFieldList.map((field) => (
        <PartnerField event={event} field={field} key={field.f_key} onRemove={onRemove} />
      ))}
      <Button
        onClick={() => {
          partnerFieldList.push({
            f_key: '',
            u_key: '',
            read_only: false,
            description: '',
            label: '',
            data_type: 'str',
            form_type: 'text',
            required: false,
          });
          event.setAttributeDirty('editable_partner_field_list');
        }}
        type={'primary'}
      >
        Add field
      </Button>
    </FormElement>
  );
}

export default observer(PartnerFields);
