import type { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import {
  faBrowser,
  faEnvelope,
  faEnvelopeOpenText,
  faImage,
  faLink,
} from '@fortawesome/pro-light-svg-icons';
import type { TFunction } from 'i18next';

import { TemplateClass } from '@feathr/blackbox';

export function templateTypeTheme(type: TemplateClass): string {
  const typeMap: Record<TemplateClass, string> = {
    [TemplateClass.Email]: 'yellow',
    [TemplateClass.ReferralEmail]: 'yellow',
    [TemplateClass.Page]: 'green',
    [TemplateClass.LandingPage]: 'green',
    [TemplateClass.ReferralPage]: 'green',
    [TemplateClass.Banner]: 'sky',
    [TemplateClass.ReferralBanner]: 'sky',
    [TemplateClass.Link]: 'sky',
    [TemplateClass.PinpointEmail]: 'yellow',
  };
  return typeMap[type];
}

export function templateTypeIcon(type: TemplateClass): IconDefinition {
  const typeMap: Record<TemplateClass, IconDefinition> = {
    [TemplateClass.Email]: faEnvelope,
    [TemplateClass.ReferralEmail]: faEnvelope,
    [TemplateClass.Page]: faBrowser,
    [TemplateClass.LandingPage]: faBrowser,
    [TemplateClass.ReferralPage]: faBrowser,
    [TemplateClass.Banner]: faImage,
    [TemplateClass.ReferralBanner]: faImage,
    [TemplateClass.Link]: faLink,
    [TemplateClass.PinpointEmail]: faEnvelopeOpenText,
  };
  return typeMap[type];
}

export type TTemplateGroup = 'account' | 'campaign' | 'default' | 'project';

export function templateGroupTheme(group: TTemplateGroup): string {
  const groupMap: Record<TTemplateGroup, string> = {
    account: 'indigo',
    campaign: 'green',
    default: 'teal',
    project: 'sky',
  };
  return groupMap[group];
}

export function templateGroupLabel(group: TTemplateGroup, t: TFunction): string {
  const groupMap: Record<TTemplateGroup, string> = {
    account: t('Account'),
    campaign: t('Campaign'),
    default: t('Default'),
    project: t('Project'),
  };
  return groupMap[group];
}
