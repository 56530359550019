import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CampaignClass, FacebookIntegrationState } from '@feathr/blackbox';
import { useAccount } from '@feathr/extender/state';

interface IDisabledFBTooltipProps {
  cls: CampaignClass;
  state: FacebookIntegrationState;
}

function DisabledFBTooltip({ cls, state }: IDisabledFBTooltipProps): JSX.Element | null {
  const account = useAccount();
  const { t } = useTranslation();
  if (state === FacebookIntegrationState.not_connected) {
    return (
      <Trans t={t}>
        Log in to your Facebook account via Feathr, then grant access to a Facebook page to launch
        Meta campaigns.
      </Trans>
    );
  } else if (state !== FacebookIntegrationState.page_selected) {
    return <Trans t={t}>Select a Facebook page via Feathr to launch Meta campaigns.</Trans>;
  } else if (
    cls === CampaignClass.EmailListFacebook &&
    !account.get('facebook').tos_accepted.custom_audience_tos
  ) {
    return (
      <Trans t={t}>
        Your Meta integration is pending approval. Please check back soon to launch Meta email
        mapping campaigns.
      </Trans>
    );
  }
  return null;
}

export default observer(DisabledFBTooltip);
