import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTimeFrameValue } from '@feathr/components';
import { ETheme } from '@feathr/components';
import type { IChart } from '@feathr/extender/components/AudienceAreaChart';
import AudienceAreaChart from '@feathr/extender/components/AudienceAreaChart';
import useDashboardStats from '@feathr/extender/hooks/useDashboardStats';

interface IProps {
  timeFrameValue: TTimeFrameValue;
}

function EmailPerformanceCharts({ timeFrameValue }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { isLoading, chartData } = useDashboardStats({ timeFrameValue });
  const shared = {
    theme: ETheme.yellow,
    shade: 500,
  };

  const charts: IChart[] = [
    {
      label: t('Sends'),
      tooltip: t('The overall number of email sends that have taken place.'),
      yAxisKey: 'num_email_sends',
      descriptor: t('sends'),
      ...shared,
    },
    {
      label: t('Opens'),
      tooltip: t('The number of email opens that have taken place across all emails'),
      yAxisKey: 'num_email_opens',
      descriptor: t('opens'),
      ...shared,
    },
    {
      label: t('Click-through rate'),
      tooltip: t('The overall click through rate across all emails.'),
      yAxisKey: 'email_click_through_rate',
      descriptor: t('CTR'),
      operation: 'percentage',
      ...shared,
    },
    {
      label: t('Bounces/Complaints'),
      tooltip: t('The overall number of email bounces and complaints that have been recorded.'),
      yAxisKey: 'num_email_bounces_and_complaints',
      descriptor: t('bounces/complaints'),
      ...shared,
    },
    {
      label: t('Clicks'),
      tooltip: t('The number of email clicks that have occurred across all emails.'),
      yAxisKey: 'num_email_clicks',
      descriptor: t('clicks'),
      ...shared,
    },
    {
      label: t('Conversions'),
      tooltip: t('The overall number of conversions driven by emails.'),
      yAxisKey: 'num_conversions_email.full',
      descriptor: t('conversions'),
      ...shared,
    },
  ];

  return (
    <AudienceAreaChart.Group
      backfillData={true}
      charts={charts}
      data={chartData}
      isLoading={isLoading}
      rowSize={2}
      separator={false}
      showValue={true}
    />
  );
}

export default EmailPerformanceCharts;
