import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { ToastType } from 'react-toastify';

import type { IFacebook } from '@feathr/blackbox';
import { FacebookIntegrationState } from '@feathr/blackbox';
import type { IButtonProps } from '@feathr/components';
import { Button, toast } from '@feathr/components';
import type { FacebookUser } from '@feathr/extender/integrations/facebook';
import facebookLogin from '@feathr/extender/integrations/facebook';
import { useAccount } from '@feathr/extender/state';

interface IFacebookConnectButtonProps extends Omit<IButtonProps, 'onClick'> {
  children: React.ReactNode;
}

function FacebookConnectButton({
  children,
  ...buttonProps
}: IFacebookConnectButtonProps): JSX.Element {
  const account = useAccount();

  const integration = account.get('facebook', {} as IFacebook);

  return (
    <Button
      {...buttonProps}
      onClick={connectFacebookUser}
      prefix={<FontAwesomeIcon icon={faFacebookSquare} size={'3x'} />}
    >
      {children}
    </Button>
  );

  async function connectFacebookUser(): Promise<void> {
    try {
      const facebookUser: FacebookUser = await facebookLogin();
      const attributes: Partial<IFacebook> = {
        access_token: facebookUser.access_token,
        user_id: facebookUser.user_id,
        username: facebookUser.username,
        state: FacebookIntegrationState.no_page_selected,
      };
      account.set({
        facebook: {
          ...integration,
          ...attributes,
        },
      });
      await account.patchDirty();
      toast('Your Facebook account is now connected', { type: ToastType.INFO });
      window.Appcues?.identify(account.id, {
        account_id: account.id,
        is_integrated_with_meta: true,
      });
    } catch (e) {
      toast('There was an issue connecting your Facebook account. Please try again later.', {
        type: ToastType.ERROR,
      });
    }
  }
}

export default observer(FacebookConnectButton);
