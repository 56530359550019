import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { ITemplate } from '@feathr/blackbox';
import type { Template } from '@feathr/blackbox';
import {
  ArchiveModalV1,
  ConfirmModalV1,
  ContextMenu,
  MenuDivider,
  MenuItem,
} from '@feathr/components';
import TemplateSummary from '@feathr/extender/components/TemplateSummary';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import { toastMessage } from '../templateUtils';

interface IProps {
  template: Template;
}

function EventTemplatesCampaignOptions({ template }: IProps) {
  const { Templates } = useContext(StoresContext);
  const localUrl = useLocalUrl();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isProjectModalOpen, toggleProjectModalOpen] = useToggle(false);
  const { t } = useTranslation();

  async function handleMakeProject() {
    const patch = {
      event_id: template.get('event'),
    } as Partial<ITemplate>;

    const updatedTemplate = await template.clone(patch);
    Templates.refreshApiCache();

    toastMessage({
      updatedTemplate,
      successMessage: t('Your template has been cloned and turned into a project template.'),
      errorMessage: t('An error occurred while trying to clone your template:\n{{error}}', {
        error: updatedTemplate.error?.message || 'Unexpected error',
      }),
    });
    toggleProjectModalOpen();
  }

  return (
    <>
      <ContextMenu>
        <MenuItem disabled={template.isUpdating} link={localUrl(template.getItemUrl())}>
          {t('View')}
        </MenuItem>
        <MenuItem disabled={template.isUpdating} link={localUrl(template.getItemUrl('edit'))}>
          {t('Edit')}
        </MenuItem>
        <MenuDivider />
        <MenuItem disabled={template.isUpdating} onClick={toggleProjectModalOpen}>
          {t('Clone as saved template')}
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={toggleArchiveModalOpen}>{t('Archive')}</MenuItem>
      </ContextMenu>
      {isProjectModalOpen && (
        <ConfirmModalV1
          message={t('Are you sure you want to clone this template into a project template?')}
          onClose={toggleProjectModalOpen}
          onConfirm={handleMakeProject}
          t={t}
          title={t('Clone as saved template')}
        >
          <TemplateSummary template={template} />
        </ConfirmModalV1>
      )}
      {isArchiveModalOpen && (
        <ArchiveModalV1
          errorMessage={t('There was an issue archiving the template. Please try again later.')}
          model={template}
          onClose={toggleArchiveModalOpen}
          successMessage={t('Template {{name}} has been archived.', { name: template.name })}
          t={t}
        >
          <TemplateSummary template={template} />
        </ArchiveModalV1>
      )}
    </>
  );
}

export default observer(EventTemplatesCampaignOptions);
