import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { ICampaignReportActionsProps } from './CampaignActions';
import CampaignActions from './CampaignActions';
import type { IReportActionsFlightProps } from './FlightActions';
import FlightReportActions from './FlightActions';
import type { IReportActionsProjectProps } from './ReportActionsProject';
import ProjectReportActions from './ReportActionsProject';

interface IProjectProps extends IReportActionsProjectProps {
  campaign?: never;
  flight?: never;
}

interface ICampaignProps extends ICampaignReportActionsProps {
  flight?: never;
  project?: never;
}
interface IFlightProps extends IReportActionsFlightProps {
  campaign?: never;
  project?: never;
}

type TReportActionsProps = IProjectProps | ICampaignProps | IFlightProps;

function isProjectProps(obj: TReportActionsProps): obj is IProjectProps {
  return 'project' in obj;
}

function isCampaignProps(obj: TReportActionsProps): obj is ICampaignProps {
  return 'campaign' in obj;
}

function isFlightProps(obj: TReportActionsProps): obj is IFlightProps {
  return 'flight' in obj;
}

function ReportActions(props: TReportActionsProps): JSX.Element | null {
  const isCampaign = isCampaignProps(props);
  const isProject = isProjectProps(props);
  const isFlight = isFlightProps(props);

  if (isCampaign) {
    const { campaign, children, ...reportExportProps } = props;
    return (
      <CampaignActions campaign={campaign} {...reportExportProps}>
        {children}
      </CampaignActions>
    );
  }

  if (isFlight) {
    const { flight, config, ...reportExportProps } = props;
    return <FlightReportActions config={config} flight={flight} {...reportExportProps} />;
  }

  if (isProject) {
    const { project, config, ...reportExportProps } = props;
    return <ProjectReportActions config={config} project={project} {...reportExportProps} />;
  }

  return null;
}

export default observer(ReportActions);
