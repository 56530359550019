import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Domain, IDomainRecordsForwardRequest } from '@feathr/blackbox';
import { validateRecordsRequest } from '@feathr/blackbox';
import {
  ButtonValid,
  Card,
  Collapse,
  EmailInput,
  Fieldset,
  Skeleton,
  Textarea,
  toast,
} from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';

import * as styles from './WebmasterEmailCompose.css';

interface IProps {
  domain: Domain;
  recordsRequest: IDomainRecordsForwardRequest;
  setRecordsRequest: Dispatch<SetStateAction<IDomainRecordsForwardRequest>>;
}

function WebmasterEmailCompose({ domain, recordsRequest, setRecordsRequest }: IProps): JSX.Element {
  const { t } = useTranslation();

  const validationErrors = validateRecordsRequest(recordsRequest);

  async function handleSendRecords(): Promise<void> {
    try {
      await domain.sendRecords(recordsRequest);
      toast(t('Your records were sent to the provided email address.'), {
        type: ToastType.SUCCESS,
      });
    } catch (error) {
      toast(t('There was an error sending your records. Please contact Support.'), {
        type: ToastType.ERROR,
      });
    }
  }

  function handleChangeMessage(newValue?: string): void {
    /*
     * <Textarea>'s onChange prop typing require this function to be typed with
     * newValue possibly undefined, but at minimum "newValue" should get an empty string
     * from the <Textarea>
     */
    setRecordsRequest({ ...recordsRequest, message: newValue ?? '' });
  }

  function handleChangeRecipient(newValue?: string): void {
    setRecordsRequest({ ...recordsRequest, recipient: newValue ?? '' });
  }

  return (
    <Card className={styles.card}>
      <Collapse defaultCollapsed={false} title={t('Send domain records to your webmaster')}>
        <Fieldset
          helpText={t(
            'Your domain records will be sent with the message you compose along with a link to download the domain records.',
          )}
        >
          <EmailInput
            label={t('To')}
            onChange={handleChangeRecipient}
            placeholder={'webmaster@domain.com'}
            t={t}
            validationError={recordsRequest.recipient ? validationErrors.recipient : ''}
            value={recordsRequest.recipient || undefined}
          />
          {domain.isPending ? (
            <Skeleton paragraphs={3} title={true} />
          ) : (
            <Textarea
              label={t('Message')}
              onChange={handleChangeMessage}
              rows={5}
              value={recordsRequest.message}
            />
          )}
          <div className={styles.sendButton}>
            <ButtonValid errors={flattenErrors(validationErrors)} onClick={handleSendRecords}>
              {t('Send')}
            </ButtonValid>
          </div>
        </Fieldset>
      </Collapse>
    </Card>
  );
}

export default observer(WebmasterEmailCompose);
