import { faCheck, faDash, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React from 'react';

import type { FacebookPage, IInstagram } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Button, Chip, TableColumnHeader, Tooltip } from '@feathr/components';

import ManagePageAgencyButton from './ManagePageAgencyButton';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './facebookPagesColumns.css';

// TODO: Verify that sorting solution per https://github.com/Feathr/shrike/issues/1595
function facebookPagesColumns(t: TFunction): Array<IColumn<FacebookPage>> {
  return [
    {
      id: 'page',
      Header: TableColumnHeader({
        title: t('Facebook Page'),
      }),
      minWidth: 150,
      Cell({ original }): JSX.Element {
        return (
          <Button
            className={styles.pictureButton}
            href={original.get('link')}
            icon={
              <img
                alt={'Facebook page logo'}
                className={styles.picture}
                src={original.get('picture')}
              />
            }
            target={'_blank'}
            type={'link'}
          >
            {original.get('name')}
          </Button>
        );
      },
    },
    {
      id: 'instagram',
      Header: TableColumnHeader({
        title: t('Instagram Account'),
      }),
      minWidth: 150,
      Cell({ original }): JSX.Element {
        enum EFacebookPageStatus {
          NOT_CONNECTED = 'NOT_CONNECTED',
          INVALID_PERMISSION = 'INVALID_PERMISSION',
          CONNECTED = 'CONNECTED',
        }
        const statusColorMap = new Map<string, string>([
          [EFacebookPageStatus.NOT_CONNECTED, 'default'],
          [EFacebookPageStatus.INVALID_PERMISSION, 'red'],
          [EFacebookPageStatus.CONNECTED, 'green'],
        ]);

        const statusLabelMap = new Map<string, string>([
          [EFacebookPageStatus.NOT_CONNECTED, t('Not connected')],
          [EFacebookPageStatus.INVALID_PERMISSION, t('No permissions')],
          [EFacebookPageStatus.CONNECTED, original.get('instagram').username],
        ]);

        const statusTooltipMap = new Map<string, string>([
          [EFacebookPageStatus.NOT_CONNECTED, t('No connected instagram account.')],
          [
            EFacebookPageStatus.INVALID_PERMISSION,
            t('Reconnect your Facebook account to grant Instagram permissions.'),
          ],
          [EFacebookPageStatus.CONNECTED, t('Connected instagram account.')],
        ]);

        function getStatus(instagram: IInstagram): string {
          if (!instagram.instagram_actor_id && !instagram.invalid_permission) {
            return EFacebookPageStatus.NOT_CONNECTED;
          }
          if (instagram.invalid_permission) {
            return EFacebookPageStatus.INVALID_PERMISSION;
          }
          return EFacebookPageStatus.CONNECTED;
        }

        const status = getStatus(original.get('instagram'));

        return (
          <Tooltip className={styles.truncated} title={statusTooltipMap.get(status)}>
            <Chip theme={statusColorMap.get(status)}>{statusLabelMap.get(status)}</Chip>
          </Tooltip>
        );
      },
    },
    {
      id: 'page_admin',
      Header: TableColumnHeader({
        title: t('Page Admin'),
      }),
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        const isPageAdmin = original.get('user_is_page_admin', false);
        return (
          <FontAwesomeIcon
            className={classNames({ [styles.success]: isPageAdmin, [styles.danger]: !isPageAdmin })}
            icon={isPageAdmin ? faCheck : faTimes}
          />
        );
      },
    },
    {
      id: 'business',
      Header: TableColumnHeader({
        title: t('Business'),
      }),
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        const business = Object.keys(original.get('business', {})).length
          ? original.get('business')
          : false;

        const title = business ? business.name : t('This page is not owned by a business.');
        return (
          <Tooltip className={styles.truncated} title={title}>
            {business ? business.name : <FontAwesomeIcon icon={faDash} />}
          </Tooltip>
        );
      },
    },
    {
      id: 'business_admin',
      Header: TableColumnHeader({
        title: t('Business Admin'),
      }),
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        const hasBusiness = Object.keys(original.get('business', {})).length;
        const isBusinessAdmin = original.get('user_is_business_admin', false);
        const icon = hasBusiness ? (isBusinessAdmin ? faCheck : faTimes) : faDash;
        return (
          <FontAwesomeIcon
            className={classNames({
              [styles.success]: hasBusiness && isBusinessAdmin,
              [styles.danger]: hasBusiness && !isBusinessAdmin,
            })}
            icon={icon}
          />
        );
      },
    },
    {
      id: 'access',
      Header: TableColumnHeader({
        title: t('Access'),
        tooltip: t(
          'Grant Feathr access to advertise on your behalf for one or more of your Facebook pages.',
        ),
      }),
      className: tableStyles.cellCenter,
      headerClassName: tableStyles.tooltip,
      maxWidth: 100,
      Cell({ original }): JSX.Element {
        return <ManagePageAgencyButton page={original} />;
      },
    },
  ];
}

export default facebookPagesColumns;
