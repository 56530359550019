import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from '@feathr/components';

interface IProps {
  onClick: () => void;
}

function AddRuleButton({ onClick }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('Add a rule to this filter')}>
      <Button name={'add_rule'} onClick={onClick} type={'icon'}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </Tooltip>
  );
}

export default observer(AddRuleButton);
