import { set } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { ISorted } from '@feathr/components';
import { Table } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';

import AdvertisersColumns from './AdvertisersColumns';

function Advertisers(): JSX.Element {
  const tableState = useLocalObservable(() => ({
    sort: [{ id: 'name' }] as ISorted[],
  }));
  const account = useAccount();

  const ttdIntegration = account.get('ttd');
  const advertisers = ttdIntegration?.subadvertisers || [];

  function onSortChange(sort: ISorted[]): void {
    set(tableState, 'sort', sort);
  }

  return (
    <Table
      columns={AdvertisersColumns}
      // Cannot sort observable in place, so make a copy using slice().
      idKey={'subadv_id'}
      isPaginated={false}
      items={advertisers.slice().sort((a, b) => {
        let result = 0;
        tableState.sort.forEach((s) => {
          if (result === 0) {
            const av = a[s.id];
            const bv = b[s.id];
            const d = s.desc ? -1 : 1;
            if (av > bv) {
              result = 1 * d;
            } else if (av < bv) {
              result = -1 * d;
            }
          } else {
            return;
          }
        });
        return result;
      })}
      noDataText={'No advertisers yet.'}
      onSortChange={onSortChange}
      sort={tableState.sort}
    />
  );
}

export default observer(Advertisers);
