import { Observer, observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { TDataRequestState } from '@feathr/blackbox';
import type { DataRequestState } from '@feathr/blackbox';
import { Alert, AlertType, Button, Step, Steps, toast, Wizard } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { StoresContext } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';

import DataRequestFormStepFive from './DataRequestPageForm/DataRequestFormStepFive';
import DataRequestFormStepFour, {
  validateStepFour,
} from './DataRequestPageForm/DataRequestFormStepFour';
import DataRequestFormStepOne, {
  validateStepOne,
} from './DataRequestPageForm/DataRequestFormStepOne';
import DataRequestFormStepSix from './DataRequestPageForm/DataRequestFormStepSix';
import DataRequestFormStepThree, {
  validateStepThree,
} from './DataRequestPageForm/DataRequestFormStepThree';
import DataRequestFormStepTwo, {
  validateStepTwo,
} from './DataRequestPageForm/DataRequestFormStepTwo';

function DataRequestPage(): JSX.Element {
  const { dataRequestId } = useParams<{ dataRequestId: string }>();
  const [currentStep, setCurrentStep] = useState(0);
  const [completeStep, setCompleteStep] = useState<number | undefined>();
  const { t } = useTranslation();

  const { Segments, DataRequests } = useContext(StoresContext);
  const dataRequest = DataRequests.get(dataRequestId);

  useEffect(() => {
    function getCompletedStep() {
      if (flattenErrors(validateStepOne(dataRequest)).length) {
        return 0;
      }
      if (flattenErrors(validateStepTwo(dataRequest)).length) {
        return 1;
      }
      if (validateStepThree(dataRequest).length) {
        return 2;
      }
      if (flattenErrors(validateStepFour(dataRequest)).length) {
        return 3;
      }
      return 5;
    }

    if (!dataRequest.isPending) {
      const state = dataRequest.get('state');
      if (state === 'submitted' || state === 'in_progress' || state === 'completed') {
        setCurrentStep(5);
        setCompleteStep(5);
      } else {
        const step = getCompletedStep();
        setCurrentStep(step);
        setCompleteStep(step);
      }
    }
  }, [dataRequest, dataRequest.isPending]);

  function handleNext() {
    const nextStep = Math.min(currentStep + 1, 5);
    setCurrentStep(nextStep);
    if (completeStep === undefined || completeStep < currentStep) {
      setCompleteStep(currentStep);
    }
  }

  function handlePrevious() {
    const previousStep = Math.max(currentStep - 1, 0);
    setCurrentStep(previousStep);
  }

  async function handleSave(state: TDataRequestState) {
    const conversionPixelId = dataRequest.get('conversion_pixel');
    let conversionPixel = conversionPixelId ? Segments.get(conversionPixelId) : undefined;

    try {
      if (conversionPixel && conversionPixel.isEphemeral) {
        conversionPixel = await Segments.add(conversionPixel);
        if (!conversionPixel.isErrored) {
          dataRequest.set({ conversion_pixel: conversionPixel.id });
        }
      }

      await dataRequest.patchDirty();
      if (dataRequest.isErrored) {
        throw dataRequest.error;
      }
      if (state === 'submitted') {
        await dataRequest.submitted();
      }
      toast(t('Request saved'), {
        type: 'success',
      });

      // If err is instance of Error, it should be of type any.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      let error = e.toString();
      if (e.json) {
        error = e.json.dataRequest;
      }
      toast(t('Something went wrong while saving your request: {{error}}', { error }));
    }
  }

  const actions = [
    <Observer key={'draft'}>
      {() => {
        const state = dataRequest.get('state', 'draft' as DataRequestState);
        return (
          <>
            {state === 'draft' && (
              <Button onClick={async () => handleSave('draft')}>{t('Save as Draft')}</Button>
            )}
          </>
        );
      }}
    </Observer>,
  ];

  const completed = dataRequest.get('state') !== 'draft' && dataRequest.get('state') !== 'canceled';

  const steps = (
    <Steps
      current={currentStep}
      disabled={completed}
      initialCompleted={completeStep}
      onChange={setCurrentStep}
    >
      <Step key={0} stepIndex={0} title={t('Context')} />
      <Step key={1} stepIndex={1} title={t('Locations')} />
      <Step key={2} stepIndex={2} title={t('Field Capture')} />
      <Step key={3} stepIndex={3} title={t('Conversion Capture')} />
      <Step key={4} stepIndex={4} title={t('Instructions')} />
      <Step key={5} stepIndex={5} title={t('Review')} />
    </Steps>
  );

  return (
    <Page title={dataRequest.name} width={'wide'}>
      {completed && (
        <Alert type={AlertType.warning}>
          {t('This request was submitted and cannot be edited.')}
        </Alert>
      )}
      {dataRequest.get('state') === 'canceled' && (
        <Alert type={AlertType.danger}>{t('This request was canceled.')}</Alert>
      )}
      <Wizard actions={actions} steps={steps}>
        {currentStep === 0 && (
          <DataRequestFormStepOne dataRequest={dataRequest} onNext={handleNext} />
        )}
        {currentStep === 1 && (
          <DataRequestFormStepTwo
            dataRequest={dataRequest}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {currentStep === 2 && (
          <DataRequestFormStepThree
            dataRequest={dataRequest}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {currentStep === 3 && (
          <DataRequestFormStepFour
            dataRequest={dataRequest}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {currentStep === 4 && (
          <DataRequestFormStepFive
            dataRequest={dataRequest}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {currentStep === 5 && (
          <DataRequestFormStepSix
            dataRequest={dataRequest}
            onPrevious={handlePrevious}
            onSave={async () => handleSave('submitted')}
          />
        )}
      </Wizard>
    </Page>
  );
}

export default observer(DataRequestPage);
