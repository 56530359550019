import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Event, IConfig } from '@feathr/blackbox';
import { Button, Toolbar } from '@feathr/components';
import type { IUseReportExportReturn } from '@feathr/extender/hooks';
import { useLocalUrl } from '@feathr/extender/state';

import ReportActionsContextMenu from './ReportActionsContextMenu';

export interface IReportActionsProjectProps extends IUseReportExportReturn<IConfig> {
  project: Event;
}

function ReportActionsProject({
  project,
  ...reportExportProps
}: Readonly<IReportActionsProjectProps>): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  return (
    <Toolbar>
      <ReportActionsContextMenu model={project} {...reportExportProps} />
      <Button
        link={localUrl(project.getItemUrl('settings'))}
        prefix={<FontAwesomeIcon icon={faCog} />}
      >
        {t('Settings')}
      </Button>
    </Toolbar>
  );
}

export default observer(ReportActionsProject);
