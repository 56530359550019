import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import facebookPagesColumns from './facebookPagesColumns';

function FacebookPagesTable(): JSX.Element {
  const { FacebookPages } = useContext(StoresContext);
  const { t } = useTranslation();

  return (
    <Table
      collection={FacebookPages}
      columns={facebookPagesColumns(t)}
      noDataText={t('No pages found.')}
    />
  );
}

export default observer(FacebookPagesTable);
