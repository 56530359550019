import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Segment, Targetable, Targeting } from '@feathr/blackbox';
import { Categories, TargetableClass } from '@feathr/blackbox';
import { Button, Card, Fieldset, Radios, Select, Tooltip } from '@feathr/components';
import type { ISegmentSelectSimpleChangeProps } from '@feathr/extender/components/SegmentSelectSimple';
import SegmentSelectSimple from '@feathr/extender/components/SegmentSelectSimple';
import { StoresContext } from '@feathr/extender/state';

import LookalikeTargetingDataSelect from './LookalikeTargetingDataSelect';

import * as styles from './LookalikeTargeting.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

interface ICategoryOption {
  id: number;
  name: string;
}

interface ICategoryGroups {
  label: string;
  options: ICategoryOption[];
}

function LookalikeTargeting({ campaign, targeting, onRemove }: IProps): JSX.Element {
  const { Segments, Targetables } = useContext(StoresContext);
  const { t } = useTranslation();

  let targetable: Targetable;
  const targetableId = targeting.get('target_data');
  if (targetableId) {
    targetable = Targetables.get(targetableId);
  } else {
    targetable = Targetables.create({
      _cls: TargetableClass.lookalike,
      name: t('Lookalike Targeting'),
      mode: 'auto',
      category_id: 468,
      partner: campaign.get('parent_kind') === 'partner' ? campaign.get('parent') : undefined,
    });
    targeting.set({ target_data: targetable.get('id') });
  }
  let segment: Segment | undefined;
  const segmentId = targetable.get('seed_segment');
  if (segmentId) {
    segment = Segments.get(segmentId);
  }

  function handleRemoveSegment(): void {
    onRemove(targeting);
  }

  async function handleSegmentChange({ id }: ISegmentSelectSimpleChangeProps): Promise<void> {
    if (id) {
      const newSegment = Segments.get(id);
      await when(() => !newSegment.isPending);
      targetable.set({ name: newSegment.get('name'), seed_segment: id });
    }
  }

  function handleCategorySelect(option: ICategoryOption | ICategoryGroups): void {
    targetable.set({ category_id: (option as ICategoryOption).id });
  }

  const selectedGroup = Categories.find(
    (cat) => !!cat.options.find((opt) => opt.id === targetable.get('category_id')),
  );
  const selectedCategory =
    selectedGroup && selectedGroup.options.find((cat) => cat.id === targetable.get('category_id'));

  return (
    <>
      <Card
        actions={[
          <Tooltip key={'remove'} title={t('Remove')}>
            <Button
              className={styles.cardButton}
              name={'remove_segment'}
              onClick={handleRemoveSegment}
              type={'naked'}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>,
        ]}
      >
        <Fieldset>
          <Radios
            attribute={'mode'}
            className={styles.radios}
            label={t('Mode')}
            model={targetable}
            name={'targetable_mode'}
            options={[
              { id: 'auto', name: t('Auto') },
              { id: 'manual', name: t('Custom') },
            ]}
          />
          {targetable.get('mode') === 'auto' ? (
            <>
              <SegmentSelectSimple
                filters={{
                  stats__num_cookies_total__gte: 30000,
                  is_conversion_segment__ne: true,
                }}
                helpText={t(
                  'Choose a group to use to derive your Lookalike data for this target. Groups must have at least 30,000 reachable People to be eligible.',
                )}
                hideCreate={true}
                label={t('Group')}
                name={'segment_select'}
                onChange={handleSegmentChange}
                required={true}
                value={segment?.id}
              />
            </>
          ) : (
            <>
              <Select<ICategoryOption | ICategoryGroups>
                helpText={t('Choose a category to narrow your search')}
                label={t('Category')}
                name={'category_select'}
                onSelectSingle={handleCategorySelect}
                optional={true}
                options={Categories}
                value={selectedCategory}
              />
              <LookalikeTargetingDataSelect campaign={campaign} targeting={targeting} />
            </>
          )}
        </Fieldset>
      </Card>
    </>
  );
}

export default observer(LookalikeTargeting);
