import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { IStats } from '@feathr/blackbox';
import type { Goal as GoalModel, LandingPageCampaign } from '@feathr/blackbox';
import { MAX_GOALS } from '@feathr/blackbox';
import { Button, Card, Fieldset, Form, Radios, Spinner } from '@feathr/components';
import Goal from '@feathr/extender/components/Goal';
import { StoresContext } from '@feathr/extender/state';

import AdvancedConversionTrackingSettings from '../../CampaignEditPage/StepGoals/AdvancedConversionTrackingSettings';
import LandingPageCampaignInfo from './LandingPageCampaignInfo';
import LandingPageCampaignPage from './LandingPageCampaignPage';

import * as styles from './SettingsLandingPageCampaignPage.css';

function SettingsLandingPageCampaignPage(): JSX.Element {
  const { t } = useTranslation();
  const { campaignId } = useParams<{ eventId: string; campaignId: string }>();
  const { Campaigns, Goals } = React.useContext(StoresContext);
  const campaign = Campaigns.get(campaignId) as LandingPageCampaign;

  const goals = Goals.list({
    filters: {
      _parent: campaignId,
      is_archived__ne: true,
    },
  });

  function addGoal(): void {
    const model = Goals.create({
      parent: campaign.get('id'),
      conv_value: 0,
      segment: undefined,
      kind: 'campaign',
    });
    goals.models.push(model);
  }

  function removeGoal(goal: GoalModel): void {
    if (!goal.isEphemeral) {
      goal.set({ is_archived: true });
    } else {
      goals.models.remove(goal);
      Goals.remove(goal.id);
    }
  }

  function changeTrackingMode(mode: 'advanced' | 'auto'): void {
    goals.models.forEach(removeGoal);
    if (mode === 'advanced') {
      const goal = Goals.create({
        parent: campaign.get('id'),
        conv_value: 0,
        segment: undefined,
        conv_type: 'custom',
      });
      goals.models.push(goal);
    }
    campaign.set({ conversion_tracking_mode: mode });
  }

  const hasMaxGoals = goals.models.length >= MAX_GOALS;

  function onModeChange(newValue?: string): void {
    if (newValue === 'advanced' || newValue === 'auto') {
      changeTrackingMode(newValue);
    }
  }

  const stats: IStats = campaign.get('total_stats');
  const advancedGoal =
    campaign.get('conversion_tracking_mode') === 'advanced'
      ? goals.models.find((goal) => !goal.get('is_archived'))
      : undefined;

  return (
    <LandingPageCampaignPage title={'Settings'}>
      <div className={styles.container}>
        <h4 className={styles.header}>Campaign Info</h4>
        <LandingPageCampaignInfo campaign={campaign} />
        <h4>Goals (optional)</h4>
        <Form description={'Configure the goals for this campaign.'} label={'Goals (optional)'}>
          <Fieldset className={styles.config}>
            <Radios
              label={'Conversion Tracking Mode'}
              onChange={onModeChange}
              options={[
                { id: 'auto', name: 'Goal' },
                { id: 'advanced', name: 'Advanced' },
              ]}
              value={campaign.get('conversion_tracking_mode')}
            />
            <Card contentClassName={styles.config}>
              {campaign.get('conversion_tracking_mode') === 'advanced' && advancedGoal && (
                <AdvancedConversionTrackingSettings goal={advancedGoal} />
              )}
              {campaign.get('conversion_tracking_mode') === 'auto' && (
                <>
                  {goals.isPending ? (
                    <Spinner />
                  ) : goals.models.filter((g) => !g.get('is_archived')).length > 0 ? (
                    <>
                      {goals.models
                        .filter((g) => !g.get('is_archived'))
                        .map((goal, index) => {
                          return (
                            <Goal
                              goal={goal}
                              goals={goals.models}
                              key={goal.id || index}
                              onRemove={removeGoal}
                              showWarning={!!stats.conversions && !!stats.conversions.full?.num}
                            />
                          );
                        })}
                    </>
                  ) : (
                    <p>No Goals. This campaign will not track conversions.</p>
                  )}
                  <Button
                    disabled={hasMaxGoals}
                    id={'addGoal'}
                    key={'add'}
                    onClick={addGoal}
                    tooltip={hasMaxGoals && t('Campaigns are limited to 5 goals.')}
                    type={goals.models.length >= 1 ? 'secondary' : 'primary'}
                  >
                    Add goal
                  </Button>
                </>
              )}
            </Card>
          </Fieldset>
        </Form>
      </div>
    </LandingPageCampaignPage>
  );
}

export default observer(SettingsLandingPageCampaignPage);
