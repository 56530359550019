import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import { toast } from '@feathr/components';
import { useUser } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

interface IUseReportActionsReturn {
  isCustomizeModalOpen: boolean;
  isLinksModalOpen: boolean;
  isExportModalOpen: boolean;
  handlers: {
    toggleCustomizeModalOpen: () => void;
    closeAndSaveReportConfig: () => Promise<void>;
    toggleLinksModalOpen: () => void;
    toggleExportModalOpen: () => void;
  };
}

function useReportActions(): IUseReportActionsReturn {
  const { t } = useTranslation();
  const user = useUser();

  const [isCustomizeModalOpen, toggleCustomizeModalOpen] = useToggle(false);
  const [isExportModalOpen, toggleExportModalOpen] = useToggle(false);
  const [isLinksModalOpen, toggleLinksModalOpen] = useToggle(false);

  async function saveReportsConfig(): Promise<void> {
    if (user?.isAttributeDirty('settings')) {
      await user.patch({ settings: user.attributes.settings });
      if (user.isErrored) {
        toast(t('Your report settings could not be saved.'), { type: ToastType.ERROR });
      } else {
        toast(t('Your report settings have been saved.'), { type: ToastType.SUCCESS });
      }
    }
  }

  async function closeAndSaveReportConfig(): Promise<void> {
    await saveReportsConfig();
    toggleCustomizeModalOpen();
  }

  return {
    isCustomizeModalOpen,
    isLinksModalOpen,
    isExportModalOpen,
    handlers: {
      toggleCustomizeModalOpen,
      toggleLinksModalOpen,
      toggleExportModalOpen,
      closeAndSaveReportConfig,
    },
  };
}

export default useReportActions;
