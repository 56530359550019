import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import type { VideoCreative } from '@feathr/blackbox';

import noImg from '@feathr/report_components/images/no-img.png';

interface IProps {
  className?: string;
  size: number;
  creative: VideoCreative;
}

function VideoCreativeThumbnail({ className, creative, size }: IProps) {
  useEffect(
    () =>
      autorun(() => {
        if (creative.get('url')) {
          const canvas = document.createElement('canvas');
          const video = document.createElement('video');
          video.addEventListener('canplaythrough', () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext('2d');
            const img = ref.current;
            if (ctx && img) {
              ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
              img.src = canvas.toDataURL('image/png');
            }
          });
          video.addEventListener('loadedmetadata', () => {
            video.currentTime = video.duration / 2;
          });
          video.crossOrigin = 'Anonymous';
          video.src = creative.get('url');
        }
      }),
    [],
  );
  const ref = React.useRef<HTMLImageElement>(null);
  return (
    <div className={className} style={{ width: `${size}px` }}>
      <img
        alt={''}
        ref={ref}
        src={noImg}
        style={{
          maxWidth: `${size}px`,
          maxHeight: `${size}px`,
        }}
      />
    </div>
  );
}

export default observer(VideoCreativeThumbnail);
