export default [
  {
    id: 'external_id',
    u_key: 'External ID',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'name',
    u_key: 'Name',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'first_name',
    u_key: 'First name',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'last_name',
    u_key: 'Last name',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'email',
    u_key: 'Primary email',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'income',
    u_key: 'Income',
    data_type: 'float',
    collection: 'Person',
  },
  {
    id: 'language',
    u_key: 'Language',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'occupation',
    u_key: 'Occupation',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'phone',
    u_key: 'Phone',
    data_type: 'str',
    collection: 'Person',
  },
  {
    id: 'address.administrative_area_name',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: State',
  },
  {
    id: 'address.country_name',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: Country',
  },
  {
    id: 'address.postal_code',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: Postal Code',
  },
  {
    id: 'address.locality',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: City',
  },
  {
    id: 'address.premise1',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: Line 1',
  },
  {
    id: 'address.premise2',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: Line 2',
  },
  {
    id: 'address.timezone',
    collection: 'Person',
    data_type: 'str',
    u_key: 'Address: Timezone',
  },
  {
    id: 'companies',
    u_key: 'Companies',
    data_type: 'list',
    collection: 'Person',
  },
  {
    id: 'pp_opt_outs.all',
    collection: 'Person',
    data_type: 'bool',
    u_key: 'Opt Out (Unsubscribed)',
    is_default: true,
  },
];
