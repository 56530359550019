import type { WithT } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { Button, ButtonValid, Form } from '@feathr/components';

import EmailCampaignSegments from '../EmailCampaignSegments';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
  onNext: () => void;
  onPrev: () => void;
  disabled: boolean;
}

interface IButtonProps extends WithT {
  onNext: () => void;
}

const NextStepButton = observer(({ onNext, t }: IButtonProps): JSX.Element => {
  return (
    <ButtonValid errors={[]} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function AutoPinpointEmailCampaignStepExclusions({
  campaign,
  disabled,
  onNext,
  onPrev,
}: IProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Form
        actions={[
          <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
            {t('Previous')}
          </Button>,
          <NextStepButton key={'next'} onNext={onNext} t={t} />,
        ]}
        description={t(
          'Optional: Choose or create an exclusion group(s). Any contact in the selected exclusion group(s) will not be sent this email, even if they perform the trigger.',
        )}
        label={t('Edit Campaign: Exclusions')}
        width={'wide'}
      >
        <EmailCampaignSegments
          campaign={campaign}
          disabled={disabled}
          hideIncluded={true}
          step={4}
          title={t('Group Exclusions')}
        />
      </Form>
    </>
  );
}

export default observer(AutoPinpointEmailCampaignStepExclusions);
