import type { JSX } from 'react';
import React from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';

import { useAccount } from '@feathr/extender/state';

function RedirectTo403(): JSX.Element {
  const { accountId } = useParams<{ accountId?: string }>();
  const location = useLocation();
  const account = useAccount();
  const redirectAccountId = accountId ?? account?.id;

  if (redirectAccountId) {
    return (
      <Redirect
        to={{ pathname: `/${redirectAccountId}/403-forbidden`, state: { from: location } }}
      />
    );
  }
  return <Redirect to={{ pathname: '/403-forbidden', state: { from: location } }} />;
}

export default RedirectTo403;
