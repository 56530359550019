import { faCaretDown, faCaretRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { TFunction } from 'i18next';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { CustomField } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';
import { fieldDataTypeIcon, fieldDataTypeLabel } from '@feathr/extender/styles/fieldDataType';

import FieldRowOptions from './FieldRowOptions';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './FieldColumns.css';

function FieldColumns(t: TFunction): Array<IColumn<CustomField>> {
  return [
    {
      id: 'values',
      Header: TableColumnHeader({
        title: t('Values'),
      }),
      sortable: false,
      expander: true,
      className: classNames(tableStyles.cellCenter, styles.expander),
      width: 70,
      Expander: ({ isExpanded }) => (
        <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretRight} />
      ),
    },
    {
      id: 'data_type',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Type'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      width: 100,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              const dataType = original.get('data_type');
              const icon = fieldDataTypeIcon(dataType);
              return (
                <>
                  {icon && <FontAwesomeIcon icon={icon} />}
                  <span className={styles.dataTypeLabel}>{fieldDataTypeLabel(dataType, t)}</span>
                </>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'u_key',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Name'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              const uKey = original.get('u_key');
              return uKey ? <>{uKey}</> : <>{t('(Empty)')}</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'description',
      Header: TableColumnHeader({
        title: t('Description'),
      }),
      sortable: false,
      className: tableStyles.cell,
      Cell: ({ original }): JSX.Element => {
        return (
          <Observer>
            {(): JSX.Element => {
              return <>{original.get('description') || ''}</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'options',
      Header: TableColumnHeader({
        title: t('Options'),
      }),
      className: tableStyles.cellCenter,
      width: 80,
      sortable: false,
      Cell({ original }): JSX.Element {
        return <FieldRowOptions original={original} />;
      },
    },
  ];
}

export default FieldColumns;
