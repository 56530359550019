import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { FacebookCampaign, FacebookDisplayCreative, IFacebookPage } from '@feathr/blackbox';
import { FacebookImageCreative } from '@feathr/blackbox';
import { Postscribe, Spinner } from '@feathr/components';

import InstagramAdPreview from './InstagramAdPreview';
import SelectAdPreviewPlatform from './SelectAdPreviewPlatform';

import * as styles from './FacebookAdPreview.css';

enum EMetaPlatformAdFormat {
  DesktopFeedStandard = 'desktop_feed_standard',
  InstagramStandard = 'instagram_standard',
}

export enum EMetaPlatform {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
}

export interface IMetaPlatform {
  adFormat: string;
  id: EMetaPlatform;
  isDisabled?: boolean;
  name: string;
  tooltip?: string;
}

interface IFacebookProps {
  campaign: FacebookCampaign;
  creative: FacebookDisplayCreative;
  page: IFacebookPage;
  testClickURL: string;
}

export function FacebookAdPreview({
  creative,
  campaign,
  page,
  testClickURL,
}: IFacebookProps): JSX.Element {
  const { t } = useTranslation();
  const metaPlatforms: IMetaPlatform[] = [
    {
      adFormat: EMetaPlatformAdFormat.DesktopFeedStandard,
      id: EMetaPlatform.Facebook,
      name: t('Facebook'),
    },
    {
      adFormat: EMetaPlatformAdFormat.InstagramStandard,
      id: EMetaPlatform.Instagram,
      name: t('Instagram'),
    },
  ];

  const [previewHtml, setPreviewHtml] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [platform, setPlatform] = useState<IMetaPlatform>(
    () => metaPlatforms.find((p) => p.id === EMetaPlatform.Facebook)!,
  );

  const instagramOption = metaPlatforms.find((p) => p.id === EMetaPlatform.Instagram)!;
  if (!campaign.get('instagram_actor_id')) {
    instagramOption.isDisabled = true;
    instagramOption.tooltip = t('You must connect an Instagram account to preview this platform.');
  } else {
    const instagramOption = metaPlatforms.find((p) => p.id === EMetaPlatform.Instagram)!;
    instagramOption.isDisabled = false;
    instagramOption.tooltip = undefined;
  }

  return (
    <div>
      <SelectAdPreviewPlatform
        campaign={campaign}
        creative={creative}
        metaPlatforms={metaPlatforms}
        page={page}
        platform={platform}
        setIsLoading={setIsLoading}
        setPlatform={setPlatform}
        setPreviewHtml={setPreviewHtml}
      />
      <div className={styles.card}>
        {isLoading ? (
          <Spinner />
        ) : (
          <a href={testClickURL} target={'_blank'}>
            {creative instanceof FacebookImageCreative && <div className={styles.fbPreview} />}
            {platform.id === EMetaPlatform.Facebook && (
              /* The postscribe component requires a "style" prop. The issue to remove that prop is here: https://github.com/Feathr/shrike/issues/1589 */
              <Postscribe html={previewHtml} style={{ transform: 'none' }} />
            )}
            {platform.id === EMetaPlatform.Instagram && (
              <InstagramAdPreview creative={creative} page={page} />
            )}
          </a>
        )}
      </div>
    </div>
  );
}

export default FacebookAdPreview;
