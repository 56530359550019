import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { EmailPreview, TLDR } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

interface IProps {
  /** Displaying emails is only supported on pinpoint campaigns */
  readonly campaign?: PinpointEmailBaseCampaign;
}

function TemplateTLDR({ campaign }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { Creatives } = useContext(StoresContext);
  const creatives = campaign?.isCreativesCampaign
    ? Creatives.list({ filters: { _parent: campaign.id }, pagination: { page_size: 1 } })
    : undefined;

  if (campaign === undefined || campaign.isPending || creatives?.isPending) {
    return <TLDR isLoading={true} />;
  }

  const previewUrl = campaign.getTemplatePreviewUrl();
  const previewTitle = t('{{title}} Preview', { title: campaign.get('subject') });

  return (
    <TLDR
      image={<EmailPreview title={previewTitle} url={previewUrl} />}
      isLoading={false}
      title={campaign.name}
    />
  );
}

export default observer(TemplateTLDR);
