import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { ImisIntegration } from '@feathr/blackbox';

import ImisDisconnectControl from './ImisDisconnectControl';
import ImisPauseControl from './ImisPauseControl';
import ImisResumeControl from './ImisResumeControl';
import ImisSyncControl from './ImisSyncControl';

interface IImisIntegrationsControls {
  integration?: ImisIntegration;
}

function ImisIntegrationControls({ integration }: IImisIntegrationsControls): JSX.Element | null {
  if (!integration) {
    // If there is no integration, we don't need to show the controls
    return null;
  }

  return (
    <>
      {integration.isConnected && !integration.isDownSyncing && (
        <>
          {/* Show sync control */}
          <ImisSyncControl integration={integration} />
        </>
      )}
      {integration.isConnected && (
        <>
          {/* Show pause control */}
          <ImisPauseControl integration={integration} />
          {/* Show disconnect control */}
          <ImisDisconnectControl integration={integration} />
        </>
      )}
      {integration.isPaused && (
        <>
          {/* Show resume control */}
          <ImisResumeControl integration={integration} />
          {/* Show disconnect control */}
          <ImisDisconnectControl integration={integration} />
        </>
      )}
    </>
  );
}

export default observer(ImisIntegrationControls);
