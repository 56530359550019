import { observer } from 'mobx-react-lite';
import React from 'react';

import type { Template } from '@feathr/blackbox';
import { NumberInput } from '@feathr/components';

interface IProps {
  template: Template;
}

function BannerTemplateMetadataInputs({ template }: IProps) {
  return (
    <>
      <NumberInput
        attribute={'width'}
        disabled={template.get('bannersnack_enabled')}
        helpText={'The width of the banner ad in pixels.'}
        label={'Width'}
        model={template}
      />
      <NumberInput
        attribute={'height'}
        disabled={template.get('bannersnack_enabled')}
        helpText={'The height of the banner ad in pixels.'}
        label={'Height'}
        model={template}
      />
    </>
  );
}

export default observer(BannerTemplateMetadataInputs);
