import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { BannersnackCreative, IBannersnackUrl } from '@feathr/blackbox';
import { CreativeClass } from '@feathr/blackbox';
import { MenuItem } from '@feathr/components';

interface IDownloadBannersnackFileMenuItemProps {
  creative: BannersnackCreative;
  type: IBannersnackUrl['type'];
}

function DownloadBannersnackFileMenuItem({
  creative,
  type,
}: IDownloadBannersnackFileMenuItemProps) {
  const { t } = useTranslation();

  const creativeType = creative.get('_cls');
  if (creativeType !== CreativeClass.DisplayBannersnack) {
    return null;
  }

  const urls =
    creativeType === CreativeClass.DisplayBannersnack
      ? creative.get('banner_urls')
      : observable([] as IBannersnackUrl[]);
  const { url } = urls.find((u) => u.type === type) || {};

  const fileType = type === 'animated-gif' ? 'GIF' : type.toUpperCase();

  return (
    <MenuItem disabled={creative.isUpdating || !url} href={url} target={'_blank'}>
      {t('Download {{file_type}}', { file_type: fileType })}
    </MenuItem>
  );
}

export default observer(DownloadBannersnackFileMenuItem);
