import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';

import type { IUrlClicks, PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import type { IColumn, ISorted } from '@feathr/components';
import { Table, TableColumnHeader, TableStatsCard } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
}

interface IRow {
  original: IUrlClicks;
}

const TrafficColumns: Array<IColumn<IUrlClicks>> = [
  {
    id: 'url',
    Header: TableColumnHeader({
      sortType: 'alpha',
      title: 'URL',
    }),
    Cell({ original }): JSX.Element {
      return original.url === 'unknown' ? (
        <>{original.url}</>
      ) : (
        <a href={original.url} target={'_blank'}>
          {original.url}
        </a>
      );
    },
  },
  {
    id: 'clicks',
    Header: TableColumnHeader({
      sortType: 'numeric',
      title: 'Clicks',
    }),
    width: 100,
    className: tableStyles.cellRight,
    Cell({ original }: IRow): JSX.Element {
      return <>{original.clicks}</>;
    },
  },
];

function TrafficBreakdownCard({ campaign }: IProps): JSX.Element {
  const [sort, setSort] = useState<ISorted[]>([{ id: 'clicks', desc: true }]);
  let tableData: IUrlClicks[] = Object.values(campaign.get('total_stats')?.clicks_by_url || {});

  sort.forEach((s) => {
    if (s.id === 'url') {
      // Cannot sort observable in place, so make a copy using slice().
      tableData = tableData
        .slice()
        .sort((a, b) =>
          s.desc ? b['url'].localeCompare(a['url']) : a['url'].localeCompare(b['url']),
        );
    } else if (s.id === 'clicks') {
      // Cannot sort observable in place, so make a copy using slice().
      tableData = tableData
        .slice()
        .sort((a, b) => (s.desc ? b['clicks'] - a['clicks'] : a['clicks'] - b['clicks']));
    }
  });

  return (
    <TableStatsCard title={reportModuleLabels.includeLinkClicksTable}>
      <Table
        columns={TrafficColumns}
        idKey={'url'}
        items={tableData}
        onSortChange={setSort}
        sort={sort}
      />
    </TableStatsCard>
  );
}

export default observer(TrafficBreakdownCard);
