import { when } from 'mobx';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type { IDailyStats } from '@feathr/blackbox';
import type { TTimeFrameValue } from '@feathr/components';
import { useId } from '@feathr/hooks';

import { StoresContext } from '../state';

interface IUseDashboardStats {
  timeFrameValue: TTimeFrameValue;
}
interface IUseDashboardStatsReturn {
  isLoading: boolean;
  chartData: IDailyStats[];
}

function useDashboardStats({ timeFrameValue }: IUseDashboardStats): IUseDashboardStatsReturn {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<IDailyStats[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);

  const { Stats } = useContext(StoresContext);
  const listId = useId({ ephemeral: true });

  const initStats = useMemo(() => {
    const filters = {
      metadata__date__gte: timeFrameValue[0],
      metadata__date__lte: timeFrameValue[1],
    };

    return Stats.list(
      {
        model: 'account',
        filters,
      },
      {},
      listId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Stats, timeFrameValue]);

  const fetchStats = useCallback(async () => {
    const stats = initStats;
    await when(() => !stats.isPending);

    // Set the page and total pages
    setPage(stats.pagination.page);
    setTotalPages(stats.pagination.pages);

    // Set the chart data
    setChartData(stats.models.map((s) => s.toJS()));
    setIsLoading(false);
  }, [initStats]);

  const fetchMoreStats = useCallback(async () => {
    const stats = initStats;

    // Fetch more stats
    stats.fetchMore();
    await when(() => !stats.isPending);

    // Update the chart data
    setChartData(stats.models.map((s) => s.toJS()));

    // Update the page and total pages
    setPage(stats.pagination.page);
    setTotalPages(stats.pagination.pages);
  }, [initStats]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  useEffect(() => {
    if (page < totalPages) {
      fetchMoreStats();
    }
  }, [fetchMoreStats, page, totalPages]);

  return {
    isLoading,
    chartData,
  };
}

export default useDashboardStats;
