import { observer } from 'mobx-react-lite';
import React from 'react';

import type { Event } from '@feathr/blackbox';
import { Checkbox, Fieldset, Spinner } from '@feathr/components';

interface IProps {
  event: Event;
}

function PartnerDashboardDataAccess({ event }: IProps) {
  return (
    <>
      <Fieldset label={'Data & Privacy'}>
        {event.isPending ? (
          <Spinner />
        ) : (
          <Checkbox
            helpText={
              'If checked, Partners will be asked before uploading contact lists for sending Invite Emails through their Partner Dashboard whether they would like to share contact info from uploaded contact lists with you, the event organizer, for marketing purposes. If they opt-in to this, customer data from uploaded contact lists will become available to you in your Data section. Feathr does not share uploaded contact list data with any other parties for any purpose and will only share data between your Partners and you if they explicitly choose to allow it.'
            }
            label={'Ask Partners to share uploaded contact lists'}
            onChange={() => {
              event.set({
                data_sharing: event.get('data_sharing') === 'request' ? 'no_request' : 'request',
              });
            }}
            value={event.get('data_sharing') === 'request'}
          />
        )}
      </Fieldset>
    </>
  );
}

export default observer(PartnerDashboardDataAccess);
