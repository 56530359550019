import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { PinpointPartnerMessage, Template } from '@feathr/blackbox';
import type { ButtonType } from '@feathr/components';
import { ButtonValid } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';
import type { IValidation, TValidateGrouped } from '@feathr/rachis';

interface IProps {
  children?: React.ReactNode;
  message: PinpointPartnerMessage;
  template?: Template;
  onNext: () => void;
  name?: string;
  type?: ButtonType;
  validate: (message: PinpointPartnerMessage, template?: Template) => TValidateGrouped;
}

export function validateStepOne(message: PinpointPartnerMessage): IValidation<TValidateGrouped> {
  return message.validate(['name'], false, 'grouped');
}

function ButtonNext({
  children = 'Next',
  message,
  template,
  onNext,
  name,
  type = 'primary',
  validate,
}: IProps): JSX.Element {
  const errors = flattenErrors(validate(message, template));
  return (
    <ButtonValid errors={errors} name={name} onClick={onNext} type={type}>
      {children}
    </ButtonValid>
  );
}

export default observer(ButtonNext);
