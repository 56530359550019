import { faFeather } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Chip } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

interface IProps {
  id: string;
  linkToItem?: boolean;
}

function FlightChip({ id, linkToItem = true }: IProps) {
  const { Flights } = useContext(StoresContext);
  const localUrl = useLocalUrl();

  const flight = Flights.get(id);
  if (flight.isErrored) {
    return null;
  }

  if (flight.isPending) {
    return <Chip isLoading={true} />;
  }

  const content = (
    <Chip prefix={<FontAwesomeIcon icon={faFeather} />}>
      {flight.name}
      {flight.get('is_archived') ? ' (Archived)' : ''}
    </Chip>
  );

  if (linkToItem) {
    return <Link to={localUrl(flight.getItemUrl())}>{content}</Link>;
  }
  return content;
}

export default observer(FlightChip);
