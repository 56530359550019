import { faHandshakeAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import type { RowRenderProps } from 'react-table';

import { Chip } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

interface IProps extends RowRenderProps {
  id: string;
  linkToItem?: boolean;
}

function PartnerChip({ id, linkToItem = true }: IProps) {
  const { Partners } = useContext(StoresContext);
  const localUrl = useLocalUrl();

  const partner = Partners.get(id);
  if (partner.isErrored) {
    return null;
  }

  if (partner.isPending) {
    return <Chip isLoading={true} />;
  }

  const content = (
    <Chip prefix={<FontAwesomeIcon icon={faHandshakeAlt} />}>
      {partner.name}
      {partner.get('is_archived') ? ' (Archived)' : ''}
    </Chip>
  );

  if (linkToItem) {
    return <Link to={localUrl(partner.getItemUrl())}>{content}</Link>;
  }
  return content;
}

export default observer(PartnerChip);
