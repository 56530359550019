import { Observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { RowRenderProps } from 'react-table';

import type { Goal } from '@feathr/blackbox';
import { Table, TableColumnHeader } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

interface IRow extends RowRenderProps {
  original: Goal;
}

interface IProps {
  segment: Goal;
}

function ConversionPixelUsageTable({ segment }: IProps) {
  const { Campaigns, Flights, Goals } = useContext(StoresContext);
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  return (
    <Table<Goal>
      collection={Goals}
      columns={[
        {
          id: 'name',
          Header: TableColumnHeader({
            title: t('Name'),
          }),
          sortable: false,
          Cell: ({ original }: IRow) => (
            <Observer>
              {() => {
                const campaign =
                  original.get('kind') === 'campaign'
                    ? Campaigns.get(original.get('parent'))
                    : undefined;
                const flight =
                  original.get('kind') === 'flight'
                    ? Flights.get(original.get('parent'))
                    : undefined;
                if (campaign && !campaign.isPending) {
                  return <Link to={localUrl(campaign.getItemUrl())}>{campaign.name}</Link>;
                }
                if (flight && !flight.isPending) {
                  return <Link to={localUrl(flight.getItemUrl())}>{flight.get('name')}</Link>;
                }
                return <>-</>;
              }}
            </Observer>
          ),
        },
        {
          id: 'kind',
          Header: TableColumnHeader({
            title: t('Type'),
          }),
          sortable: true,
          width: 150,
          Cell: ({ original }: IRow) => <>{original.get('kind')}</>,
        },
      ]}
      filters={{
        is_archived__ne: true,
        segment: segment.id,
      }}
      noDataText={t('This conversion pixel is not used in any campaigns or flights.')}
    />
  );
}

function ConversionPixelUsage({ original }: IRow) {
  return <Observer>{() => <ConversionPixelUsageTable segment={original} />}</Observer>;
}

export default ConversionPixelUsage;
