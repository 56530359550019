import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import type { OptionProps, SingleValueProps } from 'react-select';
import { components } from 'react-select';

import type { ICampaignAttributes } from '@feathr/blackbox';
import type { Campaign } from '@feathr/blackbox';
import { campaignColorMap, campaignIconMap } from '@feathr/extender/styles/campaign';
import { cssVar } from '@feathr/hooks';

import iconStyles from '@feathr/extender/components/CampaignIcons/CampaignIcons.css';
import styles from './SelectOptions.css';

import noImg from 'images/no-img.png';

function CampaignIcon(props: { campaign: ICampaignAttributes; size?: number }) {
  const icon = campaignIconMap.get(props.campaign._cls);
  if (!icon) {
    return <div />;
  }

  const backgroundColor = campaignColorMap.get(props.campaign._cls);

  return (
    <div
      className={iconStyles.root}
      style={{
        ['--l-icon-background-color' as string]: backgroundColor
          ? cssVar(`--color-${backgroundColor}-500`)
          : null,
        ['--l-icon-size' as string]: `${props.size || 24}px`,
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  );
}

export function CampaignIconOption<T extends ICampaignAttributes>(props: OptionProps<T>) {
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex' }}>
        <div className={styles.contentWrapper}>
          <CampaignIcon campaign={props.data as ICampaignAttributes} />
        </div>
        <div className={styles.contentWrapper}>
          <span>{props.data.name}</span>
        </div>
      </div>
    </components.Option>
  );
}

export function CampaignModelIconOption<T extends Campaign>(props: OptionProps<T>) {
  const campaign = props.data.toJS();
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex' }}>
        <div className={styles.contentWrapper}>
          <CampaignIcon campaign={campaign} />
        </div>
        <div className={styles.contentWrapper}>
          <span>{campaign.name}</span>
        </div>
      </div>
    </components.Option>
  );
}

export function CampaignOption<T extends ICampaignAttributes>(props: OptionProps<T>) {
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex' }}>
        <div className={styles.contentWrapper}>
          <img
            alt={''}
            src={`${props.data.template.thumbnail_url || noImg}`}
            style={{ width: '100px' }}
          />
        </div>
        <div className={styles.contentWrapper}>
          <span>{props.data.name}</span>
        </div>
      </div>
    </components.Option>
  );
}

export function CampaignSingleValue(props: SingleValueProps<Campaign>) {
  const campaign = props.data.toJS();

  return (
    <components.SingleValue {...(props as any)}>
      <div style={{ display: 'flex' }}>
        <div className={styles.contentWrapper}>
          <CampaignIcon campaign={campaign} />
        </div>
        <div className={styles.contentWrapper}>
          <span>{campaign.name}</span>
        </div>
      </div>
    </components.SingleValue>
  );
}
