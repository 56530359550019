import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { DataRequest } from '@feathr/blackbox';
import { FormSummaryItem } from '@feathr/components';

import * as styles from './RequestFieldSummary.css';

interface IProps {
  dataRequest: DataRequest;
}

function RequestFieldSummary({ dataRequest }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const request_fields = dataRequest.get('request_fields');
  return (
    <FormSummaryItem
      label={t('Request fields')}
      value={request_fields.map((requestField, i) => {
        return (
          <div className={styles.container} key={requestField.id || i}>
            <span>{requestField.field_name}</span>
            <FontAwesomeIcon icon={faArrowRight} />
            <span>{requestField.feathr_attr}</span>
          </div>
        );
      })}
    />
  );
}

export default observer(RequestFieldSummary);
