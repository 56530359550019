import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { IPreviewRow } from '@feathr/blackbox';
import { Alert, AlertType, AvatarV2 as Avatar, Card, Spinner } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import PersonPreview from '../../DataPage/PersonPage/PersonPreview';

import * as styles from './PreviewCard.css';

interface IProps {
  row: IPreviewRow;
  tagIds: string[];
}

function PreviewCard({ row, tagIds }: IProps): JSX.Element {
  const { result, error } = row;
  const { Persons } = useContext(StoresContext);
  const person = result ? Persons.create(result) : null;
  const { t } = useTranslation();

  if (person?.isPending) {
    return <Spinner />;
  }

  return (
    <Card
      className={styles.root}
      title={
        person ? (
          <>
            <Avatar name={person.get('name')} placeholder={person.get('placeholder')} />
            <div>{person.get('name')}</div>
          </>
        ) : (
          t('This person cannot be imported.')
        )
      }
    >
      {person && <PersonPreview person={person} tagIds={tagIds} />}
      {error && <Alert type={AlertType.danger}>{error}</Alert>}
    </Card>
  );
}

export default observer(PreviewCard);
