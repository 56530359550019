import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ICampaignAttributes, IMonetizationAttributes } from '@feathr/blackbox';
import { ButtonValid } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';

import type { IStepValueBaseProps } from './StepValue';
import { validateStepValue } from './StepValue';

function NextStepButton<A extends ICampaignAttributes & IMonetizationAttributes>({
  campaign,
  onNext,
}: IStepValueBaseProps<A>): JSX.Element {
  const { t } = useTranslation();
  const validationErrors = validateStepValue(campaign);
  return (
    <ButtonValid errors={flattenErrors(validationErrors)} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
}

export default observer(NextStepButton);
