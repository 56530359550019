import { faArrowRight, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { set } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { FieldDataType, IImportColumn, Importer } from '@feathr/blackbox';
import { defaultPersonAttributes, FieldCollection } from '@feathr/blackbox';
import { Button, DebouncedInput, Fieldset, Input, Select, Value } from '@feathr/components';
import CustomFieldSelect from '@feathr/extender/components/CustomFieldSelect';
import DataTypeSelect from '@feathr/extender/components/DataTypeSelect';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './DataImportPage.css';

interface IImportConfigProps {
  disabled?: boolean;
  column: IImportColumn;
  importer: Importer;
  index: number;
}

function DataImportColumnConfig({
  column,
  importer,
  disabled = false,
  index,
}: IImportConfigProps): JSX.Element {
  const { CustomFields } = useContext(StoresContext);
  const { t } = useTranslation();

  useEffect(() => {
    const columnName = column.column_name.toString().trim().toLowerCase();
    defaultPersonAttributes.forEach((attr) => {
      if (attr.id === columnName && !column.feathr_attr) {
        runInAction(() => {
          set(column, { feathr_attr: attr.id, attr_type: attr.data_type });
        });
      }
    });
    importer.setAttributeDirty('column_mappers');
  }, [column, importer]);

  const columnOptions = (importer.get('column_names') as string[]).map((c) => ({
    id: c,
    name: c,
  }));
  const columns: IObservableArray<IImportColumn> = importer.get('column_mappers');

  function deleteColumn(): void {
    columns.remove(column);
    importer.setAttributeDirty('column_mappers');
  }

  const isFieldSelected = !!column.feathr_attr;
  const filteredAttributes = importer.isOptOut
    ? defaultPersonAttributes.filter((attr) => attr.id !== 'pp_opt_outs.all')
    : defaultPersonAttributes;
  // Add extra safeguard in case the user has a column titled 'Opt Out'
  const isDefaultField =
    isFieldSelected && filteredAttributes.some((attr) => attr.id === column.feathr_attr);
  const customField =
    isFieldSelected && !isDefaultField ? CustomFields.get(column.feathr_attr!) : undefined;

  function onSelectColumn(option: { id: string; name: string }): void {
    set(column, 'column_name', option.id);
    importer.setAttributeDirty('column_mappers');
  }

  function handleChangeDataType(newValue?: FieldDataType): void {
    if (isFieldSelected && !isDefaultField && customField && customField.isEphemeral) {
      customField.set({ data_type: newValue });
    }
    set(column, { attr_type: newValue });
    importer.setAttributeDirty('column_mappers');
  }

  function handleChangeCustomField(newValue?: string | undefined): void {
    if (isFieldSelected && !isDefaultField && customField && customField.isEphemeral) {
      customField?.set({ u_key: newValue ?? '' });
      set(column, { u_key: newValue ?? '' });
      importer.setAttributeDirty('column_mappers');
    }
  }

  return (
    <Fieldset direction={'column'}>
      <Value value={`${index + 1}.`} wrapperClassName={styles.ordinal} />
      <Select
        defaultValue={columnOptions.find((opt) => opt.id === column.column_name)}
        disabled={disabled}
        onSelectSingle={onSelectColumn}
        options={columnOptions}
      />
      <FontAwesomeIcon className={styles.arrow} icon={faArrowRight} />
      {isFieldSelected && !isDefaultField && customField && customField.isEphemeral ? (
        <DebouncedInput<string> defaultValue={''} onChange={handleChangeCustomField}>
          {(liveValue, onChangeLiveValue): JSX.Element => (
            <Input
              onChange={onChangeLiveValue}
              placeholder={t('Enter custom field name...')}
              type={'text'}
              value={liveValue}
            />
          )}
        </DebouncedInput>
      ) : (
        <CustomFieldSelect
          contexts={[FieldCollection.Person]}
          customField={customField}
          disabled={disabled}
          isOptOut={importer.isOptOut}
          object={column}
        />
      )}
      <DataTypeSelect
        disabled={
          disabled ||
          !isFieldSelected ||
          isDefaultField ||
          (customField && !customField.isEphemeral)
        }
        id={'dataTypeSelect'}
        onChange={handleChangeDataType}
        placeholder={t('Select type...')}
        value={column.attr_type}
      />
      <Button
        className={styles.trash}
        disabled={disabled}
        onClick={deleteColumn}
        type={'icon-outlined'}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </Fieldset>
  );
}

export default observer(DataImportColumnConfig);
