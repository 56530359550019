import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faDesktop,
  faMobile,
  faQuestionCircle,
  faTablet,
} from '@fortawesome/pro-regular-svg-icons';
import type { TFunction } from 'i18next';

interface IGetLabelAndIcon {
  screenWidth?: number;
  platform?: string;
  t: TFunction;
}

export function getDeviceLabelAndIcon({ screenWidth, platform, t }: IGetLabelAndIcon): {
  label: string;
  icon: IconDefinition;
} {
  let label = t('Desktop');
  let icon = faDesktop;
  if (!screenWidth) {
    label = t('Unknown');
    icon = faQuestionCircle;
  } else if (platform && ['android', 'iphone', 'ipad'].includes(platform)) {
    if (screenWidth > 480) {
      label = t('Tablet');
      icon = faTablet;
    } else {
      label = t('Mobile');
      icon = faMobile;
    }
  } else if (platform && ['macos', 'linux'].includes(platform)) {
    label = t('Desktop');
    icon = faDesktop;
  } else if (screenWidth <= 768 && screenWidth > 480) {
    label = t('Tablet');
    icon = faTablet;
  } else if (screenWidth <= 480) {
    label = t('Mobile');
    icon = faMobile;
  }

  return { label, icon };
}
