import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';

import { templateTypeIcon } from '@feathr/extender/styles/template';

import type { ITemplateTypeSelectOption } from './TemplateTypeSelect';

function TemplateTypeSelectLabel({ id, name }: ITemplateTypeSelectOption): JSX.Element {
  return (
    <>
      <FontAwesomeIcon icon={templateTypeIcon(id)} /> {name}
    </>
  );
}

export default TemplateTypeSelectLabel;
