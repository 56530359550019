import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign, IExposureSettings } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const BudgetColumn: IColumn<BaseCampaign> = {
  id: 'budget',
  checkboxLabel: 'Budget',
  Header: TableColumnHeader({
    title: 'Budget',
  }),
  sortable: false,
  width: 80,
  className: tableStyles.cellRight,
  Cell({ original }) {
    return (
      <Observer>
        {(): JSX.Element => {
          const type = original.get('_cls');
          if (
            [
              CampaignClass.Referral,
              CampaignClass.TrackedLink,
              CampaignClass.LandingPage,
              CampaignClass.Conversation,
            ].includes(type)
          ) {
            return <>-</>;
          }
          const exposureSettings: IExposureSettings = original.get('exposure_settings');

          const targetValue = exposureSettings ? exposureSettings.target_value || 0 : 0;
          const targetType = exposureSettings
            ? exposureSettings.target_type || 'fixed_budget'
            : 'fixed_budget';
          return (
            <>{targetType === 'fixed_budget' ? numeral(targetValue).format('$0,0.00') : '-'}</>
          );
        }}
      </Observer>
    );
  },
  Footer: ({ data }) => {
    return (
      <Observer>
        {(): JSX.Element => {
          const total = data.reduce((subtotal, currentValue) => {
            const type = currentValue._original.get('_cls');
            if (
              [
                CampaignClass.Referral,
                CampaignClass.TrackedLink,
                CampaignClass.LandingPage,
                CampaignClass.Conversation,
              ].includes(type)
            ) {
              return subtotal;
            }
            const exposureSettings: IExposureSettings =
              currentValue._original.get('exposure_settings');
            const targetValue = exposureSettings ? exposureSettings.target_value || 0 : 0;
            const targetType = exposureSettings
              ? exposureSettings.target_type || 'fixed_budget'
              : 'fixed_budget';
            if (targetType === 'fixed_budget') {
              return subtotal + targetValue;
            }
            return subtotal;
          }, 0);
          return <>{numeral(total).format('$0,0.00')}</>;
        }}
      </Observer>
    );
  },
};

export default BudgetColumn;
