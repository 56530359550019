import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@feathr/components';
import { roleTypeChipColorMap, roleTypeMap } from '@feathr/extender/styles/user_roles';

interface IRoleTypeChipProps {
  roleId: string;
}

function RoleTypeChip({ roleId }: Readonly<IRoleTypeChipProps>): JSX.Element {
  const { t } = useTranslation();

  const theme = roleTypeChipColorMap(roleId);
  const roleType = roleTypeMap(roleId, t);

  return <Chip theme={theme}>{roleType}</Chip>;
}

export default RoleTypeChip;
