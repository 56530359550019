import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { IFacebook } from '@feathr/blackbox';
import {
  CampaignClass,
  CampaignState,
  FacebookAccessTokenHealth as TokenHealth,
  FacebookIntegrationState,
} from '@feathr/blackbox';
import { Alert, AlertType, Button, Card, Checkbox, Chip, Label, Tooltip } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { StoresContext, useAccount, useUser } from '@feathr/extender/state';
import { cssVar, moment, TimeFormat } from '@feathr/hooks';

import FacebookConnectButton from './FacebookConnectButton';
import FacebookDisconnectButton from './FacebookDisconnectButton';
import FacebookPagesTable from './FacebookPagesTable';

import * as styles from './FacebookIntegrationPage.css';

interface IStateMap {
  color: string;
  icon: IconProp;
  helpText: string;
}

function FacebookIntegrationPage(): JSX.Element {
  const account = useAccount();
  const user = useUser();

  const isAdmin = user.isSudoer || user.isAdmin;
  const { Campaigns } = useContext(StoresContext);
  const campaigns = Campaigns.list({
    filters: {
      _cls__in: [CampaignClass.Facebook, CampaignClass.EmailListFacebook],
      state__in: [CampaignState.Publishing, CampaignState.Published],
      date_end__gte: moment.utc().format(TimeFormat.isoDate),
    },
  });
  const [agree, setAgree] = useState(false);
  const { t } = useTranslation();

  let isAuthenticated = false;

  useEffect(() => {
    if (!account.isPending) {
      setAgree(isAuthenticated);
    }
  }, [account.isPending, isAuthenticated]);

  const integration = account.get('facebook', {
    state: FacebookIntegrationState.not_connected,
  } as IFacebook);
  const state = integration.state;
  const facebookEmailMappingTOSAccepted =
    account.get('facebook')?.tos_accepted?.custom_audience_tos;
  const userName = integration.username;
  const dateTokenExpiration =
    integration && integration.date_token_expiration
      ? moment.utc(integration.date_token_expiration, moment.ISO_8601)
      : undefined;
  const accessTokenHealthy =
    !!dateTokenExpiration &&
    integration.access_token_health === TokenHealth.ok &&
    dateTokenExpiration.isAfter(moment.utc());
  isAuthenticated = accessTokenHealthy && !!integration.user_id && !!integration.username;
  const formattedTokenExpirationDate = dateTokenExpiration
    ? dateTokenExpiration.format('MMMM Do, YYYY')
    : undefined;
  const dateTokenExpiresFromNow = dateTokenExpiration
    ? dateTokenExpiration.fromNow(true)
    : undefined;

  const warningText = t(
    'Your integration is under review. This process can take up to 72 hours to complete. Contact support if your integration is not complete after 72 hours.',
  );

  const loginButtonText = !isAdmin
    ? t('Only Feathr admin users can integrate with Meta')
    : t('You must allow ads management and page management to log in');

  const stateMap = new Map<string, IStateMap>([
    [
      FacebookIntegrationState.no_page_selected,
      {
        color: 'default',
        icon: faTimesCircle,
        helpText: t('Select a page below to get started.'),
      },
    ],
    [
      FacebookIntegrationState.access_token_pending,
      {
        color: 'yellow',
        icon: faInfoCircle,
        helpText: warningText,
      },
    ],
    [
      FacebookIntegrationState.payment_method_pending,
      {
        color: 'yellow',
        icon: faInfoCircle,
        helpText: warningText,
      },
    ],
    [
      FacebookIntegrationState.ad_account_pending,
      {
        color: 'yellow',
        icon: faInfoCircle,
        helpText: warningText,
      },
    ],
    [
      FacebookIntegrationState.page_selected,
      {
        color: 'sky',
        icon: faCheckCircle,
        helpText: t('Ready to launch ad campaigns on Meta platforms!'),
      },
    ],
    [
      FacebookIntegrationState.failed,
      {
        color: 'red',
        icon: faTimesCircle,
        helpText: t(
          'Something went wrong and your integration could not be completed. Please contact customer support.',
        ),
      },
    ],
  ]);

  const stateTranslationMap: Record<FacebookIntegrationState, string> = {
    [FacebookIntegrationState.no_page_selected]: t('No Page Selected'),
    [FacebookIntegrationState.not_connected]: t('Not Connected'),
    [FacebookIntegrationState.access_token_pending]: t('Access Token Pending'),
    [FacebookIntegrationState.payment_method_pending]: t('Payment Method Pending'),
    [FacebookIntegrationState.ad_account_pending]: t('Ad Account Pending'),
    [FacebookIntegrationState.page_selected]: t('Page Selected'),
    [FacebookIntegrationState.failed]: t('Failed'),
  };

  function handleAgree(): void {
    setAgree(!agree);
  }

  return (
    <Page loading={account.isPending} title={t('Meta Integration')}>
      <div className={styles.page}>
        {isAuthenticated && !!dateTokenExpiration ? (
          <>
            <Card className={styles.card}>
              <div className={styles.connection}>
                <FontAwesomeIcon
                  color={cssVar('--color-icon-facebook')}
                  icon={faFacebook}
                  size={'3x'}
                />
                <div className={styles.session}>
                  <div>
                    <Label>{t('Login')}</Label> {userName}
                  </div>
                  <div>
                    <Label>{t('Expires')}</Label>{' '}
                    <Tooltip key={'token-expiration'} title={formattedTokenExpirationDate}>
                      {dateTokenExpiresFromNow}
                    </Tooltip>
                  </div>
                </div>
                <div className={styles.actions}>
                  <Tooltip
                    className={styles.actionsLeft}
                    key={'reconnect'}
                    title={t(
                      'Renew your Facebook session by logging into your Facebook account again.',
                    )}
                  >
                    <FacebookConnectButton disabled={!integration}>
                      {t('Reconnect')}
                    </FacebookConnectButton>
                  </Tooltip>
                  <FacebookDisconnectButton
                    campaigns={campaigns.models}
                    disabled={!integration}
                    setAgree={setAgree}
                  />
                </div>
              </div>
              <div className={styles.integration}>
                <Label>{t('Integration status')}</Label>
                <div className={styles.integrationInfo}>
                  <Chip
                    prefix={<FontAwesomeIcon icon={stateMap.get(state)!.icon} />}
                    theme={stateMap.get(state)!.color}
                    tooltip={stateMap.get(state)!.helpText}
                  >
                    {stateTranslationMap[state]}
                  </Chip>
                  {!facebookEmailMappingTOSAccepted &&
                    state === FacebookIntegrationState.page_selected && (
                      <Tooltip
                        className={styles.tosWarning}
                        title={t(
                          'Your integration is pending approval from Meta. Meta email mapping campaigns will be available once your integration is approved.',
                        )}
                      >
                        <FontAwesomeIcon icon={faExclamationTriangle} size={'lg'} />
                      </Tooltip>
                    )}
                </div>
              </div>
            </Card>

            <Trans t={t}>
              <p>
                Connect your Facebook pages to centralize your social media marketing alongside your
                other marketing activities through Feathr. To grant Feathr advertiser access to your
                page{' '}
                <Button
                  href={'https://www.facebook.com/help/187316341316631'}
                  target={'_blank'}
                  type={'link'}
                >
                  you must have admin privileges of that page
                </Button>
                . If the page you want to grant access to is owned by a business manager,{' '}
                <Button
                  href={
                    'https://www.facebook.com/business/help/2169003770027706?id=2190812977867143'
                  }
                  target={'_blank'}
                  type={'link'}
                >
                  you must be an admin of the business manager that owns this page also
                </Button>
                .
              </p>
            </Trans>

            <FacebookPagesTable />
            <br />
            <p>
              <Trans t={t}>
                <strong>Note: </strong> You've allowed Feathr to manage Meta ads on your behalf. To
                disallow, disconnect your Facebook account.
              </Trans>
            </p>
          </>
        ) : (
          <>
            {!accessTokenHealthy && !!dateTokenExpiration && (
              <Alert className={styles.alertToken} type={AlertType.danger}>
                {t(
                  'Your Facebook session expired on {{ formattedTokenExpirationDate }}. Please renew your session by logging into your Facebook account again.',
                  { formattedTokenExpirationDate },
                )}
              </Alert>
            )}
            <p>
              {t(
                'Connect your Facebook pages to centralize your social media marketing alongside your other marketing activities through Feathr.',
              )}
            </p>
            <Checkbox
              disabled={agree && isAuthenticated}
              helpPlacement={'bottom'}
              helpText={
                'By checking this box you agree to let Feathr manage Meta ads and Facebook pages on your behalf'
              }
              label={'Allow ads management and page management'}
              onChange={handleAgree}
              value={agree}
            />
          </>
        )}
        <p>
          <Button
            href={'https://www.facebook.com/business/help/442224595808649'}
            target={'_blank'}
            type={'link'}
          >
            {t('Learn more about managing account permissions.')}
          </Button>
        </p>
        {!accessTokenHealthy && (
          <Tooltip title={loginButtonText}>
            <FacebookConnectButton disabled={!agree || !isAdmin}>
              {t('Log in with Facebook')}
            </FacebookConnectButton>
          </Tooltip>
        )}
      </div>
    </Page>
  );
}

export default observer(FacebookIntegrationPage);
