import { ObjectId } from 'bson';
import { toJS } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useHistory, useParams } from 'react-router';

import { Button, toast, Tooltip } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import EditAdvertiser, {
  validateAdvertiser,
} from '@feathr/extender/App/Settings/Account/AdvertisersPage/EditAdvertiser';
import { useAccount, useLocalUrl } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';

function createAdvertiser(advId?: string) {
  return {
    subadv_id: advId || new ObjectId().toHexString(),
    logo: '',
    url: '',
    name: '',
    id: '',
  };
}

function AdvertiserPage(): JSX.Element {
  const { advertiserId } = useParams<{ advertiserId: string }>();
  const account = useAccount();
  const history = useHistory();
  const localUrl = useLocalUrl();

  const store = useLocalObservable(() => ({
    advertiser:
      advertiserId === 'add'
        ? createAdvertiser()
        : account.getAdvertiser(advertiserId) || createAdvertiser(advertiserId),
  }));

  const validation = validateAdvertiser(store.advertiser);
  const advertiserIsValid = validation === undefined;
  const errors = !advertiserIsValid ? flattenErrors(validation) : [];

  async function handleSave() {
    if (!account) {
      return;
    }
    if (advertiserId === 'add') {
      const ttd = account.get('ttd');
      if (!ttd) {
        account.set({ ttd: { subadvertisers: [] } });
      }
      (account.get('ttd')?.subadvertisers || []).push(toJS(store.advertiser));
    }
    account.setAttributeDirty('ttd');
    await account.patch({ ttd: account.get('ttd') });
    if (account.isErrored) {
      toast('Failed to save advertiser, please try again later.', { type: 'error' });
    } else {
      toast('Advertiser saved.', { type: 'success' });
    }
    if (advertiserId === 'add') {
      history.push(localUrl('settings/account/advertisers'));
    }
  }

  return (
    <Page
      actions={
        advertiserIsValid ? (
          <Button onClick={handleSave} type={'primary'}>
            Save changes
          </Button>
        ) : (
          <Tooltip
            title={errors.map((e, i) => (
              <div key={i}>{e as string}</div>
            ))}
          >
            <Button disabled={true} type={'primary'}>
              Save changes
            </Button>
          </Tooltip>
        )
      }
      title={store.advertiser.name || 'Unnamed Advertiser'}
    >
      <EditAdvertiser advertiser={store.advertiser} />
    </Page>
  );
}

export default observer(AdvertiserPage);
