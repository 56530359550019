import { makeObservable } from 'mobx';

import { concatPath, toQueryString } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, isWretchError, Model, wretch } from '@feathr/rachis';

import type { EUsageMetrics } from './usage_metrics';

type TMetricQuota = Record<EUsageMetrics, Record<'quota', number | null>>;
export interface IUsageQuota extends IBaseAttributes, TMetricQuota {}

export class UsageQuota extends Model<IUsageQuota> {
  public readonly className = 'Usage Quota';

  public constraints: TConstraints<IUsageQuota> = {};

  constructor(attributes: Partial<IUsageQuota> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/stats_accounts/quota/${this.id}`, pathSuffix);
  }
}

export class UsageQuotas extends Collection<UsageQuota> {
  public getClassName(): string {
    return 'usage_quotas';
  }

  public url(): string {
    return `${this.getHostname()}stats_accounts/quota/`;
  }

  public getModel(attributes: Partial<IUsageQuota>): UsageQuota {
    return new UsageQuota(attributes);
  }

  public async getSelectedQuotas(
    accountId: string,
    metrics: EUsageMetrics[],
  ): Promise<IUsageQuota> {
    const headers = this.getHeaders();
    const params = {
      fields: {
        data: metrics.join(','),
      },
    };
    const url = `${this.url()}${accountId}?${toQueryString(params)}`;
    const response = await wretch<IUsageQuota>(url, {
      method: 'GET',
      headers,
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }
}
