import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type {
  DailyStat,
  ITrackedLink,
  ITrafficSources,
  TAttributionModel,
  TrackedLinkCampaign,
} from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { StatsCardV1, Table, TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IProps {
  dailyStats: IObservableArray<DailyStat>;
  campaign: TrackedLinkCampaign;
  attributionModel: TAttributionModel;
}

interface ITrafficBreakdown {
  views: number;
  sessions: number;
  conversions: number;
}

interface ITrafficBreakdownRow extends ITrafficBreakdown {
  id: string;
  source: string;
}

const sourceLabelMap: Record<string, string> = {
  email: 'Email Marketing',
  search: 'Paid Search',
  facebook: 'Meta',
  twitter: 'Twitter',
  linkedin: 'LinkedIn',
};

const TrafficColumns: Array<IColumn<ITrafficBreakdownRow>> = [
  {
    id: 'source',
    Header: TableColumnHeader({
      title: 'Source',
    }),
    width: 450,
    Cell: ({ original }): JSX.Element => {
      return <>{sourceLabelMap[original.source] || original.source}</>;
    },
    Footer: 'Total',
  },
  {
    id: 'views',
    Header: TableColumnHeader({
      title: 'Views',
    }),
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      return <>{original.views}</>;
    },
    Footer({ data }): JSX.Element {
      const total = data.reduce((subtotal, currentValue) => {
        return subtotal + currentValue._original.views;
      }, 0);
      return <>{total.toLocaleString()}</>;
    },
  },
  {
    id: 'sessions',
    Header: TableColumnHeader({
      title: 'Sessions',
    }),
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      return <>{original.sessions}</>;
    },
    Footer({ data }): JSX.Element {
      const total = data.reduce((subtotal, currentValue) => {
        return subtotal + currentValue._original.sessions;
      }, 0);
      return <>{total.toLocaleString()}</>;
    },
  },
  {
    id: 'conversions',
    Header: TableColumnHeader({
      title: 'Conversions',
    }),
    className: tableStyles.cellRight,
    width: 140,
    Cell({ original }): JSX.Element {
      return <>{original.conversions}</>;
    },
    Footer({ data }): JSX.Element {
      const total = data.reduce((subtotal, currentValue) => {
        return subtotal + currentValue._original.conversions;
      }, 0);
      return <>{total.toLocaleString()}</>;
    },
  },
];

function TrafficBreakdownCard({ dailyStats, campaign, attributionModel }: IProps): JSX.Element {
  const sources = campaign
    .get('tracked_links')
    .reduce((acc: ITrafficBreakdown, link: ITrackedLink) => {
      acc[link.utm_source] = { views: 0, sessions: 0, conversions: 0 };
      return acc;
    }, {} as ITrafficBreakdown);
  const tableData = Object.entries(
    dailyStats.reduce((sum, dailyStat) => {
      const traffic = dailyStat.get('traffic') as ITrafficSources;
      if (traffic) {
        (Object.keys(traffic) as Array<keyof ITrafficSources>).forEach((key) => {
          if (!sum[key]) {
            return;
          }
          sum[key].views += traffic[key] ? traffic[key].views : 0;
          sum[key].sessions += traffic[key] ? traffic[key].sessions : 0;
          let numConversions = 0;
          if (
            traffic[key] &&
            traffic[key].conversions &&
            traffic[key].conversions[attributionModel]
          ) {
            numConversions = traffic[key].conversions[attributionModel] || 0;
          }
          sum[key].conversions += numConversions;
        });
      }
      return sum;
    }, sources),
  ).map(([trafficSource, data]) => {
    return {
      id: trafficSource,
      source: trafficSource,
      ...data,
    };
  });
  return (
    <StatsCardV1 title={reportModuleLabels.includeTrafficBreakdown}>
      <Table columns={TrafficColumns} idKey={'id'} items={tableData} />
    </StatsCardV1>
  );
}

export default observer(TrafficBreakdownCard);
