import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import type { ILineItem } from '@feathr/blackbox';
import type { Bill } from '@feathr/blackbox';
import type { ISorted } from '@feathr/components';
import { Table } from '@feathr/components';

import BillColumns from './BillColumns';

interface IProps {
  bill: Bill;
}

function BillTable({ bill }: IProps) {
  const [sort, setSort] = useState<ISorted[]>([{ id: 'description' }]);

  let sortedItems: ILineItem[] = bill.isPending ? [] : bill.get('line_items', []);
  sort.forEach((s) => {
    if (s.id === 'description') {
      // Cannot sort observable in place, so make a copy using slice().
      sortedItems = sortedItems
        .slice()
        .sort((a, b) =>
          s.desc
            ? b.description.localeCompare(a.description)
            : a.description.localeCompare(b.description),
        );
    } else if (s.id === 'price') {
      // Cannot sort observable in place, so make a copy using slice().
      sortedItems = sortedItems
        .slice()
        .sort((a, b) => (s.desc ? b.price - a.price : a.price - b.price));
    }
  });

  return (
    <Table<ILineItem>
      columns={BillColumns(sortedItems, bill.get('event_id'))}
      idKey={'campaign_id'}
      isLoading={bill.isPending}
      isPaginated={false}
      items={sortedItems}
      noDataText={'No data'}
      // Prevent warning due to multiple arguments for onSortChange function.
      onSortChange={(newSort) => setSort(newSort)}
      sort={sort}
    />
  );
}

export default observer(BillTable);
