import { faInboxIn, faListCheck, faSliders } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type {
  EmailBaseCampaign,
  GoogleAdsSmartCampaign,
  IConfig as IBaseConfig,
  IReportAttributes,
  ReportModel,
} from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import { ContextMenu, CopyToClipboardButton } from '@feathr/components';
import type { IUseReportExportReturn } from '@feathr/extender/hooks';
import { useReportActions } from '@feathr/extender/hooks';
import { ConfigModal, ExportModal, ShareableLinksModal } from '@feathr/report_components';

export interface IReportActionsContextMenuProps<
  IAttributes extends IReportAttributes,
  IConfig extends IBaseConfig,
> extends IUseReportExportReturn<IConfig> {
  model: ReportModel<IAttributes>;
  /** Additional menu items to insert at the end of the context menu */
  menuItems?: ReactNode;
  /** Content to trigger with the additional menu items */
  children?: ReactNode;
}

function ReportActionsContextMenu<
  IAttributes extends IReportAttributes,
  IConfig extends IBaseConfig,
>({
  model,
  menuItems,
  children,
  startFormatted,
  endFormatted,
  reportLink,
  hasGoals,
  mode,
  config,
  handlers: exportHandlers,
}: Readonly<IReportActionsContextMenuProps<IAttributes, IConfig>>): JSX.Element {
  const { t } = useTranslation();
  const { isCustomizeModalOpen, isLinksModalOpen, isExportModalOpen, handlers } =
    useReportActions();

  function isEmailCampaign(
    model: ReportModel<IAttributes> | EmailBaseCampaign,
  ): model is EmailBaseCampaign {
    return [
      CampaignClass.PinpointEmail,
      CampaignClass.SmartPinpointEmail,
      CampaignClass.AutoPinpointEmail,
      CampaignClass.DripCampaign,
    ].includes((model as EmailBaseCampaign).get('_cls'));
  }

  function isGoogleCampaign(
    model: ReportModel<IAttributes> | GoogleAdsSmartCampaign,
  ): model is GoogleAdsSmartCampaign {
    return (model as GoogleAdsSmartCampaign).get('_cls') === CampaignClass.GoogleAdsSmart;
  }

  return (
    <>
      <ContextMenu buttonType={'secondary'} iconName={'ellipsis'}>
        <ContextMenu.Item
          onClick={handlers.toggleCustomizeModalOpen}
          prefix={<FontAwesomeIcon icon={faSliders} />}
        >
          {t('Customize report')}
        </ContextMenu.Item>
        <ContextMenu.Item
          onClick={handlers.toggleLinksModalOpen}
          prefix={<FontAwesomeIcon icon={faListCheck} />}
        >
          {t('Manage short links')}
        </ContextMenu.Item>
        <CopyToClipboardButton
          component={'MenuItem'}
          successMessage={t('Copied report link to clipboard')}
          t={t}
          text={reportLink}
        >
          {t('Copy report link')}
        </CopyToClipboardButton>
        <ContextMenu.Item
          onClick={handlers.toggleExportModalOpen}
          prefix={<FontAwesomeIcon icon={faInboxIn} />}
        >
          {t('Export to CSV')}
        </ContextMenu.Item>
        {menuItems}
      </ContextMenu>
      {children}
      {isCustomizeModalOpen && (
        <ConfigModal<IConfig>
          config={config}
          hasGoals={hasGoals}
          isEmailCampaign={isEmailCampaign(model)}
          isGoogleCampaign={isGoogleCampaign(model)}
          onChange={exportHandlers.onConfigChange}
          onClose={handlers.closeAndSaveReportConfig}
        />
      )}
      {isLinksModalOpen && (
        <ShareableLinksModal
          config={config}
          endDate={endFormatted}
          mode={mode}
          model={model}
          onClose={handlers.toggleLinksModalOpen}
          startDate={startFormatted}
        />
      )}
      {isExportModalOpen && (
        <ExportModal
          onClose={handlers.toggleExportModalOpen}
          onConfirm={exportHandlers.export}
          title={t('Export Report Data')}
        />
      )}
    </>
  );
}

export default observer(ReportActionsContextMenu);
