import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { GoogleAdsCreative, GoogleAdsSmartCampaign, Targeting } from '@feathr/blackbox';
import { ButtonEdit, CardV2 as Card, Chip, Form, Label, List, ListItem } from '@feathr/components';
import { formatPhoneNumber, TimeFormat } from '@feathr/hooks';
import type { ListResponse } from '@feathr/rachis';

import AdPreviewCard from '../AdPreviewCard';

import * as styles from './ReviewStep.css';

interface IReviewStepProps {
  readonly campaign: GoogleAdsSmartCampaign;
  readonly creatives: ListResponse<GoogleAdsCreative>;
  readonly targetings: IObservableArray<Targeting>;
  readonly setCurrentStep: (index: number) => void;
}

const DAYS_PER_MONTH = 365 / 12;

function ReviewStep({
  campaign,
  creatives,
  targetings,
  setCurrentStep,
}: IReviewStepProps): JSX.Element {
  const { t } = useTranslation();

  const showCallButton = !!campaign.get('phone_number');
  const selectedLocations = targetings.map((targeting) => (
    <Chip className={styles.chipFix} key={targeting.id} name={'location'} theme={'tag'}>
      {targeting.get('name')}
    </Chip>
  ));
  const keywords = campaign.get('keyword_themes');
  const positiveKeywords = keywords
    ?.filter((keyword) => !keyword.negative)
    ?.map((keyword) => (
      <Chip className={styles.chipFix} key={keyword.display_name} name={'keyword'} theme={'tag'}>
        {keyword.display_name}
      </Chip>
    ));
  const negativeKeywords = keywords
    ?.filter((keyword) => keyword.negative)
    ?.map((keyword) => (
      <Chip
        className={styles.chipFix}
        key={keyword.display_name}
        name={'negative-keyword'}
        theme={'tag'}
      >
        {keyword.display_name}
      </Chip>
    ));

  const adTextCreative = !creatives.isPending ? creatives.models?.[0] : undefined;
  const selectedHeadlines = adTextCreative?.get('headlines') ?? [];
  const selectedDescriptions = adTextCreative?.get('descriptions') ?? [];
  const dailyBudget = campaign.get('exposure_settings').target_cap_daily ?? 0;
  const dateCreated = moment
    .utc(campaign.get('date_created'), moment.ISO_8601)
    .format(TimeFormat.isoDate);

  function handleSetStep(index: number) {
    return function () {
      setCurrentStep(index);
    };
  }

  return (
    <Form
      label={t('Review')}
      title={t("Review your campaign to be sure it's right")}
      width={'wide'}
    >
      <div className={styles.root}>
        <div className={styles.left}>
          <Card name={'campaign-info'}>
            <Card.Header title={t('Information')}>
              <ButtonEdit onClick={handleSetStep(0)} t={t} />
            </Card.Header>
            <Card.Content addVerticalGap={true}>
              <div>
                <Label>{t('Website')}</Label>
                <div>{campaign.get('destination_url')}</div>
              </div>
              <div>
                <Label>{t('Campaign name')}</Label>
                <div>
                  {t('{{campaign}} created {{-dateCreated}}', {
                    campaign: campaign.name,
                    dateCreated,
                  })}
                </div>
              </div>
              <div>
                <Label>{t('Business name')}</Label>
                <div>{campaign.get('business_name')}</div>
              </div>
            </Card.Content>
          </Card>
          <Card name={'locations'}>
            <Card.Header description={selectedLocations} title={t('Locations')}>
              <ButtonEdit onClick={handleSetStep(1)} t={t} />
            </Card.Header>
          </Card>
          <Card name={'keyword-themes'}>
            <Card.Header description={positiveKeywords} title={t('Keyword Themes')}>
              <ButtonEdit onClick={handleSetStep(2)} t={t} />
            </Card.Header>
          </Card>
          {negativeKeywords?.length > 0 && (
            <Card name={'negative-keywords'}>
              <Card.Header description={negativeKeywords} title={t('Negative Keywords')}>
                <ButtonEdit onClick={handleSetStep(2)} t={t} />
              </Card.Header>
            </Card>
          )}
          <Card name={'ad-text'}>
            <Card.Header title={t('Ad Text')}>
              <ButtonEdit onClick={handleSetStep(3)} t={t} />
            </Card.Header>
            <Card.Content addVerticalGap={true}>
              <div data-name={'headlines'}>
                <Label>{t('Headlines')}</Label>
                <List hasBullets={true}>
                  {selectedHeadlines?.map((headline) => (
                    <ListItem key={headline.key}>{headline.text}</ListItem>
                  ))}
                </List>
              </div>
              <div data-name={'descriptions'}>
                <Label>{t('Descriptions')}</Label>
                <List hasBullets={true}>
                  {selectedDescriptions?.map((description) => (
                    <ListItem key={description.key}>{description.text}</ListItem>
                  ))}
                </List>
              </div>
              {showCallButton && (
                <div>
                  <Label>{t('Phone number')}</Label>
                  <div>
                    {formatPhoneNumber(
                      campaign.get('phone_number', ''),
                      campaign.get('phone_number_country_code'),
                    )}
                  </div>
                </div>
              )}
            </Card.Content>
          </Card>
          <Card>
            {/* TODO: Integrate step 5 real data #2588 */}
            <Card.Header description={'Coming soon'} title={t('Goal')}>
              <ButtonEdit onClick={handleSetStep(4)} t={t} />
            </Card.Header>
          </Card>
          <Card name={'budget'}>
            <Card.Header title={t('Budget')}>
              <ButtonEdit onClick={handleSetStep(5)} t={t} />
            </Card.Header>
            <Card.Content addVerticalGap={true}>
              <div>
                <Label>{t('Start date')}</Label>
                <div>
                  {moment
                    .utc(campaign.get('date_start'), moment.ISO_8601)
                    .format(TimeFormat.pickerDate)}
                </div>
              </div>
              {campaign.get('date_end') && (
                <div>
                  <Label>{t('End date')}</Label>
                  <div>
                    {moment
                      .utc(campaign.get('date_end'), moment.ISO_8601)
                      .format(TimeFormat.pickerDate)}
                  </div>
                </div>
              )}
              <div>
                <Label>{t('Daily average')}</Label>
                <div>{numeral(dailyBudget).format('$0,0.00')}</div>
              </div>
              <div>
                <Label>{t('Monthly average')}</Label>
                <div>{numeral(dailyBudget * DAYS_PER_MONTH).format('$0,0.00')}</div>
              </div>
            </Card.Content>
          </Card>
        </div>
        <div className={styles.right}>
          {!creatives.isPending && (
            <AdPreviewCard
              businessName={campaign.get('business_name')}
              descriptions={selectedDescriptions.map((description) => description.text) ?? []}
              headlines={selectedHeadlines.map((headline) => headline.text) ?? []}
              showCallButton={showCallButton}
              websiteUrl={campaign.get('destination_url')}
            />
          )}
        </div>
      </div>
    </Form>
  );
}

export default observer(ReviewStep);
