import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import type { Template } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Chip, PlaceholderImage, Spinner, TableColumnHeader, Time } from '@feathr/components';
import CampaignChip from '@feathr/extender/components/CampaignChip';
import { useLocalUrl } from '@feathr/extender/state';
import { templateTypeIcon, templateTypeTheme } from '@feathr/extender/styles/template';
import { TimeFormat } from '@feathr/hooks';

import EventTemplatesCampaignOptions from './EventTemplatesCampaignOptions';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './eventTemplatesCampaignColumns.css';

import noImg from 'images/no-img.png';

function eventTemplatesCampaignColumns(): Array<IColumn<Template>> {
  return [
    {
      id: 'thumbnail',
      Header: TableColumnHeader({
        title: 'Thumbnail',
      }),
      sortable: false,
      className: styles.thumbnail,
      width: 220,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const localUrl = useLocalUrl();
              const url = original.get('thumbnail_url', noImg);
              let thumbnail = (
                <img alt={''} src={url} style={{ maxHeight: '100px', maxWidth: '220px' }} />
              );
              if (original.isBanner && original.shouldUsePlaceholder()) {
                thumbnail = (
                  <PlaceholderImage
                    height={original.get('height')!}
                    style={{ maxHeight: '100px', maxWidth: '220px' }}
                    width={original.get('width')!}
                  />
                );
              }
              return <Link to={localUrl(original.getItemUrl())}>{thumbnail}</Link>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: 'Name',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const localUrl = useLocalUrl();
              if (original.isUpdating) {
                return (
                  <div style={{ display: 'flex' }}>
                    <i>Loading...</i>&nbsp;
                    <Spinner size={16} />
                  </div>
                );
              }
              return <Link to={localUrl(original.getItemUrl())}>{original.name}</Link>;
            }}
          </Observer>
        );
      },
    },
    {
      id: '_cls',
      Header: TableColumnHeader({
        title: 'Type',
      }),
      sortable: false,
      width: 150,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function (): JSX.Element {
              const cls = original.get('_cls');
              const typeIcon = templateTypeIcon(cls);
              return (
                <Chip
                  prefix={typeIcon ? <FontAwesomeIcon icon={typeIcon} /> : undefined}
                  theme={templateTypeTheme(cls)}
                >
                  {original.getTypeLabel()}
                </Chip>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'campaign',
      Header: TableColumnHeader({
        title: 'Campaign',
      }),
      sortable: false,
      width: 150,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const parentIsCampaign = original.get('parent_kind') === 'campaign';
              if (parentIsCampaign) {
                return <CampaignChip id={original.get('parent')} />;
              }
              return <span />;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'date_last_modified',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Last Updated',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      width: 150,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return (
                <Time
                  format={TimeFormat.shortDateTime}
                  timestamp={original.get('date_last_modified')}
                />
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'options',
      Header: TableColumnHeader({
        title: 'Options',
      }),
      sortable: false,
      className: tableStyles.cellCenter,
      width: 80,
      Cell({ original }): JSX.Element {
        return <EventTemplatesCampaignOptions template={original} />;
      },
    },
  ];
}

export default eventTemplatesCampaignColumns;
