import type { Template } from '@feathr/blackbox';
import { toast } from '@feathr/components';

interface IToastMessageProps {
  updatedTemplate: Template;
  successMessage: string;
  errorMessage: string;
}

export function toastMessage({
  updatedTemplate,
  successMessage,
  errorMessage,
}: IToastMessageProps) {
  if (updatedTemplate.isErrored) {
    toast(errorMessage, {
      type: 'error',
    });
  } else {
    toast(successMessage);
  }
}
