import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DebouncedInput, Icon, Input } from '@feathr/components';

export interface IFiltersSearch {
  name?: string;
}

interface IProps {
  filters: IFiltersSearch;
  onChangeFilters: (filters: IFiltersSearch) => void;
}

function CampaignsSearch({ filters, onChangeFilters }: IProps): JSX.Element {
  const { t } = useTranslation();

  function handleDebouncedSearchChange(newValue?: string): void {
    onChangeFilters({ ...filters, name: newValue ? newValue : undefined });
  }

  return (
    <DebouncedInput<string> defaultValue={filters.name} onChange={handleDebouncedSearchChange}>
      {(liveValue, onChangeLiveValue): JSX.Element => (
        <Input
          isClearable={true}
          onChange={onChangeLiveValue}
          placeholder={t('Search by name...')}
          prefix={<Icon icon={faSearch} />}
          type={'text'}
          value={liveValue}
        />
      )}
    </DebouncedInput>
  );
}

export default CampaignsSearch;
