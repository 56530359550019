import type { TFunction } from 'i18next';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { IImportColumn } from '@feathr/blackbox';
import type {
  CustomField,
  CustomFields as CustomFieldsCollection,
  Importer,
} from '@feathr/blackbox';
import { Button, Spinner, Step, Steps, toast, Wizard } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { StoresContext, useAccount, useLocalUrl } from '@feathr/extender/state';
import { logUserEvents, useRedirect } from '@feathr/hooks';

import PartnerImportStepOne, { validateStepOne } from './PartnerImportStepOne';
import PartnerImportStepThree from './PartnerImportStepThree';
import PartnerImportStepTwo, { validateStepTwo } from './PartnerImportStepTwo';

export interface IData {
  columns: IObservableArray<IImportColumn>;
  rows: IObservableArray<string[]>;
  column_names: IObservableArray<string>;
}

export interface IImportButtonProps {
  importer: Importer;
}

const importerIsValid = (errors: string[][]): boolean => {
  return errors.every((stepErrors) => stepErrors.length === 0);
};

const validateImporter = (importer: Importer): string[][] => {
  return [validateStepOne(importer), validateStepTwo(importer)];
};

export const ImportButton = observer((details: IImportButtonProps) => {
  const account = useAccount();
  const { CustomFields } = useContext(StoresContext);
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const [redirect] = useRedirect();
  const url = localUrl(`/projects/${details.importer.get('event')}/partners/imports`);
  const validationErrors = validateImporter(details.importer);
  const isValid = importerIsValid(validationErrors);

  const handleOnClick = useCallback(async () => {
    await onSave(details.importer, CustomFields, url, redirect, account.id, t);
  }, [CustomFields, account.id, details.importer, redirect, t, url]);

  return (
    <Button disabled={!isValid} id={'importPartners'} onClick={handleOnClick} type={'primary'}>
      {t('Import partners')}
    </Button>
  );
});

const onSave = async (
  importer: Importer,
  customFieldsCollection: CustomFieldsCollection,
  url: string,
  redirect: (defaultRedirect?: string) => void,
  accountId: string,
  t: TFunction,
): Promise<void> => {
  try {
    const saveModel = async (model: CustomField): Promise<void> => {
      if (model.isEphemeral || !model.id) {
        await model.collection!.add(model, { validate: true, refreshApiCache: false });
      } else if (model.isDirty) {
        await model.save();
      }
    };
    /*
     * Save custom fields before importer so they are available by the time
     * the importer is ready. We have to save them one at a time because
     * otherwise we introduce a race condition on the backend, thus the
     * recursive async function.
     */
    const customFields = importer.getUsedCustomFields(customFieldsCollection);

    async function saveCustomFields(fields: CustomField[]): Promise<void> {
      const field = fields.pop();
      if (!field) {
        return;
      }
      await saveModel(field);
      await saveCustomFields(fields);
    }

    await saveCustomFields(customFields);
    customFieldsCollection.refreshApiCache();
    importer.set({ state: 'pending' });
    await importer.patchDirty();
    redirect(url);
    toast(t('Import successfully uploaded.'), { type: 'success' });
    logUserEvents({
      'Completed partner import': {
        account_id: accountId,
        importer_id: importer.id,
      },
    });

    // If err is instance of Error, it should be of type any.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message = e.json ? e.json.message : e.toString();
    toast(t('Something went wrong while uploading the import: {{message}}', { message }));
  }
};

function EventPartnersImportPage(): JSX.Element {
  const { t } = useTranslation();
  const { Importers } = useContext(StoresContext);
  const { importerId } = useParams<{ importerId: string }>();

  const [currentStep, setCurrentStep] = useState(0);
  const [rows, setRows] = useState<string[][]>([]);

  const importer = Importers.get(importerId!);

  const importSteps = [
    <Step key={0} stepIndex={0} title={t('Upload')} />,
    <Step key={1} stepIndex={1} title={t('Map')} />,
    <Step key={2} stepIndex={2} title={t('Review')} />,
  ];

  const steps = (
    <Steps current={currentStep} onChange={setCurrentStep}>
      {importSteps}
    </Steps>
  );

  function onNext(): void {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
  }

  function onPrev(): void {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
  }

  if (importer.isPending) {
    return <Spinner />;
  }

  return (
    <Page title={t('Import Partners')} width={'wide'}>
      <Wizard steps={steps}>
        {currentStep === 0 && importer && (
          <PartnerImportStepOne importer={importer} onNext={onNext} rows={rows} setRows={setRows} />
        )}
        {currentStep === 1 && (
          <PartnerImportStepTwo importer={importer} onNext={onNext} onPrev={onPrev} />
        )}
        {currentStep === 2 && (
          <PartnerImportStepThree ImportButton={ImportButton} importer={importer} onPrev={onPrev} />
        )}
      </Wizard>
    </Page>
  );
}

export default observer(EventPartnersImportPage);
