import { DataRequestState } from '@feathr/blackbox';

export const dataRequestStateColorMap: Record<DataRequestState, string> = {
  [DataRequestState.Draft]: 'default',
  [DataRequestState.Submitted]: 'sky',
  [DataRequestState.InProgress]: 'yellow',
  [DataRequestState.NeedsFeedback]: 'orange',
  [DataRequestState.Live]: 'lime',
  [DataRequestState.Completed]: 'green',
  [DataRequestState.Canceled]: 'red',
  [DataRequestState.Archived]: 'violet',
};
