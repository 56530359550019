import { faCloudArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TFunction } from 'i18next';
import isEqual from 'lodash.isequal';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import type { Segment } from '@feathr/blackbox';
import type { IRadioOption } from '@feathr/components';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CardV2 as Card,
  Radios,
  toast,
  Tooltip,
} from '@feathr/components';
import EditFilters from '@feathr/extender/components/EditFilters';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import DataPage from '../DataPage';
import BreadcrumbsTable from '../DataPage/BreadcrumbsTable';
import CreatePersonButton from '../DataPage/CreatePersonButton';
import type { TVariant } from '../DataPage/PersonsTable';
import PersonsTable from '../DataPage/PersonsTable';
import type { TPresetId } from './segmentPresets';
import getSegmentPresets from './segmentPresets';

import * as styles from './DataExplorePage.css';

type TSegmentPreset = TPresetId | 'custom';

function isPreset(presetId: TSegmentPreset, segment: Segment, t: TFunction): boolean {
  if (presetId === 'custom') {
    return true;
  }

  const segmentPresets = getSegmentPresets(t);

  return (
    segment.get('mode') === segmentPresets[presetId].mode &&
    segment.get('lookback_mode') === segmentPresets[presetId].lookback_mode &&
    segment.get('lookback_value') === segmentPresets[presetId].lookback_value &&
    isEqual(toJS(segment.get('predicates')), segmentPresets[presetId].predicates)
  );
}

function DataExplorePage(): JSX.Element {
  const { Segments } = useContext(StoresContext);
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { view, tab } = useParams<{
    view: 'all' | 'known' | 'anonymous';
    tab: 'people' | 'activity';
  }>();
  const [presetId, setPresetId] = useState<TSegmentPreset>(view);
  const { t } = useTranslation();

  const segmentPresets = getSegmentPresets(t);
  const [segment] = useState<Segment>(Segments.create(segmentPresets[presetId]));

  const dataImportsPage = localUrl('/data/imports');

  /**
   * Update presetId when view is changed.
   */
  useEffect(() => {
    setPresetId(view);
    if (presetId !== view) {
      segment.set(segmentPresets[view]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  /**
   * Update group when new presetId is selected.
   */
  useEffect(() => {
    if (presetId === 'custom') {
      // Don't do anything.
    } else if (!isPreset(presetId, segment, t)) {
      segment.set(segmentPresets[presetId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presetId]);

  /**
   * Update presetId when group is modified.
   */
  useEffect(() => {
    function getPresetId(compareSegment: Segment): TSegmentPreset {
      return (Object.keys(segmentPresets).find((key) =>
        isPreset(key as TSegmentPreset, compareSegment, t),
      ) ?? 'custom') as TSegmentPreset;
    }

    const foundPresetId = getPresetId(segment);

    if (['custom'].includes(presetId)) {
      // Don't interfere with special presets, and when user modified filters.
    } else if (presetId !== foundPresetId) {
      setPresetId(foundPresetId);
    }
  }, [
    segment.get('mode'),
    segment.get('lookback_mode'),
    segment.get('lookback_value'),
    toJS(segment.get('predicates', [])),
  ]);

  async function handleCreate(): Promise<void> {
    try {
      if (segmentPresets[presetId]) {
        segment.set({ name: segmentPresets[presetId].name });
      } else {
        // TODO: Translate default name for new group.
        segment.set({ name: 'New Group' });
      }
      const response = await Segments.add(segment);
      history.push(localUrl(response.getItemUrl('edit')));
    } catch (e) {
      toast(t('There was an error creating the group.'), { type: 'error' });
    }
  }

  const segmentPresetOptions: IRadioOption[] = [
    ...Object.keys(segmentPresets)
      // Do not display special presets.
      .filter((key) => !['known', 'anonymous'].includes(key))
      .map((key) => ({
        id: key,
        name: segmentPresets[key as TPresetId].name!,
      })),
    { id: 'custom', name: t('Custom') },
  ];

  function handleChangePreset(newValue?: string): void {
    if (newValue === undefined) {
      return;
    }
    setPresetId(newValue as TSegmentPreset);
  }

  const filters = (
    <Card width={'full'}>
      <CardHeader title={t('Filters')} />
      <CardContent>
        {view === 'all' && (
          <Radios
            label={t('Select a preset')}
            onChange={handleChangePreset}
            options={segmentPresetOptions}
            value={presetId}
            wrapperClassName={styles.radios}
          />
        )}
        <EditFilters mode={tab === 'people' ? 'count' : 'crumbs'} segment={segment} />
      </CardContent>
      <CardActions>
        <Tooltip
          key={'create'}
          position={'bottom-end'}
          title={t('Turn your selected filters into a reusable group.')}
        >
          <Button onClick={handleCreate}>{t('Save as group')}</Button>
        </Tooltip>
      </CardActions>
    </Card>
  );

  const sectionTitle = ((): string => {
    switch (view) {
      case 'known':
        return t('Known');

      case 'anonymous':
        return t('Anonymous');

      default:
        return t('All');
    }
  })();

  const variant = ((): TVariant => {
    if (['known', 'anonymous'].includes(view)) {
      return view as TVariant;
    }
    return 'default';
  })();

  return (
    <DataPage
      actions={[
        <Button
          key={'view-imports'}
          link={dataImportsPage}
          name={'view-imports'}
          prefix={<FontAwesomeIcon icon={faCloudArrowUp} />}
        >
          {t('View imports')}
        </Button>,
        <CreatePersonButton key={'create-person'} />,
      ]}
      filters={filters}
      hasTabs={!['known', 'anonymous'].includes(view)}
      sectionTitle={sectionTitle}
    >
      {tab === 'people' ? (
        <PersonsTable segment={segment} variant={variant} />
      ) : (
        <BreadcrumbsTable segment={segment} />
      )}
    </DataPage>
  );
}

export default observer(DataExplorePage);
