import { faPlayCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { ImisIntegration } from '@feathr/blackbox';
import { Button, ConfirmModalV1, toast } from '@feathr/components';
import { useToggle } from '@feathr/hooks';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IiMISResumeControlProps {
  integration: ImisIntegration;
}

function ImisResumeControl({ integration }: IiMISResumeControlProps): JSX.Element | null {
  const { t } = useTranslation();
  const [showResumeModal, toggleResumeModal] = useToggle(false);

  function handleResumeModal(): void {
    toggleResumeModal();
  }

  function handleCancel(): void {
    if (showResumeModal) {
      toggleResumeModal();
    }
  }

  async function handleResume(): Promise<void> {
    try {
      await integration.resumeIntegration();
      toast(t('Your iMIS integration has been successfully resumed'), {
        type: ToastType.SUCCESS,
      });
    } catch (error) {
      toast(
        t('Something went wrong while trying to resume your iMIS integration: {{error}}', {
          error,
        }),
        {
          type: ToastType.ERROR,
        },
      );
    } finally {
      toggleResumeModal();
      // TODO: Hard reloading will not be necesary with the implemention #2651.
      window.location.reload();
    }
  }

  return (
    <>
      <Button
        key={'reconnect-imis-integration'}
        name={'reconnect-imis-integration'}
        onClick={handleResumeModal}
        prefix={<FontAwesomeIcon icon={faPlayCircle} />}
        type={'primary'}
      >
        {t('Resume integration')}
      </Button>
      {showResumeModal && (
        <ConfirmModalV1
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Resume')}
          onClose={handleCancel}
          onConfirm={handleResume}
          t={t}
          title={t('Resume your iMIS integration?')}
        >
          <p>{t('Data will resume syncing between Feathr and iMIS.')}</p>
        </ConfirmModalV1>
      )}
    </>
  );
}

export default observer(ImisResumeControl);
