import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Font } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader, Time } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import FontOptions from './FontOptions';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const columns: Array<IColumn<Font>> = [
  {
    id: 'name',
    Header: TableColumnHeader({
      sortType: 'alpha',
      title: 'Name',
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    Cell({ original }): JSX.Element {
      return (
        <Observer>
          {(): JSX.Element => {
            return <>{original.name}</>;
          }}
        </Observer>
      );
    },
  },
  {
    id: 'date_created',
    Header: TableColumnHeader({
      sortType: 'numeric',
      title: 'Created',
    }),
    minWidth: 110,
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    Cell({ original }): JSX.Element {
      return (
        <Observer>
          {(): JSX.Element => {
            return (
              <Time format={TimeFormat.shortDateTime} timestamp={original.get('date_created')} />
            );
          }}
        </Observer>
      );
    },
  },
  {
    id: 'options',
    Header: TableColumnHeader({
      title: 'Options',
    }),
    width: 80,
    sortable: false,
    className: tableStyles.cellCenter,
    Cell({ original }): JSX.Element {
      return <FontOptions font={original} />;
    },
  },
];

export default columns;
