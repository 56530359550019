import { faFileArrowDown, faFileImport } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IiMISServiceAccount, ImisIntegration, TiMISOrgSite } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  ButtonValid,
  CardV2 as Card,
  EAlertV2Type as AlertType,
  Toolbar,
} from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import type { TConfigurationContext } from './ImisConfiguration';

import * as styles from './ImisConfigStepFour.css';

interface IProps {
  context?: TConfigurationContext;
  onNext: () => void;
  onPrev: () => void;
  integration: ImisIntegration;
}

export function validateStepFour(integration: ImisIntegration): TValidateGrouped {
  return integration.validate(['sso_url'], false, 'grouped').errors;
}

function ImisConfigStepFour({
  context = 'wizard',
  onNext,
  onPrev,
  integration,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const [hasIQAFile, setHasIQAFile] = useState<boolean | undefined>();
  const orgSite: TiMISOrgSite = integration.get('org_site');
  const iqaUploadUrl = `${orgSite}/Staff/AsiCommon/Controls/IQA/Default.aspx`;
  const serviceAccount: Readonly<IiMISServiceAccount> = integration.get('service_account');
  const contactKey = serviceAccount.contact_key;

  // Validation for requirements that are not constraints-based.
  function validateIQAFile(): string[] {
    const errors: string[] = [];
    if (!hasIQAFile) {
      errors.push(
        t('You must download the file and import it into your iMIS account before proceeding.'),
      );
    }
    return errors;
  }

  function showIQAAvailabilityAlert(hasIQAFile: boolean | undefined): JSX.Element | undefined {
    if (hasIQAFile) {
      return <Alert title={t('IQA file was found')} type={AlertType.success} />;
    }
    if (hasIQAFile === false) {
      return <Alert title={t('IQA file was not found')} type={AlertType.danger} />;
    }
    return undefined;
  }

  async function handleCheckIQAFile(): Promise<void> {
    try {
      await integration.checkForIQAFile();
      setHasIQAFile(true);
    } catch (error) {
      setHasIQAFile(false);
    }
  }

  const validationErrors = [...flattenErrors(validateStepFour(integration)), ...validateIQAFile()];

  return (
    <>
      <Card>
        <Card.Header
          description={t(
            "In order to sync data between iMIS and Feathr, you'll need to download a special file here and add it to your iMIS account.",
          )}
          title={t('Set Up IQA File')}
        />
        <Card.Content
          action={
            <Button
              download={`imis-iqa-${contactKey}.xml`}
              href={integration.downloadIQAUrl}
              name={'download-iqa-file'}
              prefix={<FontAwesomeIcon icon={faFileArrowDown} />}
            >
              {t('Download')}
            </Button>
          }
          description={t(
            'Download this file and upload it to your iMIS account. It is used to setup a data relationship between Feathr and iMIS.',
          )}
          title={t('Download IQA file')}
        />
        <Card.Content
          action={
            <Button
              href={iqaUploadUrl}
              name={'import-iqa-file'}
              prefix={<FontAwesomeIcon icon={faFileImport} />}
              target={'_blank'}
            >
              {t('Import in iMIS')}
            </Button>
          }
          description={
            <>
              {t('Click the button to navigate to iMIS and upload the IQA file. ')}
              <a href={'https://help.feathr.co/hc/en-us/articles/17822906049815'} target={'_blank'}>
                {t('Learn more')}
              </a>
            </>
          }
          title={t('Upload IQA file in iMIS')}
        />
        <Card.Actions>
          <Button name={'check-for-iqa-file'} onClick={handleCheckIQAFile}>
            {t('Check for IQA file')}
          </Button>
        </Card.Actions>
      </Card>
      {context === 'wizard' && (
        <div className={styles.buttonWrapper}>
          {showIQAAvailabilityAlert(hasIQAFile)}
          <Toolbar>
            <Button key={'prev'} name={'previous'} onClick={onPrev}>
              {t('Previous')}
            </Button>
            <ButtonValid errors={validationErrors} key={'next'} name={'next'} onClick={onNext}>
              {t('Next')}
            </ButtonValid>
          </Toolbar>
        </div>
      )}
    </>
  );
}

export default observer(ImisConfigStepFour);
