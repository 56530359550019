import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { ReferralCampaign } from '@feathr/blackbox';
import { Tab } from '@feathr/components';
import { CampaignActions } from '@feathr/extender/components/PageHeaderActions';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import CampaignPage from '../../CampaignPage/CampaignPage';

import * as styles from './ReferralCampaignPage.css';

export interface IProps {
  children: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
  actions?: ReactNode;
}

function ReferralCampaignPage({
  children,
  description,
  title,
  actions,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const { campaignId } = useParams<{ eventId?: string; campaignId: string }>();
  const { Campaigns } = useContext(StoresContext);
  const campaign = Campaigns.get(campaignId) as ReferralCampaign;

  const defaultActions = <CampaignActions campaign={campaign} />;
  const tabs = [
    <Tab exact={true} key={'report'} link={localUrl(campaign.getItemUrl())} title={t('Report')} />,
    <Tab
      key={'templates'}
      link={localUrl(campaign.getItemUrl('templates'))}
      title={t('Templates')}
    />,
    <Tab key={'partners'} link={localUrl(campaign.getItemUrl('partners'))} title={t('Partners')} />,
  ];

  return (
    <CampaignPage
      actions={actions ?? defaultActions}
      campaign={campaign}
      tabs={tabs}
      title={campaign.name}
    >
      <h3>{title}</h3>
      {!!description && <div className={styles.description}>{description}</div>}
      {children}
    </CampaignPage>
  );
}

export default observer(ReferralCampaignPage);
