import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import type { Breadcrumb, FieldCollection } from '@feathr/blackbox';
import { CardV2 as Card, Skeleton, TLDR } from '@feathr/components';
import { hiddenFlavors } from '@feathr/extender/state';

import BreadcrumbDetails from '../BreadcrumbDetails';

import 'react-vertical-timeline-component/style.min.css';
import * as styles from './BreadcrumbsTimeline.css';

interface IProps {
  breadcrumbs: Breadcrumb[];
  isLoading?: boolean;
  context: FieldCollection.Person | FieldCollection.Partner;
  hasViewRawData: boolean;
}

const keys = ['one', 'two'];

// Display 2 skeleton cards while loading
const skeletonCards = new Array(2).fill(null).map((_, index) => (
  <Card contentClassName={styles.skeletonRoot} key={keys[index]}>
    <Card.Content addVerticalGap={true}>
      <div className={styles.skeletonTitle}>
        <Skeleton width={100} />
        <Skeleton width={126} />
      </div>
      <Skeleton paragraphs={1} width={400} />
    </Card.Content>
    <Card.Content addVerticalGap={true}>
      <Skeleton paragraphs={1} width={200} />
      <TLDR isLoading={true} />
      <Skeleton paragraphs={1} width={250} />
      <TLDR isLoading={true} />
      <Skeleton paragraphs={1} width={200} />
      <TLDR isLoading={true} />
    </Card.Content>
  </Card>
));

function BreadcrumbsTimeline({
  breadcrumbs,
  context,
  hasViewRawData,
  isLoading = false,
}: Readonly<IProps>): JSX.Element {
  const visibleBreadcrumbs = useMemo(
    () => breadcrumbs.filter((breadcrumb) => !hiddenFlavors.includes(breadcrumb.get('flvr'))),
    [breadcrumbs],
  );

  return (
    <VerticalTimeline className={styles.root} layout={'1-column'}>
      {visibleBreadcrumbs.map((breadcrumb) => {
        return (
          <VerticalTimelineElement
            className={styles.item}
            iconClassName={styles.icon}
            key={breadcrumb.id}
          >
            <BreadcrumbDetails
              breadcrumb={breadcrumb}
              context={context}
              hasViewRawData={hasViewRawData}
              key={breadcrumb.id}
            />
          </VerticalTimelineElement>
        );
      })}
      {isLoading && skeletonCards}
    </VerticalTimeline>
  );
}

export default observer(BreadcrumbsTimeline);
