import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Account, TUnitOfTimeConversions } from '@feathr/blackbox';
import { CardV2 as Card, Fieldset, Form, NumberInput, Select } from '@feathr/components';

import * as styles from './ConversionsOptions.css';

interface IProps {
  account: Account;
}

interface IMenuOption {
  id: TUnitOfTimeConversions;
  value: TUnitOfTimeConversions;
}

/*
 * Default should be 5 minutes. Minimum should be 1 second (i.e. what the time selector component allows).
 * Maximum should be 7 days.
 */
const cooldownMinimums = { days: 1, hours: 1, minutes: 1, seconds: 1 };
const cooldownMaximums = { days: 7, hours: 168, minutes: 10080, seconds: 604800 };

function ConversionsOptions({ account }: IProps): JSX.Element {
  const { t } = useTranslation();

  const isLoading = account.isPending;
  const { errors } = account.validate(['multi_convert_cooldown_value'], false);

  function getOptionLabel({ id }: IMenuOption): string | undefined {
    const delayValue = account.get('multi_convert_cooldown_value', getCooldownMinimum());
    return {
      seconds: t('second', { count: delayValue }),
      minutes: t('minute', { count: delayValue }),
      hours: t('hour', { count: delayValue }),
      days: t('day', { count: delayValue }),
    }[id];
  }

  function getOptionValue({ value }: IMenuOption): string {
    return value;
  }

  function handleChangeCooldownUnit({ id }: IMenuOption): void {
    account.set({
      multi_convert_cooldown_unit: id,
      multi_convert_cooldown_value: cooldownMinimums[id],
    });
  }

  function getCooldownMinimum(): number {
    const cooldownUnit = account.get('multi_convert_cooldown_unit', 'minutes');
    return cooldownMinimums[cooldownUnit];
  }

  function getCooldownMaximum(): number {
    const cooldownUnit = account.get('multi_convert_cooldown_unit', 'minutes');
    return cooldownMaximums[cooldownUnit];
  }

  function getTimeOptions(): IMenuOption[] {
    return [
      { id: 'seconds', value: t('seconds') },
      { id: 'minutes', value: t('minutes') },
      { id: 'hours', value: t('hours') },
      { id: 'days', value: t('days') },
    ];
  }

  function getTimeValue(attribute: 'multi_convert_cooldown_unit'): IMenuOption | undefined {
    return getTimeOptions().find(({ id }) => id === account.get(attribute, 'minutes'));
  }

  return (
    <Card>
      <Card.Header
        description={
          <Trans t={t}>
            Set the minimum amount of time that must pass before a duplicate conversion may be
            counted again. A duplicate conversion is a conversion for the same person and dollar
            amount as an earlier conversion. This setting applies to campaigns and flights set to{' '}
            <a
              href={
                'https://help.feathr.co/hc/en-us/articles/11076180961687-Multi-Convert-How-to-Count-Multiple-Conversions-by-the-Same-Person'
              }
              target={'_blank'}
            >
              track multiple conversions by the same person
            </a>
            .
          </Trans>
        }
        title={t('Multi-Convert Cooldown Period')}
      />
      <Card.Content>
        <Form label={t('Multi-Convert Cooldown Period')}>
          <Fieldset>
            <NumberInput
              attribute={'multi_convert_cooldown_value'}
              className={styles.delay}
              clearableClassName={styles.delayElement}
              disabled={isLoading}
              helpText={t(
                'Choose the minimum amount of time that must pass before a duplicate conversion may be counted again.',
              )}
              label={t('Cooldown time')}
              max={getCooldownMaximum()}
              min={getCooldownMinimum()}
              model={account}
              name={'multi_convert_cooldown_value'}
              prefix={t('Delay conversion for')}
              suffix={
                <Select
                  disabled={isLoading}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue}
                  name={'multi_convert_cooldown_unit'}
                  onSelectSingle={handleChangeCooldownUnit}
                  options={getTimeOptions()}
                  value={getTimeValue('multi_convert_cooldown_unit')}
                />
              }
              validationError={errors}
              wrapperClassName={styles.delayWrapper}
            />
          </Fieldset>
        </Form>
      </Card.Content>
    </Card>
  );
}

export default observer(ConversionsOptions);
