export enum ECardCollapseKeys {
  AudienceEngagement = 'audienceEngagement_collapse',
  EmailPerformance = 'emailPerformance_collapse',
  AdPerformance = 'adPerformance_collapse',
}

export enum ETimeFrameKeys {
  AudienceEngagement = 'audienceEngagement_timeframe',
  EmailPerformance = 'emailPerformance_timeframe',
  AdPerformance = 'adPerformance_timeframe',
}

export enum ENamespaces {
  AccountDashboard = 'accountDashboard',
}

export interface IPersistProps<T = string, U = string> {
  key: T;
  namespace: U;
}
