import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type {
  Account,
  Billable,
  Event,
  FacebookCampaign,
  FacebookDisplayCreative,
  Goal,
  IFacebook,
  Segment,
  Targeting,
  User,
} from '@feathr/blackbox';
import { Button, Form } from '@feathr/components';
import { StoresContext, useFlags, useUser } from '@feathr/extender/state';
import type { Model } from '@feathr/rachis';

import CampaignSummary from '../../CampaignSummary';
import type { ICampaignValidationErrors } from '../../CampaignSummary/CampaignSummary';
import { getTargetables, getTargetSegments } from '../CampaignEdit/CampaignEditStepTwo';
import SaveCampaignButton from '../SaveCampaignButton/index';
import { getGoalSegments } from '../StepGoals';

import * as styles from '../CampaignEdit/CampaignEdit.css';

interface ICampaignDetails {
  campaign: FacebookCampaign;
  targetings: IObservableArray<Targeting>;
  targetingSegments?: Segment[];
  goals: IObservableArray<Goal>;
  goalSegments?: Segment[];
  creatives: IObservableArray<FacebookDisplayCreative>;
  billable: Billable;
  account: Account;
  event: Event;
  integration?: IFacebook;
}

interface IProps {
  onPrev: () => void;
  campaign: FacebookCampaign;
  targets: IObservableArray<Targeting>;
  goals: IObservableArray<Goal>;
  creatives: IObservableArray<FacebookDisplayCreative>;
  billable: Billable;
  account: Account;
  event: Event;
  validate: (
    details: ICampaignDetails,
    user?: User,
    noMaxBudget?: boolean,
  ) => ICampaignValidationErrors;
  integration?: IFacebook;
}

function FacebookCampaignEditStepSeven({
  campaign,
  targets,
  goals,
  creatives,
  billable,
  account,
  event,
  onPrev,
  validate,
  integration,
}: IProps): JSX.Element {
  const { Segments, Targetables } = React.useContext(StoresContext);
  const flags = useFlags();
  const user = useUser();

  const targetingSegments = getTargetSegments(targets, Segments);
  const targetables = getTargetables(targets, Targetables);
  const goalSegments = getGoalSegments(goals, Segments);

  const errors = validate(
    {
      account,
      billable,
      campaign,
      creatives,
      event,
      goals,
      goalSegments,
      integration,
      targetings: targets,
      targetingSegments,
    },
    user,
    flags.noMaxBudget,
  );

  // Converting observables back to vanilla JavaScript.
  const childModels: Model[] = [...creatives.slice(), ...goals.slice(), ...targets.slice()];
  const grandchildModels: Model[] = [...goalSegments, ...targetingSegments, ...targetables];

  function handleValidate(): ICampaignValidationErrors {
    return validate(
      {
        account,
        billable,
        campaign,
        creatives,
        event,
        goals,
        goalSegments,
        integration,
        targetings: targets,
        targetingSegments,
      },
      user,
      flags.noMaxBudget,
    );
  }

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          Previous
        </Button>,
        <SaveCampaignButton
          campaign={campaign}
          childModels={childModels}
          grandchildModels={grandchildModels}
          key={'save'}
          name={'publish_campaign'}
          shouldChangeState={true}
          validate={handleValidate}
        />,
      ]}
      className={styles.formRoot}
      description={
        <div className={styles.fieldset}>
          <p>
            Check the summary below to make sure everything is configured correctly. When you're
            ready, click Publish to launch your campaign!
          </p>
        </div>
      }
      label={'Edit Campaign: Review'}
    >
      <CampaignSummary campaign={campaign} validationErrors={errors} />
    </Form>
  );
}

export default observer(FacebookCampaignEditStepSeven);
