import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

export type TPartnerExportState = 'ready' | 'pending' | 'exporting' | 'failed' | undefined;

export interface IPartnerExport extends IBaseAttributes {
  name: string;
  event: string;
  campaign: string;
  export_url: string;
  export_fields: string[];
  created_by: string;
  date_created: string;
  state: TPartnerExportState;
  rows: number;
}

export class PartnerExport extends Model<IPartnerExport> {
  public readonly className = 'PartnerExport';

  public constraints: TConstraints<IPartnerExport> = {
    export_fields: {
      presence: {
        allowEmpty: false,
      },
      length: {
        minimum: 1,
        message: '^There must be at least one export field.',
      },
    },
    campaign: (...args: any[]) => {
      const model = args[3].model;
      if (model.get('export_fields', []).includes('referral_page_links')) {
        return { presence: { allowEmpty: false } };
      }
      return null;
    },
  };
}

export class PartnerExports extends Collection<PartnerExport> {
  public getModel(attributes: Partial<IPartnerExport>) {
    return new PartnerExport(attributes);
  }

  public getClassName() {
    return 'exports';
  }

  public url() {
    return `${this.getHostname()}partners/exports/`;
  }
}
