import debounce from 'debounce-promise';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { ISelectOption } from '@feathr/components';
import { AsyncSelect, toast } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';

interface IBaseProps {
  label?: ReactNode;
  placeholder?: string;
  displayLabel?: boolean;
}
interface ISingleProps extends IBaseProps {
  value?: string;
  values?: never;
  onChangeSingle?: (id: string) => void;
  onChangeMulti?: never;
}
interface IMultiProps extends IBaseProps {
  value?: never;
  values?: string[];
  onChangeSingle?: never;
  onChangeMulti?: (ids: string[]) => void;
}

function ProjectSelect(props: ISingleProps): JSX.Element;
function ProjectSelect(props: IMultiProps): JSX.Element;
function ProjectSelect({
  label,
  displayLabel = true,
  placeholder,
  value,
  values,
  onChangeSingle,
  onChangeMulti,
}: ISingleProps | IMultiProps): JSX.Element {
  const { t } = useTranslation();
  const { Events: Projects } = useContext(StoresContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<ISelectOption[]>([]);

  const debouncedProjectOptions = debounce(getProjectOptions, DEFAULT_DEBOUNCE_WAIT);

  async function getProjectOptions(inputValue?: string): Promise<ISelectOption[]> {
    setIsLoading(true);
    try {
      const params = {};
      if (inputValue && inputValue.length > 0) {
        params['filters'] = { name__icontains: inputValue };
      }
      const projects = Projects.list({
        filters: { name__icontains: inputValue },
        only: ['id', 'logo', 'name'],
      });
      await when(() => !projects.isPending);
      const projectOptions = projects.models.map(({ id, name }) => ({
        id,
        name,
      }));
      setOptions(projectOptions);
      return projectOptions;
    } catch (error) {
      toast(t('Failed to load projects: {{- error}}', { error }), { type: ToastType.ERROR });
      return [];
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSelectProject({ id }: ISelectOption): Promise<void> {
    onChangeSingle?.(id);
    if (id === undefined) {
      /*
       *  AsyncSelect makes us re-fetch the options when typing, selecting,
       * and then clearing the input.
       */
      const newOptions = await getProjectOptions();
      setOptions(newOptions);
    }
  }

  async function handleSelectProjects(values: ISelectOption[]): Promise<void> {
    onChangeMulti?.(values.map(({ id }) => id));
    if (values.length === 0) {
      /*
       *  AsyncSelect makes us re-fetch the options when typing, selecting,
       * and then clearing the input.
       */
      const newOptions = await getProjectOptions();
      setOptions(newOptions);
    }
  }

  function getOptionLabel({ name }: ISelectOption): string {
    return name;
  }

  function getOptionValue({ id }: ISelectOption): string {
    return id;
  }

  function getLabel(): ReactNode | null {
    if (!displayLabel) {
      return null;
    }
    if (label) {
      return label;
    }
    return !label && onChangeSingle ? t('Project') : t('Projects');
  }

  return (
    <AsyncSelect
      cacheOptions={true}
      defaultOptions={true}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isLoading={isLoading}
      isMulti={!!onChangeMulti}
      label={getLabel()}
      loadOptions={debouncedProjectOptions}
      name={'project-select'}
      onSelectMulti={onChangeMulti ? handleSelectProjects : undefined}
      onSelectSingle={onChangeSingle ? handleSelectProject : undefined}
      placeholder={placeholder ?? (onChangeSingle ? t('Select a project') : t('All projects'))}
      value={
        onChangeSingle
          ? options.find(({ id }) => id === value)
          : options.filter(({ id }) => values?.includes(id))
      }
    />
  );
}

export default observer(ProjectSelect);
