import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { Segment } from '@feathr/blackbox';

import * as styles from './SegmentOption.css';

export interface IMenuOption {
  id: string;
  name: string;
}

export type TOptionProps = OptionProps<Segment | IMenuOption>;

export type TStat = 'num_cookies_total' | 'num_crumbs_total' | 'num_emails_total';

interface IDataProps extends OptionProps<Segment | IMenuOption> {
  selectProps: {
    stat?: TStat;
  };
  data: Segment | IMenuOption;
}

function SegmentOption(props: TOptionProps) {
  const { t } = useTranslation();

  const {
    data,
    selectProps: { stat },
  } = props as IDataProps;

  const labels: Record<TStat, string> = {
    num_cookies_total: t('Reachable people'),
    num_crumbs_total: t('Breadcrumbs'),
    num_emails_total: t('Unique email addresses'),
  };

  return (
    <components.Option {...props}>
      <div className={styles.root}>
        {data.id === 'addSegment' && (
          <span className={styles.icon}>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        )}
        <span>{data.name}</span>
        {data.id !== 'addSegment' && (
          <dl>
            <dt>{t('People')}</dt>
            <dd>
              <Observer>
                {() => (
                  <>{numeral((data as Segment).get('stats').num_users_total || 0).format('0,0')}</>
                )}
              </Observer>
            </dd>
            {!!stat && (
              <>
                <dt>{labels[stat]}</dt>
                <dd>
                  <Observer>
                    {() => <>{numeral((data as Segment).get('stats')[stat] || 0).format('0,0')}</>}
                  </Observer>
                </dd>
              </>
            )}
          </dl>
        )}
      </div>
    </components.Option>
  );
}

export default SegmentOption;
