import { faCaretDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Client, PickerFileMetadata, PickerResponse } from 'filestack-js';
import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { BannersnackCreative, Campaign, DisplayCreative } from '@feathr/blackbox';
import { AdTagCreative, CampaignClass, CreativeClass } from '@feathr/blackbox';
import {
  Button,
  ButtonValid,
  Dropdown,
  Form,
  Input,
  Menu,
  MenuItem,
  toast,
} from '@feathr/components';
import CloneCreativeModal from '@feathr/extender/components/CloneCreativeModal';
import { StoresContext, useAccount } from '@feathr/extender/state';
import { moment, useToggle } from '@feathr/hooks';

import CreativesList from './CreativesList';

import * as styles from './CampaignEditStepFour.css';

import noImg300x250 from '@feathr/extender/images/no-img-300x250.png';

const defaultAdTag = `<ins style="display:inline-block;width:300px;height:250px;">
<a href="@FEATHR_CLICK@" target="_blank">
  <img alt="" src="${noImg300x250}" />
</a>
</ins>
@FEATHR_TRACK@`;

export interface IProps {
  onNext: () => void;
  onPrev: () => void;
  campaign: Campaign;
  creatives: IObservableArray<DisplayCreative>;
}

function validateAdTagCreative(creative: AdTagCreative): string[] {
  const errors: string[] = [];
  const adtagHTML = creative.get('adtag');
  if (!adtagHTML) {
    errors.push('Ad tag HTML cannot be empty.');
    return errors;
  }
  if (!adtagHTML.includes('@FEATHR_TRACK@')) {
    errors.push('Ad tag HTML must include the @FEATHR_TRACK@ impression tracking macro.');
  }
  if (!(adtagHTML.includes('@FEATHR_CLICK@') || adtagHTML.includes('@FEATHR_CLICK_ESC@'))) {
    errors.push(
      'Ad tag HTML must includes a click-through tracking macro: @FEATHR_CLICK@ or @FEATHR_CLICK_ESC@.',
    );
  }
  if (adtagHTML === defaultAdTag) {
    errors.push('Ad tag HTML must be different than the default HTML');
  }
  return errors;
}

export function validateStepFour(
  campaign: Campaign,
  creatives: IObservableArray<DisplayCreative>,
): string[] {
  if (!campaign.isValid(['destination_url'], false)) {
    return campaign.validate(['destination_url'], false).errors;
  }
  if (creatives.filter((t) => t.get('is_enabled')).length < 1) {
    return ['You need at least one enabled creative.'];
  }
  if (creatives.filter((t) => !t.get('is_archived')).length < 1) {
    return ['You need at least one creative.'];
  }
  return creatives
    .map((crv) => {
      let errors: string[] = toJS(
        crv.validate(['width', 'height', 'spec', 'destination_url', 'name'], false).errors,
      );
      const validDimensions = crv.getValidDimensions(campaign);
      const specs = Object.values(validDimensions).map((v) => v.spec);
      const creativeIsValid = specs.includes(crv.get('spec'));
      if (!creativeIsValid) {
        errors.push(
          `${crv.get('width')}x${crv.get('height')} is not a valid size for this creative type.`,
        );
      }
      if (
        crv instanceof AdTagCreative &&
        (crv as AdTagCreative).get('_cls') === CreativeClass.DisplayAdTag
      ) {
        errors = errors.concat(validateAdTagCreative(crv as AdTagCreative));
      }

      if (errors.length) {
        return errors[0];
      }
      return undefined;
    })
    .filter((msg) => !!msg) as string[];
}

const NextStepButton = observer(({ campaign, creatives, onNext }: Omit<IProps, 'onPrev'>) => {
  const validationErrors = validateStepFour(campaign, creatives);
  return (
    <ButtonValid errors={validationErrors} onClick={onNext}>
      Next
    </ButtonValid>
  );
});

interface IAddCreativeButtonProps {
  campaign: Campaign;
  creatives: IObservableArray<DisplayCreative>;
  remainingBanners?: number;
}

const AddMediaCreativeButton = observer(({ creatives, campaign }: IAddCreativeButtonProps) => {
  const account = useAccount();
  const { Creatives } = React.useContext(StoresContext);

  function setCreatives(client: Client) {
    return (files: PickerResponse): void => {
      const { filesUploaded } = files;
      filesUploaded.forEach(async (file) => {
        const { key, container, handle } = file;
        const url = `https://s3.amazonaws.com/${container}/${encodeURIComponent(key!)}`;
        const metadata = await client.metadata(handle, {
          width: true,
          height: true,
          mimetype: true,
          size: true,
          filename: true,
        });
        let cls = CreativeClass.DisplayImage;
        let { width, height } = metadata;
        const { mimetype, filename } = metadata;
        if (mimetype.includes('video')) {
          const videoDimensions = await getVideoDimensions(url);
          width = videoDimensions.width;
          height = videoDimensions.height;
          cls = CreativeClass.DisplayVideo;
        }
        const creative = Creatives.create({
          width,
          height,
          mimetype,
          url,
          _cls: cls,
          parent: campaign.id,
          name: filename,
          is_enabled: true,
          account: account.id,
        }) as DisplayCreative;
        const dimensionDefinition = creative.getSpec();
        if (dimensionDefinition) {
          creative.set({ spec: dimensionDefinition.spec });
        }
        const validDimensions = creative.getValidDimensions(campaign);
        const specs = Object.values(validDimensions).map((v) => v.spec);
        const creativeIsValidDimensions = specs.includes(creative.get('spec'));
        //  file.filename is available for local uploads - file.originalPath is available for web uploads
        const determineFileName = (file: PickerFileMetadata): string => {
          return file.filename || file.originalPath || '';
        };
        const lowerCasedFileName = determineFileName(file).toLowerCase();
        const creativeIsM4v = lowerCasedFileName.endsWith('.m4v');
        if (!creativeIsValidDimensions) {
          const closest = creative.closestValidDimension(validDimensions);
          toast(
            `"${filename}" is the wrong size (${width}x${height}). The closest valid size would be ${closest}`,
            { type: 'error' },
          );
        } else if (creativeIsM4v) {
          toast(
            'Sorry, .m4v files are not supported. Supported filetypes are .jpeg, .png, .gif, .mp4, or .webm',
            {
              type: 'error',
            },
          );
        } else {
          const reponse = (await Creatives.add(creative)) as DisplayCreative;
          runInAction(() => {
            creatives.push(reponse);
          });
        }
      });
    };
  }

  async function addMediaCreative(): Promise<void> {
    const filestackJs = await import(/* webpackChunkName: "filestack-js" */ 'filestack-js');
    const client = filestackJs.init(FILESTACK_API_KEY);
    await client
      .picker({
        maxFiles: 10,
        maxSize: 200 * 1024 * 1024,
        accept: ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/webm'],
        onUploadDone: setCreatives(client),
        transformations: {
          crop: false,
          circle: false,
          rotate: false,
        },
        dropPane: {},
      })
      .open();
  }

  return (
    <MenuItem id={'addMedia'} onClick={addMediaCreative}>
      Add Image/Video Creative
    </MenuItem>
  );
});

const AddAdTagCreativeButton = observer(({ creatives, campaign }: IAddCreativeButtonProps) => {
  const account = useAccount();
  const { Creatives } = React.useContext(StoresContext);

  async function addAdTagCreative(): Promise<void> {
    const adtags = creatives.filter((c) => c.get('_cls') === CreativeClass.DisplayAdTag).length;
    const creative = Creatives.create({
      mimetype: 'text/html',
      _cls: CreativeClass.DisplayAdTag,
      adtag: defaultAdTag,
      spec: 'medium_rectangle',
      width: 300,
      height: 250,
      parent: campaign.id,
      is_enabled: true,
      account: account.id,
      name: adtags > 0 ? `Untitled Ad tag (${adtags})` : 'Untitled Ad tag',
    }) as AdTagCreative;
    const reponse = (await Creatives.add(creative)) as AdTagCreative;
    runInAction(() => {
      creatives.push(reponse);
    });
  }

  return (
    <MenuItem id={'addAdtag'} onClick={addAdTagCreative}>
      Add Ad tag Creative
    </MenuItem>
  );
});

const AddBannersnackCreativeButton = observer(
  ({ creatives, campaign, remainingBanners }: IAddCreativeButtonProps) => {
    const account = useAccount();
    const { Creatives } = React.useContext(StoresContext);

    async function addBannersnackCreative(): Promise<void> {
      const bannersnacks = creatives.filter(
        (c) => c.get('_cls') === CreativeClass.DisplayBannersnack,
      ).length;
      const creative = Creatives.create({
        mimetype: 'text/html',
        _cls: CreativeClass.DisplayBannersnack,
        spec: 'medium_rectangle',
        width: 300,
        height: 250,
        parent: campaign.id,
        is_enabled: true,
        account: account.id,
        name: bannersnacks > 0 ? `Untitled Banner (${bannersnacks})` : 'Untitled Banner Builder',
      }) as BannersnackCreative;
      const response = (await Creatives.add(creative)) as BannersnackCreative;
      creatives.push(response);
    }

    return (
      <MenuItem
        className={styles.divider}
        id={'addBannersnack'}
        onClick={addBannersnackCreative}
        type={creatives.length >= 1 ? 'secondary' : 'primary'}
      >
        Build creative
        {!!(remainingBanners && remainingBanners <= 10) &&
          ` (Remaining for this month: ${remainingBanners})`}
      </MenuItem>
    );
  },
);

async function getVideoDimensions(url: string): Promise<{ width: number; height: number }> {
  return new Promise<{ width: number; height: number }>((resolve) => {
    const video = document.createElement('video');
    video.addEventListener('loadedmetadata', () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
      });
    });
    video.src = url;
  });
}

function CampaignEditStepFour({ campaign, creatives, onNext, onPrev }: IProps): JSX.Element {
  const { Creatives } = useContext(StoresContext);
  const [showCloneCreative, toggleCloneCreative] = useToggle(false);
  const { t } = useTranslation();
  const account = useAccount();
  const isMobileCampaign = [CampaignClass.MobileGeoFencing].includes(campaign.get('_cls'));
  const existingBanners = Creatives.list({
    filters: {
      _cls: CreativeClass.DisplayBannersnack,
      date_created__gte: moment.utc().startOf('month').toISOString(),
      date_created__lte: moment.utc().endOf('month').toISOString(),
      is_archived__ne: true,
      _parent__exists: true,
    },
    pagination: { page_size: 1 },
  });
  const remainingBanners =
    !!account && !existingBanners.isPending && !account.isFalcon
      ? 10 - existingBanners.pagination.count
      : Infinity;

  const addCreativeMenu = (
    <Menu>
      <AddMediaCreativeButton campaign={campaign} creatives={creatives} key={'media'} />
      {!isMobileCampaign && (
        <AddAdTagCreativeButton campaign={campaign} creatives={creatives} key={'adtag'} />
      )}
      {!isMobileCampaign && remainingBanners > 0 && (
        <AddBannersnackCreativeButton
          campaign={campaign}
          creatives={creatives}
          key={'bannersnack'}
          remainingBanners={remainingBanners}
        />
      )}
      <MenuItem className={styles.divider} onClick={toggleCloneCreative}>
        Clone existing creative
      </MenuItem>
    </Menu>
  );

  function handleCloneCreative(creative: DisplayCreative): void {
    creatives.push(creative as DisplayCreative);
  }

  return (
    <>
      <Form
        actions={[
          <Button key={'prev'} onClick={onPrev}>
            Previous
          </Button>,
          <Dropdown content={addCreativeMenu} key={'addDropdown'}>
            <Button
              id={'add'}
              suffix={<FontAwesomeIcon icon={faCaretDown} />}
              type={creatives.length >= 1 ? 'secondary' : 'primary'}
            >
              Add creative
            </Button>
          </Dropdown>,
          <NextStepButton campaign={campaign} creatives={creatives} key={'next'} onNext={onNext} />,
        ]}
        description={
          <>
            <p>Upload and configure the ads this campaign will show to its Targets.</p>
            {campaign.get('_cls') === CampaignClass.MobileGeoFencing ? (
              <p>
                Because Geofencing campaigns act exclusively on mobile inventory, we require that
                you include only mobile-friendly ad formats to ensure performance: 300x50, 300x250,
                320x50, 336x280, 320x480.
              </p>
            ) : (
              <p>
                We recommend that you include at least three Creatives, one of each of the most
                popular ad formats: 300x250 (Medium Rectangle), 160x600 (Wide Skyscraper) and 728x90
                (Leaderboard).{' '}
                <a
                  href={
                    'https://help.feathr.co/hc/en-us/articles/360036748934-Ad-Creative-Specifications-'
                  }
                  rel={'noreferrer'}
                  target={'_blank'}
                >
                  See full Ad Creative Specs.
                </a>
              </p>
            )}
            <p>
              Each creative will redirect to the provided Destination URL, unless you specify a
              different URL on the creative itself.
            </p>
          </>
        }
        label={'Edit Campaign: Creative'}
      >
        <Input
          attribute={'destination_url'}
          label={'Destination URL'}
          model={campaign}
          placeholder={'https://example.com/landingpage?utm_campaign=feathr'}
          required={true}
          type={'url'}
        />
        <Input
          attribute={'alt_text'}
          helpText={t(
            'Please provide advertisement text with a clear call-to-action to display in the event the image cannot be loaded and for visually-impaired individuals who may use a screen reader. (Text provided here will be overriden by alternate text provided on the creative.)',
          )}
          label={'Alternate text'}
          model={campaign}
          optional={true}
          placeholder={'Advertisement: Like bees? Click here to find out about Beecon 2023!'}
          type={'text'}
        />
        <CreativesList campaign={campaign} creatives={creatives} />
      </Form>
      {showCloneCreative && (
        <CloneCreativeModal
          campaign={campaign}
          eventId={campaign.get('event')}
          onClose={toggleCloneCreative}
          onConfirm={handleCloneCreative}
          remainingBanners={remainingBanners}
        />
      )}
    </>
  );
}

export default observer(CampaignEditStepFour);
