import classNames from 'classnames';
import { get, set } from 'mobx';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { IParticipation, Partners } from '@feathr/blackbox';
import { Campaign, PinpointPartnerMessage } from '@feathr/blackbox';
import { Fieldset, Select } from '@feathr/components';
import type { Model } from '@feathr/rachis';

import Recipients from './Recipients';
import type { IParticipationWithCampaign } from './Recipients/Recipients';

import * as styles from './RecipientsPicker.css';

interface IProps<T extends Model<IParticipation | IParticipationWithCampaign>> {
  className?: string;
  disabled?: boolean;
  model: T;
  partnersCollection?: Partners;
}

function RecipientsPicker<T extends Model<IParticipation | IParticipationWithCampaign>>({
  className,
  disabled = false,
  model,
  partnersCollection,
}: IProps<T>): JSX.Element {
  const options = [
    {
      id: 'event',
      name:
        model instanceof Campaign ? 'Include all project partners' : 'Send to all project partners',
    },
    { id: 'manual', name: 'Select partners' },
  ];
  if (model instanceof PinpointPartnerMessage && model.get('invites_campaign')) {
    options.splice(0, 1, { id: 'campaign', name: 'Send to all campaign partners' });
  }

  return (
    <Observer>
      {(): JSX.Element => {
        const participation = model.get('participation');
        return (
          <>
            <Fieldset className={classNames(styles.mode, className)}>
              <Select
                disabled={disabled}
                label={'Mode'}
                name={'audience_mode'}
                onSelectSingle={(opt) => {
                  set(participation, 'mode', opt.id);
                  model.setAttributeDirty('participation');
                }}
                options={options}
                value={options.find((opt) => opt.id === get(participation, 'mode'))}
              />
            </Fieldset>
            {get(participation, 'mode') === 'manual' && (
              <Recipients
                disabled={disabled}
                model={model}
                partnersCollection={partnersCollection}
              />
            )}
          </>
        );
      }}
    </Observer>
  );
}

export default RecipientsPicker;
