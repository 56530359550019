import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useContext, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { IImporter, ReferralCampaign } from '@feathr/blackbox';
import { Partners } from '@feathr/blackbox';
import { Button, Form } from '@feathr/components';
import ConfirmButton from '@feathr/extender/components/ConfirmButton';
import RecipientsPicker from '@feathr/extender/components/RecipientsPicker';
import { StoresContext, useAccount, useLocalUrl, useUser } from '@feathr/extender/state';
import { logUserEvents } from '@feathr/hooks';

import ImportersAlert from './ImportersAlert';

interface IProps {
  campaign: ReferralCampaign;
  onNext: () => void;
  onPrev: () => void;
}

function StepTwo({ campaign, onNext, onPrev }: IProps): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const user = useUser();

  const localUrl = useLocalUrl();
  const { Importers } = useContext(StoresContext);
  const eventId = campaign.get('event');
  const partnersCollection = useRef(new Partners([])).current;

  const createImport = useCallback(async () => {
    // Don't catch any errors; let them bubble up.
    const importerAttrs: Record<keyof IImporter, string> = {
      event: eventId,
      created_by: user.id,
      kind: 'partner',
      state: 'draft',
    };

    const importer = Importers.create(importerAttrs);
    await Importers.add(importer, { validate: false });
    logUserEvents({
      'Started partner import': {
        account_id: account.id,
        importer_id: importer.id,
      },
    });
    return localUrl(importer.getItemUrl());
  }, [Importers, account.id, user, eventId, localUrl]);

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <Button key={'next'} onClick={onNext} type={'primary'}>
          {t('Next')}
        </Button>,
      ]}
      description={
        <>
          <Trans t={t}>
            <p>
              Choose which Partners will participate in this campaign. You can skip this step to
              include all partners for this project as participants in the campaign.
            </p>
            <p>
              If you don't have a complete list of partners ready right now, don't worry. You can
              come back and revise your participant list later.
            </p>
          </Trans>
          <ImportersAlert eventId={eventId} partnersCollection={partnersCollection} />
        </>
      }
      label={t('Edit Campaign: Partners')}
    >
      <RecipientsPicker model={campaign} partnersCollection={partnersCollection} />
      <ConfirmButton
        description={
          <p>
            {t('Before you can import partners, the changes to your campaign need to be saved.')}
          </p>
        }
        model={campaign}
        prefix={<FontAwesomeIcon icon={faPlus} />}
        redirect={createImport}
        step={2}
      >
        {t('Import partners')}
      </ConfirmButton>
    </Form>
  );
}

export default observer(StepTwo);
