import type { JSX } from 'react';
import React, { useContext } from 'react';

import type { Template } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import { Popover, Suggestions } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

interface IProps {
  input: JSX.Element;
  path: string;
  template: Template;
}

function SuggestMergeTags({ template, input, path }: IProps) {
  const { CustomFields } = useContext(StoresContext);
  const customFields =
    template.get('_cls') === TemplateClass.LandingPage
      ? CustomFields.newListResponse()
      : CustomFields.list({
          filters: { collection: 'Partner' },
          pagination: {
            page: 0,
            page_size: 1000,
          },
        });
  const mergefields = template.getMergefields(customFields.models);
  const mergeTags = mergefields
    .filter((field) => field.type === 'tag')
    .map((field) => ({ label: field.name, value: field.value }));
  const getOnClickMergeTag = (pathString: string) => {
    const pathComponents = pathString.split('.');
    let setValue = {};
    return (value: string) => {
      if (pathComponents.length === 2) {
        const nested = template.get(pathComponents[0]);
        const current = nested[pathComponents[1]] || '';
        setValue = { [pathComponents[0]]: { ...nested, [pathComponents[1]]: current + value } };
      } else {
        const current = template.get(pathComponents[0], '') || '';
        setValue = { [pathComponents[0]]: current + value };
      }
      template.set(setValue);
    };
  };
  return (
    <Popover align={'start'} arrow={false} position={'bottom'} toggle={'onFocus'}>
      <span>{input}</span>
      <Suggestions
        isLoading={false}
        onClickSuggestion={getOnClickMergeTag(path)}
        suggestions={mergeTags}
      />
    </Popover>
  );
}

export default SuggestMergeTags;
