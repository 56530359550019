import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ISegment, Segment } from '@feathr/blackbox';
import { SegmentStatHelper } from '@feathr/blackbox';
import { DetailedProgressBar } from '@feathr/components';

interface IProps {
  segment: Segment;
  mode: 'count' | 'crumbs' | 'reachable' | 'emails';
}

function SegmentGauge({ segment, mode }: Readonly<IProps>): JSX.Element {
  const statHelper = {
    lookback_mode: 'unbounded',
    lookback_value: 0,
    name: 'full segment',
    mode: 'match_any',
    predicates: [],
    account: '',
    is_custom: false,
  } as Partial<ISegment>;

  if (segment.get('parent_segment')) {
    statHelper['parent_segment'] = segment.get('parent_segment');
  }
  const [fullStatHelper] = useState(
    new SegmentStatHelper(observable<Partial<ISegment>>(statHelper)),
  );
  const { t } = useTranslation();

  const count = segment[mode] ?? 0;
  const fullCount = fullStatHelper[mode];

  useEffect(() => {
    if (count > fullCount) {
      // Force @compute to recompute, due to a new array being assigned to observable.
      runInAction(() => {
        fullStatHelper.segment.predicates = [];
      });
    }
  }, [count, fullCount, fullStatHelper.segment]);

  const descriptionMap = {
    start: {
      count: t('Person', { count }),
      reachable: t('Reachable person', { count }),
      crumbs: t('Breadcrumb', { count }),
      emails: t('Unique email address', { count }),
    },
    end: {
      count: t('Total people'),
      reachable: t('Total people'),
      crumbs: t('Total breadcrumbs'),
      emails: t('Total emails'),
    },
  };

  const isLoading = fullStatHelper.countPromise.state === 'pending';

  return (
    <DetailedProgressBar
      endLabel={descriptionMap.end[mode]}
      endValue={fullCount}
      isLoading={isLoading}
      startLabel={descriptionMap.start[mode]}
      startValue={count}
    />
  );
}

export default observer(SegmentGauge);
