import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ImisIntegration } from '@feathr/blackbox';
import { Step, Steps, Wizard } from '@feathr/components';
import { useReactionEffect } from '@feathr/hooks';

import ImisConfigStepFive from './ImisConfigStepFive';
import ImisConfigStepFour from './ImisConfigStepFour';
import ImisConfigStepOne from './ImisConfigStepOne';
import ImisConfigStepThree from './ImisConfigStepThree';
import ImisConfigStepTwo from './ImisConfigStepTwo';

import * as styles from './ImisConfiguration.css';

interface IProps {
  integration: ImisIntegration;
}

export type TConfigurationContext = 'wizard' | 'settings';

function ImisConfiguration({ integration }: IProps): React.JSX.Element {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [completeStep, setCompleteStep] = useState<number>(0);
  const steps = (
    <Steps completed={completeStep} current={currentStep} onChange={setCurrentStep}>
      <Step key={1} stepIndex={0} title={t('Set Organization')} />
      <Step key={2} stepIndex={1} title={t('Verify User')} />
      <Step key={3} stepIndex={2} title={t('Set Up Business Object')} />
      <Step key={4} stepIndex={3} title={t('Set Up IQA File')} />
      <Step key={5} stepIndex={4} title={t('Communication Preferences')} />
    </Steps>
  );

  function onNext(): void {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    if (completeStep === undefined || completeStep < nextStep) {
      setCompleteStep(nextStep);
    }
  }

  function onPrev(): void {
    setCurrentStep(currentStep - 1);
  }

  useReactionEffect(
    () => integration.get('org_site') !== undefined && !integration.isDirty,
    () => setCurrentStep(1),
    { once: true },
  );

  return (
    <Wizard layout={'horizontal'} steps={steps}>
      <div className={styles.root}>
        {currentStep === 0 && <ImisConfigStepOne integration={integration} onNext={onNext} />}
        {currentStep === 1 && (
          <ImisConfigStepTwo integration={integration} onNext={onNext} onPrev={onPrev} />
        )}
        {currentStep === 2 && (
          <ImisConfigStepThree integration={integration} onNext={onNext} onPrev={onPrev} />
        )}
        {currentStep === 3 && (
          <ImisConfigStepFour integration={integration} onNext={onNext} onPrev={onPrev} />
        )}
        {currentStep === 4 && <ImisConfigStepFive integration={integration} onPrev={onPrev} />}
      </div>
    </Wizard>
  );
}

export default ImisConfiguration;
