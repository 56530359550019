import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useClipboard } from 'use-clipboard-copy';

import { Card, toast } from '@feathr/components';

import * as styles from './AchInfo.css';

function AchInfo(): JSX.Element {
  const clipboard = useClipboard({
    onSuccess: () => {
      toast('Successfully copied to clipboard', { type: 'success' });
    },
    onError: () => {
      toast("Couldn't copy to clipboard. Please try again.", { type: 'error' });
    },
  });

  return (
    <Card>
      <table className={styles.root}>
        <tbody>
          <tr>
            <th className={styles.key}>Beneficiary's name</th>
            <th className={styles.key}>Beneficiary's bank</th>
            <th className={styles.key}>Account number</th>
          </tr>
          <tr>
            <td>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faCopy}
                onClick={() => clipboard.copy('Feathr, Inc.')}
              />
              Feathr, Inc.
            </td>
            <td>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faCopy}
                onClick={() => clipboard.copy('Western Alliance Bank')}
              />
              Western Alliance Bank
            </td>
            <td>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faCopy}
                onClick={() => clipboard.copy('8192549606')}
              />
              8192549606
            </td>
          </tr>
          <tr>
            <td className={styles.key}>Beneficiary's address (ACH only)</td>
            <td className={styles.key}>Beneficiary's bank address</td>
            <td className={styles.key}>Routing number</td>
          </tr>
          <tr>
            <td>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faCopy}
                onClick={() =>
                  clipboard.copy('104 N. Main Street, Suite 600, Gainesville, FL 32601')
                }
              />
              <span className={styles.value}>
                104 N. Main Street, Suite 600, Gainesville, FL 32601
              </span>
            </td>
            <td>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faCopy}
                onClick={() =>
                  clipboard.copy('Six Concourse Parkway, Suite 2130, Atlanta, GA 30328')
                }
              />
              <span className={styles.value}>
                Six Concourse Parkway, Suite 2130, Atlanta, GA 30328
              </span>
            </td>
            <td>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faCopy}
                onClick={() => clipboard.copy('121143260')}
              />
              <span className={styles.value}>121143260</span>
            </td>
          </tr>
          <tr>
            <td className={styles.key}>Check payment address</td>
            <td></td>
            <td className={styles.key}>Swift code</td>
          </tr>
          <tr>
            <td>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faCopy}
                onClick={() => clipboard.copy('Feathr Inc., PO BOX 628915, Orlando, FL 32862-8915')}
              />
              <span className={styles.value}>PO BOX 628915, Orlando, FL 32862-8915</span>
            </td>
            <td></td>
            <td>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faCopy}
                onClick={() => clipboard.copy('BBFXUS6S')}
              />
              <span className={styles.value}>BBFXUS6S</span>
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
}

export default AchInfo;
