import { when } from 'mobx';
import { useCallback, useContext, useEffect, useState } from 'react';

import type { IAggregatedStats, IDailyStats } from '@feathr/blackbox';
import type { TTimeFrameValue } from '@feathr/components';

import { StoresContext } from '../state';

interface IProps {
  timeFrameValue: TTimeFrameValue;
  chartData?: IDailyStats[];
}

interface IGoalLabels {
  goalLabelA?: string;
  goalLabelB?: string;
  goalLabelC?: string;
}

interface IUseDashboardStatsReturn {
  /** The aggregated stats for the account */
  aggregatedStats?: IAggregatedStats;
  /** A metric that evaluates the success in acquiring new audience members and expanding reach. */
  community: number;
  /** A metric that measures the success in terms of deepening the engagement with existing audiences. */
  connections: number;
  /** A metric that measures the impact in terms of driving the actions and conversions that advance the organization’s mission. */
  conversions: number;
  /** Whether or not the data is loading. */
  isLoading: boolean;
  /** The chart data with the top 3 goals' stats added to it */
  chartDataWithGoals: IDailyStats[] | undefined;
  /** The labels for the top 3 goals */
  goalLabels?: IGoalLabels;
}

function useDashboardAggregatedStats({
  timeFrameValue,
  chartData,
}: IProps): IUseDashboardStatsReturn {
  const { AggregatedStats, Stats } = useContext(StoresContext);
  const [aggregatedStats, setAggregatedStats] = useState<IAggregatedStats>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const filters = {
    metadata__date__gte: timeFrameValue[0],
    metadata__date__lte: timeFrameValue[1],
  };

  // Section I: Aggregated Stats
  useEffect(() => {
    const fetchStats = async (): Promise<void> => {
      setIsLoading(true);
      const stats = AggregatedStats.aggregate({
        filters,
        model: 'account',
      });
      await when(() => !stats.isPending);
      setAggregatedStats(stats.toJS());
      setIsLoading(false);
    };
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrameValue]);

  // Sum the community, connections, and conversions stats
  const audienceEngagementStats = {
    community: aggregatedStats?.num_persons_created ?? 0,
    connections:
      (aggregatedStats?.num_persons_with_ad_clicks ?? 0) +
      (aggregatedStats?.num_persons_with_ad_views ?? 0) +
      (aggregatedStats?.num_persons_with_page_link_clicks ?? 0) +
      (aggregatedStats?.num_persons_with_page_views ?? 0) +
      (aggregatedStats?.num_email_clicks ?? 0) +
      (aggregatedStats?.num_email_opens ?? 0),
    conversions: aggregatedStats?.num_persons_converted ?? 0,
  };

  // Section II: Top Goals
  const topGoals = aggregatedStats?.top_goals ?? [];
  const topGoalsIds = topGoals.map((goal) => goal.goal_id);

  const [goalDataA, setGoalDataA] = useState<IDailyStats[]>();
  const [goalDataB, setGoalDataB] = useState<IDailyStats[]>();
  const [goalDataC, setGoalDataC] = useState<IDailyStats[]>();

  // Callback function to fetch top goal stats
  const fetchTopGoalStats = useCallback(
    async (goalId: string) => {
      const stats = Stats.list({
        model: 'goal',
        filters: {
          ...filters,
          metadata__obj_id: goalId,
        },
      });
      await when(() => !stats.isPending);
      return stats.models.map((s) => s.toJS());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeFrameValue],
  );

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (topGoalsIds.length > 0 && chartData) {
        setIsLoading(true);
        try {
          // Fetch the top 3 goals' stats concurrently
          const [dataA, dataB, dataC] = await Promise.all(
            topGoalsIds.slice(0, 3).map(fetchTopGoalStats),
          );
          setGoalDataA(dataA || []);
          setGoalDataB(dataB || []);
          setGoalDataC(dataC || []);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching top goal stats:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTopGoalStats, chartData, topGoalsIds.length]);

  // Iterate over the chart data and add the goal data to it. Rename the goal data keys to match the chart data keys.
  const chartDataWithGoals = chartData?.map((data) => {
    const dataPointA = goalDataA?.find((goal) => goal.metadata.date === data.metadata.date);
    const dataPointB = goalDataB?.find((goal) => goal.metadata.date === data.metadata.date);
    const dataPointC = goalDataC?.find((goal) => goal.metadata.date === data.metadata.date);

    return {
      ...data,
      num_conversions_goal_a: dataPointA?.num_conversions || 0,
      num_conversions_goal_b: dataPointB?.num_conversions || 0,
      num_conversions_goal_c: dataPointC?.num_conversions || 0,
    };
  });

  const { community, connections, conversions } = audienceEngagementStats;

  // If goal data has a num_conversions key, then there are conversions. If so, return the goal's name.
  function getGoalLabel(goalData?: IDailyStats[]): string | undefined {
    return goalData?.some((data) => 'num_conversions' in data)
      ? goalData?.[0]?.metadata.name
      : undefined;
  }

  const goalLabels = {
    goalLabelA: getGoalLabel(goalDataA),
    goalLabelB: getGoalLabel(goalDataB),
    goalLabelC: getGoalLabel(goalDataC),
  };

  return {
    aggregatedStats,
    chartDataWithGoals,
    community,
    connections,
    conversions,
    isLoading,
    goalLabels,
  };
}

export default useDashboardAggregatedStats;
