import { faPlus, faRemove } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { GoogleAdsSmartCampaign, IGoogleAdsKeyword } from '@feathr/blackbox';
import { Button, Input, Label, Spinner } from '@feathr/components';
import { validate } from '@feathr/rachis';

import KeywordButton from '../KeywordButton';

import * as styles from './KeywordsChooser.css';

interface IKeywordsChooserProps {
  campaign: GoogleAdsSmartCampaign;
  label: string;
  isNegative: boolean;
  name: string;
  onAdd: (customKeyword: string, isNegative: boolean) => void;
  onRemove: (keyword: IGoogleAdsKeyword, isNegative: boolean) => void;
  placeholder?: string;
  selectedKeywords: IGoogleAdsKeyword[];
}

function KeywordsChooser({
  campaign,
  label,
  isNegative,
  name,
  onAdd,
  onRemove,
  placeholder,
  selectedKeywords,
}: Readonly<IKeywordsChooserProps>): JSX.Element {
  const { t } = useTranslation();
  const [customKeyword, setCustomKeyword] = useState<string>('');

  const filteredKeywords = selectedKeywords.filter((keyword) => keyword.negative === isNegative);

  function handleOnChangeCustomKeyword(value?: string): void {
    // If there's no value provide an empty string the user can erase the input
    setCustomKeyword(value ?? '');
  }

  function handleAddKeyword(): void {
    onAdd(customKeyword, isNegative);
    setCustomKeyword('');
  }

  function handleRemoveKeyword(keyword: IGoogleAdsKeyword): void {
    onRemove(keyword, isNegative);
  }

  const selectedKeywordButtons = filteredKeywords.length ? (
    filteredKeywords.map((keyword) => (
      <KeywordButton
        key={keyword.resource_name ?? keyword.display_name}
        keyword={keyword}
        onClick={handleRemoveKeyword}
        suffix={<FontAwesomeIcon icon={faRemove} />}
      />
    ))
  ) : (
    <span>{t('No keywords selected')}</span>
  );

  function validateKeyword(): string | undefined {
    const errors: Array<string | undefined> = validate.single(customKeyword, {
      presence: {
        allowEmpty: false,
        message: t("Can't be blank"),
      },
      format: {
        // Allow letters, numbers, spaces, and hyphens
        pattern: /^[a-zA-Z0-9 -]{3,}$/,
        message: t('Enter short words or phrases without punctuation'),
      },
    });
    return errors ? errors[0] : undefined;
  }

  return (
    <>
      <div>
        <Label>{label}</Label>
        <div data-name={name}>{campaign.isPending ? <Spinner /> : selectedKeywordButtons}</div>
      </div>
      <div className={styles.customKeywordInputWrapper}>
        <Input
          aria-label={'Add custom keyword'}
          className={styles.input}
          data-name={`${name}-custom_keyword`}
          onChange={handleOnChangeCustomKeyword}
          placeholder={placeholder ?? t('Keyword theme')}
          // Only show the error if the user has started typing
          validationError={customKeyword ? validateKeyword() : undefined}
          value={customKeyword}
          wrapperClassName={styles.inputWrapper}
        />
        <Button
          disabled={!!validateKeyword()}
          name={`${name}-add_keyword_theme`}
          onClick={handleAddKeyword}
          prefix={<FontAwesomeIcon icon={faPlus} />}
          tooltip={validateKeyword()}
          type={'icon-outlined'}
        >
          {t('Add')}
        </Button>
      </div>
    </>
  );
}

export default observer(KeywordsChooser);
