import type { WithT } from 'i18next';
import type { IObservableArray } from 'mobx';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Domain } from '@feathr/blackbox';
import { ButtonValid, Fieldset, Form, Input, toast } from '@feathr/components';
import { flattenError, flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface IProps {
  disabled?: boolean;
  domain: Domain;
  onNext: () => void;
  setCompleteStep: (step: number) => void;
  setMatchingDomains: (domains: IObservableArray<Domain>) => void;
}

interface IButtonProps extends WithT {
  domain: Domain;
  onNext: () => void;
}

interface IErrors extends TValidateGrouped {
  email_domain?: string[];
}

export function validateStepOne(domain: Domain): IErrors {
  return domain.validate<IErrors>(['email_domain'], false, 'grouped').errors;
}

const NextStepButton = observer(({ domain, onNext, t }: IButtonProps): JSX.Element => {
  const validationErrors = validateStepOne(domain);
  const errors = flattenErrors(validationErrors);
  return (
    <ButtonValid errors={domain.isEphemeral ? errors : []} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function DomainEditStepOne({
  disabled = false,
  domain,
  onNext,
  setCompleteStep,
  setMatchingDomains,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const validationErrors = validateStepOne(domain);

  async function stepOneNext(): Promise<void> {
    // Reset matching domains to empty.
    setMatchingDomains(observable([]));

    if (!domain.get('content_domain')) {
      domain.set({ content_domain: `fthr-content.${domain.get('email_domain')}` });
    }

    if (!domain.get('mail_from')) {
      domain.set({ mail_from: `feathrmail.${domain.get('email_domain')}` });
    }

    try {
      const response = await domain.getMatchingDomains();
      const matchingDomains = response.models;
      if (matchingDomains.length) {
        setMatchingDomains(matchingDomains);
        domain.set({ mail_from: matchingDomains[0].get('mail_from') });
      }
      onNext();
    } catch (error) {
      toast(
        t(
          'There was a problem creating your domain. Please try again. If this error persists, please contact support.',
        ),
        { type: ToastType.ERROR },
      );
    }
  }

  function handleDomainChange(value?: string): void {
    const emailDomain = value ?? '';
    domain.set({ email_domain: emailDomain });
    const contentDomain = domain.get('content_domain')
      ? domain.get('content_domain').split('.')[0]
      : undefined;
    if (contentDomain) {
      domain.set({ content_domain: `${contentDomain}.${emailDomain}` });
    }
    const mailFrom = domain.get('mail_from') ? domain.get('mail_from').split('.')[0] : undefined;
    if (mailFrom) {
      domain.set({ mail_from: `${mailFrom}.${emailDomain}` });
    }
    setCompleteStep(0);
  }

  return (
    <Form
      actions={[<NextStepButton domain={domain} key={'next'} onNext={stepOneNext} t={t} />]}
      description={t('Add a new domain to use with Feathr.')}
      label={t('Edit Domain: Info')}
    >
      <Fieldset>
        <Input
          disabled={disabled}
          helpText={
            <Trans t={t}>
              <p>
                Set up the domain you want to use by entering the root domain, i.e.{' '}
                <strong>domain.com</strong>. Do not enter the full domain, i.e.{' '}
                <strong>https://www.domain.com</strong>, or a subdomain, i.e.{' '}
                <strong>content.domain.com</strong>.
              </p>
            </Trans>
          }
          label={t('Domain')}
          onChange={handleDomainChange}
          required={true}
          type={'text'}
          validationError={flattenError(validationErrors.email_domain)}
          value={domain.get('email_domain')}
        />
      </Fieldset>
    </Form>
  );
}

export default observer(DomainEditStepOne);
