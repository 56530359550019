import moment from 'moment';
import { useState } from 'react';

import type { IConfig as IBaseConfig, IReportAttributes, ReportModel } from '@feathr/blackbox';
import { TimeFormat, useReactionEffect } from '@feathr/hooks';
import type { IRachisMessage } from '@feathr/rachis';
import { wretch } from '@feathr/rachis';
import type { IReportDateRangeProps } from '@feathr/report_components';

interface IUseReportCustomDateRangeProps<
  IAttributes extends IReportAttributes,
  IConfig extends IBaseConfig,
> {
  model: ReportModel<IAttributes>;
  config: IConfig;
}

export interface IUseReportCustomDateRangeHandlers
  extends Pick<IReportDateRangeProps, 'setReportStart' | 'setReportEnd' | 'setMode'> {
  export: (email: string) => Promise<void>;
}

export interface IUseReportCustomDateRangeReturn
  extends Pick<IReportDateRangeProps, 'reportEnd' | 'reportStart' | 'mode'> {
  startFormatted: string;
  endFormatted: string;
  reportLink: string;
  handlers: IUseReportCustomDateRangeHandlers;
}

function useReportCustomDateRange<
  IAttributes extends IReportAttributes,
  IConfig extends IBaseConfig,
>({
  config,
  model,
}: IUseReportCustomDateRangeProps<IAttributes, IConfig>): IUseReportCustomDateRangeReturn {
  const [reportStart, setReportStart] = useState<string | undefined>(model.reportRangeStart);
  const [reportEnd, setReportEnd] = useState<string | undefined>(model.reportRangeEnd);
  const [mode, setMode] = useState<'live' | 'dateWindow'>('live');

  const startMoment = moment.utc(reportStart);
  const endMoment = moment.utc(reportEnd === 'now' ? undefined : reportEnd);

  const startFormatted = startMoment.format(TimeFormat.isoDate);
  const endFormatted = endMoment.format(TimeFormat.isoDate);

  const configString = btoa(JSON.stringify(config));
  const queryString = `?s=${startFormatted}&e=${
    mode === 'live' ? 'now' : endFormatted
  }&c=${configString}&m=${mode === 'live' ? 'l' : 'dw'}`;
  const reportLink = `${BLACKBOX_SHORT_URL}reports/${model.reportKey}/${model.id}${queryString}`;

  async function onExport(email: string): Promise<void> {
    const exportParams = new URLSearchParams({
      attribution_model: config.attributionModel,
      start: startFormatted,
      end: endFormatted,
      email,
    });
    // TODO: Add error handling.
    await wretch<IRachisMessage>(
      `${model.collection!.url()}${model.id}/export?${exportParams.toString()}`,
      {
        method: 'GET',
        headers: model.collection!.getHeaders(),
      },
    );
  }

  useReactionEffect(
    () => !model.isPending,
    () => {
      if (model.isPending) {
        return;
      }
      setReportStart(model.reportRangeStart);
      setReportEnd(model.reportRangeEnd);
    },
  );

  return {
    reportStart,
    reportEnd,
    startFormatted,
    endFormatted,
    mode,
    reportLink,
    handlers: {
      setReportStart,
      setReportEnd,
      setMode,
      export: onExport,
    },
  };
}

export default useReportCustomDateRange;
