import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';

import type { IPredicate, TComparison } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';
import { cssVar } from '@feathr/hooks';

import type { IAttrOption, IComparisonOption } from '../utils';
import { getComparisonOptions, getDateComparisonOptionsMap } from '../utils';

import * as styles from './ComparisonSelect.css';

interface IProps {
  disabled?: boolean;
  handleChangeComparison: (comparison: TComparison) => void;
  predicate: IPredicate;
  rule?: IAttrOption;
}

export function ComparisonOption(props: any): JSX.Element {
  const { name } = props.data;
  const { Option } = components;
  return (
    <Option {...props}>
      <span className={styles.comparison}>{name}</span>
    </Option>
  );
}

function ComparisonSelect({
  disabled,
  handleChangeComparison,
  predicate,
  rule,
}: Readonly<IProps>): JSX.Element | null {
  const { t } = useTranslation();

  const dateComparisonOptionsMap = getDateComparisonOptionsMap(t);
  const dateComparisonOptions = Object.values(dateComparisonOptionsMap);

  const [dateTimeSelectOption, setDateTimeSelectOption] = useState<ISelectOption | undefined>(
    dateComparisonOptionsMap.exactDateAndTime,
  );

  // Map the new predicates to their respective dropdown values
  useEffect(() => {
    if (predicate.comparison?.endsWith('_date_r')) {
      if (predicate.value === 0) {
        setDateTimeSelectOption(dateComparisonOptionsMap.today);
      } else {
        setDateTimeSelectOption(dateComparisonOptionsMap.relativeDate);
      }
    }

    if (predicate.comparison?.endsWith('_date')) {
      setDateTimeSelectOption(dateComparisonOptionsMap.exactDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We don't want to show comparison if a rule hasn't been selected.
  if (!rule) {
    return null;
  }

  const { type } = rule;

  function handleSelectComparison({ id }: IComparisonOption): void {
    runInAction(() => {
      predicate.comparison = id;
      handleChangeComparison(id);

      // Reset value to the current date when changing comparison.
      if (predicate.attr_type === 'date') {
        predicate.value = new Date().toISOString();
      } else {
        predicate.value = '';
      }
      // Reset date time select option to exact date and time
      setDateTimeSelectOption(dateComparisonOptionsMap.exactDateAndTime);
    });
  }

  function handleTimeSelectComparison({ id }: ISelectOption): void {
    runInAction(() => {
      const option = dateComparisonOptions.find((option) => option.id === id);
      setDateTimeSelectOption(option);

      /*
       * Prevent excessive concatenation of comparison when clicking the same option.
       * Grab the first substring before the underscore.
       * ie: `gt_date_r` could turn into `gt_date_r_date` without this check.
       */
      let newComparison = predicate.comparison?.split('_').shift();

      if (id === 'date_time') {
        // Do nothing and use newComparison as is
      } else if (id === 'today') {
        newComparison = `${newComparison}_date_r`;
        predicate.value = 0;
      } else {
        // Relative date `_date_r` and exact date `_date`
        newComparison = `${newComparison}${id}`;

        // Default to one day selected for relative date to play nicely with today
        if (id === '_date_r') {
          predicate.value = 1;
        }
      }

      /*
       * Reset value to today's date when changing comparison unless it's a relative date.
       * Today is set to zero above.
       */
      if (id !== 'today' && id !== '_date_r') {
        if (predicate.attr_type === 'date') {
          predicate.value = new Date().toISOString();
        } else {
          predicate.value = '';
        }
      }

      handleChangeComparison(newComparison as TComparison);
    });
  }

  const options = rule ? getComparisonOptions(rule, t) : [];
  const comparison = options.find(({ id }) => id === predicate.comparison);
  /*
   * If we're using a date comparison, we need to split the comparison between the
   * two dropdowns to determine what kind of date it is: relative, exact, time etc.
   */
  const dateComparison = options.find(({ id }) => id === predicate.comparison?.split('_').shift());

  const selectStyles = {
    /*
     * Margin-right here spaces the attr_against container from
     * the comparison operator container
     */
    container: (provided) => ({ ...provided, marginRight: cssVar('--spacing-1') }),
    menu: (provided) => ({ ...provided, width: 'auto' }),
    singleValue: (provided) => ({
      ...provided,
      transform: 'none',
      position: 'relative',
      top: 0,
      maxWidth: '100%',
      cursor: 'pointer',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      transform: 'none',
      position: 'relative',
      top: 0,
    }),
  };

  return (
    <>
      <Select
        className={classNames(styles.select, styles.comparisonSelect)}
        components={{ Option: ComparisonOption }}
        disabled={disabled}
        name={'predicate_comparison'}
        onSelectSingle={handleSelectComparison}
        options={options}
        styles={selectStyles}
        value={type === 'date' ? dateComparison : comparison}
      />
      {/* Don't show date options for exists and nexists */}
      {type === 'date' && !predicate?.comparison?.includes('exists') && (
        <Select
          className={classNames(styles.select, styles.dateTimeTypeSelect)}
          components={{ Option: ComparisonOption }}
          disabled={disabled}
          name={'predicate_comparison_date_time_type'}
          onSelectSingle={handleTimeSelectComparison}
          options={dateComparisonOptions}
          styles={selectStyles}
          value={dateTimeSelectOption}
        />
      )}
    </>
  );
}

export default observer(ComparisonSelect);
