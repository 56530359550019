import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import { StatBox } from '@feathr/components';

import type { IStatProps } from './Stats';

function ROIStat({ tooltip, value }: IStatProps): JSX.Element {
  const formatted = numeral(value).format('$0,0.00');

  return (
    <StatBox
      label={'Conversion value'}
      primary={formatted}
      tooltip={
        tooltip ??
        'The combined value of Conversions according to the value of their corresponding Goals.'
      }
    />
  );
}

export default observer(ROIStat);
