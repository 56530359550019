import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Billable } from '@feathr/blackbox';
import { CardV2 as Card } from '@feathr/components';

import LegacyAddressInput from '../LegacyAddressInput';

interface IProps {
  billable: Billable;
}

function BillingAddressCard({ billable }: Readonly<IProps>): JSX.Element {
  return (
    <Card>
      <Card.Header title={'Billing address'} />
      <Card.Content>
        <LegacyAddressInput<Billable> attribute={'address'} model={billable} />
      </Card.Content>
    </Card>
  );
}

export default observer(BillingAddressCard);
