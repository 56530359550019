import { computed, makeObservable } from 'mobx';

import { concatPath, moment, TimeFormat } from '@feathr/hooks';
import type { TConstraints } from '@feathr/rachis';
import { isWretchError } from '@feathr/rachis';
import { Collection, wretch } from '@feathr/rachis';

import type { Goals } from './goals';
import type { IReportAttributes } from './model/report';
import { ReportModel } from './model/report';
import type { IStats, TAttributionModel } from './stats';

export interface ILeg {
  name: string;
  campaigns: string[];
}

export interface IFlight extends IReportAttributes {
  readonly event_id: string;
  readonly account_id: string;
  attribution_model: TAttributionModel;
  date_created: string;
  date_start: string;
  date_end: string;
  /**
   * Toggles calculation between legacy bloomfilter and new live stats.
   * Defaults to false.
   */
  enable_live_reach: boolean;
  legs: ILeg[];
  name?: string;
  objectives: Goals;
  shared_description?: string;
  shared_image?: string;
  shared_name?: string;
  shared: boolean;
  total_stats: IStats;
  rerun: boolean;
}

export class Flight extends ReportModel<IFlight> {
  public readonly className = 'Flight';

  public reportKey = 'f' as const;

  public constraints: TConstraints<IFlight> = {
    conversion_objectives: {
      length: {
        maximum: 1,
        tooLong: '^Too many Goals.',
      },
    },
    name: {
      presence: {
        allowEmpty: false,
      },
    },
  };

  constructor(attributes: Partial<IFlight> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public get reportRangeStart(): string {
    const dateStart = this.get('date_start');
    const dateCreated = this.get('date_created');
    if (dateStart) {
      return dateStart;
    }
    if (dateCreated) {
      return dateCreated;
    }
    return moment.utc().format(TimeFormat.isoDate);
  }

  @computed
  public get reportRangeEnd(): string {
    const dateEnd = this.get('date_end');
    if (dateEnd) {
      return dateEnd;
    }
    return moment.utc().format(TimeFormat.isoDate);
  }

  public getDefaults(): Partial<IFlight> {
    return {
      total_stats: { flavors: {}, spend: 0 },
      legs: [],
    };
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/projects/${this.get('event_id')}/flights/${this.id}`, pathSuffix);
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Flight';
  }

  public removeCampaign(id: string): void {
    this.set({
      legs: this.get('legs', []).map((leg) => ({
        ...leg,
        campaigns: leg.campaigns.filter((campaignId) => campaignId !== id),
      })),
    });
  }
}

export class Flights extends Collection<Flight> {
  public getModel(attributes: Partial<IFlight>): Flight {
    return new Flight(attributes);
  }

  public getClassName(): string {
    return 'flights';
  }

  public async createFromPath(pathId: string, overrides: Partial<IFlight>): Promise<Flight> {
    const response = await wretch<IFlight>(`${this.getHostname()}paths/${pathId}/create`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(overrides),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return this.processJSONResponse(response);
  }
}
