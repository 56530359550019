import { ObjectId } from 'bson';
import debounce from 'debounce-promise';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  BlackbaudRaisersEdgeIntegration as BREIntegration,
  CustomField as CustomFieldType,
  IBlackbaudContactMapping,
  TBlackbaudContactMappingKey,
  TIntegrationPersonAttributes,
} from '@feathr/blackbox';
import {
  defaultPartnerAttributes,
  defaultPersonAttributes,
  FieldCollection,
  FieldDataType,
} from '@feathr/blackbox';
import { AsyncSelect, toast } from '@feathr/components';
import DataRequestBreadcrumbAttributes from '@feathr/extender/components/CustomFieldSelect/DataRequestBreadcrumbAttributes';
import { FieldSingleValue } from '@feathr/extender/components/SelectOptions';
import { FieldOption } from '@feathr/extender/components/SelectOptions/FieldOption';
import { StoresContext } from '@feathr/extender/state';
import { RaisersEdgeContactLabelMap } from '@feathr/extender/styles/blackbaud_raisers_edge';

type TAttributesMap = Record<
  Exclude<FieldCollection, 'Project'>,
  | typeof defaultPartnerAttributes
  | typeof defaultPersonAttributes
  | typeof DataRequestBreadcrumbAttributes
>;

const attributesMap: TAttributesMap = {
  [FieldCollection.Partner]: defaultPartnerAttributes,
  [FieldCollection.Person]: defaultPersonAttributes,
  [FieldCollection.Breadcrumb]: DataRequestBreadcrumbAttributes,
};

interface ISyncPersonSelectProps {
  /** The collections to search for fields. Person is the default. */
  collections?: FieldCollection[];
  integration: BREIntegration;
  /** The current mapping. */
  mapping: IBlackbaudContactMapping;
  /** List of mappings to determine which mappings have already been mapped. */
  mappings: IBlackbaudContactMapping[];
  className?: string;
}

interface ISelectField {
  id: string;
  u_key: string;
  data_type: FieldDataType;
}

function SyncPersonSelect({
  collections = [FieldCollection.Person],
  integration,
  mappings,
  mapping,
  className,
}: ISyncPersonSelectProps): JSX.Element {
  const { t } = useTranslation();
  const { CustomFields } = useContext(StoresContext);
  const defaultFields: ISelectField[] = collections.reduce((acc, context) => {
    return acc.concat(attributesMap[context]);
  }, []);

  const { default_field: defaultField, custom_field: customField } = mapping;

  const fetchedField = customField ? CustomFields.get(customField) : undefined;

  const value: ISelectField | undefined = customField
    ? fetchedField?.isPending
      ? undefined
      : fetchedField!.toJS()
    : defaultFields.find(({ id }) => id === defaultField);

  const currentlyMappedFields: Array<TBlackbaudContactMappingKey | string | null> | undefined =
    mappings?.map((mapping) => mapping.default_field ?? mapping.custom_field);

  async function handleOnClear(): Promise<void> {
    const fieldUnmapped = RaisersEdgeContactLabelMap(t, mapping.key);
    try {
      await integration.updateContactMapping({
        mapping: mapping.id,
        defaultField: null,
        customField: null,
      });

      toast(t('Successfully unmapped {{fieldUnmapped}}', { fieldUnmapped }), {
        type: ToastType.SUCCESS,
      });

      // TODO: Hard reloading will not be necesary with the implemention #2651.
      window.location.reload();
    } catch (error) {
      toast(
        t('Failed to unmap {{fieldUnmapped}}. Try again. {{error}}', { fieldUnmapped, error }),
        {
          type: ToastType.ERROR,
        },
      );
    }
  }

  async function handleOnSelectSingle(option: ISelectField): Promise<void> {
    const contactField = RaisersEdgeContactLabelMap(t, mapping.key);
    const feathrField = option.u_key;
    const defaultField = !ObjectId.isValid(option.id as string)
      ? (option.id as TIntegrationPersonAttributes)
      : undefined;
    const customField = ObjectId.isValid(option.id as string) ? option.id : undefined;
    try {
      // TODO: Refactor to use ContactMapping.patch() as part of #2651.
      await integration.updateContactMapping({
        mapping: mapping.id,
        defaultField: defaultField,
        customField: defaultField ? null : customField,
      });

      toast(
        t('Successfully mapped {{contactField}} to {{feathrField}}', {
          contactField,
          feathrField,
        }),
        {
          type: ToastType.SUCCESS,
        },
      );

      // TODO: Hard reloading will not be necesary with the implemention #2651.
      window.location.reload();
    } catch (error) {
      toast(
        t('Failed to map {{contactField}} to {{feathrField}}. Try again. {{error}}', {
          contactField,
          feathrField,
          error,
        }),
        {
          type: ToastType.ERROR,
        },
      );
    }
  }

  const loadOptions = async (inputValue: string): Promise<ISelectField[]> => {
    const customFields = CustomFields.list({
      filters: {
        collection__in: collections,
        is_archived__ne: true,
        u_key__icontains: inputValue,
      },
      pagination: {
        page_size: 20,
      },
    });

    await when(() => !customFields.isPending);
    return [
      ...defaultFields
        // Remove pp_opt_outs.all from the list of default fields.
        .filter((attribute) => attribute.id !== 'pp_opt_outs.all')
        .filter((attribute) => {
          return attribute.u_key.toLowerCase().includes(inputValue.toLowerCase());
        }),
      ...customFields.models.map((customField: CustomFieldType) => customField.toJS()),
    ]
      .filter(
        // Remove fields that are already mapped except for the currently mapped field.
        (field) =>
          !currentlyMappedFields.includes(field.id as TIntegrationPersonAttributes) ||
          field.id === mapping.default_field,
      )
      .filter(
        // Keep only fields with the same data type as the object.
        (field) => {
          // If the data_type is a number type, allow both to display.
          if (field.data_type === FieldDataType.int || field.data_type === FieldDataType.float) {
            return (
              mapping.data_type === FieldDataType.int || mapping.data_type === FieldDataType.float
            );
          }
          return field.data_type === mapping.data_type;
        },
      );
  };

  const debouncedLoadOptions = debounce(loadOptions, 600);

  function getOptionLabel(option: ISelectField): string {
    return option.u_key;
  }

  function getOptionValue({ id }: ISelectField): string {
    return id;
  }

  function noOptionsMessage(): string {
    const map = {
      [FieldDataType.int]: t('number'),
      [FieldDataType.float]: t('number'),
      [FieldDataType.str]: t('text'),
      [FieldDataType.bool]: t('true/false'),
      [FieldDataType.date]: t('date'),
    };
    if (!mapping.data_type) {
      return t('No options available');
    }
    // We must escape "true/false" or it will output &#47;
    return t('No options for {{-type}} field type', { type: map[mapping.data_type] });
  }

  return (
    <AsyncSelect<ISelectField>
      /*
       * SyncPersonSelectControl shows the selected option with a prefix icon that indicated the field type.
       * FieldOption shows options with a prefix icon that indicates the field type.
       */
      components={{
        Option: FieldOption,
        SingleValue: FieldSingleValue,
      }}
      defaultOptions={true}
      disabled={!mapping.editable}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isClearable={true}
      isLoading={fetchedField ? fetchedField.isPending : false}
      loadOptions={debouncedLoadOptions}
      noOptionsMessage={noOptionsMessage}
      onClear={handleOnClear}
      onSelectSingle={handleOnSelectSingle}
      value={value}
      wrapperClassName={className}
    />
  );
}

export default observer(SyncPersonSelect);
