import type { JSX } from 'react';
import React from 'react';

import { FieldCollection } from '@feathr/blackbox';
import Page from '@feathr/extender/App/Page';
import CustomFieldsTable from '@feathr/extender/components/CustomFieldsTable';

function EventPartnersFieldsPage(): JSX.Element {
  return (
    <Page title={'Custom Fields'}>
      <CustomFieldsTable collection={FieldCollection.Partner} />
    </Page>
  );
}

export default EventPartnersFieldsPage;
