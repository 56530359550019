import { faHandshakeAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Chip } from '@feathr/components';

import { StoresContext } from '../../state';

interface IProps {
  id: string;
  localUrl?: (url: string) => string;
}

function PartnerChip({ id, localUrl }: IProps) {
  const { Partners } = useContext(StoresContext);

  const partner = Partners.get(id);
  if (partner.isErrored) {
    return null;
  }

  if (partner.isPending) {
    return <Chip isLoading={true} />;
  }

  const content = (
    <Chip prefix={<FontAwesomeIcon icon={faHandshakeAlt} />}>
      {partner.name}
      {partner.get('is_archived') ? ' (Archived)' : ''}
    </Chip>
  );

  if (localUrl) {
    return <Link to={localUrl(partner.getItemUrl())}>{content}</Link>;
  }
  return content;
}

export default observer(PartnerChip);
