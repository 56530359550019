import type { JSX } from 'react';
import React from 'react';

import { MenuItem } from '@feathr/components';
interface IAppcuesContent {
  attributes: {
    id: string;
    name: string;
  };
}

function AppcuesMenuItem({ id, name }: IAppcuesContent['attributes']): JSX.Element {
  function renderFlow(): void {
    window.Appcues?.show(id);
  }

  return <MenuItem onClick={renderFlow}>{name}</MenuItem>;
}

export default AppcuesMenuItem;
