import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React, { useContext } from 'react';

import type { Partner } from '@feathr/blackbox';
import type { ITableProps, ITableWrapperProps } from '@feathr/components';
import { Table } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import type { IObject } from '@feathr/rachis';

import PartnersColumns from './PartnersColumns';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';

interface IProps {
  campaignId?: string;
  className?: string;
  columnIds: string[];
  filterElements?: ITableWrapperProps['filters'];
  isLoading?: boolean;
  selected?: string[];
  setSelected?: Dispatch<SetStateAction<string[]>>;
}

interface IModelProps extends IProps {
  filters?: IObject;
  items?: never;
}
interface IObjectProps extends IProps {
  filters?: never;
  items: Partner[];
}

function PartnersTable({
  campaignId,
  className,
  columnIds,
  filters,
  filterElements,
  isLoading = false,
  items,
  selected,
  setSelected,
}: IModelProps | IObjectProps): JSX.Element {
  const { Partners, Tags } = useContext(StoresContext);

  async function selectAll(): Promise<void> {
    if (setSelected) {
      if (items !== undefined) {
        setSelected(items.map((partner) => partner.id));
      } else {
        await when(() => Partners.count({ filters }) > 0);
        const partnersCount = Partners.count({ filters });
        const allPartners = Partners.list({
          filters,
          only: ['id'],
          pagination: { page_size: partnersCount },
        });
        await when(() => !allPartners.isPending);
        const allPartnerIds = allPartners.models.map((p) => p.id);
        setSelected(allPartnerIds);
      }
    }
  }

  const commonProps: ITableProps<Partner> = {
    className,
    columns: PartnersColumns(Tags, columnIds, campaignId),
    filterElements,
    hasHeaderGroups: false,
    initialSort: [{ id: 'name' }],
    isLoading,
    isSelectable: true,
    noDataText: 'No partners have reached this step.',
    onSelectAll: selectAll,
    selected,
    setSelected,
  };

  if (items !== undefined) {
    return <Table<Partner> {...commonProps} idKey={'id'} items={items} />;
  } else {
    return <Table<Partner> {...commonProps} collection={Partners} filters={filters} />;
  }
}

export default observer(PartnersTable);
