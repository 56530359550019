import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ImisIntegration } from '@feathr/blackbox';
import { EIntegrationConnectionState, integrationStateTranslationMap } from '@feathr/blackbox';
import { Chip } from '@feathr/components';
import {
  templateTypeColorState,
  templateTypeIconState,
} from '@feathr/extender/styles/integrations';

import * as styles from './ImisIntegrationStatus.css';

interface IProps {
  integration?: ImisIntegration;
  pause?: boolean;
}

function ImisIntegrationStatus({ integration }: IProps): JSX.Element {
  const { t } = useTranslation();
  const state = integration
    ? integration.integrationState
    : EIntegrationConnectionState.notConnected;
  return (
    <div className={styles.content}>
      <Chip
        prefix={<FontAwesomeIcon icon={templateTypeIconState(state)} />}
        theme={templateTypeColorState(state)}
      >
        {integrationStateTranslationMap(t)[state]}
      </Chip>
    </div>
  );
}

export default observer(ImisIntegrationStatus);
