import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { FacebookCampaign } from '@feathr/blackbox';
import { ButtonValid, Fieldset, Form, Input } from '@feathr/components';

import CampaignObjectiveSelect from './CampaignObjectiveSelect';

interface IProps {
  onNext: () => void;
  campaign: FacebookCampaign;
}

export function validateStepOne(campaign: FacebookCampaign): string[] {
  return campaign.validate(['name', 'objective_type'], false).errors;
}

const NextStepButton = observer(({ campaign, onNext }: IProps) => {
  const { t } = useTranslation();

  const validationErrors = validateStepOne(campaign);
  return (
    <ButtonValid errors={validationErrors} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function FacebookCampaignEditStepOne({ campaign, onNext }: IProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Form
      actions={[<NextStepButton campaign={campaign} key={'next'} onNext={onNext} />]}
      description={
        <Trans t={t}>
          <p>
            Provide a descriptive name for your campaign so you can find it later. It helps to put
            the targets and goals, if any, of the campaign in the name; E.g., "Cart Abandoners -
            Registration".
          </p>
          <p>
            <a
              href={
                'https://help.feathr.co/hc/en-us/articles/360048057553-How-to-launch-a-Facebook-campaign-from-Feathr'
              }
              target={'_blank'}
            >
              Check out our help desk to learn about Meta Campaign best practices.
            </a>
          </p>
        </Trans>
      }
      label={t('Edit Campaign: Name and objective')}
    >
      <Fieldset>
        <Input
          attribute={'name'}
          helpText={t('Name your campaign')}
          label={t('Name')}
          model={campaign}
          name={'campaign_name'}
          required={true}
          type={'text'}
        />
        <CampaignObjectiveSelect campaign={campaign} />
      </Fieldset>
    </Form>
  );
}

export default observer(FacebookCampaignEditStepOne);
