import React from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { IEvent } from '@feathr/blackbox';

import * as eventStyles from '../EventSwitcher/EventSwitcher.css';
import * as selectStyles from './SelectOptions.css';

import noImg from 'images/no-img.png';

export function EventOption(props: OptionProps<IEvent>) {
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex' }}>
        <div className={selectStyles.contentWrapper}>
          <div
            style={{
              width: '35px',
              height: '35px',
              backgroundImage: `url("${props.data.logo || noImg}")`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
          />
        </div>
        <div className={'name'}>
          <span>{props.data.name}</span>
        </div>
      </div>
    </components.Option>
  );
}

export function EventSingleValue(props: any) {
  return (
    <components.SingleValue {...props}>
      <div className={eventStyles.navBarEvent}>
        <span
          className={eventStyles.navBarEventLogo}
          style={{ backgroundImage: `url("${props.data.logo || noImg}")` }}
        />
        {props.data.name}
      </div>
    </components.SingleValue>
  );
}
