import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faChrome,
  faEdge,
  faFirefox,
  faInternetExplorer,
  faOpera,
  faSafari,
  faYahoo,
} from '@fortawesome/free-brands-svg-icons';

// TODO: Combine with david's types in blackbox and persons.ts
export enum EBrowsers {
  chrome = 'Chrome',
  edge = 'Edge',
  firefox = 'Firefox',
  msie = 'Internet Explorer',
  opera = 'Opera',
  safari = 'Safari',
  yahoo = 'Yahoo',
}

// TODO: Combine with david's types in blackbox and persons.ts
export const browserIconMap: Record<keyof typeof EBrowsers, IconDefinition> = {
  msie: faInternetExplorer,
  edge: faEdge,
  chrome: faChrome,
  firefox: faFirefox,
  safari: faSafari,
  opera: faOpera,
  yahoo: faYahoo,
};
