import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { StoresContext } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function ConversionLabel({ className, id }: IProps) {
  const { Conversions, Persons } = React.useContext(StoresContext);
  const conversion = Conversions.get(id);
  const person = !conversion.isPending ? Persons.get(conversion.get('person')) : undefined;
  return (
    <div
      className={classNames(
        { [skeleton]: conversion.isPending || !person || person.isPending },
        className,
      )}
    >
      {!!person && person.name}
    </div>
  );
}

export default observer(ConversionLabel);
