import { faCog, faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import type { TableCellRenderer } from 'react-table';

import {
  Button,
  Checkbox,
  DebouncedInput,
  Input,
  Label,
  Popover,
  Tooltip,
} from '@feathr/components';
import type { IObject } from '@feathr/rachis';

import { StoresContext } from '../../state';
import dataSegmentsPageColumns from './dataSegmentsPageColumns';

import * as styles from './DataSegmentsPageFilters.css';

interface IFilters extends IObject {
  name__icontains?: string;
}

interface IProps<T extends IFilters> {
  columnIds: string[];
  filters: T;
  setFilters: (newFilters: T) => void;
  updateColumnIds: (newColumnIds: string[]) => void;
}

export interface ISegmentsColumn {
  checkboxLabel?: string;
  Header: (() => JSX.Element) | string;
  id: string;
  sortable?: boolean;
  Cell?: TableCellRenderer;
  module?: string;
}

function DataSegmentsPageFilters<T extends IFilters>({
  columnIds,
  filters,
  setFilters,
  updateColumnIds,
}: IProps<T>): JSX.Element {
  const { Goals, Tags, Targetings } = useContext(StoresContext);
  const { t } = useTranslation();

  function getColumnSetter(columnId: string) {
    return () => {
      const newColumnIds = [...columnIds];
      const index = newColumnIds.indexOf(columnId);
      const columnVisible = index >= 0;
      if (columnVisible) {
        newColumnIds.splice(index, 1);
      } else {
        newColumnIds.push(columnId);
      }
      updateColumnIds(newColumnIds);
    };
  }

  function handleDebouncedSearchChange(newValue?: string): void {
    setFilters({
      ...filters,
      name__icontains: newValue,
    });
  }

  const columns = dataSegmentsPageColumns({ Goals, Tags, Targetings });
  return (
    <>
      <DebouncedInput<string>
        defaultValue={filters.name__icontains}
        onChange={handleDebouncedSearchChange}
      >
        {(liveValue, onChangeLiveValue): JSX.Element => (
          <Input
            isClearable={true}
            onChange={onChangeLiveValue}
            placeholder={t('Search by name...')}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
      <Tooltip position={'top-end'} title={t('Configure columns')}>
        <Popover position={'bottom'} toggleOnClick={false}>
          <Button type={'icon-outlined'}>
            <FontAwesomeIcon icon={faCog} />
          </Button>
          <div className={styles.content}>
            <section>
              <Label>{t('Columns')}</Label>
              <div className={styles.columns}>
                {columns.map((column) => {
                  return (
                    <Checkbox
                      id={`checkbox-${column.id}`}
                      key={column.id}
                      label={typeof column.checkboxLabel === 'string' && column.checkboxLabel}
                      name={column.id}
                      onChange={getColumnSetter(column.id!)}
                      value={columnIds.includes(column.id!)}
                    />
                  );
                })}
              </div>
            </section>
          </div>
        </Popover>
      </Tooltip>
    </>
  );
}

export default DataSegmentsPageFilters;
