import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { AutoPinpointEmailCampaign } from '@feathr/blackbox';
import { CampaignClass, type IDripCampaignStepSpec } from '@feathr/blackbox';
import { Button, CardV2 as Card, Label } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import MergeDataInputs from '../../../EmailDetails/MergeDataInputs';
import EmailInputs from '../EmailInputs';
import StepTargeting from './StepTargeting';

interface IEditAutomationProps {
  step: IDripCampaignStepSpec;
  onApply: (updatedStep: IDripCampaignStepSpec) => void;
}

function EditAutomation({ step, onApply }: Readonly<IEditAutomationProps>): JSX.Element {
  const { Campaigns } = useContext(StoresContext);
  const { t } = useTranslation();
  const { step: stepAttributes = step } = step;
  const campaign = useMemo(() => {
    const autoCampaign = {
      ...stepAttributes,
      _cls: CampaignClass.AutoPinpointEmail,
    };
    return Campaigns.create(autoCampaign) as AutoPinpointEmailCampaign;
  }, [Campaigns, stepAttributes]);

  // TODO: Validate all the things in #3705
  const validationErrors: any = [];

  function handleApply(): void {
    const updatedStep: IDripCampaignStepSpec = {
      ...campaign.attributes,
    };
    onApply(updatedStep);
  }

  return (
    <Card theme={'disabled'} width={'full'}>
      <Card.Content>
        <Label weight={'semibold'}>{t('Automation')}</Label>
        <StepTargeting campaign={campaign} validationErrors={validationErrors} />
      </Card.Content>
      <Card.Content>
        <Label weight={'semibold'}>{t('Email')}</Label>
        <EmailInputs campaign={campaign} />
      </Card.Content>
      <Card.Content>
        <Label margin={'condensed'} weight={'semibold'}>
          {t('Default Merge Data')}
        </Label>
        <p>
          {t(
            'These values will be used to replace merge tags when the recipient does not have data for the corresponding field.',
          )}
        </p>
        <MergeDataInputs disabled={false} />
      </Card.Content>
      <Card.Actions>
        {/* TODO: convert to ButtonValid in #3705 */}
        <Button onClick={handleApply} type={'primary'}>
          {t('Apply')}
        </Button>
      </Card.Actions>
    </Card>
  );
}

export default observer(EditAutomation);
