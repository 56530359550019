import type { IObservableArray } from 'mobx';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';

import type {
  FacebookCampaign,
  IConfig,
  IFacebookCampaignInsightsParams,
  ILocation,
  PinpointEmailBaseCampaign,
  Segment,
  Segments as SegmentsCollection,
  Targeting,
  TrackedLinkCampaign,
} from '@feathr/blackbox';
import { CampaignClass, reportModuleLabels } from '@feathr/blackbox';
import { moment } from '@feathr/hooks';

import { StoresContext } from '../../state';
import type { IReportProps } from '../../types';
import { ActivityBreakdownCard } from '../ActivityBreakdownCard';
import ActivityCard from '../ActivityCard';
import ActivitySummaryCard from '../ActivitySummaryCard';
import BudgetCard from '../BudgetCard';
import CampaignEngagementCard from '../CampaignEngagementCard/CampaignEngagementCard';
import ContextWrapper from '../ContextWrapper';
import ConversionTableCard from '../ConversionTableCard';
import CreativeTableCard from '../CreativeTableCard';
import DailyStatsTableCard from '../DailyStatsTableCard';
import EmailActivitySummaryCard from '../EmailActivitySummaryCard';
import FormSubmissionsTableCard from '../FormSubmissionsTableCard';
import HeatmapCard from '../HeatmapCard';
import KeywordStatsTableCard from '../KeywordStatsTableCard';
import LeadsSummaryCard from '../LeadsSummaryCard';
import LinkClicksTableCard from '../LinkClicksTableCard';
import LoadingMessage from '../LoadingMessage';
import PartnersStatsTableCard from '../PartnersStatsTableCard/PartnersStatsTableCard';
import { ROICard } from '../ROICard';
import TrafficBreakdownCard from '../TrafficBreakdownCard';
import FacebookCampaignAlerts from './FacebookCampaignAlerts';

import * as styles from './CampaignReport.css';

function getTargetSegments(
  targetings: IObservableArray<Targeting>,
  Segments: SegmentsCollection,
): Segment[] {
  return targetings
    .filter(
      (t) =>
        t.get('kind') === 'segment' &&
        !!t.get('target_data') &&
        !t.get('is_archived') &&
        t.get('included'),
    )
    .map((t) => Segments.get(t.get('target_data')!));
}

function CampaignReport({
  config = {
    includeROI: false,
    includeActivitySummary: true,
    includeSpendPerformance: true,
    includeHeatmap: true,
    includeAdActivity: true,
    includeEmailActivity: true,
    includePageActivity: true,
    includeGoogleActivity: true,
    includeActivityBreakdown: true,
    includeConversionsTable: false,
    includeCreativesTable: true,
    includeDailyStatsTable: true,
    includeLeadsSummary: true,
    includeFormSubmissionsTable: true,
    includeTrafficBreakdown: true,
    includeMonetizationROI: false,
    includeLinkClicksTable: true,
    includeKeywordStatsTable: true,
    attributionModel: 'full',
  } as IConfig,
  end,
  localUrl,
  hasGoals,
  modelId,
  start,
  store,
}: IReportProps): JSX.Element {
  return (
    <ContextWrapper store={store}>
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          const {
            AggregatedStats,
            Campaigns,
            FacebookCampaignInsights,
            Stats,
            Targetings,
            Goals,
            Segments,
          } = useContext(StoresContext);

          const pageSize =
            Math.max(
              Math.ceil(
                moment
                  .duration(
                    moment.utc(end, moment.ISO_8601).diff(moment.utc(start, moment.ISO_8601)),
                  )
                  .asDays(),
              ),
              0,
            ) + 1;
          const campaign = Campaigns.get(modelId);
          let campaignDailyStats = Stats.newListResponse();
          const goals = Goals.list({
            filters: {
              _parent: modelId,
              is_archived__ne: true,
            },
          });
          let targetings = Targetings.newListResponse();
          const isSegmentCampaign = [CampaignClass.Segment, CampaignClass.Facebook].includes(
            campaign.get('_cls'),
          );
          if (!campaign.isPending) {
            campaignDailyStats = Stats.list({
              filters: {
                metadata__date__gte: start,
                metadata__date__lte: end,
                metadata__obj_id: modelId,
              },
              model: campaign.isFacebook ? 'facebook_campaign' : 'campaign',
              ordering: ['metadata.date'],
              pagination: {
                page_size: pageSize,
              },
            });
            if (isSegmentCampaign) {
              targetings = Targetings.list({
                filters: {
                  _parent: campaign.id,
                },
              });
            } else {
              targetings.isPending = false;
            }
          }

          const aggregatedStats = AggregatedStats.aggregate({
            filters: {
              metadata__date__gte: start,
              metadata__date__lte: end,
              metadata__obj_id: modelId,
            },
            model: campaign.isFacebook ? 'facebook_campaign' : 'campaign',
          });

          const pending =
            campaign.isPending ||
            campaignDailyStats.isPending ||
            targetings.isPending ||
            goals.isPending ||
            aggregatedStats.isPending;
          if (pending) {
            return <LoadingMessage />;
          }
          let budget = 0;
          const exposureSettings = campaign.get('exposure_settings');
          if (exposureSettings.target_type === 'fixed_budget') {
            budget = exposureSettings.target_value!;
          } else if (exposureSettings.target_type === 'fixed_impressions') {
            budget = exposureSettings.target_value! / 250;
          }
          let totalUsers = 0;
          if (isSegmentCampaign) {
            totalUsers = getTargetSegments(targetings.models, Segments).reduce(
              (acc, segment) => acc + (segment.get('stats').num_cookies_total || 0),
              0,
            );
          }

          const conversions = aggregatedStats.get('conversions');
          const keywords = aggregatedStats.get('terms');

          const numConversions = campaign.isGoogle
            ? aggregatedStats.get('num_conversions')
            : conversions?.[config.attributionModel].num || 0;
          const facebookCampaignStats = campaign.isFacebook
            ? FacebookCampaignInsights.getStats({
                campaign_id: campaign.id,
                date_start: start,
                date_end: end,
              } as IFacebookCampaignInsightsParams)
            : undefined;
          const isMonetization = campaign.get('parent_kind') === 'partner';
          const showSpend = !(isMonetization && !localUrl);
          const showMonetizationROI =
            config.includeMonetizationROI && campaign.get('monetization_value');
          const campaignClass = campaign.get('_cls');
          const isTrackedLink = campaignClass === CampaignClass.TrackedLink;
          const isReferral = campaignClass === CampaignClass.Referral;
          const isLanding = campaignClass === CampaignClass.LandingPage;
          interface ITextMap {
            clicksLabel?: string;
            clicksTooltip?: string;
            reachLabel?: string;
            reachTooltip?: string;
            uniqueClicksLabel?: string;
            uniqueClicksTooltip?: string;
            viewsLabel?: string;
            viewsTooltip?: string;
          }

          const textMap = new Map<string, ITextMap>([
            [
              CampaignClass.PinpointEmail,
              {
                clicksLabel: 'Total Clicks',
                clicksTooltip:
                  'The total number of times that links in this email have been clicked. Includes multiple clicks by the same person.',
                reachLabel: 'Unique Opens',
                reachTooltip: 'The number of unique people who have opened this email.',
                uniqueClicksTooltip:
                  'The number of unique people who have clicked a link in this email.',
                viewsLabel: 'Total Opens',
                viewsTooltip:
                  'The total number of times this email has been opened. Includes multiple opens by the same person.',
              },
            ],
            [
              CampaignClass.TrackedLink,
              {
                clicksLabel: 'Page Clicks',
                clicksTooltip: 'Total clicks on a page reached through a tracked link.',
                reachTooltip: 'Number of people who arrived at the URL through a tracked link.',
                uniqueClicksLabel: 'Unique Page Clicks',
                uniqueClicksTooltip:
                  'Number of people who clicked a link at the URL reached through a tracked link.',
                viewsLabel: 'Page Views',
                viewsTooltip: 'Total visits to a page reached through a tracked link.',
              },
            ],
          ]);

          return (
            <>
              {campaign.isFacebook && !!localUrl && (
                <FacebookCampaignAlerts
                  campaign={campaign as FacebookCampaign}
                  localUrl={localUrl}
                />
              )}
              {config.includeActivitySummary &&
                (campaign.isEmailType() ? (
                  <EmailActivitySummaryCard aggregatedStats={aggregatedStats} campaign={campaign} />
                ) : (
                  <ActivitySummaryCard
                    aggregatedStats={aggregatedStats}
                    campaign={campaign}
                    clicksLabel={textMap.get(campaignClass)?.clicksLabel}
                    clicksTooltip={textMap.get(campaignClass)?.clicksTooltip}
                    conversions={numConversions}
                    facebookCampaignStats={facebookCampaignStats}
                    isMonetization={isMonetization}
                    reachLabel={textMap.get(campaignClass)?.reachLabel}
                    reachTooltipText={textMap.get(campaignClass)?.reachTooltip}
                    targetImpressions={campaign.get('exposure_settings').target_value}
                    totalUsers={totalUsers}
                    uniqueClicksLabel={textMap.get(campaignClass)?.uniqueClicksLabel}
                    uniqueClicksTooltipText={textMap.get(campaignClass)?.uniqueClicksTooltip}
                    viewsLabel={textMap.get(campaignClass)?.viewsLabel}
                    viewsTooltip={textMap.get(campaignClass)?.viewsTooltip}
                  />
                ))}
              {campaign.isGoogle && config.includeGoogleActivity && (
                <div className={styles.flex}>
                  <ActivityCard
                    dailyStats={campaignDailyStats.models}
                    label={'Views'}
                    lines={[{ name: 'Views', showStatSummary: false, statsKey: 'num_views' }]}
                  />
                  <ActivityCard
                    dailyStats={campaignDailyStats.models}
                    label={'Clicks'}
                    lines={[{ name: 'Clicks', showStatSummary: false, statsKey: 'num_clicks' }]}
                  />
                </div>
              )}
              {isTrackedLink && config.includeTrafficBreakdown && (
                <TrafficBreakdownCard
                  attributionModel={config.attributionModel}
                  campaign={campaign as TrackedLinkCampaign}
                  dailyStats={campaignDailyStats.models}
                />
              )}
              {(campaign.isAdCampaign || campaign.isGoogle) &&
                showSpend &&
                config.includeSpendPerformance && (
                  <BudgetCard
                    aggregatedStats={aggregatedStats}
                    budget={budget}
                    conversions={numConversions}
                    isGoogle={campaign.isGoogle}
                    monetizationValue={campaign.get('monetization_value')}
                    showBudget={exposureSettings.target_type === 'fixed_budget'}
                    showCPA={hasGoals || campaign.isGoogle}
                    showCPM={!campaign.isGoogle}
                    showMonetizationROI={
                      isMonetization && showMonetizationROI && config.includeMonetizationROI
                    }
                  />
                )}
              {config.includeHeatmap && (
                <div style={{ marginBottom: '10px' }}>
                  <HeatmapCard
                    points={campaignDailyStats.models.reduce((locs, stat) => {
                      if (stat.get('locations')) {
                        locs.push(...(stat.get('locations') as ILocation[]));
                      }
                      return locs;
                    }, [] as ILocation[])}
                  />
                </div>
              )}
              <div style={{ marginBottom: '10px' }}>
                {!campaign.isGoogle && config.includeAdActivity && (
                  <ActivityCard
                    dailyStats={campaignDailyStats.models}
                    label={reportModuleLabels.includeAdActivity}
                    lines={[
                      { name: 'Views', flavors: [{ key: 'ad_view' }] },
                      { name: 'Clicks', flavors: [{ key: 'ad_click' }], yAxisId: 'right' },
                      {
                        name: 'CTR',
                        flavors: [{ key: 'ctr' }],
                        showStatSummary: false,
                      },
                    ]}
                  />
                )}
                {config.includeEmailActivity && (
                  <ActivityCard
                    dailyStats={campaignDailyStats.models}
                    label={reportModuleLabels.includeEmailActivity}
                    lines={
                      campaign.isEmail
                        ? [
                            {
                              name: 'Sends',
                              flavors: [
                                { key: 'pinpoint_tracked_email_send', op: '+' },
                                { key: 'pinpoint_tracked_email_suppression', op: '-' },
                              ],
                            },
                            {
                              name: 'Deliveries',
                              flavors: [{ key: 'pinpoint_tracked_email_delivered' }],
                            },
                            {
                              name: 'Total opens',
                              flavors: [{ key: 'pinpoint_tracked_email_open' }],
                            },
                            {
                              name: 'Total clicks',
                              flavors: [{ key: 'pinpoint_tracked_email_click' }],
                            },
                          ]
                        : [
                            { name: 'Views', flavors: [{ key: 'email_view' }] },
                            {
                              name: 'Clicks',
                              flavors: [{ key: 'email_link_click' }],
                              yAxisId: 'right',
                            },
                            {
                              name: 'CTR',
                              flavors: [{ key: 'ctr' }],
                              showStatSummary: false,
                            },
                          ]
                    }
                  />
                )}
                {campaign.isEmail && config.includeCampaignEngagement && (
                  <CampaignEngagementCard
                    aggregatedStats={aggregatedStats}
                    campaign={campaign}
                    dateRange={{ end, start }}
                    localUrl={localUrl}
                  />
                )}
                {config.includePageActivity && (
                  <ActivityCard
                    dailyStats={campaignDailyStats.models}
                    label={reportModuleLabels.includePageActivity}
                    lines={[
                      { name: 'Views', flavors: [{ key: 'page_view' }] },
                      { name: 'Clicks', flavors: [{ key: 'page_link_click' }], yAxisId: 'right' },
                      {
                        name: 'CTR',
                        flavors: [{ key: 'ctr' }],
                        showStatSummary: false,
                      },
                    ]}
                  />
                )}
              </div>
              {campaign.isEmail && config.includeLinkClicksTable && (
                <LinkClicksTableCard campaign={campaign as PinpointEmailBaseCampaign} />
              )}
              {config.includeActivityBreakdown && !campaign.isPinpointCampaign() && (
                <div style={{ marginBottom: '10px' }}>
                  <ActivityBreakdownCard
                    conversions={numConversions}
                    dailyStats={campaignDailyStats.models}
                  />
                </div>
              )}
              {config.includeROI && showSpend && hasGoals && (
                <ROICard
                  attributionModel={config.attributionModel}
                  dailyStats={campaignDailyStats.models}
                  end={end}
                  goals={goals.models.map((goal) => goal.toJS())}
                  start={start}
                />
              )}
              {config.includeConversionsTable && hasGoals && (
                <ConversionTableCard
                  attributionModel={config.attributionModel}
                  campaigns={[campaign]}
                  end={end}
                  goals={goals.models.map((goal) => goal.toJS())}
                  localUrl={localUrl}
                  model={campaign}
                  start={start}
                  targetings={targetings.models}
                />
              )}
              {campaign.isAdCampaign && config.includeCreativesTable && (
                <CreativeTableCard campaign={campaign} end={end} start={start} />
              )}
              {!campaign.isFacebook &&
                campaign.isAdCampaign &&
                config.includeCampaignEngagement && (
                  <CampaignEngagementCard
                    aggregatedStats={aggregatedStats}
                    campaign={campaign}
                    dateRange={{ end, start }}
                    localUrl={localUrl}
                  />
                )}
              {isReferral && config.includeLeadsSummary && (
                <LeadsSummaryCard
                  attributionModel={config.attributionModel}
                  campaign={campaign}
                  end={end}
                  start={start}
                />
              )}
              {(isReferral || isLanding) && config.includeFormSubmissionsTable && (
                <FormSubmissionsTableCard
                  campaign={campaign}
                  end={end}
                  localUrl={localUrl}
                  start={start}
                />
              )}
              {config.includeDailyStatsTable && (
                <DailyStatsTableCard
                  attributionModel={config.attributionModel}
                  end={end}
                  object={campaign}
                  showConversions={hasGoals}
                  showSpend={showSpend}
                  start={start}
                />
              )}
              {config.includePartnersStatsTable && (
                <PartnersStatsTableCard
                  attributionModel={config.attributionModel}
                  campaignId={campaign.id}
                  end={end}
                  localUrl={localUrl}
                  start={start}
                />
              )}
              {campaign.isGoogle && config.includeKeywordStatsTable && (
                <KeywordStatsTableCard campaign={campaign} keywords={keywords} />
              )}
            </>
          );
        }}
      </Observer>
    </ContextWrapper>
  );
}

export default CampaignReport;
