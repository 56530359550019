import { faArrowRight, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, set } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { DataRequest, IRequestField } from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import {
  Button,
  Collapse,
  CollapsibleCard,
  Fieldset,
  ImagePicker,
  Input,
} from '@feathr/components';

import FieldSuggestions from './FieldSuggestions';

import * as styles from './RequestField.css';

interface IProps {
  requestField: IRequestField;
  request: DataRequest;
}

function RequestField({ requestField, request }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  function removeField(): void {
    request.get('request_fields').remove(requestField);
    request.setAttributeDirty('request_fields');
  }

  function handleNameChange(newValue?: string) {
    set(requestField, { field_name: newValue });
    request.setAttributeDirty('request_fields');
  }

  function handleUrlChange(newValue?: string) {
    set(requestField, { url: newValue });
    request.setAttributeDirty('request_fields');
  }

  return (
    <CollapsibleCard
      actions={[
        <Button
          className={styles.button}
          key={'remove'}
          onClick={removeField}
          prefix={<FontAwesomeIcon icon={faTrash} />}
          title={t('Remove field')}
          type={'naked'}
        />,
      ]}
      className={styles.card}
    >
      <div className={styles.collapsedPreviewContainer} key={'field-preview'}>
        <span>
          <strong>{t('Form field')}:</strong>&nbsp;{requestField.field_name}
        </span>
        <FontAwesomeIcon icon={faArrowRight} size={'lg'} />
        <span>
          <strong>{t('Feathr field')}:</strong>&nbsp;
          {requestField.feathr_attr}
        </span>
      </div>
      <>
        <Fieldset>
          <Input
            helpText={t(
              'The name of the field in your form from which you want to capture the input value.',
            )}
            key={'field_name'}
            label={t('Form field')}
            onChange={handleNameChange}
            type={'text'}
            value={requestField.field_name}
          />
          <FieldSuggestions
            collections={[FieldCollection.Breadcrumb, FieldCollection.Person]}
            includeDefaults={true}
            onChange={(value) => {
              set(requestField, 'feathr_attr', value);
              request.setAttributeDirty('request_fields');
            }}
            value={get(requestField, 'feathr_attr')}
          />
        </Fieldset>
        <Collapse title={'Additional Info'}>
          <Fieldset>
            <Input
              helpText={
                <p>
                  {t(
                    'You can help us make sure we are capturing the right field in a form that spans multiple pages by providing the url where this particular field is found.',
                  )}
                </p>
              }
              key={'url'}
              label={t('URL')}
              onChange={handleUrlChange}
              optional={true}
              type={'url'}
              value={requestField.url}
            />
            <ImagePicker
              buttonText={t('Upload image')}
              helpText={t(
                "A picture's worth a thousand words. Point to exactly the field you would like to collect.",
              )}
              key={'filestack_url'}
              label={t('Screen capture')}
              onPick={(url) => {
                set(requestField, 'filestack_url', url);
                request.setAttributeDirty('request_fields');
              }}
              optional={true}
              value={requestField.filestack_url}
            />
          </Fieldset>
        </Collapse>
      </>
    </CollapsibleCard>
  );
}

export default observer(RequestField);
