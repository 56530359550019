import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { IGoogleAdPreviewProps } from '@feathr/components';
import { Button, CardV2 as Card, GoogleAdPreview } from '@feathr/components';
import { pickRandomItems } from '@feathr/hooks';

import * as styles from './AdPreviewCard.css';

interface IAdPreviewProps
  extends Pick<IGoogleAdPreviewProps, 'showCallButton' | 'websiteUrl' | 'businessName'> {
  readonly headlines: string[];
  readonly descriptions: string[];
}

const LEARN_MORE_LINK_1 = 'https://support.google.com/google-ads/answer/7653455';
const LEARN_MORE_LINK_2 = 'https://support.google.com/google-ads/answer/7652938';

function AdPreviewCard({
  headlines,
  descriptions,
  showCallButton = false,
  websiteUrl,
  businessName,
}: IAdPreviewProps): JSX.Element {
  const { t } = useTranslation();
  const [randomHeadlines, setRandomHeadlines] = useState(pickRandomItems(headlines, 3));
  const [randomDescriptions, setRandomDescriptions] = useState(pickRandomItems(descriptions, 2));

  function handleRegenerate(): void {
    setRandomHeadlines(pickRandomItems(headlines, 3));
    setRandomDescriptions(pickRandomItems(descriptions, 2));
  }

  return (
    <Card contentClassName={styles.card} isSticky name={'ad-preview'}>
      <Card.Header title={t('Ad Preview')}>
        <Button onClick={handleRegenerate} prefix={<FontAwesomeIcon icon={faArrowsRotate} />}>
          {t('Regenerate')}
        </Button>
      </Card.Header>
      <Card.Content>
        <GoogleAdPreview
          businessName={businessName}
          descriptions={randomDescriptions}
          headlines={randomHeadlines}
          showCallButton={showCallButton}
          t={t}
          websiteUrl={websiteUrl}
        />
        <div className={styles.description}>
          <Trans t={t}>
            Assets can be shown in any order, so make sure that they make sense individually or in
            combination and do not violate Google's policies or local law. You can make sure certain
            text appears in your ad.{' '}
            <a href={LEARN_MORE_LINK_1} target={'_blank'}>
              Learn more
            </a>
          </Trans>
        </div>
      </Card.Content>
      <Card.Content
        action={
          <a href={LEARN_MORE_LINK_2} target={'_blank'}>
            {t('Learn more')}
          </a>
        }
        title={t('Need help writing successful ads?')}
      ></Card.Content>
    </Card>
  );
}

export default observer(AdPreviewCard);
