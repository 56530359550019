import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import type {
  Goal,
  IConfig as IBaseConfig,
  IReportAttributes,
  ReportModel,
  TReportClass,
} from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import { StoresContext, useAccount, useUser } from '@feathr/extender/state';
import { useReactionEffect } from '@feathr/hooks';
import type { ListResponse } from '@feathr/rachis';

interface IUseReportConfigProps<
  IAttributes extends IReportAttributes,
  IConfig extends IBaseConfig,
> {
  model: ReportModel<IAttributes>;
  initialConfig: IConfig;
}

export interface IUseReportConfigHandlers<IConfig extends IBaseConfig> {
  temp?: IConfig;
  onConfigChange: (key: any, value: any) => void;
  //   onConfigChange: (key: keyof IConfig, value: any) => void;
}

export interface IUseReportConfigReturn<IConfig extends IBaseConfig> {
  goals: ListResponse<Goal>;
  config: IConfig;
  hasGoals: boolean;
  handlers: IUseReportConfigHandlers<IConfig>;
}

function useReportConfig<IAttributes extends IReportAttributes, IConfig extends IBaseConfig>({
  model,
  initialConfig,
}: IUseReportConfigProps<IAttributes, IConfig>): IUseReportConfigReturn<IConfig> {
  const account = useAccount();
  const user = useUser();

  const { eventId, campaignId, flightId } = useParams<{
    eventId: string;
    campaignId?: string;
    flightId?: string;
  }>();
  const store = useContext(StoresContext);

  // Load in user's reports_config setting if it exists
  const reportClass: TReportClass =
    model.className === 'campaign' ? model.get('_cls') : (model.className as TReportClass);
  const userReportsConfig = user.getReportsConfigSetting(reportClass, initialConfig);

  const [config, setConfig] = useState<IConfig>({
    ...initialConfig,
    ...userReportsConfig[reportClass],
  });

  let goals = store.Goals.newListResponse();
  if (campaignId || flightId) {
    goals = store.Goals.list({
      filters: {
        _parent: campaignId ?? flightId,
        is_archived__ne: true,
      },
    });
  } else if (eventId) {
    const campaigns = store.Campaigns.list({
      filters: {
        _parent: eventId,
        is_archived__ne: true,
        state__ne: CampaignState.Draft,
        _cls__ne: CampaignClass.PinpointPartnerMessage,
      },
    });
    const flights = store.Flights.list({
      filters: {
        event_id: eventId,
        is_archived__ne: true,
      },
    });
    const flightlessCampaigns = campaigns.models.filter((cpn) => !cpn.get('flight'));
    goals = store.Goals.list({
      filters: {
        _parent__in: flights.models
          .map((f) => f.id)
          .concat(flightlessCampaigns.map((cpn) => cpn.id)),
        is_archived__ne: true,
      },
    });
  }

  const hasGoals = goals.models.length > 0;

  useReactionEffect(
    () => !!account && !account.isPending,
    () => {
      if (!account || account.isPending) {
        return;
      }
      setConfig((newConfig) => ({
        ...newConfig,
        attributionModel:
          sessionStorage.getItem(`${model.id}_attributionModel`) ??
          account.get('attribution_model', 'full'),
      }));
    },
  );

  useEffect(() => {
    if (goals.models.length > 0) {
      setConfig((newConfig) => ({
        ...newConfig,
        includeROI: true,
        includeConversionsTable: true,
      }));
    }
  }, [goals.isPending, goals.models.length]);

  function onConfigChange(key: keyof IConfig, value: any): void {
    const updatedConfig = {
      ...config,
      [key]: value,
    };

    // Update user's reports_config setting with latest changes
    userReportsConfig[reportClass] = updatedConfig;
    user?.setReportsConfigSetting(userReportsConfig);

    setConfig(updatedConfig);
    if (key === 'attributionModel') {
      sessionStorage.setItem(`${model.id}_attributionModel`, value);
    }
  }

  return {
    config,
    goals,
    hasGoals,
    handlers: {
      onConfigChange,
    },
  };
}

export default useReportConfig;
