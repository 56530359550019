import { useState } from 'react';

import { generateKeys } from './utils';

type TUniqueItem<T> = T extends object ? T & { key: string } : { key: string; value: T };

type TNonUniqueItem<T> = { key?: string; value: T };

/**
 * Generates unique keys for items in a list of any type.
 * Sets unique keys on new objects, so key does not have to be generated
 * inside components.
 */
export function useUniqueKeys<T>(
  init: T[],
): [Array<TUniqueItem<T>>, (newItems: Array<TNonUniqueItem<T> | TUniqueItem<T>>) => void] {
  // Generate initial unique keys for the provided items
  const initial = generateKeys(
    init.map((item) => (typeof item === 'object' && item !== null ? { ...item } : { value: item })),
  ) as Array<TUniqueItem<T>>;

  const [value, dispatch] = useState(initial);

  // Function to update the list of items with new items, generating unique keys as needed
  function setValue(newItems: Array<TNonUniqueItem<T> | TUniqueItem<T>>): void {
    dispatch(
      generateKeys(
        newItems.map((item) =>
          typeof item === 'object' && item !== null ? { ...item } : { value: item },
        ),
      ) as Array<TUniqueItem<T>>,
    );
  }

  return [value, setValue];
}
