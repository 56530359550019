import type { JSX, ReactNode } from 'react';
import React from 'react';

import { Session } from '@feathr/blackbox';
import { ErrorBoundary } from '@feathr/components';

import { NoUserError } from '../../state/useUser';

interface IProps {
  children: ReactNode;
}

function UserErrorBoundary({ children }: IProps): JSX.Element {
  function handleError(error: Error): void {
    if (error instanceof NoUserError && process.env.NODE_ENV !== 'development') {
      Session.logOut(() => {
        window.location.assign(
          `${Session.logInUrl}?from=${encodeURIComponent(window.location.href)}`,
        );
      });
    }
  }

  return <ErrorBoundary onError={handleError}>{children}</ErrorBoundary>;
}

export default UserErrorBoundary;
