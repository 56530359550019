import { faBracketsCurly, faEdit, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer } from 'mobx-react-lite';
import React from 'react';

import type { ReferralCampaign } from '@feathr/blackbox';
import { Button, TableColumnHeader, Tooltip } from '@feathr/components';
import type { IConfirmButtonProps } from '@feathr/extender/components/ConfirmButton';
import ConfirmButton from '@feathr/extender/components/ConfirmButton';
import type { ITemplateRow } from '@feathr/extender/components/TemplatesTable/TemplatesTable';
import { useLocalUrl } from '@feathr/extender/state';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './TemplateColumnOptions.css';

function getHashStep() {
  const matchedHash = /\#step(\d{1})$/.exec(location.hash);
  return matchedHash ? +matchedHash[1] : undefined;
}

const TemplateColumnOptions = {
  id: 'options',
  Header: TableColumnHeader({
    title: 'Options',
  }),
  width: 150,
  sortable: false,
  className: tableStyles.cellCenter,
  Cell({ original }: ITemplateRow) {
    return (
      <Observer>
        {function useAnonymousFunction() {
          const localUrl = useLocalUrl();
          const { campaign, template } = original;

          function handleClickRemove() {
            (campaign as ReferralCampaign).removeTemplate(template.id);
            template.archive();
          }

          const confirmButtonProps = {
            buttonClassName: styles.optionsButton,
            disabled: campaign!.isPending || template.isUpdating,
            buttonType: 'naked' as IConfirmButtonProps['buttonType'],
            element: 'Button' as IConfirmButtonProps['element'],
            model: campaign as ReferralCampaign,
            step: getHashStep(),
          };

          return (
            <>
              <Tooltip title={'Edit metadata'}>
                <ConfirmButton
                  {...confirmButtonProps}
                  description={
                    <p>
                      Before you can edit this metadata, the changes to your campaign need to be
                      saved.
                    </p>
                  }
                  prefix={<FontAwesomeIcon icon={faBracketsCurly} />}
                  redirect={localUrl(template.getItemUrl('edit/metadata'))}
                  title={'Edit metadata'}
                />
              </Tooltip>
              <Tooltip title={'Edit template'}>
                <ConfirmButton
                  {...confirmButtonProps}
                  description={
                    <p>
                      Before you can edit this template, the changes to your campaign need to be
                      saved.
                    </p>
                  }
                  prefix={<FontAwesomeIcon icon={faEdit} />}
                  redirect={localUrl(template.getItemUrl('edit/design'))}
                  title={'Edit Template'}
                />
              </Tooltip>
              <Tooltip title={'Remove template'}>
                <Button
                  className={styles.optionsButton}
                  disabled={campaign!.isPending}
                  onClick={handleClickRemove}
                  prefix={<FontAwesomeIcon icon={faTrash} />}
                  type={'naked'}
                />
              </Tooltip>
            </>
          );
        }}
      </Observer>
    );
  },
};

export default TemplateColumnOptions;
