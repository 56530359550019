import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Event } from '@feathr/blackbox';
import {
  Button,
  CampaignsIcon,
  Card,
  FlightsIcon,
  Icon,
  PartnersIcon,
  Skeleton,
  TimeRange,
  Tooltip,
} from '@feathr/components';
import Tags from '@feathr/extender/components/Tags';
import { useAccount, useLocalUrl } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';
import * as styles from './EventCard.css';

import noImg from 'images/no-img.png';

interface IProps {
  item?: Event;
  loading?: boolean;
}

function truncate(name: string): string {
  if (name.length > 56) {
    return `${name.slice(0, 54)}...`;
  }
  return name;
}

function FlightsEventGridItem({ item, loading = false }: IProps): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  if (loading || !item) {
    return (
      <Card
        actions={[
          <Button disabled={true} key={'flights'} type={'tertiary'}>
            <Icon icon={FlightsIcon} />
          </Button>,
          account.isFalcon && (
            <Button disabled={true} key={'partners'} type={'tertiary'}>
              <Icon icon={PartnersIcon} />
            </Button>
          ),
          <Button disabled={true} key={'campaigns'} type={'tertiary'}>
            <Icon icon={CampaignsIcon} />
          </Button>,
        ]}
        className={styles.root}
        cover={
          <span className={classNames(styles.icon, skeleton)}>
            <img alt={''} src={noImg} />
          </span>
        }
        loading={true}
        title={'Justo Sit Bibendum'}
        titleTruncated={true}
      >
        <TimeRange className={styles.subtext} format={TimeFormat.shortDate} loading={true} />
        <Skeleton className={styles.tags} />
      </Card>
    );
  }

  const logo = item.get('logo');
  const dateEnd = item.get('date_end');
  const dateStart = item.get('date_start');
  const tags = item.get('tag_ids', []);

  return (
    <Card
      actions={[
        <Tooltip key={'flights'} title={t('Flights')}>
          <Button link={localUrl(item.getItemUrl('flights'))} type={'tertiary'}>
            <Icon icon={FlightsIcon} />
          </Button>
        </Tooltip>,
        account.isFalcon && (
          <Tooltip key={'partners'} title={t('Partners')}>
            <Button link={localUrl(item.getItemUrl('partners'))} type={'tertiary'}>
              <Icon icon={PartnersIcon} />
            </Button>
          </Tooltip>
        ),
        <Tooltip key={'campaigns'} title={t('Campaigns')}>
          <Button link={localUrl(item.getItemUrl('campaigns'))} type={'tertiary'}>
            <Icon icon={CampaignsIcon} />
          </Button>
        </Tooltip>,
      ]}
      className={styles.root}
      cover={
        <span className={styles.icon}>
          <img alt={''} src={logo ? logo : noImg} />
        </span>
      }
      link={localUrl(item.getItemUrl('campaigns'))}
      title={truncate(item.name)}
      titleTruncated={true}
    >
      {dateEnd || dateStart ? (
        <TimeRange
          className={styles.subtext}
          end={dateEnd}
          format={TimeFormat.shortDate}
          start={dateStart}
        />
      ) : (
        <div className={styles.subtext}>&#160;</div>
      )}
      {tags.length ? (
        <Tags className={styles.tags} value={tags} />
      ) : (
        <div className={styles.tags}>
          <span>&#160;</span>
        </div>
      )}
    </Card>
  );
}

export default observer(FlightsEventGridItem);
