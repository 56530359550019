import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { IStats } from '@feathr/blackbox';
import { Alert, AlertType, Superpixel } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useAccount } from '@feathr/extender/state';

function SuperPixelPage(): JSX.Element {
  const account = useAccount();
  const stats = account.get('stats', { num_crumbs: 0 } as IStats);

  const params = new URLSearchParams(window.location.search);

  return (
    <Page title={'Your Super Pixel'} width={'wide'}>
      {params.get('nodata') && (
        <Alert type={AlertType.info}>
          <p>
            Welcome to your Feathr database! This is where you'll find all the analytics data that
            you collect through the Super Pixel, and where you'll be able to build actionable
            audience groups to use in your campaigns and goals. If you haven't already, please
            follow the instructions below to install the super pixel on your website(s) and start
            collecting information about your digital audience.
          </p>
        </Alert>
      )}
      <Superpixel
        crumbs={stats.num_crumbs || 0}
        hasServices={account.hasServices}
        id={account.id}
        loading={account.isPending}
      />
    </Page>
  );
}

export default observer(SuperPixelPage);
