import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Skeleton, TableColumnHeader } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

const ProjectColumn: IColumn<BaseCampaign> = {
  id: 'project',
  checkboxLabel: 'Project',
  Header: TableColumnHeader({
    title: 'Project',
  }),
  sortable: false,
  // width value in figma
  width: 223,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          const localUrl = useLocalUrl();
          const { Events } = useContext(StoresContext);
          const projectId = original.get('event');
          const project = Events.get(projectId);

          if (project.isPending) {
            return <Skeleton />;
          }
          const to = localUrl(project.getItemUrl());
          return <Link to={to}>{project.get('name')}</Link>;
        }}
      </Observer>
    );
  },
};

export default ProjectColumn;
