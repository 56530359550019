import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { Breadcrumb, Campaign, IBreadcrumb } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { Button, Table, TableStatsCard } from '@feathr/components';
import { useToggle } from '@feathr/hooks';
import type { IListParams, IRachisMessage } from '@feathr/rachis';
import { wretch } from '@feathr/rachis';

import { StoresContext } from '../../state';
import ExportModal from '../ExportModal';
import { FormSubmissionsTableColumns } from './FormSubmissionsTableColumns';

interface IProps {
  campaign: Campaign;
  end: string;
  localUrl?: (url: string) => string;
  start: string;
}

function FormSubmissionsTableCard({ campaign, end, localUrl, start }: IProps): JSX.Element {
  const [isExportModalOpen, toggleExportModalOpen] = useToggle(false);
  const { Breadcrumbs } = useContext(StoresContext);
  const inclusiveEndDate = new Date(
    new Date(end).setDate(new Date(end).getDate() + 1),
  ).toISOString();
  const { t } = useTranslation();
  const constraints = {
    kind: 'activity',
    attr_against: 'form_type',
    attr_type: 'string',
    comparison: 'eq',
    value: 'feathr',
    group: [
      {
        kind: 'activity',
        attr_against: 'form_type',
        attr_type: 'string',
        comparison: 'eq',
        value: 'feathr',
      },
      {
        kind: 'activity',
        attr_against: 'd_c',
        attr_type: 'date',
        comparison: 'lte',
        value: inclusiveEndDate,
      },
      {
        kind: 'activity',
        attr_against: 'd_c',
        attr_type: 'date',
        comparison: 'gte',
        value: start,
      },
      {
        kind: 'activity',
        attr_against: 'cpn_id',
        attr_type: 'string',
        comparison: 'eq',
        value: campaign.id,
      },
    ],
  };

  async function handleConfirmExport(email: string): Promise<void> {
    const exportParams = new URLSearchParams({
      [campaign.className.toLowerCase()]: campaign.id,
      start,
      end,
      email,
    });
    // TODO: Add error handling.
    await wretch<IRachisMessage>(
      `${Breadcrumbs.getHostname()}leads/export?${exportParams.toString()}`,
      {
        method: 'GET',
        headers: Breadcrumbs.getHeaders(),
      },
    );
  }

  const actions = [
    <Button key={'export'} onClick={toggleExportModalOpen}>
      {t('Export to CSV')}
    </Button>,
  ];

  return (
    <>
      <TableStatsCard actions={actions} title={reportModuleLabels.includeFormSubmissionsTable}>
        <Table<Breadcrumb>
          collection={Breadcrumbs}
          columns={FormSubmissionsTableColumns(campaign.get('_cls'), localUrl)}
          initialPagesize={10}
          initialSort={[{ id: 'd_c' }]}
          noDataText={t('No form submissions')}
          params={
            {
              predicates: toJS([constraints]),
              lookback_mode: 'unbounded',
            } as IListParams<IBreadcrumb>
          }
        />
      </TableStatsCard>
      {isExportModalOpen && (
        <ExportModal
          onClose={toggleExportModalOpen}
          onConfirm={handleConfirmExport}
          title={t('Export Form Submissions')}
        />
      )}
    </>
  );
}

export default observer(FormSubmissionsTableCard);
