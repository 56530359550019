import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Fieldset, Input, ModalV1 } from '@feathr/components';
import { useToggle } from '@feathr/hooks';
import { validate } from '@feathr/rachis';

interface IConnectButtonProps {
  readonly onClick: (id: string) => Promise<void>;
}

function ConnectButton({ onClick }: IConnectButtonProps): JSX.Element | null {
  const { t } = useTranslation();
  const [customerAccount, setCustomerAccount] = useState('');
  const [showConnectModal, toggleConnectModal] = useToggle(false);

  function handleCustomerAccountChange(newValue?: string): void {
    setCustomerAccount(newValue ?? '');
  }

  function handleConnect(): void {
    onClick(customerAccount);
    toggleConnectModal();
  }

  function validateCustomerAccount(): string | undefined {
    const error: Array<string | undefined> = validate.single(customerAccount, {
      presence: {
        allowEmpty: false,
        message: t("Can't be blank"),
      },
      format: {
        pattern: /^(?:\d{10}|\d{3}-\d{3}-\d{4})$/,
        flags: 'i',
        message: t('Must be a valid customer account or ID number'),
      },
    });
    return error ? error[0] : undefined;
  }

  return (
    <>
      <Button name={'connect_button'} onClick={toggleConnectModal}>
        {t('Connect')}
      </Button>
      {showConnectModal && (
        <ModalV1
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Request access')}
          confirmDisabled={!!validateCustomerAccount()}
          controlled={true}
          onClose={toggleConnectModal}
          onConfirm={handleConnect}
          size={'sm'}
          t={t}
          title={t('Request Account Connection')}
        >
          <Fieldset>
            <Input
              label={t('Customer account or ID number')}
              onChange={handleCustomerAccountChange}
              type={'text'}
              validationError={validateCustomerAccount()}
              value={customerAccount}
            />
          </Fieldset>
        </ModalV1>
      )}
    </>
  );
}

export default observer(ConnectButton);
