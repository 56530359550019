import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { WorkBook } from 'xlsx';
import { read, utils } from 'xlsx';

import type { IImportColumn, Importer } from '@feathr/blackbox';
import { Button, ButtonValid, Fieldset, FileUpload, Form, Input } from '@feathr/components';
import TagsField from '@feathr/extender/components/TagsField';

import * as styles from './DataImportPage.css';

interface IProps {
  disabled?: boolean;
  importer: Importer;
  onNext: () => void;
}

interface IButtonProps {
  importer: Importer;
  onNext: () => void;
}

const NextStepButton = observer(({ importer, onNext }: IButtonProps) => {
  const { t } = useTranslation();

  const validationErrors = validateStepOne(importer);
  if (importer.get('column_mappers').length < 1) {
    validationErrors.push(t('You must have at least 1 column that maps to Email.'));
  } else if (!importer.get('rows') || importer.get('rows') < 1) {
    validationErrors.push(t('You must have at least 1 row to import data.'));
  }
  return (
    <ButtonValid errors={validationErrors} id={'goToStepTwo'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

export const validateStepOne = (importer: Importer): IObservableArray<string> => {
  return toJS(importer.validate(['name', 'file_name', 'data_key', 'tag_ids'], false).errors);
};

const processWorkbook = (workbook: WorkBook): [string[][], string[]] => {
  const data = utils.sheet_to_json<string[]>(workbook.Sheets[workbook.SheetNames[0]], {
    header: 1,
    blankrows: false,
  });
  const columns = data.shift() ?? [];
  return [data, columns];
};

function DataImportStepOne({ disabled = false, importer, onNext }: IProps): JSX.Element {
  const { t } = useTranslation();

  const actions = [<NextStepButton importer={importer} key={'nextStep'} onNext={onNext} />];

  function setTagIds(val: string[]): void {
    importer.set({ tag_ids: val });
  }

  function handleUpload(key: string, container: string, file: File, filename: string): void {
    const reader = new FileReader();

    function onLoad(): void {
      if (reader.result && reader.result instanceof ArrayBuffer) {
        const array = new Uint8Array(reader.result);
        const workbook = read(array, { type: 'array' });
        const [r, c] = processWorkbook(workbook);

        // Remove possible elisions (holes in array) caused by empty columns in source data
        const rows = [...r].map((row) => [...row].map((column) => column ?? ''));
        const columns = [...c].map((column) => column ?? '');

        importer.set({
          data_key: key,
          data_bucket: container,
          file_name: filename,
          name: importer.get('name'),
          column_names: columns,
          column_mappers: observable.array(
            columns.map(
              (columnName) =>
                ({
                  column_name: columnName,
                  feathr_attr: undefined,
                  attr_type: undefined,
                }) as IImportColumn,
            ),
          ),
          rows: rows.length,
        });
      }
    }

    reader.addEventListener('load', onLoad);
    reader.readAsArrayBuffer(file);
  }

  function clearFile(): void {
    importer.unset('data_key');
    importer.unset('data_bucket');
    importer.unset('file_name');
    importer.unset('column_names');
    importer.set({ column_mappers: [], rows: 0 });
  }

  const description = importer.isOptOut
    ? t(
        'Upload a spreadsheet of email addresses to import as an opt out list. Every Person connected with each email address in your import will be unsubscribed from all of your email campaigns, whether they already exist in your account or are being imported for the first time. You may also include additional CRM data to attach to each opted out Person your import creates.',
      )
    : t(
        'Upload a spreadsheet of email addresses to import into your database as person records. You may also include additional CRM data to attach to each person identified by email address.',
      );

  return (
    <Form actions={actions} description={description} label={t('Upload')}>
      <Fieldset>
        <Input
          attribute={'name'}
          disabled={disabled}
          label={t('Name')}
          model={importer}
          type={'text'}
        />
      </Fieldset>
      <Fieldset>
        {!importer.isOptOut && (
          <TagsField
            context={'person'}
            disabled={disabled}
            helpText={t(
              'Tagging is the best way to group your imports. Include one or more tags to ensure you can find your lists for future email campaigns.',
            )}
            label={t('Tags')}
            onChange={setTagIds}
            value={importer.get('tag_ids', [])}
          />
        )}
      </Fieldset>
      <Fieldset direction={'column'}>
        <FileUpload
          attribute={'file'}
          disabled={disabled}
          id={'upload'}
          model={importer}
          onUpload={handleUpload}
          pickerOptions={{
            accept: [
              'text/comma-separated-values',
              'text/csv',
              'application/csv',
              '.csv',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              '.xlsx',
            ],
            fromSources: [
              'local_file_system',
              'imagesearch',
              'facebook',
              'instagram',
              'googledrive',
            ],
            maxFiles: 1,
            // Set 50MB limit.
            maxSize: 50 * 1024 * 1024,
            exposeOriginalFile: true,
            storeTo: {
              location: 's3',
              container: 'feathr-import-data',
              access: 'private',
              region: 'us-east-1',
            },
          }}
          wrapperClassName={styles.upload}
        />
        <div className={styles.filename}>
          <Input
            attribute={'file_name'}
            disabled={true}
            helpPlacement={'bottom'}
            helpText={'accepts .xlsx and .csv files'}
            model={importer}
            suffix={
              importer.get('file_name') ? (
                <Button onClick={clearFile} type={'naked'}>
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              ) : undefined
            }
            type={'text'}
          />
          {importer.get('column_mappers') && importer.get('column_mappers').length > 0 && (
            <p>
              {t('Number of columns: {{columnCount}}', {
                columnCount:
                  importer.get('column_mappers') && importer.get('column_mappers').length,
              })}
              {importer.get('column_mappers') && importer.get('column_mappers').length >= 1 ? (
                <FontAwesomeIcon className={styles.valid} icon={faCheck} />
              ) : (
                <FontAwesomeIcon className={styles.invalid} icon={faTimes} />
              )}
            </p>
          )}
          {importer.get('rows', 0) > 0 && (
            <p>
              {t('Number of rows: {{rowCount}}', {
                rowCount: numeral(importer.get('rows')).format('0,0'),
              })}
              {importer.get('rows') > 0 ? (
                <FontAwesomeIcon className={styles.valid} icon={faCheck} />
              ) : (
                <FontAwesomeIcon className={styles.invalid} icon={faTimes} />
              )}
            </p>
          )}
        </div>
      </Fieldset>
    </Form>
  );
}

export default observer(DataImportStepOne);
