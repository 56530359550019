import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';

import { EUsageMetrics, FieldCollection } from '@feathr/blackbox';
import { Button, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import CustomFieldsTable from '@feathr/extender/components/CustomFieldsTable';
import { useLocalUrl, useUsageAndQuota } from '@feathr/extender/state';

function DataFieldsPage(): JSX.Element {
  const localUrl = useLocalUrl();
  const location = useLocation();
  const { t } = useTranslation();
  const { accountId } = useParams<{ accountId: string }>();
  const { getUsageText } = useUsageAndQuota(accountId, [EUsageMetrics.CustomDataFields]);

  const collection =
    location.hash === '#activity' ? FieldCollection.Breadcrumb : FieldCollection.Person;

  const actions = (
    <Toolbar>
      <Button
        link={localUrl(`/data/custom-fields/add`)}
        prefix={<FontAwesomeIcon icon={faPlus} />}
        type={'primary'}
      >
        {t('Create custom field')}
      </Button>
    </Toolbar>
  );

  return (
    <Page actions={actions} secondaryText={getUsageText()} title={t('Custom Fields')}>
      <CustomFieldsTable collection={collection} showSelect={true} />
    </Page>
  );
}

export default observer(DataFieldsPage);
