import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomField } from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import { Button, Form, SaveButtonValid } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { flattenErrors, useRedirect } from '@feathr/hooks';

import type { IDataFieldErrors } from '../DataFieldEditPage';
import DataFieldSummary from './DataFieldSummary';

interface IProps {
  field: CustomField;
  onPrev: () => void;
  validate: (field: CustomField) => IDataFieldErrors;
}

function DataFieldEditSummary({ field, onPrev, validate }: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const [redirect] = useRedirect();
  const { t } = useTranslation();

  function handleSave(): void {
    const collection = field.get('collection');
    const hash = collection === FieldCollection.Breadcrumb ? '#activity' : '#attribute';
    redirect(localUrl(`/data/custom-fields${hash}`));
  }

  const validationErrors = validate(field);
  const actions = [
    <Button key={'previous'} onClick={onPrev}>
      {t('Previous')}
    </Button>,
    <SaveButtonValid<CustomField>
      errors={flattenErrors(validationErrors)}
      key={'save'}
      method={field.isEphemeral ? 'add' : 'patch'}
      model={field}
      onSave={handleSave}
    >
      {t('Submit')}
    </SaveButtonValid>,
  ];

  return (
    <Form
      actions={actions}
      description={t('Check the summary below to make sure everything is configured correctly.')}
      label={t('Create Custom Field: Review')}
    >
      <DataFieldSummary field={field} validationErrors={validationErrors} />
    </Form>
  );
}

export default observer(DataFieldEditSummary);
