import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Prism from 'prismjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Segment } from '@feathr/blackbox';
import { AlertV2, CopyToClipboardButton, EAlertV2Type } from '@feathr/components';
import { DOMPurify } from '@feathr/hooks';

interface IProps {
  category?: string;
  segment: Segment;
}

function ConversionPixelCode({ category, segment }: IProps) {
  const { t } = useTranslation();

  function ref(node: HTMLElement) {
    if (node) {
      Prism.highlightElement(node);
    }
  }

  function getSprinkleString(s: Segment) {
    return `// ${t('The following function invocation is for illustration purposes only.')}
// ${t(
      'You will have to provide your own means of deriving the monetary value of your conversion sprinkle.',
    )}
let shoppingCartValue = getShoppingCartValue();
// ${t(
      'Invoke the feathr function with the following parameters to sprinkle a conversion breadcrumb.',
    )}
feathr(
  'convert',
  '${s.id}',
  {
    amount: shoppingCartValue, // ${t('number, required')}
    currency: 'USD', // ${t("string, optional, defaults to 'USD'")}
    category: '${
      category ? DOMPurify.sanitize(category).replace(/'/g, "\\'") : 'Conversion'
    }' // ${t("string, optional, defaults to 'Conversion'")}
  }
);`;
  }

  return (
    <>
      <AlertV2
        title={t(
          'Add the following code to your Feathr Super Pixel implementation to sprinkle when someone does the activity that you want to track as a conversion.',
        )}
        type={EAlertV2Type.info}
      />
      <pre>
        <code className={'language-javascript'} ref={ref}>
          {getSprinkleString(segment)}
        </code>
      </pre>
      <CopyToClipboardButton
        prefix={<FontAwesomeIcon icon={faCopy} />}
        t={t}
        text={getSprinkleString(segment)}
      />
    </>
  );
}

export default ConversionPixelCode;
