import isEqual from 'lodash.isequal';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Person, TPersonCustomDataValue } from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import { CardV2 as Card, Fieldset, SaveButton, Spinner, Toggle } from '@feathr/components';
import { StoresContext, useActionBar } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import FieldInput from './FieldInput';

import * as styles from './CustomFields.css';

interface IProps {
  readonly person: Person;
}

function CustomFields({ person }: IProps): JSX.Element {
  const { t } = useTranslation();
  const [hideBlankFields, toggleHideBlankFields] = useToggle(
    JSON.parse(localStorage.getItem('hideBlankFields') || 'false'),
  );

  const { CustomFields } = useContext(StoresContext);
  const fields = CustomFields.list({
    filters: {
      collection: FieldCollection.Person,
      is_archived__ne: true,
    },
    pagination: { page_size: 1000 },
  });
  const { setRightActions } = useActionBar();

  const customData = person.get('custom_data');

  function handleChange(key: string, newValue: TPersonCustomDataValue): void {
    const updatedCustomData = { ...customData, [key]: newValue };
    if (newValue === undefined) {
      delete updatedCustomData[key];
    }

    runInAction(() => {
      person.set({ custom_data: updatedCustomData });
      if (isEqual(updatedCustomData, person.shadowAttributes.custom_data)) {
        person.setAttributeClean('custom_data');
      }
    });
  }

  function handleToggleHideBlankFields(): void {
    toggleHideBlankFields();
    localStorage.setItem('hideBlankFields', JSON.stringify(!hideBlankFields));
  }

  useEffect(() => {
    setRightActions(
      <SaveButton disabled={!person.isDirty} method={'patch'} model={person}>
        {t('Save')}
      </SaveButton>,
    );
  }, [t, person, person.isDirty, setRightActions]);

  function renderInput(field): JSX.Element | null {
    if (hideBlankFields && field.get('data_type') !== 'bool' && !customData[field.get('u_key')]) {
      return null;
    }

    return (
      <FieldInput
        customData={customData}
        field={field}
        key={field.get('u_key')}
        onChange={handleChange}
        value={customData[field.get('u_key')]}
      />
    );
  }

  return (
    <div className={styles.root}>
      <Card>
        <Card.Header title={t('Custom fields')} />
        <Card.Content>
          <div className={styles.toggle}>
            <Toggle
              label={t('Hide blank fields')}
              layout={'well'}
              onChange={handleToggleHideBlankFields}
              value={hideBlankFields}
            />
          </div>
          {fields.isPending ? (
            <Spinner />
          ) : (
            <Fieldset>{fields.models.map((field) => renderInput(field))}</Fieldset>
          )}
        </Card.Content>
      </Card>
    </div>
  );
}

export default observer(CustomFields);
