import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import type { Font } from '@feathr/blackbox';
import {
  Button,
  Fieldset,
  FileUpload,
  Form,
  Input,
  SaveButtonValid,
  Value,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import useAccount from '@feathr/extender/state/useAccount';
import { useId } from '@feathr/hooks';

import * as styles from './FontEditPage.css';

interface ISaveButtonProps {
  font: Font;
  onSave: () => Promise<void>;
}

const SaveButton = observer(({ font, onSave }: ISaveButtonProps) => {
  const validationErrors = font.validate([], false);
  return (
    <SaveButtonValid<Font>
      errors={toJS(validationErrors.errors)}
      method={font.isEphemeral ? 'add' : 'patch'}
      model={font}
      onSave={onSave}
    >
      Save
    </SaveButtonValid>
  );
});

function FontEditPage(): JSX.Element {
  const account = useAccount();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { fontId } = useParams<{ fontId: string }>();

  const { Fonts } = useContext(StoresContext);
  const [font] = useState<Font>(fontId === 'add' ? Fonts.create() : Fonts.get(fontId));
  const [fontFaceLoaded, setFontFaceLoaded] = useState(false);
  const elementId = useId();

  async function onSave() {
    history.push(localUrl('/settings/account/fonts'));
  }

  function handleClearFilename() {
    font.set({ filename: '', key: '' });
    setFontFaceLoaded(false);
  }

  useEffect(() => {
    if (font.get('key')) {
      const fontFace = new FontFace(
        'sample',
        `url(https://feathr-api-fonts.s3.amazonaws.com/${encodeURIComponent(font.get('key'))})`,
      );
      fontFace.load().then(() => {
        document.fonts.add(fontFace);
        setFontFaceLoaded(true);
      });
    } else {
      setFontFaceLoaded(false);
    }
  }, [font.get('key')]);

  return (
    <Page loading={font.isPending} title={font.name}>
      <Form
        actions={[
          <SaveButton font={font} key={'save'} onSave={onSave} />,
          <Button key={'cancel'} onClick={onSave}>
            Cancel
          </Button>,
        ]}
        label={'Edit font'}
      >
        <Fieldset>
          <FileUpload
            attribute={'filename'}
            disabled={!!font.get('filename')}
            helpText={'Accepts .eot, .otf, .svg, .ttf, .woff, and .woff2 files.'}
            id={elementId}
            label={'Upload a font file'}
            model={font}
            onUpload={(key, __, ___, originalFilename) => {
              font.set({
                name: originalFilename?.split('.')[0],
                filename: originalFilename,
                key,
              });
            }}
            pickerOptions={{
              accept: ['.eot', '.otf', '.svg', '.ttf', '.woff', '.woff2'],
              maxFiles: 1,
              exposeOriginalFile: true,
              storeTo: {
                location: 's3',
                container: `feathr-api-fonts/${account.id}`,
                access: 'public',
                region: 'us-east-1',
              },
            }}
          />
          {!!font.get('filename') && (
            <>
              <label>Uploaded file</label>
              <Value
                className={styles.filename}
                isClearable={true}
                isLoading={font.isPending}
                onClear={handleClearFilename}
                type={'pre'}
                value={font.get('filename')}
              />
              <Input
                attribute={'name'}
                helpText={
                  'This is what the font will be called in the template editor. Must be unique among your fonts.'
                }
                label={'Font name'}
                model={font}
                type={'text'}
              />
              {fontFaceLoaded && (
                <>
                  <label>Sample</label>
                  <p style={{ fontFamily: 'sample', fontSize: '3rem', lineHeight: 1 }}>
                    Sphinx of black quartz, judge my vow.
                  </p>
                </>
              )}
            </>
          )}
        </Fieldset>
      </Form>
    </Page>
  );
}

export default observer(FontEditPage);
