import classNames from 'classnames';
import type { JSX } from 'react';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Nav, NavItem, Time } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { stringifyBreadcrumbs, TimeFormat } from '@feathr/hooks';

import { label, path } from './routes';
import type { IMatchProps } from './routes/routes';
import { getRoute } from './routes/routes';

import styles from './Breadcrumbs.css';

interface IProps {
  className?: string;
  includeCurrentPage?: boolean;
}

function Breadcrumb(
  matches: IMatchProps,
  key: keyof IMatchProps,
  depth: number,
): JSX.Element | null {
  const localUrl = useLocalUrl();

  // eslint-disable-next-line react/destructuring-assignment
  const match = matches[key];
  if (!match) {
    return null;
  }
  const item = getRoute(matches, depth);

  const itemLabel = item ? label(item, match, matches) : null;
  return item && itemLabel ? (
    <NavItem className={styles.crumb} key={key} to={localUrl(path(item, depth, matches))}>
      {itemLabel}
    </NavItem>
  ) : null;
}

function Breadcrumbs({ className, includeCurrentPage = true }: IProps): JSX.Element | null {
  const match = useRouteMatch<{
    accountId: string;
    primary: string;
    secondary?: string;
    tertiary?: string;
    quaternary?: string;
    quinary?: string;
    senary?: string;
    septenary?: string;
    octonary?: string;
  }>({
    path: '/:accountId/:primary/:secondary?/:tertiary?/:quaternary?/:quinary?/:senary?/:septenary?/:octonary?',
  });

  if (!match) {
    return null;
  }

  const crumbs = [
    Breadcrumb(match.params, 'primary', 1),
    Breadcrumb(match.params, 'secondary', 2),
    Breadcrumb(match.params, 'tertiary', 3),
    Breadcrumb(match.params, 'quaternary', 4),
    Breadcrumb(match.params, 'quinary', 5),
    Breadcrumb(match.params, 'senary', 6),
    Breadcrumb(match.params, 'senary', 6),
  ].filter((crumb) => !!crumb) as JSX.Element[];

  // Grab url segments and filter out undefined ones
  const urlSegments = Object.entries(match.params).filter((item) => !!item[1]);
  const appCuesStringifiedCrumbs = stringifyBreadcrumbs(urlSegments);

  // Show date if the page is the account dashboard.
  const date =
    match.params.primary === 'dashboard' ? (
      <Time format={TimeFormat.longWeekdayDate} timestamp={new Date().toISOString()} />
    ) : null;

  return date || includeCurrentPage || crumbs.length > 1 ? (
    <Nav
      appCuesTrigger={appCuesStringifiedCrumbs}
      className={classNames(styles.root, className)}
      label={'Breadcrumbs'}
      ordered={true}
    >
      {date ?? crumbs.map((crumb) => crumb).slice(0, crumbs.length - (includeCurrentPage ? 0 : 1))}
    </Nav>
  ) : null;
}

export default Breadcrumbs;
