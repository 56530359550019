import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ICreditCardSource } from '@feathr/blackbox';
import {
  AmexCardIcon,
  DinersClubCardIcon,
  DiscoverCardIcon,
  Icon,
  JCBCardIcon,
  MastercardCardIcon,
  UnionPayCardIcon,
  UnknownPaymentCardIcon,
  VisaCardIcon,
} from '@feathr/components';

import PaymentMethod from '../PaymentMethod';

import * as styles from './CreditCard.css';

const BRAND_ICONS = {
  Visa: VisaCardIcon,
  MasterCard: MastercardCardIcon,
  'American Express': AmexCardIcon,
  'Diners Club': DinersClubCardIcon,
  Discover: DiscoverCardIcon,
  JCB: JCBCardIcon,
  UnionPay: UnionPayCardIcon,
  Unknown: UnknownPaymentCardIcon,
};

interface IProps {
  source: ICreditCardSource;
}

function CreditCard({ source }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const icon = BRAND_ICONS[source.brand];

  const expiration = `${source.exp_month} / ${source.exp_year.toString().slice(-2)}`;

  const details = (
    <div className={styles.details}>
      {t('Ending in')} {source.last4}
      <span className={styles.expiry}>
        {t('Expires')} {expiration}
      </span>
    </div>
  );

  return <PaymentMethod icon={<Icon className={styles.icon} icon={icon} />} label={details} />;
}

export default CreditCard;
