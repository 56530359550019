import { computed, runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { TCampaignGroup } from '@feathr/blackbox';
import { Button, Drawer } from '@feathr/components';
import { useReactionEffect } from '@feathr/hooks';

import CampaignsFilters from './CampaignsFilters';
import type { IFilters } from './CampaignsFilters/CampaignsFilters';

interface IProps {
  campaignGroup: TCampaignGroup;
  close: () => void;
  filters: IFilters;
  isOpen: boolean;
  onChange: (filters: IFilters) => void;
}

function CampaignsFiltersDrawer({
  campaignGroup,
  close,
  filters,
  isOpen,
  onChange,
}: Readonly<IProps>): JSX.Element {
  const { eventId: projectId } = useParams<{ eventId?: string }>();
  const { t } = useTranslation();

  const drawerFilters = useLocalObservable<IFilters>(() => ({
    date_end: filters.date_end,
    date_start: filters.date_start,
    event__in: filters.event__in,
    participants: filters.participants,
    partner: filters.partner,
    state__in: filters.state__in,
    types: filters.types,
  }));

  function setDrawerFilters(newFilters: IFilters): void {
    runInAction(() => {
      drawerFilters.date_end = newFilters.date_end;
      drawerFilters.date_start = newFilters.date_start;
      drawerFilters.event__in = newFilters.event__in;
      drawerFilters.participants = newFilters.participants;
      drawerFilters.partner = newFilters.partner;
      drawerFilters.state__in = newFilters.state__in;
      drawerFilters.types = newFilters.types;
    });
  }

  const filterCount = computed(() => {
    // Count number of items in each filter and return the total count
    return Object.entries(drawerFilters)
      .filter(([key]) =>
        [
          'date_end',
          'date_start',
          'event__in',
          'participants',
          'partner',
          'state__in',
          'types',
        ].includes(key),
      )
      .reduce(
        (count, [, value]) => count + (Array.isArray(value) ? value.length : value ? 1 : 0),
        0,
      );
  });

  useReactionEffect(
    () => filters,
    (filters) => {
      setDrawerFilters(filters);
    },
    {},
    [filters],
  );

  function handleClearFilters(): void {
    setDrawerFilters({
      date_end: undefined,
      date_start: undefined,
      event__in: projectId ? [projectId] : [],
      participants: undefined,
      partner: undefined,
      state__in: [],
      types: [],
    });
  }

  function handleApplyFilters(): void {
    onChange(drawerFilters);
    close();
  }

  function handleCloseDrawer(): void {
    // When closing without applying, we want to reset the drawer filters to the current filters
    setDrawerFilters(filters);
    close();
  }

  return (
    <Drawer
      isOpen={isOpen}
      name={'filters-drawer'}
      onClose={handleCloseDrawer}
      title={t('Filters')}
    >
      <Drawer.Content addVerticalGap={true}>
        <CampaignsFilters
          campaignGroup={campaignGroup}
          context={projectId ? 'project' : 'global'}
          filters={drawerFilters}
          onChange={setDrawerFilters}
        />
      </Drawer.Content>
      <Drawer.Actions>
        <Button isFullWidth={true} key={'clear'} onClick={handleClearFilters}>
          {t('Clear ({{count}})', { count: filterCount.get() })}
        </Button>
        <Button isFullWidth={true} key={'apply'} onClick={handleApplyFilters} type={'primary'}>
          {t('Apply')}
        </Button>
      </Drawer.Actions>
    </Drawer>
  );
}

export default observer(CampaignsFiltersDrawer);
