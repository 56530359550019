import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';

import { EUserRoleIDs, type UserRole } from '@feathr/blackbox';
import Page from '@feathr/extender/App/Page';
import RedirectTo404 from '@feathr/extender/App/Routes/RedirectTo404';
import { StoresContext, useActionBar, useLocalUrl } from '@feathr/extender/state';

import EditRoleCard from './EditRoleCard';
import SaveRoleButton from './SaveRoleButton';

import * as styles from './EditRolePage.css';

export type TAction = 'create' | 'edit';

function EditRolePage(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const localUrl = useLocalUrl();
  const { setRightActions } = useActionBar();
  const { UserRoles } = useContext(StoresContext);

  // URL Parameters
  const { roleId, action } = useParams<{
    // If the action is 'create', the role is being created. If the action is 'edit', the role is being edited.
    action: TAction;
    roleId: string;
  }>();
  const isCreatingRole = action === 'create';
  const hasValidRoleId = !Object.values(EUserRoleIDs).includes(roleId as EUserRoleIDs);

  // Query Parameters
  const params = new URLSearchParams(window.location.search);
  const roleName = params.get('name') ?? t('Unnamed Role');

  // State
  const getOrCreateRole = isCreatingRole
    ? UserRoles.create({ name: roleName })
    : UserRoles.get(roleId);
  const [role] = useState<UserRole>(getOrCreateRole);

  // The role id does not match one in the database
  const isRoleInvalid = !role.isPending && role.isErrored;

  // After saving, navigate to the roles page.
  function handleOnSave(): void {
    history.push(localUrl('/settings/account/users/roles'));
  }

  /*
   * If the user navigates away from the page, clear property values that have been set and not saved.
   */
  useEffect(() => {
    return () => {
      role.reload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    setRightActions(<SaveRoleButton onSave={handleOnSave} role={role} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role.isDirty, role.isValid()]);

  return hasValidRoleId && !isRoleInvalid ? (
    <Page contentClassName={styles.pageContent} loading={role.isPending} title={role.name}>
      {!role.isPending && <EditRoleCard role={role} />}
    </Page>
  ) : (
    <RedirectTo404 />
  );
}

export default observer(EditRolePage);
