import { faExclamationTriangle, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Campaign, Targetable, Targeting } from '@feathr/blackbox';
import {
  CampaignClass,
  CampaignState,
  EIntegrationTypes,
  EmailListCampaign,
  EmailListFacebookCampaign,
  TargetableClass,
} from '@feathr/blackbox';
import { Button, Card, FileUpload, toast, Tooltip } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import useAccount from '@feathr/extender/state/useAccount';

import * as styles from './SegmentTargeting/SegmentTargeting.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

const uniqueEmailThresholds = {
  [CampaignClass.EmailListFacebook]: 2500,
  [CampaignClass.EmailList]: 2500,
};
const uniqueEmailRecommendation = 10000;

function EmailListTargeting({ campaign, targeting, onRemove }: IProps): JSX.Element {
  const { Targetables } = React.useContext(StoresContext);
  const account = useAccount();
  const { t } = useTranslation();
  const isFacebookEmailCampaign = campaign.get('_cls') === CampaignClass.EmailListFacebook;

  let targetable: Targetable;
  const targetableId = targeting.get('target_data');
  if (targetableId) {
    targetable = Targetables.get(targetableId);
  } else {
    targetable = Targetables.create({
      _cls: TargetableClass.email_list,
      name: 'Email List Targeting',
      partner: campaign.get('parent_kind') === 'partner' ? campaign.get('parent') : undefined,
    });
    targeting.set({ target_data: targetable.get('id') });
    if (campaign.get('_cls') === CampaignClass.EmailListFacebook) {
      targeting.set({ integrations: [EIntegrationTypes.Facebook] });
      targetable.set({ integrations: [EIntegrationTypes.Facebook] });
    }
  }
  const handleUpload = async (key, container, file, filename): Promise<any> => {
    const Papa = await import('papaparse');
    const parseFile = (csvFile: File, csvTargetable: Targetable): Promise<unknown> => {
      return new Promise((resolve) => {
        Papa.parse<Record<'email', string>>(csvFile, {
          header: true,
          complete: (results) => {
            resolve(results.data);
            runInAction(() => {
              const uniqueEmails = new Set(
                results.data.filter((row) => !!row.email).map((row) => row.email),
              );
              csvTargetable.set({ num_unique_emails: uniqueEmails.size });
            });
          },
        });
      });
    };
    const parsedData: any = await parseFile(file as File, targetable);
    const threshold = uniqueEmailThresholds[campaign.get('_cls')];
    if (Object.keys(parsedData[0])[0] !== 'email') {
      toast('The uploaded CSV does not have a column with an "email" header', {
        type: 'error',
      });
    } else if (Object.keys(parsedData[0]).length > 1) {
      toast('The uploaded CSV cannot contain more than one column', {
        type: 'error',
      });
    } else if ((targetable.get('num_unique_emails') || 0) < threshold) {
      toast(
        `The uploaded CSV must have at least ${numeral(threshold).format(
          '0,0',
        )} unique email addresses.`,
        {
          type: 'error',
        },
      );
    } else {
      const url = `https://s3.amazonaws.com/${container}/${encodeURIComponent(key!)}`;
      const advertiserId = account.get('ttd')?.id ?? 'noAdvId';
      const fileExt = filename.split('.').pop();
      const targetId = targetable.get('id');
      const cleanFileName = `${advertiserId}-${targetId}.${fileExt}`;

      targetable.set({
        name: cleanFileName,
        list_file: url,
        list_file_name: filename,
      });
    }
  };

  const isAboveEmailMinimumThreshold =
    targetable.get('num_unique_emails', 0) >= uniqueEmailThresholds[campaign.get('_cls')];
  const isBelowEmailMinimumRecommendation =
    targetable.get('num_unique_emails', 0) < uniqueEmailRecommendation;
  const emailCountRecommendation = numeral(uniqueEmailRecommendation).format('0,0');
  const emailCountProvided = numeral(targetable.get('num_unique_emails', 0)).format('0,0');

  return (
    <Card
      actions={[
        <Tooltip key={'remove'} title={'Remove'}>
          <Button name={'remove_targeting'} onClick={() => onRemove(targeting)} type={'naked'}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Tooltip>,
      ]}
    >
      <section>
        {targetable.get('list_file') ? (
          <>
            <label>{t('File')}</label>
            <p>{targetable.get('list_file_name') || targetable.get('name')}</p>
            {targetable.get('num_unique_emails') && (
              <>
                <label>{t('Unique Email Addresses')}</label>
                <p>{emailCountProvided}</p>
              </>
            )}
            {isAboveEmailMinimumThreshold &&
              isBelowEmailMinimumRecommendation &&
              isFacebookEmailCampaign && (
                <p>
                  <FontAwesomeIcon
                    className={styles.warning}
                    icon={faExclamationTriangle}
                    size={'lg'}
                  />
                  {t(
                    'Feathr recommends {{emailCountRecommendation}} unique email addresses to ensure good results from the email mapping process. This campaign may not perform well with {{emailCountProvided}} unique email addresses.',
                    {
                      emailCountRecommendation,
                      emailCountProvided,
                    },
                  )}
                </p>
              )}
            {!targetable.isValid([], false) && (
              <p className={styles.error}>{targetable.validate([], false).errors}</p>
            )}
          </>
        ) : (
          <>
            <FileUpload
              attribute={'list_file'}
              disabled={campaign.get('state') === CampaignState.Published}
              helpText={
                <>
                  <span>
                    <Trans t={t}>
                      Upload a <code>.csv</code> file of your target email addresses. The file
                      should consist of a single column with one email address per row, and with
                      header "email".{' '}
                      <a
                        href={
                          'https://feathr-static-assets.s3.amazonaws.com/data/email_list_template.csv'
                        }
                      >
                        Click here{' '}
                      </a>
                      to download an example file to use as a template.
                    </Trans>
                  </span>
                  <br />
                  {campaign instanceof EmailListCampaign && (
                    <span>
                      {t(
                        'You must include at least {{uniqueEmailThresholds}} unique email addresses to ensure good results from the email mapping process.',
                        {
                          uniqueEmailThresholds: numeral(
                            uniqueEmailThresholds[CampaignClass.EmailList],
                          ).format('0,0'),
                        },
                      )}
                    </span>
                  )}
                  {campaign instanceof EmailListFacebookCampaign && (
                    <span>
                      {t(
                        'You must include at least {{uniqueEmailThresholds}} unique email addresses to ensure good results from the email mapping process.',
                        {
                          uniqueEmailThresholds: numeral(
                            uniqueEmailThresholds[CampaignClass.EmailListFacebook],
                          ).format('0,0'),
                        },
                      )}
                    </span>
                  )}
                </>
              }
              label={'Upload CSV'}
              model={targetable}
              name={'upload_csv'}
              onUpload={handleUpload}
              pickerOptions={{
                accept: ['text/comma-separated-values', 'text/csv', 'application/csv', '.csv'],
                maxFiles: 1,
                exposeOriginalFile: true,
                storeTo: {
                  location: 's3',
                  container: 'feathr-import-data',
                  access: 'public',
                  region: 'us-east-1',
                },
              }}
            />
          </>
        )}
      </section>
    </Card>
  );
}

export default observer(EmailListTargeting);
