import { set } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IDynamicContent } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';

interface IProps {
  dynamicContent: IDynamicContent;
}

function FontSelect({ dynamicContent }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  const options = [
    { id: 'Arial', name: 'Arial' },
    { id: 'Courier', name: 'Courier' },
    { id: 'Georgia', name: 'Georgia' },
    { id: 'Helvetica', name: 'Helvetica' },
    { id: 'Lato', name: 'Lato' },
    { id: 'Lucida Grande', name: 'Lucida Grande' },
    { id: 'Montserrat', name: 'Montserrat' },
    { id: 'Open Sans', name: 'Open Sans' },
    { id: 'Roboto', name: 'Roboto' },
    { id: 'Source Sans Pro', name: 'Source Sans Pro' },
    { id: 'Tahoma', name: 'Tahoma' },
    { id: 'Trebuchet', name: 'Trebuchet' },
    { id: 'Ubuntu', name: 'Ubuntu' },
    { id: 'Verdana', name: 'Verdana' },
  ];

  function handleFontChange(option: ISelectOption): void {
    set(dynamicContent, { font: option.id });
  }

  return (
    <Select
      label={t('Font')}
      onSelectSingle={handleFontChange}
      options={options}
      value={{ id: dynamicContent.font!, name: dynamicContent.font! }}
    />
  );
}

export default observer(FontSelect);
