import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Billable, Event } from '@feathr/blackbox';
import { Button, ButtonValid, Card, Popover } from '@feathr/components';
import { currencyFormatter } from '@feathr/extender/utils';
import { useToggle } from '@feathr/hooks';
import { validate } from '@feathr/rachis';

import AddAddress from './AddAddress';
import AddFunds from './AddFunds';
import TransferFunds from './TransferFunds';

import * as styles from './BillingFunds.css';

interface IProps {
  event: Event;
  billable?: Billable;
}

function BillingFunds({ event, billable }: IProps) {
  const billing = event.get('billing');
  const [showTransferFundsModal, setShowTransferFundsModal] = React.useState(false);
  const [showAddFundsModal, toggleShowAddFundsModal] = useToggle(false);
  const [showAddAddressModal, toggleShowAddAddressModal] = useToggle(false);
  const { t } = useTranslation();
  if (billable && billable.isPending) {
    return null;
  }

  function toggleShowTransferFundsModal() {
    setShowTransferFundsModal(!showTransferFundsModal);
  }

  const hasFunds: boolean = (billing?.max_transfer || 0) > 0;

  function validateFunds() {
    return !hasFunds ? [t('No pre-paid funds available to transfer.')] : [];
  }

  /*
   * Using validation here instead of on the billable model because
   * the address only needs to be valid to add pre-paid funds, not always
   */
  const validationErrors = (billable: Billable) => {
    const address = billable.get('address');
    return validate(
      {
        line1: address?.line1,
        country: address?.country,
      },
      {
        line1: {
          presence: {
            allowEmpty: false,
            message: t("^Address Line 1 can't be blank."),
          },
        },
        country: {
          presence: {
            allowEmpty: false,
            message: t("^Country can't be blank."),
          },
        },
      },
    );
  };

  const mediaCreditBalance = currencyFormatter.format(
    Math.max(billing?.media_credit_balance || 0, 0),
  );

  const maxTransfer = currencyFormatter.format(Math.max(billing?.max_transfer || 0, 0));
  const transferDiff = currencyFormatter.format(
    Math.max(
      Math.max(billing?.media_credit_balance || 0) - Math.max(billing?.max_transfer || 0),
      0,
    ),
  );

  return (
    <>
      <Card className={styles.card} contentClassName={styles.cardContent}>
        <div className={styles.funds}>
          <label>
            {t('Media credit balance')}
            <Popover toggle={'onMouseOver'}>
              <span>
                <FontAwesomeIcon className={styles.info} icon={faInfoCircle} />
              </span>
              <section className={styles.section}>
                <p>{t('The amount you have currently funded on this project.')}</p>
              </section>
              <section>
                {hasFunds && (
                  <>
                    <p>
                      {t(
                        "On the 5th of each month, funds will be automatically pulled from this balance to pay the billing period's media invoice.",
                      )}
                    </p>
                  </>
                )}
              </section>
            </Popover>
          </label>
          <span>{mediaCreditBalance}</span>
        </div>
        <div className={styles.funds}>
          <label>
            {t('Available to transfer')}
            <Popover toggle={'onMouseOver'}>
              <span>
                <FontAwesomeIcon className={styles.info} icon={faInfoCircle} />
              </span>
              <section className={styles.section}>
                <p>{t('The amount you have available to transfer from this project.')}</p>
                {mediaCreditBalance !== maxTransfer && (
                  <div>
                    <p>
                      {t(
                        'Amount available to transfer is reduced by budgeted spend of {{transferDiff}}',
                        { transferDiff },
                      )}
                    </p>
                  </div>
                )}
              </section>
            </Popover>
          </label>
          <span>{maxTransfer}</span>
        </div>
        <div className={styles.buttons}>
          <ButtonValid errors={validateFunds()} onClick={toggleShowTransferFundsModal}>
            {t('Transfer pre-paid funds')}
          </ButtonValid>
          <Button
            onClick={
              billable && !validationErrors(billable)
                ? toggleShowAddFundsModal
                : toggleShowAddAddressModal
            }
            type={'primary'}
          >
            {t('Add pre-paid funds')}
          </Button>
        </div>
      </Card>
      {showTransferFundsModal && (
        <TransferFunds event={event} toggleShowTransferFundsModal={toggleShowTransferFundsModal} />
      )}
      {showAddAddressModal && billable && (
        <AddAddress
          billable={billable}
          event={event}
          toggleShowAddAddressModal={toggleShowAddAddressModal}
        />
      )}
      {showAddFundsModal && billable && (
        <AddFunds
          billable={billable}
          event={event}
          toggleShowAddFundsModal={toggleShowAddFundsModal}
        />
      )}
    </>
  );
}

export default observer(BillingFunds);
