import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { EmailBaseCampaign } from '@feathr/blackbox';
import {
  Button,
  CardV2 as Card,
  CopyToClipboardButton,
  EmailPreview,
  Label,
  Time,
  useEmailPreview,
} from '@feathr/components';
import { EMAIL_TEMPLATE_PREVIEW_RELEASE_DATE } from '@feathr/extender/global';
import { useUser } from '@feathr/extender/state';
import { moment, TimeFormat } from '@feathr/hooks';

import * as styles from './EmailDetails.css';

interface IProps {
  campaign: EmailBaseCampaign;
}

function EmailDetails({ campaign }: Readonly<IProps>): JSX.Element | null {
  const { t } = useTranslation();
  const user = useUser();

  const previewUrl = campaign.getTemplatePreviewUrl();
  const { fetchFailed: previewFetchFailed, isLoading: isPreviewLoading } = useEmailPreview({
    url: previewUrl,
  });

  // Prevent rendering if the campaign type is not supported. This should never happen.
  if (!campaign.isEmail) {
    return null;
  }

  const dateCreated = campaign.get('date_created');
  const dateSendStart = campaign.get('date_send_start');
  const fromAddress = campaign.get('from_address');
  const fromName = campaign.get('from_name', t('N/A'));
  const subject = campaign.get('subject');
  const previewTitle = t('{{title}} Preview', { title: campaign.get('subject') });
  const userTimezone = user.get('timezone');

  const shouldShowEmailPreview = moment
    .utc(dateCreated)
    .isAfter(moment.utc(EMAIL_TEMPLATE_PREVIEW_RELEASE_DATE));

  return (
    <Card width={'full'}>
      <Card.Content contentClassName={styles.content}>
        {shouldShowEmailPreview && <EmailPreview title={previewTitle} url={previewUrl} />}

        <div className={styles.details}>
          <span className={styles.detail}>
            <Label className={styles.label}>{t('Subject')}</Label>
            {subject}
          </span>

          <span className={styles.detail}>
            <Label className={styles.label}>{t('From name')}</Label>
            {fromName}
          </span>

          <span className={styles.detail}>
            <Label className={styles.label}>{t('Sent date')}</Label>
            <Time
              format={TimeFormat.pickerDateTimeZone}
              timestamp={dateSendStart}
              timezone={userTimezone}
            />
          </span>

          <span className={styles.detail}>
            <Label className={styles.label}>{t('From address')}</Label>
            {fromAddress}
          </span>

          {shouldShowEmailPreview && (
            <div className={styles.buttonWrapper}>
              <Button
                disabled={isPreviewLoading || previewFetchFailed}
                href={previewUrl}
                prefix={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                target={'_blank'}
                tooltip={previewFetchFailed && t('This email preview is unavailable')}
                tooltipPosition={'bottom'}
                type={'secondary'}
              >
                {t('View email')}
              </Button>
              <CopyToClipboardButton
                disabled={isPreviewLoading || previewFetchFailed}
                successMessage={t('Public link copied to clipboard')}
                t={t}
                text={previewUrl}
              >
                {t('Copy public link')}
              </CopyToClipboardButton>
            </div>
          )}
        </div>
      </Card.Content>
    </Card>
  );
}

export default observer(EmailDetails);
