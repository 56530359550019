import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const ClicksColumn: IColumn<BaseCampaign> = {
  id: 'clicks',
  checkboxLabel: 'Clicks',
  Header: TableColumnHeader({
    title: 'Clicks',
  }),
  width: 80,
  sortable: false,
  className: tableStyles.cellRight,
  Cell({ original }) {
    return (
      <Observer>
        {(): JSX.Element => {
          return <>{original.totalClicks.toLocaleString()}</>;
        }}
      </Observer>
    );
  },
  Footer({ data }) {
    return (
      <Observer>
        {(): JSX.Element => {
          const total = data.reduce((subtotal, currentValue) => {
            return subtotal + currentValue._original.totalClicks;
          }, 0);
          return <>{total.toLocaleString()}</>;
        }}
      </Observer>
    );
  },
};

export default ClicksColumn;
