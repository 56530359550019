import type { IObservableValue } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import { ProgressBar } from '@feathr/components';

import type { ReportStore } from '../../state';
import { StoresContext } from '../../state';
import ContextWrapper from '../ContextWrapper';
import SegmentGraph from '../SegmentGraph';
import SegmentTable from './SegmentTable';

import * as styles from './SegmentReport.css';

interface IProps {
  store: ReportStore;
  segmentId: string;
  start: string;
  end: string;
  complete: IObservableValue<number>;
}

function SegmentReport({ store, segmentId, start, end, complete }: IProps) {
  return (
    <ContextWrapper store={store}>
      <Observer>
        {function useAnonymousFunction() {
          const { Stats } = useContext(StoresContext);

          const statsResult = Stats.list({
            filters: {
              metadata__date__gte: start,
              metadata__date__lte: end,
              metadata__obj_id: segmentId,
            },
            model: 'segment',
            ordering: ['metadata.date'],
          });
          const segmentDailyStats = statsResult.models;
          const pending = statsResult.isPending;
          if (pending || complete.get() < 1) {
            return (
              <div className={styles.progressWrapper}>
                <ProgressBar value={complete.get() * 100} width={'50%'} />
                <div>Preparing group analytics, thanks for waiting!</div>
              </div>
            );
          }
          return (
            <div className={styles.reportWrapper}>
              <SegmentGraph
                charts={[
                  'num_users_total',
                  'num_users_active',
                  'num_cookies_total',
                  'num_emails_total',
                ]}
                label={'Group Tracking'}
                stats={segmentDailyStats}
              />
              <SegmentTable label={'Group Analytics Table'} stats={segmentDailyStats} />
            </div>
          );
        }}
      </Observer>
    </ContextWrapper>
  );
}

export default SegmentReport;
