import { autorun, observable, runInAction, set } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { ICustomData, Partner } from '@feathr/blackbox';
import { Button, Toolbar } from '@feathr/components';
import { useToggle } from '@feathr/hooks';

import PartnerAddCustomData from './PartnerAddCustomData';
import PartnerCustomDataInputs from './PartnerCustomDataInputs';

import styles from './PartnerCustomData.css';

interface IProps {
  partner: Partner;
}

function PartnerCustomData({ partner }: IProps): JSX.Element {
  const [toggleAddData, setToggleAddData] = useToggle(false);
  const customDataObject = partner.get('custom_data') as ICustomData;
  const customData = customDataObject
    ? observable.array(Object.entries(customDataObject).map(([key, value]) => ({ key, value })))
    : observable.array([]);
  const { t } = useTranslation();

  function handleFieldChange(datumKey: string, newKey: string): void {
    runInAction(() => {
      // Update key and clear value to prevent type mismatch
      set(customData[customData.findIndex((datum) => datum.key === datumKey)], {
        key: newKey,
        value: undefined,
      });
    });
  }

  function handleValueChange(
    datumKey: string,
    newValue: string | boolean | number | Date | string[] | undefined,
  ): void {
    runInAction(() => {
      set(customData[customData.findIndex((datum) => datum.key === datumKey)], { value: newValue });
    });
  }

  useEffect(
    () =>
      autorun(() => {
        const newCustomData = customData.reduce((ncd, cd) => {
          ncd[cd.key] = cd.value;
          return ncd;
        }, {} as Record<string, string | number | boolean | Date | string[] | undefined>);
        partner.set({ custom_data: newCustomData });
      }),
    [customData],
  );

  return (
    <>
      <label>{t('Custom Fields')}</label>
      {customData ? (
        customData.map((datum) => (
          <div id={datum.key} key={datum.key}>
            <PartnerCustomDataInputs
              customData={customData}
              datumKey={datum.key}
              handleFieldChange={handleFieldChange}
              handleValueChange={handleValueChange}
              value={datum.value}
            />
          </div>
        ))
      ) : (
        <></>
      )}
      {!toggleAddData ? (
        <Toolbar align={'left'}>
          <Button
            className={styles.button}
            id={'addCustomData'}
            onClick={setToggleAddData}
            title={'Add Custom Data'}
          >
            {t('Add custom field')}
          </Button>
        </Toolbar>
      ) : (
        <PartnerAddCustomData partner={partner} toggleAddData={setToggleAddData} />
      )}
    </>
  );
}

export default observer(PartnerCustomData);
