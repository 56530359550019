import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { IImportColumn, IPreviewRow } from '@feathr/blackbox';
import { Table } from '@feathr/components';

import PreviewCard from './PreviewCard';
import previewTableColumns from './PreviewTableColumns';

import styles from './DataImportPreview.css';

interface IProps {
  columnMappers: IImportColumn[];
  rows: IPreviewRow[];
  tagIds: string[];
}

function DataImportPreview({ columnMappers, rows, tagIds }: IProps): JSX.Element {
  const { t } = useTranslation();
  const [selected, setSelected] = useState({
    row: rows[0],
    index: 0,
  });

  const rowHandler = useCallback(
    (state, rowInfo) => {
      if (rowInfo && rowInfo.row) {
        const { error } = rowInfo.original;

        return {
          onClick: (): void => {
            setSelected({
              row: rows[rowInfo.index],
              index: rowInfo.index,
            });
          },
          className: classNames(
            { [styles.errorRow]: error },
            { [styles.selectedRow]: rowInfo.index === selected.index },
          ),
          style: {
            cursor: 'pointer',
          },
        };
      }
      return {};
    },
    [rows, selected.index],
  );

  return (
    <div className={styles.root}>
      <h3>{t('Import preview')}</h3>
      <div className={styles.description}>
        <Trans t={t}>
          <p>
            Select a row below to see a preview of what the imported data will look like within
            Feathr. The preview only shows the first 10 rows of the import.
          </p>
        </Trans>
      </div>

      <Table<IPreviewRow>
        columns={previewTableColumns(columnMappers, tagIds)}
        getTrProps={rowHandler}
        idKey={'id'}
        isPaginated={false}
        items={rows}
        noDataText={'No preview rows to display.'}
      />
      {selected.row && <PreviewCard row={selected.row} tagIds={tagIds} />}
    </div>
  );
}

export default observer(DataImportPreview);
