import { faRefresh } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EConnectionStatus } from '@feathr/blackbox';
import { Button } from '@feathr/components';

import ConnectButton from './ConnectButton';
import DisconnectButton from './DisconnectButton';

interface IGoogleAdsConnectButtonProps {
  readonly status: EConnectionStatus;
  readonly onConnect: (customerId: string) => Promise<void>;
  readonly onDisconnect: () => Promise<void>;
  readonly onRefresh: () => Promise<void>;
}

function GoogleAdsCustomerConnect({
  status,
  onConnect,
  onDisconnect,
  onRefresh,
}: IGoogleAdsConnectButtonProps): JSX.Element {
  const { t } = useTranslation();

  function getButton(): Record<EConnectionStatus, JSX.Element> {
    return {
      [EConnectionStatus.connected]: <DisconnectButton onClick={onDisconnect} />,
      [EConnectionStatus.disconnected]: <ConnectButton onClick={onConnect} />,
      [EConnectionStatus.pending]: (
        <Button
          name={'refresh_button'}
          onClick={onRefresh}
          prefix={<FontAwesomeIcon icon={faRefresh} />}
          tooltip={t('Refreshing will check to see if your invitation has been accepted.')}
        >
          {t('Refresh')}
        </Button>
      ),
    };
  }

  return getButton()[status];
}

export default GoogleAdsCustomerConnect;
