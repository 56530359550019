import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faAddressBook,
  faAddressCard,
  faArrowPointer,
  faBrowser,
  faBullseyePointer,
  faChartPie,
  faCheck,
  faCode,
  faEnvelope,
  faEnvelopeOpenText,
  faFileCheck,
  faFileImport,
  faGear,
  faMousePointer,
  faPaperPlane,
  faRectangleAd,
  faStar,
} from '@fortawesome/pro-light-svg-icons';
import { faStarExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import type { RowRenderProps } from 'react-table';

import { Flavors } from '@feathr/blackbox';
import { Chip } from '@feathr/components';

import * as styles from './FlavorChip.css';

export type TFlavor = keyof Flavors;

export const flavorToColorMap: Record<keyof Flavors, string> = {
  ad_click: 'sky',
  ad_view: 'sky',
  contact_list_import: 'indigo',
  conversion: 'orange',
  custom: 'default',
  dashboard_create: 'orange',
  dashboard_import: 'green',
  dashboard_send_complete: 'orange',
  dashboard_send_request: 'green',
  dashboard_submit: 'green',
  dashboard_view: 'teal',
  email_preferences_update: 'yellow',
  partner_message_link_click: 'yellow',
  partner_message_send: 'yellow',
  partner_message_view: 'yellow',
  created_by_import: 'indigo',
  updated_by_import: 'indigo',
  page_link_click: 'green',
  page_view: 'teal',
  report_view: 'teal',
  update: 'indigo',
  form_submission: 'blue',
  convert: 'orange',
  pinpoint_tracked_email_hardbounce: 'red',
  pinpoint_tracked_email_softbounce: 'red',
  pinpoint_tracked_email_suppression: 'yellow',
  pinpoint_tracked_email_open: 'yellow',
  pinpoint_tracked_email_click: 'yellow',
  pinpoint_tracked_email_complaint: 'red',
  pinpoint_tracked_email_send: 'yellow',
  pinpoint_tracked_email_delivered: 'yellow',
  pinpoint_tracked_email_rendering_failure: 'red',
  user_create: 'default',
  user_update: 'default',
  zapier_import: 'orange',
  zapier_update: 'orange',
  imis_create: 'orange',
  imis_update: 'orange',
  raisers_edge_constituent_created: 'purple',
  raisers_edge_constituent_updated: 'purple',
  raisers_edge_constituent_summary: 'purple',
  raisers_edge_gift_added: 'purple',
};

export const flavorToIconMap: Record<keyof Flavors, IconDefinition> = {
  ad_click: faRectangleAd,
  ad_view: faRectangleAd,
  contact_list_import: faAddressCard,
  conversion: faBullseyePointer,
  custom: faStar,
  dashboard_create: faStarExclamation,
  dashboard_import: faAddressBook,
  dashboard_send_complete: faCheck,
  dashboard_send_request: faPaperPlane,
  dashboard_submit: faAddressCard,
  dashboard_view: faBrowser,
  email_preferences_update: faGear,
  partner_message_link_click: faEnvelope,
  partner_message_send: faEnvelope,
  partner_message_view: faEnvelopeOpenText,
  created_by_import: faFileImport,
  updated_by_import: faAddressCard,
  page_link_click: faBrowser,
  page_view: faBrowser,
  report_view: faChartPie,
  update: faAddressCard,
  form_submission: faFileCheck,
  convert: faCode,
  pinpoint_tracked_email_hardbounce: faEnvelope,
  pinpoint_tracked_email_softbounce: faEnvelope,
  pinpoint_tracked_email_suppression: faEnvelope,
  pinpoint_tracked_email_open: faEnvelopeOpenText,
  pinpoint_tracked_email_click: faEnvelope,
  pinpoint_tracked_email_complaint: faEnvelope,
  pinpoint_tracked_email_send: faEnvelope,
  pinpoint_tracked_email_delivered: faEnvelope,
  pinpoint_tracked_email_rendering_failure: faEnvelope,
  user_create: faArrowPointer,
  user_update: faArrowPointer,
  zapier_import: faFileImport,
  zapier_update: faFileImport,
  imis_create: faFileImport,
  imis_update: faAddressCard,
  raisers_edge_constituent_created: faFileImport,
  raisers_edge_constituent_updated: faFileImport,
  raisers_edge_constituent_summary: faFileImport,
  raisers_edge_gift_added: faFileImport,
};

interface IProps extends RowRenderProps {
  flavor: TFlavor;
}

function FlavorChip({ flavor }: Readonly<IProps>): JSX.Element {
  const flvr = flavor || 'custom';
  let icon = flavorToIconMap[flvr];
  if (!icon) {
    icon = faMousePointer;
  }
  const color = flavorToColorMap[flvr] || 'default';
  const description = Flavors[flvr] || flvr;
  return (
    <Chip className={styles.chipFix} prefix={<FontAwesomeIcon icon={icon} />} theme={color}>
      {description}
    </Chip>
  );
}

export default FlavorChip;
