import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { IGoogleAdsAdText } from '@feathr/blackbox';

import type { IInputListItemSharedProps } from './InputListItem';
import InputListItem from './InputListItem';

interface IInputListProps extends IInputListItemSharedProps {
  readonly items: IGoogleAdsAdText[];
  readonly minItems: number;
  readonly maxItems: number;
}

function InputList({
  items,
  label,
  maxLength,
  maxItems,
  minItems,
  onChange,
  onRemove,
  removeTooltip,
  type = 'input',
}: IInputListProps): JSX.Element {
  const canRemove = items.length > minItems && items.length <= maxItems;
  return (
    <>
      {items.map((item, index) => {
        return (
          <InputListItem
            canRemove={canRemove}
            index={index}
            item={item.text}
            key={item.key}
            label={label}
            maxLength={maxLength}
            onChange={onChange}
            onRemove={onRemove}
            removeTooltip={removeTooltip}
            type={type}
          />
        );
      })}
    </>
  );
}

export default observer(InputList);
