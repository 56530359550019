import { faLockKeyhole, faUnlockKeyhole } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { WithT } from 'i18next';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Domain } from '@feathr/blackbox';
import { Button, ButtonValid, Fieldset, Form, Input, Tooltip } from '@feathr/components';
import { flattenError, flattenErrors, useToggle } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import * as styles from './DomainEditStepTwo.css';

interface IProps {
  disabled?: boolean;
  domain: Domain;
  matchingDomains: IObservableArray<Domain>;
  onNext: () => void;
  onPrev: () => void;
}

interface IButtonProps extends WithT {
  domain: Domain;
  onNext: () => void;
  isEditingMailFrom: boolean;
}

interface IErrors extends TValidateGrouped {
  mail_from?: string[];
}

export function validateStepThree(domain: Domain): IErrors {
  return domain.validate<IErrors>(['mail_from'], false, 'grouped').errors;
}

const NextStepButton = observer(({ domain, isEditingMailFrom, onNext, t }: IButtonProps) => {
  const validationErrors = validateStepThree(domain);
  const errors = flattenErrors(validationErrors);
  if (isEditingMailFrom) {
    errors.push(t('Please lock in your email sending domain by clicking the lock above.'));
  }
  return (
    <ButtonValid errors={domain.isEphemeral ? errors : []} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function DomainEditStepThree({
  disabled = false,
  domain,
  matchingDomains,
  onNext,
  onPrev,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const [editMailFrom, toggleEditMailFrom] = useToggle(false);
  const validationErrors = validateStepThree(domain);

  function handleChangeMailFrom(newValue?: string) {
    const emailSubdomain = newValue ? newValue : '';
    domain.set({ mail_from: `${emailSubdomain}.${domain.get('email_domain')}` });
  }

  const prefix = (
    <Tooltip
      title={
        !!matchingDomains.length
          ? t(
              'Email sending settings for this domain have already been configured, and cannot be modified.',
            )
          : editMailFrom
            ? t('Click to set your email serving subdomain.')
            : t('Click to edit your email serving subdomain.')
      }
    >
      <Button
        className={styles.button}
        disabled={disabled || !!matchingDomains.length || domain.isV1}
        onClick={toggleEditMailFrom}
      >
        <FontAwesomeIcon icon={editMailFrom ? faUnlockKeyhole : faLockKeyhole} />
      </Button>
    </Tooltip>
  );

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <NextStepButton
          domain={domain}
          isEditingMailFrom={editMailFrom}
          key={'next'}
          onNext={onNext}
          t={t}
        />,
      ]}
      description={
        <Trans t={t}>
          <p>
            Set up your subdomain for sending emails from Feathr.{' '}
            <strong>
              We have automatically determined email sending settings for your domain. Only make
              changes if you are sure they are needed.
            </strong>
          </p>
          <p>
            In addition to this setup, we highly recommend{' '}
            <a href={'https://dmarc.org'} target={'_blank'}>
              configuring DMARC for your domain
            </a>
          </p>
        </Trans>
      }
      label={t('Edit Domain: Info')}
    >
      <Fieldset>
        <Input
          disabled={disabled || !editMailFrom || !!matchingDomains.length}
          helpText={
            <Trans t={t}>
              <p>
                The domain you would like your emails to be sent from. Use a subdomain, like{' '}
                <strong>fthrmail</strong>.domain.com that is only used for serving Feathr content.
              </p>
            </Trans>
          }
          label={t('Email sending domain address')}
          onChange={handleChangeMailFrom}
          prefix={prefix}
          prefixClassName={styles.prefix}
          required={true}
          suffix={domain.isV1 ? '' : `.${domain.get('email_domain')}`}
          type={'text'}
          validationError={flattenError(validationErrors.mail_from)}
          value={
            matchingDomains.length
              ? matchingDomains[0].get('mail_from').replace(`.${domain.get('email_domain')}`, '')
              : domain.get('mail_from').replace(`.${domain.get('email_domain')}`, '')
          }
        />
      </Fieldset>
    </Form>
  );
}

export default observer(DomainEditStepThree);
