import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Page from '@feathr/extender/App/Page';
import { StoresContext } from '@feathr/extender/state';

import ImisIntegrationCard from './ImisIntegrationCard';
import ImisIntegrationControls from './ImisIntegrationControls/ImisIntegrationControls';

function ImisIntegrationPage(): JSX.Element {
  const { t } = useTranslation();
  const { ImisIntegrations } = useContext(StoresContext);
  const imisIntegrations = ImisIntegrations.list();
  // There should only be one integration per account; so, if there is one, we have the basics of an integration
  const integration = imisIntegrations.models.length ? imisIntegrations.models[0] : undefined;

  const actions = <ImisIntegrationControls integration={integration} />;

  return (
    <Page
      actions={actions}
      // TODO: Update this description to include activity data if/when the integration supports it.
      description={t('Connect to iMIS to synchronize contact data.')}
      loading={imisIntegrations.isPending}
      title={t('iMIS Integration Manager')}
    >
      <ImisIntegrationCard integration={integration} />
    </Page>
  );
}

export default observer(ImisIntegrationPage);
