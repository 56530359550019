import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Creative } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { Button, Table, TableStatsCard } from '@feathr/components';
import { useToggle } from '@feathr/hooks';
import type { IRachisMessage } from '@feathr/rachis';
import { wretch } from '@feathr/rachis';

import { StoresContext } from '../../state';
import ExportModal from '../ExportModal';
import CreativeTableColumns from './CreativeTableColumns';

interface IProps {
  campaign: Campaign;
  end: string;
  start: string;
}

function CreativeTableCard({ campaign, end, start }: IProps): JSX.Element {
  const { Creatives, Stats } = useContext(StoresContext);
  const [showExportModal, toggleExportModal] = useToggle(false);
  const { t } = useTranslation();

  async function handleConfirmExport(email: string): Promise<void> {
    const exportParams = new URLSearchParams({
      _parent: campaign.id,
      start,
      end,
      email,
    });
    // TODO: Add error handling.
    await wretch<IRachisMessage>(`${Creatives.url()}export?${exportParams.toString()}`, {
      method: 'GET',
      headers: Creatives.getHeaders(),
    });
  }

  const actions = [
    <Button key={'export'} onClick={toggleExportModal}>
      {t('Export to CSV')}
    </Button>,
  ];

  return (
    <>
      <TableStatsCard actions={actions} title={reportModuleLabels.includeCreativesTable}>
        <Table<Creative>
          collection={Creatives}
          columns={CreativeTableColumns(campaign, start, end, Stats, t)}
          filters={{
            _parent: campaign.id,
            is_archived__ne: true,
            is_hidden__ne: true,
            date_created__lte: end,
          }}
          initialSort={[{ id: 'name' }]}
        />
      </TableStatsCard>
      {showExportModal && (
        <ExportModal
          onClose={toggleExportModal}
          onConfirm={handleConfirmExport}
          title={'Export Creatives'}
        />
      )}
    </>
  );
}

export default observer(CreativeTableCard);
