import classNames from 'classnames';
import type { TFunction } from 'i18next';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import type { Importer } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader, Time } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import ImporterStateChip from './ImporterStateChip';
import optionsColumn from './optionsColumn';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IRow {
  original: Importer;
}

export default function dataImportsColumns(
  columnIds: string[],
  t: TFunction,
): Array<IColumn<Importer>> {
  const columns = [
    {
      id: 'name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Name'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      width: 250,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const localUrl = useLocalUrl();

              return <Link to={localUrl(original.getItemUrl())}>{original.name}</Link>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'state',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Status'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellCenter,
      width: 110,
      Cell({ original }: IRow): JSX.Element {
        return <ImporterStateChip importer={original} />;
      },
    },
    {
      // Created time stamp is extracted from id as defined in MongoDB
      id: 'id',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Created'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellCenter,
      width: 110,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => (
              <Time format={TimeFormat.shortDate} timestamp={original.dateCreated.format()}></Time>
            )}
          </Observer>
        );
      },
    },
    {
      id: 'rows',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Total Records'),
        tooltip: t('Number of existing contacts that were matched and updated.'),
      }),
      headerClassName: classNames(tableStyles.sort, tableStyles.tooltip),
      className: tableStyles.cellCenter,
      width: 160,
      Cell({ original }: IRow): JSX.Element {
        return <Observer>{(): JSX.Element => <>{original.get('rows') || 0}</>}</Observer>;
      },
    },
    {
      id: 'inserted',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Persons Created'),
        tooltip: t('New contacts that were created from this import.'),
      }),
      headerClassName: classNames(tableStyles.sort, tableStyles.tooltip),
      className: tableStyles.cellCenter,
      width: 182,
      Cell({ original }: IRow): JSX.Element {
        return <Observer>{(): JSX.Element => <>{original.get('inserted')}</>}</Observer>;
      },
    },
    {
      id: 'duplicates',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Persons Updated'),
        tooltip: t('Number of existing contacts that were matched and updated.'),
      }),
      headerClassName: classNames(tableStyles.sort, tableStyles.tooltip),
      className: tableStyles.cellCenter,
      width: 182,
      Cell({ original }: IRow): JSX.Element {
        return <Observer>{(): JSX.Element => <>{original.get('duplicates')}</>}</Observer>;
      },
    },
    {
      id: 'skipped',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Persons Skipped'),
        tooltip: t(
          'Number of records that were not imported (usually because of a missing or improperly formatted email address).',
        ),
      }),
      headerClassName: classNames(tableStyles.sort, tableStyles.tooltip),
      className: tableStyles.cellCenter,
      width: 182,
      Cell({ original }: IRow): JSX.Element {
        return <Observer>{(): JSX.Element => <>{original.get('skipped')}</>}</Observer>;
      },
    },
    optionsColumn(),
  ];
  return columnIds.length ? columns.filter((column) => columnIds.includes(column.id!)) : columns;
}
