import type { TFunction } from 'i18next';

import type { TBinaryUserRolePermission, TDefaultRoleId, TPermissionMode } from '@feathr/blackbox';
import { EUserRoleIDs } from '@feathr/blackbox';

type TCombinedUserRoleProperty = 'users_and_roles';

// Provide and additional permission and return a description of that permission.
export const additionalPermissionDescriptionsMap = (
  additionalPermission: TBinaryUserRolePermission,
  t: TFunction,
): string => {
  const map: Record<TBinaryUserRolePermission, string> = {
    billing: t('Access, manage, and add billing options.'),
    conversions: t('Access account settings for conversions.'),
    domains: t('View, edit, and add domains that are used for serving content and emails.'),
    imports: t('Import person data and opt-out lists.'),
    integrations: t('Setup and manage data integrations with other platforms.'),
  };
  return map[additionalPermission] ?? t('No description available.');
};

/*
 * Some properties are combined into a single permission.
 * This function returns a description of that combined permission.
 *
 * For example, the 'users_and_roles' permission includes user and role management.
 */
export function combinedPropertyDescriptionMap(
  combinedProperty: TCombinedUserRoleProperty,
  t: TFunction,
): string {
  const map: Record<TCombinedUserRoleProperty, string> = {
    users_and_roles: t(
      'View, edit, and add users and custom roles, with varying levels of permissions.',
    ),
  };
  return map[combinedProperty] ?? t('No description available.');
}

export function readOnlyRolePrimaryValueMap(roleId: TDefaultRoleId, t: TFunction): string {
  const map: Record<TDefaultRoleId, string> = {
    [EUserRoleIDs.Admin]: t('Yes'),
    [EUserRoleIDs.User]: t('No'),
  };
  return map[roleId] ?? t('No value available.');
}

// Convert the permission mode to a translated human-readable string.
export function getPermissionMode(mode: TPermissionMode, t: TFunction): string {
  const map: Record<TPermissionMode, string> = {
    all: t('All'),
    some: t('Some'),
    none: t('None'),
  };
  return map[mode] ?? t('Invalid mode: {{mode}}', { mode });
}

// Convert the permission mode to a translated binary human-readable string.
export function getBinaryPermissionMode(mode: TPermissionMode, t: TFunction): string {
  const map: Record<TPermissionMode, string> = {
    all: t('Yes'),
    some: t('No'),
    none: t('No'),
  };
  return map[mode];
}

export function roleTypeChipColorMap(roleId: TDefaultRoleId | string): string {
  const roleTypeSystemColor = 'sky';
  const roleTypeCustomColor = 'default';
  const map: Record<TDefaultRoleId, string> = {
    [EUserRoleIDs.Admin]: roleTypeSystemColor,
    [EUserRoleIDs.User]: roleTypeSystemColor,
  };
  return map[roleId] ?? roleTypeCustomColor;
}

export function roleTypeMap(roleId: TDefaultRoleId | string, t: TFunction): string {
  const systemRole = t('System');
  const customRole = t('Custom');
  const map: Record<TDefaultRoleId, string> = {
    [EUserRoleIDs.Admin]: systemRole,
    [EUserRoleIDs.User]: systemRole,
  };
  return map[roleId] ?? customRole;
}
