import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { CardV2 as Card, Fieldset, Input } from '@feathr/components';

interface IProps {
  readonly person: Person;
}

function AddressData({ person }: IProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header title={t('Address')} />
      <Card.Content>
        <Fieldset>
          <Input
            attribute={['address', 'premise1']}
            key={'premise1'}
            label={t('Street address line 1')}
            model={person}
            name={'street-address-line-1-input'}
            type={'text'}
          />
          <Input
            attribute={['address', 'premise2']}
            key={'premise2'}
            label={t('Street address line 2')}
            model={person}
            name={'street-address-line-2-input'}
            type={'text'}
          />
          <Input
            attribute={['address', 'locality']}
            key={'locality'}
            label={t('City')}
            model={person}
            name={'city-input'}
            type={'text'}
          />
          <Input
            attribute={['address', 'administrative_area_name']}
            key={'administrative_area_name'}
            label={t('State/Province')}
            model={person}
            name={'state-or-province-input'}
            type={'text'}
          />
          <Input
            attribute={['address', 'postal_code']}
            key={'postal_code'}
            label={t('ZIP/Postal code')}
            model={person}
            name={'zip-or-postal-code-input'}
            type={'text'}
          />
        </Fieldset>
      </Card.Content>
    </Card>
  );
}

export default observer(AddressData);
