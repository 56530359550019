import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useRef } from 'react';

import type { ConversationCampaign } from '@feathr/blackbox';
import { useAccount } from '@feathr/extender/state';

import getFeathrPixel from './feathrPixel';

import { preview, previewWrapper } from '../ConversationDetails.css';

interface IProps {
  campaign: ConversationCampaign;
}

function ConversationPreview({ campaign }: IProps): JSX.Element {
  const account = useAccount();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const renderPreviewFrame = () => {
    if (!iframeRef.current) {
      return;
    }
    const document = iframeRef.current.contentDocument;
    const window = iframeRef.current.contentWindow;
    if (!(document && window)) {
      return;
    }
    document.open('text/html', 'replace');
    document.write(getFeathrPixel(account.id));
    document.close();
    const defaultConversationCampaign = campaign.toJS();
    (window as any).feathr.defaultConversationCampaign = defaultConversationCampaign;
  };

  const updatePreview = () => {
    if (!iframeRef.current) {
      return;
    }
    const window = iframeRef.current.contentWindow;
    if (window && (window as any).conversationStore) {
      const conversationCampaign = campaign.toJS();
      (window as any).conversationStore.dispatch({
        type: 'feathr/conversation/FETCH_CONVERSATION_SUCCESS',
        data: conversationCampaign,
      });
    }
  };

  useEffect(() => renderPreviewFrame(), []);

  useEffect(
    () =>
      reaction(
        () => campaign.toJS(),
        async () => {
          const promise = new Promise((resolve, reject) => {
            function poll(tries: number) {
              setTimeout(() => {
                const result = (iframeRef.current!.contentWindow as any).conversationStore;
                if (!result) {
                  poll(tries + 1);
                } else if (tries > 10) {
                  reject(new Error('Conversation store not available.'));
                } else {
                  resolve(result);
                }
              }, 100);
            }

            poll(0);
          });

          await promise;
          updatePreview();
        },
        { delay: 2000, fireImmediately: true },
      ),
    [],
  );

  return (
    <div className={previewWrapper}>
      <iframe className={preview} ref={iframeRef} title={'Conversation Preview'} />
    </div>
  );
}

export default observer(ConversationPreview);
