import { faMousePointer, faPlus, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { components } from 'react-select';

import { FieldCollection, FieldDataType } from '@feathr/blackbox';
import { Chip } from '@feathr/components';
import { fieldDataTypeIcon } from '@feathr/extender/styles/fieldDataType';

function valueToFieldDataType(value: string | number | boolean | Date | undefined) {
  if (typeof value === 'string') {
    return FieldDataType.str;
  }
  if (typeof value === 'number') {
    return FieldDataType.int;
  }
  if (typeof value === 'boolean') {
    return FieldDataType.bool;
  }
  if (value instanceof Date) {
    return FieldDataType.date;
  }
  return FieldDataType.str;
}

export function FieldOption(props: any) {
  let icon = fieldDataTypeIcon(
    props.data.data_type ? props.data.data_type : valueToFieldDataType(props.data.value),
  );
  if (props.data.id === 'create') {
    icon = faPlus;
  }
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
          {icon && <FontAwesomeIcon icon={icon} />}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{props.data.name || props.data.label || props.data.u_key}</span>
        </div>
      </div>
    </components.Option>
  );
}

const collectionOptions = {
  [FieldCollection.Person]: ['Attribute', faUser],
  [FieldCollection.Breadcrumb]: ['Activity', faMousePointer],
};

export function CollectionFieldOption(props: any) {
  let icon = fieldDataTypeIcon(
    props.data.data_type ? props.data.data_type : valueToFieldDataType(props.data.value),
  );
  const chipContent = collectionOptions[props.data.collection];
  const collectionChip = chipContent ? (
    <Chip prefix={<FontAwesomeIcon icon={chipContent[1]} />}>{chipContent[0]}</Chip>
  ) : undefined;
  if (props.data.id === 'create') {
    icon = faPlus;
  }
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
            {icon && <FontAwesomeIcon icon={icon} />}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{props.data.name || props.data.label || props.data.u_key}</span>
          </div>
        </div>
        {!!collectionChip && (
          <div style={{ display: 'flex', alignItems: 'center' }}>{collectionChip}</div>
        )}
      </div>
    </components.Option>
  );
}
