import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IStats } from '@feathr/blackbox';
import { FormSummaryItem, Tooltip } from '@feathr/components';

import * as styles from './PinpointEmailCampaignAudienceSummary.css';

interface IProps {
  stats: IStats;
}

function PinpointEmailCampaignAudienceSummary({ stats }: IProps) {
  const { t } = useTranslation();

  return (
    <FormSummaryItem
      label={t('Audience summary')}
      value={
        <ul>
          <li key={'num_targeted'}>
            Targeted: {stats.num_targeted || 0}
            <Tooltip
              className={classNames(styles.icon)}
              title={
                'The total number of people with email addresses from your combination of included and excluded groups.'
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Tooltip>
          </li>
          <li key={'num_not_sent'}>
            Not sent: {stats.num_not_sent || 0}
            <Tooltip
              className={classNames(styles.icon)}
              title={
                'The number of people who were not sent your email because they previously hard bounced, unsubscribed, or marked your emails as spam.'
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Tooltip>
          </li>
          <li key={'num_sent'}>
            Total sent:{' '}
            {(stats.flavors?.pinpoint_tracked_email_send || 0) +
              (stats.flavors?.partner_message_send || 0)}
            <Tooltip
              className={classNames(styles.icon)}
              title={'The final number of people who were sent your email.'}
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Tooltip>
          </li>
        </ul>
      }
    />
  );
}

export default observer(PinpointEmailCampaignAudienceSummary);
