import { faCloudUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ToastType } from 'react-toastify';

import type { IImporter, Importer } from '@feathr/blackbox';
import { Button, toast, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { StoresContext, useLocalUrl, useUser } from '@feathr/extender/state';

import EventImportCard from './EventImportCard';

import * as styles from './EventPartnersImportsHistoryPage.css';

function EventPartnersImportsHistoryPage(): JSX.Element {
  const { Importers } = useContext(StoresContext);
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const user = useUser();
  const { t } = useTranslation();

  const importers = Importers.list({
    filters: {
      event: eventId,
      kind: 'partner',
    },
  });

  async function addImporter(): Promise<void> {
    try {
      const importerAttrs: Record<
        keyof Pick<IImporter, 'event' | 'created_by' | 'kind' | 'state'>,
        string
      > = {
        event: eventId,
        created_by: user.id,
        kind: 'partner',
        state: 'draft',
      };

      const importer = Importers.create(importerAttrs as Partial<Importer>);
      const response = await Importers.add(importer, { validate: false });
      history.push(localUrl(response.getItemUrl()));
    } catch (e) {
      toast(t('There was an error creating the import.'), { type: ToastType.ERROR });
    }
  }

  const importPartnersButton: JSX.Element = (
    <Button
      onClick={addImporter}
      prefix={<FontAwesomeIcon icon={faCloudUpload} />}
      type={'primary'}
    >
      {t('Import partners')}
    </Button>
  );

  const actions: JSX.Element = <Toolbar>{importPartnersButton}</Toolbar>;
  const hasImports = !!importers.models.length;

  const noImportsMessage: JSX.Element = (
    <div className={styles.noImports}>
      <h4>{t('No Imports')}</h4>
      <p>{t('You have not created any imports.')}</p>
      {importPartnersButton}
    </div>
  );

  return (
    <Page actions={hasImports && actions} loading={importers.isPending} title={t('Imports')}>
      {importers.models.map((importer: Importer) => (
        <EventImportCard importer={importer} key={importer.id} />
      ))}
      {!importers.isPending && !hasImports && noImportsMessage}
    </Page>
  );
}

export default observer(EventPartnersImportsHistoryPage);
