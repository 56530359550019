import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import {
  AlertV2 as Alert,
  Button,
  CardContent,
  CardHeader,
  CardV2 as Card,
  EAlertV2Type,
  Spinner,
  toast,
} from '@feathr/components';
import { useAccount, useUser } from '@feathr/extender/state';
import type { TRachisEmpty } from '@feathr/rachis';
import { Collection, isWretchError, wretch } from '@feathr/rachis';

import Page from '../Page';

import * as styles from './VersionPage.css';

interface IBlackboxVersion {
  DB_ENV: string;
  ES_ENV: string;
  FLASK_ENV: string;
  GIT_COMMIT: string;
}

function VersionPage(): JSX.Element {
  const account = useAccount();
  const user = useUser();
  const { t } = useTranslation();
  const [blackboxVersion, setBlackboxVersion] = useState<IBlackboxVersion | undefined>();
  const [blackboxVersionError, setBlackboxVersionError] = useState<string | undefined>();

  // Do not use wretch to have the most direct connection to the backend
  async function getBlackboxVersion(): Promise<IBlackboxVersion | undefined> {
    const response = await fetch(`${BLACKBOX_URL}version`, {
      method: 'GET',
      headers: Collection.prototype.getHeaders(),
    });
    if (!response.ok) {
      setBlackboxVersionError(await response.text());
      return;
    }
    try {
      return (await response.json()).data as IBlackboxVersion;
    } catch (error) {
      const message = error instanceof Error ? error.message : String(error);
      setBlackboxVersionError(message);
      return;
    }
  }

  useEffect(() => {
    getBlackboxVersion().then(setBlackboxVersion);
  }, [setBlackboxVersion]);

  async function handleReset(): Promise<void> {
    const response = await wretch<TRachisEmpty>(
      `${BLACKBOX_URL}accounts/${account.id}/purge-and-reset?with_data=0`,
      {
        headers: Collection.prototype.getHeaders(),
        method: 'POST',
      },
    );
    if (isWretchError(response)) {
      toast(t('An error occurred while trying to reset this account!'), { type: ToastType.ERROR });
    }
  }

  async function handleReseed(): Promise<void> {
    const response = await wretch<TRachisEmpty>(
      `${BLACKBOX_URL}accounts/${account.id}/purge-and-reset?with_data=1`,
      {
        headers: Collection.prototype.getHeaders(),
        method: 'POST',
      },
    );
    if (isWretchError(response)) {
      toast(t('An error occurred while trying to reset and reseed this account!'), {
        type: ToastType.ERROR,
      });
    }
  }

  const allowedAccounts = [
    '000000000000000000000000',
    'beeeeeeeeeeeeeeeeeeeeeef',
    'dead0000000000000000cafe',
  ];

  // When blackboxVersion.DB_ENV and blackboxVersion.ES_ENV are set to 'staging', we are on staging
  const isOnStaging =
    blackboxVersion?.DB_ENV === 'staging' && blackboxVersion?.ES_ENV === 'staging';
  const isEmpowered =
    isOnStaging && allowedAccounts.includes(account.id) && (user.isSuperuser || user.isAdmin);

  return (
    <Page title={t('Version')}>
      {isEmpowered && (
        <Card name={'utils'}>
          <CardHeader title={t('Utilities')} />
          <CardContent
            action={
              <Button onClick={handleReset} type={'primary'}>
                {t('Reset')}
              </Button>
            }
            description={t('Reset this account to the minimal required data.')}
          />
          <CardContent
            action={
              <Button onClick={handleReseed} type={'primary'}>
                {t('Reseed')}
              </Button>
            }
            description={t('Reset and reseed this account with default test data.')}
          />
        </Card>
      )}
      <Card name={'frontend'}>
        <CardHeader title={t('Frontend')} />
        <CardContent
          action={GIT_VERSION || 'dev'}
          contentClassName={styles.value}
          description={t('Git commit hash')}
          title={t('Version')}
        />
        <CardContent action={user.name} title={t('Username')} />
        <CardContent action={user.id} title={t('User ID')} />
        <CardContent action={account.name} title={t('Account name')} />
        <CardContent action={account.id} title={t('Account ID')} />
      </Card>
      <Card name={'backend'}>
        <CardHeader title={t('Backend')} />
        {!blackboxVersion && !blackboxVersionError && (
          <CardContent key={'loader'}>
            <Spinner />
          </CardContent>
        )}
        {blackboxVersionError && (
          <CardContent key={'loader'}>
            <Alert
              description={t(
                'Refresh the page to try again. If the problem persists, please contact support.',
              )}
              title={t('Could not fetch version information from the backend')}
              type={EAlertV2Type.danger}
            />
          </CardContent>
        )}

        {blackboxVersion && (
          <>
            <CardContent
              action={blackboxVersion.GIT_COMMIT.trim() || 'dev'}
              contentClassName={styles.value}
              description={t('Git commit hash')}
              key={'git'}
              title={t('Version')}
            />
            <CardContent action={blackboxVersion.DB_ENV} key={'db'} title={t('Database')} />
            <CardContent action={blackboxVersion.ES_ENV} key={'es'} title={t('Elasticsearch')} />
            <CardContent action={blackboxVersion.FLASK_ENV} key={'flask'} title={t('Flask')} />
          </>
        )}
      </Card>
    </Page>
  );
}

export default VersionPage;
