import classNames from 'classnames';
import debounce from 'debounce-promise';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';

import type { ITag, TTagContext } from '@feathr/blackbox';
import { AsyncCreatableSelectElement } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';

import * as styles from './TagsField.css';

interface IProps {
  className?: string;
  context: TTagContext;
  disabled?: boolean;
  helpText?: string;
  hideInternalTags?: boolean;
  label?: React.ReactNode;
  onChange: (newValue: string[]) => void;
  value?: string[];
  wrapperClassName?: string;
}

function TagsField({
  className,
  context,
  disabled = false,
  helpText,
  hideInternalTags = false,
  label,
  onChange,
  value = [],
  wrapperClassName,
}: IProps): JSX.Element {
  const { Tags } = useContext(StoresContext);
  const defaultTags = Tags.list({
    filters: { context },
    pagination: { page_size: 10 },
  }).models.map((t) => t.toJS());

  const loadOptions = debounce(async (inputValue: string) => {
    const tags = Tags.list({
      filters: { context, name__icontains: inputValue },
      pagination: { page_size: 1000 },
    });

    await when(() => !tags.isPending);
    const options = tags.models.map((tag) => tag.toJS());
    return hideInternalTags ? options.filter((tag) => !tag.internal) : options;
  }, DEFAULT_DEBOUNCE_WAIT);

  function isValidNewOption(inputValue: string, _: any, options: readonly ITag[]): boolean {
    const tagModel = Tags.create({ context, name: inputValue });
    return (
      !!inputValue &&
      tagModel.isValid(['name'], false) &&
      !options.find((tag) => tag.name === inputValue)
    );
  }

  async function createOption(inputValue: string): Promise<ITag> {
    const tag = await Tags.add(Tags.create({ context, name: inputValue }));
    onChange([...value, tag.id]);
    return tag.toJS();
  }

  const currentTags = value.map((tagId) => Tags.get(tagId).toJS());

  return (
    <AsyncCreatableSelectElement<ITag>
      className={classNames(styles.root, className)}
      createOption={createOption}
      defaultOptions={hideInternalTags ? defaultTags.filter((tag) => !tag.internal) : defaultTags}
      disabled={disabled}
      getNewOptionData={(inputValue, optionLabel) =>
        ({ id: inputValue, name: optionLabel }) as ITag
      }
      getOptionLabel={(option: any) => (option.name ? option.name : '')}
      // cacheOptions={true}
      getOptionValue={(option: any) => (option.id ? option.id : '')}
      helpText={helpText}
      isMulti={true}
      isValidNewOption={isValidNewOption}
      key={'tags-field'}
      label={label}
      loadOptions={loadOptions}
      onChange={(values) => {
        onChange(values.map((tag) => tag.id!));
      }}
      placeholder={'Tags'}
      value={hideInternalTags ? currentTags.filter((tag) => !tag.internal) : currentTags}
      wrapperClassName={wrapperClassName}
    />
  );
}

export default observer(TagsField);
