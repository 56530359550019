import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const FlightColumn: IColumn<BaseCampaign> = {
  id: 'flight',
  checkboxLabel: 'Flight',
  Header: TableColumnHeader({
    title: 'Flight',
  }),
  sortable: false,
  className: tableStyles.cellRight,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          const { Flights } = useContext(StoresContext);
          const flightId = original.get('flight');
          const flight = flightId ? Flights.get(flightId) : undefined;
          return <>{flight?.get('name') || '-'}</>;
        }}
      </Observer>
    );
  },
};

export default FlightColumn;
