import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import { CampaignClass } from '@feathr/blackbox';
import { StatBox } from '@feathr/components';

import type { IStatProps } from './Stats';

interface IProps extends IStatProps {
  type?: CampaignClass;
  views?: number;
}

function ClicksStat({
  label = 'Clicks',
  tooltip,
  type,
  value,
  views,
  size = 'default',
}: IProps): JSX.Element {
  const primary = numeral(value).format('0,0');
  const secondaryLabel = views !== undefined ? 'CTR' : undefined;
  const secondary = views !== undefined ? numeral(value / views || 0).format('0.00%') : undefined;
  const ctrFlagged = !!(
    type &&
    views &&
    [
      CampaignClass.Search,
      CampaignClass.Segment,
      CampaignClass.Lookalike,
      CampaignClass.SeedSegment,
      CampaignClass.Affinity,
      CampaignClass.EmailList,
      CampaignClass.MobileGeoFencing,
      CampaignClass.MobileGeoFenceRetargeting,
    ].includes(type) &&
    value / views >= 0.04 &&
    views > 1000
  );

  return (
    <StatBox
      label={label}
      primary={primary}
      secondary={secondary}
      secondaryLabel={secondaryLabel}
      size={size}
      tooltip={tooltip}
      warning={
        ctrFlagged
          ? 'Please note that a CTR above 4% is considered unusual. Contact Product Support if you have any questions.'
          : undefined
      }
    />
  );
}

export default observer(ClicksStat);
