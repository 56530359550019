import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { DripCampaign } from '@feathr/blackbox';
import { CardV2 as Card, Input, Layout } from '@feathr/components';

import EmailCampaignSendWindow from '../../EmailCampaignSendWindow';

interface IDetailsStepProps {
  campaign: DripCampaign;
}

function DetailsStep({ campaign }: IDetailsStepProps): JSX.Element {
  const { t } = useTranslation();

  /*
   * TODO: Verify this is in fact the best way to get the time to send and subtype
   * for the trigger step of the campaign after step 1 is integrated #3705
   */
  const triggerStep =
    campaign.get('step_specs').length > 0 ? campaign.get('step_specs')[0] : undefined;
  const timeToSend = triggerStep?.time_to_send;
  const isTimeSubtype = triggerStep?.subtype === 'time';

  // TODO: Disable the inputs after the campaign has been published #3732
  const isDisabled = false;

  return (
    <Layout>
      <>
        <Card width={'full'}>
          <Card.Header
            description={t(
              'People who meet the triggers of this campaign during the specified time frame will receive your message. The maximum time window is one year.',
            )}
            title={t('Send Window')}
          />
          <Card.Content addVerticalGap={true}>
            <EmailCampaignSendWindow
              campaign={campaign}
              isDisabled={isDisabled}
              isTimeSubtype={isTimeSubtype}
              timeToSend={timeToSend}
              // TODO: Add validation errors #3714
              validationErrors={undefined}
            />
          </Card.Content>
        </Card>
        <Card width={'full'}>
          <Card.Header title={t('Information')} />
          <Card.Content>
            <Input
              attribute={'name'}
              label={t('Campaign name')}
              model={campaign}
              required={true}
              type={'text'}
            />
          </Card.Content>
        </Card>
      </>
    </Layout>
  );
}

export default observer(DetailsStep);
