import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { Fragment } from 'react';

import type { DataRequest } from '@feathr/blackbox';
import type { ButtonType } from '@feathr/components';
import { Button, Tooltip } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface IProps {
  children?: React.ReactNode;
  dataRequest: DataRequest;
  disabled?: boolean;
  onNext: () => void;
  type?: ButtonType;
  validate: (request: DataRequest) => TValidateGrouped;
}

function ButtonSubmit({
  children,
  dataRequest,
  disabled,
  onNext,
  type = 'primary',
  validate,
  ...buttonProps
}: IProps): JSX.Element {
  const errors = flattenErrors(validate(dataRequest));
  const isValid = errors.length === 0;
  const button = (
    <Button {...buttonProps} disabled={disabled || !isValid} onClick={onNext} type={type}>
      {children}
    </Button>
  );

  return isValid ? (
    button
  ) : (
    <Tooltip
      title={errors.map((error, i) => (
        <Fragment key={i}>
          {error}
          {i < errors.length - 1 && <br />}
        </Fragment>
      ))}
    >
      {button}
    </Tooltip>
  );
}

export default observer(ButtonSubmit);
