import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { FacebookCampaign } from '@feathr/blackbox';
import { FormElement, Select } from '@feathr/components';

import * as styles from './AgeSelect.css';

interface IAgeSelectProps {
  campaign: FacebookCampaign;
  label?: string;
  helpText?: ReactNode;
}

interface IAge {
  id: number;
  name: string;
}

const AgeOption = (props: OptionProps<IAge>): JSX.Element => (
  <components.Option {...props}>
    <div
      className={classNames(styles.description, {
        [styles.disabled]: props.isDisabled,
      })}
    >
      {props.data.name}
    </div>
  </components.Option>
);

const AgeSelect = observer(({ campaign, label, helpText }: IAgeSelectProps): JSX.Element => {
  const { t } = useTranslation();
  const [selectedMinAge, setSelectedMinAge] = useState<number | null>(
    campaign.get('exposure_settings')?.age_min,
  );
  const [selectedMaxAge, setSelectedMaxAge] = useState<number | null>(
    campaign.get('exposure_settings')?.age_max,
  );

  const selectedAdCategory = campaign.get('special_ad_category');
  const targetedCountries = campaign.get('targeted_countries');
  const isRestrictedCategory = ['HOUSING', 'CREDIT'].includes(selectedAdCategory);

  if (isRestrictedCategory) {
    return (
      <FormElement
        helpText={t(
          'Facebook requires an age range of 18 - 65+ for Credit and Housing Ad Categories',
        )}
        label={label}
      >
        <div className={classNames(styles.selectContainer, styles.disabled)}>18 - 65+</div>
      </FormElement>
    );
  }

  const minimumAge = ((): number => {
    if (Array.isArray(targetedCountries)) {
      // Sets the minimum age to 20 for Thailand and 21 for Indonesia.
      if (targetedCountries.includes('TH')) {
        return 20;
      }
      if (targetedCountries.includes('ID')) {
        return 21;
      }
    }
    // Sets the minimum age to 18 for housing, credit, and employment ads.
    if (['EMPLOYMENT'].includes(selectedAdCategory)) {
      return 18;
    }
    // Sets the minimum age to 13 for all other ads.
    return 13;
  })();

  // Adjusts the age options based on the minimum age.
  const ageOptionsLength = 65 - minimumAge + 1;

  const ageOptions: IAge[] = Array.from({ length: ageOptionsLength }, (_, i) => ({
    id: i + minimumAge,
    name: i + minimumAge === 65 ? '65+' : (i + minimumAge).toString(),
  }));

  function handleMinAgeChange(selectedOption: IAge | null): void {
    // Handles changes in the minimum age selection, updates state, and ensures max age is reset if necessary
    const newMinAge: number | null = selectedOption ? selectedOption.id : null;
    // Determine the new minimum age based on selected option
    setSelectedMinAge(newMinAge);
    if (selectedMaxAge !== null && newMinAge !== null && selectedMaxAge <= newMinAge) {
      setSelectedMaxAge(null);
      // Reset the selected maximum age if it is less than or equal to the new minimum age
      campaign.set({
        exposure_settings: {
          ...campaign.get('exposure_settings'),
          age_max: null,
        },
      });
    }
    campaign.set({
      exposure_settings: {
        ...campaign.get('exposure_settings'),
        age_min: newMinAge,
      },
    });
  }

  function handleMaxAgeChange(selectedOption: IAge | null): void {
    // Handles changes in the maximum age selection and updates both local state and the campaign's settings
    const newMaxAge: number | null = selectedOption ? selectedOption.id : null;
    // Determine the new maximum age based on the selected option
    setSelectedMaxAge(newMaxAge);
    campaign.set({
      exposure_settings: {
        ...campaign.get('exposure_settings'),
        age_max: newMaxAge,
      },
    });
  }

  const getValue = (age: number | null): IAge | null | undefined => {
    // Returns the age option matching the given age id, or null if no age is selected.
    return age !== null ? ageOptions.find((option) => option.id === age) : null;
  };

  const maxAgeOptions: IAge[] = ageOptions.filter(
    // Filters the ageOptions array to include only options greater than the selected minimum age, ensuring valid max age choices
    (option) => !selectedMinAge || option.id > selectedMinAge,
  );

  return (
    <FormElement helpText={helpText} label={label}>
      <div className={styles.selectContainer}>
        <Select<IAge>
          components={{ Option: AgeOption }}
          isClearable={true}
          name={'min_age_select'}
          onChange={handleMinAgeChange}
          options={ageOptions}
          placeholder={t('Select minimum age...')}
          value={getValue(selectedMinAge)}
        />
        <Select<IAge>
          components={{ Option: AgeOption }}
          isClearable={true}
          name={'max_age_select'}
          onChange={handleMaxAgeChange}
          options={maxAgeOptions}
          placeholder={t('Select maximum age...')}
          value={getValue(selectedMaxAge)}
        />
      </div>
    </FormElement>
  );
});

export default AgeSelect;
