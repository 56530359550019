import React from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';

import { useAccount } from '@feathr/extender/state';

function RedirectTo404() {
  const { accountId } = useParams<{ accountId?: string }>();
  const location = useLocation();
  const account = useAccount();
  const redirectAccountId = accountId ? accountId : account ? account.id : undefined;

  if (redirectAccountId) {
    return (
      <Redirect
        to={{ pathname: `/${redirectAccountId}/404-not-found`, state: { from: location } }}
      />
    );
  }
  return <Redirect to={{ pathname: '/404-not-found', state: { from: location } }} />;
}

export default RedirectTo404;
