import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardV2 as Card, EmailTemplateSelect, Label, Well } from '@feathr/components';

import * as styles from './PreviewAutomation.css';

function PreviewAutomation(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Card.Content>
        <Label weight={'semibold'}>{t('Automation')}</Label>
        {/* TODO: Integrate the preview UI #3704 */}
        <div className={styles.wells}>
          <Well layout={'vertical'} theme={'white'}>
            <Well.Row
              description={t('Send to everyone')}
              label={t('Step targeting')}
              tooltip={'Placeholder tooltip'}
            />
            <Well.Row
              description={t('30 minutes')}
              label={t('Delay from delivery')}
              tooltip={'Placeholder tooltip'}
            />
          </Well>
        </div>
      </Card.Content>
      <Card.Content>
        <Label weight={'semibold'}>{t('Email')}</Label>
        {/* TODO: Integrate the preview UI #3704 */}
        <EmailTemplateSelect readOnly={true} title={t('No template selected')} />
      </Card.Content>
    </>
  );
}

export default PreviewAutomation;
