import { makeObservable } from 'mobx';

import { concatPath, toQueryString } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, isWretchError, Model, wretch } from '@feathr/rachis';

export enum EUsageMetrics {
  Breadcrumbs = 'num_monthly_breadcrumbs',
  IncludedIdentifiedPersons = 'num_identified_persons',
  IncludedAnonymousPersons = 'num_anonymous_people',
  Emails = 'num_monthly_emails',
  CustomDataFields = 'num_custom_fields',
  ConversionPixels = 'num_conversion_pixels',
  EmailMappingCampaigns = 'num_email_mapping_campaigns',
  GeofencingCampaigns = 'num_geofencing_campaigns',
  HistoricalGeofencingCampaigns = 'num_historical_geofencing_campaigns',
  DKIMAuthentications = 'num_dkim_authentications',
  AdBuilders = 'num_monthly_ad_builders',
  Projects = 'num_active_projects',
  Users = 'num_users',
  PartnersByProject = 'num_partners_by_project',
}

type TMetric = Record<EUsageMetrics, number>;
export interface IUsageMetric extends IBaseAttributes, TMetric {}

export class UsageMetric extends Model<IUsageMetric> {
  public readonly className = 'Usage Metric';

  public constraints: TConstraints<IUsageMetric> = {};

  constructor(attributes: Partial<IUsageMetric> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/stats_accounts/usage/${this.id}`, pathSuffix);
  }
}

export class UsageMetrics extends Collection<UsageMetric> {
  public getClassName(): string {
    return 'usage_metrics';
  }

  public url(): string {
    return `${this.getHostname()}stats_accounts/usage/`;
  }

  public getModel(attributes: Partial<IUsageMetric>): UsageMetric {
    return new UsageMetric(attributes);
  }

  public async getSelectedMetrics(
    accountId: string,
    metrics: EUsageMetrics[],
  ): Promise<IUsageMetric> {
    const headers = this.getHeaders();
    const params = {
      fields: {
        data: metrics.join(','),
      },
    };
    const url = `${this.url()}${accountId}?${toQueryString(params)}`;
    const response = await wretch<IUsageMetric>(url, {
      method: 'GET',
      headers,
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }
}
