import type { TFunction, WithT } from 'i18next';
import type { JSX } from 'react';
import React from 'react';
import { Trans } from 'react-i18next';

import { EUsageMetrics } from '@feathr/blackbox';

import * as styles from './UsageStat.css';

interface IUsageStat extends WithT {
  limit: string;
  title: string;
  usage: string;
}

function UsageStat({ title, usage, limit, t }: IUsageStat): JSX.Element {
  return (
    <div className={styles.usage} key={title}>
      <Trans t={t}>
        <span className={styles.usageCategory}>{{ title }} </span>
        <span className={styles.usageCurrent}>{{ usage }}</span>
        <span> / {{ limit }}</span>
      </Trans>
    </div>
  );
}

export function usageMap(t: TFunction, key: EUsageMetrics): string {
  const mappedData = {
    [EUsageMetrics.IncludedAnonymousPersons]: t('Anonymous'),
    [EUsageMetrics.IncludedIdentifiedPersons]: t('Identified'),
    [EUsageMetrics.Breadcrumbs]: t('Breadcrumbs'),
  };

  return mappedData[key];
}

export default UsageStat;
