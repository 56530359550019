import { when } from 'mobx';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Flight } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { ArchiveModal, toast } from '@feathr/components';
import CampaignSummary from '@feathr/extender/App/EventsPage/CampaignSummary';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

interface ICampaignDeleteModalProps {
  campaign: Campaign;
  isDeleteModalOpen: boolean;
  closeDeleteModal: () => void;
}

function CampaignDeleteModal({
  campaign,
  isDeleteModalOpen,
  closeDeleteModal,
}: Readonly<ICampaignDeleteModalProps>): JSX.Element {
  const rootStore = React.useContext(StoresContext);
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  const state = campaign.get('state');
  const isNotDraft = state !== CampaignState.Draft;

  const flightOrProject = campaign.get('flight') ? t('flight') : t('project');
  const flightOrProjectURL = campaign.get('flight')
    ? localUrl(campaign.getItemUrl())
    : localUrl(`/projects/${campaign.get('parent')}/report`);

  const alertPageLink = (
    <a href={flightOrProjectURL} target={'_blank'}>
      {t('View {{flightOrProject}} report', { flightOrProject })}
    </a>
  );

  async function getFlight(): Promise<Flight> {
    const { Flights } = rootStore;
    const flight = Flights.get(campaign.get('flight')!);
    await when(() => !flight.isPending);
    return Flights.get(flight.id);
  }

  async function destroy(): Promise<void> {
    try {
      if (campaign.get('flight')) {
        const flight = await getFlight();
        flight.removeCampaign(campaign.id);
        await flight.save();
      }
      campaign.set({
        is_archived: true,
        state: CampaignState.Archived,
      });
      await campaign.delete();

      toast(t('Campaign {{name}} has been deleted.', { name: campaign.name }));
      closeDeleteModal();
    } catch (error: any) {
      toast(
        t('Encountered an error while deleting:\n{{- errorMessage}}', {
          errorMessage: error.message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  return (
    <ArchiveModal
      alertDescription={
        isNotDraft
          ? t(
              'Deleting this campaign will make this {{flightOrProject}}\'s reported conversions inaccurate. To recalculate conversions to exclude the deleted campaign, select "Recalculate conversions" from the conversions table on this {{flightOrProject}}\'s report.',
              { flightOrProject },
            )
          : undefined
      }
      alertLinks={isNotDraft && alertPageLink}
      alertTitle={
        isNotDraft &&
        t('Are you sure you want to delete this campaign? This action cannot be undone.')
      }
      confirmationPhrase={t('DELETE')}
      method={'delete'}
      onClose={closeDeleteModal}
      onConfirm={destroy}
      opened={isDeleteModalOpen}
      requireConfirmation={true}
      t={t}
      title={t('Delete Campaign')}
    >
      <CampaignSummary campaign={campaign} showMinimal={true} />
    </ArchiveModal>
  );
}

export default CampaignDeleteModal;
