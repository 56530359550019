import type { Dispatch, JSX, SetStateAction } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { User } from '@feathr/blackbox';
import type { IColumn, ITableWrapperProps } from '@feathr/components';
import { Table } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import type { IObject } from '@feathr/rachis';
interface IUsersTableProps {
  columns: Array<IColumn<User>>;
  filters?: IObject;
  filterElements?: ITableWrapperProps['filters'];
  filterLabel?: ITableWrapperProps['filterLabel'];
  isPaginated?: boolean;
  selected: string[] | undefined;
  setSelected: Dispatch<SetStateAction<string[]>>;
}

function UsersTable(props: Readonly<IUsersTableProps>): JSX.Element {
  const { Users } = useContext(StoresContext);
  const { t } = useTranslation();

  return (
    <Table<User>
      {...props}
      collection={Users}
      initialPagesize={10}
      initialSort={[{ id: 'date_last_access' }]}
      isSelectable={true}
      noDataText={t('No users were found.')}
      wrapperMode={'simple'}
    />
  );
}

export default UsersTable;
