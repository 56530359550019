import { faBracketsCurly, faEdit, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { WithT } from 'i18next';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';

import type {
  AutoPinpointEmailCampaign,
  PinpointEmailCampaign,
  SmartPinpointEmailCampaign,
} from '@feathr/blackbox';
import { ArchiveModalV1, Button, TableColumnHeader, Tooltip } from '@feathr/components';
import type { IConfirmButtonProps } from '@feathr/extender/components/ConfirmButton';
import ConfirmButton from '@feathr/extender/components/ConfirmButton';
import type { ITemplateRow } from '@feathr/extender/components/TemplatesTable/TemplatesTable';
import { useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './TemplateColumnPinpointOptions.css';

interface IDisableOptions extends WithT {
  editMetadata?: boolean;
  editTemplate?: boolean;
  removeTemplate?: boolean;
}

function getHashStep(): number | undefined {
  const matchedHash = /\#step(\d{1})$/.exec(location.hash);
  return matchedHash ? +matchedHash[1] : undefined;
}

type TCampaign = PinpointEmailCampaign | SmartPinpointEmailCampaign | AutoPinpointEmailCampaign;

function TemplateColumnPinpointOptions({
  t,
  editMetadata = true,
  editTemplate = true,
  removeTemplate = true,
}: IDisableOptions) {
  return {
    id: 'options',
    Header: TableColumnHeader({
      title: t('Options'),
    }),
    width: 150,
    sortable: false,
    className: tableStyles.cellCenter,
    Cell({ original }: ITemplateRow): React.ReactElement {
      return (
        <Observer>
          {function useAnonymousFunction(): React.ReactElement {
            const localUrl = useLocalUrl();
            const { campaign, template } = original;
            const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);

            async function handleArchive(): Promise<void> {
              (campaign as TCampaign).removeTemplate(template.id);
              await template.archive();
            }

            const confirmButtonProps = {
              buttonClassName: styles.optionsButton,
              disabled: campaign?.isPending || template.isUpdating,
              buttonType: 'naked' as IConfirmButtonProps['buttonType'],
              element: 'Button' as IConfirmButtonProps['element'],
              model: campaign as TCampaign,
              step: getHashStep(),
            };

            return (
              <>
                <Tooltip title={t('Edit metadata')}>
                  <ConfirmButton
                    {...confirmButtonProps}
                    description={
                      <Trans t={t}>
                        <p>
                          Before you can edit this metadata, the changes to your campaign need to be
                          saved.
                        </p>
                      </Trans>
                    }
                    disabled={editMetadata || template.get('read_only', false)}
                    name={'edit_metadata_option'}
                    prefix={<FontAwesomeIcon icon={faBracketsCurly} />}
                    redirect={localUrl(template.getItemUrl('edit/metadata'))}
                    title={t('Edit metadata')}
                  />
                </Tooltip>
                <Tooltip title={t('Edit template')}>
                  <ConfirmButton
                    {...confirmButtonProps}
                    description={
                      <Trans t={t}>
                        <p>
                          Before you can edit this template, the changes to your campaign need to be
                          saved.
                        </p>
                      </Trans>
                    }
                    disabled={editTemplate || template.get('read_only', false)}
                    name={'edit_template_option'}
                    prefix={<FontAwesomeIcon icon={faEdit} />}
                    redirect={localUrl(template.getItemUrl('edit/design'))}
                  />
                </Tooltip>
                <Tooltip title={t('Remove template')}>
                  <Button
                    className={styles.optionsButton}
                    disabled={removeTemplate || campaign?.isPending}
                    name={'remove_template_option'}
                    onClick={toggleArchiveModalOpen}
                    prefix={<FontAwesomeIcon icon={faTrash} />}
                    type={'naked'}
                  />
                </Tooltip>
                {isArchiveModalOpen && (
                  <ArchiveModalV1
                    model={template}
                    onClose={toggleArchiveModalOpen}
                    onConfirm={handleArchive}
                    t={t}
                  />
                )}
              </>
            );
          }}
        </Observer>
      );
    },
  };
}

export default TemplateColumnPinpointOptions;
