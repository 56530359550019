import { ObjectId } from 'bson';
import { useContext } from 'react';
import { useRouteMatch } from 'react-router';

import type { Account } from '@feathr/blackbox';

import { StoresContext } from '.';

class NoAccountError extends Error {
  public __proto__: Error;
  constructor(message = 'No account context available.') {
    const trueProto = new.target.prototype;
    super(message);
    this.__proto__ = trueProto;
  }

  public toString(): string {
    return `NoAccountError: ${this.message}`;
  }
}

function assertAccount(account: Account | undefined): asserts account {
  if (account === undefined || account.isEphemeral || account.isErrored) {
    throw new NoAccountError();
  }
}

interface IAccountOptions {
  required: boolean;
}

function useAccount(): Account;
function useAccount(options: { required: true }): Account;
function useAccount(options: { required: false }): Account | undefined;
function useAccount(options: IAccountOptions = { required: true }): Account | undefined {
  let account: Account | undefined;
  const { required } = options;
  const { Accounts } = useContext(StoresContext);
  const match = useRouteMatch<{ accountId?: string }>({
    /*
     * Include trailing slash and strict=true to ensure matched path includes
     * accountId and at least one child path element.
     */
    path: '/:accountId',
    sensitive: true,
  });
  const pathAccountId = match && match.params.accountId;
  const pathAccountIdIsValid = pathAccountId && ObjectId.isValid(pathAccountId);

  const accountId: string | undefined =
    pathAccountId && pathAccountIdIsValid ? pathAccountId : undefined;
  if (accountId) {
    if (accountId !== sessionStorage.getItem('accountId')) {
      // If we change account context, set new account context in session storage
      sessionStorage.setItem('accountId', accountId);
    }
    account = Accounts.get(accountId);
  }

  if (required) {
    assertAccount(account);
  }

  return account;
}

export default useAccount;
export { NoAccountError };
