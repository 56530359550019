import React from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { IPerson } from '@feathr/blackbox';
import { AvatarV2 as Avatar } from '@feathr/components';

import { contentWrapper, spaced } from './SelectOptions.css';

function PersonOption<T extends IPerson>(props: OptionProps<T>) {
  const { name, placeholder, email } = props.data as IPerson;
  return (
    <components.Option {...props}>
      <div className={contentWrapper}>
        <Avatar name={name} placeholder={placeholder} />
        <span className={spaced}>{name.trim() || placeholder.name}</span>
        {!!email && <span>&lt;{email}&gt;</span>}
      </div>
    </components.Option>
  );
}

export default PersonOption;
