import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardV2 as Card, List } from '@feathr/components';
import BillableCard from '@feathr/extender/components/BillableCard';
import { StoresContext, useAccount } from '@feathr/extender/state';

import * as styles from './BillableList.css';

function BillableList(): JSX.Element {
  const { Billables } = React.useContext(StoresContext);
  const account = useAccount();
  const { t } = useTranslation();

  const filters = account.primaryBillableId ? { id__ne: account.primaryBillableId } : {};

  const billables = Billables.list({ filters, ordering: ['-id'] });

  return (
    <Card width={'wide'}>
      <Card.Header title={t('Additional Billing Configurations')} />
      <Card.Content>
        <List className={styles.list}>
          {billables.isPending && <List.Item key={'pending'}>...</List.Item>}
          {billables.models.map((billable) => (
            <List.Item key={billable.id}>
              <BillableCard allowDelete={true} billable={billable} />
            </List.Item>
          ))}
        </List>
      </Card.Content>
    </Card>
  );
}

export default observer(BillableList);
