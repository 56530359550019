import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';
import { cssVar } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const ProgressColumn: IColumn<BaseCampaign> = {
  id: 'progress',
  checkboxLabel: 'Progress',
  Header: TableColumnHeader({
    title: 'Progress',
  }),
  sortable: false,
  className: tableStyles.cellCenter,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const { spend = 0, flavors = {} } = original.get('total_stats');
          const adView = flavors.ad_view || 0;
          const { target_value: targetValue = 0, target_type: targetType = 'fixed_budget' } =
            original.get('exposure_settings', {});
          let widthNumber = 0;
          if (targetType === 'fixed_budget') {
            widthNumber = spend > 0 ? Math.ceil((spend / targetValue) * 100) : 0;
          } else if (targetType === 'fixed_impressions') {
            widthNumber = adView > 0 ? Math.ceil((adView / targetValue) * 100) : 0;
          }
          widthNumber = Math.min(widthNumber, 100);
          const width = `${widthNumber}%`;
          return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <div
                style={{
                  flex: '1',
                  width: '100%',
                  height: '32px',
                  backgroundColor: cssVar('--color-disabled'),
                  borderRadius: '4px',
                }}
              >
                <div
                  style={{
                    width,
                    height: '32px',
                    backgroundColor: cssVar('--color-brand-teal'),
                    borderRadius: '4px',
                  }}
                />
              </div>
            </div>
          );
        }}
      </Observer>
    );
  },
};

export default ProgressColumn;
