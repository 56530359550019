import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Nav, NavItem } from '@feathr/components';
import { useGoogleAdsPermissions } from '@feathr/extender/hooks';
import { useAccount, useLocalUrl } from '@feathr/extender/state';

function HomeMarketingNav(): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const { hasMonetization } = useAccount();
  const showGoogleAds = useGoogleAdsPermissions();

  return (
    <Nav indented={true} label={t('Marketing navigation')} type={'vertical'}>
      <NavItem isForwardedFrom={localUrl(`/marketing`)} to={localUrl('/marketing/all')}>
        {t('All')}
      </NavItem>
      <NavItem to={localUrl('/marketing/ads')}>{t('Ads')}</NavItem>
      <NavItem to={localUrl('/marketing/email')}>{t('Email')}</NavItem>
      {showGoogleAds && <NavItem to={localUrl('/marketing/google-ads')}>{t('Google Ads')}</NavItem>}
      {hasMonetization && (
        <NavItem to={localUrl('/marketing/monetization')}>{t('Monetization')}</NavItem>
      )}
      <NavItem to={localUrl('/marketing/other')}>{t('Other')}</NavItem>
    </Nav>
  );
}

export default HomeMarketingNav;
