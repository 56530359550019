import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode, RefObject } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { ITemplate, RecipientType, Template } from '@feathr/blackbox';
import { toast } from '@feathr/components';
import type {
  Bee,
  TDynamicContentResolveHandler,
  TFormResolveHandler,
} from '@feathr/extender/components/BeeEditor';
import BeeEditor, { EBeeEditorAction } from '@feathr/extender/components/BeeEditor';
import { useReactionEffect } from '@feathr/hooks';

import TemplatePreview from '../../TemplatePreview';
import AddDynamicContentDialog from '../AddDynamicContentDialog';
import ManageFormDialog from '../ManageFormDialog';
import SendPreview from '../SendPreview';
import { TemplateEditorState, useBeeEditor } from './config';

import * as styles from './BeeTemplateEditor.css';

export interface IBeeTemplateEditorProps {
  className?: string;
  isReadOnly?: boolean;
  onTemplateSelect?: (template: Template) => void;
  recipientType?: RecipientType;
  setBeePlugin?: (newPlugin: Bee) => void;
  template: Template;
  toolbar?: ReactNode;
  wrapperRef?: RefObject<HTMLDivElement>;
}

function BeeTemplateEditor({
  className,
  isReadOnly = false,
  recipientType,
  setBeePlugin,
  template,
  toolbar,
  wrapperRef,
}: Readonly<IBeeTemplateEditorProps>): JSX.Element {
  const { t } = useTranslation();
  const { config, editorState, dispatchEditorAction } = useBeeEditor();
  const {
    actions: { onSave },
    models: { formCustomFields, mergefieldCustomFields },
  } = config;

  const unsavedContent = localStorage.getItem(template.id);

  useReactionEffect(
    () => unsavedContent !== null,
    () => {
      async function patchUnsavedChanges(): Promise<void> {
        const parsedContent = JSON.parse(unsavedContent!);
        template.set({ content: parsedContent });
        const response = await template.patchDirty();
        if (response.isErrored) {
          toast(t('There was an error updating your unsaved changes.'), {
            type: ToastType.ERROR,
          });
          return;
        }
        toast(t('Your unsaved changes were updated.'), {
          type: ToastType.INFO,
        });
        localStorage.removeItem(template.id);
      }

      patchUnsavedChanges();
    },
    { once: true },
  );

  const templateContent: ITemplate['content'] = template.get('content');
  useEffect(() => {
    if (
      [
        TemplateEditorState.resolvingPreview,
        TemplateEditorState.resolvingSend,
        TemplateEditorState.resolvingSave,
      ].includes(editorState.state)
    ) {
      const content: ITemplate['content'] = { ...templateContent };

      // Only update content.json if it's different from the current content.json
      if (editorState.json && !isEqual(editorState.json, content.json)) {
        content.json = editorState.json;
        template.set({ content });
      }

      // TODO: Can we skip to EBeeEditorAction.ready if content.isDirty is false?
      if (editorState.state === TemplateEditorState.resolvingSave) {
        onSave(editorState.json!);
      } else {
        dispatchEditorAction({ type: EBeeEditorAction.resolve });
      }
    } else if (editorState.state === TemplateEditorState.error) {
      toast(editorState.error?.message ?? 'Unknown error', {
        type: ToastType.ERROR,
      });
    }
  }, [dispatchEditorAction, editorState, onSave, template, templateContent]);

  function handleCloseDialog(): void {
    dispatchEditorAction({ type: EBeeEditorAction.resolve });
  }

  return (
    <BeeEditor
      className={classNames({
        [styles.hidden]: [
          TemplateEditorState.showingPreview,
          TemplateEditorState.showingSend,
          TemplateEditorState.addingDynamicContent,
          TemplateEditorState.managingForm,
        ].includes(editorState.state),
      })}
      config={config}
      isLoading={mergefieldCustomFields.isPending || formCustomFields.isPending}
      isReadOnly={isReadOnly}
      setBeePlugin={setBeePlugin}
      template={template}
      toolbar={toolbar}
      wrapperClassName={className}
      wrapperId={'editorRoot'}
      wrapperRef={wrapperRef}
    >
      {/* Preview state is still needed for TemplateEditor.tsx */}
      {editorState.state === TemplateEditorState.showingPreview && (
        <TemplatePreview
          recipientType={recipientType}
          template={template}
          visible={editorState.state === TemplateEditorState.showingPreview}
        />
      )}
      {editorState.state === TemplateEditorState.showingSend && (
        <SendPreview
          onClose={handleCloseDialog}
          recipientType={recipientType}
          template={template}
        />
      )}
      {editorState.state === TemplateEditorState.addingDynamicContent && (
        <AddDynamicContentDialog
          onClose={handleCloseDialog}
          reject={editorState.reject}
          resolve={editorState.resolve as TDynamicContentResolveHandler}
          template={template}
        />
      )}
      {editorState.state === TemplateEditorState.managingForm && editorState.form && (
        <ManageFormDialog
          form={editorState.form}
          onClose={handleCloseDialog}
          reject={editorState.reject}
          resolve={editorState.resolve as TFormResolveHandler}
        />
      )}
    </BeeEditor>
  );
}

export default observer(BeeTemplateEditor);
