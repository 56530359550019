import isEqual from 'lodash.isequal';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type {
  Event as Project,
  IPermissionSettings,
  Segment as Group,
  TBinaryUserRolePermission,
  TEditableUserRolePermission,
  TPermissionAllowList,
  TPermissionMode,
  UserRole,
} from '@feathr/blackbox';
import type { IRadioOption } from '@feathr/components';
import { CardV2 as Card, Fieldset, Input, Label, Radios, Toggle } from '@feathr/components';
import ModelSelect from '@feathr/extender/components/ModelSelect/ModelSelect';
import { StoresContext } from '@feathr/extender/state';
import { additionalPermissionDescriptionsMap } from '@feathr/extender/styles/user_roles';
import { flattenError } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import type { TAction } from '../EditRolePage';

import * as styles from './EditRoleCard.css';
interface IEditRoleCardProps {
  role: UserRole;
}

type TPermissionSettingsMap = {
  [key in TEditableUserRolePermission]: IPermissionSettings;
};

function validateUserRole(role: UserRole): TValidateGrouped {
  return (
    role.validate(['name', 'projects.allow_list', 'segments.allow_list'], false, 'grouped')
      .errors ?? []
  );
}

function EditRoleCard({ role }: Readonly<IEditRoleCardProps>): JSX.Element {
  const { t } = useTranslation();

  const { Segments: Groups, Events: Projects } = useContext(StoresContext);

  // Get the action from the URL params. If the action is 'create', the role is being created. If the action is 'edit', the role is being edited.
  const { action } = useParams<{ action: TAction }>();
  const isCreatingRole = action === 'create';

  const complexProperties = ['projects', 'segments'];
  const binaryProperties = ['billing', 'imports', 'domains', 'integrations'];
  const properties = [...complexProperties, ...binaryProperties];

  function getPermissions(properties: string[], role: UserRole): TPermissionSettingsMap {
    // Default permission settings
    const permissionModeAll: IPermissionSettings = { mode: 'all', allow_list: [] };
    const permissionModeNone: IPermissionSettings = { mode: 'none', allow_list: [] };

    return properties.reduce((acc, property) => {
      const permissionSetting = binaryProperties.includes(property)
        ? permissionModeNone
        : permissionModeAll;
      // If the role is being created, set the permission settings to the default values. If the role is being edited, set the permission settings to the role's current values.
      acc[property] = role.get(property, { ...permissionSetting });
      return acc;
    }, {} as TPermissionSettingsMap);
  }

  const {
    projects,
    segments: groups,
    billing,
    imports,
    domains,
    integrations,
  } = getPermissions(properties, role);

  const cardTitle = isCreatingRole ? t('Create Role') : t('Edit Role');

  /*
   * If the role is being created:
   * 1. Set the role name to dirty so that the user is prompted to save the role name. This will allow them to create the role with the provided defaults.
   * 2, Set the role's default values for projects, segments, billing, imports, domains, and integrations.
   */
  if (isCreatingRole) {
    role.setAttributeDirty('name');
    role.set({ projects, segments: groups, billing, imports, domains, integrations });
  }

  const projectAccessOptions: Array<IRadioOption<TPermissionMode>> = [
    {
      id: 'all',
      name: t('All projects'),
    },
    {
      id: 'some',
      name: t('Specific projects'),
    },
  ];

  const groupAccessOptions: Array<IRadioOption<TPermissionMode>> = [
    {
      id: 'all',
      name: t('All groups'),
    },
    {
      id: 'some',
      name: t('Specific groups'),
    },
  ];

  function resetClean(
    attribute: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newData: any,
  ): void {
    // If the role is being created, don't set the attribute to clean.
    if (isCreatingRole) {
      return;
    }
    if (isEqual(role.shadowAttributes[attribute], newData)) {
      role.setAttributeClean(attribute);
    }
  }

  // Handlers

  /*
   * Handler to change the role name
   * A handler is used instead of model={role} and attribute={'name'} so that we can set the attribute to clean when the value is reset to the initial value.
   */
  function handleOnChangeRoleName(newValue?: string): void {
    role.set({ name: newValue });
    resetClean('name', newValue);
  }

  /*
   * Handler to change the project access mode
   * newValue expects a string but model expects a TPermissionMode. Therefore, we cast newValue to TPermissionMode when setting it on the model.
   */
  function handleChangeProjectAccess(newValue?: string): void {
    const allowList = newValue === 'all' ? [] : projects.allow_list;
    role.set({ projects: { mode: newValue as TPermissionMode, allow_list: allowList } });
    resetClean('projects', {
      mode: newValue,
      allow_list: allowList,
    });
  }

  /*
   * Handler to change the group access mode
   * newValue expects a string but model expects a TPermissionMode. Therefore, we cast newValue to TPermissionMode when setting it on the model.
   */
  function handleChangeGroupAccess(newValue?: string): void {
    const allowList = newValue === 'all' ? [] : groups.allow_list;
    role.set({ segments: { mode: newValue as TPermissionMode, allow_list: allowList } });
    resetClean('segments', {
      mode: newValue,
      allow_list: allowList,
    });
  }

  // Handler to change the projects allow list
  function handleOnChangeMultiProjects(selectedProjects: TPermissionAllowList): void {
    const allowList = selectedProjects ?? [];
    role.set({ projects: { mode: projects.mode, allow_list: allowList } });
    resetClean('projects', {
      mode: projects.mode,
      allow_list: allowList,
    });
  }

  // Handler to select multiple groups/segments
  function handleOnSelectMultiGroups(selectedGroups: TPermissionAllowList): void {
    const allowList = selectedGroups ?? [];
    role.set({ segments: { mode: groups.mode, allow_list: allowList } });
    resetClean('segments', {
      mode: groups.mode,
      allow_list: allowList,
    });
  }

  // Handler to toggle the additional permissions
  function handleOnChangeToggle(
    attribute: TBinaryUserRolePermission,
  ): (newToggleValue?: boolean) => void {
    return function (newToggleValue?: boolean): void {
      // If the new value is undefined, do nothing.
      if (newToggleValue === undefined) {
        return;
      }
      const mode = newToggleValue ? 'all' : 'none';
      // Set the new value for the attribute. If the new value is true, set the mode to 'all'. If the new value is false, set the mode to 'none'.
      role.set({
        [attribute]: {
          mode: mode,
        },
      });
      resetClean(attribute, { mode: mode, allow_list: [] });
    };
  }

  // Utils
  function convertPermissionModeToBoolean(currentMode: TPermissionMode): boolean {
    if (currentMode === 'all') {
      return true;
    }
    if (currentMode === 'none' || currentMode === 'some') {
      return false;
    }
    return false;
  }

  /*
   * Prevent the user from assigning access directly to a "child segment."
   * If this occurred it would not cause a problem. However, access to "child segments" is controlled by whether or not the role has access to the "parent segment".
   * Therefore, exposing "child segments" on this page will lead to confusion.
   */

  // Validation
  const validationErrors = validateUserRole(role);

  return (
    <Card>
      <Card.Header title={cardTitle} />
      <Card.Content>
        <Fieldset>
          {/* Role name */}
          <Input
            isClearable={true}
            label={t('Role name')}
            onChange={handleOnChangeRoleName}
            validationError={flattenError(validationErrors.name)}
            value={role.get('name')}
          />

          {/* Projects */}
          <Radios
            helpText={t('Select accessible projects for this role.')}
            label={t('Projects')}
            layout={'block'}
            onChange={handleChangeProjectAccess}
            options={projectAccessOptions}
            validationError={flattenError(validationErrors['projects.allow_list'])}
            value={projects.mode}
          />
          {projects.mode === 'some' && (
            <ModelSelect<Project, TPermissionAllowList>
              collection={Projects}
              isMulti={true}
              noOptionsMessage={t('No projects found.')}
              onSelect={handleOnChangeMultiProjects}
              placeholder={t('Select specific projects...')}
              value={projects.allow_list}
            />
          )}

          {/* Groups */}
          <Radios
            helpText={t('Select accessible groups for this role.')}
            label={t('Groups')}
            layout={'block'}
            onChange={handleChangeGroupAccess}
            options={groupAccessOptions}
            validationError={flattenError(validationErrors['segments.allow_list'])}
            value={groups.mode}
          />
          {groups.mode === 'some' && (
            <ModelSelect<Group, TPermissionAllowList>
              collection={Groups}
              filters={{ parent_segment__exists: false }}
              isMulti={true}
              noOptionsMessage={t('No groups found.')}
              onSelect={handleOnSelectMultiGroups}
              placeholder={t('Select specific groups...')}
              value={groups.allow_list}
            />
          )}
        </Fieldset>

        {/* Additional permissions */}
        <Fieldset
          className={styles.wells}
          helpText={t('If enabled, users with this role can edit the following account features.')}
          label={<Label>{t('Additional permissions')}</Label>}
        >
          <Toggle
            helpText={additionalPermissionDescriptionsMap('billing', t)}
            label={t('Allow billing')}
            layout={'well'}
            onChange={handleOnChangeToggle('billing')}
            value={convertPermissionModeToBoolean(billing.mode)}
          />

          <Toggle
            helpText={additionalPermissionDescriptionsMap('imports', t)}
            label={t('Allow data importer')}
            layout={'well'}
            onChange={handleOnChangeToggle('imports')}
            value={convertPermissionModeToBoolean(imports.mode)}
          />

          <Toggle
            helpText={additionalPermissionDescriptionsMap('domains', t)}
            label={t('Allow domains')}
            layout={'well'}
            onChange={handleOnChangeToggle('domains')}
            value={convertPermissionModeToBoolean(domains.mode)}
          />

          <Toggle
            helpText={additionalPermissionDescriptionsMap('integrations', t)}
            label={t('Allow integrations')}
            layout={'well'}
            onChange={handleOnChangeToggle('integrations')}
            value={convertPermissionModeToBoolean(integrations.mode)}
          />
        </Fieldset>
      </Card.Content>
    </Card>
  );
}

export default observer(EditRoleCard);
