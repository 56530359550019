import { supported as mapboxGlIsSupported } from '@mapbox/mapbox-gl-supported';
import classNames from 'classnames';
import { LngLatBounds, Map } from 'mapbox-gl';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import { reportModuleLabels } from '@feathr/blackbox';
import { TableStatsCard } from '@feathr/components';

import * as styles from './HeatmapCard.css';

interface ILocation {
  lat: number;
  lng: number;
  count: number;
}

interface IProps {
  points: ILocation[];
  className?: string;
}

function getPointsBounds(points: ILocation[]): LngLatBounds {
  const intensityMax = getPointsMaxIntensity(points, 3);
  let sw: [number, number] = [Infinity, Infinity];
  let ne: [number, number] = [-Infinity, -Infinity];
  points.forEach((point) => {
    if (point.count < intensityMax) {
      return;
    }
    if (point.lng < sw[0]) {
      sw[0] = point.lng;
    }
    if (point.lat < sw[1]) {
      sw[1] = point.lat;
    }
    if (point.lng > ne[0]) {
      ne[0] = point.lng;
    }
    if (point.lat > ne[1]) {
      ne[1] = point.lat;
    }
  });
  if (sw[0] === Infinity || sw[1] === Infinity) {
    sw = [0, 50];
  }
  if (ne[0] === -Infinity || ne[1] === -Infinity) {
    ne = [0, -20];
  }
  return new LngLatBounds(sw, ne);
}

function getPointsMaxIntensity(points: ILocation[], deviations = 5): number {
  const counts = points.map((point) => point.count);
  const max = Math.max(...counts);
  const mean = counts.reduce((acc, count) => acc + count, 0) / counts.length;
  const sumOfSquaredDifferences = counts.reduce((acc, count) => acc + (count - mean) ** 2, 0);
  const standardDeviation = Math.sqrt(sumOfSquaredDifferences / (counts.length - 1));
  return Math.min(mean + standardDeviation * deviations, max);
}

function HeatmapCard({ points, className }: IProps): JSX.Element {
  const [map, setMap] = useState<Map | undefined>();
  const mapContainer = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const initializeMap = (): void => {
      const intensityMax = getPointsMaxIntensity(points);
      const bounds = getPointsBounds(points);
      const newMap = new Map({
        container: mapContainer.current!,
        style: 'mapbox://styles/alevental/ck1vf3f9j5h7d1cqp1j64zjkx?optimize=true',
        antialias: true,
        interactive: true,
        attributionControl: false,
        doubleClickZoom: true,
        boxZoom: true,
        keyboard: false,
        center: bounds.getCenter(),
        maxZoom: 10,
      });
      setMap(newMap);
      newMap.on('load', () => {
        newMap.addSource('locations', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: points.map((point) => ({
              type: 'Feature',
              properties: { count: point.count },
              geometry: {
                type: 'Point',
                coordinates: [point.lng, point.lat],
              },
            })),
          },
        });
        newMap.addLayer({
          id: 'locations-heatmap',
          type: 'heatmap',
          source: 'locations',
          paint: {
            'heatmap-weight': ['interpolate', ['linear'], ['get', 'count'], 0, 0, intensityMax, 1],
            /*
             * Increase the heatmap color weight by zoom level
             * heatmap-intensity is a multiplier on top of heatmap-weight
             */
            'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
            /*
             * Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
             * Begin color ramp at 0-stop with a 0-transparency color
             * to create a blur-like effect.
             */
            'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba(87,169,227,0)',
              0.25,
              'rgb(87,169,227)',
              0.5,
              'rgb(38,189,179)',
              0.75,
              'rgb(254,230,77)',
              1,
              'rgb(246,146,30)',
            ],
            // Adjust the heatmap radius by zoom level
            'heatmap-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              ['interpolate', ['linear'], ['get', 'count'], 0, 4, intensityMax, 8],
              9,
              ['interpolate', ['linear'], ['get', 'count'], 0, 24, intensityMax, 80],
            ],
            // Adjust the heatmap opacity by zoom level
            'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 0, 0.8, 18, 0.8],
          },
        });
        newMap.fitBounds(bounds, { duration: 300, padding: 80 });
      });
    };

    if (!map && mapContainer.current) {
      initializeMap();
    }
  }, [map, mapContainer.current]);

  return (
    <TableStatsCard contentType={'bordered'} title={reportModuleLabels.includeHeatmap}>
      <div className={styles.mapContainer}>
        {mapboxGlIsSupported() ? (
          <div className={classNames(styles.map, className)} ref={getMapboxRef} />
        ) : (
          <p>There was an error loading the heatmap. Please try again later.</p>
        )}
      </div>
    </TableStatsCard>
  );

  function getMapboxRef(el): HTMLDivElement | null {
    return (mapContainer.current = el);
  }
}

export default observer(HeatmapCard);
