import type { Template, Templates as TemplatesType } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import type { TTemplateGroup } from '@feathr/extender/styles/template';
import type { IObject, ListResponse } from '@feathr/rachis';

interface IListTemplates {
  accountId: string;
  eventId: string;
  group?: TTemplateGroup;
  name?: string;
  templateClass: TemplateClass;
  Templates: TemplatesType;
}

const templateClasses: Record<string, TemplateClass[]> = {
  [TemplateClass.ReferralPage]: [TemplateClass.ReferralPage, TemplateClass.Page],
  [TemplateClass.ReferralEmail]: [TemplateClass.ReferralEmail, TemplateClass.Email],
  [TemplateClass.ReferralBanner]: [TemplateClass.ReferralBanner, TemplateClass.Banner],
  [TemplateClass.LandingPage]: [TemplateClass.LandingPage, TemplateClass.Page],
  [TemplateClass.PinpointEmail]: [TemplateClass.PinpointEmail],
};

export function templatesFilters({
  accountId,
  eventId,
  group,
  name,
  templateClass,
}: Omit<IListTemplates, 'Templates'>): IObject {
  const filters: IObject = {
    __raw__: {
      is_archived: false,
      $or: [],
      _cls: { $in: templateClasses[templateClass] },
    },
  };

  if (!group || group === 'default') {
    filters.__raw__.$or.push({
      default: true,
    });
  }
  if (!group || group === 'account') {
    filters.__raw__.$or.push({
      parent_kind: 'account',
      parent: `ObjectId('${accountId}')`,
      default: { $ne: true },
    });
  }
  if (!group || group === 'project') {
    filters.__raw__.$or.push({
      parent_kind: 'event',
      parent: `ObjectId('${eventId}')`,
      default: { $ne: true },
    });
  }
  if (!group || group === 'campaign') {
    filters.__raw__.$or.push({
      parent_kind: 'campaign',
      event: `ObjectId('${eventId}')`,
      default: { $ne: true },
    });
  }
  if (name) {
    filters.__raw__.name = { $regex: name, $options: 'i' };
  }
  return filters;
}

export function templatesOnly(): string[] {
  return [
    '_cls',
    'date_last_modified',
    'default',
    'height',
    'name',
    'parent_kind',
    'thumbnail_url',
    'width',
  ];
}

export function listTemplates({
  Templates,
  ...filterProps
}: IListTemplates): ListResponse<Template> {
  const filters = templatesFilters(filterProps);

  return Templates.list({
    filters,
    pagination: {
      page: 0,
      page_size: 20,
    },
    ordering: ['-date_last_modified'],
  });
}
