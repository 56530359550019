import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX, Ref } from 'react';
import React, { forwardRef } from 'react';
import type { ActionMeta, ValueType } from 'react-select';
import type ReactSelect from 'react-select';

import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';
import { useUser } from '@feathr/extender/state';
import { moment } from '@feathr/hooks';

import * as styles from './TimezoneSelect.css';

interface ITimezoneSelectProps {
  className?: string;
  isLoading?: boolean;
  placeholder?: string;
  label?: string;
  value?: string;
}

function TimezoneSelect(
  { className, isLoading, placeholder, value, ...additionalProps }: ITimezoneSelectProps,
  ref: Ref<ReactSelect<ISelectOption>>,
): JSX.Element {
  const user = useUser();

  const timezoneOptions = moment.tz
    .names()
    .map((timezone) => ({ id: timezone, name: timezone } as ISelectOption));

  function handleChange(newValue: ValueType<ISelectOption>, action: ActionMeta<ISelectOption>) {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (newValue) {
        user?.set({ timezone: (newValue as ISelectOption).id });
      }
    }
  }

  const selectedOption = value ? timezoneOptions.find((option) => option.id === value) : undefined;

  return (
    <Select<ISelectOption>
      {...additionalProps}
      innerRef={ref}
      isMulti={false}
      onChange={handleChange}
      options={timezoneOptions}
      placeholder={placeholder || 'Select timezone...'}
      value={selectedOption}
      wrapperClassName={classNames(styles.root, className)}
    />
  );
}

export default observer(forwardRef(TimezoneSelect));
