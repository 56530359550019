import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import type { ColumnRenderProps } from 'react-table';

import type { BaseCampaign, Campaign, IStats } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader, Tooltip } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function getSpend(stats: IStats): number {
  return stats.spend || 0;
}

const SpendColumn: IColumn<BaseCampaign> = {
  id: 'stats__spend',
  checkboxLabel: 'Spend',
  Header: TableColumnHeader({
    title: 'Spend',
    sortType: 'numeric',
  }),
  headerClassName: tableStyles.sort,
  className: tableStyles.cellRight,
  Cell({ original }): JSX.Element {
    return original.isFacebook ? (
      <Tooltip
        title={
          'Meta campaign spend is shown here for your reporting convenience only and does not represent an additional charge from Feathr.'
        }
      >
        <Observer>
          {(): JSX.Element => {
            const spend = getSpend(original.get('total_stats'));
            return <>{numeral(spend).format('$0,0.00')}</>;
          }}
        </Observer>
      </Tooltip>
    ) : (
      <Observer>
        {(): JSX.Element => {
          const spend = getSpend(original.get('total_stats'));
          return <>{numeral(spend).format('$0,0.00')}</>;
        }}
      </Observer>
    );
  },
  Footer({ data }: ColumnRenderProps<{ _original: Campaign }>): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const total = data.reduce((subtotal, currentValue) => {
            const spend = getSpend(currentValue._original.get('total_stats'));
            return subtotal + spend;
          }, 0);
          return <>{numeral(total).format('$0,0.00')}</>;
        }}
      </Observer>
    );
  },
};

export default SpendColumn;
