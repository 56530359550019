import { Observer } from 'mobx-react-lite';
import React from 'react';

import { TableColumnHeader } from '@feathr/components';

import type { ITemplateRow } from './TemplatesTable';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const TemplateColumnName = {
  id: 'name',
  Header: TableColumnHeader({
    sortType: 'alpha',
    title: 'Name',
  }),
  headerClassName: tableStyles.sort,
  className: tableStyles.cell,
  Cell({ original }: ITemplateRow) {
    return (
      <Observer>
        {() => {
          const { template } = original;
          return <>{template.name}</>;
        }}
      </Observer>
    );
  },
};

export default TemplateColumnName;
