import classNames from 'classnames';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { IPerson } from '@feathr/blackbox';
import { useLocalUrl } from '@feathr/extender/state';
import { cssVar } from '@feathr/hooks';

import * as styles from './PersonName.css';

interface IProps {
  person: IPerson;
  hasLink?: boolean;
}

interface IBoundaryProps {
  children?: ReactNode;
  name: string;
}

class ErrorBoundary extends React.Component<IBoundaryProps> {
  public static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  public state = { hasError: false };

  public render(): ReactNode {
    const { children, name } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return name;
    }
    return children;
  }
}

function PersonName({ person, hasLink }: Readonly<IProps>): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const isNotAnonymous = person.name && person.name.trim().length !== 0;
  const placeholder = person.placeholder || {
    name: t('Anonymous'),
    color_hex: cssVar('--color-text-body'),
    color_name: t('Gray'),
    bird: t('Pigeon'),
  };
  const name = isNotAnonymous ? person.name! : placeholder.name || t('Anonymous')!;
  return (
    <div className={classNames(styles.root, { [styles.anonymous]: !isNotAnonymous })}>
      <ErrorBoundary name={name}>
        {!hasLink ? (
          <span>{name}</span>
        ) : (
          // TODO: Change person from IPerson to Person so we can use class methods.
          <Link to={localUrl(`/data/people/${person.id}/activity`)}>{name}</Link>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default PersonName;
