import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';

import type { Targeting } from '@feathr/blackbox';
import { Button, Chip } from '@feathr/components';

import * as styles from './LocationTag.css';

interface ILocationTagProps {
  readonly targeting: Targeting;
  readonly onClick: (value: Targeting) => void;
  readonly prefix?: ReactNode;
  readonly suffix?: ReactNode;
}

// TODO: convert this to re-usable TagButton component #2954
function LocationTag({ targeting, onClick, prefix, suffix }: ILocationTagProps): JSX.Element {
  function handleTagClick(): void {
    onClick(targeting);
  }

  return (
    <Button className={styles.root} onClick={handleTagClick} type={'naked'}>
      <Chip className={styles.chipFix} prefix={prefix} suffix={suffix} theme={'tag'}>
        {targeting.get('name')}
      </Chip>
    </Button>
  );
}

export default observer(LocationTag);
