import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { TModalV1ActionEvent } from '@feathr/components';
import { Button } from '@feathr/components';

import InviteUsersModal from './InviteUsersModal';

function InviteUsersButton(): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  function handleOpenModal(): void {
    setIsModalOpen(true);
  }

  function handleCloseModal(event: TModalV1ActionEvent): void {
    setIsModalOpen(false);
    // Prevent event from bubbling up to Popover click listener
    event.stopPropagation();
  }

  return (
    <>
      <Button onClick={handleOpenModal} type={'primary'}>
        {t('Invite users')}
      </Button>
      {isModalOpen && <InviteUsersModal onClose={handleCloseModal} />}
    </>
  );
}

export default InviteUsersButton;
