import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  GoogleAdsSmartCampaign,
  IGoogleAdsBudget,
  IGoogleAdsSuggestedBudgets,
} from '@feathr/blackbox';
import { EGoogleAdsBudgetType } from '@feathr/blackbox';
import {
  CardV2 as Card,
  DailyBudgetCard,
  DatePicker,
  Form,
  NumberInput,
  Spinner,
  toast,
} from '@feathr/components';
import { flattenError, moment, momentToDate, TimeFormat } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import * as styles from './BudgetStep.css';

interface IBudgetStepProps {
  campaign: GoogleAdsSmartCampaign;
}

interface IBudgetCard extends IGoogleAdsBudget {
  type: EGoogleAdsBudgetType;
}

type TDateType = 'start' | 'end';

const LEARN_MORE =
  'https://support.google.com/google-ads/answer/9846714?hl=en&visit_id=638445569620365034-2457033940&rd=1';
// Google Ads is expecting 30.4 https://support.google.com/google-ads/answer/6385083?hl=en
const DAYS_PER_MONTH = parseFloat((365 / 12).toFixed(1));
const BUDGET_TYPES = Object.values(EGoogleAdsBudgetType);

export function validateBudgetStep(campaign: GoogleAdsSmartCampaign): TValidateGrouped {
  return (
    campaign.validate(
      ['exposure_settings.target_cap_daily', 'date_start', 'date_end'],
      false,
      'grouped',
    ).errors ?? []
  );
}

function BudgetStep({ campaign }: IBudgetStepProps): JSX.Element {
  const { t } = useTranslation();
  const budgetInputsRef = useRef<HTMLDivElement>(null);
  const [dailyBudget, setDailyBudget] = useState<number>(0);
  const [monthlyBudget, setMonthlyBudget] = useState<number>(0);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState<boolean>(campaign.isPending);
  const [suggestedBudgets, setSuggestedBudgets] = useState<IBudgetCard[]>([]);

  // Suggest keywords on the first render of the page
  useEffect(() => {
    setIsLoadingSuggestions(true);

    campaign
      .suggest<IGoogleAdsSuggestedBudgets>({
        type: 'budget',
        businessName: campaign.get('business_name'),
        destinationUrl: campaign.get('destination_url'),
      })
      .then((budgets) => {
        const mappedBudgets = BUDGET_TYPES.map((type: EGoogleAdsBudgetType): IBudgetCard => {
          return { ...budgets[type], type };
        });
        setSuggestedBudgets(mappedBudgets);

        // Select the recommended budget if we have no pre-existing input
        handleChangeBudget('daily')(
          campaign.get('exposure_settings')?.target_cap_daily ?? budgets.recommended.daily_amount,
        );
      })
      .catch((error) => {
        toast(t('Something went wrong: {{- error}}', { error }), {
          type: ToastType.ERROR,
        });
      })
      .finally(() => {
        setIsLoadingSuggestions(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeDate(type: TDateType): (date?: string) => void {
    return function (date?: string) {
      campaign.set({ [`date_${type}`]: moment(date).utc().format(TimeFormat.isoDate) });
    };
  }

  function handleClearDate(type: TDateType): () => void {
    return function () {
      campaign.set({ [`date_${type}`]: undefined });
    };
  }

  function handleSelectBudget({ daily_amount: daily }: Omit<IGoogleAdsBudget, 'monthly_amount'>) {
    return () => {
      // Scroll to the inputs so the user can see the values change
      budgetInputsRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      // Change the daily/monthly budget inputs based on the daily val
      handleChangeBudget('daily')(daily);
    };
  }

  /**
   * Handle the state of daily and monthly inputs to keep them up to date
   * when recommended budgets are selected. This should always be called
   * instead of setMonthlyBudget and setDailyBudget.
   */
  function handleChangeBudget(type: 'daily' | 'monthly'): (newBudget?: number) => void {
    return function (newBudget?: number) {
      if (newBudget === undefined) {
        setMonthlyBudget(0);
        setDailyBudget(0);
        return;
      }

      // Default to daily type
      let newDailyBudget = newBudget;
      let newMonthlyBudget = newBudget * DAYS_PER_MONTH;

      if (type === 'monthly') {
        newDailyBudget = newBudget / DAYS_PER_MONTH;
        newMonthlyBudget = newBudget;
      }

      const parsedDailyBudget = parseFloat(newDailyBudget.toFixed(2));

      setMonthlyBudget(parseFloat(newMonthlyBudget.toFixed(2)));
      setDailyBudget(parsedDailyBudget);

      const budgetCap = {
        target_cap_daily: parsedDailyBudget,
      };

      campaign.set({
        exposure_settings: budgetCap,
      });
    };
  }

  const budgetCards = suggestedBudgets.length ? (
    suggestedBudgets.map(
      ({
        daily_amount: daily,
        monthly_amount: monthly,
        min_daily_clicks: min,
        max_daily_clicks: max,
        type,
      }): JSX.Element => {
        // Hide the estimated clicks if we have all zero values
        const description =
          min > 0 && max > 0
            ? t('Get an estimated {{min}}-{{max}} ad clicks each month', {
                min,
                max,
              })
            : undefined;

        return (
          <DailyBudgetCard
            budget={numeral(daily).format('$0,0.00')}
            description={description}
            isRecommended={type === 'recommended'}
            key={type}
            monthlyMax={t('{{monthly}} monthly max', {
              monthly: numeral(monthly).format('$0,00'),
            })}
            onClick={handleSelectBudget({
              daily_amount: daily,
              min_daily_clicks: min,
              max_daily_clicks: max,
            })}
            t={t}
          />
        );
      },
    )
  ) : (
    <p>{t('No suggested budgets available')}</p>
  );

  const today = momentToDate(moment.utc());
  const startDate = campaign.get('date_start')
    ? momentToDate(moment.utc(campaign.get('date_start'), moment.ISO_8601).startOf('day'))
    : undefined;
  const endDate = campaign.get('date_end')
    ? momentToDate(moment.utc(campaign.get('date_end'), moment.ISO_8601).startOf('day'))
    : undefined;
  const endDateMin = campaign.get('date_start')
    ? momentToDate(moment.utc(campaign.get('date_start'), moment.ISO_8601).add(1, 'day'))
    : today;

  const startDateInPast = !!(startDate && startDate < today);

  return (
    <div className={styles.root}>
      <Form
        description={t(
          'Your budget recommendation is based on your keyword themes and location settings. Budget recommendations help you stay competitive with other businesses looking to reach the same customers.',
        )}
        label={t('Budget')}
        title={t('Set a budget to get the results you want')}
      >
        <Card>
          <Card.Header title={t('Date Range')} />
          <Card.Content>
            <div className={styles.dates}>
              <DatePicker
                isClearable={true}
                isFullWidth={true}
                label={t('Start date')}
                minDate={today}
                name={'date_start'}
                onClear={handleClearDate('start')}
                onDateStrChange={handleChangeDate('start')}
                placeholderText={t('Select start date')}
                selected={startDate}
                showCalendarIcon={true}
                validationError={flattenError(validateBudgetStep(campaign)?.date_start)}
              />

              <DatePicker
                isClearable={true}
                isFullWidth={true}
                label={t('End date')}
                minDate={startDateInPast ? today : endDateMin}
                name={'date_end'}
                onClear={handleClearDate('end')}
                onDateStrChange={handleChangeDate('end')}
                placeholderText={t('Select end date')}
                selected={endDate}
                showCalendarIcon={true}
                validationError={flattenError(validateBudgetStep(campaign)?.date_end)}
              />
            </div>
          </Card.Content>
        </Card>
        <Card>
          <Card.Header
            description={t('Select a recommended budget or enter your own.')}
            title={t('Budget')}
          ></Card.Header>
          <Card.Content addVerticalGap={true}>
            {isLoadingSuggestions ? (
              <Spinner />
            ) : (
              <>
                <div>{budgetCards}</div>
                <div className={styles.inputs} ref={budgetInputsRef}>
                  <NumberInput
                    className={styles.input}
                    label={t('Daily average budget')}
                    onChange={handleChangeBudget('daily')}
                    prefix={'$'}
                    validationError={flattenError(
                      validateBudgetStep(campaign)?.['exposure_settings.target_cap_daily'],
                    )}
                    value={dailyBudget}
                    wrapperClassName={styles.inputWrapper}
                  />
                  <NumberInput
                    className={styles.input}
                    label={t('Monthly average budget')}
                    onChange={handleChangeBudget('monthly')}
                    prefix={'$'}
                    value={monthlyBudget}
                    wrapperClassName={styles.inputWrapper}
                  />
                </div>
                <Trans t={t}>
                  <div>
                    You only pay for clicks on your ad. Some days you might spend less than your
                    daily average, and on others you might spend more. But over the month you won't
                    pay more than your monthly max.{' '}
                    <a href={LEARN_MORE} target={'_blank'}>
                      Learn more
                    </a>
                  </div>
                </Trans>
              </>
            )}
          </Card.Content>
        </Card>
      </Form>
    </div>
  );
}

export default observer(BudgetStep);
