import classNames from 'classnames';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import type { TStageType } from '@feathr/blackbox';
import type { Partner, Tags } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Chip, TableColumnHeader, Time } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import OptionsColumn from './OptionsColumn';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './PartnersColumns.css';

import noImg from 'images/no-img.png';

interface IRow {
  original: Partner;
}

interface IStageDetails {
  name: string;
  color: string;
}

const stageDetails: Record<TStageType, IStageDetails> = {
  new: {
    name: 'Unmessaged',
    color: 'default',
  },
  unread: {
    name: 'Unread',
    color: 'yellow',
  },
  received: {
    name: 'Received email',
    color: 'yellow',
  },
  visited: {
    name: 'Visited dashboard',
    color: 'orange',
  },
  shared: {
    name: 'Shared collateral',
    color: 'green',
  },
  leads: {
    name: 'Generating leads',
    color: 'indigo',
  },
  completed: {
    name: 'Generating conversions',
    color: 'teal',
  },
};

function getStateChip(states: Record<TStageType, string[]>, campaignId?: string): JSX.Element {
  // Order the stages from last -> first
  const stages = [
    'completed',
    'leads',
    'shared',
    'visited',
    'received',
    'unread',
    'new',
  ] as TStageType[];

  /*
   * Helper function to check if the partner has an element in the given stage, and optionally a
   * given campaign ID.
   */
  const findIfPartnerInStage = (stage: TStageType, campaignId?: string): boolean => {
    if (campaignId) {
      // If the campaignID is defined we need to check that ID exists in the array.
      return !!states[stage].find((id) => id === campaignId);
    }
    // If the campaignId is not defined we only need to check if an element exists in the array.
    return !!states[stage].length;
  };

  if (findIfPartnerInStage('completed', campaignId) && findIfPartnerInStage('leads', campaignId)) {
    // A partner can exist in the completed and leads stages simultaneously.
    return (
      <div className={styles.chips}>
        <Chip theme={stageDetails.completed.color}>{stageDetails.completed.name}</Chip>
        <Chip theme={stageDetails.leads.color}>{stageDetails.leads.name}</Chip>
      </div>
    );
  }
  for (const stage of stages) {
    /*
     * If a partner does not exist in both the completed and leads stages simultaneously (checked
     * above) then it can only exist in one stage at a time.
     */
    if (findIfPartnerInStage(stage, campaignId)) {
      const { name, color } = stageDetails[stage];
      return <Chip theme={color}>{name}</Chip>;
    }
  }
  return <Chip theme={stageDetails.new.color}>{stageDetails.new.name}</Chip>;
}

export default function PartnersColumns(
  tags: Tags,
  columnIds?: string[],
  campaignId?: string,
): Array<IColumn<Partner>> {
  const columns: Array<IColumn<Partner>> = [
    {
      id: 'logo',
      checkboxLabel: 'Logo',
      Header: TableColumnHeader({
        title: 'Logo',
      }),
      width: 64,
      sortable: false,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        const logo = original.get('logo', noImg);
        return <div className={styles.logo} style={{ backgroundImage: `url('${logo}')` }} />;
      },
    },
    {
      id: 'name',
      checkboxLabel: 'Name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: 'Name',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const localUrl = useLocalUrl();

              return <Link to={localUrl(original.getItemUrl())}>{original.get('name')}</Link>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'is_active',
      checkboxLabel: 'Active',
      Header: TableColumnHeader({
        title: 'Active',
      }),
      sortable: false,
      Cell({ original }): JSX.Element {
        const isActive = original.get('is_active', false);
        const numCrumbs = original.get('stats').num_crumbs;
        return (
          <Chip theme={isActive ? 'lime' : 'default'} tooltip={`${numCrumbs} tracked breadcrumbs`}>
            {isActive ? 'Active' : 'Inactive'}
          </Chip>
        );
      },
    },
    {
      id: 'activity',
      checkboxLabel: 'Stage',
      Header: TableColumnHeader({
        title: 'Stage',
      }),
      width: 130,
      sortable: false,
      Cell({ original }): JSX.Element {
        const campaignStates = original.get('campaign_states', {
          completed: [],
          leads: [],
          new: [],
          received: [],
          shared: [],
          unread: [],
          visited: [],
        });
        return getStateChip(campaignStates, campaignId);
      },
    },
    {
      id: 'email',
      checkboxLabel: 'Primary email',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: 'Primary email',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        return <>{original.get('email', '-')}</>;
      },
    },
    {
      id: 'stats__flavors__partner_message_send',
      checkboxLabel: 'Messages Received',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Messages Received',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      width: 120,
      Cell({ original }): JSX.Element {
        const stats = original.get('stats');
        const msgs = stats.flavors.partner_message_send;
        return <>{msgs || 0}</>;
      },
    },
    {
      id: 'stats__flavors__partner_message_view',
      checkboxLabel: 'Message Opens',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Message Opens',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      width: 120,
      Cell({ original }): JSX.Element {
        const stats = original.get('stats');
        const msgs = stats.flavors.partner_message_view;
        return <>{msgs || 0}</>;
      },
    },
    {
      id: 'stats__flavors__dashboard_view',
      checkboxLabel: 'Visits',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Visits',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      width: 70,
      Cell({ original }): JSX.Element {
        const stats = original.get('stats');
        const { dashboard_view: dashboardView = 0 } = stats.flavors;
        return <>{dashboardView.toLocaleString()}</>;
      },
    },
    {
      id: 'stats__num_views',
      checkboxLabel: 'Views',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Views',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      width: 70,
      Cell({ original }): JSX.Element {
        const stats = original.get('stats');
        return <>{stats.num_views || 0}</>;
      },
    },
    {
      id: 'stats__num_clicks',
      checkboxLabel: 'Clicks',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Clicks',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      width: 70,
      Cell({ original }): JSX.Element {
        const stats = original.get('stats');
        return <>{stats.num_clicks || 0}</>;
      },
    },
    {
      id: 'stats__num_conversions',
      checkboxLabel: 'Conversions',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Conversions',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      width: 120,
      Cell({ original }): JSX.Element {
        const stats = original.get('stats');
        const { num_conversions: numConversions = 0 } = stats;
        return <>{numConversions}</>;
      },
    },
    {
      id: 'tag_ids',
      checkboxLabel: 'Tags',
      Header: TableColumnHeader({
        title: 'Tags',
      }),
      sortable: false,
      className: classNames(tableStyles.cell, styles.tags),
      Cell({ original }: IRow): JSX.Element {
        const tagIds = original.get('tag_ids', []);
        return (
          <>
            {tagIds.map((tagId: string) => {
              const tag = tags.get(tagId);
              if (tag.get('context') === 'partner') {
                return (
                  <span className={styles.tag} key={tagId}>
                    {tag.get('name')}
                  </span>
                );
              }
              return null;
            })}
          </>
        );
      },
    },
    {
      id: 'stats__date_first_access',
      checkboxLabel: 'First Access',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'First Access',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      width: 155,
      Cell({ original }: IRow): JSX.Element {
        const stats = original.get('stats');
        const { date_first_access: dateFirstAccess } = stats;
        return dateFirstAccess ? (
          <Time format={TimeFormat.intlDateTime} timestamp={dateFirstAccess} />
        ) : (
          <>-</>
        );
      },
    },
    {
      id: 'stats__date_last_access',
      checkboxLabel: 'Last Access',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Last Access',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      width: 155,
      Cell({ original }: IRow): JSX.Element {
        const stats = original.get('stats');
        const { date_last_access: dateLastAccess } = stats;
        return dateLastAccess ? (
          <Time format={TimeFormat.intlDateTime} timestamp={dateLastAccess} />
        ) : (
          <>-</>
        );
      },
    },
    OptionsColumn(),
  ];
  return columnIds ? columns.filter((column) => columnIds.includes(column.id!)) : columns;
}
