import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { PinpointPartnerMessage } from '@feathr/blackbox';
import { Button, DatePicker, Fieldset, Form } from '@feathr/components';
import { moment, TimeFormat, timezoneAbbr } from '@feathr/hooks';
import { momentToDate } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import ButtonNext from './ButtonNext';

import * as styles from './EventPartnersMessageStepSix.css';

interface IProps {
  disabled?: boolean;
  message: PinpointPartnerMessage;
  onNext: () => void;
  onPrevious: () => void;
}

export function validateStepSix(message: PinpointPartnerMessage): TValidateGrouped {
  return message.validate(['date_send_start'], false, 'grouped').errors;
}

function EventPartnersMessageStepSix({
  disabled = false,
  message,
  onNext,
  onPrevious,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const sendStartTimestamp = message.get('date_send_start');
  const sendStartMoment = moment.utc(sendStartTimestamp).local();
  const minStartTimeMoment = moment.utc(sendStartTimestamp).startOf('day').isAfter(moment())
    ? moment().startOf('day')
    : moment();

  const sendMoment = sendStartTimestamp
    ? moment.utc(sendStartTimestamp)
    : moment.utc().add(1, 'day').startOf('day');
  const sendDate = momentToDate(sendMoment.local());

  return (
    <Form
      actions={[
        <Button key={'previous'} name={'previous_step'} onClick={onPrevious}>
          {t('Previous')}
        </Button>,
        <ButtonNext
          key={'next'}
          message={message}
          name={'next-step'}
          onNext={onNext}
          validate={validateStepSix}
        />,
      ]}
      description={t('Select the date and time you wish the message to be sent.')}
      label={t('Edit Message: Schedule')}
    >
      <Fieldset>
        <DatePicker
          dateFormat={'MMMM d, yyyy h:mm aa'}
          disabled={disabled}
          helpPlacement={'bottom'}
          helpText={sendMoment.utc().format(TimeFormat.pickerDateTimeZone)}
          maxTime={sendStartMoment.local().endOf('day').toDate()}
          minDate={new Date()}
          minTime={minStartTimeMoment.toDate()}
          name={'date_send_start'}
          onDateStrChange={(dateStr) => {
            message.set({ date_send_start: dateStr });
          }}
          selected={sendDate}
          showTimeSelect={true}
          suffix={timezoneAbbr(sendDate)}
          timeIntervals={15}
          wrapperClassName={styles.datepicker}
        />
      </Fieldset>
    </Form>
  );
}

export default observer(EventPartnersMessageStepSix);
