import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { CampaignState as CampaignStateType } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { Select } from '@feathr/components';
import { campaignStateLabelMap } from '@feathr/extender/styles/campaign';

interface IStateOption {
  id: CampaignStateType;
  name: string;
}

interface IProps {
  onChange: (value?: CampaignStateType[]) => void;
  value?: CampaignStateType[];
}

function CampaignStateSelect({ onChange, value }: IProps): JSX.Element {
  const { t } = useTranslation();

  function handleSelectState(options: IStateOption[]): void {
    onChange(options.map(({ id }) => id));
  }

  const options = (function (): IStateOption[] {
    const states = Object.values(CampaignState);
    return states.map((state) => ({
      id: state,
      name: campaignStateLabelMap(t, state) as string,
    }));
  })();

  return (
    <Select
      id={'stateSelect'}
      isMulti={true}
      label={t('Status')}
      name={'campaign-state-select'}
      onSelectMulti={handleSelectState}
      options={options}
      placeholder={t('All statuses')}
      value={value ? options.filter(({ id }) => value.includes(id)) : []}
    />
  );
}

export default CampaignStateSelect;
