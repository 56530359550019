import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Account, TAttributionModel } from '@feathr/blackbox';
import { CardV2 as Card, Form } from '@feathr/components';
import { AttributionModel } from '@feathr/report_components';

interface IProps {
  account: Account;
}

function AttributionOptions({ account }: IProps): JSX.Element {
  const { t } = useTranslation();
  const isLoading = account.isPending;

  function setAttributionModel(attributionModel: TAttributionModel): void {
    account.set({ attribution_model: attributionModel });
  }

  return (
    <Card>
      <Card.Header
        description={
          <Trans t={t}>
            Here you can set your default attribution model. The model you choose here will be set
            as the default for future campaigns and flights. Changing this selection will not
            override the current attribution model set on campaigns and flights.
          </Trans>
        }
        title={t('Default Attribution Model')}
      />
      <Card.Content>
        <Form label={t('Default attribution model')}>
          <AttributionModel
            isLoading={isLoading}
            onChange={setAttributionModel}
            value={account.get('attribution_model')}
          />
        </Form>
      </Card.Content>
    </Card>
  );
}

export default observer(AttributionOptions);
