import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ModalV1, Tooltip } from '@feathr/components';
import BreadcrumbDetails from '@feathr/extender/components/BreadcrumbDetails';
import type { IBreadcrumbDetailsProps } from '@feathr/extender/components/BreadcrumbDetails/BreadcrumbDetails';
import { useToggle } from '@feathr/hooks';

function BreadcrumbDetailsButton({ breadcrumb, hasViewRawData, context }: IBreadcrumbDetailsProps) {
  const [isModalVisible, toggleModalVisible] = useToggle(false);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('View details')}>
        <Button onClick={toggleModalVisible} type={'naked'}>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </Tooltip>
      {isModalVisible && (
        <ModalV1
          cancelButtonText={t('Close')}
          controlled={true}
          onClose={toggleModalVisible}
          size={'sm'}
          t={t}
          title={t('Activity Details')}
        >
          <BreadcrumbDetails
            breadcrumb={breadcrumb}
            context={context}
            hasViewRawData={hasViewRawData}
          />
        </ModalV1>
      )}
    </>
  );
}

export default observer(BreadcrumbDetailsButton);
