import type { IObservableArray } from 'mobx';
import React from 'react';
import { Bar, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import type { DailyStat } from '@feathr/blackbox';
import { StatsCardV1 } from '@feathr/components';
import { cssVar, moment, TimeFormat } from '@feathr/hooks';

type TDataKey = 'num_users_total' | 'num_users_active' | 'num_cookies_total' | 'num_emails_total';

interface IProps {
  label: string;
  stats: IObservableArray<DailyStat>;
  charts?: TDataKey[];
}

export default function SegmentGraph({
  charts = ['num_users_active', 'num_users_total'],
  label,
  stats,
}: IProps) {
  const chartData = stats.map((stat) => ({
    date: moment.utc(stat.get('metadata').date, moment.ISO_8601).format(TimeFormat.isoDate),
    num_users_total: stat.get('num_users_total', { daily: 0 }).daily,
    num_users_active: stat.get('num_users_active', { daily: 0 }).daily,
    num_emails_total: stat.get('num_emails_total', { daily: 0 }).daily,
    num_cookies_total: stat.get('num_cookies_total', { daily: 0 }).daily,
  }));
  return (
    <StatsCardV1 contentType={'bordered'} title={label}>
      <ResponsiveContainer height={300} width={'100%'}>
        <ComposedChart barSize={4} data={chartData}>
          <XAxis dataKey={'date'} interval={'preserveStartEnd'} minTickGap={100} />
          <YAxis axisLine={false} tick={false} yAxisId={'left'} />
          {charts.includes('num_users_active') && (
            <YAxis axisLine={false} orientation={'right'} tick={false} yAxisId={'right'} />
          )}
          <Tooltip />
          {charts.includes('num_users_active') && (
            <Bar
              dataKey={'num_users_active'}
              fill={cssVar('--color-brand-orange')}
              stroke={cssVar('--color-brand-orange')}
              yAxisId={'right'}
            />
          )}
          {charts.includes('num_users_total') && (
            <Line
              dataKey={'num_users_total'}
              dot={false}
              fill={cssVar('--color-primary-3')}
              stroke={cssVar('--color-primary-3')}
              type={'monotone'}
              yAxisId={'left'}
            />
          )}
          {charts.includes('num_cookies_total') && (
            <Line
              dataKey={'num_cookies_total'}
              dot={false}
              fill={cssVar('--color-brand-yellow')}
              stroke={cssVar('--color-brand-yellow')}
              type={'monotone'}
              yAxisId={'left'}
            />
          )}
          {charts.includes('num_emails_total') && (
            <Line
              dataKey={'num_emails_total'}
              dot={false}
              fill={cssVar('--color-brand-teal')}
              stroke={cssVar('--color-brand-teal')}
              type={'monotone'}
              yAxisId={'left'}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </StatsCardV1>
  );
}
