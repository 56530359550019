import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';

import type { ImisIntegration } from '@feathr/blackbox';
import { EIntegrationConnectionState } from '@feathr/blackbox';
import { Toolbar } from '@feathr/components';
import ImisLastSync from '@feathr/extender/App/Settings/Account/ImisIntegrationPage/ImisLastSync/ImisLastSync';

import ImisConfiguration from './ImisConfigWizard';
import ImisConnectedCard from './ImisConnectedCard';
import ImisDownSyncStatus from './ImisDownSyncStatus/ImisDownSyncStatus';
import ImisGettingStartedCard from './ImisGettingStartedCard';
import ImisIntegrationStatus from './ImisIntegrationStatus/ImisIntegrationStatus';

interface IProps {
  integration?: ImisIntegration;
}

function ImisIntegrationCard({ integration }: IProps): JSX.Element {
  // The starting state here is that it is not the first time the integration is being configured
  const [isBeingConfigured, setIsBeingConfigured] = useState<boolean>(!isFirstTimeConfig());
  const { notConnected, connected, paused, failed } = EIntegrationConnectionState;
  const integrationState = integration?.integrationState ?? notConnected;
  const isGettingStarted = !integration && integrationState === notConnected;
  const isDisconnected = integration?.isDisconnected;

  function isFirstTimeConfig(): boolean {
    if (!integration) {
      return false;
    }
    // If we have an integration, but it's not connected, or it is failed, we're configuring the integration.
    return [notConnected, failed].includes(integrationState);
  }

  return (
    <>
      <Toolbar align={'left'}>
        <ImisIntegrationStatus integration={integration} />
        <ImisDownSyncStatus integration={integration} />
        <ImisLastSync integration={integration} />
      </Toolbar>

      {/* If there is no integration and the integration state is not connected, render the ImisGettingStartedCard */}
      {isGettingStarted && <ImisGettingStartedCard setIsBeingConfigured={setIsBeingConfigured} />}

      {/* If there is an integration and the integration has been disconnected and it is not being configured, render the ImisGettingStartedCard with the previous integration and the setIsBeingConfigured */}
      {integration && isDisconnected && !isBeingConfigured && (
        <ImisGettingStartedCard
          integration={integration}
          setIsBeingConfigured={setIsBeingConfigured}
        />
      )}

      {/* If there is an integration, and it is being configured, and it is disconnected, or it is the first time being configured, render the ImisConfiguration with the integration */}
      {integration && ((isBeingConfigured && isDisconnected) || isFirstTimeConfig()) && (
        <ImisConfiguration integration={integration} />
      )}

      {/* If there is an integration, and it is connected, render the ImisConnectedCard */}
      {integration && [connected, paused].includes(integrationState) && (
        <ImisConnectedCard integration={integration} />
      )}
    </>
  );
}

export default observer(ImisIntegrationCard);
