import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IBlackbaudContactMapping } from '@feathr/blackbox';
import { Time } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

interface IDateLastSyncedProps {
  className?: string;
  /** The last time that this contact mapping was synced to our database. This prop is required but the value from the database could be undefined if there is no record of a last sync. */
  dateLastSynced: IBlackbaudContactMapping['date_last_synced'] | undefined;
  /** Whether to show the "Never updated" text if the dateLastSynced is null. */
  showNeverSynced?: boolean;
  /** Whether to show the "Last synced" text. */
  showLastSyncedPrefix?: boolean;
}

function RaisersEdgeDateLastSynced({
  className,
  dateLastSynced,
  showNeverSynced = false,
  showLastSyncedPrefix = false,
}: IDateLastSyncedProps): JSX.Element {
  const { t } = useTranslation();

  function getDateLastSyncedMessage(): ReactNode {
    if (showNeverSynced && !dateLastSynced) {
      return t('Never synced');
    }

    if (dateLastSynced) {
      const timeComponent: JSX.Element = (
        <Time format={TimeFormat.timeFromNow} timestamp={dateLastSynced} />
      );

      if (!showLastSyncedPrefix) {
        return timeComponent;
      }

      return (
        <>
          {t('Last synced')} {timeComponent}
        </>
      );
    }
    return null;
  }

  return <span className={className}>{getDateLastSyncedMessage()}</span>;
}

export default RaisersEdgeDateLastSynced;
