import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Option } from 'react-select/src/filters';

import { TemplateClass, templateTypeLabel } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';

import TemplateTypeSelectLabel from './TemplateTypeSelectLabel';

export interface ITemplateTypeSelectOption extends ISelectOption {
  id: TemplateClass;
}

interface IProps {
  className?: string;
  onChange: (value?: TemplateClass) => void;
  value?: TemplateClass;
  wrapperClassName?: string;
}

function TemplateTypeSelect({ className, onChange, value, wrapperClassName }: IProps): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();

  function filterOption({ label }: Option, inputValue: string): boolean {
    return label.toLowerCase().includes(inputValue.toLowerCase());
  }

  function onClearType(): void {
    onChange(undefined);
  }

  function onSelectType({ id }): void {
    onChange(id);
  }

  const typeOptions: ISelectOption[] = Object.keys(TemplateClass)
    // Filter out account-level template options.
    .filter((key) => !['Email', 'Page', 'Link', 'Banner'].includes(key as TemplateClass))
    .filter((key) => {
      if (account && !account.isFinchLegacy) {
        return !(key.includes('Referral') || key.includes('Partner'));
      }
      return true;
    })
    .map<ISelectOption>((key) => {
      const value: TemplateClass = TemplateClass[key];
      return {
        id: value,
        name: templateTypeLabel(value),
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Select
      className={className}
      filterOption={filterOption}
      formatOptionLabel={TemplateTypeSelectLabel}
      isClearable={true}
      onClear={onClearType}
      onSelectSingle={onSelectType}
      options={typeOptions}
      placeholder={t('Type')}
      value={typeOptions.find((option) => {
        return option.id === value;
      })}
      wrapperClassName={wrapperClassName}
    />
  );
}

export default TemplateTypeSelect;
