import capitalize from 'lodash.capitalize';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Importer, TImporterState } from '@feathr/blackbox';
import { Chip } from '@feathr/components';

interface IProps {
  importer: Importer;
}

export const importerStateColors: Record<TImporterState, string> = {
  ['draft']: 'default',
  ['pending']: 'yellow',
  ['complete']: 'green',
  ['failed']: 'red',
};

function ImporterStateChip({ importer }: IProps): JSX.Element {
  const state = importer.get('state');
  return <Chip theme={importerStateColors[state]}>{capitalize(state)}</Chip>;
}

export default observer(ImporterStateChip);
