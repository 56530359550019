import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { TModalV1ActionEvent } from '@feathr/components';
import { Button } from '@feathr/components';

import BulkAssignModal from './BulkAssignModal';

interface IBulkAssignButtonProps {
  selected: string[];
  onClearSelection: () => void;
}

function BulkAssignButton({
  onClearSelection,
  selected,
}: Readonly<IBulkAssignButtonProps>): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  function handleOpenModal(): void {
    setIsModalOpen(true);
  }

  function handleCloseModal(event: TModalV1ActionEvent): void {
    setIsModalOpen(false);
    // Prevent event from bubbling up to Popover click listener
    event.stopPropagation();
  }

  return (
    <>
      <Button onClick={handleOpenModal}>{t('Bulk Assign')}</Button>
      {isModalOpen && (
        <BulkAssignModal
          onClearSelection={onClearSelection}
          onClose={handleCloseModal}
          selected={selected}
        />
      )}
    </>
  );
}

export default BulkAssignButton;
