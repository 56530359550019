import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type {
  Campaign,
  Targetable,
  Targetables as TargetablesCollection,
  Targeting,
} from '@feathr/blackbox';
import { Button, ButtonValid, Form } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import GeoFilter from './GeoFilter';

interface IProps {
  onNext: () => void;
  onPrev: () => void;
  campaign: Campaign;
  targetings: IObservableArray<Targeting>;
}

export function validateStepThree(targetings: IObservableArray<Targeting>): string[] {
  const geofilters = targetings.filter(
    (t) => t.get('kind') === 'geo' && t.get('is_archived') !== true,
  );
  if (geofilters.length === 0) {
    return [];
  }
  if (geofilters.filter((t) => t.get('included')).length < 1) {
    return ['At least one Filter must be "included" rather than "excluded".'];
  }
  return targetings
    .map((t) => {
      const errors = t.validate(['target_data', 'kind'], false).errors;
      if (errors.length) {
        return errors[0];
      }
      return undefined;
    })
    .filter((msg) => !!msg) as string[];
}

const NextStepButton = observer(({ targetings, onNext }: Omit<IProps, 'campaign' | 'onPrev'>) => {
  const validationErrors = validateStepThree(targetings);
  return (
    <ButtonValid errors={validationErrors} onClick={onNext}>
      Next
    </ButtonValid>
  );
});

export function getGeoFilters(
  targetings: IObservableArray<Targeting>,
  Targetables: TargetablesCollection,
): Targetable[] {
  return targetings
    .filter((t) => t.get('kind') === 'geo' && !!t.get('target_data') && !t.get('is_archived'))
    .map((t) => Targetables.get(t.get('target_data')!))
    .filter((f) => f.isEphemeral);
}

function CampaignEditStepThree({ campaign, targetings, onNext, onPrev }: IProps): JSX.Element {
  const { Targetings } = React.useContext(StoresContext);

  function removeTargeting(targeting: Targeting): void {
    if (!targeting.isEphemeral) {
      targeting.set({ is_archived: true });
    } else {
      targetings.remove(targeting);
      Targetings.remove(targeting.id);
    }
  }

  function handleAddFilter(): void {
    const model = Targetings.create({
      parent: campaign.get('id'),
      kind: 'geo',
      included: true,
    });
    runInAction(() => {
      targetings.push(model);
    });
  }

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          Previous
        </Button>,
        <Button
          id={'addFilter'}
          key={'add'}
          onClick={handleAddFilter}
          type={targetings.length >= 1 ? 'secondary' : 'primary'}
        >
          Add filter
        </Button>,
        <NextStepButton key={'next'} onNext={onNext} targetings={targetings} />,
      ]}
      description={
        <>
          <p>
            You can filter your Targets geographically. Click the Add Filter button below to add a
            Filter.
          </p>
          <p>Each Filter represents a country, region or city and can be included or excluded.</p>
          <p>
            If you add at least one included Filter, Feathr will only bid to show ads to people who
            are located in those included Filters.
          </p>
          <p>Feathr will not bid to show ads to people who are located in any excluded Filters.</p>
          <p>
            You may skip this step if you want, but it is recommended to include some geographic
            filtering for most campaigns, especially any that use third-party data (such as
            Lookalike, Email Mapping, Affinity, or Search Keyword campaigns).
          </p>
        </>
      }
      label={'Edit Campaign: Filters'}
    >
      {targetings
        .filter((t) => !t.get('is_archived') && t.get('kind') === 'geo')
        .map((targeting, index) => {
          return (
            <GeoFilter
              campaign={campaign}
              key={targeting.id || index}
              onRemove={removeTargeting}
              targeting={targeting}
              targetings={targetings}
            />
          );
        })}
    </Form>
  );
}

export default observer(CampaignEditStepThree);
