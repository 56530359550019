import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import type { ColumnRenderProps } from 'react-table';

import type { BaseCampaign, Campaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function weightedAvg(
  { rateSum, openSum },
  currentValue: { _original: Campaign },
): { rateSum: number; openSum: number } {
  const stats = currentValue._original.get('total_stats') || {};
  const rate = stats.email_click_through_rate || 0;
  const flavors = stats.flavors || {};
  const opens = flavors.pinpoint_tracked_email_open || 0;
  return {
    rateSum: rateSum + rate * opens,
    openSum: openSum + opens,
  };
}

const EmailCTRColumn: IColumn<BaseCampaign> = {
  id: 'stats__email_click_through_rate',
  checkboxLabel: 'Email CTR',
  Header: TableColumnHeader({
    title: 'Email CTR',
    sortType: 'numeric',
  }),
  headerClassName: tableStyles.sort,
  width: 115,
  className: tableStyles.cellRight,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const stats = original.get('total_stats') || {};
          const rate = stats.email_click_through_rate || 0;
          return <>{numeral(rate).format('0%')}</>;
        }}
      </Observer>
    );
  },
  Footer({ data }: ColumnRenderProps<{ _original: Campaign }>): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const { rateSum, openSum } = data.reduce(weightedAvg, { rateSum: 0, openSum: 0 });
          const avg = openSum > 0 ? rateSum / openSum : 0;
          return <>{numeral(avg).format('0.00%')}</>;
        }}
      </Observer>
    );
  },
};

export default EmailCTRColumn;
