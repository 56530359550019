import classNames from 'classnames';
import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import type { RowRenderProps } from 'react-table';

import type { Segment } from '@feathr/blackbox';
import type {
  Goals as GoalsCollection,
  Tags as TagsCollection,
  Targetings as TargetingsCollection,
} from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Button, Chip, TableColumnHeader, Tooltip } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { cssVar } from '@feathr/hooks';

import DataSegmentOptions from './DataSegmentOptions';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './DataSegmentsColumns.css';

interface IRow extends RowRenderProps {
  original: Segment;
}

interface IColumnsProps {
  Tags: TagsCollection;
  Targetings: TargetingsCollection;
  Goals: GoalsCollection;
  columnIds?: string[];
}

function OptionsCell({ original }: IRow): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { Segments, Goals } = useContext(StoresContext);
        const model = Segments.get(original.id!);

        const numCampaignGoals = Goals.count({
          filters: {
            segment: original.id,
            kind: 'campaign',
            is_archived__ne: true,
          },
        });

        const numFlightGoals = Goals.count({
          filters: {
            segment: original.id,
            kind: 'flight',
            is_archived__ne: true,
          },
        });

        const showRecalculationOption = !!(numCampaignGoals + numFlightGoals);

        return (
          <DataSegmentOptions model={model} showRecalculationOption={showRecalculationOption} />
        );
      }}
    </Observer>
  );
}

function dataSegmentsPageColumns({
  Goals,
  Tags,
  Targetings,
  columnIds,
}: IColumnsProps): Array<IColumn<Segment>> {
  const columns: Array<IColumn<Segment>> = [
    {
      id: 'name',
      checkboxLabel: 'Name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: 'Name',
      }),
      headerClassName: tableStyles.sort,
      width: 180,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const localUrl = useLocalUrl();
              return (
                <>
                  <Link to={localUrl(`/data/segments/${original.id}`)}>
                    {original.get('name', '(Empty)')}
                  </Link>
                  {!!original.get('read_only', true) && (
                    <Chip className={styles.readonly}>Read-only</Chip>
                  )}
                </>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'stats__num_cookies_total',
      checkboxLabel: 'Reachable People',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Reachable People',
        tooltip: 'The number of people who can be targeted with Feathr Ads.',
      }),
      headerClassName: classNames(tableStyles.sort, tableStyles.tooltip),
      className: tableStyles.cellRight,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const stats = original.get('stats', { num_cookies_total: 0 });
              return <>{stats.num_cookies_total || 0}</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'reachability',
      checkboxLabel: 'Reachability',
      Header: TableColumnHeader({
        title: 'Reachability',
        tooltip: 'The proportion of Reachable People out of the tracked audience.',
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const stats = original.get('stats') || {
                num_users_total: 0,
                num_cookies_total: 0,
              };
              const total = stats.num_users_total || 0;
              const cookies = stats.num_cookies_total || 0;
              let widthNumber = cookies > 0 && total > 0 ? Math.ceil((cookies / total) * 100) : 0;
              widthNumber = Math.min(widthNumber, 100);
              const width = `${widthNumber}%`;
              return (
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <div
                    style={{
                      flex: '1',
                      width: '100%',
                      height: '32px',
                      backgroundColor: cssVar('--color-disabled'),
                      borderRadius: '4px',
                    }}
                  >
                    <div
                      style={{
                        width,
                        height: '32px',
                        backgroundColor: cssVar('--color-brand-yellow'),
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                </div>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'stats__num_emails_total',
      checkboxLabel: 'Unique Email Addresses',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Unique Email Addresses',
        tooltip: 'Feathr is able to send email marketing to these people in your group.',
      }),
      headerClassName: classNames(tableStyles.sort, tableStyles.tooltip),
      className: tableStyles.cellRight,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const stats = original.get('stats', { num_emails_total: 0 });
              return <>{stats.num_emails_total || 0}</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'email_coverage',
      checkboxLabel: 'Email Address Coverage',
      Header: TableColumnHeader({
        title: 'Email Address Coverage',
        tooltip: 'The number of unique email addresses in your group.',
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const stats = original.get('stats') || {
                num_users_total: 0,
                num_emails_total: 0,
              };
              const total = stats.num_users_total || 0;
              const emails = stats.num_emails_total || 0;
              let widthNumber = emails > 0 && total > 0 ? Math.ceil((emails / total) * 200) : 0;
              widthNumber = Math.min(widthNumber, 100);
              const width = `${widthNumber}%`;
              return (
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <div
                    style={{
                      flex: '1',
                      width: '100%',
                      height: '32px',
                      backgroundColor: cssVar('--color-disabled'),
                      borderRadius: '4px',
                    }}
                  >
                    <div
                      style={{
                        width,
                        height: '32px',
                        backgroundColor: cssVar('--color-brand-teal'),
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                </div>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'tag_ids',
      checkboxLabel: 'Tags',
      Header: TableColumnHeader({
        title: 'Tags',
        tooltip: 'Give your group unique identifiers to make them easier to sort.',
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      className: classNames(tableStyles.cell, styles.tags),
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return (
                <>
                  {original.get('tag_ids', []).map((tagId: string) => {
                    const tag = Tags.get(tagId);
                    return (
                      <Chip key={tagId} theme={'tag'}>
                        {tag.get('name')}
                      </Chip>
                    );
                  })}
                </>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'campaign_targets',
      checkboxLabel: 'Targets',
      Header: TableColumnHeader({
        title: 'Targets',
        tooltip: 'The number of retargeting campaign targets that use this group.',
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      expander: true,
      className: classNames(tableStyles.cellRight),
      width: 100,
      Expander({ isExpanded, original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return (
                <Tooltip title={isExpanded ? 'Click to hide usage' : 'Click to show usage'}>
                  <Button type={'link'}>
                    {numeral(
                      Targetings.count({
                        filters: {
                          _target_data: original.id,
                          is_archived__ne: true,
                        },
                      }),
                    ).format('0,0')}
                  </Button>
                </Tooltip>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'campaign_goals',
      checkboxLabel: 'Campaign Goals',
      Header: TableColumnHeader({
        title: 'Campaign Goals',
        tooltip: 'The number of campaign goals that use this group.',
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      expander: true,
      className: classNames(tableStyles.cellRight),
      width: 140,
      Expander({ isExpanded, original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return (
                <Tooltip title={isExpanded ? 'Click to hide usage' : 'Click to show usage'}>
                  <Button type={'link'}>
                    {numeral(
                      Goals.count({
                        filters: {
                          segment: original.id,
                          kind: 'campaign',
                          is_archived__ne: true,
                        },
                      }),
                    ).format('0,0')}
                  </Button>
                </Tooltip>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'flight_goals',
      checkboxLabel: 'Flight Goals',
      Header: TableColumnHeader({
        title: 'Flight Goals',
        tooltip: 'The number of flights that use this group as a goal.',
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      expander: true,
      className: classNames(tableStyles.cellRight),
      width: 120,
      Expander({ isExpanded, original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return (
                <Tooltip title={isExpanded ? 'Click to hide usage' : 'Click to show usage'}>
                  <Button type={'link'}>
                    {numeral(
                      Goals.count({
                        filters: {
                          segment: original.id,
                          kind: 'flight',
                          is_archived__ne: true,
                        },
                      }),
                    ).format('0,0')}
                  </Button>
                </Tooltip>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'options',
      checkboxLabel: 'Options',
      Header: TableColumnHeader({
        title: 'Options',
      }),
      width: 80,
      sortable: false,
      className: tableStyles.cellCenter,
      Cell(row: IRow): JSX.Element {
        return <OptionsCell {...row} />;
      },
    },
  ];

  return columnIds ? columns.filter((column) => columnIds.includes(column.id!)) : columns;
}

export default dataSegmentsPageColumns;
