import { computed, makeObservable } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { isWretchError } from '@feathr/rachis';
import { wretch } from '@feathr/rachis';
import { BulkCollection, DisplayModel } from '@feathr/rachis';

import type { IBreadcrumb } from '../breadcrumbs';
import type { IPerson } from '../persons/persons';
import type { IStats } from '../stats';

export interface ICustomData {
  [key: string]: string | number | boolean | Date | string[] | undefined;
}

export interface IEditableField {
  data_type: 'str' | 'int' | 'float' | 'list' | 'bool' | 'date';
  description?: string;
  f_key: string;
  form_type: 'text' | 'textarea' | 'image' | 'number' | 'checkbox' | 'list' | 'date';
  is_default?: boolean;
  label?: string;
  u_key: string;
  read_only?: boolean;
  required?: boolean;
  character_limit?: number;
}

export interface IPartnerLead extends IPerson {
  /** The crumbs that qualified this person as a lead.  */
  inner_hits: { breadcrumbs: IBreadcrumb[] };
}

export interface IPartnerStats extends IStats {
  date_first_access: string;
  date_last_access: string;
  num_clicks: number;
  num_views: number;
}

export type TStageType =
  | 'new'
  | 'unread'
  | 'received'
  | 'visited'
  | 'shared'
  | 'leads'
  | 'completed';

export interface IPartner extends IBaseAttributes {
  lead_notifications: boolean;
  logo: string;
  dashboard_token: string;
  dashboard_url: string;
  stats: IPartnerStats;
  email: string;
  name?: string;
  parent: string;
  is_active: boolean;
  tag_ids: string[];
  editable_fields: IEditableField[];
  state: 'updating' | 'updated';
  campaign_states: Record<TStageType, string[]>;
  custom_data: ICustomData;
}

export class Partner extends DisplayModel<IPartner> {
  public readonly className = 'Partner';

  public constraints: TConstraints<IPartner> = {};

  constructor(attributes: Partial<IPartner> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getDefaults(): Partial<IPartner> {
    return {
      stats: {
        date_first_access: '',
        date_last_access: '',
        num_conversions: 0,
        num_clicks: 0,
        num_views: 0,
        spend: 0,
        flavors: {
          dashboard_view: 0,
        },
      },
    };
  }

  public getDashboardUrl(): string {
    const dashboardUrl = this.get('dashboard_url');
    if (!dashboardUrl) {
      return '';
    }
    return process.env.NODE_ENV === 'production'
      ? dashboardUrl
      : BLACKBOX_URL.concat(dashboardUrl.split('/').slice(4).join('/'));
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/projects/${this.get('parent')}/partners/${this.id}`, pathSuffix);
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unknown Partner';
  }

  public async getLeads(): Promise<IPartnerLead[]> {
    const url = `${this.collection!.url()}${this.id}/get_leads/${this.get('dashboard_token')}/`;
    const response = await wretch<IPartnerLead[]>(url, {
      method: 'GET',
      headers: this.collection!.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public getDownloadLeadsUrl(): string {
    if (!this.collection) {
      throw new Error('Partner is not in a collection.');
    }
    return `${this.collection!.url()}${this.id}/leads/${this.get('dashboard_token')}/`;
  }
}

export class Partners extends BulkCollection<Partner> {
  public getModel(attributes: Partial<IPartner>): Partner {
    return new Partner(attributes);
  }

  public getClassName(): string {
    return 'partners';
  }
}
