import { faLayerPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  IiMISContactMapping,
  ImisIntegration,
  TiMISContactMappingKey,
} from '@feathr/blackbox';
import { EiMISContactFieldMappingKeys } from '@feathr/blackbox';
import type { ISelectOption, ISorted } from '@feathr/components';
import { Button, ModalV1, Select, toast } from '@feathr/components';
import { iMISContactFieldTranslationMap } from '@feathr/extender/styles/integrations';
import { enumKeys, useToggle } from '@feathr/hooks';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IiMISMappingProps {
  integration: ImisIntegration;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IiMISContactFieldOption {
  id: TiMISContactMappingKey;
  name: string;
}

function ImisAddMapping({ integration }: IiMISMappingProps): JSX.Element {
  const { t } = useTranslation();
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const [selectedFields, setSelectedFields] = useState<TiMISContactMappingKey[]>([]);
  const [contactMappings, setContactMappings] = useState<IiMISContactMapping[] | null>(null);

  useEffect(() => {
    async function getMappings(): Promise<void> {
      // TODO: Refactor to use ContactMappings.list() as part of #2651.
      const mappings = await integration.getContactMappings();
      setContactMappings(mappings);
    }

    if (!contactMappings) {
      getMappings();
    }
  }, [contactMappings, integration]);

  const contactMappingOptions: IiMISContactFieldOption[] = enumKeys(
    EiMISContactFieldMappingKeys,
  ).map((key) => ({
    id: key,
    name: iMISContactFieldTranslationMap(t, key),
  }));

  // Filter out the contact mapping options that are already mapped
  const filteredContactMappingOptions: ISorted[] = contactMappingOptions.filter(
    (option: ISorted) => {
      return !contactMappings?.some(
        (mapping: IiMISContactMapping): boolean => mapping.key === option.id,
      );
    },
  );

  const isDisabled = filteredContactMappingOptions.length === 0;

  function handleOnClick(): void {
    toggleIsModalOpen();
  }

  async function handleConfirm(): Promise<void> {
    toggleIsModalOpen();
    try {
      await integration.addContactMappings(selectedFields);
      toast(t('Successfully added mapping.'), {
        type: ToastType.SUCCESS,
      });

      // TODO: Hard reloading will not be necesary with the implemention #2651.
      window.location.reload();
    } catch (error) {
      toast(t('Failed to add mapping. Try again.'), {
        type: ToastType.ERROR,
      });
    }
  }

  function handleSelectFields(options: ISelectOption[]): void {
    // Convert options to an array of option.id
    const selectedOptions: TiMISContactMappingKey[] = options.map(
      (option) => option.id as TiMISContactMappingKey,
    );
    setSelectedFields(selectedOptions);
  }

  return (
    <>
      <Button
        disabled={isDisabled}
        onClick={handleOnClick}
        prefix={<FontAwesomeIcon icon={faLayerPlus} />}
        tooltip={isDisabled ? t('All iMIS contact fields have been added.') : undefined}
        tooltipPosition={'left'}
        type={'primary'}
      >
        {t('Add mapping')}
      </Button>
      {isModalOpen && (
        <ModalV1
          confirmButtonText={'Add'}
          confirmButtonType={'primary'}
          confirmDisabled={selectedFields.length === 0}
          controlled={true}
          onClose={toggleIsModalOpen}
          onConfirm={handleConfirm}
          t={t}
          title={t('Add Mapping')}
        >
          <p>{t('Select additional iMIS contact fields to create mappings to Feathr fields.')}</p>
          <Select
            isMulti={true}
            label={t('iMIS Contact Fields')}
            onSelectMulti={handleSelectFields}
            options={filteredContactMappingOptions}
          />
        </ModalV1>
      )}
    </>
  );
}

export default observer(ImisAddMapping);
