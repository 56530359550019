import { action, computed, makeObservable, observable } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, IRachisMessage, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel, isWretchError, wretch } from '@feathr/rachis';

import { ECollectionClassName } from './model';
import type { IFetchPersonFieldValue } from './persons';

export enum FieldCollection {
  Partner = 'Partner',
  Person = 'Person',
  Project = 'Project',
  Breadcrumb = 'Breadcrumb',
}

export enum FieldDataType {
  str = 'str',
  int = 'int',
  float = 'float',
  bool = 'bool',
  list = 'list',
  date = 'date',
}

export enum FieldFormType {
  text = 'text',
  number = 'number',
  textarea = 'textarea',
  email = 'email',
  url = 'url',
  image = 'image',
}

export interface ICustomField extends IBaseAttributes {
  collection: FieldCollection;
  data_type: FieldDataType;
  description: string;
  f_key: string;
  is_read_only: boolean;
  parent: string;
  u_key: string;
}

export interface IDefaultField
  extends Pick<ICustomField, 'id' | 'u_key' | 'data_type' | 'collection' | 'is_read_only'> {
  is_default?: boolean;
}

export class CustomField extends DisplayModel<ICustomField> {
  public readonly className = 'CustomField';

  @observable public mergeId: string | undefined;

  public constraints: TConstraints<ICustomField> = {
    u_key: {
      presence: {
        allowEmpty: false,
        message: '^Custom field must have a name.',
      },
    },
    collection: {
      presence: {
        allowEmpty: false,
        message: '^Custom field must have a collection.',
      },
    },
    data_type: {
      presence: {
        allowEmpty: false,
        message: '^Custom field must have a data type.',
      },
    },
    is_read_only: {
      exclusion: {
        within: [true],
        message: '^Custom field is read-only.',
      },
    },
  };

  constructor(attributes: Partial<ICustomField> = {}) {
    super(attributes);

    makeObservable(this);
  }

  @computed
  public get name(): string {
    return this.get('u_key', '').trim() || 'Unnamed Field';
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/data/custom-fields/${this.id}`, pathSuffix);
  }

  @action
  public setMergeId(id: string | undefined): void {
    this.mergeId = id;
  }

  public async merge(): Promise<IRachisMessage> {
    this.assertCollection(this.collection, ECollectionClassName.CustomField);

    if (!this.mergeId || !this.id || !this.collection) {
      throw new Error('Invalid state for merge.');
    }
    const url = `${this.collection.url()}${this.id}/merge`;
    const headers = this.collection.getHeaders();
    // TODO: Frontend and backend implementation don't seem to match up on expectations.
    const response = await wretch<IRachisMessage>(url, {
      headers,
      method: 'POST',
      body: JSON.stringify({ merge_id: this.mergeId }),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }
}

export class CustomFields extends Collection<CustomField> {
  public getClassName(): string {
    return 'custom_fields';
  }

  public getModel(attributes: Partial<ICustomField>): CustomField {
    return new CustomField(attributes);
  }

  public async getListOptions(fKey: string, query?: string): Promise<IFetchPersonFieldValue[]> {
    const headers = this.getHeaders();
    const url = `${BLACKBOX_URL}persons/custom.${fKey}/values/?q=${encodeURIComponent(query ?? '')}`;
    const response = await wretch<IFetchPersonFieldValue[]>(url, {
      headers,
      method: 'GET',
    });

    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }
}
