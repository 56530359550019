import { faInboxIn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX, RefObject } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { TActionButtonType, TModalV1ActionEvent } from '@feathr/components';
import { Button } from '@feathr/components';

import type { IExportCampaignsModalProps } from './ExportCampaignsModal';
import ExportCampaignsModal from './ExportCampaignsModal';

interface IProps extends Omit<IExportCampaignsModalProps, 'onClose'> {
  isFullWidth?: boolean;
  modalRef?: RefObject<HTMLDivElement>;
  theme?: TActionButtonType;
}

function ExportCampaignsButton({
  isFullWidth,
  modalRef,
  params,
  theme = 'secondary',
}: Readonly<IProps>): JSX.Element {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  function handleOpenModal(): void {
    setModalOpen(true);
  }

  function handleCloseModal(event: TModalV1ActionEvent): void {
    setModalOpen(false);
    // Prevent event from bubbling up to Popover click listener
    event.stopPropagation();
  }

  return (
    <>
      <Button
        isFullWidth={isFullWidth}
        onClick={handleOpenModal}
        prefix={<FontAwesomeIcon icon={faInboxIn} />}
        type={theme}
      >
        {t('Export as CSV')}
      </Button>
      {isModalOpen && (
        <ExportCampaignsModal onClose={handleCloseModal} params={params} ref={modalRef} />
      )}
    </>
  );
}

export default ExportCampaignsButton;
