import type { JSX, ReactNode } from 'react';
import React from 'react';

import Page from '@feathr/extender/App/Page';

import * as styles from './ErrorPage.css';

interface IProps {
  children?: ReactNode;
  title: string;
  description: ReactNode;
}

function ErrorPage({ children, title, description }: Readonly<IProps>): JSX.Element {
  return (
    <Page showHeader={false}>
      <div className={styles.root}>
        <div className={styles.header}>
          <h2>{title}</h2>
          <div>{description}</div>
        </div>
        <div>{children}</div>
      </div>
    </Page>
  );
}

export default ErrorPage;
