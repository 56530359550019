import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import type { WorkBook } from 'xlsx';
import { read, utils } from 'xlsx';

import type { IImportColumn } from '@feathr/blackbox';
import type { Importer } from '@feathr/blackbox';
import { ButtonValid, Fieldset, FileUpload, Form, Input } from '@feathr/components';
import TagsField from '@feathr/extender/components/TagsField';
import { cssVar } from '@feathr/hooks';

import * as styles from './EventPartnersImportPage.css';

interface IProps {
  importer: Importer;
  onNext: () => void;
  setRows: (r: string[][]) => void;
  rows: string[][];
}

interface IButtonProps {
  importer: Importer;
  onNext: () => void;
  rows: string[][];
}

const NextStepButton = observer(({ rows, importer, onNext }: IButtonProps) => {
  const validationErrors = validateStepOne(importer);
  const columnMappers = importer.get('column_mappers');
  const hasEmptyColumnNames = columnMappers.some((cm) => {
    return !cm?.column_name;
  });
  if (columnMappers.length < 2) {
    validationErrors.push('You must have at least 2 columns that map to Name and Email.');
  } else if (rows.length < 1) {
    validationErrors.push('You must have at least 1 row to import Partner data.');
  }
  if (hasEmptyColumnNames) {
    validationErrors.push('You must have a valid name for each import column.');
  }
  return (
    <ButtonValid errors={validationErrors} id={'goToStepTwo'} onClick={onNext}>
      Next
    </ButtonValid>
  );
});

export const validateStepOne = (importer: Importer): string[] => {
  return toJS<string[]>(
    importer.validate(['name', 'file_name', 'data_key', 'tag_ids', 'event'], false).errors,
  );
};

const processWorkbook = (workbook: WorkBook): [string[][], string[]] => {
  const data = utils.sheet_to_json<string[]>(workbook.Sheets[workbook.SheetNames[0]], {
    header: 1,
    blankrows: false,
  });
  const columns = data.shift() ?? [];
  return [data, columns];
};

function PartnerImportStepOne({ importer, onNext, setRows, rows }: IProps): JSX.Element {
  function handleChangeTagsField(newValue: string[]): void {
    importer.set({ tag_ids: newValue });
  }

  function handleUpload(key: string, _: string, file: File, filename: string): void {
    const reader = new FileReader();
    reader.addEventListener('load', (load: any) => {
      const array = new Uint8Array(load.target.result);
      const workbook = read(array, { type: 'array' });
      const [r, c] = processWorkbook(workbook);
      setRows(r);
      importer.set({
        data_key: key,
        file_name: filename,
        column_names: c,
        column_mappers: observable.array(
          c.map(
            (columnName) =>
              ({
                column_name: columnName,
                feathr_attr: undefined,
                attr_type: undefined,
              } as IImportColumn),
          ),
        ),
      });
    });
    reader.readAsArrayBuffer(file as Blob);
  }

  const actions = [
    <NextStepButton importer={importer} key={'nextStep'} onNext={onNext} rows={rows} />,
  ];
  return (
    <Form
      actions={actions}
      description={
        <p>
          Upload a spreadsheet file describing one or more partners. You may optionally specify a
          tag to attach to each partner.
        </p>
      }
      label={'Upload'}
      title={'Upload'}
    >
      <Fieldset>
        <Input
          attribute={'name'}
          helpText={
            'Name this import. This will create a tag that is unique to the group of people in this upload. You can search for this tag when creating a group to easily target these people.'
          }
          id={'fileName'}
          label={'Import Name'}
          model={importer}
          placeholder={'Email List Import April 2020'}
          type={'text'}
        />
        <TagsField
          context={'partner'}
          label={'Tags'}
          onChange={handleChangeTagsField}
          value={importer.get('tag_ids', [])}
        />
      </Fieldset>
      <Fieldset className={styles.uploadSection} direction={'column'}>
        <FileUpload
          attribute={'file'}
          id={'upload'}
          model={importer}
          onUpload={handleUpload}
          pickerOptions={{
            accept: [
              'text/comma-separated-values',
              'text/csv',
              'application/csv',
              '.csv',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              '.xlsx',
            ],
            maxFiles: 1,
            exposeOriginalFile: true,
            storeTo: {
              location: 's3',
              container: 'feathr-import-data',
              access: 'private',
              region: 'us-east-1',
            },
          }}
        />
        <div>
          <Input
            attribute={'file_name'}
            disabled={true}
            helpPlacement={'bottom'}
            helpText={'accepts .xlsx and .csv files'}
            model={importer}
            type={'text'}
          />
          {importer.get('column_mappers') && importer.get('column_mappers').length > 0 && (
            <p>
              Number of columns:{' '}
              {importer.get('column_mappers') && importer.get('column_mappers').length}
              {importer.get('column_mappers') && importer.get('column_mappers').length >= 2 ? (
                <FontAwesomeIcon icon={faCheck} style={{ color: cssVar('--color-success') }} />
              ) : (
                <FontAwesomeIcon icon={faTimes} style={{ color: cssVar('--color-warning') }} />
              )}
            </p>
          )}
          {rows.length > 0 && (
            <p>
              Number of rows: {rows.length}
              {rows.length > 0 ? (
                <FontAwesomeIcon icon={faCheck} style={{ color: cssVar('--color-success') }} />
              ) : (
                <FontAwesomeIcon icon={faTimes} style={{ color: cssVar('--color-warning') }} />
              )}
            </p>
          )}
        </div>
      </Fieldset>
    </Form>
  );
}

export default observer(PartnerImportStepOne);
