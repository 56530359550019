export const FEATHRMAIL = 'feathrmail';
export const FEATHR_DEFAULT_REDIRECT_ID = '000000000000000000000000';
/*
 * We added the email campaign template preview feature on December 21st 2022, and we are unable to generate previews
 * retroactive to when the feature was released.
 */
export const EMAIL_TEMPLATE_PREVIEW_RELEASE_DATE = '2022-12-21';

/*
 * We upgraded out Facebook API version to v17 on the below date. With v16 we were able to use the campaign objectives
 * REACH, LINK_CLICKS, and CONVERSIONS but these are no longer were available using v17. We cannot update the objectives
 * for campaigns that were running at the time of the upgrade, so we will need to keep the objectives available.
 */
export const FACEBOOK_OBJECTIVES_REFACTOR_DATE = '2023-06-20';
