import { faCreditCard } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from './BillableDefaultMissing.css';

function BillableDefaultMissing(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <FontAwesomeIcon className={styles.icon} icon={faCreditCard} size={'3x'} />
      <h4>{t('No primary billing option')}</h4>
      <p>{t('Get started by adding a new primary billing configuration.')}</p>
    </div>
  );
}

export default BillableDefaultMissing;
