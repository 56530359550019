import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { ReferralCampaign } from '@feathr/blackbox';
import { ButtonValid, DatePicker, Fieldset, Form, Input } from '@feathr/components';
import { flattenError, flattenErrors, moment, momentToDate, TimeFormat } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface IProps {
  onNext: () => void;
  campaign: ReferralCampaign;
}

interface IErrors extends TValidateGrouped {
  name?: string[];
  date_start?: string[];
  date_end?: string[];
}

function getMinStartDate(): moment.Moment {
  const minDaysAdvance = 1;
  return moment.utc().add(minDaysAdvance, 'days').startOf('day');
}

function getMinDuration(): moment.Duration {
  return moment.duration(3, 'days');
}

export function validateStepOne(campaign: ReferralCampaign): IErrors {
  return campaign.validate<IErrors>(['name', 'date_start', 'date_end'], false, 'grouped').errors;
}

const NextStepButton = observer(({ campaign, onNext }: IProps) => {
  const errors = validateStepOne(campaign);
  return (
    <ButtonValid errors={flattenErrors(errors)} name={'next_step'} onClick={onNext}>
      Next
    </ButtonValid>
  );
});

function StepOne({ campaign, onNext }: IProps): JSX.Element {
  const startMoment = campaign.get('date_start')
    ? moment.utc(campaign.get('date_start'), moment.ISO_8601).startOf('day')
    : undefined;
  const startDate = startMoment ? momentToDate(startMoment) : undefined;

  const endMoment = campaign.get('date_end')
    ? moment.utc(campaign.get('date_end'), moment.ISO_8601).startOf('day')
    : undefined;
  const endDate = endMoment ? momentToDate(endMoment) : undefined;

  function handleChangeStartDate(newDate?: string): void {
    // If the date is invalid, don't update the model.
    if (!moment(newDate).isValid()) {
      return;
    }
    const patch: { date_start: string; date_end?: string } = {
      date_start: moment(newDate).utc().format(TimeFormat.isoDate),
    };
    const newStartMoment = moment.utc(newDate, moment.ISO_8601);
    const newMinEndMoment = newStartMoment.add(getMinDuration());
    if (endMoment && newMinEndMoment.isAfter(endMoment)) {
      patch.date_end = newMinEndMoment.startOf('day').format();
    }
    campaign.set(patch);
  }

  function handleChangeEndDate(newDate?: string): void {
    // If the date is invalid, don't update the model.
    if (!moment(newDate).isValid()) {
      return;
    }
    campaign.set({ date_end: moment(newDate).utc().format(TimeFormat.isoDate) });
  }

  const validationErrors = validateStepOne(campaign);

  return (
    <Form
      actions={[<NextStepButton campaign={campaign} key={'next'} onNext={onNext} />]}
      label={'Edit Campaign: Info'}
    >
      <Fieldset>
        <Input
          attribute={'name'}
          helpText={'Name your campaign.'}
          label={'Name'}
          model={campaign}
          required={true}
          type={'text'}
          validationError={flattenError(validationErrors.name)}
        />
      </Fieldset>
      <Fieldset
        helpText={
          <>
            <p>
              Please provide a start and end date for the campaign. Feathr uses these values to set
              the date ranges for reporting on campaign activity and conversions. Typically the
              start date is today and the end date is at the conclusion of the marketing initiative
              for this project.
            </p>
          </>
        }
      >
        <DatePicker
          helpText={'The campaign will start at 12AM UTC on this date.'}
          label={'Start Date'}
          minDate={momentToDate(getMinStartDate())}
          onDateStrChange={handleChangeStartDate}
          selected={startDate}
          validationError={flattenError(validationErrors.date_start)}
        />
        <DatePicker
          helpText={'The campaign will end at 12AM UTC on this date.'}
          label={'End Date'}
          minDate={startMoment ? momentToDate(startMoment.add(getMinDuration())) : undefined}
          onDateStrChange={handleChangeEndDate}
          selected={endDate}
          validationError={flattenError(validationErrors.date_end)}
        />
      </Fieldset>
    </Form>
  );
}

export default observer(StepOne);
