import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Tooltip } from '@feathr/components';

import * as styles from './RefreshController.css';

interface IProps {
  handleRefresh: () => void;
  shouldRefresh: boolean;
  toggleRefresh: () => void;
}

function RefreshController({ handleRefresh, shouldRefresh, toggleRefresh }: IProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={styles.refresh}>
      <Tooltip title={t('Refresh list')}>
        <Button className={styles.refreshButton} onClick={handleRefresh} type={'icon-outlined'}>
          <FontAwesomeIcon icon={faSync} />
        </Button>
      </Tooltip>
      <Checkbox label={t('Auto refresh')} onChange={toggleRefresh} value={shouldRefresh} />
    </div>
  );
}

export default observer(RefreshController);
