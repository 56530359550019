import classNames from 'classnames';
import type { IObservableArray } from 'mobx';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import type { ActionMeta, ValueType } from 'react-select';

import type { BaseCampaign, Campaign, CampaignClass } from '@feathr/blackbox';
import { AsyncSelect } from '@feathr/components';
import {
  CampaignModelIconOption,
  CampaignSingleValue,
} from '@feathr/extender/components/SelectOptions/CampaignOption';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './CampaignSelect.css';

interface IProps {
  className?: string;
  disabled?: boolean;
  eventId: string;
  id?: string;
  onClear: () => void;
  placeholder?: string;
  type?: CampaignClass | CampaignClass[];
  filters?: Record<string, any>;
}

interface ISingleProps extends IProps {
  isMulti: false;
  onChange: (value: string) => void;
  value?: string;
}

interface IMultiProps extends IProps {
  isMulti: true;
  onChange: (value: string[]) => void;
  value?: string[];
}

function CampaignSelect({
  className,
  disabled = false,
  eventId,
  id,
  isMulti = false,
  onChange,
  onClear,
  placeholder,
  type,
  value,
  filters = {},
}: ISingleProps | IMultiProps): JSX.Element {
  const { Campaigns } = useContext(StoresContext);

  async function loadOptions(inputValue: string): Promise<Campaign[]> {
    const data = Campaigns.list({
      filters: {
        _cls__in: type ? (Array.isArray(type) ? type : [type]) : undefined,
        name__icontains: inputValue,
        _parent: eventId,
        ...filters,
      },
    });
    await when(() => !data.isPending);
    return (data.models as IObservableArray<BaseCampaign>)
      .filter((model) => {
        if (Array.isArray(value)) {
          return !value.includes(model.id);
        }
        return !value || value !== model.id;
      })
      .map((model) => model);
  }

  function handleChange(newValue: ValueType<Campaign>, action: ActionMeta<Campaign>): void {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (Array.isArray(newValue)) {
        if (newValue.length) {
          (onChange as IMultiProps['onChange'])((newValue as Campaign[]).map((v) => v.id!));
        } else {
          onClear();
        }
      } else if (newValue) {
        (onChange as ISingleProps['onChange'])((newValue as Campaign).id!);
      } else {
        onClear();
      }
    }
  }

  return (
    <AsyncSelect<Campaign>
      components={{ Option: CampaignModelIconOption, SingleValue: CampaignSingleValue }}
      defaultOptions={true}
      disabled={disabled}
      getOptionLabel={(c) => c.name}
      id={id}
      isClearable={true}
      isMulti={isMulti}
      loadOptions={loadOptions}
      name={'campaign_select'}
      noOptionsMessage={({ inputValue }) =>
        inputValue.length ? 'No results...' : 'Start typing the name of a campaign...'
      }
      onChange={handleChange}
      placeholder={placeholder}
      value={
        Array.isArray(value)
          ? value.map((valueId) => Campaigns.get(valueId))
          : value
          ? Campaigns.get(value)
          : undefined
      }
      wrapperClassName={classNames(styles.root, className)}
    />
  );
}

export default observer(CampaignSelect);
