import type { IObservableArray } from 'mobx';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { FacebookCampaign, FacebookDisplayCreative, IFacebookPage } from '@feathr/blackbox';
import {
  Button,
  ButtonValid,
  Collapse,
  CountrySelect,
  Fieldset,
  Form,
  Input,
} from '@feathr/components';

import AdCategorySelect from './AdCategorySelect';
import AddCreativeButton from './AddCreativeButton';
import AgeSelect from './AgeSelect';
import FacebookCreativesList from './FacebookCreativesList';
import FacebookPageSelect from './FacebookPageSelect';

import * as styles from './FacebookCampaignEditStepFour.css';

interface IProps {
  onNext: () => void;
  onPrev: () => void;
  campaign: FacebookCampaign;
  creatives: IObservableArray<FacebookDisplayCreative>;
}

export function validateStepFour(
  campaign: FacebookCampaign,
  creatives: IObservableArray<FacebookDisplayCreative>,
): string[] {
  const campaignErrors = campaign.validate(
    ['destination_url', 'facebook_page_id', 'publisher_platforms', 'targeted_countries'],
    false,
  ).errors;
  if (campaignErrors.length) {
    return campaignErrors;
  }
  if (creatives.filter((t) => t.get('is_enabled')).length < 1) {
    return ['You need at least one enabled creative.'];
  }
  return creatives
    .map((crv) => {
      const errors = toJS(
        crv.validate(
          [
            'width',
            'height',
            'spec',
            'destination_url',
            'name',
            'caption',
            'duration',
            'ad_video_id',
            'ad_video_thumbnail',
          ],
          false,
        ).errors,
      );

      if (errors.length) {
        return errors[0];
      }
      return undefined;
    })
    .filter((msg) => !!msg) as string[];
}

const NextStepButton = observer(({ campaign, creatives, onNext }: Omit<IProps, 'onPrev'>) => {
  const validationErrors = validateStepFour(campaign, creatives);
  return (
    <ButtonValid errors={validationErrors} name={'next_step'} onClick={onNext}>
      Next
    </ButtonValid>
  );
});

function FacebookCampaignEditStepFour({
  campaign,
  creatives,
  onNext,
  onPrev,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const [page, setPage] = useState<IFacebookPage>();

  return (
    <>
      <Form
        actions={[
          <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
            Previous
          </Button>,
          <NextStepButton campaign={campaign} creatives={creatives} key={'next'} onNext={onNext} />,
        ]}
        description={
          <>
            <p>Upload your creatives below and add your caption, headline, and destination URL.</p>
            <Collapse title={t('Creative specifications')}>
              <Trans t={t}>
                <table className={styles.specTable}>
                  <thead>
                    <tr>
                      <th>Images</th>
                      <th>Videos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <dl>
                          <dt>File Type:</dt>
                          <dd>JPG or PNG</dd>
                          <dt>Ratio:</dt>
                          <dd>1.91:1 to 1:1</dd>
                          <dt>Supported Resolutions (px):</dt>
                          <dd>
                            <ul>
                              <li>1200 x 628</li>
                              <li>1080 x 1080</li>
                            </ul>
                          </dd>
                          <dt>Maximum File Size:</dt>
                          <dd>20MB</dd>
                        </dl>
                      </td>
                      <td>
                        <dl>
                          <dt>File Type:</dt>
                          <dd>MP4, MOV or GIF</dd>
                          <dt>Ratio:</dt>
                          <dd>1:1 or 4:5 (mobile)</dd>
                          <dt>Supported Resolutions (px):</dt>
                          <dd>
                            <ul>
                              <li>1080 x 1080</li>
                              <li>1920 x 1080</li>
                              <li>864 x 1080</li>
                              <li>1080 x 1920</li>
                            </ul>
                          </dd>
                          <dt>Video Duration:</dt>
                          <dd>5 seconds to 2 minutes</dd>
                          <dt>Maximum File Size:</dt>
                          <dd>1GB</dd>
                        </dl>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Trans>
            </Collapse>
          </>
        }
        label={'Edit Campaign: Creative'}
      >
        <Fieldset>
          <Input
            attribute={'destination_url'}
            label={t('Destination URL')}
            model={campaign}
            name={'destination_url'}
            placeholder={'https://example.com/landingpage'}
            required={true}
            type={'url'}
          />
          <FacebookPageSelect campaign={campaign} setPage={setPage} />
          <AdCategorySelect campaign={campaign} />
          <CountrySelect
            attribute={'targeted_countries'}
            helpText={t('Select where you want to run this campaign.')}
            isMulti={true}
            label={t('Targeted countries')}
            model={campaign}
            required={true}
          />
          {/* You must have a page selected to view the creatives */}

          <AgeSelect
            campaign={campaign}
            helpText={t('Select the age range you want to target.')}
            label={t('Age range')}
          />

          <h4>{t('Creatives')}</h4>
          <FacebookCreativesList campaign={campaign} creatives={creatives} page={page} />
          <AddCreativeButton campaign={campaign} creatives={creatives} />
        </Fieldset>
      </Form>
    </>
  );
}

export default FacebookCampaignEditStepFour;
