import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { PinpointPartnerMessage } from '@feathr/blackbox';
import { Fieldset, Form, Input } from '@feathr/components';
import type { TValidateGrouped } from '@feathr/rachis';

import ButtonNext from './ButtonNext';

interface IProps {
  disabled?: boolean;
  message: PinpointPartnerMessage;
  onNext: () => void;
}

export function validateStepOne(message: PinpointPartnerMessage): TValidateGrouped {
  return message.validate(['name'], false, 'grouped').errors;
}

function EventPartnersMessageStepOne({ disabled = false, message, onNext }: IProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Form
      actions={[
        <ButtonNext
          key={'next'}
          message={message}
          name={'next_step'}
          onNext={onNext}
          validate={validateStepOne}
        />,
      ]}
      description={t('Provide a descriptive name for your message so you can find it later.')}
      label={t('Edit Message: Name')}
    >
      <Fieldset>
        <Input
          attribute={'name'}
          disabled={disabled}
          label={t('Name')}
          model={message}
          name={'campaign_name'}
          type={'text'}
        />
      </Fieldset>
    </Form>
  );
}

export default EventPartnersMessageStepOne;
