import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';

import type { Campaign, DisplayCreative } from '@feathr/blackbox';
import { StoresContext } from '@feathr/extender/state';
import { moment } from '@feathr/hooks';

import Creative from '../../Creative';

interface IListProps {
  creatives: IObservableArray<DisplayCreative>;
  campaign: Campaign;
}

function CreativesList({ creatives, campaign }: IListProps): JSX.Element {
  const { Creatives } = useContext(StoresContext);

  function removeCreative(creative: DisplayCreative): void {
    if (creative.isEphemeral) {
      creatives.remove(creative);
      Creatives.remove(creative.get('id'));
    } else {
      creative.set({ is_archived: true });
      creative.patchDirty();
    }
  }

  return (
    <>
      {creatives
        .filter((c) => !c.get('is_archived'))
        .sort((a, b) => moment.utc(a.get('date_created')).diff(moment.utc(b.get('date_created'))))
        .map((creative) => {
          return (
            <Creative
              campaign={campaign}
              creative={creative}
              creatives={creatives}
              key={creative.listId}
              onRemove={removeCreative}
            />
          );
        })}
    </>
  );
}

export default observer(CreativesList);
