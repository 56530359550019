import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Breadcrumb } from '@feathr/blackbox';
import { Chip } from '@feathr/components';
import { getDeviceLabelAndIcon } from '@feathr/extender/styles/device';

interface IProps {
  readonly breadcrumb: Breadcrumb;
}

function DeviceChip({ breadcrumb }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { label, icon } = getDeviceLabelAndIcon({
    screenWidth: breadcrumb.get('s_w'),
    platform: breadcrumb.get('platform'),
    t,
  });

  return <Chip prefix={<FontAwesomeIcon icon={icon} />}>{label}</Chip>;
}

export default observer(DeviceChip);
