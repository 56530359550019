import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTimeFrameValue } from '@feathr/components';
import AudienceAreaChart from '@feathr/extender/components/AudienceAreaChart';
import useDashboardAggregatedStats from '@feathr/extender/hooks/useDashboardAggregatedStats';
import useDashboardStats from '@feathr/extender/hooks/useDashboardStats';

import type { TView } from '../AudienceEngagement';
import { primaryChart, secondaryCharts } from './charts';

import * as styles from './AudienceEngagementCharts.css';

interface IProps {
  view: TView;
  timeFrameValue: TTimeFrameValue;
}

function AudienceEngagementCharts({ view, timeFrameValue }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { isLoading: isStatsLoading, chartData } = useDashboardStats({ timeFrameValue });
  const {
    isLoading: isAggStatsLoading,
    chartDataWithGoals,
    conversions,
    goalLabels: { goalLabelA, goalLabelB, goalLabelC } = {},
  } = useDashboardAggregatedStats({
    timeFrameValue,
    chartData,
  });

  const isLoading = isStatsLoading || isAggStatsLoading;

  const primary = primaryChart({ view, conversions: conversions.toString(), t });
  const secondary = secondaryCharts({
    view,
    conversionLabels: { goalLabelA, goalLabelB, goalLabelC },
    t,
  });

  // If goal data isn't available, use the unprocessed chart data with its backfilled data.
  const chartDataWithGoalsAvailable = chartDataWithGoals && chartDataWithGoals?.length > 0;

  return (
    <div className={styles.root}>
      {/* We do not render a primary chart for the "Connections" view. */}
      {primary && (
        <div key={'primary-chart'}>
          <AudienceAreaChart
            {...primary}
            backfillData={true}
            data={chartData}
            isLoading={isLoading}
            showValue={true}
            size={'large'}
            xAxisKey={'metadata.date'}
          />
        </div>
      )}
      <div key={'secondary-charts'}>
        <AudienceAreaChart.Group
          backfillData={true}
          charts={secondary}
          data={chartDataWithGoalsAvailable ? chartDataWithGoals : chartData}
          isLoading={isLoading}
          rowSize={3}
          showValue={true}
        />
      </div>
    </div>
  );
}

export default observer(AudienceEngagementCharts);
