import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import ErrorPage from '@feathr/extender/App/ErrorPage';
import { useLocalUrl } from '@feathr/extender/state';

interface ILocationState {
  from: {
    pathname: string;
  };
}

function NotFound(): JSX.Element {
  const location = useLocation<ILocationState>();
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const description = location && location.state && location.state.from && (
    <Trans shouldUnescape={true} t={t}>
      <p>
        You were trying to visit <em>{{ path: location.state.from.pathname }}</em>.
      </p>
    </Trans>
  );

  return (
    <ErrorPage description={description} title={t("Sorry, that page doesn't exist.")}>
      <Trans t={t}>
        <p>
          You might find what you were looking for in the&nbsp;
          <Link to={localUrl('/projects')}>projects section</Link> or the&nbsp;
          <Link to={localUrl('/data/explore/people')}>data section</Link>.
        </p>
      </Trans>
    </ErrorPage>
  );
}

export default NotFound;
