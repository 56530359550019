import debounce from 'debounce-promise';
import { reaction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Label } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useAccount } from '@feathr/extender/state';

import * as styles from './SubscriptionsPage.css';

function SubscriptionsPage(): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();

  const store = useLocalObservable(() => ({
    previewKey: account.get('email_preferences_alias'),
    updateKey: () => (store.previewKey = account.get('email_preferences_alias')),
  }));
  const debouncedUpdateKey = debounce(async () => {
    await account.patchDirty();
    store.updateKey();
  }, 1000);
  useEffect(() => reaction(() => account.get('email_preferences_alias'), debouncedUpdateKey));

  return (
    <Page title={'Subscription Management Settings'}>
      <Form
        description={
          <p>
            Preview the language that email recipients will see on their subscription preferences
            management page.
          </p>
        }
        label={'Subscription Management Settings'}
      >
        <Input
          attribute={'email_preferences_alias'}
          helpText={t(
            'Here you can specify a custom label to be used for your Feathr account level subscription. Email recipients will see this when they are managing their subscription preferences. Defaults to the name of your account.',
          )}
          label={t('Custom account subscription label')}
          model={account}
          placeholder={account.get('name')}
          type={'text'}
        />
        <Label>Preview</Label>
        <iframe
          className={styles.preview}
          key={store.previewKey}
          src={account.emailPreferencesPreviewUrl}
          title={'Preview'}
        />
      </Form>
    </Page>
  );
}

export default observer(SubscriptionsPage);
