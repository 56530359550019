import type { ReactNode } from 'react';
import React from 'react';

import type { ReportStore } from '../../state';
import { StoresContext } from '../../state';

interface IProps {
  children: ReactNode;
  store: ReportStore;
}

function ContextWrapper({ children, store }: IProps) {
  return <StoresContext.Provider value={store}>{children}</StoresContext.Provider>;
}

export default ContextWrapper;
