import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { StoresContext } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function ImportLabel({ className, id }: IProps) {
  const { Importers } = React.useContext(StoresContext);
  const original = Importers.get(id);
  return (
    <div className={classNames({ [skeleton]: original.isPending }, className)}>{original.name}</div>
  );
}

export default observer(ImportLabel);
