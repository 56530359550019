import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import type { ColumnRenderProps } from 'react-table';

import type { Account, BaseCampaign, Campaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import type { ICampaignRow } from './CampaignsColumns';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IPermissionColumn {
  shouldShow?: (account: Account) => boolean;
}

function getROI(original: ICampaignRow['original']): number {
  const value = original.get('monetization_value') || 0;
  const spend = original.get('total_stats')?.spend || 0;
  return Math.max(value - spend, 0);
}

const MonetizationROIColumn: IPermissionColumn & IColumn<BaseCampaign> = {
  id: 'monetization_roi',
  checkboxLabel: 'Monetization Conversion Value',
  Header: TableColumnHeader({
    title: 'Monetization Conversion Value',
  }),
  className: tableStyles.cellRight,
  width: 240,
  sortable: false,
  Cell({ original }: ICampaignRow): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const value = getROI(original);
          return <>{numeral(value).format('$0,0.00')}</>;
        }}
      </Observer>
    );
  },
  Footer: ({ data }: ColumnRenderProps<{ _original: Campaign }>): JSX.Element => {
    return (
      <Observer>
        {(): JSX.Element => {
          const total = data.reduce((subtotal, currentValue) => {
            const value = getROI(currentValue._original);
            return subtotal + value;
          }, 0);
          return <>{numeral(total).format('$0,0.00')}</>;
        }}
      </Observer>
    );
  },
  shouldShow: (account: Account): boolean => account.hasMonetization,
};

export default MonetizationROIColumn;
