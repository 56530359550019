import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ImisIntegration } from '@feathr/blackbox';
import { Tab, Tabs } from '@feathr/components';

import ImisSettings from './ImisSyncWizard/ImisSettings/ImisSettings';
import ImisSyncContacts from './ImisSyncWizard/ImisSyncContact';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IiMISConnectedCardProps {
  integration: ImisIntegration;
}

type TTabKey = 'mappings' | 'settings';

function ImisConnectedCard({ integration }: IiMISConnectedCardProps): JSX.Element {
  const { t } = useTranslation();
  const [tab, setTab] = useState<TTabKey>('mappings');

  function handleClickMappingsTab(): void {
    setTab('mappings');
  }

  function handleClickSettingsTab(): void {
    setTab('settings');
  }

  return (
    <>
      <Tabs>
        <Tab
          active={tab === 'mappings'}
          key={'mappings'}
          onClick={handleClickMappingsTab}
          title={t('Mappings')}
        />
        <Tab
          active={tab === 'settings'}
          key={'settings'}
          onClick={handleClickSettingsTab}
          title={t('Settings')}
        />
      </Tabs>
      {tab === 'mappings' && <ImisSyncContacts integration={integration} />}
      {tab === 'settings' && <ImisSettings integration={integration} />}
    </>
  );
}

export default ImisConnectedCard;
