declare global {
  // tslint:disable-next-line
  interface Window {
    FB: any;
    fbAsyncInit: any;
  }
}

function initFbSdk(): Promise<any> {
  return new Promise((resolve, reject) => {
    if (typeof window.FB !== 'undefined') {
      resolve(window.FB);
    } else {
      window.fbAsyncInit = () => {
        try {
          window.FB.init({
            appId: FACEBOOK_APP_ID,
            autoLogAppEvents: true,
            xfbml: true,
            version: FACEBOOK_API_VERSION,
            cookie: true,
          });
          window.FB.AppEvents.logPageView();
          resolve(window.FB);
        } catch (exc) {
          reject(exc);
        }
      };
      (function (d, s: 'script', id): void {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s);
        js.id = id;
        js.async = true;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode!.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  });
}

export class FacebookUser {
  public username: string;
  public access_token: string;
  public user_id: string;

  constructor(me: any) {
    this.username = me.name;
    this.access_token = me.accessToken;
    this.user_id = me.userID;
  }
}

async function getUserInfo(authResponse: any): Promise<FacebookUser> {
  return new Promise(function (resolve) {
    window.FB.api('/me', function (me: any) {
      Object.assign(me, authResponse);
      const facebookUser = new FacebookUser(me);
      resolve(facebookUser);
    });
  });
}

export default async function facebookLogin(): Promise<FacebookUser> {
  await initFbSdk();
  return new Promise(function (resolve, reject) {
    const options = {
      scope: [
        'business_management',
        'ads_management',
        'pages_show_list',
        'ads_read',
        'pages_manage_ads',
        'pages_read_engagement',
      ].join(','),
      return_scopes: true,
    };
    const cb = function (response: any): void {
      if (response.authResponse && response.status === 'connected') {
        const me = getUserInfo(response.authResponse);
        resolve(me);
      } else {
        reject(JSON.stringify(response));
      }
    };
    window.FB.login(cb, options);
  });
}
