/* eslint-disable simple-import-sort/imports */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'normalize.css';

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { initMsw, Session } from '@feathr/blackbox';
import { Collection } from '@feathr/rachis';

import App from './App';
import AppWrapper from './AppWrapper';
import UserErrorBoundary from './components/UserErrorBoundary';

import 'leaflet/dist/leaflet.css';

// Set below for all Collection intances at once.
Collection.prototype.getHeaders = (useAccountContext = true): HeadersInit =>
  Session.getHeaders(useAccountContext);
Collection.prototype.getHostname = (): string => BLACKBOX_URL;

// TODO: Figure out if we need this
window.addEventListener(
  'dragover',
  (e) => {
    e.preventDefault();
  },
  false,
);
window.addEventListener(
  'drop',
  (e) => {
    e.preventDefault();
  },
  false,
);

function startApp(): void {
  // Assign logout function to globals to allow wretch to pick up on it.
  globalThis.feathrLogout = (): Promise<void> => {
    return Session.logOut(() => {
      window.location.assign(
        `${Session.logInUrl}?from=${encodeURIComponent(window.location.href)}`,
      );
    });
  };

  if (!Session.isLoggedIn) {
    window.location.assign(`${Session.logInUrl}?from=${encodeURIComponent(window.location.href)}`);
    return;
  }

  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://58ec8dc694284508ac96e18b48ad81bc@o1296517.ingest.sentry.io/6526185',
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      replaysSessionSampleRate: 0.01,
      // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
      replaysOnErrorSampleRate: 0.1,
      tracesSampleRate: 0.05,
    });
  }

  initMsw();

  const root = ReactDOM.createRoot(document.getElementById('react-root')!);
  root.render(
    <AppWrapper>
      <UserErrorBoundary>
        <App />
      </UserErrorBoundary>
    </AppWrapper>,
  );
}

startApp();
