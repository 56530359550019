import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type {
  Campaign,
  DisplayCreative,
  FacebookCampaign,
  FacebookDisplayCreative,
  IAdTagBaseCreative,
  IFacebookPage,
} from '@feathr/blackbox';
import { CreativeClass } from '@feathr/blackbox';
import { Postscribe } from '@feathr/components';

import FacebookAdPreview from '../FacebookAdPreview';

import * as styles from './CreativePreview.css';

interface IProps {
  campaign: Campaign;
  creative: DisplayCreative;
  page?: IFacebookPage;
}

export function formatAdTag(adtag: string, clickURL: string): string {
  return adtag
    .replace(/@FEATHR_CLICK@/g, clickURL)
    .replace(/@FEATHR_CLICK_ESC@/g, encodeURIComponent(clickURL))
    .replace(/@FEATHR_TRACK@/g, '');
}

function CreativePreview({ creative, campaign, page }: IProps): JSX.Element {
  const { t } = useTranslation();
  const destinationURL = creative.get('destination_url') || campaign.get('destination_url');
  const useOrignalDestination = creative.get('use_original_destination') ? 1 : 0;
  const queryStr = `?rdr=${encodeURIComponent(destinationURL)}&preview=1&a_id=${campaign.get(
    'account',
  )}&use_original_destination=${useOrignalDestination}&preview_name=${creative.get('name')}&ordr=`;
  const testClickURL = `${POLO_URL}analytics/crumb${queryStr}`;
  const spec = creative.get('spec');
  const isHD = spec.includes('1080') || spec.includes('720');
  const [playbackStarted, setPlaybackStarted] = React.useState(false);

  const formattedAdTag =
    creative.get('_cls') === CreativeClass.DisplayAdTag ||
    creative.get('_cls') === CreativeClass.DisplayBannersnack
      ? formatAdTag((creative as DisplayCreative<IAdTagBaseCreative>).get('adtag'), testClickURL)
      : '';

  return (
    <>
      <Trans t={t}>
        <p>
          Below is a full-size preview of your creative. Click it to test the click-through tracking
          and destination.
        </p>
        <p>
          <strong>Note: </strong>Viewing and clicking on this preview will not impact campaign and
          creative reporting.
        </p>
      </Trans>
      <div className={styles.root}>
        {creative.get('_cls') === CreativeClass.DisplayImage && (
          <ins
            className={styles.imgPreview}
            style={{
              width: creative.get('width'),
              height: creative.get('height'),
            }}
          >
            <a href={testClickURL} target={'_blank'}>
              <img
                alt={'Creative Preview'}
                height={creative.get('height')}
                src={creative.get('url')}
                width={creative.get('width')}
              />
            </a>
          </ins>
        )}
        {creative.get('_cls') === CreativeClass.DisplayVideo && (
          <div
            className={styles.vidPreview}
            style={{
              width: isHD ? '900px' : '480px',
              height: isHD ? '536px' : '550px',
            }}
          >
            {playbackStarted && (
              <div
                className={styles.vidPreviewClickZone}
                onClick={() => window.open(testClickURL, '_blank')}
              />
            )}
            <video controls={true} onPlay={() => setPlaybackStarted(true)}>
              <source src={creative.get('url')} />
            </video>
          </div>
        )}
        {(creative.get('_cls') === CreativeClass.DisplayAdTag ||
          creative.get('_cls') === CreativeClass.DisplayBannersnack) && (
          <Postscribe html={formattedAdTag} style={{ transform: 'none' }} />
        )}
        {(creative.get('_cls') === CreativeClass.FacebookImage ||
          creative.get('_cls') === CreativeClass.FacebookVideo) &&
          page && (
            <FacebookAdPreview
              campaign={campaign as FacebookCampaign}
              creative={creative as FacebookDisplayCreative}
              page={page}
              testClickURL={testClickURL}
            />
          )}
      </div>
    </>
  );
}

export default CreativePreview;
