import React from 'react';

import {
  Accounts,
  APIKeys,
  Billables,
  Bills,
  BlackbaudRaisersEdgeIntegrations,
  Breadcrumbs,
  Campaigns,
  ContactLists,
  Conversions,
  Creatives,
  CustomFields,
  DailyStats,
  DataRequests,
  Domains,
  EmailSendRequests,
  EmailVerifications,
  Events,
  FacebookCampaignIntegrations,
  FacebookPages,
  Flights,
  Fonts,
  GeoFilters,
  Goals,
  GoogleAdsCustomers,
  ImisIntegrations,
  Importers,
  Notifications,
  PartnerExports,
  Partners,
  Paths,
  Persons,
  RedirectDomains,
  Redirects,
  Segments,
  SendRequests,
  Tags,
  Targetables,
  Targetings,
  Templates,
  UsageMetrics,
  UsageQuotas,
  UsageReports,
  UserNotifications,
  UserRoles,
  Users,
} from '@feathr/blackbox';
import { ReportStore } from '@feathr/report_components';

export { default as useAccount } from './useAccount';
export { default as useFlags } from './useFlags';
export { default as useLocalUrl } from './useLocalUrl';
export { default as useUsageAndQuota } from './useUsageAndQuota';
export { default as useUser } from './useUser';
export { default as useRole } from './useRole';
export { ActionBarProvider, actionBarClassName, useActionBar } from './actionBar.context';
export { Providers } from './testing';

export const hiddenFlavors = [
  'bot_partner_message_link_click',
  'bot_partner_message_unsubscribe',
  'bot_partner_message_view',
  'bot_pinpoint_tracked_email_click',
  'bot_pinpoint_tracked_email_open',
  'bot_pinpoint_tracked_email_unsubscribe',
  'email_bot_detection_click',
  'email_link_click',
  'email_view',
];

export class RootStore extends ReportStore {
  public Accounts = new Accounts([]);

  public APIKeys = new APIKeys([]);

  public Billables = new Billables([]);

  public Bills = new Bills([]);

  public BlackbaudRaisersEdgeIntegrations = new BlackbaudRaisersEdgeIntegrations([]);

  public Breadcrumbs = new Breadcrumbs([]);

  public Campaigns = new Campaigns([]);

  public ContactLists = new ContactLists([]);

  public Conversions = new Conversions([]);

  public Creatives = new Creatives([]);

  public CustomFields = new CustomFields([]);

  public DataRequests = new DataRequests([]);

  public Domains = new Domains([]);

  // Partner dashboard email sends
  public EmailSendRequests = new EmailSendRequests([]);

  public EmailVerifications = new EmailVerifications([]);

  public Events = new Events([]);

  public ImisIntegrations = new ImisIntegrations([]);

  public FacebookCampaignIntegrations = new FacebookCampaignIntegrations([]);

  public FacebookPages = new FacebookPages([]);

  public Flights = new Flights([]);

  public Fonts = new Fonts([]);

  public GeoFilters = new GeoFilters([]);

  public Goals = new Goals([]);

  public GoogleAdsCustomers = new GoogleAdsCustomers([]);

  public Importers = new Importers([]);

  public Notifications = new Notifications([]);

  public Partners = new Partners([]);

  public PartnerExports = new PartnerExports([]);

  public Paths = new Paths([]);

  public Persons = new Persons([]);

  public RedirectDomains = new RedirectDomains([]);

  public Redirects = new Redirects([]);

  public Segments = new Segments([]);

  public SendRequests = new SendRequests([]);

  public Stats = new DailyStats([]);

  public Tags = new Tags([]);

  public Targetables = new Targetables([]);

  public Targetings = new Targetings([]);

  public Templates = new Templates([]);

  public UsageReports = new UsageReports([]);

  public Users = new Users([]);

  public UserRoles = new UserRoles([]);

  public UsageMetrics = new UsageMetrics([]);

  public UsageQuotas = new UsageQuotas([]);

  public UserNotifications: UserNotifications;

  public constructor() {
    super();

    this.UserNotifications = new UserNotifications([], {
      Notifications: this.Notifications,
    });
  }
}

export function createStore(): RootStore {
  return new RootStore();
}

export const StoresContext = React.createContext(new RootStore());

export default createStore;
