import type { JSX } from 'react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { ILogInResponse } from '@feathr/blackbox';
import { Session } from '@feathr/blackbox';
import { ButtonValid, Fieldset, Form, Input, toast } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { flattenErrors, useDebounce } from '@feathr/hooks';
import { isWretchError, validate, wretch } from '@feathr/rachis';

function ResetPasswordPage(): JSX.Element {
  const [newPw, setNewPw] = useState('');
  const { t } = useTranslation();
  const [confirmPw, setConfirmPw] = useState('');
  const [passwordValidate, setPasswordValidate] = useDebounce<boolean>(false);
  const [confirmPasswordValidate, setConfirmPasswordValidate] = useDebounce<boolean>(false);
  const refs = [useRef<HTMLInputElement | null>(null), useRef<HTMLInputElement | null>(null)];

  function validateForm(): Record<'password' | 'confirmPassword', string[]> {
    return (
      validate(
        { password: newPw, confirmPassword: confirmPw },
        {
          password: {
            format: {
              pattern: /(?!^\d+$)^.+$/,
              message: 'must contain a letter or special character.',
            },
            length: { minimum: 15, message: 'must be at least 15 characters.' },
            presence: { allowEmpty: false, message: 'may not be empty.' },
          },
          confirmPassword: {
            equality: 'password',
          },
        },
        { format: 'grouped' },
      ) || {}
    );
  }

  async function onClickReset(): Promise<void> {
    const response = await wretch<ILogInResponse>(
      `${BLACKBOX_URL}reset-password/${Session.token}`,
      {
        method: 'POST',
        body: JSON.stringify({ password: newPw, password2: confirmPw }),
        headers: { 'Content-Type': 'application/json' },
      },
    );
    if (isWretchError(response)) {
      // TODO: Update error messages to be more descriptive.
      toast(t('Something went wrong.\n{{- error}}', { error: response.error.message }), {
        type: ToastType.ERROR,
      });
      // eslint-disable-next-line no-console
      console.error(response.error.message);
      throw new Error(t('Something went wrong.'));
    }

    toast(t('Password updated.'), { type: ToastType.SUCCESS });
    setNewPw('');
    setConfirmPw('');
    setPasswordValidate(false);
    setConfirmPasswordValidate(false);
    refs.forEach((ref) => {
      if (ref.current) {
        ref.current.value = '';
      }
    });
  }

  function handlePasswordChange(newValue?: string): void {
    setPasswordValidate(true);
    setNewPw(newValue ?? '');
  }

  function handleConfirmChange(newValue?: string): void {
    setConfirmPasswordValidate(true);
    setConfirmPw(newValue ?? '');
  }

  const errors = validateForm();

  return (
    <Page title={t('Reset Password')}>
      <Form
        actions={
          <ButtonValid
            errors={flattenErrors(errors)}
            key={'reset'}
            name={'reset_password'}
            onClick={onClickReset}
            type={'primary'}
          >
            {t('Reset password')}
          </ButtonValid>
        }
        label={t('Reset password')}
      >
        <p>
          {t(
            'Must be at least 15 characters in length and contain at least one letter or special character.',
          )}
        </p>
        <Fieldset>
          <Input
            helpPlacement={'bottom'}
            helpText={t(
              'Must be at least 15 characters in length and contain at least one letter or special character.',
            )}
            label={t('Enter a new password')}
            name={'password'}
            onChange={handlePasswordChange}
            ref={refs[0]}
            type={'password'}
            validationError={passwordValidate && errors.password ? errors.password : undefined}
            value={newPw}
          />
          <Input
            label={t('Confirm New Password')}
            name={'confirm_password'}
            onChange={handleConfirmChange}
            ref={refs[1]}
            type={'password'}
            validationError={
              confirmPasswordValidate && errors.confirmPassword ? errors.confirmPassword : undefined
            }
            value={confirmPw}
          />
        </Fieldset>
      </Form>
    </Page>
  );
}

export default ResetPasswordPage;
