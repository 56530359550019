import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Flight } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { ConfirmModalV1, EAlertV2Type, toast } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

interface IProps {
  flight: Flight;
  legId: number;
  onClose: () => void;
}

function LegModalArchiveCampaigns({ flight, legId, onClose }: IProps): JSX.Element {
  const { Campaigns } = useContext(StoresContext);
  const { t } = useTranslation();

  const legs = flight.get('legs');

  async function archiveCampaigns() {
    const leg = legs[legId];
    await Campaigns.bulk(leg.campaigns, { is_archived: true, state: CampaignState.Archived });
    Campaigns.refreshApiCache();
    onClose();
    toast(`Successfully archived all campaigns in ${legs[legId].name}.`, {
      type: 'success',
    });
  }

  const message = (
    <Trans t={t}>
      Are you sure you want to archive all campaigns in leg <em>{legs[legId].name}</em>?
    </Trans>
  );

  return (
    <ConfirmModalV1
      message={message}
      onClose={onClose}
      onConfirm={archiveCampaigns}
      t={t}
      title={t('Archive Campaigns')}
      type={EAlertV2Type.warning}
    />
  );
}

export default observer(LegModalArchiveCampaigns);
