import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Campaign } from '@feathr/blackbox';
import type { IPageProps } from '@feathr/extender/App/Page';
import Page from '@feathr/extender/App/Page';
import CampaignDatesAndChips from '@feathr/extender/components/CampaignDatesAndChips';

export interface IProps extends IPageProps {
  campaign: Campaign;
  children: React.ReactNode;
}

function CampaignPage({ campaign, children, ...props }: IProps): JSX.Element {
  return (
    <Page {...props} description={<CampaignDatesAndChips campaign={campaign} />} width={'wide'}>
      {children}
    </Page>
  );
}

export default observer(CampaignPage);
