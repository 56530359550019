import { faEye, faPenToSquare, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { UserRole } from '@feathr/blackbox';
import { ContextMenu, MenuItem } from '@feathr/components';
import { useAccount, useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import ArchiveRoleModal from './ArchiveRoleModal';
import ViewReadOnlyRoleModal from './ViewReadOnlyRoleModal';

interface IRoleOptionsProps {
  role: UserRole;
}

function RoleOptions({ role }: Readonly<IRoleOptionsProps>): JSX.Element {
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isViewReadOnlyRoleOpen, toggleViewReadOnlyRoleOpen] = useToggle(false);
  const account = useAccount();

  const localUrl = useLocalUrl();
  const isDefault = role.isDefault;

  const { t } = useTranslation();

  function handleClose(): void {
    toggleArchiveModalOpen();
    account.reload();
  }

  return (
    <>
      <ContextMenu name={'role-options'}>
        {isDefault && (
          <MenuItem
            name={'view-role'}
            onClick={toggleViewReadOnlyRoleOpen}
            prefix={<FontAwesomeIcon icon={faEye} />}
          >
            {t('View')}
          </MenuItem>
        )}
        <MenuItem
          disabled={isDefault}
          link={!isDefault ? localUrl(role.getItemUrl('edit')) : undefined}
          name={'edit-role'}
          prefix={<FontAwesomeIcon icon={faPenToSquare} />}
        >
          {t('Edit')}
        </MenuItem>
        <MenuItem
          disabled={isDefault}
          name={'delete-role'}
          onClick={toggleArchiveModalOpen}
          prefix={<FontAwesomeIcon icon={faTrash} />}
        >
          {t('Delete')}
        </MenuItem>
      </ContextMenu>
      {isArchiveModalOpen && <ArchiveRoleModal onClose={handleClose} role={role} />}
      {isViewReadOnlyRoleOpen && (
        <ViewReadOnlyRoleModal onClose={toggleViewReadOnlyRoleOpen} role={role} />
      )}
    </>
  );
}

export default observer(RoleOptions);
