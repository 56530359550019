import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { EIntegrationSyncRule } from '@feathr/blackbox';
import { Chip } from '@feathr/components';
import {
  iMISSyncRuleTranslationMap,
  templateTypeIconSyncRule,
} from '@feathr/extender/styles/integrations';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IiMISSyncRuleProps {
  syncRule: EIntegrationSyncRule;
}

function ImisSyncRuleChip({ syncRule }: IiMISSyncRuleProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Chip prefix={<FontAwesomeIcon icon={templateTypeIconSyncRule(syncRule)} />}>
        {iMISSyncRuleTranslationMap(t, syncRule)}
      </Chip>
    </>
  );
}

export default ImisSyncRuleChip;
