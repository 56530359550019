import { ObjectId } from 'bson';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  ICustomField,
  IiMISContactMapping,
  ImisIntegration,
  TiMISContactMappingKey,
  TIntegrationPersonAttributes,
} from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import { toast } from '@feathr/components';
import { iMISContactFieldTranslationMap } from '@feathr/extender/styles/integrations';

import ImisCustomFieldSelect from '../ImisCustomFieldSelect';

interface IProps {
  value?: TiMISContactMappingKey;
  original: IiMISContactMapping;
  integration: ImisIntegration;
}

function ImisSyncContactSelect({ original, integration }: IProps): JSX.Element {
  const { t } = useTranslation();

  async function handleOnSelectSingle(option: ICustomField): Promise<void> {
    const contactField = iMISContactFieldTranslationMap(t, original.key);
    const fieldsMapped = t('{{contactField}} to {{feathrField}}', {
      contactField,
      feathrField: option.u_key,
    });
    const defaultField = !ObjectId.isValid(option.id as string)
      ? (option.id as TIntegrationPersonAttributes)
      : undefined;
    const customField = ObjectId.isValid(option.id as string) ? option.id : undefined;
    try {
      // TODO: Refactor to use ContactMapping.patch() as part of #2651.
      await integration.updateContactMapping({
        mapping: original.id,
        defaultField: defaultField,
        customField: customField,
      });

      toast(t('Successfully mapped {{fieldsMapped}}.', { fieldsMapped }), {
        type: ToastType.SUCCESS,
      });

      // TODO: Hard reloading will not be necesary with the implemention #2651.
      window.location.reload();
    } catch (error) {
      toast(t('Failed to map {{fieldsMapped}}. Try again. {{error}}', { fieldsMapped, error }), {
        type: ToastType.ERROR,
      });
    }
  }

  async function handleOnClear(): Promise<void> {
    const fieldUnmapped = iMISContactFieldTranslationMap(t, original.key);
    try {
      // TODO: Refactor to use ContactMapping.patch() as part of #2651.
      await integration.updateContactMapping({
        mapping: original.id,
        defaultField: null,
        customField: null,
      });

      toast(t('Successfully unmapped {{fieldUnmapped}}.', { fieldUnmapped }), {
        type: ToastType.SUCCESS,
      });

      // TODO: Hard reloading will not be necesary with the implemention #2651.
      window.location.reload();
    } catch (error) {
      toast(t('Failed to unmap {{fieldUnmapped}}. Try again. {{error}}', { fieldUnmapped }), {
        type: ToastType.ERROR,
      });
    }
  }

  return (
    <ImisCustomFieldSelect
      contexts={[FieldCollection.Person]}
      disabled={!original.editable}
      integration={integration}
      isClearable={true}
      // Set the portal target to the document body so the dropdown is not clipped by overflow
      menuPortalTarget={document.body}
      name={`imis-sync-contact-${original.id}`}
      object={original}
      onClear={handleOnClear}
      onSelectSingle={handleOnSelectSingle}
      placeholder={t('Select a Feathr field...')}
    />
  );
}

export default observer(ImisSyncContactSelect);
