import type { TFunction } from 'i18next';

import { EConnectionStatus } from '@feathr/blackbox';

interface IConnectionInfo {
  theme: string;
  text: string;
}

export function connectionStatusInfo(
  status: EConnectionStatus,
  t: TFunction,
  dateIntegrated: string,
): IConnectionInfo {
  const connectionStatusMap: Record<EConnectionStatus, IConnectionInfo> = {
    [EConnectionStatus.connected]: {
      theme: 'green',
      text: t('Connected on {{-dateIntegrated}}', { dateIntegrated }),
    },
    [EConnectionStatus.disconnected]: {
      theme: 'default',
      text: t('Not connected'),
    },
    [EConnectionStatus.pending]: {
      theme: 'yellow',
      text: t('Pending invitation'),
    },
  };

  return connectionStatusMap[status];
}
