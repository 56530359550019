import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { Button, ButtonValid, CardContent, CardHeader, CardV2, Form } from '@feathr/components';
import ConsentCapture from '@feathr/extender/components/ConsentCapture';
import { useUser } from '@feathr/extender/state';
import { flattenErrors, moment, TimeFormat } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import EmailCampaignSegments from '../EmailCampaignSegments';
import PinpointEmailCampaignAudienceSummary from '../PinpointEmailCampaignAudienceSummary';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
  onNext: () => void;
  onPrev: () => void;
  disabled: boolean;
}

export function validateStepThree(campaign: PinpointEmailBaseCampaign): TValidateGrouped {
  return campaign.validate(['segments', 'consent.has_consent'], false, 'grouped').errors;
}

function SmartPinpointEmailCampaignStepThree({
  campaign,
  disabled,
  onNext,
  onPrev,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();
  const stats = campaign.get('total_stats');

  function handleChangeConsent(newValue = false): void {
    if (newValue) {
      campaign.set({
        consent: {
          user: user.id,
          has_consent: true,
          date_consented: moment.utc().format(TimeFormat.isoDateTime),
        },
      });
    } else {
      campaign.set({
        consent: {
          has_consent: false,
        },
      });
    }
  }

  const NextStepButton = observer(() => {
    const validationErrors = validateStepThree(campaign);
    const errors = [...flattenErrors(validationErrors)];
    return (
      <ButtonValid errors={errors} name={'next_step'} onClick={onNext}>
        {t('Next')}
      </ButtonValid>
    );
  });

  return (
    <>
      <Form
        actions={[
          <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
            {t('Previous')}
          </Button>,
          <NextStepButton key={'next'} />,
        ]}
        description={
          <Trans t={t}>
            <p>
              Choose or create a group of people that will receive this email. Anyone in this group
              who performs the trigger during your send window (which you'll setup on the next step)
              will receive your email as a response to that trigger. Otherwise, they will receive
              your email at the end of the send window.
            </p>
          </Trans>
        }
        label={t('Edit Campaign: Audience')}
      >
        <EmailCampaignSegments campaign={campaign} disabled={disabled} step={3} />
        {!!campaign.get('segments', []).length && (
          <CardV2 width={'full'}>
            <CardHeader title={t('Consent')} />
            <CardContent>
              <ConsentCapture
                alertDescription={t(
                  'I understand that if my campaigns exhibit abnormally high bounce or complaint rates, it may result in my account being placed under temporary review or suspension.',
                )}
                alertTitle={t(
                  'I attest that I am sending this email to recipients who have signed up and specifically requested to receive email about this topic from me or my organization.',
                )}
                disabled={disabled}
                label={t(
                  'Please type "AGREE" in the box below to verify the above statement (case-sensitive).',
                )}
                onChange={handleChangeConsent}
                value={campaign.get('consent', { has_consent: false }).has_consent}
              />
            </CardContent>
          </CardV2>
        )}

        {!!stats.num_targeted &&
          [CampaignState.Published, CampaignState.Stopped].includes(campaign.get('state')) &&
          campaign.isAfterDateSendEnd && <PinpointEmailCampaignAudienceSummary stats={stats} />}
      </Form>
    </>
  );
}

export default observer(SmartPinpointEmailCampaignStepThree);
