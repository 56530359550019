import { faCheck, faFloppyDisk, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import type { WithT } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import type { ITooltipProps, TActionButtonType } from '@feathr/components';
import { ButtonValid, Icon } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';
import { flattenError } from '@feathr/hooks';
import type { IBaseAttributes, IValidation, Model } from '@feathr/rachis';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import { save } from './SaveCampaignButton.utils';

export interface ISaveProps extends WithT {
  accountId: string;
  campaign: Campaign;
  childModels: Array<Model<IBaseAttributes>>;
  grandchildModels?: Array<Model<IBaseAttributes>>;
  name?: string;
  preSave?: (campaign: Campaign) => Promise<void> | void;
  postSave?: (campaign: Campaign, stateChanged?: boolean) => Promise<void> | void;
  shouldChangeState?: boolean;
  type?: TActionButtonType;
  tooltipPosition?: ITooltipProps['position'];
}

interface ISaveButtonProps extends Omit<ISaveProps, 't' | 'accountId'> {
  disabled?: boolean;
  showIcon?: boolean;
  validate?: () => ICampaignValidationErrors | IValidation<ICampaignValidationErrors>;
}

function SaveCampaignButton({
  campaign,
  childModels,
  grandchildModels = [],
  disabled,
  name,
  preSave,
  postSave,
  shouldChangeState = false,
  showIcon = false,
  validate,
  tooltipPosition,
  type = 'secondary',
}: ISaveButtonProps): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();
  const state = campaign.get('state', CampaignState.Draft);

  function getLabel(): string {
    if (state === CampaignState.Draft) {
      return shouldChangeState ? t('Publish') : t('Save as draft');
    } else if (
      [CampaignState.Published, CampaignState.Publishing, CampaignState.Erroring].includes(state)
    ) {
      return shouldChangeState ? t('Stop campaign') : t('Save changes');
    }
    return shouldChangeState ? t('Publish') : t('Save changes');
  }

  function handleSave(): Promise<void> {
    return save({
      campaign,
      childModels,
      grandchildModels,
      preSave,
      postSave,
      shouldChangeState,
      t,
      accountId: account.id,
    });
  }

  /*
   * shouldChangeState = true
   *   when draft or stopped => publish
   *   when published or publishing (what about erroring?) => stop campaign
   *
   * shouldChangeState = false
   *   when draft => save draft
   *   when stopped => save changes
   *   when published or publishing (what about erroring?) => save changes
   *
   * Stop button will be disabled for all campaigns past their end date.
   * Save should be disabled: on draft, stopped, or published - when no changes
   * from previous campaign state are present.
   */

  const isPublishing = state === CampaignState.Publishing;
  const isPublishedOrPublishing =
    [CampaignState.Published, CampaignState.Erroring].includes(state) || isPublishing;
  const isErroring = state === CampaignState.Erroring;

  let buttonTheme = type;
  if (shouldChangeState) {
    buttonTheme = isPublishedOrPublishing || isErroring ? 'primary' : 'success';
  }

  const childModelsDirty = [...childModels, ...grandchildModels].some(({ isDirty }) => isDirty);
  // If no changes, disable save button.
  const isSaveButtonNoChanges = !shouldChangeState && !campaign.isDirty && !childModelsDirty;
  // If published single send campaign is past start date, disable stop button.
  const isSingleSendPastStartDate =
    isPublishedOrPublishing &&
    shouldChangeState &&
    campaign.get('_cls') === CampaignClass.PinpointEmail &&
    campaign.isPastStartDate;
  // If campaign is past end date, disable stop/publish button.
  const isPublishOrStopButtonPastEndDate = shouldChangeState && campaign.isPastEndDate;

  // Allow force disabled, else let validation deal with it.
  let isDisabled: boolean | undefined = disabled;
  if (
    isSaveButtonNoChanges ||
    isPublishing ||
    isPublishOrStopButtonPastEndDate ||
    isSingleSendPastStartDate
  ) {
    isDisabled = true;
  }

  /**
   * Type guard for IValidation
   *
   * When using async validation we need access to additional properties, such
   * as isPending. In that case the validate function passed in should return a
   * IValidation object.
   */
  function isValidation(
    obj?: ICampaignValidationErrors | IValidation<ICampaignValidationErrors>,
  ): obj is IValidation<ICampaignValidationErrors> {
    return !!obj && 'errors' in obj;
  }

  const validation = validate?.();
  const errors = isValidation(validation) ? validation?.errors : validation;
  const isLoading = isValidation(validation) && validation.isPending;

  const iconMap = {
    [CampaignState.Draft]: faPenToSquare,
    [CampaignState.Published]: faFloppyDisk,
    [CampaignState.Publishing]: faFloppyDisk,
    [CampaignState.Erroring]: faFloppyDisk,
    [CampaignState.Stopped]: faCheck,
  };

  return (
    <ButtonValid
      disabled={isDisabled}
      errors={flattenError(errors)}
      isLoading={isLoading}
      name={name}
      onClick={handleSave}
      prefix={showIcon ? <Icon icon={iconMap[state]} /> : undefined}
      tooltip={
        isPublishOrStopButtonPastEndDate
          ? t(
              'This campaign can no longer be published or stopped because it is past the end date.',
            )
          : undefined
      }
      tooltipPosition={tooltipPosition}
      type={buttonTheme}
    >
      {getLabel()}
    </ButtonValid>
  );
}

export default observer(SaveCampaignButton);
