import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { IiMISContactMapping, ImisIntegration } from '@feathr/blackbox';
import type { ISorted } from '@feathr/components';
import { Section, Table, Toolbar } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

import ImisAddMapping from '../ImisAddMapping';
import iMISSyncContactColumns from './ImisSyncContactColumns';

import * as styles from './ImisSyncContacts.css';

interface IProps {
  integration: ImisIntegration;
}

function ImisSyncContacts({ integration }: IProps): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const [sort, setSort] = useState<ISorted[]>([{ id: 'imis_field' }]);
  const [mappings, setMappings] = useState<IiMISContactMapping[] | null>(null);

  useEffect(() => {
    async function getMappings(): Promise<void> {
      // TODO: Refactor to use ContactMappings.list() as part of #2651.
      const mappings = await integration.getContactMappings();
      setMappings(mappings);
    }

    getMappings();
  }, [integration]);

  let mappingItems: IiMISContactMapping[] | null = null;

  if (mappings !== null) {
    sort.forEach((s) => {
      if (s.id === 'feathr_field' || 'imis_field') {
        mappingItems = [...mappings].sort((a, b) =>
          s.desc ? b['id'].localeCompare(a['id']) : a['id'].localeCompare(b['id']),
        );
      } else if (s.id === 'sync_rule') {
        mappingItems = [...mappings].sort((a, b) =>
          s.desc
            ? b['sync_rule'].localeCompare(a['sync_rule'])
            : a['sync_rule'].localeCompare(b['sync_rule']),
        );
      }
    });
  }

  // Since we are using items in the Table component, we must implement sorting ourselves.

  const description = (
    <Trans t={t}>
      <p>
        Contact mappings are the link between iMIS and Feathr. They tell use which contact data to
        sync from iMIS to Feathr, and exactly where and how to sync it.{' '}
        <a href={'https://help.feathr.co/hc/en-us/articles/16781103223319'}>
          Learn more about mapping iMIS to Feathr.
        </a>
      </p>
      <p>
        If a field is not mapped below it won't be synced.{' '}
        <Link target={'_blank'} to={localUrl(`/data/custom-fields/add`)} type={'link'}>
          You can add more custom fields on this page.
        </Link>
      </p>
    </Trans>
  );

  return (
    <Section description={description} layout={'vertical'} title={t('Contact mappings')}>
      <Toolbar>
        <ImisAddMapping integration={integration} />
      </Toolbar>
      <Table<IiMISContactMapping>
        className={styles.table}
        columns={iMISSyncContactColumns({ t, integration })}
        idKey={'id'}
        isLoading={mappings === null}
        isPaginated={false}
        items={mappingItems ?? []}
        noDataText={t('No contact mappings have been created.')}
        onSortChange={setSort}
        sort={sort}
      />
    </Section>
  );
}

export default observer(ImisSyncContacts);
