import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { SingleValueProps } from 'react-select';
import { components } from 'react-select';

import { Segment } from '@feathr/blackbox';

import type { IMenuOption, TStat } from '../SegmentOption';

import * as styles from './SegmentSingleValue.css';

type TSingleValueProps = SingleValueProps<Segment | IMenuOption>;

interface IDataProps extends TSingleValueProps {
  selectProps: {
    stat?: TStat;
  };
  data: Segment;
}

function SegmentSingleValue(props: TSingleValueProps) {
  const { t } = useTranslation();

  const {
    data,
    selectProps: { stat },
  } = props as IDataProps;

  const labels: Record<TStat, string> = {
    num_cookies_total: t('Reachable people'),
    num_crumbs_total: t('Breadcrumbs'),
    num_emails_total: t('Unique email addresses'),
  };

  return (
    <components.SingleValue {...props}>
      <div className={styles.root}>
        <span className={styles.label}>
          <Observer>{() => <>{data.name}</>}</Observer>
        </span>
        {data instanceof Segment && (
          <dl className={styles.data}>
            <dt>{t('People')}</dt>
            <dd>
              <Observer>
                {() => <>{numeral(data.get('stats').num_users_total || 0).format('0,0')}</>}
              </Observer>
            </dd>
            {!!stat && (
              <>
                <dt>{labels[stat]}</dt>
                <dd>
                  <Observer>
                    {() => <>{numeral(data.get('stats')[stat] || 0).format('0,0')}</>}
                  </Observer>
                </dd>
              </>
            )}
          </dl>
        )}
      </div>
    </components.SingleValue>
  );
}

export default SegmentSingleValue;
