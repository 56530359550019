import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Targetable, Targeting } from '@feathr/blackbox';
import { Categories, TargetableClass } from '@feathr/blackbox';
import { Button, Card, Fieldset, Select, Tooltip } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import AffinityTargetingDataSelect from './AffinityTargetingDataSelect/AffinityTargetingDataSelect';

import * as styles from './AffinityTargeting.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

interface ICategoryOption {
  id: number;
  name: string;
}

interface ICategoryGroups {
  label: string;
  options: ICategoryOption[];
}

function AffinityTargeting({ campaign, targeting, onRemove }: IProps): JSX.Element {
  const { Targetables } = useContext(StoresContext);
  const { t } = useTranslation();

  let targetable: Targetable;
  const targetableId = targeting.get('target_data');
  if (targetableId) {
    targetable = Targetables.get(targetableId);
  } else {
    targetable = Targetables.create({
      _cls: TargetableClass.affinity,
      name: t('Affinity Targeting'),
      category_id: 468,
      partner: campaign.get('parent_kind') === 'partner' ? campaign.get('parent') : undefined,
    });
    targeting.set({ target_data: targetable.get('id') });
  }

  const handleRemoveSegment = useCallback(() => onRemove(targeting), [onRemove, targeting]);

  const handleCategorySelect = useCallback(
    (option: ICategoryOption | ICategoryGroups) =>
      targetable.set({ category_id: (option as ICategoryOption).id }),
    [targetable],
  );

  const selectedGroup = Categories.find(
    (cat: ICategoryGroups) =>
      !!cat.options.find((opt: ICategoryOption) => opt.id === targetable.get('category_id')),
  );
  const selectedCategory =
    selectedGroup &&
    selectedGroup.options.find((cat: ICategoryOption) => cat.id === targetable.get('category_id'));

  return (
    <Card
      actions={[
        <Tooltip key={'remove'} title={t('Remove')}>
          <Button
            className={styles.cardButton}
            name={'remove_segment'}
            onClick={handleRemoveSegment}
            type={'naked'}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Tooltip>,
      ]}
    >
      <Fieldset>
        <Select<ICategoryOption | ICategoryGroups>
          helpText={t('Choose an affinity category to narrow your search.')}
          label={t('Affinity category')}
          name={'category_select'}
          onSelectSingle={handleCategorySelect}
          options={Categories}
          required={true}
          value={selectedCategory}
        />
        <AffinityTargetingDataSelect campaign={campaign} targeting={targeting} />
      </Fieldset>
    </Card>
  );
}

export default observer(AffinityTargeting);
