import type { Campaign } from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';

import type { IUseCampaignModalsReturn } from './useCampaignModals';
import useCampaignModals from './useCampaignModals';
import useCampaignText from './useCampaignText';

interface ICampaignText {
  disableRepublish: string | undefined;
  disableClone: string | undefined;
  disableStop: string | undefined;
}

interface IUseCampaignStateReturn extends IUseCampaignModalsReturn {
  allowed: {
    /**
     * The logic associated with whether the stop button should be displayed for the
     * current campaign because the campaign lacks the ability to be stopped. Campaigns
     * that have the ability to be disabled/stopped should never have this enabled and
     * should instead modify the logic to disable the button based on specific conditions.
     */
    stop: boolean;
    delete: boolean;
  };
  /**
   * The logic associated with when to disable the following buttons, generally due to
   * state and/or date constraints.
   */
  disabled: {
    stop: boolean;
    republish: boolean;
    clone: boolean;
  };
  /**
   * Whether or not the campaign has a wizard associated with it. Used to help determine
   * when to show the edit button for the wizard.
   */
  hasWizard: boolean;
  text: ICampaignText;
}

/**
 * The useCampaign hook allows ups to keep track of important campaign state, types, modals and text.
 * This allows us to use consistent patterns and language when it comes to managing campaigns. It can
 * serve as a single source of truth for which campaigns and under what circumstances they can be stopped,
 * re-enabled, etc.
 *
 * This lets us stop checking campaign state across the app and instead return contextual allow, disable,
 * etc logic that can be shared across the app, to maintain consistency. We'll slowly roll this out as
 * we touch different parts of the app.
 */
function useCampaign({ campaign }: { campaign: Campaign }): IUseCampaignStateReturn {
  // Get all the campaign-based text
  const text = useCampaignText();

  // Get all the modal states and handlers to pass to the return
  const { modalStates, modalHandlers } = useCampaignModals();

  const state = campaign.get('state');
  const type = campaign.get('_cls');
  const { isPastEndDate, isPastStartDate } = campaign;

  // General campaign state
  const isDraft = state === CampaignState.Draft;
  const isStopped = state === CampaignState.Stopped;
  const isEnabled = state === CampaignState.Published;
  const isPublishing = state === CampaignState.Publishing;

  // General campaign type
  const isConversation = type === CampaignClass.Conversation;
  const isLandingPage = type === CampaignClass.LandingPage;
  // This is a deprecated lookalike type. The current lookalike type is SeedSegmentCampaign
  const isOldLookalike = type === CampaignClass.Lookalike;

  // Campaign based state
  const isStoppedEmail =
    (type === CampaignClass.AutoPinpointEmail || type === CampaignClass.SmartPinpointEmail) &&
    isStopped;
  const isSingleSendPastStartDate =
    (isPublishing || isEnabled) && type === CampaignClass.PinpointEmail && isPastStartDate;
  const hasWizard = !isLandingPage && !isConversation;

  // Logic to allow the display of specific buttons for a campaign
  const allowStop = !isLandingPage && isEnabled;
  const allowDelete = isDraft;

  // Disable buttons
  const disableStop = isPastEndDate || isSingleSendPastStartDate;
  const disableRepublish = isPastEndDate || isStoppedEmail;
  const disableClone = isOldLookalike;

  // Determine the tooltip text displayed to the user based on the current state of the campaign
  const getTooltipText = (): {
    disableRepublishTooltip?: string;
    disableStopTooltip?: string;
    disableCloneTooltip?: string;
  } => {
    let disableRepublishTooltip: string | undefined = undefined;
    let disableStopTooltip: string | undefined = undefined;
    let disableCloneTooltip: string | undefined = undefined;

    if (isPastEndDate) {
      disableRepublishTooltip = text.disableStopOrRepublishPastEndDate;
      disableStopTooltip = text.disableStopOrRepublishPastEndDate;
    }

    if (isStoppedEmail) {
      disableRepublishTooltip = text.disableEmailRepublish;
    }

    if (isSingleSendPastStartDate) {
      disableStopTooltip = text.disableSingleSendStopStartEndDate;
    }

    if (disableClone) {
      disableCloneTooltip = text.disableLookalikeClone;
    }

    return { disableRepublishTooltip, disableStopTooltip, disableCloneTooltip };
  };

  // Get the tooltip text for the various states
  const { disableRepublishTooltip, disableStopTooltip, disableCloneTooltip } = getTooltipText();

  return {
    allowed: {
      stop: allowStop,
      delete: allowDelete,
    },
    disabled: {
      stop: disableStop,
      clone: disableClone,
      republish: disableRepublish,
    },
    hasWizard,
    modalHandlers,
    modalStates,
    text: {
      disableRepublish: disableRepublishTooltip,
      disableStop: disableStopTooltip,
      disableClone: disableCloneTooltip,
    },
  };
}

export default useCampaign;
