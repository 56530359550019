import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { CampaignClass } from '@feathr/blackbox';
import { CampaignLabelMap } from '@feathr/blackbox';
import { CardV2 as Card } from '@feathr/components';
import { CampaignIcon } from '@feathr/extender/components/CampaignIcons';

import { useCampaignDescriptionMap } from '.';

import * as styles from './CardContent.css';

interface ICardContentProps {
  cls: CampaignClass;
  disabledEmailCampaign: boolean;
  usageText?: string | null;
}

function CardContent({ cls, disabledEmailCampaign, usageText }: ICardContentProps): JSX.Element {
  const { t, campaignDescriptionMap } = useCampaignDescriptionMap();

  return (
    <Card.Content contentClassName={styles.root} padding={'relaxed'}>
      <CampaignIcon cls={cls} hover={false} size={48} />
      <h4 className={styles.title}>{CampaignLabelMap.get(cls)}</h4>
      <p className={styles.description}>{campaignDescriptionMap.get(cls)}</p>
      {usageText && <p>{usageText}</p>}
      {disabledEmailCampaign && (
        <p className={styles.error}>
          {t('Your Feathr account is suspended and cannot publish any new email campaigns.')}
        </p>
      )}
    </Card.Content>
  );
}

export default observer(CardContent);
