import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, ICampaignAttributes, IMonetizationAttributes } from '@feathr/blackbox';
import { Button, CardContent, CardV2, Fieldset, Form, NumberInput } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';
import type { Attributes, TValidateGrouped } from '@feathr/rachis';

import NextStepButton from './NextStepButton';

import * as styles from './StepValue.css';

export interface IStepValueBaseProps<A extends ICampaignAttributes & IMonetizationAttributes> {
  onNext: () => void;
  campaign: Campaign<A>;
}

interface IStepValueProps<A extends ICampaignAttributes & IMonetizationAttributes>
  extends IStepValueBaseProps<A> {
  onPrev: () => void;
}

interface IStepValueErrors extends TValidateGrouped {
  monetization_value?: string[];
}

export function validateStepValue<A extends ICampaignAttributes & IMonetizationAttributes>(
  campaign: Campaign<A>,
): IStepValueErrors {
  return campaign.validate(['monetization_value'], false, 'grouped').errors;
}

function StepValue<A extends ICampaignAttributes & IMonetizationAttributes>({
  campaign,
  onNext,
  onPrev,
}: IStepValueProps<A>): JSX.Element {
  const { t } = useTranslation();
  const validationErrors = validateStepValue(campaign);

  const description = t(
    'What is the value to your organization of this sponsored email? Usually this is the amount of money a sponsor paid you to send it on their behalf. This is used to help you report on the overall revenue generated for your organization by sponsored campaigns launched in Feathr.',
  );

  function handleMonetizationValueChange(newValue?: number): void {
    // Because generics and union types are sometimes strange bedfellows, specifically cast the patch.
    campaign.set({ monetization_value: newValue ?? 0 } as Partial<Attributes<Campaign<A>>>);
  }

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          Previous
        </Button>,
        <NextStepButton campaign={campaign} key={'next'} onNext={onNext} />,
      ]}
      description={description}
      label={t('Edit Campaign: Sponsor package value')}
    >
      <CardV2>
        <CardContent>
          <Fieldset>
            <NumberInput
              className={styles.budgetInput}
              label={t('Sponsor package value')}
              min={0}
              onChange={handleMonetizationValueChange}
              prefix={'$'}
              validationError={flattenErrors(validationErrors)}
              value={campaign.get('monetization_value')}
              wrapperClassName={styles.budgetInput}
            />
          </Fieldset>
        </CardContent>
      </CardV2>
    </Form>
  );
}

export default observer(StepValue);
