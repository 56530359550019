import type { IBaseAttributes, IListOptions, IListParams, ListResponse } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

export interface ICategoryConversionStats {
  category: string;
  num: number;
  roi: number;
}

export interface IGoalConversionStats {
  goal_id: string;
  num: number;
  roi: number;
  categories: ICategoryConversionStats[];
}

export interface ISegmentConversionStats {
  goal_segment_id: string;
  num: number;
  roi: number;
  goals: IGoalConversionStats[];
}

export interface IConversionAttributionModelStats {
  num: number;
  roi: number;
  segments_breakdown: ISegmentConversionStats[];
}

export interface IAttributions<T> {
  full?: T;
  linear?: T;
  positional?: T;
  temporal?: T;
  first_touch?: T;
  last_touch?: T;
}

export type TConversionStats = IAttributions<IConversionAttributionModelStats>;

export type TAttributionModel = keyof TConversionStats;

export function isAttributionModel(s: string): s is TAttributionModel {
  if (
    s === 'full' ||
    s === 'linear' ||
    s === 'positional' ||
    s === 'temporal' ||
    s === 'first_touch' ||
    s === 'last_touch'
  ) {
    return true;
  }
  return false;
}

export interface IStats {
  conversions?: TConversionStats;
  flavors: IFlavors;
  id?: string;
  num_clicks_active?: number;
  num_clicks_new?: number;
  num_conversions?: number;
  num_crumbs?: number;
  num_impressions_bid?: number;
  num_impressions_won?: number;
  num_segments?: number;
  num_users_active?: number;
  num_users_new?: number;
  spend: number;
  user_flavors?: Record<string, unknown>;
  // The following are for email campaigns only.
  clicks_per_person?: number;
  clicks_by_url?: {
    [hash: string]: IUrlClicks;
  };
  email_click_through_rate?: number;
  email_open_rate?: number;
  hard_bounce_rate?: number;
  num_new_users_new_reachable?: number;
  num_not_sent?: number;
  num_persons_unsubscribed?: number;
  num_targeted?: number;
  opens_per_person?: number;
  successful_delivery_rate?: number;
  unique_deliveries?: number;

  // Google specific
  num_clicks?: number;
  num_views?: number;
  conversions_roi?: number;
}

interface IDailyStat {
  daily: number;
}

export type TTrafficConversionAttribution = Partial<IAttributions<number>>;

export interface ITrafficSource {
  views: number;
  sessions: number;
  conversions: TTrafficConversionAttribution;
}

export interface ITrafficSources {
  email: ITrafficSource;
  search: ITrafficSource;
  facebook: ITrafficSource;
  twitter: ITrafficSource;
  linkedin: ITrafficSource;
  youtube: ITrafficSource;
  tumblr: ITrafficSource;
  pinterest: ITrafficSource;
  reddit: ITrafficSource;
  feathr: ITrafficSource;
  other: ITrafficSource;
  [key: string]: ITrafficSource;
}

export interface ILocation {
  lat: number;
  lng: number;
  count: number;
}

export interface IHostCounts {
  [key: string]: number;
}

interface IDailyHost {
  daily: IHostCounts;
}

export interface IHosts {
  ad_view?: IDailyHost;
  ad_click?: IDailyHost;
  page_view?: IDailyHost;
  page_link_click?: IDailyHost;
  email_view?: IDailyHost;
  email_link_click?: IDailyHost;
}

export interface IFlavors {
  ad_click?: number;
  ad_view?: number;
  conversation_opened?: number;
  conversation_popped?: number;
  conversation_response?: number;
  dashboard_link_click?: number;
  dashboard_view?: number;
  email_link_click?: number;
  email_send?: number;
  email_view?: number;
  page_link_click?: number;
  page_view?: number;
  partner_message_link_click?: number;
  partner_message_send?: number;
  partner_message_view?: number;
  pinpoint_tracked_email_click?: number;
  pinpoint_tracked_email_complaint?: number;
  pinpoint_tracked_email_delivered?: number;
  pinpoint_tracked_email_hardbounce?: number;
  pinpoint_tracked_email_open?: number;
  pinpoint_tracked_email_rendering_failure?: number;
  pinpoint_tracked_email_send?: number;
  pinpoint_tracked_email_softbounce?: number;
  pinpoint_tracked_email_suppression?: number;
  zapier_imports?: number;
  zapier_updates?: number;
}

export interface IUrlClicks {
  url: string;
  clicks: number;
}

interface IDailyStatsMetaData {
  date: string;
  name: string;
  obj_id: string;
}

interface IGoogleStats {
  num_clicks: number;
  num_conversions: number;
  spend: number;
  ctr: number;
  cpc: number;
  cpm: number;
  num_views: number;
}

export interface IDailyStats extends IBaseAttributes {
  metadata: IDailyStatsMetaData;
  flavors?: IFlavors;
  user_flavors?: IFlavors;
  num_crumbs?: IDailyStat;
  num_users_active?: IDailyStat;
  num_users_new?: IDailyStat;
  num_cookies_total?: IDailyStat;
  num_emails_total?: IDailyStat;
  num_users_total?: IDailyStat;
  num_users_change?: IDailyStat;
  num_conversions?: IDailyStat;
  num_clicks?: IDailyStat;
  num_clicks_new?: IDailyStat;
  num_clicks_active?: IDailyStat;
  num_views?: IDailyStat;
  conversions?: TConversionStats;
  spend?: IDailyStat;
  coverages?: {
    email: number;
    cookies: number;
  };
  platforms?: {
    [s: string]: number;
  };
  locations?: ILocation[];
  hosts?: IHosts;
  traffic?: ITrafficSources;

  // Email campaigns
  clicks_per_person?: IDailyStat;
  email_open_rate?: IDailyStat;
  hard_bounce_rate?: IDailyStat;
  opens_per_person?: IDailyStat;
  successful_delivery_rate?: IDailyStat;
  unique_deliveries?: IDailyStat;

  // Account stats
  ad_click_through_rate?: number;
  email_click_through_rate?: number;
  google?: IGoogleStats;
  num_ad_clicks?: number;
  num_ad_views?: number;
  num_persons_created?: number;
  num_persons_created_by_data_integration?: number;
  num_persons_created_by_ad_campaign?: number;
  num_persons_created_by_import?: number;
  num_persons_created_by_pixel?: number;
  num_persons_reached?: number;
  num_persons_with_ad_clicks?: number;
  num_persons_with_ad_views?: number;
  num_persons_with_page_link_clicks?: number;
  num_persons_with_page_views?: number;
  num_persons_with_email_clicks?: number;
  num_persons_with_email_opens?: number;
  num_email_bounces_and_complaints?: number;
  num_email_clicks?: number;
  num_email_opens?: number;
  num_email_sends?: number;
}

export class DailyStat extends Model<IDailyStats> {
  public readonly className = 'DailyStat';

  public constraints = {};
}

/** Models that can be used to get statistical data. */
export type TStatsListModel =
  | 'account'
  | 'goal'
  | 'campaign'
  | 'creative'
  | 'event'
  | 'facebook_campaign'
  | 'facebook_creative'
  | 'flight'
  | 'segment';

export interface IStatsListParams extends IListParams<IDailyStat> {
  model: TStatsListModel;
}

export class DailyStats extends Collection<DailyStat> {
  public getModel(attributes: Partial<IDailyStats>): DailyStat {
    return new DailyStat(attributes);
  }

  public getClassName(): string {
    return 'stats';
  }

  public list(
    params: Partial<IStatsListParams>,
    options: Partial<IListOptions> = {},
    /** listId Use the useId() hook to generate a unique listID. */
    listId?: string,
  ): ListResponse<DailyStat> {
    return super.list(params, options, listId);
  }
}
