import type { JSX } from 'react';
import React, { forwardRef, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { IExportCampaignsProps } from '@feathr/blackbox';
import type { TModalV1ActionEvent } from '@feathr/components';
import { EmailInput, ModalV1, toast } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { flattenError } from '@feathr/hooks';
import { validate } from '@feathr/rachis';

export interface IExportCampaignsModalProps {
  onClose: (event: TModalV1ActionEvent) => void;
  params: Omit<IExportCampaignsProps, 'email'>;
}

const ExportCampaignsModal = forwardRef<HTMLDivElement, Readonly<IExportCampaignsModalProps>>(
  function ExportCampaignsModal({ onClose, params }, ref): JSX.Element {
    const { Campaigns } = useContext(StoresContext);
    const [email, setEmail] = useState<string | undefined>();
    const [emailHadFocus, setEmailHadFocus] = useState<boolean>(false);
    const { t } = useTranslation();

    function handleClose(event: TModalV1ActionEvent): void {
      // Clear data
      setEmail(undefined);
      setEmailHadFocus(false);
      // Close modal
      onClose(event);
    }

    function handleEmailBlur(): void {
      setEmailHadFocus(true);
    }

    function handleChangeEmail(newEmail?: string): void {
      setEmail(newEmail);
      // On first change start displaying validation errors
      setEmailHadFocus(true);
    }

    async function handleSend(event: TModalV1ActionEvent): Promise<void> {
      try {
        // Email is always present due to validation
        await Campaigns.exportCampaigns({ ...params, email: email! });
        toast(t('You will receive an email with a link to download the CSV file.'), {
          type: ToastType.SUCCESS,
        });
      } catch (error) {
        toast(t('An error occurred while exporting the data.'), {
          type: ToastType.ERROR,
        });
      }
      // Close modal and clear data
      handleClose(event);
    }

    const validationErrors = ((): string[] => {
      const emailConstraint = {
        presence: {
          allowEmpty: false,
          message: '^Email address cannot be empty.',
        },
        email: {
          message: '^Email address is invalid.',
        },
      };
      const error = validate.single(email, emailConstraint);
      return flattenError(error) ?? [];
    })();

    return (
      <ModalV1
        confirmButtonText={t('Send')}
        // Hide the tooltip if there are no validation errors
        confirmButtonTooltip={validationErrors.length ? validationErrors : undefined}
        confirmDisabled={validationErrors.length > 0}
        controlled={true}
        description={t(
          'Provide us with the email address where you would like to receive the data. You should then receive an email containing the requested data in .csv format.',
        )}
        onClose={onClose}
        onConfirm={handleSend}
        ref={ref}
        size={'sm'}
        t={t}
        title={t('Export Data')}
      >
        <EmailInput
          label={t('Email Address')}
          onBlur={handleEmailBlur}
          onChange={handleChangeEmail}
          t={t}
          validationError={emailHadFocus ? validationErrors : undefined}
          value={email}
        />
      </ModalV1>
    );
  },
);

export default ExportCampaignsModal;
