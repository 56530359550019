import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Button, MenuItem, ModalV1, toast } from '@feathr/components';
import { useRedirect } from '@feathr/hooks';
import type { Model } from '@feathr/rachis';

export interface IConfirmButtonProps {
  buttonClassName?: string;
  buttonType?: 'primary' | 'secondary' | 'naked' | 'icon-outlined';
  /** Used for button label and modal title */
  children?: string;
  description?: ReactNode;
  disabled?: boolean;
  element?: 'Button' | 'MenuItem';
  model: Model<any>;
  name?: string;
  onCancel?: () => Promise<void> | void;
  onConfirm?: () => Promise<void> | void;
  prefix?: ReactNode;
  redirect: string | (() => Promise<string | false> | string | false) | false;
  /** Add #step<x> fragment to redirect url */
  step?: number;
  /** Overrides modal title */
  title?: string;
}

function ConfirmButton({
  buttonClassName,
  buttonType,
  children,
  description,
  disabled,
  element = 'Button',
  model,
  name,
  onCancel,
  onConfirm,
  prefix,
  redirect,
  step,
  title,
  ...additionalProps
}: IConfirmButtonProps): JSX.Element {
  const history = useHistory();
  const [, setRedirect] = useRedirect();
  const [isModalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  function handleClick(): void {
    if (model.isDirty) {
      setModalVisible(true);
    } else {
      handleConfirm();
    }
  }

  async function handleConfirm(): Promise<void> {
    try {
      setModalVisible(false);
      if (onConfirm) {
        await onConfirm();
      }
      await model.patchDirty();

      const r = typeof redirect === 'function' ? await redirect() : redirect;
      if (r === false) {
        throw new Error(t('Redirect was aborted.'));
      }
      history.push(setRedirect(r, step ? `step${step}` : undefined));
    } catch (e) {
      toast(t('There was an error while saving.'), { type: 'error' });
    }
  }

  async function handleCancel(): Promise<void> {
    if (onCancel) {
      await onCancel();
    }
    setModalVisible(false);
  }

  return (
    <>
      {element === 'Button' ? (
        <Button
          className={buttonClassName}
          disabled={disabled}
          name={name}
          onClick={handleClick}
          prefix={prefix}
          type={buttonType}
          {...additionalProps}
        >
          {children}
        </Button>
      ) : (
        <MenuItem disabled={disabled} onClick={handleClick} prefix={prefix}>
          {children}
        </MenuItem>
      )}
      {isModalVisible && (
        <ModalV1
          confirmButtonText={model.get('state') === 'draft' ? t('Save as draft') : t('Save')}
          controlled={true}
          onClose={handleCancel}
          onConfirm={handleConfirm}
          size={'sm'}
          t={t}
          title={title || children}
        >
          {description}
        </ModalV1>
      )}
    </>
  );
}

export default observer(ConfirmButton);
