import type { JSX } from 'react';
import React from 'react';

import type { CampaignClass } from '@feathr/blackbox';

import type { IType, TCampaignSection } from '../CampaignTypeSelect';
import CampaignTypeSelectSection from '../CampaignTypeSelectSection';

export interface IProps {
  disabled?: boolean;
  hasSections?: boolean;
  onSelect: (type: CampaignClass) => void;
  types: IType[];
}

function CampaignTypeSelectSections({
  disabled,
  hasSections = true,
  onSelect,
  types,
}: IProps): JSX.Element {
  if (!hasSections) {
    return <CampaignTypeSelectSection disabled={disabled} onSelect={onSelect} types={types} />;
  }

  const sections: TCampaignSection[] = ['expand', 'cultivate', 'understand'];
  return (
    <>
      {sections.map((section) => {
        const filteredTypes = types.filter((type) => type.sections?.includes(section));
        if (!filteredTypes.length) {
          return null;
        }
        return (
          <CampaignTypeSelectSection
            disabled={disabled}
            key={section}
            onSelect={onSelect}
            section={section}
            types={filteredTypes}
          />
        );
      })}
    </>
  );
}

export default CampaignTypeSelectSections;
