import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import type { Event } from '@feathr/blackbox';
import { Button } from '@feathr/components';
import { StoresContext, useAccount, useLocalUrl, useRole, useUser } from '@feathr/extender/state';
import { useDebounce } from '@feathr/hooks';
import type { Attributes, IListParams } from '@feathr/rachis';

import type { ISwitcherItem } from '../Switcher';
import Switcher from '../Switcher';

import * as styles from './EventSwitcher.css';

interface IMenuOption {
  href: string;
  name: string;
}

interface IProps {
  className?: string;
}

interface IEventOption {
  id: string;
  name: string;
  logo: string;
}

export interface IEventFilters {
  is_archived__ne: true;
  name__icontains: string;
}

function EventSwitcher({ className }: IProps): JSX.Element {
  const { Events } = React.useContext(StoresContext);
  const user = useUser();
  const { hasProjects } = useRole();
  const account = useAccount();
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [search, setSearch] = useDebounce<string | undefined>();

  const listParams: IListParams<Attributes<Event>> = {
    filters: {
      is_archived__ne: true,
    } as IEventFilters,
    only: ['id', 'logo', 'name'],
    ordering: ['name'],
    pagination: {
      page: 0,
      page_size: 10,
    },
  };
  if (search) {
    listParams.filters!.name__icontains = search;
  }
  const events = Events.list(listParams);
  const selectedEvent = eventId ? Events.get(eventId) : undefined;
  const options = events.models.map(
    (event) =>
      ({
        id: event.id,
        name: event.get('name'),
        logo: event.get('logo'),
      }) as any,
  ) as Array<IEventOption | IMenuOption>;

  const canAddProject = account.canAddProject(user);

  if (account && user && !account.isPending && canAddProject && hasProjects) {
    const newEventOption: IMenuOption = {
      href: localUrl('/projects/add'),
      name: 'New Project',
    };
    options.unshift(newEventOption);
  }

  const url = new URL(window.location.href);

  const items: ISwitcherItem[] = events.models.map((e) => {
    const pathParts = url.pathname.split('/');
    pathParts.splice(2, 2, 'projects', e.id);
    return {
      id: e.id,
      logo: e.get('logo'),
      name: e.name,
      url: `${pathParts.slice(0, 4).join('/')}${
        pathParts.length <= 4 ? `${url.search}${url.hash}` : ''
      }`,
    };
  });
  if (!search && canAddProject && hasProjects) {
    items.unshift({
      id: 'add',
      logo: <FontAwesomeIcon icon={faPlus} />,
      name: 'New Project',
      url: localUrl('/projects/add'),
    });
  }

  function handleSetSearch(newValue: string | undefined): void {
    setInputValue(newValue);
    setSearch(newValue);
  }

  return (
    <Switcher
      className={classNames(styles.button, className)}
      // TODO: rename count attribute to more accurately represent compact status of dropdown content.
      count={2}
      currentItem={
        selectedEvent
          ? {
              id: selectedEvent.id,
              logo: selectedEvent.get('logo'),
              name: selectedEvent.name,
            }
          : undefined
      }
      dropdownClassName={styles.root}
      hasMore={!events.isPending && events.models.length > 0}
      hideSummary={true}
      isLoading={events.isPending}
      items={items}
      placeholder={'Select a project...'}
      search={inputValue}
      setSearch={handleSetSearch}
      suffix={
        <Button link={localUrl('/projects')} type={'link'}>
          View all projects
        </Button>
      }
    />
  );
}

export default observer(EventSwitcher);
