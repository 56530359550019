import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Card } from '@feathr/components';
import CreativeThumbnail from '@feathr/extender/components/CreativeThumbnail';
import { StoresContext } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';
import * as styles from './CreativeCard.css';

interface IProps {
  className?: string;
  id: string;
  isLoading?: false;
}

interface IPropsLoading {
  className?: string;
  id?: string;
  isLoading: true;
}

function CreativeCard({ className, id, isLoading }: IProps | IPropsLoading) {
  const { Creatives } = React.useContext(StoresContext);

  const skeletonCard = (
    <Card
      className={classNames(styles.root, skeleton, className)}
      cover={<>&#160;</>}
      title={' '}
    />
  );

  if (isLoading) {
    return skeletonCard;
  }

  // If isLoading === false then id is required.
  const creative = Creatives.get(id!);
  if (creative.isPending) {
    return skeletonCard;
  }

  return (
    <Card
      className={classNames(styles.root, className)}
      cover={<CreativeThumbnail creative={creative.toJS()} size={200} />}
      title={creative.name}
    />
  );
}

export default observer(CreativeCard);
