import { Observer } from 'mobx-react-lite';
import React from 'react';

import type { IStats } from '@feathr/blackbox';
import { TableColumnHeader } from '@feathr/components';

import type { IData, ITemplateRow } from './TemplatesTable';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const TemplateColumnClicks = {
  id: 'clicks',
  Header: TableColumnHeader({
    title: 'Clicks',
  }),
  sortable: false,
  className: tableStyles.cellRight,
  width: 80,
  accessor({ template }: IData) {
    return (
      <Observer>
        {() => {
          const stats: IStats = template.get('stats') || {};
          const flavors = stats.flavors || {};
          const adClick = flavors.ad_click || 0;
          const pageLinkClick = flavors.page_link_click || 0;
          const emailLinkClick = flavors.email_link_click || 0;
          return <>{adClick + pageLinkClick + emailLinkClick}</>;
        }}
      </Observer>
    );
  },
  Cell({ original }: ITemplateRow) {
    return (
      <Observer>
        {() => {
          const { template } = original;
          const stats: IStats = template.get('stats') || {};
          const flavors = stats.flavors || {};
          const adClick = flavors.ad_click || 0;
          const pageLinkClick = flavors.page_link_click || 0;
          const emailLinkClick = flavors.email_link_click || 0;
          return <>{(adClick + pageLinkClick + emailLinkClick).toLocaleString()}</>;
        }}
      </Observer>
    );
  },
};

export default TemplateColumnClicks;
