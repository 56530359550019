import { ObjectId } from 'bson';
import { Observer, observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import type { Goal } from '@feathr/blackbox';
import { Fieldset, Input, Select } from '@feathr/components';
import ConversionPixelCode from '@feathr/extender/App/DataConversionPixelsPage/ConversionPixelCode';
import ConversionModelRadios from '@feathr/extender/components/ConversionModelRadios/ConversionModelRadios';
import { StoresContext, useFlags } from '@feathr/extender/state';

interface IProps {
  goal: Goal;
  disabled?: boolean;
}

function AdvancedConversionTrackingSettings({ goal, disabled = false }: IProps) {
  const { Segments } = useContext(StoresContext);
  const flags = useFlags();

  const segments = Segments.list({
    filters: {
      is_conversion_segment: true,
    },
  });

  function createSegment() {
    segments.models.forEach((s) => {
      if (s.isEphemeral) {
        segments.models.remove(s);
      }
    });
    const segmentId = new ObjectId().toHexString();
    const newSegment = Segments.create({
      id: segmentId,
      read_only: true,
      lookback_mode: 'unbounded',
      predicates: [
        {
          attr_type: 'string',
          attr_against: 'seg_id',
          comparison: 'eq',
          kind: 'activity',
          value: segmentId,
        },
      ],
      is_conversion_segment: true,
    });
    segments.models.push(newSegment);
    goal.set({ name: newSegment.name, segment: segmentId });
  }

  const segmentId = goal.get('segment');
  const segment = segmentId ? Segments.get(segmentId) : undefined;

  function handleChangePixelName(newValue?: string) {
    segment!.set({ name: newValue });
    goal.set({ name: newValue });
  }

  return (
    <>
      <Fieldset>
        <Observer>
          {() => {
            const options = [
              { id: 'create', name: 'Create New Conversion Pixel' },
              ...segments.models.map((s) => s.toJS()),
            ];
            return (
              <Select
                disabled={disabled}
                isClearable={true}
                label={'Select or Create Conversion Pixel'}
                onSelectSingle={(option) => {
                  if (!option) {
                    goal.set({ name: '', segment: undefined });
                  } else if (option.id === 'create') {
                    createSegment();
                  } else {
                    goal.set({ name: option.name, segment: option.id });
                  }
                }}
                options={options}
                value={options.find((option) => option.id === goal.get('segment'))}
              />
            );
          }}
        </Observer>
        {segment && segment.isEphemeral && (
          <Observer>
            {() => (
              <Input
                helpText={'Describe what this pixel is capturing. E.g., "Event Registrations"'}
                label={'Conversion Pixel Name'}
                onChange={handleChangePixelName}
                type={'text'}
                value={segment.get('name')}
              />
            )}
          </Observer>
        )}
        {flags.hasMultiConversions && segment && <ConversionModelRadios goal={goal} />}
      </Fieldset>
      {segment && <ConversionPixelCode segment={segment} />}
    </>
  );
}

export default observer(AdvancedConversionTrackingSettings);
