import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';

import type { PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { Well } from '@feathr/components';
import EmailTemplateSelect from '@feathr/extender/components/EmailTemplateSelect';
import { StoresContext } from '@feathr/extender/state';

import EmailInformationInputs from '../../../EmailDetails/EmailInformationInputs';
import SenderInformationInputs from '../../../EmailDetails/SenderInformationInputs';

interface IEmailInputsProps {
  campaign: PinpointEmailBaseCampaign;
}

function EmailInputs({ campaign }: Readonly<IEmailInputsProps>): JSX.Element {
  const { Templates } = useContext(StoresContext);

  const templateId = campaign.get('template_id');
  const template = templateId ? Templates.get(templateId) : undefined;

  return (
    <Well layout={'vertical'} theme={'white'}>
      <EmailTemplateSelect campaign={campaign} template={template} />
      {/* TODO: Will need some validation attention in #3705 and will need to be reused within autosend campaigns in #3704 */}
      <SenderInformationInputs campaign={campaign} disabled={false} validationErrors={[]} />
      <EmailInformationInputs campaign={campaign} disabled={false} />
    </Well>
  );
}

export default observer(EmailInputs);
