import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { ImisIntegration } from '@feathr/blackbox';

import ImisConfigStepFive from '../../ImisConfigWizard/ImisConfigStepFive';
import ImisConfigStepFour from '../../ImisConfigWizard/ImisConfigStepFour';
import ImisConfigStepOne from '../../ImisConfigWizard/ImisConfigStepOne';
import ImisConfigStepThree from '../../ImisConfigWizard/ImisConfigStepThree';

import * as styles from './ImisSettings.css';

interface IProps {
  integration: ImisIntegration;
}

function ImisSettings({ integration }: Readonly<IProps>): JSX.Element {
  const steps = [ImisConfigStepOne, ImisConfigStepThree, ImisConfigStepFour, ImisConfigStepFive];

  // TODO: Refactor steps to take optional onNext and onPrev props #2933

  function onNext(): void {}

  function onPrev(): void {}

  return (
    <div className={styles.container}>
      {steps.map((Step, index) => (
        <Step
          context={'settings'}
          integration={integration}
          key={index}
          onNext={onNext}
          onPrev={onPrev}
        />
      ))}
    </div>
  );
}

export default observer(ImisSettings);
