import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { User } from '@feathr/blackbox';
import { TLDR } from '@feathr/components';

import noImg from '@feathr/extender/images/no-img.png';

interface IProps {
  className?: string;
  user?: User;
}

function UserTLDR({ className, user }: IProps): JSX.Element {
  if (user === undefined || user.isPending) {
    return <TLDR className={className} isLoading={true} />;
  }

  return (
    <TLDR
      className={className}
      image={user.get('picture') || noImg}
      isLoading={false}
      title={user.name}
    />
  );
}

export default observer(UserTLDR);
