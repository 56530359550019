import type { PickerOptions } from 'filestack-js';
import { set } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ITTDSubAdvertiser } from '@feathr/blackbox';
import { advertiserConstraints } from '@feathr/blackbox';
import { Form, ImagePicker, Input } from '@feathr/components';
import { validate } from '@feathr/rachis';

interface IProps {
  advertiser: ITTDSubAdvertiser;
}

function validateAdvertiser(advertiser: ITTDSubAdvertiser) {
  return validate.validate(advertiser, advertiserConstraints);
}

function EditAdvertiser({ advertiser }: IProps) {
  const store = useLocalObservable(() => ({ dirtyFields: [] as string[] }));
  const { t } = useTranslation();

  function onChangeName(newValue?: string) {
    if (!store.dirtyFields.includes('name')) {
      store.dirtyFields.push('name');
    }
    set(advertiser, 'name', newValue);
  }

  function onChangeURL(newValue?: string) {
    if (!store.dirtyFields.includes('url')) {
      store.dirtyFields.push('url');
    }
    set(advertiser, 'url', newValue);
  }

  function onPickLogo(url: string) {
    if (!store.dirtyFields.includes('logo')) {
      store.dirtyFields.push('logo');
    }
    set(advertiser, 'logo', url);
  }

  const validation = validateAdvertiser(advertiser);

  return (
    <Form label={'Edit Advertiser'}>
      <ImagePicker
        helpText={"File or URL of the organization's logo image. .png or .jpg files accepted."}
        label={'Logo'}
        onPick={onPickLogo}
        pickerOptions={
          {
            accept: ['image/png', 'image/jpg', '.jpg', '.png'],
            fromSources: ['local_file_system', 'url'],
            customText: {
              'Select Files to Upload': t('Upload logo file or provide logo URL'),
            },
            maxFiles: 1,
          } as PickerOptions
        }
        validationError={
          validation && store.dirtyFields.includes('logo') ? validation.logo : undefined
        }
        value={advertiser.logo}
      />
      <Input
        helpText={'The name of the business or organization.'}
        label={'Name'}
        onChange={onChangeName}
        type={'text'}
        validationError={
          validation && store.dirtyFields.includes('name') ? validation.name : undefined
        }
        value={advertiser.name}
      />
      <Input
        helpText={'The URL of the main website for the business or organization.'}
        label={'Website URL'}
        onChange={onChangeURL}
        type={'url'}
        validationError={
          validation && store.dirtyFields.includes('url') ? validation.url : undefined
        }
        value={advertiser.url}
      />
    </Form>
  );
}

export default observer(EditAdvertiser);
export { validateAdvertiser };
