import { when } from 'mobx';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Flight } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { ConfirmModal, toast } from '@feathr/components';
import CampaignSummary from '@feathr/extender/App/EventsPage/CampaignSummary';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

interface ICampaignRemoveFromFlightModalProps {
  campaign: Campaign;
  isRemoveFromFlightModalOpen: boolean;
  closeRemoveFromFlightModal: () => void;
}

function CampaignRemoveFromFlightModal({
  campaign,
  isRemoveFromFlightModalOpen,
  closeRemoveFromFlightModal,
}: Readonly<ICampaignRemoveFromFlightModalProps>): JSX.Element {
  const rootStore = React.useContext(StoresContext);
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  const state = campaign.get('state');
  const isNotDraft = state !== CampaignState.Draft;

  const flightOrProject = campaign.get('flight') ? t('flight') : t('project');
  const flightOrProjectURL = campaign.get('flight')
    ? localUrl(campaign.getItemUrl())
    : localUrl(`/projects/${campaign.get('parent')}/report`);

  const alertPageLink = (
    <a href={flightOrProjectURL} target={'_blank'}>
      {t('View {{flightOrProject}} report', { flightOrProject })}
    </a>
  );

  async function getFlight(): Promise<Flight> {
    const { Flights } = rootStore;
    const flight = Flights.get(campaign.get('flight')!);
    await when(() => !flight.isPending);
    return Flights.get(flight.id);
  }

  async function removeFromFlight(): Promise<void> {
    try {
      const { Campaigns } = rootStore;
      if (campaign.get('flight')) {
        const flight = await getFlight();
        flight.removeCampaign(campaign.id);
        await flight.save();
      }
      campaign.set({ flight: undefined });
      await campaign.save();
      Campaigns.removeFromApiCache(campaign.id);
      toast(t('Campaign {{name}} was removed from flight.', { name: campaign.name }));

      closeRemoveFromFlightModal();
    } catch (error: any) {
      toast(
        t('Encountered an error while removing from flight:\n{{- errorMessage}}', {
          errorMessage: error.message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  return (
    <ConfirmModal
      alertDescription={
        isNotDraft
          ? t(
              'Removing this campaign from this flight will make this flight\'s reported conversions inaccurate. To recalculate conversions to exclude the removed campaign, select "Recalculate conversions" from the conversions table on this flight\'s report.',
              { flightOrProject },
            )
          : undefined
      }
      alertLinks={isNotDraft && alertPageLink}
      alertTitle={
        isNotDraft && t('Are you sure you want to remove this campaign from this flight?')
      }
      confirmationPhrase={t('REMOVE')}
      confirmButtonText={t('Remove')}
      confirmButtonType={'danger'}
      onClose={closeRemoveFromFlightModal}
      onConfirm={removeFromFlight}
      opened={isRemoveFromFlightModalOpen}
      t={t}
      title={t('Remove From Flight')}
    >
      <CampaignSummary campaign={campaign} layout={'vertical'} showMinimal={true} />
    </ConfirmModal>
  );
}

export default CampaignRemoveFromFlightModal;
