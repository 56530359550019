import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTimeFrameValue } from '@feathr/components';
import { ETheme } from '@feathr/components';
import type { IChart } from '@feathr/extender/components/AudienceAreaChart';
import AudienceAreaChart from '@feathr/extender/components/AudienceAreaChart';
import useDashboardStats from '@feathr/extender/hooks/useDashboardStats';

interface IProps {
  timeFrameValue: TTimeFrameValue;
}

function AdPerformanceCharts({ timeFrameValue }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { isLoading, chartData } = useDashboardStats({ timeFrameValue });

  const shared = {
    theme: ETheme.sky,
    shade: 500,
  };

  const charts: IChart[] = [
    {
      label: t('Views'),
      tooltip: t('The number of ad views that have taken place across all ad campaigns.'),
      yAxisKey: 'num_ad_views',
      descriptor: t('views'),
      ...shared,
    },
    {
      label: t('Click-through rate'),
      tooltip: t('The overall click through rate across all ad campaigns.'),
      yAxisKey: 'ad_click_through_rate',
      descriptor: t('CTR'),
      operation: 'percentage',
      ...shared,
    },
    {
      label: t('Clicks'),
      tooltip: t('The number of ad clicks that have occurred across all ad campaigns.'),
      yAxisKey: 'num_ad_clicks',
      descriptor: t('clicks'),
      ...shared,
    },
    {
      label: t('Conversions'),
      tooltip: t('The overall number of conversions driven by ad campaigns.'),
      yAxisKey: 'num_conversions_ad.full',
      descriptor: t('conversions'),
      ...shared,
    },
  ];

  return (
    <AudienceAreaChart.Group
      backfillData={true}
      charts={charts}
      data={chartData}
      isLoading={isLoading}
      rowSize={2}
      separator={false}
      showValue={true}
    />
  );
}

export default AdPerformanceCharts;
