import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import type { CreativeClass } from '@feathr/blackbox';
import { creativeTypeLabel } from '@feathr/blackbox';
import { Card } from '@feathr/components';

import CreativeIcon from './CreativeIcon';

import * as styles from './CreativeTypeSelect.css';

export interface ICreativeTypeCardProps {
  addCreative: (type: CreativeClass, campaignId?: string) => void;
  cls: CreativeClass;
  disabled?: boolean;
  isClicked: boolean;
  campaignId?: string;
  toggleClicked: () => void;
}

function CreativeClassCard({
  addCreative,
  cls,
  disabled,
  isClicked,
  toggleClicked,
}: ICreativeTypeCardProps) {
  return (
    <Card
      className={classNames(styles.card, { [styles.cardClicked]: isClicked })}
      disabled={isClicked || disabled}
      onClick={() => {
        if (!disabled) {
          if (!isClicked) {
            addCreative(cls);
          }
          toggleClicked();
        }
      }}
    >
      <div className={styles.title}>
        <CreativeIcon cls={cls} hover={false} size={64} />
        <span>{creativeTypeLabel(cls)}</span>
      </div>
    </Card>
  );
}

export default observer(CreativeClassCard);
