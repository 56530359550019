import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { DataRequest } from '@feathr/blackbox';
import { Fieldset, Form, Input, Textarea } from '@feathr/components';
import type { TValidateGrouped } from '@feathr/rachis';

import ButtonNext from './ButtonNext';
import GTMPermissionDisplay from './GTMPermissionDisplay';

interface IProps {
  dataRequest: DataRequest;
  onNext: () => void;
}

export function validateStepOne(dataRequest: DataRequest): TValidateGrouped {
  return dataRequest.validate(
    ['name', 'gtm_container', 'gtm_account_id', 'context'],
    false,
    'grouped',
  ).errors;
}

function DataRequestFormStepOne({ dataRequest, onNext }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  const urlFindGTMAccount =
    'https://feathr.zendesk.com/knowledge/articles/4408425162775/en-us?brand_id=360002113593';
  const urlIntroDataServices = 'https://help.feathr.co/hc/en-us/articles/360052993093';
  return (
    <Form
      actions={[
        <ButtonNext
          dataRequest={dataRequest}
          key={'next'}
          onNext={onNext}
          validate={validateStepOne}
        />,
      ]}
      description={
        <Trans t={t}>
          <p>
            Use this form to submit a request for our Implementation Engineers to enhance tracking
            on any webpage where your organization's Super Pixel is placed. These requests are
            typically used to track form submissions as conversion activities and to capture data
            from the forms themselves.{' '}
            <a href={urlIntroDataServices} target={'_blank'}>
              Learn more about Feathr's Data Services in this intro article.
            </a>
          </p>
        </Trans>
      }
      label={'Edit Message: Name'}
    >
      <Fieldset>
        <Input
          attribute={'name'}
          label={'Description'}
          model={dataRequest}
          placeholder={'Name and email capture for Feathrcon2021'}
          type={'text'}
        />
        <Input
          attribute={'gtm_container'}
          label={'GTM Container'}
          model={dataRequest}
          placeholder={'F3ATHR1'}
          prefix={'GTM-'}
          type={'text'}
        />
        <Input
          attribute={'gtm_account_id'}
          helpText={
            <Trans t={t}>
              <p>
                Check if implementation@feathrapp.com has access to your container here. Granting
                access to your container is a critical step that you should do before submitting
                this request.
              </p>
              <p>
                Learn more about{' '}
                <a href={urlFindGTMAccount} target={'_blank'}>
                  how to find your GTM account ID
                </a>
                .
              </p>
            </Trans>
          }
          label={t('GTM Account Id or Container URL')}
          model={dataRequest}
          optional={true}
          placeholder={'1234567890'}
          type={'text'}
        />
        <GTMPermissionDisplay dataRequest={dataRequest} displayType={'long'} />
        <Textarea
          attribute={'context'}
          helpText={
            <Trans t={t}>
              <p>
                Please provide a couple of sentences that tell us what your goal is with this
                implementation request. Do you want to create a target group to better direct your
                marketing efforts? Perhaps you want to create a goal group to measure the
                effectiveness of your campaigns.
                <br />
                The more context you can offer here the better able our Implementation Engineers
                will be to tailor your solution to your goals.
              </p>
            </Trans>
          }
          label={'Context'}
          model={dataRequest}
          required={true}
        />
      </Fieldset>
    </Form>
  );
}

export default observer(DataRequestFormStepOne);
