import { faFileArrowDown, faFileImport } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { WithT } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ImisIntegration } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  ButtonValid,
  CardV2 as Card,
  EAlertV2Type as AlertType,
  Toolbar,
} from '@feathr/components';

import type { TConfigurationContext } from './ImisConfiguration';

import * as styles from './ImisConfigStepThree.css';

interface IProps {
  context?: TConfigurationContext;
  onNext: () => void;
  onPrev: () => void;
  integration: ImisIntegration;
}

interface IAlertProps extends WithT {
  hasBusinessObject?: boolean;
}

function BusinessObjectAvailabilityAlert({
  hasBusinessObject,
  t,
}: Readonly<IAlertProps>): JSX.Element | null {
  if (hasBusinessObject) {
    return <Alert title={t('Business Object file was found')} type={AlertType.success} />;
  }
  if (hasBusinessObject === false) {
    return <Alert title={t('Business Object file was not found')} type={AlertType.danger} />;
  }
  return null;
}

function ImisConfigStepThree({
  context = 'wizard',
  onNext,
  onPrev,
  integration,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const [hasBusinessObject, setHasBusinessObject] = useState<boolean | undefined>();
  const fileUploadUrl = `${integration.get('org_site')}/Staff/AsiCommon/Controls/BOA/Default.aspx`;

  function validation(): string[] {
    const errors: string[] = [];
    if (!hasBusinessObject) {
      errors.push(
        t(
          'You must download and import the Business Object in your iMIS account before you can proceed.',
        ),
      );
    }

    return errors;
  }

  async function handleCheckBusinessObject(): Promise<void> {
    try {
      await integration.validateBusinessObjectFile();
      setHasBusinessObject(true);
    } catch (e) {
      setHasBusinessObject(false);
    }
  }

  return (
    <>
      <Card>
        <Card.Header
          description={t(
            "In order to sync data between iMIS and Feathr, you'll need to download the Business Object file and add it to your iMIS account.",
          )}
          title={t('Set Up Business Object')}
        />
        <Card.Content
          action={
            <Button
              download={'My_FeathrDownsyncContactBO.xml'}
              href={integration.downloadBusinessObjectFileUrl}
              name={'download-business-object-file'}
              prefix={<FontAwesomeIcon icon={faFileArrowDown} />}
            >
              {t('Download')}
            </Button>
          }
          description={t(
            'Download this file and upload it to your iMIS account. It is used to setup a data relationship between Feathr and iMIS.',
          )}
          title={t('Download Business Object')}
        />
        <Card.Content
          action={
            <Button
              href={fileUploadUrl}
              name={'import-business-object'}
              prefix={<FontAwesomeIcon icon={faFileImport} />}
              target={'_blank'}
            >
              {t('Upload in iMIS')}
            </Button>
          }
          description={
            <>
              {t('Click the button to navigate to iMIS and upload the Business Object. ')}
              <a
                href={
                  'https://help.feathr.co/hc/en-us/articles/20952786063255-How-to-Integrate-iMIS-with-Feathr-NEW#01HNB9E5V5W8THHJ11WW13TJNW'
                }
              >
                {t('Learn more')}
              </a>
            </>
          }
          title={t('Import Business Object in iMIS')}
        />
        <Card.Actions>
          <Button name={'check-for-business-object'} onClick={handleCheckBusinessObject}>
            {t('Check for Business Object file')}
          </Button>
        </Card.Actions>
      </Card>
      {context === 'wizard' && (
        <div className={styles.buttonWrapper}>
          <BusinessObjectAvailabilityAlert hasBusinessObject={hasBusinessObject} t={t} />
          <Toolbar align={'right'}>
            <Button key={'prev'} name={'previous'} onClick={onPrev}>
              {t('Previous')}
            </Button>
            <ButtonValid errors={validation()} key={'next'} name={'next'} onClick={onNext}>
              {t('Next')}
            </ButtonValid>
          </Toolbar>
        </div>
      )}
    </>
  );
}

export default observer(ImisConfigStepThree);
