import { ObjectId } from 'bson';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DripCampaign, IDripCampaignStepSpec } from '@feathr/blackbox';
import { Button, CardV2 as Card, InlineEdit, Layout, StepSection } from '@feathr/components';

import EditAutomation from './EditAutomation/EditAutomation';
import EditInitialEnrollment from './EditInitialEnrollment/EditInitialEnrollment';
import PreviewAutomation from './PreviewAutomation';
import PreviewInitialEnrollment from './PreviewInitialEnrollment';

interface IBuilderStepProps {
  campaign: DripCampaign;
}

function BuilderStep({ campaign }: Readonly<IBuilderStepProps>): JSX.Element {
  const { t } = useTranslation();

  const stepSpecs = campaign.get('step_specs');
  const [steps, setSteps] = useState<IDripCampaignStepSpec[]>(
    stepSpecs.length
      ? stepSpecs
      : [
          {
            key: new ObjectId().toHexString(),
            name: t('Untitled'),
          },
          {
            key: new ObjectId().toHexString(),
            name: t('Untitled'),
          },
        ],
  );

  const [editingStep, setEditingStep] = useState<number | null>(null);

  useEffect(() => {
    campaign.set({ step_specs: steps });
  }, [campaign, steps]);

  function handleAdd(): void {
    setSteps([
      ...steps,
      {
        key: new ObjectId().toHexString(),
        name: t('Untitled'),
      } as IDripCampaignStepSpec,
    ]);
  }

  function handleDelete(stepIndex: number): () => void {
    return (): void => {
      setSteps(steps.filter((_, i) => i !== stepIndex));
    };
  }

  function handleEdit(stepIndex: number): () => void {
    return () => {
      setEditingStep(stepIndex);
    };
  }

  function onApply(updatedStep: IDripCampaignStepSpec): void {
    setSteps((prevSteps) =>
      prevSteps.map((step) => (step.key === updatedStep.key ? updatedStep : step)),
    );
    setEditingStep(null);
  }

  function renderStep(stepIndex: number): JSX.Element {
    if (stepIndex === 0) {
      if (editingStep === stepIndex) {
        return <EditInitialEnrollment onApply={onApply} step={steps[stepIndex]} />;
      } else {
        return (
          <Card theme={'disabled'} width={'full'}>
            <PreviewInitialEnrollment />
            <Card.Actions>
              <Button onClick={handleEdit(stepIndex)}>{t('Edit step')}</Button>
            </Card.Actions>
          </Card>
        );
      }
    } else {
      if (editingStep === stepIndex) {
        return <EditAutomation onApply={onApply} step={steps[stepIndex]} />;
      } else {
        return (
          <Card theme={'disabled'} width={'full'}>
            <PreviewAutomation />
            <Card.Actions>
              <Button onClick={handleEdit(stepIndex)}>{t('Edit step')}</Button>
            </Card.Actions>
          </Card>
        );
      }
    }
  }

  function handleChangeStepName(stepIndex: number) {
    return (value?: string): void => {
      setSteps((prevSteps) =>
        prevSteps.map((s, i) => {
          if (i === stepIndex) {
            return { ...s, name: value ?? '' };
          }
          return s;
        }),
      );
    };
  }

  return (
    <Layout>
      <Card width={'full'}>
        <Card.Header
          description={t(
            'Design the steps of your drip campaign. Each step has an email and automation rules that determine when and how that email should be sent.',
          )}
          title={t('Steps')}
        />
        {/* Do not use addVerticalGap={true} or the step lines will be too short */}
        <Card.Content>
          {steps.map((step, stepIndex) => {
            return (
              <StepSection
                defaultOpen={true}
                key={step.key}
                onDelete={stepIndex > 0 ? handleDelete(stepIndex) : undefined}
                stepIndex={stepIndex}
                stepNumber={stepIndex + 1}
                // Skip onDelete for the first step
              >
                <StepSection.Header>
                  <InlineEdit onChange={handleChangeStepName(stepIndex)} value={step.name} />
                </StepSection.Header>
                <StepSection.Content>{renderStep(stepIndex)}</StepSection.Content>
              </StepSection>
            );
          })}
        </Card.Content>
        <Card.Actions>
          <Button onClick={handleAdd}>{t('Add step')}</Button>
        </Card.Actions>
      </Card>
    </Layout>
  );
}

export default BuilderStep;
