import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { Flight, Goal } from '@feathr/blackbox';
import { Card, Fieldset, Form, Input, Radios, SaveButton, Tooltip } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import GoalComponent from '@feathr/extender/components/Goal';
import { StoresContext } from '@feathr/extender/state';

import AdvancedConversionTrackingSettings from '../CampaignEditPage/StepGoals/AdvancedConversionTrackingSettings';
import FlightPathSettings from '../FlightPage/MakeSharedFlightModal/FlightPathSettings';

import * as styles from './FlightEditPage.css';

function validate(flight: Flight, goal: Goal): string[] {
  return [
    ...toJS(
      flight.validate(
        ['name', 'shared', 'shared_name', 'shared_description', 'shared_image'],
        false,
      ).errors,
    ),
    ...toJS(goal.validate(['conv_type', 'conv_value', 'name', 'segment'], false).errors),
  ];
}

function FlightEditPage() {
  const { Flights, Goals, Segments } = useContext(StoresContext);
  const { flightId } = useParams<{ flightId: string; eventId: string }>();
  const newGoal = useRef(Goals.create({ parent: flightId, conv_value: 0, kind: 'flight' })).current;
  const { t } = useTranslation();

  const flight = Flights.get(flightId);
  const goals = Goals.list({
    filters: {
      _parent: flight.id,
      is_archived__ne: true,
    },
  });
  const goal = !goals.isPending && goals.models.length > 0 ? goals.models[0] : newGoal;
  const segment = goal.get('segment') ? Segments.get(goal.get('segment')!) : undefined;

  async function handleSave() {
    if (segment) {
      if (segment.isEphemeral) {
        await Segments.add(segment);
      } else {
        await segment.patchDirty();
      }
    }
    if (goal.isEphemeral) {
      await Goals.add(goal);
    } else {
      await goal.patchDirty();
    }
    Flights.refreshApiCache();
  }

  const errors = validate(flight, goal);
  const isValid = errors.length === 0;

  const saveButton = (
    <SaveButton
      disabled={!isValid}
      errorMessage={t('There was an issue saving. Please try again later.')}
      key={'finish'}
      method={'patch'}
      model={flight}
      onSave={handleSave}
      successMessage={t('Changes have been saved.')}
    />
  );

  const actions = [
    isValid ? (
      <Fragment key={'finish'}>{saveButton}</Fragment>
    ) : (
      <Tooltip
        key={'finish'}
        title={errors.map((error, i) => (
          <Fragment key={i}>
            {error}
            {i < errors.length - 1 && <br />}
          </Fragment>
        ))}
      >
        {saveButton}
      </Tooltip>
    ),
  ];

  const stats = flight.get('total_stats');

  function changeTrackingMode(mode: 'advanced' | 'auto') {
    if (mode === 'auto') {
      goal.set({
        conv_value_dynamic: false,
        conv_value: 0,
        segment: undefined,
        name: '',
      });
    } else if (mode === 'advanced') {
      goal.set({ conv_value_dynamic: true, conv_value: 0, segment: undefined, name: '' });
    }
    flight.set({ conversion_tracking_mode: mode });
  }

  function handleModeChange(newValue?: string) {
    if (newValue === 'advanced' || newValue === 'auto') {
      changeTrackingMode(newValue);
    }
  }

  return (
    <Page pageTitle={t('Edit {{name}}', { name: flight.name })} title={t('Edit')}>
      <Form actions={actions} className={styles.form} label={t('Edit flight')}>
        <Fieldset>
          <Input attribute={'name'} label={t('Name')} model={flight} type={'text'} />
        </Fieldset>
        <Card contentClassName={styles.cardContent} title={t('Conversion Tracking')}>
          <Radios
            className={styles.field}
            label={t('Conversion Tracking Mode')}
            onChange={handleModeChange}
            options={[
              { id: 'auto', name: t('Goal') },
              { id: 'advanced', name: t('Advanced') },
            ]}
            value={flight.get('conversion_tracking_mode') || 'auto'}
          />
          {flight.get('conversion_tracking_mode') === 'advanced' && goal && (
            <AdvancedConversionTrackingSettings goal={goal} />
          )}
          {flight.get('conversion_tracking_mode') === 'auto' && (
            <GoalComponent
              goal={goal}
              goals={observable([])}
              showWarning={
                !flight.isPending && !!stats.conversions && !!stats.conversions.full?.num
              }
            />
          )}
        </Card>
        <FlightPathSettings flight={flight} title={t('Flight path')} />
      </Form>
    </Page>
  );
}

export default observer(FlightEditPage);
