import type { JSX } from 'react';
import React, { Fragment } from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { TPublisherPlatforms } from '@feathr/blackbox';
import { EMouseLeaveDelay, Tooltip } from '@feathr/components';

interface IPlatformOption {
  id: string;
  isDisabled: boolean;
  name: string;
  platforms: TPublisherPlatforms[];
  tooltip?: JSX.Element;
}

function PlatformOption(props: OptionProps<IPlatformOption>): JSX.Element {
  const {
    data: { id, name, tooltip },
  } = props;
  const option = (
    <components.Option {...props}>
      <Fragment key={id}>{name}</Fragment>
    </components.Option>
  );
  return tooltip ? (
    <Tooltip closeDelay={EMouseLeaveDelay.Link} title={tooltip}>
      {option}
    </Tooltip>
  ) : (
    option
  );
}

export default PlatformOption;
