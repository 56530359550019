import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EIntegrationTypes } from '@feathr/blackbox';
import { Nav, NavItem, NavMenu } from '@feathr/components';
import { useGoogleAdsPermissions } from '@feathr/extender/hooks';
import { useAccount, useFlags, useLocalUrl, useRole, useUser } from '@feathr/extender/state';

function SettingsNav(): JSX.Element {
  const localUrl = useLocalUrl();
  const user = useUser();
  const account = useAccount();
  const { t } = useTranslation();
  const { hasBilling, hasConversions, hasDomains, hasIntegrations, hasPermissionsLicenseOrFlag } =
    useRole();
  const { hasImis, hasBlackbaudRaisersEdge } = useFlags();

  const { isSudoer, isAdmin: isAdminUser } = user;
  const isAdmin = isSudoer || isAdminUser;
  const { activePackage, isPlatformAdvanced, isPro } = account;
  const showAPIKeys = account.isFalcon;
  const showImis = isPlatformAdvanced || hasImis;
  const showBlackbaudRaisersEdge = isPlatformAdvanced || hasBlackbaudRaisersEdge;
  const showGoogleAds = useGoogleAdsPermissions({ requireConnectedCustomer: false });

  // If the account has iMIS configured then it can't have Blackbaud Raiser's Edge NXT.
  const hasNoConflictsWithBlackbaud = account.conflictingIntegrations.includes(
    EIntegrationTypes.Imis,
  );

  // If the account has Blackbaud Raiser's Edge NXT configured then it can't have iMIS.
  const hasNoConflictsWithImis = account.conflictingIntegrations.includes(
    EIntegrationTypes.BlackbaudRaisersEdge,
  );

  const billingNav = (
    <Nav indented={true} label={'Billing settings navigation'} type={'vertical'}>
      <NavItem to={localUrl('/settings/billing/configurations')}>{t('Configurations')}</NavItem>
      <NavItem to={localUrl('/settings/billing/license')}>{t('License')}</NavItem>
      <NavItem to={localUrl('/settings/billing/invoices')}>{t('Invoices')}</NavItem>
    </Nav>
  );

  if (!activePackage && !user?.isSudoer) {
    return (
      <Nav indented={true} label={'Settings navigation'} type={'vertical'}>
        <NavMenu menu={billingNav} to={localUrl('/settings/billing')}>
          {t('Billing')}
        </NavMenu>
      </Nav>
    );
  }

  const userNav = (
    <Nav indented={true} label={'User settings navigation'} type={'vertical'}>
      <NavItem to={localUrl('/settings/user/notifications')}>{t('Notifications')}</NavItem>
      <NavItem to={localUrl('/settings/user/profile')}>{t('Profile')}</NavItem>
      <NavItem to={localUrl('/settings/user/reset-password')}>{t('Reset Password')}</NavItem>
    </Nav>
  );

  const accountNav = (
    <Nav indented={true} label={'Account settings navigation'} type={'vertical'}>
      {(account.get('is_agency') || isSudoer) && (
        <NavItem to={localUrl('/settings/account/advertisers')}>{t('Advertisers')}</NavItem>
      )}
      {hasConversions && (
        <NavItem to={localUrl('/settings/account/conversions')}>{t('Conversions')}</NavItem>
      )}
      {hasDomains && (
        <>
          <NavItem to={localUrl('/settings/account/domains')}>{t('Domains')}</NavItem>
          <NavItem to={localUrl('/settings/account/allow-list')}>{t('Domain Allow List')}</NavItem>
        </>
      )}
      <NavItem to={localUrl('/settings/account/emails')}>{t('Emails')}</NavItem>
      <NavItem to={localUrl('/settings/account/ip-filtering')}>{t('IP Filtering')}</NavItem>
      <NavItem to={localUrl('/settings/account/fonts')}>{t('Fonts')}</NavItem>
      {isSudoer && <NavItem to={localUrl('/settings/account/profile')}>{t('Profile')}</NavItem>}
      <NavItem to={localUrl('/settings/account/subscriptions')}>{t('Subscriptions')}</NavItem>
      {isAdmin && (
        <NavItem to={localUrl('/settings/account/users')}>
          {hasPermissionsLicenseOrFlag ? t('Users & Roles') : t('Users')}
        </NavItem>
      )}
    </Nav>
  );

  const integrationsNav = (
    <Nav indented={true} label={'Integrations settings navigation'} type={'vertical'}>
      {showAPIKeys && (
        <NavItem to={localUrl('settings/integrations/api-keys')}>{t('API Keys')}</NavItem>
      )}
      {showGoogleAds && (
        <NavItem to={localUrl('/settings/integrations/google-ads')}>{t('Google Ads')}</NavItem>
      )}
      {showImis && hasNoConflictsWithImis && (
        <NavItem to={localUrl('/settings/integrations/imis')}>{t('iMIS')}</NavItem>
      )}
      <NavItem to={localUrl('/settings/integrations/meta')}>{t('Meta')}</NavItem>
      {showBlackbaudRaisersEdge && hasNoConflictsWithBlackbaud && (
        <NavItem to={localUrl('/settings/integrations/raisers-edge')}>
          {t("Raiser's Edge NXT")}
        </NavItem>
      )}
    </Nav>
  );

  return (
    <Nav indented={true} label={'Settings navigation'} type={'vertical'}>
      <NavMenu menu={userNav} to={localUrl('/settings/user')}>
        {t('User')}
      </NavMenu>
      <NavMenu menu={accountNav} to={localUrl('/settings/account')}>
        {t('Account')}
      </NavMenu>
      {hasIntegrations && (
        <NavMenu menu={integrationsNav} to={localUrl('/settings/integrations')}>
          {t('Integrations')}
        </NavMenu>
      )}
      {hasBilling && (
        <NavMenu menu={billingNav} to={localUrl('/settings/billing')}>
          {t('Billing')}
        </NavMenu>
      )}
      {isPro && <NavItem to={localUrl('/settings/pro-resources')}>{t('Resources')}</NavItem>}
    </Nav>
  );
}

export default observer(SettingsNav);
