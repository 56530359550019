import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { BlackbaudRaisersEdgeIntegration } from '@feathr/blackbox';

import RaisersEdgeConnectBlackbaudCard from './RaisersEdgeConnectBlackbaudCard';
import RaisersEdgeSyncWizard from './RaisersEdgeSyncWizard';

interface IProps {
  integration?: BlackbaudRaisersEdgeIntegration;
}

function RaisersEdgeIntegrationCard({ integration }: IProps): JSX.Element {
  const isConnected = integration?.isConnected;
  const isDisconnected = integration?.isDisconnected;
  const isPaused = integration?.isPaused;

  return (
    <>
      {/* An integration object doesn't exist and the user has never clicked "Connect Blackbaud" or the integration is disconnected. Or, an integration object exists but the user has not logged in yet. */}
      {(!integration || isDisconnected || (integration && !isConnected && !isPaused)) && (
        <RaisersEdgeConnectBlackbaudCard integration={integration} />
      )}

      {/* The integration is connected and the user can begin mapping fields. */}
      {(isConnected || isPaused) && <RaisersEdgeSyncWizard integration={integration} />}
    </>
  );
}

export default observer(RaisersEdgeIntegrationCard);
