import { computed, makeObservable } from 'mobx';

import type { IBaseAttributes } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

import type { TAttributionModel } from './stats';

export interface IConfig {
  attributionModel: TAttributionModel;
  includeActivityBreakdown?: boolean;
  includeActivitySummary?: boolean;
  includeAdActivity?: boolean;
  includeAdPerformance?: boolean;
  includeConversionsTable?: boolean;
  includeCreativesTable?: boolean;
  includeDailyStatsTable?: boolean;
  includeGoogleActivity?: boolean;
  includeEmailActivity?: boolean;
  includeEmailPerformance?: boolean;
  includeFlightStats?: boolean;
  includeFormSubmissionsTable?: boolean;
  includeHeatmap?: boolean;
  includeKeywordStatsTable?: boolean;
  includeLeadsSummary?: boolean;
  includeLeadsTable?: boolean;
  includeLinkClicksTable?: boolean;
  includeMonetizationROI?: boolean;
  includePageActivity?: boolean;
  includePartnersStatsTable?: boolean;
  includeROI?: boolean;
  includeSpendPerformance?: boolean;
  includeTrafficBreakdown?: boolean;
  includeCampaignEngagement?: boolean;
}

export const reportModuleLabels = {
  includeActivityBreakdown: 'Activity breakdown',
  includeActivitySummary: 'Activity summary',
  includeAdActivity: 'Ad activity',
  includeAdPerformance: 'Ad performance',
  includeCampaignEngagement: 'Campaign engagement',
  includeConversionsTable: 'Conversions table',
  includeCreativesTable: 'Creatives table',
  includeDailyStatsTable: 'Daily stats table',
  includeEmailActivity: 'Email activity',
  includeEmailPerformance: 'Email performance',
  includeGoogleActivity: 'Google activity',
  includeFlightStats: 'Flight stats',
  includeFormSubmissionsTable: 'Form submissions table',
  includeHeatmap: 'Activity heatmap',
  includeKeywordStatsTable: 'Search term performance',
  includeLeadsSummary: 'Leads summary',
  includeLinkClicksTable: 'Link clicks',
  includeMonetizationROI: 'Monetization conversion value',
  includePageActivity: 'Page activity',
  includePartnersStatsTable: 'Partner stats',
  includeROI: 'Conversion value',
  includeSpendPerformance: 'Spend performance',
  includeTrafficBreakdown: 'Traffic breakdown',
} as const;

export interface IRedirect extends IBaseAttributes {
  readonly id: string;
  url: string;
  short_code: string;
  proxy: boolean;
  domain: string;
}

export class Redirect extends Model<IRedirect> {
  public readonly className = 'Redirect';

  public constraints = {
    short_code: {
      presence: {
        allowEmpty: false,
        message: '^Redirect link cannot be blank.',
      },
      length: {
        minimum: 3,
        tooShort: '^Redirect link must be at least 3 characters in length.',
      },
      format: {
        pattern: '[A-Za-z0-9_.~-]+',
        flags: 'i',
        message:
          '^Redirect link may only contain alphanumeric characters, underscores, hyphens, periods or tildes.',
      },
    },
    url: {
      url: {
        message: '^A valid URL must be provided.',
      },
    },
  };

  constructor(attributes: Partial<IRedirect> = {}) {
    super(attributes);

    makeObservable(this);
  }

  @computed
  public get config() {
    const defaultConfig: IConfig = {
      includeSpendPerformance: true,
      includeHeatmap: true,
      includeAdActivity: true,
      includeEmailActivity: true,
      includePageActivity: true,
      includeActivityBreakdown: true,
      includeConversionsTable: true,
      includeCreativesTable: true,
      includeDailyStatsTable: false,
      includeMonetizationROI: false,
      attributionModel: 'linear',
    };
    try {
      return JSON.parse(atob(this.getSearchParam('c') || '')) as IConfig;
    } catch (error) {
      return defaultConfig;
    }
  }

  public set config(value: IConfig) {
    const encoded = btoa(JSON.stringify(value));
    this.setSearchParam('c', encoded);
  }

  @computed
  public get reportStart() {
    return this.getSearchParam('s') || '';
  }

  public set reportStart(value: string) {
    this.setSearchParam('s', value);
  }

  @computed
  public get reportEnd() {
    return this.getSearchParam('e') || '';
  }

  public set reportEnd(value: string) {
    this.setSearchParam('e', value);
  }

  @computed
  public get reportMode(): 'live' | 'dateWindow' {
    const mode = this.getSearchParam('m');
    return mode === 'l' ? 'live' : 'dateWindow';
  }

  public set reportMode(value: 'live' | 'dateWindow') {
    this.setSearchParam('m', value === 'live' ? 'l' : 'dw');
  }

  public getSearchParam(key: string) {
    try {
      const url = new URL(this.get('url'));
      const { searchParams } = url;
      return searchParams.get(key);
    } catch (error) {
      return '';
    }
  }

  public setSearchParam(key: string, value: string) {
    try {
      const url = new URL(this.get('url'));
      const searchParams = new URLSearchParams(url.searchParams);
      searchParams.set(key, value);
      url.search = `?${searchParams.toString()}`;
      this.set({ url: url.href });
    } catch (error) {
      // Do nothing?
    }
  }
}

export class Redirects extends Collection<Redirect> {
  public getModel(attributes = {}) {
    return new Redirect(attributes);
  }

  public getClassName() {
    return 'redirects';
  }
}
