import React from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { IPartner } from '@feathr/blackbox';

import { contentWrapper } from './SelectOptions.css';

import noImg from 'images/no-img.png';

function PartnerOption<T extends IPartner>(props: OptionProps<T>) {
  return (
    <components.Option {...props}>
      <div className={contentWrapper}>
        <div
          style={{
            marginRight: '10px',
            backgroundImage: `url("${props.data.logo || noImg}")`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '36px',
            height: '36px',
            borderRadius: '4px',
          }}
        />
        <span>{props.data.name}</span>
      </div>
    </components.Option>
  );
}

export default PartnerOption;
