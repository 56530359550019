import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faCalendar,
  faCheckSquare,
  faHashtag,
  faList,
  faText,
} from '@fortawesome/pro-light-svg-icons';
import type { TFunction } from 'i18next';

import { FieldDataType } from '@feathr/blackbox';

export function fieldDataTypeIcon(type: FieldDataType): IconDefinition {
  const icons: Record<FieldDataType, IconDefinition> = {
    [FieldDataType.bool]: faCheckSquare,
    [FieldDataType.date]: faCalendar,
    [FieldDataType.float]: faHashtag,
    [FieldDataType.int]: faHashtag,
    [FieldDataType.list]: faList,
    [FieldDataType.str]: faText,
  };
  return icons[type];
}

export function fieldDataTypeLabel(type: FieldDataType, t: TFunction): string {
  const labels: Record<FieldDataType, string> = {
    [FieldDataType.bool]: t('True/False'),
    [FieldDataType.date]: t('Date'),
    [FieldDataType.float]: t('Number'),
    [FieldDataType.int]: t('Number'),
    [FieldDataType.list]: t('List'),
    [FieldDataType.str]: t('Text'),
  };
  return labels[type];
}
