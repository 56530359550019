import React from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { ITemplate } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import { PlaceholderImage } from '@feathr/components';
import CampaignChip from '@feathr/extender/components/CampaignChip';

import { contentWrapper } from './SelectOptions.css';

import noImg from 'images/no-img.png';

export function TemplateOption(props: OptionProps<ITemplate>) {
  const isBanner = [TemplateClass.Banner, TemplateClass.ReferralBanner].includes(props.data._cls);
  const shouldUsePlaceholder =
    isBanner && props.data.bannersnack_enabled && (!props.data.banner_hash || props.data.default);
  const isDefault = props.data.default === true;
  const isAccountTemplate = props.data.parent_kind === 'account';
  const hasCampaignParent = props.data.parent_kind === 'campaign';
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex' }}>
        <div className={contentWrapper}>
          {shouldUsePlaceholder ? (
            <PlaceholderImage
              height={props.data.height}
              style={{ width: '100px' }}
              width={props.data.width}
            />
          ) : (
            <img alt={''} src={`${props.data.thumbnail_url || noImg}`} style={{ width: '100px' }} />
          )}
        </div>
        <div className={contentWrapper}>
          <span>{props.data.name}</span>
        </div>
        <div className={contentWrapper}>
          {hasCampaignParent ? (
            isAccountTemplate || isDefault ? (
              <CampaignChip id={props.data.parent} linkToItem={false} />
            ) : (
              false
            )
          ) : (
            false
          )}
        </div>
      </div>
    </components.Option>
  );
}
