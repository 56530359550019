import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { FacebookCampaign, TFacebookCampaign } from '@feathr/blackbox';
import { FacebookCampaignObjectiveMap, FacebookObjectiveType } from '@feathr/blackbox';
import { Select } from '@feathr/components';
import { FACEBOOK_OBJECTIVES_REFACTOR_DATE } from '@feathr/extender/global';

import * as styles from './CampaignObjectiveSelect.css';

function CampaignObjectiveOption(props: OptionProps<ICampaignObjective>): JSX.Element {
  return (
    <components.Option {...props}>
      <Fragment key={props.data.id}>
        {props.data.name}
        <div className={styles.description}>
          <p>{props.data.description}</p>
        </div>
      </Fragment>
    </components.Option>
  );
}

interface IProps {
  campaign: FacebookCampaign;
}

interface ICampaignObjective {
  id: TFacebookCampaign['objective_type'];
  name?: string;
  description: string;
}

function CampaignObjectiveSelect({ campaign }: IProps): JSX.Element {
  const { t } = useTranslation();
  const options: ICampaignObjective[] =
    !campaign.get('date_first_published') ||
    campaign.get('date_first_published') > FACEBOOK_OBJECTIVES_REFACTOR_DATE
      ? [
          {
            id: FacebookObjectiveType.Reach,
            name: FacebookCampaignObjectiveMap.get(FacebookObjectiveType.Reach),
            description: t('Show ads to the maximum amount of people.'),
          },
          {
            id: FacebookObjectiveType.Traffic,
            name: FacebookCampaignObjectiveMap.get(FacebookObjectiveType.Traffic),
            description: t('Optimize this campaign for driving website visits.'),
          },
        ]
      : [
          {
            id: FacebookObjectiveType.Reach_Legacy,
            name: FacebookCampaignObjectiveMap.get(FacebookObjectiveType.Reach_Legacy),
            description: t('Show ads to the maximum amount of people.'),
          },
          {
            id: FacebookObjectiveType.Traffic_Legacy,
            name: FacebookCampaignObjectiveMap.get(FacebookObjectiveType.Traffic_Legacy),
            description: t('Optimize this campaign for driving website visits.'),
          },
        ];

  function handleOnSelectSingle(option: ICampaignObjective): void {
    campaign.set({ objective_type: option.id });
  }

  return (
    <Select<ICampaignObjective>
      components={{ Option: CampaignObjectiveOption }}
      disabled={!!campaign.get('date_first_published')}
      helpText={
        <Trans t={t}>
          <p>
            Your Meta advertising objective is what you want people to do when they see your ads.
          </p>
          <p>
            <FontAwesomeIcon className={styles.warning} icon={faExclamationTriangle} /> This cannot
            be changed after you publish your campaign.
          </p>
        </Trans>
      }
      label={t('Campaign objective')}
      name={'campaign_objective'}
      onSelectSingle={handleOnSelectSingle}
      options={options}
      placeholder={t('Choose a campaign objective')}
      required={true}
      value={options.find((option) => option.id === campaign.get('objective_type'))}
    />
  );
}

export default observer(CampaignObjectiveSelect);
