import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { AggregatedStat, EmailBaseCampaign } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { StatsCard } from '@feathr/components';

import EmailStats from '../EmailStats';

interface ICardProps {
  aggregatedStats: AggregatedStat;
  campaign?: EmailBaseCampaign;
}

function EmailActivitySummaryCard({
  campaign,
  aggregatedStats,
}: Readonly<ICardProps>): JSX.Element | null {
  if (!campaign?.isEmail) {
    return null;
  }

  return (
    <StatsCard title={reportModuleLabels.includeActivitySummary}>
      <EmailStats aggregatedStats={aggregatedStats} campaign={campaign} />
    </StatsCard>
  );
}

export default observer(EmailActivitySummaryCard);
