import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';
import CampaignOptions from '@feathr/extender/components/CampaignOptions';

import type { ICampaignRow } from './CampaignsColumns';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const OptionsColumn: IColumn<BaseCampaign> = {
  id: 'options',
  checkboxLabel: 'Options',
  Header: TableColumnHeader({
    title: 'Options',
  }),
  width: 80,
  sortable: false,
  className: tableStyles.cellCenter,
  Cell(row: ICampaignRow): JSX.Element {
    return <OptionsCell row={row} />;
  },
};

interface IProps {
  row: ICampaignRow;
}

function OptionsCell({ row }: IProps): JSX.Element {
  const campaign = row.original as BaseCampaign;

  return <CampaignOptions campaign={campaign} />;
}

export default OptionsColumn;
