import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';

import type { ICustomField } from '@feathr/blackbox';
import type { CustomField } from '@feathr/blackbox';
import { Alert, AlertType, AsyncSelect, FormSummaryItem } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { fieldDataTypeIcon } from '@feathr/extender/styles/fieldDataType';

import * as styles from './MergeFieldDialog.css';

function FieldOption(props: any) {
  // eslint-disable-next-line react/destructuring-assignment, @typescript-eslint/naming-convention
  const { data_type, u_key } = props.data;
  const icon = fieldDataTypeIcon(data_type);
  return (
    <components.Option {...props}>
      <div className={styles.fieldOptionContainer}>
        <div className={styles.fieldOptionIcon}>{icon && <FontAwesomeIcon icon={icon} />}</div>
        <div className={styles.fieldOptionKey}>
          <span>{u_key}</span>
        </div>
      </div>
    </components.Option>
  );
}

interface IProps {
  model: CustomField;
}

function MergeFieldDialog({ model }: IProps) {
  const { CustomFields } = useContext(StoresContext);
  const { t } = useTranslation();

  const loadOptions = async (value: string) => {
    const customFields = CustomFields.list({
      filters: {
        u_key__icontains: value,
        _is_read_only__ne: true,
        collection: model.get('collection'),
      },
    });
    await when(() => !customFields.isPending);
    return customFields.models
      .filter((customField) => customField.id !== model.id)
      .map((customField) => customField.toJS());
  };

  return (
    <>
      <div className={styles.dialogSection}>
        <Alert type={AlertType.warning}>
          {t(
            `Choose a field from the selection below to merge into the field "{{targetField}}". Data currently found under the chosen field will be moved to the field "{{targetField}}", and the chosen field will be deleted. Please note that this action cannot be reverted, and some data loss may occur if a record in your database already has data under both the field "{{targetField}}" and the chosen field.`,
            { targetField: model.name },
          )}
        </Alert>
      </div>
      <div className={styles.dialogSection}>
        <AsyncSelect<ICustomField>
          components={{ Option: FieldOption }}
          defaultOptions={true}
          getOptionLabel={(option: ICustomField) => option.u_key}
          getOptionValue={(option: ICustomField) => option.id!}
          isClearable={true}
          isMulti={false}
          loadOptions={loadOptions}
          onClear={() => {
            model.setMergeId(undefined);
          }}
          onSelectSingle={(option) => {
            model.setMergeId(option.id);
          }}
          placeholder={t('Select Field to Merge')}
          value={model.mergeId ? CustomFields.get(model.mergeId).toJS() : undefined}
        />
      </div>
      <FormSummaryItem
        className={styles.mergeResultContainer}
        label={t('Merge result:')}
        value={
          <p className={styles.mergeResult}>
            {model.mergeId
              ? t(
                  'The custom field "{{fieldToMerge}}" will be merged into "{{targetField}}"."{{fieldToMerge}}" will no longer exist after the merge.',
                  { fieldToMerge: CustomFields.get(model.mergeId).name, targetField: model.name },
                )
              : t('No custom field selected.')}
          </p>
        }
      />
    </>
  );
}

export default observer(MergeFieldDialog);
