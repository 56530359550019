import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { IFlight, TAttributionModel } from '@feathr/blackbox';
import ReportActions from '@feathr/extender/components/PageHeaderActions';
import ReportWrapper from '@feathr/extender/components/ReportWrapper/ReportWrapper';
import { useReportExport } from '@feathr/extender/hooks';
import { StoresContext } from '@feathr/extender/state';
import { FlightReport } from '@feathr/report_components';

import FlightPage from '../FlightPage';

interface IConfig {
  attributionModel: TAttributionModel;
  includeActivitySummary: boolean;
  includeAdActivity: boolean;
  includeAdPerformance: boolean;
  includeConversionsTable: boolean;
  includeEmailActivity: boolean;
  includeEmailPerformance: boolean;
  includeFlightStats: boolean;
  includePageActivity: boolean;
  includeROI: boolean;
  includeSpendPerformance: boolean;
}

const config: IConfig = {
  attributionModel: 'full',
  includeActivitySummary: true,
  includeAdActivity: true,
  includeAdPerformance: true,
  includeConversionsTable: false,
  includeEmailActivity: true,
  includeEmailPerformance: true,
  includeFlightStats: true,
  includePageActivity: true,
  includeROI: false,
  includeSpendPerformance: true,
};

function FlightReportPage(): JSX.Element {
  const { t } = useTranslation();
  const { flightId } = useParams<{ eventId: string; flightId: string }>();
  const { Flights } = useContext(StoresContext);
  const flight = Flights.get(flightId);
  const reportExportProps = useReportExport<IFlight, IConfig>({ model: flight, config });

  const actions = <ReportActions flight={flight} {...reportExportProps} />;

  return (
    <FlightPage actions={actions} config={config} flight={flight} title={t('Report')}>
      <ReportWrapper model={flight} Report={FlightReport} {...reportExportProps} />
    </FlightPage>
  );
}

export default observer(FlightReportPage);
