import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Campaign, IGoogleAdsKeyword } from '@feathr/blackbox';
import { ContextMenu, MenuItem, toast } from '@feathr/components';

interface IKeywordStatsOptionsProps {
  campaign: Campaign;
  isNegative: boolean;
  term: string;
}

function KeywordStatsOptions({
  campaign,
  isNegative,
  term,
}: Readonly<IKeywordStatsOptionsProps>): JSX.Element {
  const { t } = useTranslation();
  const existingKeywordThemes = campaign.get('keyword_themes');

  async function handleAdd(): Promise<void> {
    try {
      const existingNegativeKeywords = existingKeywordThemes
        .filter((keyword: IGoogleAdsKeyword) => keyword.negative === true)
        .map((keyword: IGoogleAdsKeyword) => keyword.display_name);
      const keyword = { display_name: term, negative: true, suggestion: false };
      if (!existingNegativeKeywords.includes(keyword.display_name)) {
        const keywords = [...existingKeywordThemes, keyword];
        campaign.set({ keyword_themes: keywords });
        await campaign.patchDirty();
      }
      toast(
        t('{{keyword}} has been added as a negative keyword for the campaign.', {
          keyword: term,
        }),
        { type: ToastType.SUCCESS },
      );
    } catch (error) {
      toast(t('Something went wrong while adding the negative keyword:\n{{error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
  }

  async function handleRemove(): Promise<void> {
    try {
      const newKeywords = existingKeywordThemes.filter(
        (keyword: IGoogleAdsKeyword) => keyword.display_name !== term || !keyword.negative,
      );
      campaign.set({ keyword_themes: newKeywords });
      await campaign.patchDirty();
      toast(
        t('{{keyword}} has been removed as a negative keyword for the campaign.', {
          keyword: term,
        }),
        { type: ToastType.SUCCESS },
      );
    } catch (error) {
      toast(t('Something went wrong while removing the negative keyword:\n{{error}}'), {
        type: ToastType.ERROR,
      });
    }
  }

  return (
    <ContextMenu name={'keyword-stats-options'}>
      {!isNegative && <MenuItem onClick={handleAdd}>{t('Add negative keyword')}</MenuItem>}
      {isNegative && <MenuItem onClick={handleRemove}>{t('Remove negative keyword')}</MenuItem>}
      <MenuItem link={`${campaign.getItemUrl()}/edit#step3`}>{t('Edit keyword themes')}</MenuItem>
    </ContextMenu>
  );
}

export default KeywordStatsOptions;
