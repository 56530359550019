import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const EmailSendsColumn: IColumn<BaseCampaign> = {
  id: 'stats__flavors__pinpoint_tracked_email_send',
  checkboxLabel: 'Email Sends',
  Header: TableColumnHeader({
    title: 'Email Sends',
  }),
  width: 110,
  sortable: false,
  className: tableStyles.cellRight,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const stats = original.get('total_stats') || {};
          const flavors = stats.flavors || {};
          const sends = flavors.pinpoint_tracked_email_send || 0;
          const suppressions = flavors.pinpoint_tracked_email_suppression || 0;
          return <>{(sends - suppressions).toLocaleString()}</>;
        }}
      </Observer>
    );
  },
  Footer({ data }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const total = data.reduce((subtotal, currentValue) => {
            const stats = currentValue._original.get('total_stats') || {};
            const flavors = stats.flavors || {};
            const sends = flavors.pinpoint_tracked_email_send || 0;
            const suppressions = flavors.pinpoint_tracked_email_suppression || 0;
            return subtotal + sends - suppressions;
          }, 0);
          return <>{total.toLocaleString()}</>;
        }}
      </Observer>
    );
  },
};

export default EmailSendsColumn;
