import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@mantine/hooks';
import type { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TCampaignGroup } from '@feathr/blackbox';
import type { ButtonType } from '@feathr/components';
import { Button, Tooltip } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';

import type { IAddCampaignModalProps } from './AddCampaignModal';
import AddCampaignModal from './AddCampaignModal';

interface IProps
  extends Pick<
    IAddCampaignModalProps,
    'campaignClasses' | 'campaignGroup' | 'flightId' | 'partnerId' | 'postSave' | 'redirect'
  > {
  children?: ReactNode;
  className?: string;
  prefix?: ReactNode;
  tooltip?: ReactNode;
  type?: ButtonType;
}

function getButtonLabel(t: TFunction, campaignGroup: TCampaignGroup): string {
  const buttonLabelMap: Record<TCampaignGroup, string> = {
    ads: t('Create ad campaign'),
    all: t('Create'),
    email: t('Create email'),
    monetization: t('Create monetization campaign'),
    'google-ads': t('New campaign'),
    other: t('Create campaign'),
  };
  return buttonLabelMap[campaignGroup];
}

function AddCampaignButton({
  campaignGroup,
  children,
  className,
  prefix,
  tooltip,
  type = 'primary',
  ...modalProps
}: IProps): JSX.Element {
  const [isModalOpen, { open: openModal, close: closeModal }] = useDisclosure(false);

  const { t } = useTranslation();
  const account = useAccount();

  const triggerProps = {
    className,
    name: 'add_campaign',
    onClick: openModal,
    prefix: prefix ?? prefix !== null ? <FontAwesomeIcon icon={faPlus} /> : undefined,
  };

  const trigger = (
    <Button {...triggerProps} disabled={!account.hasActivePackage} type={type}>
      {children ?? (campaignGroup && getButtonLabel(t, campaignGroup))}
    </Button>
  );

  // Accounts without active packages or invalid package names cannot create campaigns.
  if (!account.hasActivePackage) {
    return (
      <Tooltip
        position={'right'}
        title={t('You must have an active license to create a campaign.')}
      >
        {trigger}
      </Tooltip>
    );
  }

  return (
    <>
      {tooltip ? <Tooltip title={tooltip}>{trigger}</Tooltip> : trigger}
      <AddCampaignModal
        {...modalProps}
        campaignGroup={campaignGroup}
        onClose={closeModal}
        opened={isModalOpen}
      />
    </>
  );
}

export default observer(AddCampaignButton);
