import React from 'react';

import { cssVar } from '@feathr/hooks';

import { funnelStage, funnelStage1, funnelStage2, funnelStage3 } from './FunnelChart.css';

export interface IData {
  label: string;
  value: number;
}

interface IProps {
  height: number;
  width: number;
  data: IData[];
}

const stageClassNames = [funnelStage1, funnelStage2, funnelStage3];

function FunnelChart({ data, height, width }: IProps) {
  const max = data.reduce((acc, datum) => Math.max(datum.value, acc), 0);
  const min = max / 6;
  const total = data.reduce((acc, datum) => acc + Math.max(datum.value, min), 0);
  let current = 0;
  const chartWidth = width - 100;
  const slope = height / chartWidth;
  let previousWidth = chartWidth;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: `${width}px`,
      }}
    >
      {data.map((datum, index) => {
        const value = datum.value < min ? min : datum.value;
        const ratio = value / total;
        const calculatedHeight = height * ratio;
        current += calculatedHeight;
        const datumWidth = (height - current) / slope;
        const borderWidth = (previousWidth - datumWidth) / 2;
        previousWidth = datumWidth;
        return (
          <div
            key={datum.label}
            style={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              className={`${funnelStage} ${stageClassNames[index % 3]}`}
              style={{
                width: `${datumWidth}px`,
                borderTopWidth: `${calculatedHeight}px`,
                borderRightWidth: `${borderWidth}px`,
                borderLeftWidth: `${borderWidth}px`,
              }}
            />
            <div
              style={{
                fontSize: '10px',
                position: 'absolute',
                right: '20px',
                textAlign: 'right',
                top: `${calculatedHeight / 2 - 10}px`,
                width: `${chartWidth / 2 + 30}px`,
                borderBottom: `1px solid ${cssVar('--color-primary-1')}`,
                zIndex: 0,
              }}
            >
              {datum.label} ({datum.value.toLocaleString()})
            </div>
          </div>
        );
      })}
    </div>
  );
}

export { FunnelChart };
