import type { IObservableValue } from 'mobx';
import { action, computed, makeObservable, observable, runInAction, when } from 'mobx';
import { fromPromise } from 'mobx-utils';

import { concatPath, moment } from '@feathr/hooks';
import type { IBaseAttributes, IRachisMessage, TConstraints } from '@feathr/rachis';
import { isWretchError, wretch } from '@feathr/rachis';
import { BulkCollection } from '@feathr/rachis';

import type { ICampaignAttributes, IPinpointTrigger } from './campaigns';
import type { IGoal } from './goals';
import { ECollectionClassName } from './model';
import type { IReportAttributes } from './model/report';
import { ReportModel } from './model/report';
import type { DailyStats } from './stats';
import type { ITargeting } from './targetings';

export type TComparison =
  | 'eq'
  | 'eq_date'
  | 'eq_date_r'
  | 'ne'
  | 'ne_date'
  | 'ne_date_r'
  | 'gt'
  | 'gt_date'
  | 'gt_date_r'
  | 'gte_date'
  | 'gte_date_r'
  | 'gt_r'
  | 'gte'
  | 'gte_r'
  | 'lt'
  | 'lt_date'
  | 'lt_date_r'
  | 'lte_date'
  | 'lte_date_r'
  | 'lt_r'
  | 'lte'
  | 'lte_r'
  | 'in'
  | 'nin'
  | 'exists'
  | 'nexists'
  | 'contains'
  | 'ncontains'
  | 'starts_with'
  | 'regexp'
  | 'wildcard'
  | 'contains_substring';

export type TPredicateKind = 'attribute' | 'activity' | 'update';
export type TPredicateMode = 'match_all' | 'match_any';
export type TLookbackMode = 'relative' | 'absolute' | 'unbounded' | 'before';

export interface ISegmentUsage extends Record<string, unknown> {
  active_campaigns: ICampaignAttributes[];
  targetings: ITargeting[];
  goals: IGoal[];
}

export interface IPredicate {
  attr_against?: string;
  comparison?: TComparison;
  attr_type?: 'date' | 'string' | 'integer' | 'float' | 'list' | 'boolean';
  value?: string | boolean | number | string[];
  unit?: 'days';
  kind?: TPredicateKind;
  group?: IPredicate[];
  group_mode?: TPredicateMode;
  /** @deprecated */
  _kind?: TPredicateKind;
}

export interface IUniquePredicate extends IPredicate {
  key: string;
}

export interface IPredicateGroup {
  kind?: TPredicateKind;
  mode: TPredicateMode;
  group: IPredicate[];
  /** @deprecated */
  _kind?: TPredicateKind;
}

interface ISegmentStats {
  coverages: {
    cookie: number;
    email: number;
  };
  num_cookies_total: number;
  num_crumbs_total: number;
  num_emails_total: number;
  num_users_active: number;
  num_users_new: number;
  num_users_total: number;
}

interface IFacebookCustomAudience {
  /** Lower estimate of audience size in Facebook */
  lower_bound: number;
  /** Higher estimate of audience size in Facebook */
  upper_bound: number;
}
export interface ISegment extends IReportAttributes {
  account: string;
  date_active_end?: string;
  date_active_start?: string;
  fb_custom_audience?: IFacebookCustomAudience;
  is_conversion_segment: boolean;
  is_custom: boolean;
  kind?: 'person_segment';
  lookback_mode: TLookbackMode;
  lookback_units?: 'days' | 'hours' | 'minutes';
  lookback_value: string | number;
  mode: TPredicateMode;
  name?: string;
  parent_segment?: string;
  predicates: IPredicate[];
  read_only: boolean;
  stats: Partial<ISegmentStats>; // TODO: Use common interface that campaign uses for stats
  tag_ids?: string[];
  user_can_clone: boolean;
  user_can_edit: boolean;
}

export interface ISegmentFetchFieldCardinalityResponse extends Record<string, unknown> {
  cardinality: number;
}

export interface ISegmentFetchCountResponse extends Record<string, unknown> {
  count: number;
}

type TGroupCloneParams = Partial<
  Pick<
    ISegment,
    'name' | 'predicates' | 'lookback_mode' | 'mode' | 'lookback_value' | 'lookback_units'
  >
>;

interface IPersonSegmentExport extends IBaseAttributes {
  created_by: string;
  date_created: string;
  export_field_headers: string[];
  export_fields: string[];
  export_url: string;
  internal: false;
  name: string;
  person_segment: string;
  rows: number;
  state: string;
}

interface ISegmentFetchExportResponse extends Record<string, unknown> {
  exports: IPersonSegmentExport[];
}

export interface IStatsPayload {
  predicates: IPredicate[];
  mode: TPredicateMode;
  lookback_mode: TLookbackMode;
  lookback_value: string | number;
  parent_segment?: string;
}

type TURLVariant = 'count' | 'reachable' | 'uniqueEmails' | 'crumbs';

function getStatsPayload(segment: Segment): IStatsPayload {
  const parentSegment = segment.get('parent_segment');

  const payload = {
    predicates: segment.get('predicates'),
    mode: segment.get('mode'),
    lookback_mode: segment.get('lookback_mode'),
    lookback_value: segment.get('lookback_value'),
  };

  if (parentSegment) {
    payload['parent_segment'] = parentSegment;
  }

  return payload;
}

// Transform the triggers to match the Segment predicates. Update trigger kinds cannot be used for stats.
export function transformTriggersToPredicates(triggers: IPinpointTrigger[]): IPredicate[] {
  return triggers.map((trigger) => {
    if (trigger.kind === 'update') {
      return { ...trigger, kind: 'attribute' };
    }
    return trigger;
  });
}

export class Segment extends ReportModel<ISegment> {
  public readonly className = 'Segment';

  public reportKey = 's' as const;

  public oldCount = 0;

  public oldReachable = 0;

  public oldCrumbs = 0;

  public oldUniqueEmails = 0;

  @observable public statsComplete = 0.0;

  @observable countPromiseState = 'pending';

  constructor(attributes: Partial<ISegment> = {}) {
    super(attributes);

    makeObservable(this);
  }

  @computed
  get countPromise() {
    return fromPromise(this.fetchCount());
  }

  @computed
  get count() {
    return this.countPromise.case({
      pending: (): number => {
        runInAction(() => {
          this.countPromiseState = 'pending';
        });
        return this.oldCount;
      },
      rejected: (): number => {
        runInAction(() => {
          this.countPromiseState = 'rejected';
        });

        return (this.oldCount = 0);
      },
      fulfilled: (value: number): number => {
        runInAction(() => {
          this.countPromiseState = 'fulfilled';
        });

        return (this.oldCount = value);
      },
    });
  }

  @computed
  public get name() {
    return (
      this.get('name', '').trim() ||
      (this.get('is_conversion_segment') ? 'Unnamed Conversion Pixel' : 'Unnamed Group')
    );
  }

  @computed
  public get permissions() {
    return {
      canClone: this.get('user_can_clone'),
      canEdit: this.get('user_can_edit'),
      isReadOnly: this.get('read_only'),
    };
  }

  @computed
  get reachablePromise() {
    return fromPromise(this.fetchReachable());
  }

  @computed
  get reachable() {
    return this.reachablePromise.case({
      pending: () => this.oldReachable,
      rejected: () => (this.oldReachable = 0),
      fulfilled: (value) => (this.oldReachable = value),
    });
  }

  @computed
  get uniqueEmailsPromise() {
    return fromPromise(this.fetchUniqueEmails());
  }

  @computed
  get emails() {
    return this.uniqueEmailsPromise.case({
      pending: () => this.oldUniqueEmails,
      rejected: () => (this.oldUniqueEmails = 0),
      fulfilled: (value) => (this.oldUniqueEmails = value),
    });
  }

  @computed
  get crumbsPromise() {
    return fromPromise(this.fetchCrumbs());
  }

  @computed
  get crumbs() {
    return this.crumbsPromise.case({
      pending: () => this.oldCrumbs,
      rejected: () => (this.oldCrumbs = 0),
      fulfilled: (value) => (this.oldCrumbs = value),
    });
  }

  public constraints: TConstraints<ISegment> = {
    name: {
      presence: {
        allowEmpty: false,
      },
    },
  };

  public getDefaults(): Partial<ISegment> {
    return {
      stats: {
        num_users_active: 0,
        num_users_new: 0,
        num_users_total: 0,
      },
    };
  }

  public isActive() {
    const now = moment.utc();
    const activeStart = moment.utc(this.get('date_active_start'));
    if (this.get('date_active_end')) {
      const activeEnd = moment.utc(this.get('date_active_end'));
      return now.isBefore(activeEnd) && now.isAfter(activeStart);
    }
    return now.isAfter(activeStart);
  }

  public getItemUrl(pathSuffix?: string) {
    return concatPath(`/data/segments/${this.id}`, pathSuffix);
  }

  public async fetchLiveStat<T extends Record<string, unknown>>(stat: TURLVariant): Promise<T> {
    this.assertCollection(this.collection, ECollectionClassName.Segment);

    const url = this.collection.url(stat);
    const headers = this.collection.getHeaders();
    const payload = getStatsPayload(this);
    const response = await wretch<T>(url, {
      headers,
      method: 'POST',
      body: JSON.stringify(payload),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public async fetchCount(): Promise<number> {
    try {
      const { count } = await this.fetchLiveStat<ISegmentFetchCountResponse>('count');
      return count;
    } catch (error) {
      return 0;
    }
  }

  public async fetchReachable(): Promise<number> {
    try {
      const { cardinality } =
        await this.fetchLiveStat<ISegmentFetchFieldCardinalityResponse>('reachable');
      return cardinality;
    } catch (error) {
      return 0;
    }
  }

  public async fetchUniqueEmails(): Promise<number> {
    try {
      const { cardinality } =
        await this.fetchLiveStat<ISegmentFetchFieldCardinalityResponse>('uniqueEmails');
      return cardinality;
    } catch (error) {
      return 0;
    }
  }

  public async fetchCrumbs(): Promise<number> {
    try {
      const { count } = await this.fetchLiveStat<ISegmentFetchCountResponse>('crumbs');
      return count;
    } catch (error) {
      return 0;
    }
  }

  @action
  public fetchStats(stats: DailyStats, start: string, end: string): IObservableValue<number> {
    this.assertCollection(this.collection, ECollectionClassName.Segment);

    const startMoment = moment.utc(start, moment.ISO_8601);
    const endMoment = moment.utc(end, moment.ISO_8601);
    const expectedResults = endMoment.diff(startMoment, 'days');
    const url = `${BLACKBOX_URL}segments/${this.id}/generate?start=${start}&end=${end}`;
    const headers = this.collection.getHeaders();
    const statsComplete = observable.box(0);
    // TODO: We should async/await the response from wretch, and add error handling.
    wretch<IRachisMessage>(url, { headers, method: 'GET' });
    const intervalHandle = window.setInterval(async () => {
      const segmentDailyStats = stats.list(
        {
          filters: {
            metadata__date__gte: start,
            metadata__date__lte: end,
            metadata__obj_id: this.id,
          },
          model: 'segment',
          ordering: ['metadata.date'],
        },
        { reset: true },
      );
      await when(() => !segmentDailyStats.isPending);
      const currentResults = segmentDailyStats.models.length;
      const complete = currentResults / expectedResults;
      if (complete >= 1.0) {
        window.clearInterval(intervalHandle);
      }
      runInAction(() => {
        statsComplete.set(complete);
      });
    }, 3000);
    return statsComplete;
  }

  public async getUsage(): Promise<ISegmentUsage> {
    this.assertCollection(this.collection, ECollectionClassName.Segment);

    const headers = this.collection.getHeaders();
    const url = `${BLACKBOX_URL}segments/${this.id}/usage`;
    const response = await wretch<ISegmentUsage>(url, {
      headers,
      method: 'GET',
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public async export(
    exportFields: string[],
    exportFieldHeaders: string[],
    email: string,
    exportMode: 'persons' | 'breadcrumbs',
  ): Promise<ISegmentFetchExportResponse> {
    this.assertCollection(this.collection, ECollectionClassName.Segment);

    const url = `${BLACKBOX_URL}segments/${this.id}/export`;
    const headers = this.collection.getHeaders();

    const response = await wretch<ISegmentFetchExportResponse>(url, {
      headers,
      method: 'POST',
      body: JSON.stringify({
        export_field_headers: exportFieldHeaders,
        export_fields: exportFields,
        email,
        export_mode: exportMode,
      }),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }
}

export class Segments extends BulkCollection<Segment> {
  public fullSegment: Segment | undefined = undefined;

  public getFullSegment() {
    if (!this.fullSegment) {
      this.fullSegment = this.create({
        name: 'Full Audience Group',
        lookback_mode: 'unbounded',
        lookback_value: 0,
        mode: 'match_any',
        predicates: [],
      });
    }
    return this.fullSegment;
  }

  public getModel(attributes: Partial<ISegment> = {}) {
    return new Segment(attributes);
  }

  public getClassName() {
    return 'person_segments';
  }

  public url(variant?: TURLVariant): string {
    const paths: Record<TURLVariant, string> = {
      count: 'persons/count',
      reachable: 'segments/field_cardinality/breadcrumbs.ttd_id',
      uniqueEmails: 'segments/field_cardinality/email',
      crumbs: 'breadcrumbs/count/',
    };
    return `${this.getHostname()}${variant ? paths[variant] : 'segments/'}`;
  }

  public async clone(parentSegment: string, data: TGroupCloneParams): Promise<Segment> {
    return super.clone(parentSegment, data);
  }
}
