import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { ITemplate, LandingPageCampaign } from '@feathr/blackbox';
import { CampaignState, TemplateClass } from '@feathr/blackbox';
import { Button, Input, Spinner, Tooltip } from '@feathr/components';
import TemplateSelectModal from '@feathr/extender/components/TemplateSelectModal';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';
import * as styles from './LandingPageTemplateEdit.css';

interface IProps {
  campaign: LandingPageCampaign;
}

function LandingPageTemplateEdit({ campaign }: Readonly<IProps>): JSX.Element {
  const { eventId } = useParams<{ eventId: string }>();
  const { Events, Templates } = useContext(StoresContext);
  const [previewLoaded, setPreviewLoaded] = useState(false);
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const event = Events.get(eventId);
  if (campaign.isPending || event.isPending) {
    return <Spinner />;
  }

  const template = Templates.get(campaign.get('template').id);

  const imageSrc = `${template.get('thumbnail_url')}?w=800&h=600&cb=${Date.now()}`;
  if (!previewLoaded) {
    const image = new Image();
    image.onload = () => {
      setPreviewLoaded(true);
    };
    image.src = imageSrc;
  }

  function onTemplateSelect(newTemplate: ITemplate): void {
    setPreviewLoaded(false);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { content, dynamic_content, metatags, thumbnail_url } = newTemplate;
    template.set({
      content,
      dynamic_content,
      metatags,
      thumbnail_url,
      _cls: TemplateClass.LandingPage,
    });
  }

  // Extract domain and port form short url
  const defaultDomain = new URL(BLACKBOX_SHORT_URL).host;
  const prefix = `https://${event.mktg_page_rdr_domain.get('domain') || defaultDomain}`;

  function handleLinkClick(): void {
    window.open(`${prefix}/${template.get('short_code')}`, '_blank');
  }

  return (
    <div className={styles.container}>
      <div className={styles.preview}>
        <div className={styles.urlWrapper}>
          <div className={styles.urlContainer}>
            <label>{t('Page URL')}</label>
            <div className={styles.urlActions}>
              <div className={styles.url}>
                <Input
                  attribute={'short_code'}
                  model={template}
                  prefix={`${prefix}/`}
                  type={'text'}
                />
              </div>
              {campaign.get('state') !== CampaignState.Draft && (
                <Tooltip
                  key={'gotopage'}
                  title={t(
                    'Changes to this Landing Page will be live in less than an hour. Please use Preview to see recent changes.',
                  )}
                >
                  <Button
                    disabled={template.isAttributeDirty('short_code')}
                    onClick={handleLinkClick}
                    type={'icon-outlined'}
                  >
                    {t('Go to page')}
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>

        <div>
          <label>{t('Preview')}</label>
          <div className={styles.previewContainer}>
            {previewLoaded ? (
              <div
                className={styles.previewImage}
                style={{ backgroundImage: `url(${imageSrc})` }}
              />
            ) : (
              <div className={classNames(skeleton, styles.previewSkeleton)} />
            )}
          </div>
          <div className={styles.container}>
            <div className={styles.action}>
              <Button link={localUrl(template.getItemUrl('edit'))}>Edit page</Button>
            </div>
            <div className={styles.action}>
              <TemplateSelectModal
                label={t('Change Template')}
                onSelect={onTemplateSelect}
                templateClass={TemplateClass.LandingPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(LandingPageTemplateEdit);
