import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

import { CampaignClass } from '@feathr/blackbox';
import { isDripCampaign, isEmailCampaign, isLandingPageCampaign } from '@feathr/blackbox';
import Page from '@feathr/extender/App/Page';
import { StoresContext } from '@feathr/extender/state';

import DefaultCampaignPage from './DefaultCampaignPage';
import DripCampaignReportPage from './DripCampaignReportPage';
import DefaultLandingPageCampaignPage from './LandingPageCampaignPage/DefaultLandingPageCampaignPage';
import PinpointEmailCampaignReportPage from './PinpointEmailCampaignReportPage';
import DefaultReferralCampaignPage from './ReferralCampaignPage/DefaultReferralCampaignPage';
import TrackedLinkCampaignPage from './TrackedLinkCampaignPage';

function CampaignRouter(): JSX.Element {
  const { eventId, campaignId } = useParams<{ eventId?: string; campaignId: string }>();
  const { Campaigns, Goals } = useContext(StoresContext);

  const campaign = Campaigns.get(campaignId);
  const goals = Goals.list({
    filters: { _parent: campaignId, is_archived__ne: true },
  });

  /*
   * Show a loading spinner while the campaign and goals are being fetched.
   * Page has a loading prop that will show a properly centered spinner. Page requires a child.
   */
  if (!eventId || campaign.isPending || goals.isPending) {
    return (
      <Page loading={true}>
        <></>
      </Page>
    );
  }

  if (campaign.get('_cls') === CampaignClass.Conversation) {
    return (
      <Switch>
        <Redirect
          exact={true}
          from={'/:accountId/projects/:eventId/campaigns/:campaignId'}
          to={'/:accountId/projects/:eventId/campaigns/:campaignId/edit'}
        />
        <Redirect
          exact={true}
          from={'/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId'}
          to={'/:accountId/projects/:eventId/flights/:flightId/campaigns/:campaignId/edit'}
        />
      </Switch>
    );
  }

  if (campaign.get('_cls') === CampaignClass.Referral) {
    return <DefaultReferralCampaignPage campaign={campaign} />;
  }

  if (campaign.get('_cls') === CampaignClass.TrackedLink) {
    return <TrackedLinkCampaignPage campaign={campaign} eventId={eventId} />;
  }

  if (isLandingPageCampaign(campaign)) {
    return <DefaultLandingPageCampaignPage campaign={campaign} />;
  }

  if (isDripCampaign(campaign)) {
    return <DripCampaignReportPage campaign={campaign} />;
  }

  if (isEmailCampaign(campaign)) {
    return <PinpointEmailCampaignReportPage campaign={campaign} />;
  }

  return <DefaultCampaignPage campaign={campaign} eventId={eventId} />;
}

export default observer(CampaignRouter);
