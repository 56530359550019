import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Billable, TVerifyAmounts } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  EAlertV2Type as EAlertType,
  Fieldset,
  NumberInput,
  toast,
} from '@feathr/components';

import * as styles from './BankAccountVerify.css';

interface IProps {
  billable: Billable;
}

function BankAccountVerify({ billable }: Readonly<IProps>): JSX.Element {
  const [amounts, setAmounts] = useState<TVerifyAmounts>([0, 0]);
  const { t } = useTranslation();

  function handleFirstAmountChange(newValue?: number): void {
    if ((newValue ?? 0) < 0 || (newValue ?? 0) > 99) {
      return;
    }
    setAmounts([newValue ?? 0, amounts[1]]);
  }

  function handleSecondAmountChange(newValue?: number): void {
    if ((newValue ?? 0) < 0 || (newValue ?? 0) > 99) {
      return;
    }
    setAmounts([amounts[0], newValue ?? 0]);
  }

  function handleInput(event: React.FormEvent<HTMLInputElement>): void {
    event.currentTarget.value = event.currentTarget.value.padStart(2, '0');
  }

  async function handleVerify(): Promise<void> {
    const model = await billable.verify(amounts);
    if (model.isErrored) {
      return;
    }
    toast(t('Your bank account has been verified.'), { type: 'success' });
  }

  return (
    // Use div instead of fragment to prevent gap and margin conflict in parent element
    <div>
      <Alert
        description={t(
          'Two micro-transactions should post to your account within 3 days. Enter them below to verify them.',
        )}
        title={t('Your bank account needs to be verified.')}
        type={EAlertType.warning}
      />
      <Fieldset
        className={styles.root}
        direction={'column'}
        helpText={t(
          'Enter the amount in cents below. For example, if the microdeposit amount is $0.35, enter 35.',
        )}
        label={t('Verify bank account')}
        validationError={
          billable.isErrored && billable.error?.message ? billable.error.message : undefined
        }
      >
        <div className={styles.sideBySide}>
          {/* TODO: Initial amount has single zero, not double. Once it changes, it works. */}
          <NumberInput
            label={t('Amount 1')}
            max={99}
            min={0}
            onChange={handleFirstAmountChange}
            onInput={handleInput}
            prefix={'$ 0.'}
            value={amounts[0]}
          />
          <NumberInput
            label={t('Amount 2')}
            max={99}
            min={0}
            onChange={handleSecondAmountChange}
            onInput={handleInput}
            prefix={'$ 0.'}
            value={amounts[1]}
          />
          <Button
            className={styles.button}
            disabled={!(amounts[0] > 0 && amounts[1] > 0)}
            onClick={handleVerify}
            type={'success'}
          >
            {t('Verify')}
          </Button>
        </div>
      </Fieldset>
    </div>
  );
}

export default observer(BankAccountVerify);
