import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Template } from '@feathr/blackbox';
import { EmptyState } from '@feathr/components';
import TemplateMergefieldDefaults from '@feathr/extender/App/TemplatePage/TemplateMetadataForm/TemplateMergefieldDefaults';

interface IMergeDataInputsProps {
  disabled: boolean;
  template?: Template;
}

/* TODO: Get working once we have a template to work with in #3704, just showing a placeholder for now */
function MergeDataInputs({ disabled, template }: Readonly<IMergeDataInputsProps>): JSX.Element {
  const { t } = useTranslation();

  return template && !!template.get('used_mergefields').length ? (
    <TemplateMergefieldDefaults disabled={disabled} template={template} />
  ) : (
    <EmptyState label={t('No merge tags to display')} />
  );
}

export default MergeDataInputs;
