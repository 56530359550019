import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCalendarCheck, faCalendarXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, Time } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IiMISLastSyncChipProps {
  dateLastSynced: string | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function ImisLastSyncChip({ dateLastSynced }: Readonly<IiMISLastSyncChipProps>): JSX.Element {
  const { t } = useTranslation();
  const isSynced = !!dateLastSynced;
  const chipColor: string = isSynced ? 'sky' : 'default';
  const chipIcon: IconDefinition = isSynced ? faCalendarCheck : faCalendarXmark;

  return (
    <>
      <Chip prefix={<FontAwesomeIcon icon={chipIcon} />} theme={chipColor}>
        {dateLastSynced ? (
          <Time format={TimeFormat.timeFromNow} timestamp={dateLastSynced} />
        ) : (
          t('Never')
        )}
      </Chip>
    </>
  );
}

export default ImisLastSyncChip;
