import { faLocation, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { ObjectId } from 'bson';
import type { FeatureCollection, Geometry, Polygon } from 'geojson';
import type {
  GeoJSONSource,
  GeoJSONSourceRaw,
  MapboxGeoJSONFeature,
  MapMouseEvent,
} from 'mapbox-gl';
import mapboxgl, { LngLat, LngLatBounds, Map, Popup } from 'mapbox-gl';
import type { IReactionDisposer } from 'mobx';
import { autorun, runInAction, set } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { Campaign, IGeoFencingPlace, Targetable } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { Button, Card, Collapse, Input, toast, Tooltip } from '@feathr/components';

import * as styles from './GeoFenceTargetingPlaces.css';

interface IProps {
  targetable: Targetable;
  campaign: Campaign;
}

type LngLatArray = [number, number];

function toRadians(angleInDegrees: number): number {
  return (angleInDegrees * Math.PI) / 180;
}

function toDegrees(angleInRadians: number): number {
  return (angleInRadians * 180) / Math.PI;
}

function offset(c1: LngLatArray, distance: number, bearing: number): LngLatArray {
  const lat1 = toRadians(c1[1]);
  const lon1 = toRadians(c1[0]);
  const dByR = distance / 6378137; // Distance divided by 6378137 (radius of the earth) wgs84
  const lat = Math.asin(
    Math.sin(lat1) * Math.cos(dByR) + Math.cos(lat1) * Math.sin(dByR) * Math.cos(bearing),
  );
  const lon =
    lon1 +
    Math.atan2(
      Math.sin(bearing) * Math.sin(dByR) * Math.cos(lat1),
      Math.cos(dByR) - Math.sin(lat1) * Math.sin(lat),
    );
  return [toDegrees(lon), toDegrees(lat)] as LngLatArray;
}

function circleToPolygon(center: LngLatArray, radius: number, numberOfSegments = 24): Polygon {
  const flatCoordinates: number[] = [];
  const coordinates: number[][] = [];
  for (let i = 0; i < numberOfSegments; ++i) {
    flatCoordinates.push(...offset(center, radius, (2 * Math.PI * i) / numberOfSegments));
  }
  flatCoordinates.push(flatCoordinates[0], flatCoordinates[1]);

  for (let i = 0, j = 0; j < flatCoordinates.length; j += 2) {
    coordinates[i++] = flatCoordinates.slice(j, j + 2);
  }

  return {
    type: 'Polygon',
    coordinates: [coordinates.reverse()],
  } as Polygon;
}

function radiusToZoom(radius: number): number {
  let zoom = 18;
  if (radius >= 30 && radius < 145) {
    zoom = 16;
  } else if (radius >= 145 && radius < 500) {
    zoom = 14;
  } else if (radius >= 500 && radius < 2000) {
    zoom = 12;
  } else if (radius >= 2000 && radius < 8000) {
    zoom = 10;
  } else if (radius >= 8000) {
    zoom = 8;
  }
  return zoom;
}

type ICoordinates = [number, number];

interface ICoordinateFeature {
  center: ICoordinates;
  geometry: {
    type: string;
    coordinates: ICoordinates;
  };
  place_name: string;
  place_type: string[];
  properties: Record<string, unknown>;
  type: 'Feature';
}

/*
 * Given a query in the form "lng, lat" or "lat, lng" returns the matching
 * geographic coordinate(s) as search results in carmen geojson format,
 * https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
 */
function coordinatesGeocoder(query: string): ICoordinateFeature[] | null {
  // Match anything which looks like a decimal degrees coordinate pair
  const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i);
  if (!matches) {
    return null;
  }

  function coordinateFeature(lng: number, lat: number): ICoordinateFeature {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      place_name: 'Lat: ' + lat + ' Lng: ' + lng,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature',
    };
  }

  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes = [] as ICoordinateFeature[];

  if (coord1 < -90 || coord1 > 90) {
    // Must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
    // Must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (geocodes.length === 0) {
    // Else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  return geocodes;
}

function targetableToFeatureCollection(
  places: IGeoFencingPlace[],
  radius: number,
): FeatureCollection {
  return {
    type: 'FeatureCollection',
    features: places.map((place) => ({
      type: 'Feature',
      properties: { id: place.id, name: place.name, lng: place.lng, lat: place.lat },
      geometry: circleToPolygon([place.lng, place.lat], radius),
    })),
  } as FeatureCollection<Geometry>;
}

function GeoFenceTargetingMap({ targetable, campaign }: IProps): JSX.Element {
  const mapRef = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<Map | undefined>(undefined);
  const [geocoder, setGeocoder] = React.useState<any | undefined>(undefined);

  React.useEffect(() => {
    let m: Map | undefined;
    if (mapRef.current) {
      const geojson: GeoJSONSourceRaw = {
        type: 'geojson',
        data: targetableToFeatureCollection(targetable.get('places', []), targetable.get('radius')),
      };
      m = new Map({
        container: mapRef.current,
        style: 'mapbox://styles/alevental/ck1vf3f9j5h7d1cqp1j64zjkx',
        center: [-82.330596, 29.65062],
        zoom: 2,
        antialias: true,
        attributionControl: false,
      });
      if (campaign.get('state') === CampaignState.Draft) {
        const g = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          flyTo: {
            zoom: 12,
          },
          localGeocoder: coordinatesGeocoder,
          mapboxgl,
          marker: false,
          placeholder: 'Name or coordinates...',
          types: 'poi',
        });
        m.addControl(g);
        setGeocoder(g);
      }

      m.on('load', () => {
        if (!m) {
          return;
        }
        m.addSource('places', geojson);
        m.addLayer({
          id: 'placesLayer',
          type: 'fill',
          source: 'places',
          paint: {
            'fill-color': 'rgba(255, 196, 37, 0.6)',
            'fill-outline-color': 'rgba(255, 135, 37, 0.6)',
          },
        });
        setMap(m);
      });
    }
    return () => {
      if (m) {
        m.remove();
      }
    };
  }, []);

  React.useEffect(() => {
    let autorunDisposer: IReactionDisposer | undefined;

    function onClick(clickEvent: MapMouseEvent): void {
      if (clickEvent.defaultPrevented) {
        return;
      }
      const place = {
        id: new ObjectId().toHexString(),
        lat: clickEvent.lngLat.lat,
        lng: clickEvent.lngLat.lng,
        name: 'Unnamed Place',
      };
      runInAction(() => {
        if (!targetable.get('places')) {
          targetable.set({ places: [place] });
        } else {
          const newPlaces = [...targetable.get('places', []), place];
          targetable.set({ places: newPlaces });
        }
      });
    }

    function onClickPlaces(
      clickEvent: MapMouseEvent & { features?: MapboxGeoJSONFeature[] },
    ): void {
      const feature = clickEvent.features ? clickEvent.features[0] : null;
      if (map && feature && feature.properties) {
        new Popup().setLngLat(clickEvent.lngLat).setHTML(feature.properties.name).addTo(map);
      }
      clickEvent.preventDefault();
    }

    function onMouseEnterPlaces(): void {
      if (map) {
        map.getCanvas().style.cursor = 'pointer';
      }
    }

    function onMouseLeavePlaces(): void {
      if (map) {
        map.getCanvas().style.cursor = '';
      }
    }

    function onGeocoderResult(resultEvent: any): void {
      if (geocoder) {
        const { result } = resultEvent;
        const place = {
          id: new ObjectId().toHexString(),
          lng: result.center[0],
          lat: result.center[1],
          name: result.place_name,
        };
        const existingPlaces: IGeoFencingPlace[] = targetable.get('places', []);
        if (existingPlaces.some((p) => p.lat === place.lat && p.lng === place.lng)) {
          return;
        }
        runInAction(() => {
          if (!targetable.get('places')) {
            targetable.set({ places: [place] });
          } else {
            const newPlaces = [...targetable.get('places', []), place];
            targetable.set({ places: newPlaces });
          }
        });
      }
    }

    if (map) {
      const places = targetable.get('places', []);
      if (places.length > 1) {
        try {
          const boundsStart = new LngLat(places[0].lng, places[0].lat);
          const bounds = places.reduce(
            (acc, place) => acc.extend(new LngLat(place.lng, place.lat)),
            new LngLatBounds(boundsStart, boundsStart),
          );
          map.fitBounds(bounds, { padding: 100 });
        } catch (error) {
          toast(`${error}`, { type: 'error' });
        }
      } else if (places.length === 1) {
        const zoom = radiusToZoom(targetable.get('radius'));
        try {
          map.easeTo({ center: [places[0].lng, places[0].lat], zoom });
        } catch (error) {
          toast(`${error}`, { type: 'error' });
        }
      }
    }

    if (campaign.get('state') === CampaignState.Draft && map) {
      map.on('click', 'placesLayer', onClickPlaces);
      map.on('mouseenter', 'placesLayer', onMouseEnterPlaces);
      map.on('mouseleave', 'placesLayer', onMouseLeavePlaces);
      map.on('click', onClick);
      autorunDisposer = autorun(() => {
        const radius = targetable.get('radius');
        const places = targetable.get('places', []);
        const placesSource = map && (map.getSource('places') as GeoJSONSource);
        if (placesSource) {
          const data: FeatureCollection = targetableToFeatureCollection(places, radius);
          placesSource.setData(data);
        }
      });
    }
    if (campaign.get('state') === CampaignState.Draft && geocoder) {
      geocoder.on('result', onGeocoderResult);
    }
    return function cleanup() {
      if (autorunDisposer) {
        autorunDisposer();
      }
      if (map) {
        map.off('click', onClick);
        map.off('click', 'placesLayer', onClickPlaces);
        map.off('mouseenter', 'placesLayer', onMouseEnterPlaces);
        map.off('mouseleave', 'placesLayer', onMouseLeavePlaces);
      }
      if (geocoder) {
        geocoder.off('results', onGeocoderResult);
      }
    };
  }, [map, targetable]);

  return (
    <div style={{ marginBottom: '10px' }}>
      <div ref={mapRef} style={{ position: 'relative', width: '100%', height: '300px' }} />
      <GeoFenceTargetingPlaces map={map} targetable={targetable} />
    </div>
  );
}

interface IPlacesProps {
  map?: Map;
  targetable: Targetable;
}

const GeoFenceTargetingPlaces = observer(({ map, targetable }: IPlacesProps) => {
  const places: IGeoFencingPlace[] = targetable.get('places', []);
  return (
    <Collapse className={styles.collapse} maxHeight={200} title={`Show Places (${places.length})`}>
      {places.map((place) => (
        <GeoFenceTargetingPlace
          key={place.id}
          map={map}
          place={place}
          places={places}
          radius={targetable.get('radius')}
          targetable={targetable}
        />
      ))}
    </Collapse>
  );
});

interface IPlaceProps {
  map?: Map;
  place: IGeoFencingPlace;
  places: IGeoFencingPlace[];
  radius: number;
  targetable: Targetable;
}

const GeoFenceTargetingPlace = observer(
  ({ map, place, places, radius, targetable }: IPlaceProps) => {
    const onRemove = (toRemove: IGeoFencingPlace) => {
      const index = places.findIndex((p) => p.id === toRemove.id);
      places.splice(index, 1);
    };

    const onGoTo = (toGoTo: IGeoFencingPlace) => {
      const destination = places.find((p) => p.id === toGoTo.id);
      if (map && destination) {
        const zoom = radiusToZoom(radius);
        try {
          map.easeTo({ center: [destination.lng, destination.lat], zoom });
        } catch (error) {
          toast(`${error}`, { type: 'error' });
        }
      }
    };

    function handleNameChange(newValue?: string): void {
      set(place, { name: newValue });
      targetable.setAttributeDirty('places');
    }

    return (
      <Card
        actions={[
          <Tooltip key={'goto'} title={'Center map on this Place'}>
            <Button name={'goto_place'} onClick={() => onGoTo(place)} type={'naked'}>
              <FontAwesomeIcon icon={faLocation} />
            </Button>
          </Tooltip>,
          <Tooltip key={'remove'} title={'Remove'}>
            <Button name={'remove_place'} onClick={() => onRemove(place)} type={'naked'}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>,
        ]}
        className={styles.placeCard}
      >
        <div>
          <label>Location</label>{' '}
          <span>
            {numeral(place.lng).format('00.000000')}, {numeral(place.lat).format('00.000000')}
          </span>
        </div>
        <Input
          label={'Name'}
          name={'place_name'}
          onChange={handleNameChange}
          type={'text'}
          value={place.name}
        />
      </Card>
    );
  },
);

export default observer(GeoFenceTargetingMap);
