import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TDefaultRoleId, UserRole } from '@feathr/blackbox';
import { List, ModalV1 } from '@feathr/components';
import {
  additionalPermissionDescriptionsMap,
  combinedPropertyDescriptionMap,
  getBinaryPermissionMode,
  getPermissionMode,
  readOnlyRolePrimaryValueMap,
} from '@feathr/extender/styles/user_roles';

interface IViewReadOnlyRoleModalProps {
  role: UserRole;
  onClose: () => void;
}

function ViewReadOnlyRoleModal({
  role,
  onClose,
}: Readonly<IViewReadOnlyRoleModalProps>): JSX.Element {
  const { t } = useTranslation();

  const title = t('View {{roleName}} Role', { roleName: role.name });

  // roleID should be a TDefaultRoleId because Admin and User are are read-only roles that are part of TDefaultRoleId.
  const { id: roleId } = role as { id: TDefaultRoleId };

  return (
    <ModalV1
      cancelButtonText={t('Close')}
      controlled={true}
      onClose={onClose}
      size={'sm'}
      t={t}
      title={title}
    >
      <List hasDividers={true}>
        <List.Item label={t('Role name')} primary={role.name} />
        <List.Item
          label={t('Projects')}
          primary={getPermissionMode(role.get('projects').mode, t)}
        />
        <List.Item label={t('Groups')} primary={getPermissionMode(role.get('segments').mode, t)} />
        <List.Item
          label={t('Allow conversions?')}
          primary={getBinaryPermissionMode(role.get('conversions').mode, t)}
          tooltip={additionalPermissionDescriptionsMap('conversions', t)}
        />
        <List.Item
          label={t('Allow billing?')}
          primary={getBinaryPermissionMode(role.get('billing').mode, t)}
          tooltip={additionalPermissionDescriptionsMap('billing', t)}
        />
        <List.Item
          label={t('Allow imports?')}
          primary={getBinaryPermissionMode(role.get('imports').mode, t)}
          tooltip={additionalPermissionDescriptionsMap('imports', t)}
        />
        <List.Item
          label={t('Allow domains?')}
          primary={getBinaryPermissionMode(role.get('domains').mode, t)}
          tooltip={additionalPermissionDescriptionsMap('domains', t)}
        />
        <List.Item
          label={t('Allow integrations?')}
          primary={getBinaryPermissionMode(role.get('integrations').mode, t)}
          tooltip={additionalPermissionDescriptionsMap('integrations', t)}
        />
        <List.Item
          label={t('Allow user and role management?')}
          primary={readOnlyRolePrimaryValueMap(roleId, t)}
          tooltip={combinedPropertyDescriptionMap('users_and_roles', t)}
        />
      </List>
    </ModalV1>
  );
}

export default ViewReadOnlyRoleModal;
