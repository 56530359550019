import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Notification, NotificationList } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './GlobalNotifications.css';

interface IProps {
  className?: string;
}

function GlobalNotifications({ className }: Readonly<IProps>): JSX.Element | null {
  const { t } = useTranslation();
  const { UserNotifications } = React.useContext(StoresContext);

  const filters = {
    is_read__ne: true,
    is_global: true,
  };

  const userNotifications = UserNotifications.list({
    filters,
    pagination: {
      page_size: 3,
    },
    ordering: ['-_id'],
  });

  if (userNotifications.isPending || userNotifications.models.length === 0) {
    return null;
  }

  async function handleMarkAsRead(notificationId: string): Promise<void> {
    const userNotification = userNotifications.models.find(
      (un) => un.get('notification_id') === notificationId,
    );

    if (userNotification && (await userNotification.read())) {
      UserNotifications.refreshApiCache();
    }
  }

  return (
    <NotificationList className={classNames(styles.root, className)}>
      {userNotifications.models.map((userNotification) => {
        const notification = userNotification.notification;
        if (notification.isPending) {
          return (
            <Notification isLoading={true} key={`loading-${userNotification.id}`} type={'banner'} />
          );
        }
        const action = notification.get('action');
        const level = notification.get('level');

        return (
          <li key={notification.id}>
            <Notification
              className={className}
              created={notification.get('timestamp')}
              id={notification.id}
              isRead={userNotification.get('is_read')}
              level={level}
              // Links are never relative and should not use localUrl
              link={notification.getCta()}
              linkText={action.text}
              // Don't use notification.getMessage() because we are using custom link text.
              markAsRead={level !== 2 ? handleMarkAsRead : undefined}
              message={notification.get('message')}
              t={t}
              title={notification.get('title')}
              type={'banner'}
            />
          </li>
        );
      })}
    </NotificationList>
  );
}

export default observer(GlobalNotifications);
