import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonValid } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';

import type { IPinpointEmailCampaignStepDesignBaseProps } from './PinpointEmailCampaignStepDesign';
import { validateStepDesign } from './PinpointEmailCampaignStepDesign';

function NextStepButton({
  campaign,
  onNext,
  template,
}: IPinpointEmailCampaignStepDesignBaseProps): JSX.Element {
  const { t } = useTranslation();
  const errors = validateStepDesign(campaign, template);
  return (
    <ButtonValid errors={flattenErrors(errors)} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
}

export default observer(NextStepButton);
