import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Creative } from '@feathr/blackbox';
import { CreativeClass } from '@feathr/blackbox';
import { Chip, TLDR } from '@feathr/components';
import CreativeThumbnail from '@feathr/extender/components/CreativeThumbnail';

import * as styles from './CreativeTLDR.css';

interface IProps {
  readonly creative?: Creative;
}

function CreativeTLDR({ creative }: IProps): JSX.Element | null {
  if (creative === undefined || creative.isPending) {
    return <TLDR isLoading={true} />;
  }

  const name = creative.get('name');
  const cls = creative.get('_cls') as CreativeClass;
  const hasLink = [
    CreativeClass.ReferralPage,
    CreativeClass.PartnerMessageEmail,
    CreativeClass.ReferralEmail,
  ].includes(cls);

  const spec =
    typeof creative['getSpec'] === 'function' ? (creative as Creative).getSpec() : undefined;

  function getType(): string {
    if (spec) {
      return spec.name;
    }
    switch (cls) {
      case CreativeClass.ReferralPage:
        return 'Page';

      case CreativeClass.ReferralBanner:
        return 'Banner';

      case CreativeClass.ReferralEmail:
        return 'Email';

      default:
        return '-';
    }
  }

  const type = getType();

  /*
   * Don't display this creative if we hit the getType()
   * default because there's probably bad data.
   */
  if (type === '-') {
    return null;
  }

  const dimensions =
    creative.get('width') && creative.get('height')
      ? `${creative.get('width')}px x ${creative.get('height')}px`
      : undefined;
  let imageComponent = (
    <CreativeThumbnail className={styles.root} creative={creative.toJS()} size={200} />
  );

  if (hasLink) {
    imageComponent = (
      <a href={creative.get('static_url')} target={'_blank'}>
        {imageComponent}
      </a>
    );
  }

  return (
    <TLDR
      description={
        <>
          {dimensions && <span className={styles.dimensions}>{dimensions}</span>}
          <Chip className={styles.chip}>{type}</Chip>
        </>
      }
      image={imageComponent}
      isLoading={false}
      title={name}
    />
  );
}

export default observer(CreativeTLDR);
