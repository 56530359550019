import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Nav, NavItem, NavMenu } from '@feathr/components';
import { useAccount, useLocalUrl, useRole } from '@feathr/extender/state';

function DataNav(): JSX.Element {
  const account = useAccount();
  const localUrl = useLocalUrl();
  const { hasImports, hasSegments, hasSegmentsSome, isSudoer } = useRole();
  const { t } = useTranslation();

  const exploreNav = (
    <Nav indented={true} label={t('Important groups of people navigation')} type={'vertical'}>
      <NavItem
        isForwardedFrom={localUrl('/data/explore')}
        to={localUrl('/data/explore/all/people')}
      >
        {t('All')}
      </NavItem>
      <NavItem to={localUrl('/data/explore/known/people')}>{t('Known')}</NavItem>
      <NavItem to={localUrl('/data/explore/anonymous/people')}>{t('Anonymous')}</NavItem>
    </Nav>
  );

  const pixelNav = (
    <Nav indented={true} label={t('Implementation navigation')} type={'vertical'}>
      <NavItem to={localUrl('/data/pixel/conversions')}>{t('Conversion Pixels')}</NavItem>
      {(account.hasPixelImplementation || isSudoer) && (
        <NavItem to={localUrl(`/data/pixel/implementations`)}>{t('Implementations')}</NavItem>
      )}
    </Nav>
  );

  return (
    <Nav indented={true} label={t('Community navigation')} type={'vertical'}>
      {hasSegments && (
        <NavMenu hasDefaultChild={true} menu={exploreNav} to={localUrl('/data/explore')}>
          {t('People')}
        </NavMenu>
      )}
      {(hasSegments || hasSegmentsSome) && (
        <NavItem to={localUrl('/data/segments')}>{t('Groups')}</NavItem>
      )}
      {hasSegments && account.isFalcon && (
        <NavItem to={localUrl('/data/custom-fields')}>{t('Custom Fields')}</NavItem>
      )}
      {hasImports && <NavItem to={localUrl('/data/imports')}>{t('Imports')}</NavItem>}
      <NavMenu menu={pixelNav} to={localUrl('/data/pixel')}>
        {t('Super Pixel')}
      </NavMenu>
    </Nav>
  );
}

export default DataNav;
