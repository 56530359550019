import type { WithT } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { ImisIntegration } from '@feathr/blackbox';
import { ButtonValid } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface INextStepButtonProps extends WithT {
  onNext: () => void | Promise<void>;
  integration: ImisIntegration;
  validation: (integration: ImisIntegration) => TValidateGrouped;
}

const NextStepButton = observer(
  ({ integration, onNext, t, validation }: INextStepButtonProps): JSX.Element => {
    const validationErrors = validation(integration);
    const errors = flattenErrors(validationErrors);
    return (
      <ButtonValid errors={errors} onClick={onNext}>
        {t('Next')}
      </ButtonValid>
    );
  },
);

export default NextStepButton;
