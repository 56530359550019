import { observer } from 'mobx-react-lite';
import React from 'react';

import type { IPartnerDashboardConfig } from '@feathr/blackbox';
import type { Event } from '@feathr/blackbox';
import { Checkbox, Fieldset, Input, RichTextEditor, Skeleton, Textarea } from '@feathr/components';

import PartnerInfoModuleConfig from '../PartnerInfoModuleConfig';

import * as styles from './PartnerDashboardModules.css';

interface IProps {
  event: Event;
}

function PartnerDashboardModules({ event }: IProps) {
  const partnerDashboardConfig = event.get('partner_dashboard_config');

  function toggleSetting(setting: keyof IPartnerDashboardConfig) {
    return () => {
      const settings = partnerDashboardConfig;
      event.set({
        partner_dashboard_config: {
          ...settings,
          [setting]: !partnerDashboardConfig[setting],
        },
      });
    };
  }

  return (
    <Fieldset label={'Partner Dashboard Tiles'}>
      {event.isPending ? (
        <Fieldset
          className={styles.setting}
          label={<Skeleton paragraphs={0} title={true} width={300} />}
        >
          <Skeleton paragraphs={3} title={true} />
        </Fieldset>
      ) : (
        <>
          <Fieldset
            className={styles.setting}
            label={
              <Checkbox
                label={'Show Partner Info Tile'}
                onChange={toggleSetting('show_info_module')}
                value={partnerDashboardConfig.show_info_module}
              />
            }
          >
            {partnerDashboardConfig.show_info_module ? (
              <PartnerInfoModuleConfig event={event} />
            ) : (
              <p className={styles.disabled}>Hidden from partners.</p>
            )}
          </Fieldset>
          <Fieldset
            className={styles.setting}
            label={
              <Checkbox
                label={'Show Landing Pages Tile'}
                onChange={toggleSetting('show_page_module')}
                value={partnerDashboardConfig.show_page_module}
              />
            }
          >
            {partnerDashboardConfig.show_page_module ? (
              <>
                <Input
                  attribute={['partner_dashboard_config', 'page_text']}
                  label={'Invite Page Tile Title'}
                  model={event}
                  type={'text'}
                />
                <RichTextEditor
                  attribute={['partner_dashboard_config', 'page_description']}
                  label={'Invite Page Tile Description'}
                  model={event}
                />
              </>
            ) : (
              <p className={styles.disabled}>Hidden from partners.</p>
            )}
          </Fieldset>
          <Fieldset
            className={styles.setting}
            label={
              <Checkbox
                label={'Show Email Invites Tile'}
                onChange={toggleSetting('show_email_module')}
                value={partnerDashboardConfig.show_email_module}
              />
            }
          >
            {partnerDashboardConfig.show_email_module ? (
              <>
                <Input
                  attribute={['partner_dashboard_config', 'invite_text']}
                  label={'Invite Email Tile Title'}
                  model={event}
                  type={'text'}
                />
                <RichTextEditor
                  attribute={['partner_dashboard_config', 'invite_description']}
                  label={'Invite Email Tile Description'}
                  model={event}
                />
              </>
            ) : (
              <p className={styles.disabled}>Hidden from partners.</p>
            )}
          </Fieldset>
          <Fieldset
            className={styles.setting}
            label={
              <Checkbox
                label={'Show Banners Tile'}
                onChange={toggleSetting('show_banner_module')}
                value={partnerDashboardConfig.show_banner_module}
              />
            }
          >
            {partnerDashboardConfig.show_banner_module ? (
              <>
                <Input
                  attribute={['partner_dashboard_config', 'banner_text']}
                  label={'Invite Banner Tile Title'}
                  model={event}
                  type={'text'}
                />
                <RichTextEditor
                  attribute={['partner_dashboard_config', 'banner_description']}
                  label={'Invite Banner Tile Description'}
                  model={event}
                />
              </>
            ) : (
              <p className={styles.disabled}>Hidden from partners.</p>
            )}
          </Fieldset>
          <Fieldset
            className={styles.setting}
            label={
              <Checkbox
                label={'Show Partner Leads Tile'}
                onChange={toggleSetting('show_partner_leads')}
                value={partnerDashboardConfig.show_partner_leads}
              />
            }
          >
            {partnerDashboardConfig.show_partner_leads ? (
              <>
                <Input
                  attribute={['partner_dashboard_config', 'lead_text']}
                  label={'Leads Tile Title'}
                  model={event}
                  type={'text'}
                />
                <Textarea
                  attribute={['partner_dashboard_config', 'lead_description']}
                  label={'Leads Tile Description'}
                  model={event}
                />
              </>
            ) : (
              <p className={styles.disabled}>Hidden from partners.</p>
            )}
          </Fieldset>
          <Fieldset
            className={styles.setting}
            label={
              <Checkbox
                label={'Show Monetization Campaigns Tile'}
                onChange={toggleSetting('show_monetization_module')}
                value={partnerDashboardConfig.show_monetization_module}
              />
            }
          >
            {partnerDashboardConfig.show_monetization_module ? (
              <>
                <Input
                  attribute={['partner_dashboard_config', 'monetization_text']}
                  label={'Monetization Tile Title'}
                  model={event}
                  type={'text'}
                />
                <Textarea
                  attribute={['partner_dashboard_config', 'monetization_description']}
                  label={'Monetization Tile Description'}
                  model={event}
                />
              </>
            ) : (
              <p className={styles.disabled}>Hidden from partners.</p>
            )}
          </Fieldset>
        </>
      )}
    </Fieldset>
  );
}

export default observer(PartnerDashboardModules);
