import { observer } from 'mobx-react-lite';
import React from 'react';

import type { Event } from '@feathr/blackbox';
import { Fieldset, Input, Textarea } from '@feathr/components';

import PartnerFields from './PartnerFields';

interface IProps {
  event: Event;
}

function PartnerInfoModuleConfig({ event }: IProps) {
  return (
    <Fieldset>
      <Input
        attribute={['partner_dashboard_config', 'info_text']}
        helpText={
          'This is the text that will appear as the name of the module on the Partner Dashboard welcome page.'
        }
        label={'Info Module Name'}
        model={event}
        type={'text'}
      />
      <Textarea
        attribute={['partner_dashboard_config', 'info_description']}
        helpText={'This is the text that will appear at the top of the Info module page.'}
        label={'Info Module Description'}
        model={event}
        rows={4}
      />
      <PartnerFields event={event} />
    </Fieldset>
  );
}

export default observer(PartnerInfoModuleConfig);
