import { Observer } from 'mobx-react-lite';
import React from 'react';

import type { IStats } from '@feathr/blackbox';
import { TableColumnHeader } from '@feathr/components';

import type { IData, ITemplateRow } from './TemplatesTable';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const TemplateColumnViews = {
  id: 'views',
  Header: TableColumnHeader({
    title: 'Views',
  }),
  sortable: false,
  className: tableStyles.cellRight,
  width: 80,
  accessor({ template }: IData) {
    return (
      <Observer>
        {() => {
          const stats: IStats = template.get('stats') || {};
          const flavors = stats.flavors || {};
          const adView = flavors.ad_view || 0;
          const pageView = flavors.page_view || 0;
          const emailView = flavors.email_view || 0;
          return <>{adView + pageView + emailView}</>;
        }}
      </Observer>
    );
  },
  Cell({ original }: ITemplateRow) {
    return (
      <Observer>
        {() => {
          const { template } = original;
          const stats: IStats = template.get('stats') || {};
          const flavors = stats.flavors || {};
          const adView = flavors.ad_view || 0;
          const pageView = flavors.page_view || 0;
          const emailView = flavors.email_view || 0;
          return <>{(adView + pageView + emailView).toLocaleString()}</>;
        }}
      </Observer>
    );
  },
};

export default TemplateColumnViews;
