import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IQuestion } from '@feathr/blackbox';
import { Button, CardV2 as Card, Input, NumberInput } from '@feathr/components'; // eslint-disable-line
import { useLocalUrl } from '@feathr/extender/state';

import * as styles from './QuestionConfig.css';

const addAnswer = (question: IQuestion) => (): void => {
  question.answers.push({ text: '', uuid: nanoid(12) });
};

const removeAnswer = (question: IQuestion, index: number) => (): void => {
  question.answers.splice(index, 1);
};

interface IProps {
  readonly question: IQuestion;
}

function QuestionConfig({ question }: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  function handleTextChange(newValue?: string): void {
    question.text = newValue ?? '';
  }

  function handleResponseChange(newValue?: string): void {
    question.response = newValue ?? '';
  }

  function handleDelayChange(newValue?: number): void {
    question.delay = (newValue ?? 0) * 1000;
  }

  return (
    <div>
      <Input
        className={styles.row}
        helpText={t('What do you want to ask your audience?')}
        label={t('Question text')}
        onChange={handleTextChange}
        placeholder={t('What part of CookieCon are you most excited about this year?')}
        type={'text'}
        value={question.text}
      />
      <Input
        className={styles.row}
        helpText={t('This will be the text that users see after answering the question.')}
        label={t('Response')}
        onChange={handleResponseChange}
        placeholder={t('Thank you for your response!')}
        type={'text'}
        value={question.response}
      />
      <NumberInput
        className={styles.row}
        helpText={t(
          'How long should Conversations wait before prompting the user with your question?',
        )}
        label={t('Delay (in seconds)')}
        onChange={handleDelayChange}
        value={question.delay / 1000}
      />

      <Card contentClassName={styles.answersHeader}>
        <Card.Header title={t('Answers')} />
        <Card.Content addVerticalGap={true}>
          {question.answers.length === 0 && <div>{t('No answers yet.')}</div>}

          {question.answers.map((answer, index) => {
            function handleAnswerChange(newValue?: string): void {
              answer.text = newValue ?? '';
            }

            return (
              <div className={styles.answer} key={answer.segment || answer.uuid}>
                <Input
                  label={t('Answer {{num}}', { num: index + 1 })}
                  onChange={handleAnswerChange}
                  type={'text'}
                  value={answer.text}
                  wrapperClassName={styles.answerInput}
                />
                {answer.segment && (
                  <Button
                    link={localUrl(`/data/segments/${answer.segment}`)}
                    target={'_blank'}
                    type={'icon-outlined'}
                  >
                    {t('View group')}
                  </Button>
                )}
                <Button onClick={removeAnswer(question, index)} type={'icon-outlined'}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            );
          })}
        </Card.Content>
        <Card.Actions>
          <Button onClick={addAnswer(question)}>{t('Add answer')}</Button>
        </Card.Actions>
      </Card>
    </div>
  );
}

export default observer(QuestionConfig);
