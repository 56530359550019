import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import type { IAdTagCreative, ICreativeAttributes } from '@feathr/blackbox';
import { CreativeClass, creativeTypeLabel, disambiguateCreative } from '@feathr/blackbox';
import { Button, ModalV1, Section, toast } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import CreativeTypeSelect from './CreativeTypeSelect';

import * as styles from './AddCreativeButton.css';

function AddCreativeButton(): JSX.Element {
  const { Creatives } = useContext(StoresContext);

  const history = useHistory();
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const { t } = useTranslation();

  async function addCreative(type: CreativeClass): Promise<void> {
    const creative =
      type === CreativeClass.DisplayAdTag
        ? ({ _cls: type, adtag: '', event: eventId, mimetype: 'text/html' } as IAdTagCreative)
        : ({ _cls: type, event: eventId } as ICreativeAttributes);
    const response = await Creatives.add(disambiguateCreative(creative), { validate: false });
    toast(`${creativeTypeLabel(type)} creative created.`, { type: 'success' });
    toggleIsModalOpen();

    history.push(localUrl(response.getItemUrl('edit')));
  }

  const uploadCreatives: CreativeClass[] = Object.values(CreativeClass).filter(
    (c) => c.includes('DisplayCreative') && !c.includes('Bannersnack'),
  );

  const buildCreatives: CreativeClass[] = Object.values(CreativeClass).filter(
    (c) => c.includes('DisplayCreative') && c.includes('Bannersnack'),
  );

  return (
    <>
      <Button
        icon={<FontAwesomeIcon icon={faPlus} />}
        key={'add'}
        name={'add_creative'}
        onClick={toggleIsModalOpen}
        type={'primary'}
      >
        {t('Add creative')}
      </Button>
      {isModalOpen && (
        <ModalV1
          controlled={true}
          onClose={toggleIsModalOpen}
          t={t}
          title={t('What type of creative would you like to add?')}
        >
          <Section
            className={styles.section}
            contentClassName={styles.sectionContent}
            headerClassName={styles.sectionHeader}
            title={t('Upload Creative')}
          >
            <CreativeTypeSelect addCreative={addCreative} creativeClasses={uploadCreatives} />
          </Section>

          <Section
            className={styles.section}
            contentClassName={styles.sectionContent}
            headerClassName={styles.sectionHeader}
            title={t('Build Creative')}
          >
            <CreativeTypeSelect addCreative={addCreative} creativeClasses={buildCreatives} />
          </Section>
        </ModalV1>
      )}
    </>
  );
}

export default observer(AddCreativeButton);
