import { observer } from 'mobx-react-lite';
import React from 'react';

import type { ReferralCampaign } from '@feathr/blackbox';
import { Button, ButtonValid, Fieldset, Form, Input } from '@feathr/components';
import { flattenError, flattenErrors } from '@feathr/hooks';
import type { IValidateGrouped } from '@feathr/rachis';

interface IProps {
  campaign: ReferralCampaign;
  onNext: () => void;
  onPrev: () => void;
}

export function validateStepFour(campaign: ReferralCampaign): IValidateGrouped {
  const fields: string[] = [];
  if (campaign.get('banner_templates', []).length) {
    fields.push('destination_url', 'banner_templates');
  }
  if (campaign.get('page_templates', []).length) {
    fields.push('twitter_text', 'twitter_hashtags', 'page_templates');
  }
  // Don't validate when fields is empty, or everything will get validated.
  return (fields.length ? campaign.validate(fields, false, 'grouped').errors : {}) as Record<
    string,
    string[]
  >;
}

const NextStepButton = observer(({ campaign, onNext }: Omit<IProps, 'onPrev'>) => {
  const errors = validateStepFour(campaign);
  return (
    <ButtonValid errors={flattenErrors(errors)} onClick={onNext}>
      Next
    </ButtonValid>
  );
});

function StepFour({ campaign, onNext, onPrev }: IProps) {
  const validationErrors = validateStepFour(campaign);

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          Previous
        </Button>,
        <NextStepButton campaign={campaign} key={'next'} onNext={onNext} />,
      ]}
      label={'Edit Campaign: Metadata'}
    >
      {!!campaign.get('banner_templates', []).length && (
        <Fieldset label={'Banners'}>
          <Input
            attribute={'destination_url'}
            helpPlacement={'bottom'}
            helpText={'Set the destination URL for all banners.'}
            label={'Destination URL'}
            model={campaign}
            required={true}
            type={'text'}
            validationError={flattenError(validationErrors.destination_url)}
          />
        </Fieldset>
      )}
      {!!campaign.get('page_templates', []).length && (
        <Fieldset label={'Landing pages'}>
          <Input
            attribute={'twitter_text'}
            helpPlacement={'bottom'}
            helpText={
              'This text will be pre-filled in social sharing features that are available to your partners.'
            }
            label={'Social share text'}
            model={campaign}
            optional={true}
            type={'text'}
            validationError={flattenError(validationErrors.twitter_text)}
          />
          <Input
            attribute={'twitter_hashtags'}
            helpPlacement={'bottom'}
            helpText={
              <>
                These tags will be automatically included in social sharing features available to
                your partners. Do not include the "#" sign. Add multiple tags by separating them
                with a comma.
                <br />
                E.g. <em>hashtagone,hashtagtwo</em>
              </>
            }
            label={'Social share tags'}
            model={campaign}
            optional={true}
            type={'text'}
            validationError={flattenError(validationErrors.twitter_hashtags)}
          />
        </Fieldset>
      )}
    </Form>
  );
}

export default observer(StepFour);
