import { faRotate } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { ImisIntegration } from '@feathr/blackbox';
import { Button, toast } from '@feathr/components';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IiMISSyncControlProps {
  integration: ImisIntegration;
}

function ImisSyncControl({ integration }: IiMISSyncControlProps): JSX.Element | null {
  const { t } = useTranslation();

  async function handleSync(): Promise<void> {
    try {
      await integration.syncIntegration();
      toast(t('Your iMIS integration has started syncing'), {
        type: ToastType.SUCCESS,
      });
    } catch (error) {
      toast(
        t('Something went wrong while trying to resume your iMIS integration: {{error}}', {
          error,
        }),
        {
          type: ToastType.ERROR,
        },
      );
    } finally {
      // TODO: Hard reloading will not be necesary with the implemention #2651.
      window.location.reload();
    }
  }

  return (
    <>
      <Button
        key={'sync-imis-integration'}
        name={'sync-imis-integration'}
        onClick={handleSync}
        prefix={<FontAwesomeIcon icon={faRotate} />}
        type={'primary'}
      >
        {t('Sync integration')}
      </Button>
    </>
  );
}

export default observer(ImisSyncControl);
