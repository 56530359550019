import { faChevronRight, faEllipsis } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { FacebookDisplayCreative, IFacebookPage } from '@feathr/blackbox';
import { CallToActionTypes, CreativeClass } from '@feathr/blackbox';

import * as styles from './InstagramAdPreview.css';

interface IInstagramAdPreviewProps {
  creative: FacebookDisplayCreative;
  page: IFacebookPage;
}

/*
 * The InstagramAdPreview component was created because of an issue with Facebook (their fault).
 * When we request an Instagram preview from Facebook, they return an iframe, as expected, but
 * the image's source in the iframe is not from a domain Facebook trusts. For the facebook
 * preview, they accept the s3 bucket image, then make a copy they store in their own
 * repository, usually fbcdn.net, then use that as the image source. The instagram preview's
 * image source is straight from our s3 bucket, and this causes the image to not load. We
 * can't change the source of the image, so we have to create our own preview.
 *
 * This is a temporary solution until Facebook fixes their issue, which may be never.
 *
 * Here's a comment I, Chris Folsom, left on the Facebook support website:
 * @see https://developers.facebook.com/support/bugs/1689031624801128/?join_id=f14d92a82ecebb
 */
function InstagramAdPreview({ creative, page }: IInstagramAdPreviewProps): JSX.Element {
  const { t } = useTranslation();
  const creativeClass = creative.get('_cls');
  const creativeUrl = creative.get('url');
  const creativeMessage = creative.get('message');
  return (
    <div className={styles.instagramAdPreview}>
      <img
        alt={'Instagram ad preview header'}
        className={styles.header}
        src={'https://facebook.com/images/instagram/ads/preview/header-background-2x.png'}
      />
      <div className={styles.top}>
        <div className={styles.topLeft}>
          <img alt={'Facebook page logo'} className={styles.logo} src={page.picture} />
          <div className={styles.text}>
            <p className={styles.name}>{page.instagram.username}</p>
            <p className={styles.sponsored}>{t('Sponsored')}</p>
          </div>
        </div>
        <div className={styles.topRight}>
          <FontAwesomeIcon icon={faEllipsis} size={'2x'} />
        </div>
      </div>
      {creativeClass === CreativeClass.FacebookImage && (
        <img alt={'Ad'} className={styles.creativeImage} src={creativeUrl} />
      )}
      {creativeClass === CreativeClass.FacebookVideo && (
        <video
          autoPlay={true}
          className={styles.creativeImage}
          controls={true}
          loop={true}
          src={creativeUrl}
        />
      )}
      <div className={styles.bottom}>
        <div className={styles.callToAction}>
          <p className={styles.callToActionText}>
            {/* creatives.ts has call_to_action_type always be required, and is of type CallToActionTypes,
            so it should always exist. */}
            {CallToActionTypes.find((t) => t.id === creative.get('call_to_action_type'))!.name}
          </p>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className={styles.allButtons}>
          <div className={styles.threeButtons}>
            <img alt={'Like'} src={'https://facebook.com//images/instagram/ads/preview/Like.png'} />
            <img
              alt={'Comment'}
              src={'https://facebook.com/images/instagram/ads/preview/Comment.png'}
            />
            <img
              alt={'Share'}
              src={'https://facebook.com/images/instagram/ads/preview/direct-share.png'}
            />
          </div>
          <div className={styles.saveButton}>
            <img alt={'Save'} src={'https://facebook.com/images/instagram/ads/preview/save.png'} />
          </div>
        </div>
        {creativeMessage && (
          <div className={styles.primaryText}>
            <p>
              <strong>{page.instagram.username}</strong> {creativeMessage}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(InstagramAdPreview);
