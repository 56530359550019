import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign, IStats } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function getResponses(stats: IStats): number {
  const flavors = stats.flavors || {};
  return flavors.conversation_response || 0;
}

const ResponsesColumn: IColumn<BaseCampaign> = {
  id: 'stats__flavors__conversation_response',
  checkboxLabel: 'Responses',
  Header: TableColumnHeader({
    title: 'Responses',
    sortType: 'numeric',
  }),
  headerClassName: tableStyles.sort,
  className: tableStyles.cellRight,
  width: 126,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const responses = getResponses(original.get('total_stats'));
          return <>{responses.toLocaleString()}</>;
        }}
      </Observer>
    );
  },
  Footer({ data }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const total = data.reduce((subtotal, currentValue) => {
            const response = getResponses(currentValue._original.get('total_stats'));
            return subtotal + response;
          }, 0);
          return <>{total.toLocaleString()}</>;
        }}
      </Observer>
    );
  },
};

export default ResponsesColumn;
