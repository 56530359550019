import capitalize from 'lodash.capitalize';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import type { BaseCampaign, PinpointPartnerMessage } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Chip, TableColumnHeader, Time } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { campaignStateColorMap } from '@feathr/extender/styles/campaign';
import { TimeFormat } from '@feathr/hooks';

import EventPartnersMessagesOptions from './EventPartnersMessagesOptions';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const eventPartnersMessagesColumns: Array<IColumn<BaseCampaign>> = [
  {
    id: 'name',
    Header: TableColumnHeader({
      sortType: 'numeric',
      title: 'Name',
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    minWidth: 200,
    Cell({ original }): JSX.Element {
      return (
        <Observer>
          {function useAnonymousFunction(): JSX.Element {
            const localUrl = useLocalUrl();
            const name = original.get('name');
            return <Link to={localUrl(original.getItemUrl())}>{name || 'Unnamed message'}</Link>;
          }}
        </Observer>
      );
    },
  },
  {
    id: 'state',
    Header: TableColumnHeader({
      sortType: 'alpha',
      title: 'Status',
    }),
    headerClassName: tableStyles.sort,
    Cell({ original }): JSX.Element {
      const state = original.get('state');
      return <Chip theme={campaignStateColorMap.get(state)}>{capitalize(state)}</Chip>;
    },
  },
  {
    id: 'date_created',
    Header: TableColumnHeader({
      sortType: 'numeric',
      title: 'Created',
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    width: 110,
    Cell({ original }): JSX.Element {
      return <Time format={TimeFormat.shortDateTime} timestamp={original.get('date_created')} />;
    },
  },
  {
    id: 'date_send_start',
    Header: TableColumnHeader({
      sortType: 'numeric',
      title: 'Send Time',
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    minWidth: 176,
    Cell({ original }): JSX.Element {
      return <Time format={TimeFormat.shortDateTime} timestamp={original.get('date_send_start')} />;
    },
  },
  {
    id: 'from_address',
    Header: TableColumnHeader({
      sortType: 'alpha',
      title: 'From',
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    minWidth: 170,
    accessor: 'attributes.from_address',
  },
  {
    id: 'sent',
    Header: TableColumnHeader({
      title: 'Sent',
    }),
    className: tableStyles.cellRight,
    maxWidth: 100,
    sortable: false,
    Cell(row): JSX.Element {
      const flavors = row.original.attributes.stats?.flavors || {};
      const emailSend = flavors.email_send || 0;
      const partnerMessageSend = flavors.partner_message_send || 0;
      return (emailSend + partnerMessageSend).toLocaleString();
    },
  },
  {
    id: 'opened',
    Header: TableColumnHeader({
      title: 'Opened',
    }),
    className: tableStyles.cellRight,
    maxWidth: 100,
    sortable: false,
    Cell(row): JSX.Element {
      const flavors = row.original.attributes.stats?.flavors || {};
      const emailView = flavors.email_view || 0;
      const partnerMessageView = flavors.partner_message_view || 0;
      return (emailView + partnerMessageView).toLocaleString();
    },
  },
  {
    id: 'clicked',
    Header: TableColumnHeader({
      title: 'Clicked',
    }),
    className: tableStyles.cellRight,
    maxWidth: 100,
    sortable: false,
    Cell(row): JSX.Element {
      const flavors = row.original.attributes.stats?.flavors || {};
      const emailLinkClick = flavors.email_link_click || 0;
      const partnerMessageLinkClick = flavors.partner_message_link_click || 0;
      return (emailLinkClick + partnerMessageLinkClick).toLocaleString();
    },
  },
  {
    id: 'options',
    Header: TableColumnHeader({
      title: 'Options',
    }),
    className: tableStyles.cellCenter,
    width: 80,
    sortable: false,
    Cell({ original }): JSX.Element {
      return <EventPartnersMessagesOptions message={original as PinpointPartnerMessage} />;
    },
  },
];

export default eventPartnersMessagesColumns;
