import { Observer } from 'mobx-react-lite';
import React from 'react';

import { PlaceholderImage, TableColumnHeader } from '@feathr/components';

import type { ITemplateRow } from '../TemplatesTable';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

import noImg from 'images/no-img.png';

const TemplateColumnThumbnail = {
  id: 'logo',
  Header: TableColumnHeader({
    title: 'Thumbnail',
  }),
  width: 110,
  sortable: false,
  className: tableStyles.cellCenter,
  Cell({ original }: ITemplateRow) {
    return (
      <Observer>
        {() => {
          const { template } = original;
          const url = template.get('thumbnail_url', noImg);
          let thumbnail = (
            <img alt={''} src={url} style={{ maxHeight: '80px', maxWidth: '110px' }} />
          );
          if (template.isBanner && template.shouldUsePlaceholder()) {
            thumbnail = (
              <PlaceholderImage
                height={template.get('height')!}
                style={{ maxHeight: '80px', maxWidth: '110px' }}
                width={template.get('width')!}
              />
            );
          }
          return thumbnail;
        }}
      </Observer>
    );
  },
};

export default TemplateColumnThumbnail;
