import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { ImisIntegration } from '@feathr/blackbox';
import { CardV2 as Card, Input, toast, Toolbar } from '@feathr/components';
import { flattenError } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import type { TConfigurationContext } from './ImisConfiguration';
import NextStepButton from './NextStepButton';

import * as styles from './ImisConfigStepOne.css';

interface IStepOneProps {
  context?: TConfigurationContext;
  onNext: () => void;
  integration: ImisIntegration;
}

function validateStepOne(integration: ImisIntegration): TValidateGrouped {
  return integration.validate(['org_site'], false, 'grouped').errors;
}

function ImisConfigStepOne({
  context = 'wizard',
  onNext,
  integration,
}: Readonly<IStepOneProps>): JSX.Element {
  const { t } = useTranslation();
  const orgSite: string = integration.get('org_site', '');
  const validationErrors = validateStepOne(integration);

  function handleOrganizationChange(value?: string): void {
    const orgSite = value ?? '';
    integration.set({ org_site: orgSite });
  }

  async function handleSave(): Promise<void> {
    if (!integration) {
      toast(t('An error occurred while saving your iMIS integration.'), {
        type: ToastType.ERROR,
      });
      return;
    }
    if (integration.isDirty) {
      try {
        await integration.patchDirty();
        toast(t('Your iMIS integration was successfully updated.'), { type: ToastType.SUCCESS });
      } catch (error) {
        toast(t('An error occurred while saving your iMIS integration. {{error}}', { error }), {
          type: ToastType.ERROR,
        });
      }
    }
  }

  async function handleNext(): Promise<void> {
    if (integration) {
      await handleSave();
    }
    onNext();
  }

  const description =
    context === 'wizard'
      ? t('Enter the domain for your iMIS organization.')
      : integration.get('org_site');
  const title = context === 'wizard' ? t('Set Organization') : t('Organization Domain');

  return (
    <>
      <Card contentClassName={styles.root}>
        <Card.Header description={description} title={title} />
        {context === 'wizard' && (
          <Card.Content>
            <Input
              helpText={t('e.g. https://myorganization.imiscloud.com')}
              label={t('Organization domain')}
              onChange={handleOrganizationChange}
              required={true}
              type={'text'}
              validationError={flattenError(validationErrors.org_site)}
              value={orgSite}
            />
          </Card.Content>
        )}
      </Card>
      {context === 'wizard' && (
        <Toolbar align={'right'} className={styles.buttonWrapper}>
          <NextStepButton
            integration={integration}
            key={'next'}
            onNext={handleNext}
            t={t}
            validation={validateStepOne}
          />
        </Toolbar>
      )}
    </>
  );
}

export default observer(ImisConfigStepOne);
