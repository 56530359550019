import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX, Ref } from 'react';
import React, { forwardRef, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type ReactAsyncSelect from 'react-select/async';
import type { Option } from 'react-select/src/filters';

import type { ISegment, Segment } from '@feathr/blackbox';
import type { IAsyncSelectProps } from '@feathr/components';
import { AsyncSelect } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import type { IListParams } from '@feathr/rachis';

import type { TStat } from './SegmentOption';
import SegmentOption from './SegmentOption';
import SegmentSingleValue from './SegmentSingleValue';

interface IMenuOption {
  id: string;
  name: string;
}

export interface ISegmentSelectSimpleChangeProps {
  id: string;
  oldId?: string;
}

export interface ISegmentSelectSimpleProps
  extends Omit<
    IAsyncSelectProps<Segment | IMenuOption>,
    'value' | 'onSelectMulti' | 'onSelectSingle' | 'onClear' | 'onChange'
  > {
  filters?: IListParams<ISegment>['filters'];
  disabled?: boolean;
  excludeIds?: string[];
  hideCreate?: boolean;
  includeIds?: string[];
  onChange: (props: ISegmentSelectSimpleChangeProps) => void;
  onCreate?: (segment: Segment) => Promise<void> | void;
  stat?: TStat;
  name?: string;
  value?: string;
}

function SegmentSelectSimple(
  {
    filters = {},
    disabled = false,
    excludeIds = [],
    hideCreate = false,
    includeIds = [],
    onChange,
    onCreate,
    stat,
    value,
    ...additionalProps
  }: ISegmentSelectSimpleProps,
  ref: Ref<ReactAsyncSelect<Segment | IMenuOption>>,
): JSX.Element {
  const { Segments } = useContext(StoresContext);
  const [, setSegmentQuery] = useState('');
  const { t } = useTranslation();

  async function loadSegmentOptions(inputValue: string): Promise<Array<Segment | IMenuOption>> {
    const localFilters: IListParams<ISegment>['filters'] = {
      ...filters,
      is_archived__ne: true,
      name__icontains: inputValue,
    };

    const segments = Segments.list(
      {
        filters: localFilters,
        pagination: {
          page: 0,
          page_size: 20,
        },
      },
      {},
    );
    await when(() => !segments.isPending);
    // Converting observables back to vanilla JavaScript.
    return [{ id: 'addSegment', name: t('Create group') }, ...segments.models.slice()];
  }

  function filterOption(option: Option): boolean {
    if (hideCreate && option.data.id === 'addSegment') {
      return false;
    }
    if (includeIds.length) {
      return includeIds.includes(option.data.id) && !excludeIds.includes(option.data.id);
    }
    return !excludeIds.includes(option.data.id);
  }

  async function handleSelect(option: Segment | IMenuOption): Promise<void> {
    if (!option.id) {
      // This should not happen.
      return;
    } else if (option.id === 'addSegment') {
      const newSegment = Segments.create({
        name: stat === 'num_emails_total' ? t('New Email Group') : undefined,
        mode: 'match_any',
        lookback_mode: 'unbounded',
        predicates:
          stat === 'num_emails_total'
            ? [
                {
                  kind: 'attribute',
                  attr_against: 'email',
                  attr_type: 'string',
                  comparison: 'eq',
                  value: '',
                  group: [],
                },
              ]
            : [],
        is_custom: true,
      });
      if (onCreate) {
        await onCreate(newSegment);
      }
      onChange({
        id: newSegment.id,
        oldId: value,
      });
    } else {
      onChange({
        id: option.id,
        oldId: value,
      });
    }
  }

  function handleInputChange(newValue: string): void {
    /*
     * Force loadOptions to refresh when input changes.
     * If the user clicks the input and does not select anything
     * provide a value of the last action to trigger the refresh.
     */
    setSegmentQuery(newValue);
  }

  const segment = value ? Segments.get(value) : undefined;

  return (
    <AsyncSelect<Segment | IMenuOption>
      {...additionalProps}
      cacheOptions={true}
      components={{ Option: SegmentOption, SingleValue: SegmentSingleValue }}
      defaultOptions={true}
      disabled={disabled}
      filterOption={filterOption}
      innerRef={ref}
      loadOptions={loadSegmentOptions}
      onInputChange={handleInputChange}
      onSelectSingle={handleSelect}
      placeholder={hideCreate ? t('Choose a group') : t('Choose or create a group')}
      stat={stat}
      value={segment}
    />
  );
}

export default observer(forwardRef(SegmentSelectSimple));
