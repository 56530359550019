import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import type { Dispatch } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  BlackbaudRaisersEdgeIntegration as BREIntegration,
  IBlackbaudContactMapping,
} from '@feathr/blackbox';
import { Spinner, toast } from '@feathr/components';

import SyncMappingCard from '../../SyncMappingCard';
import DeleteMappingButton from '../DeleteMappingButton';
import RaisersEdgeDateLastSynced from '../RaisersEdgeDateLastSynced';
import SyncPersonSelect from './SyncPersonSelect';

import * as styles from './RaisersEdgeSyncPerson.css';

interface IRaisersEdgeSyncPersonProps {
  addMappingButton: JSX.Element;
  integration: BREIntegration;
  setHasMappings: Dispatch<React.SetStateAction<boolean>>;
}

function RaisersEdgeSyncPerson({
  addMappingButton,
  integration,
  setHasMappings,
}: IRaisersEdgeSyncPersonProps): JSX.Element {
  const { t } = useTranslation();
  const [mappings, setMappings] = useState<IBlackbaudContactMapping[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getMappings(): Promise<void> {
      // TODO: Refactor to use ContactMappings.list() as part of #2651.
      try {
        setIsLoading(true);
        const mappings = await integration.getContactMappings();
        setIsLoading(false);
        setMappings(mappings);
        setHasMappings(true);
      } catch (error) {
        setIsLoading(false);
        toast(t('Failed to load mappings.\n{{- error}}', { error }), { type: ToastType.ERROR });
      }
    }

    if (!mappings) {
      getMappings();
    }
  }, []);

  const noMappingsMessage: JSX.Element = (
    <div className={styles.message}>
      <h4>{t('No mappings found')}</h4>
      <p>{t('Add a new mapping to get started.')}</p>
      {addMappingButton}
    </div>
  );

  if (isLoading) {
    return <Spinner />;
  } else {
    if (mappings) {
      // We want to sort the required mappings to the top of the list.
      const mappingCards: JSX.Element[] = mappings
        .sort((a, b) => {
          // TODO: Implement sorting in the query as part of #2991.
          return a.required === b.required ? 0 : a.required ? -1 : 1;
        })
        .map((mapping) => (
          <SyncMappingCard
            controls={[
              <RaisersEdgeDateLastSynced
                className={styles.sync}
                dateLastSynced={mapping.date_last_synced}
                key={`${mapping.id}-date-last-synced`}
                showLastSyncedPrefix={false}
                showNeverSynced={true}
              />,
              <DeleteMappingButton
                disabled={mapping.required}
                id={mapping.id}
                integration={integration}
                key={`${mapping.id}-delete-mapping-button`}
              />,
            ]}
            key={mapping.id}
            mapping={mapping}
            mappings={mappings}
            target={
              <SyncPersonSelect
                className={styles.select}
                integration={integration}
                mapping={mapping}
                mappings={mappings}
              />
            }
          />
        ));
      return <>{mappingCards}</>;
    }
    return noMappingsMessage;
  }
}

export default observer(RaisersEdgeSyncPerson);
