import type { PickerOptions } from 'filestack-js';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useId } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Event, Template } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import {
  CodeMirror,
  ImagePicker,
  Input,
  Label,
  NumberInput,
  Select,
  Textarea,
} from '@feathr/components';
import { useAccount } from '@feathr/extender/state';

import SuggestMergeTags from './SuggestMergeTags';

import * as styles from './PageTemplateMetadataInputs.css';

interface IProps {
  template: Template;
  event?: Event;
}

function PageTemplateMetadataInputs({ event, template }: IProps): JSX.Element {
  const account = useAccount();
  const ogImageId = useId();
  const { t } = useTranslation();

  function handleChangeShortCode(newValue?: string): void {
    template.set({ short_code: newValue });
  }

  function handleChangeTitle(newValue?: string): void {
    template.set({ title: newValue });
  }

  function handleChangeMetaAuthor(newValue?: string): void {
    template.set({
      metatags: {
        ...template.get('metatags'),
        author: newValue ?? '',
      },
    });
  }

  function handleChangeOGTitle(newValue?: string): void {
    template.set({
      metatags: {
        ...template.get('metatags'),
        og_title: newValue ?? '',
      },
    });
  }

  function handleChangeOGObjectType(option: { id: string; name: string }): void {
    template.set({ metatags: { ...template.get('metatags'), og_type: option.id } });
  }

  function handleChangeOGImage(newValue?: string): void {
    template.set({
      metatags: {
        ...template.get('metatags'),
        og_image: newValue ?? '',
      },
    });
  }

  function handleChangeOGImageWidth(newValue?: number): void {
    template.set({
      metatags: {
        ...template.get('metatags'),
        og_image_width: newValue ?? 0,
      },
    });
  }

  function handleChangeOGImageHeight(newValue?: number): void {
    template.set({
      metatags: {
        ...template.get('metatags'),
        og_image_height: newValue ?? 0,
      },
    });
  }

  function handleChangeMetaTwitterSite(newValue?: string): void {
    template.set({
      metatags: {
        ...template.get('metatags'),
        twitter_site: newValue ?? '',
      },
    });
  }

  function handleChangeMetaTwitterCreator(newValue?: string): void {
    template.set({
      metatags: {
        ...template.get('metatags'),
        twitter_creator: newValue ?? '',
      },
    });
  }

  const ogTypeOptions = [
    {
      id: 'website',
      name: 'Website',
    },
    {
      id: 'article',
      name: 'Article',
    },
    {
      id: 'business.business',
      name: 'Business',
    },
    {
      id: 'place',
      name: 'Place',
    },
    {
      id: 'product',
      name: 'Product',
    },
    {
      id: 'profile',
      name: 'Profile',
    },
  ];
  const isReferralCampaign = template.get('_cls') === TemplateClass.ReferralPage;
  const shortCodeHelpText = `
    Pages made from this Template will be reachable at URLs derived from this URL template.
      ${
        isReferralCampaign
          ? 'Use Partner merge tags (like @PARTNER_NAME@) to ensure each Partner has a unique URL for their page.'
          : ''
      }
  `;

  // Extract domain and port form short url
  const defaultDomain = new URL(BLACKBOX_SHORT_URL).host;
  const prefix = `https://${(event && event.mktg_page_rdr_domain.get('domain')) || defaultDomain}`;
  const input = (
    <Input
      helpText={shortCodeHelpText}
      label={'URL Template'}
      onChange={handleChangeShortCode}
      prefix={`${prefix}/`}
      type={'text'}
      validationError={template.validate(['short_code']).errors}
      value={template.get('short_code')}
    />
  );

  return (
    <div className={styles.templateMetaDataInputs}>
      <legend className={styles.templateMetaDataHeader}>Page Configuration</legend>
      {isReferralCampaign ? (
        <SuggestMergeTags input={input} path={'short_code'} template={template} />
      ) : (
        input
      )}
      {account.isFalcon && (
        <>
          <CodeMirror
            attribute={'extra_header_html'}
            label={'Additional Header HTML'}
            model={template}
            name={'extra_header_html'}
            options={{ height: 150 }}
          />
          <CodeMirror
            attribute={'extra_footer_html'}
            label={'Additional Footer HTML'}
            model={template}
            name={'extra_footer_html'}
            options={{ height: 150 }}
          />
        </>
      )}

      <legend className={styles.templateMetaDataHeader}>General Metadata</legend>
      <SuggestMergeTags
        input={
          <Input
            helpText={`
                  The text that will appear in the browser tab when someone is viewing this page.
                  Also used to label search results.`}
            label={'Title'}
            onChange={handleChangeTitle}
            type={'text'}
            value={template.get('title')}
          />
        }
        path={'title'}
        template={template}
      />
      <SuggestMergeTags
        input={
          <Textarea
            attribute={['metatags', 'description']}
            helpText={`
            Additional text that is often displayed in search results,
            as well as when shared on social media.
            `}
            label={'Description'}
            model={template}
          />
        }
        path={'metatags.description'}
        template={template}
      />
      <SuggestMergeTags
        input={
          <Input
            helpText={'Sometimes also included to provide additional context in search results.'}
            label={'Author'}
            onChange={handleChangeMetaAuthor}
            type={'text'}
            value={template.get('metatags').author}
          />
        }
        path={'metatags.author'}
        template={template}
      />

      <legend className={styles.templateMetaDataHeader}>Open Graph Metadata</legend>
      <Trans t={t}>
        <p>
          These fields are used by social media platforms which support the Open Graph
          specification, such as Meta platforms, Twitter and LinkedIn. They allow you to control
          what parts of your page are extracted and displayed when a link to it is shared on one of
          these platforms.
        </p>
      </Trans>
      <SuggestMergeTags
        input={
          <Input
            helpText={
              'The displayed title when pages made from this template are shared on social media.'
            }
            label={'Open Graph Title'}
            onChange={handleChangeOGTitle}
            type={'text'}
            value={template.get('metatags').og_title}
          />
        }
        path={'metatags.og_title'}
        template={template}
      />
      <Select
        helpText={
          'This controls how the preview is displayed when pages made from this template are shared through social media.'
        }
        label={'Open Graph Object Type'}
        onSelectSingle={handleChangeOGObjectType}
        options={ogTypeOptions}
        value={ogTypeOptions.find((option) => option.id === template.get('metatags').og_type)}
      />
      <Label htmlFor={ogImageId}>{t('Open Graph image URL')}</Label>
      <ImagePicker
        buttonText={'Upload Open Graph image'}
        helpText={
          'The displayed preview image when pages made from this template are shared on social media. .png or .jpg files accepted.'
        }
        onPick={handleChangeOGImage}
        pickerOptions={
          {
            accept: ['image/png', 'image/jpg', '.jpg', '.png'],
            fromSources: ['local_file_system', 'url'],
            customText: {
              'Select Files to Upload': t('Upload Open Graph image or provide an image URL'),
            },
            maxFiles: 1,
          } as PickerOptions
        }
        validationError={template.validate(['metatags', 'og_image']).errors}
        value={template.get('metatags').og_image}
      />
      <SuggestMergeTags
        input={
          <Input
            helpText={
              'Paste the URL of the image you want to use, or upload an image via the file picker.'
            }
            id={ogImageId}
            onChange={handleChangeOGImage}
            type={'text'}
            value={template.get('metatags').og_image}
          />
        }
        path={'metatags.og_image'}
        template={template}
      />
      <NumberInput
        helpText={'The width of the preview image in pixels.'}
        label={'Open Graph Image Width'}
        min={0}
        onChange={handleChangeOGImageWidth}
        value={template.get('metatags').og_image_width}
      />
      <NumberInput
        helpText={'The height of the preview image in pixels.'}
        label={'Open Graph Image Height'}
        min={0}
        onChange={handleChangeOGImageHeight}
        value={template.get('metatags').og_image_height}
      />

      <legend className={styles.templateMetaDataHeader}>Twitter Metadata</legend>
      <p>
        These fields are used exclusively by Twitter to provide context for links shared in tweets.
      </p>
      <SuggestMergeTags
        input={
          <Input
            helpText={
              'The @username Twitter handle to associate with a page made from this template.'
            }
            label={'Twitter Site Username'}
            onChange={handleChangeMetaTwitterSite}
            type={'text'}
            value={template.get('metatags').twitter_site}
          />
        }
        path={'metatags.twitter_site'}
        template={template}
      />
      <SuggestMergeTags
        input={
          <Input
            helpText={
              'The @username Twitter handle to associate as the creator of the content of a page made from this template.'
            }
            label={'Twitter Creator Username'}
            onChange={handleChangeMetaTwitterCreator}
            type={'text'}
            value={template.get('metatags').twitter_creator}
          />
        }
        path={'metatags.twitter_creator'}
        template={template}
      />
    </div>
  );
}

export default observer(PageTemplateMetadataInputs);
