import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import { Alert, AlertType, Button, Form } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

import CampaignSummary from '../../CampaignSummary';
import type { ICampaignValidationErrors } from '../../CampaignSummary/CampaignSummary';

import * as styles from './PinpointEmailCampaignStepSeven.css';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
  onPrev: () => void;
  validationErrors: ICampaignValidationErrors;
  saveButton: ReactNode;
  // The following is not used by Autosend Campaigns
  isVerifiedDomainSender?: boolean;
}

function PinpointEmailCampaignStepSeven({
  campaign,
  isVerifiedDomainSender,
  onPrev,
  saveButton,
  validationErrors,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const isAutosend = campaign.get('_cls') === CampaignClass.AutoPinpointEmail;
  const shouldEnforceSendLimit =
    !isAutosend && !isVerifiedDomainSender && validationErrors.contacts_limit;
  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        saveButton,
      ]}
      description={
        <div>
          <Trans t={t}>
            Review the details of your campaign. If you need to modify anything simply return to the
            appropriate step of the campaign. Once it all looks good, hit Publish!
          </Trans>
          {shouldEnforceSendLimit && (
            <Alert className={styles.alert} type={AlertType.danger}>
              <Trans t={t}>
                The domain for this email address has not been verified. Email campaigns from
                unverified domains are limited to 1,000 contacts. To remove this limitation,{' '}
                <a href={localUrl('settings/account/domains')}>authorize your domain</a> before
                publishing this campaign. Authorizing your domain improves deliverability and
                tightens email security.{' '}
                <a
                  href={
                    'https://help.feathr.co/hc/en-us/articles/360040220793-Connecting-a-Domain-to-your-Feathr-Account'
                  }
                  target={'_blank'}
                >
                  Learn more about authorizing your domain.
                </a>
              </Trans>
            </Alert>
          )}
        </div>
      }
      label={t('Edit Campaign: Review')}
    >
      <CampaignSummary campaign={campaign} validationErrors={validationErrors} />
    </Form>
  );
}

export default observer(PinpointEmailCampaignStepSeven);
