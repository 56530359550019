import { faEye, faSquareArrowUpRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { WithT } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { IiMISServiceAccount, ImisIntegration, TiMISOrgSite } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  ButtonCopy,
  ButtonValid,
  CardV2 as Card,
  EAlertV2Type as AlertType,
  Form,
  FormElement,
  toast,
  Toolbar,
} from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import type { TConfigurationContext } from './ImisConfiguration';

import * as styles from './ImisConfigStepTwo.css';

interface IProps {
  context?: TConfigurationContext;
  onNext: () => void;
  onPrev: () => void;
  integration: ImisIntegration;
}

interface INextButtonProps extends WithT {
  onNext: () => void;
  integration: ImisIntegration;
  serviceAccount?: Readonly<IiMISServiceAccount>;
  isUserAvailable?: boolean;
}
export function validateStepTwo(integration: ImisIntegration): TValidateGrouped {
  return integration.validate(['org_site'], false, 'grouped').errors;
}

const NextStepButton = observer(
  ({ integration, onNext, serviceAccount, isUserAvailable, t }: INextButtonProps): JSX.Element => {
    const validationErrors = validateStepTwo(integration);
    const errors = flattenErrors(validationErrors);
    if (!integration.isPending && !serviceAccount) {
      errors.push(t('This user could not be retrieved.'));
    }
    if (!integration.isPending && !isUserAvailable) {
      errors.push(
        t('You must create and validate this user in your iMIS account before proceeding.'),
      );
    }
    return (
      <ButtonValid errors={errors} name={'next'} onClick={onNext}>
        {t('Next')}
      </ButtonValid>
    );
  },
);

function ImisConfigStepTwo({
  context = 'wizard',
  onNext,
  onPrev,
  integration,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const [serviceAccount, setServiceAccount] = useState<IiMISServiceAccount>();
  const [isServiceAccountLoading, setIsServiceAccountLoading] = useState<boolean>(true);
  const [isUserAvailable, setIsUserAvailable] = useState<boolean | undefined>();
  const orgSite: TiMISOrgSite = integration.get('org_site');
  const createServiceAccountUserUrl = `${orgSite}/Staff/iCore/Contacts/Create_Contact_Person_or_Org.aspx`;

  useEffect((): (() => void) => {
    let isMounted = true;

    // If serviceAccount exists, no need to fetch it again
    if (serviceAccount) {
      return () => {
        // Cleanup function to set isMounted to false when the component unmounts
        isMounted = false;
      };
    }

    async function getServiceAccount(): Promise<void> {
      try {
        const getServiceAccount = await integration.getServiceAccount();

        // Check if the component is still mounted before updating state
        if (isMounted) {
          setServiceAccount(getServiceAccount);
          setIsServiceAccountLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setServiceAccount(undefined);
          toast(
            t('Unable to retrieve the iMIS service account. {{error}}', {
              error,
            }),
            {
              type: ToastType.ERROR,
            },
          );
          setIsServiceAccountLoading(false);
        }
      }
    }

    getServiceAccount();

    return (): void => {
      isMounted = false;
    };
  }, [serviceAccount, integration]);

  const { username, email, password } = serviceAccount ?? {
    username: t('No username found'),
    email: t('No email found'),
    password: t('No password found'),
  };

  async function handleCheckForUser(): Promise<void> {
    try {
      await integration.validateCredentials();
      setIsUserAvailable(true);
    } catch (error) {
      setIsUserAvailable(false);
    }
  }

  function showUserAvailabilityAlert(
    isUserAvailable: boolean | undefined,
  ): JSX.Element | undefined {
    if (isUserAvailable) {
      return <Alert title={t('This user was found')} type={AlertType.success} />;
    }
    if (isUserAvailable === false) {
      return <Alert title={t('This user was not found')} type={AlertType.danger} />;
    }
    return undefined;
  }

  const description =
    context === 'wizard'
      ? t(
          'Set up a special user in your iMIS account with this exact user name and password. This will allow Feathr to connect to your iMIS account. Be sure to provide this user with Remote Service Access only.',
        )
      : t(
          'This is the special user in your iMIS account that Feathr uses to connect to your iMIS account.',
        );

  const title = context === 'wizard' ? t('Verify User') : t('Verified User');

  return (
    <>
      <Card>
        <Card.Header description={description} title={title}>
          <Button
            className={styles.button}
            href={createServiceAccountUserUrl}
            name={'setup-in-imis'}
            prefix={<FontAwesomeIcon icon={faSquareArrowUpRight} />}
            target={'_blank'}
          >
            {t('Set Up in iMIS')}
          </Button>
        </Card.Header>
        <Card.Content>
          <Form label={t('User information')}>
            <FormElement className={styles.button} label={t('Click to copy username')}>
              <ButtonCopy
                className={styles.copyButton}
                disabled={!serviceAccount}
                isLoading={integration.isPending || isServiceAccountLoading}
                name={'copy-username'}
              >
                {username}
              </ButtonCopy>
            </FormElement>
            <FormElement className={styles.button} label={t('Click to copy email')}>
              <ButtonCopy
                className={styles.copyButton}
                disabled={!serviceAccount}
                isLoading={integration.isPending || isServiceAccountLoading}
                name={'copy-email'}
              >
                {email}
              </ButtonCopy>
            </FormElement>
            <FormElement className={styles.button} label={t('Show password and copy to clipboard')}>
              <ButtonCopy
                className={styles.copyButton}
                disabled={!serviceAccount}
                isLoading={integration.isPending || isServiceAccountLoading}
                name={'copy-password'}
                secret={true}
                suffix={<FontAwesomeIcon icon={faEye} />}
              >
                {password}
              </ButtonCopy>
            </FormElement>
          </Form>
        </Card.Content>
        <Card.Actions>
          <Button name={'check-for-user'} onClick={handleCheckForUser}>
            {t('Check for user')}
          </Button>
        </Card.Actions>
      </Card>
      {context === 'wizard' && (
        <div className={styles.buttonWrapper}>
          {showUserAvailabilityAlert(isUserAvailable)}
          <Toolbar align={'right'}>
            <Button key={'prev'} name={'previous'} onClick={onPrev}>
              {t('Previous')}
            </Button>
            <NextStepButton
              integration={integration}
              isUserAvailable={isUserAvailable}
              key={'next'}
              onNext={onNext}
              serviceAccount={serviceAccount}
              t={t}
            />
          </Toolbar>
        </div>
      )}
    </>
  );
}

export default observer(ImisConfigStepTwo);
