import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { UserNotification } from '@feathr/blackbox';
import { Notification } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

interface IProps {
  className?: string;
  item?: UserNotification;
  loading?: boolean;
}

function NotificationCard({ className, item, loading = false }: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  if (loading || !item || item.isPending) {
    return <Notification isLoading={true} />;
  }

  const notification = item.notification;
  if (notification.isPending) {
    return <Notification isLoading={true} />;
  }

  function handleMarkAsRead() {
    item?.read();
  }

  function handleMarkAsUnread() {
    item?.unread();
  }

  return (
    <Notification
      className={className}
      created={notification.get('timestamp')}
      icon={notification.getIcon()}
      id={notification.id}
      isRead={item.get('is_read')}
      level={notification.get('level')}
      link={localUrl(notification.getCta())}
      markAsRead={handleMarkAsRead}
      markAsUnread={handleMarkAsUnread}
      message={notification.getMessage()}
      t={t}
      title={notification.get('title')}
    />
  );
}

export default observer(NotificationCard);
