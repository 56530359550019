import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Nav, NavItem } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

function EventPartnersNav(): JSX.Element {
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const { t } = useTranslation();

  return (
    <Nav indented={true} label={'Project partners navigation'} type={'vertical'}>
      <NavItem to={localUrl(`/projects/${eventId}/partners/activity`)}>{t('Activity')}</NavItem>
      <NavItem to={localUrl(`/projects/${eventId}/partners/messages`)}>{t('Messages')}</NavItem>
      <NavItem to={localUrl(`/projects/${eventId}/partners/imports`)}>{t('Imports')}</NavItem>
      <NavItem to={localUrl(`/projects/${eventId}/partners/exports`)}>{t('Exports')}</NavItem>
      <NavItem to={localUrl(`/projects/${eventId}/partners/fields`)}>{t('Custom Fields')}</NavItem>
      <NavItem to={localUrl(`/projects/${eventId}/partners/dashboard`)}>
        {t('Dashboard Settings')}
      </NavItem>
    </Nav>
  );
}

export default EventPartnersNav;
