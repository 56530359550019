import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faFacebook, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import {
  faBrowser,
  faCode,
  faEnvelope,
  faFilm,
  faHammerCrash,
  faImage,
} from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';

import { CreativeClass } from '@feathr/blackbox';
import { cssVar } from '@feathr/hooks';

export const creativeColorMap = new Map<CreativeClass, string>([
  [CreativeClass.DisplayAdTag, cssVar('--color-primary-3')],
  [CreativeClass.DisplayBannersnack, cssVar('--color-brand-orange')],
  [CreativeClass.DisplayImage, cssVar('--color-brand-yellow')],
  [CreativeClass.DisplayVideo, cssVar('--color-brand-orange')],
  [CreativeClass.FacebookImage, cssVar('--color-brand-warning')],
  [CreativeClass.FacebookVideo, cssVar('--color-brand-purple')],
  [CreativeClass.PartnerMessageEmail, cssVar('--color-brand-danger')],
  [CreativeClass.ReferralBanner, cssVar('--color-brand-teal')],
  [CreativeClass.ReferralEmail, cssVar('--color-brand-green')],
  [CreativeClass.ReferralPage, cssVar('--color-brand-yellow-dark')],
]);

export const creativeIconMap = new Map<CreativeClass, IconDefinition>([
  [CreativeClass.DisplayAdTag, faCode],
  [CreativeClass.DisplayBannersnack, faHammerCrash],
  [CreativeClass.DisplayImage, faImage],
  [CreativeClass.DisplayVideo, faFilm],
  [CreativeClass.FacebookImage, faFacebook],
  [CreativeClass.FacebookVideo, faFacebookSquare],
  [CreativeClass.PartnerMessageEmail, faPaperPlane],
  [CreativeClass.ReferralBanner, faImage],
  [CreativeClass.ReferralEmail, faEnvelope],
  [CreativeClass.ReferralPage, faBrowser],
]);
